/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {Row, Col, Input, Form, Button, message,Spin } from "antd";
import constantsMsgs from "../../../constants/messages";
import edit_pen_img from "../../../assests/images/edit-pen.svg";
import "../../../commonStyles/crossButton.scss";
import ServiceTypeModal from "../PendingDrs/ServiceTypeModal";
import "./style.scss";
import API from "../../../api";
import urls from "../../../api/urls";
import moment from "moment";
import {
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
} from "../../../utils";

const { EWAYBILL,PUT_RATECARD_RTO } = urls;

const CommonRTO = ({
  data,
  handleCancel = () => {},
  handleSave = () => {},
}) => {
  const [form] = Form.useForm();
  const currentDate = moment();
  const transporterId = "88AABCM9407D1ZS";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [serviceTypeResponse,setServiceTypeResponse] = useState({});
  const [selectedData,setSelectedData] = useState("");
  const todayDateNTimeFormat = currentDate.format("DD/MM/YYYY hh:mm:ss A");

  const editPenHandle = async() => {
    let userPincode = JSON.parse(localStorage.getItem("userId"))?.premises?.pincode;
    setIsModalOpen(true);
    let areaId = data?.addressDetails?.receiverAreaId;
    let toPincode = data?.addressDetails?.receiverPincode;
    let type = data?.shipmentDetails?.type?.toUpperCase()?.replaceAll("-","_");
    let content = data?.shipmentDetails?.content;
    let description = data?.shipmentDetails?.description;
    let weight = data?.shipmentDetails?.weight;
    let response = await API.Api(
      `${PUT_RATECARD_RTO}${userPincode}/to/${toPincode}`
    ).postWithPrmiseHeader({
      areaId: areaId,
      type,
      content,
      description,
      weight
    });
    setLoading(true);
    if(response?.status){
      setServiceTypeResponse(response?.response?.data?.data);
      setLoading(false);
    }else{
      setLoading(false);
      message.error(response?.response?.data?.message);
    }
  };

  const eWayBillHandle = async (value, name) => {
    if (value?.length === 12) {
      setLoading(true);

      let response = await API.Api(
        EWAYBILL + "/" + value
      ).getWithPremiseIdHeader();

      if (response?.status) {
        let data = JSON.parse(response?.response?.data?.data);
        const validilityDate = moment(data?.validUpto, "DD/MM/YYYY");
        const todayDateNTime = moment(todayDateNTimeFormat, "DD/MM/YYYY");

        if (data?.errorCodes) {
          setDisableSubmit(true);
          form.setFields([
            {
              name: name,
              errors: ["Please give correct E-way bill number"],
            },
          ]);
          setLoading(false);
        } else if (todayDateNTime.isAfter(validilityDate)) {
          setDisableSubmit(true);
          setLoading(false);
          form.setFields([
            {
              name: name,
              errors: ["E-way bill expired"],
            },
          ]);
        } else if (data?.transporterId !== transporterId) {
          setDisableSubmit(true);

          form.setFields([
            {
              name: name,
              errors: ["Transporter id is not matching"],
            },
          ]);
          setLoading(false);
        } else {
          setDisableSubmit(false);
          setLoading(false);
          // setVerifiedEwayBill(data);
          return Promise.resolve(
            message.success("E-way bill request succeeds")
          );
        }
      } else {
        setDisableSubmit(true);
        setLoading(false);

        form.setFields([
          {
            name: name,
            errors: [response?.response?.data?.message],
          },
        ]);
      }
    }

    function hasErrors(data) {
      for (const item of data) {
        if (item?.errors && item?.errors?.length > 0) {
          return true;
        }
      }
      return false;
    }
    const errorData = form.getFieldsError();
    const hasError = hasErrors(errorData);

    if (hasError) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  };

  const handleModalOpen = (e) => {
    setIsModalOpen(e);
  };
  const selectTypeDataHandle = (e)=>{
    setSelectedData(e);
  };
  return (
    <Spin spinning={loading}>
      <div className="rto-container">
        <div className="modal-content global-form">
          <Form
            form={form}
            name="rtoForm"
            onFinish={()=>handleSave(selectedData)}
            layout="vertical"
          >
            <Row className="global-form-row">
              <Col span={24}>
                <Row>
                  <div className="title highlight">
                    <h4>{constantsMsgs?.BOOKING_DETAILS}</h4>
                  </div>
                </Row>
                <div className="booking-details">
                  <Row className="mb-1">
                    <Col md={8} lg={8} xs={24} sm={8} className="mb-1 responsive-field">
                      <div>
                        <p>New AWb No.</p>
                        <b>{data?.awbNumber}</b>
                      </div>
                    </Col>
                    <Col md={8} lg={8} xs={24} sm={8} className="mb-1">
                      <div>
                        <p>Return Initiation Date: </p>
                      </div>
                      <div className="highlight">
                        {data?.shipmentDetails?.bookingStartTime}
                      </div>
                    </Col>
                    {/* new eway bill */}
                    {data?.shipmentDetails?.ewayBillNumber && (
                      <Col md={8} lg={8} xs={24} sm={8} className="mb-1">
                        <div>{/* <p>New Eway Bill Number: </p> */}</div>
                        <div className="highlight">
                          <Form.Item
                            label={"New E-way Bill"}
                            name={"parentNewEwayBill"}
                            rules={[
                              {
                                required:
                                  +data?.shipmentDetails?.value >= 50000
                                    ? true
                                    : false,
                                message: "E-way bill is required",
                              },
                              () => ({
                                validator(rule, value = "") {
                                  if (value?.length > 0 && value?.length < 12) {
                                    return Promise.reject(
                                      "E-way bill should be 12 digits"
                                    );
                                  } else {
                                    return Promise.resolve();
                                  }
                                },

                                validateTrigger: "onSubmit",
                              }),
                            ]}
                            onKeyPress={(event) => {
                              if (/[^0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                              if (
                                checkNumbervalue(event) ||
                                checkSpecialCharacter(event) ||
                                checkSpace(event)
                              ) {
                                event.preventDefault();
                              }
                            }}
                          >
                            <Input
                              // onChange={() => setdisableSubmit(true)}
                              maxLength={12}
                              minLength={12}
                              onChange={(e) =>
                                eWayBillHandle(
                                  e?.target?.value,
                                  "parentNewEwayBill"
                                )
                              }
                              // className="input-field-style"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row className="mb-1">
                    <Col md={8} lg={8} xs={24} sm={8} className="mb-1">
                      <div>
                        <p>Original AWB No:</p>
                        <b>{data?.oldAwbNumber}</b>
                      </div>
                    </Col>
                    <Col md={8} lg={8} xs={24} sm={8} className="mb-1">
                      <div>
                        <p>Booking Date:</p>
                      </div>
                      <div className="highlight">
                        {data?.shipmentDetails?.bookingCompleteTime}
                      </div>
                    </Col>
                    {data?.shipmentDetails?.oldEwayBillNumber && (
                      <Col md={8} lg={8} xs={24} sm={8} className="mb-1">
                        <div>
                          <p>Original EWay Bill Number </p>
                          <b>{data?.shipmentDetails?.oldEwayBillNumber}</b>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row className=" mb-1">
                    <Col md={8} lg={8} xs={24} sm={8} className="mb-1">
                      <div>
                        <p>Book From</p>
                        <b>{data?.addressDetails?.senderCity}</b>
                      </div>
                    </Col>
                    <Col md={8} lg={8} xs={24} sm={8} className="mb-1">
                      <div>
                        <p>Book To </p>
                        {/* <b>20.09.2023</b> */}
                      </div>
                      <div className="highlight">
                        {data?.addressDetails?.receiverCity}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            {/* Child */}
            {data?.childShipmentDetails?.length > 0 ? <Row className="address">
              <Col>
                <Row>
                  <div className="title">
                    <h4>Child Shipment Details</h4>
                  </div>
                </Row>
              </Col>
              <Col className="child-container">
                <Row className="d-flex p-1" style={{ margin: "0" }}>
                  <Col span={6} className="child-lable">
                    New AWB Number
                  </Col>
                  <Col span={6} className="child-lable">
                    Old AWB Number
                  </Col>
                  <Col span={6} className="child-lable">
                    Old Eway Bill Number
                  </Col>
                  <Col span={6} className="child-lable">
                    New Eway Bill Number
                  </Col>
                </Row>
                {data?.childShipmentDetails?.map((data, index) => (
                  <Row
                    className="align-center pl-1 pr-1 pt-05 pb-05"
                    style={{ margin: "0", borderTop: "1px solid #DDDDDD" }}
                    key={index}
                  >
                    <Col span={6}>{data?.newAwbNumber}</Col>
                    <Col span={6}>{data?.childAwbNumber}</Col>
                    <Col span={6}>{data?.ewayBillNumber}</Col>
                    <Col span={6}>
                      <Form.Item
                        // label={"E-way bill"}
                        name={index}
                        rules={[
                          {
                            required:
                              +data?.shipmentDetails?.value >= 50000
                                ? true
                                : false,
                            message: "New E-way bill is required",
                          },
                          () => ({
                            validator(rule, value = "") {
                              if (value?.length > 0 && value?.length < 12) {
                                return Promise.reject(
                                  "E-way bill should be 12 digits"
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },

                            validateTrigger: "onSubmit",
                          }),
                        ]}
                        onKeyPress={(event) => {
                          if (/[^0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                          if (
                            checkNumbervalue(event) ||
                            checkSpecialCharacter(event) ||
                            checkSpace(event)
                          ) {
                            event.preventDefault();
                          }
                        }}
                      >
                        <Input
                          // onChange={() => setdisableSubmit(true)}
                          maxLength={12}
                          minLength={12}
                          className="input-field-style"
                          placeholder="Enter new Eway no."
                          onChange={(e) =>
                            eWayBillHandle(e?.target?.value, index)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row> : null}
            {/* address */}
            <Row className="address" gutter={16}>
              <Col>
                <Row>
                  <div className="title">
                    <h4>Address</h4>
                  </div>
                </Row>
                <Row>
                  <Col md={12} lg={12} sm={24} xs={24}>
                    <div className="card">
                      <div>
                        <b>From,</b>
                        <h4>{
                          <>
                            <p>{data?.addressDetails?.senderName}</p>
                            <p>{data?.addressDetails?.senderMobileNumber}</p>
                          </>
                        }</h4>
                      </div>
                      <div>
                        <p>
                          {data?.addressDetails?.senderAddressLine1},{" "}
                          {data?.addressDetails?.senderAddressLine2}{" "}
                          {data?.addressDetails?.senderCity},
                          {data?.addressDetails?.senderState},{" "}
                          {data?.addressDetails?.senderPincode}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={12} lg={12} sm={24} xs={24}>
                    <div className="card">
                      <div>
                        <b>To,</b>
                        <h4>{
                          <>
                            <p>{data?.addressDetails?.receiverName}</p>
                            <p>{data?.addressDetails?.receiverMobileNumber}</p>
                          </>
                        }</h4>
                      </div>
                      <div>
                        <p>
                          {data?.addressDetails?.receiverAddressLine1},{" "}
                          {data?.addressDetails?.receiverAddressLine2}{" "}
                          {data?.addressDetails?.receiverCity},
                          {data?.addressDetails?.receiverState},{" "}
                          {data?.addressDetails?.receiverPincode}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* service */}
            <Row className="service">
              <Col span={24}>
                <Row>
                  <div className="title space-between">
                    <h4>Service Details</h4>
                    <img
                      src={edit_pen_img}
                      alt="edit"
                      className="mr-1"
                      onClick={editPenHandle}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </Row>
                <Row>
                  <Col md={12} lg={12} sm={24} xs={24}>
                    <div className="card left-card">
                      <Row>
                        <Col span={12} className="key">
                          <p>Type: </p>
                          <p>Service : </p>
                          <p>Travel by : </p>
                          <p>Content : </p>
                        </Col>
                        <Col span={12} className="value">
                          <p>
                            {data?.shipmentDetails?.type
                              ? data?.shipmentDetails?.type
                              : null}
                          </p>
                          <p>{selectedData ? selectedData?.split("_")[1] :data?.serviceDetails?.service}</p>
                          <p>{selectedData ? selectedData?.split("_")[2] :data?.serviceDetails?.travelBy}</p>
                          <p>{data?.shipmentDetails?.content}</p>
                          
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={12} lg={12} sm={24} xs={24}>
                    <div className="card right-card">
                      <Row>
                        <Col span={12} className="key">
                          <p>Description: </p>
                          <p className="last"> Sender’s GST number :</p>
                          <p>Value:</p>
                          <p>Weight (Gms) : </p>
                        </Col>
                        <Col span={12} className="value">
                          <p>{data?.shipmentDetails?.description || ""}</p>
                          <p>{data?.addressDetails?.senderGst}</p>
                          <p>{data?.shipmentDetails?.value}</p>
                          <p>{data?.shipmentDetails?.weight}</p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="payment">
              <Col span={24}>
                <Row>
                  <div className="title">
                    <h4>Payment</h4>
                  </div>
                </Row>
                <Row>
                  <Col md={12} lg={12} sm={24} xs={24}>
                    <div className="card left-card">
                      <Row>
                        <Col span={12} className="key">
                          <p>Total Charges: </p>
                          <p>SGST @ 9% : </p>
                          <p className="last">CGST @ 9% : </p>
                        </Col>
                        <Col span={12} className="value">
                          <p>{data?.charges?.charges}</p>
                          <p>
                            {data?.charges?.sgst
                              ? data?.charges?.sgst
                              : "00.00"}
                          </p>
                          <p className="last">
                            {data?.charges?.cgst
                              ? data?.charges?.cgst
                              : "00.00"}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={12} lg={12} sm={24} xs={24}>
                    <div className="card right-card">
                      <Row>
                        <Col span={12} className="key">
                          <p> {constantsMsgs?.IGST}: </p>
                          <p>{constantsMsgs?.CESS} : </p>
                          <p className="last"></p>
                        </Col>
                        <Col span={12} className="value">
                          <p>
                            {data?.charges?.igst
                              ? data?.charges?.igst
                              : "00.00"}
                          </p>
                          <p>
                            {data?.charges?.cess
                              ? data?.charges?.cess
                              : "00.00"}
                          </p>
                          <p className="last"></p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="space-between" style={{background:"#FBEE78",borderRadius:"5px"}}>
                <p style={{border:"none"}}> Freight :</p>
                <p style={{border:"none"}}> {selectedData ? selectedData?.split("_")[0] : data?.charges?.freight}</p>
              </Col>
            </Row>
            <Row gutter={12} className="d-flex flex-end mt-1">
              <Col>
                <Button className="cancel-btn" onClick={handleCancel}>
                  CANCEL
                </Button>
              </Col>
              <Col className="">
                <Button
                  className="save-btn"
                  htmlType="submit"
                  disabled={disableSubmit}
                  // onClick={handleSave}
                >
                  SAVE
                </Button>
              </Col>
            </Row>
            <ServiceTypeModal
              isModalOpen={isModalOpen}
              handleModalOpen={handleModalOpen}
              serviceTypeResponse={serviceTypeResponse}
              selectTypeDataHandle={selectTypeDataHandle}
            />
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default CommonRTO;
