/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from "react";
import { Input, message, Row, Modal, Tree, Spin } from "antd";

import { capitalize, getUserAndPremiseId, serialNum } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { APIs, getPremiseId, getToken } from "../../../utils/worker";
import { ReactComponent as Packet_icon } from "../../../assests/images/SVG/packet-icon.svg";
import { ReactComponent as Scan_check } from "../../../assests/images/SVG/check.svg";
import axios from "axios";
import urls from "../../../api/urls";
import API from "../../../api";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

const { GET_PACKET_CONTENTS, GET_BAGGED_CONTENTS } = urls;

const BagContentModal = ({ currentBarcode, showModal, modalClose }) => {
  const navigate = useNavigate();
  const token = getToken();
  const premiseId = getPremiseId();
  const getUserInfo = getUserAndPremiseId();
  const userId = getUserInfo?.userId;
  const [loading, setLoading] = useState(false);
  const [currentScanNum, setcurrentScanNum] = useState([]);
  const [scannedAwbs, setScannedAwbs] = useState([]);
  const [tableData, setTableData] = useState();
  const [treeData, setTreeData] = useState([]);
  const [packetShipment, setPacketShipment] = useState([]);

  useEffect(() => {
    getTableData();
  }, []);

  const checkStatus = (val) => {
    return (
      val?.barcodeBaggingStatus === "HUB-IN-SCANNED" || "INSCANNED_AT_TRANSIT"
    );
  };

  let barcode = "";
  let interval;

  const handleKeyDown = (event) => {
    if (interval) clearInterval(interval);
    if (event.code == "Enter") {
      if (barcode) handleStatus(barcode);
      barcode = "";
      return;
    }
    if (event.key != "Shift") barcode += event.key;
    interval = setInterval(() => (barcode = ""), 20);
  };

  async function handleStatus(id) {
    setLoading(true);
    let response = await API.Api(
      `${APIs.baseURL}/inscan-service/v1/barcode/in-scan/${id}/status/HUB-IN-SCANNED`
    ).putIdHeader();

    if (response) {
      setScannedAwbs((prev) => [...prev, id] || null);
      message.success(id + " scanned successfully");
      let temp = tableData;
      let index = temp.findIndex((item) => item?.barcodeNo == id);
      if (temp[index] !== undefined) {
        temp[index].barcodeBaggingStatus = "HUB-IN-SCANNED";
        setTableData([...temp]);
      } else {
        let temp1 = packetShipment;
        let index1 = temp1.findIndex((item) => item.awbNo == id);
        temp1[index1].status = "HUB-IN-SCANNED";
        setPacketShipment([...temp1]);
      }
      setLoading(false);
    } else {
      setLoading(false);
      message.error(response.response.data.message || "Network error");
    }
  }

  // useEffect(() => {
  //   window.addEventListener("keydown", handleKeyDown);
  //   // cleanup this component
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  const getTableData = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_BAGGED_CONTENTS + currentBarcode
    ).getWithPremiseIdHeader();

    if (response?.status) {
      setLoading(false);
      let data = response?.response?.data;
      let bagContent = data?.bagContents;
      setTableData([...bagContent]);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    let data = tableData?.sort(function (a, b) {
      return a.barcodeBaggingStatus === "HUB-IN-SCANNED"
        ? 1
        : b.barcodeBaggingStatus === "HUB-IN-SCANNED"
        ? -1
        : 0;
    });
    let bagContent = data;
    let treeData = [];
    bagContent?.map((e, i) => {
      treeData.push({
        title: (
          <span style={{ color: e.barcodeType == "5" ? "blue" : "#000" }}>
            {e.barcodeNo}&nbsp;
            {e?.barcodeType === "5" ? (
              <>
                <Packet_icon />
                <span style={{ marginLeft: "10px" }}>
                  {checkStatus(e) && <Scan_check />}
                </span>
              </>
            ) : (
              checkStatus(e) && <Scan_check />
            )}
          </span>
        ),
        key: `barcode_${i}`,
        id: e.barcodeNo,
        isLeaf: e?.barcodeType === "5" ? false : true,
      });
    });
    setTreeData([...treeData]);
  }, [tableData]);

  const updateTreeData = (list, key, children) =>
    list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });

  const onLoadData = async ({ key, children }) => {
    let barcode = treeData.find((item) => item.key === key).id;
    let response = await API.Api(
      GET_PACKET_CONTENTS + barcode
    ).getWithPremiseIdHeader();
    let data = response?.response?.data?.packetShipments;
    setPacketShipment(data);
    let packetShipments = [];
    for (let i = 0; i < data.length; i++) {
      if (i == data.length || i == data.length - 1) {
        packetShipments.push({
          title: (
            <span>
              {data[i].awbNo}{" "}
              {data[i]?.status === "HUB-IN-SCANNED" && <Scan_check />}
            </span>
          ),
          key: `${i}-0`,
          isLeaf: true,
        });
      } else if (i < data.length) {
        packetShipments.push({
          title: (
            <span>
              {data[i].awbNo}{" "}
              {data[i]?.status === "HUB-IN-SCANNED" ||
                ("INSCANNED_AT_TRANSIT" && <Scan_check />)}{" "}
              ,{data[i + 1].awbNo}{" "}
              {data[i + 1]?.status === "HUB-IN-SCANNED" ||
                ("INSCANNED_AT_TRANSIT" && <Scan_check />)}
              ,
              {/* {data[i+2].awbNo} {data[i+2]?.status === "HUB-IN-SCANNED" && <Scan_check />}, */}
            </span>
          ),
          key: `${i}-0`,
          isLeaf: true,
        });
      }
      i++;
    }
    return new Promise((resolve) => {
      if (children) {
        resolve();
        return;
      }
      setTimeout(() => {
        setTreeData((origin) => updateTreeData(origin, key, packetShipments));
        resolve();
      }, 10);
    });
  };

  useEffect(() => {
    let data = packetShipment;
    let packetShipments = [];
    for (let i = 0; i < data.length; i++) {
      if (i == data.length || i == data.length - 1) {
        packetShipments.push({
          title: (
            <span>
              {data[i].awbNo}{" "}
              {data[i]?.status === "HUB-IN-SCANNED" ||
                ("INSCANNED_AT_TRANSIT" && <Scan_check />)}
            </span>
          ),
          key: `${i}-0`,
          isLeaf: true,
        });
      } else if (i < data.length) {
        packetShipments.push({
          title: (
            <span>
              {data[i].awbNo}{" "}
              {data[i]?.status === "HUB-IN-SCANNED" ||
                ("INSCANNED_AT_TRANSIT" && <Scan_check />)}{" "}
              ,{data[i + 1].awbNo}{" "}
              {data[i + 1]?.status === "HUB-IN-SCANNED" ||
                ("INSCANNED_AT_TRANSIT" && <Scan_check />)}
              ,
              {/* {data[i+2].awbNo} {data[i+2]?.status !== "HUB-IN-SCANNED" && <Scan_check />}, */}
            </span>
          ),
          key: `${i}-0`,
          isLeaf: true,
        });
      }
      i++;
    }
    setTreeData((origin) =>
      updateTreeData(origin, "barcode_0", packetShipments)
    );
  }, [packetShipment]);

  return (
    <Modal
      title={`Bag Contents (${currentBarcode})`}
      open={showModal}
      onCancel={() => modalClose()}
      width={600}
      footer={null}
      className="hub-show-modal"
      destroyOnClose>
      <Spin
        spinning={loading}
        // indicator={
        //   <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        // }
      >
        <Row gutter={15} className="mb-1">
          {/* <Input
          maxLength={14}
          placeholder="Enter barcode to scan"
          bordered
          style={{
            borderRadius: "5px",
            height: "40px",
            width: "100%",
            margin: "8px 33px 15px 12px",
          }}
          onChange={(e) => {
            if (e.target.value.length > 6) {
              setcurrentScanNum(parseInt(e.target.value));
              handleStatus(e.target.value);
              //navigate("/packet-contents");
            }
          }}
        /> */}

          <Tree loadData={onLoadData} treeData={treeData} />
        </Row>
      </Spin>
    </Modal>
  );
};

export default BagContentModal;
