/* eslint-disable indent */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Spin,
  message,
  Progress,
  Modal,
  Alert,
} from "antd";
import { capitaliseWithHyphen } from "../../../utils";
import { useNavigate, useLocation } from "react-router";
import urls from "../../../api/urls";
import API from "../../../api";
import NormalAPI from "../../../api";
import moment from "moment";
import back_img from "../../../assests/images/rightVector.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import { CreateTripForm } from "./CreateTripForm";
import { EiligibleBagContainer } from "./EiligibleBagContainer";
// import dummyELigibleBag from "./Dummy/dummyEligibleBags.json";

const {
  GET_ALL_VEHICLE_ROUTS,
  GET_ALL_VEHICLES,
  POST_ELIGIBLE_BAGS,
  POST_TRIP_ID,
  GET_VIEW_TRIP,
  LOCK_LCR,
  GET_DRIVER_LIST,
  PUT_DRIVER_DETAILS,
} = urls;
const createTrip = ({
  recoveredTripdata,
  tabTwo,
  btnDsbl,
  tripSchData,
  tripSchIndex,
  travellBy,
  noBagHandle,
  showModal,
  isTripModalOpen,
  optionalTripBtn,
}) => {
  let date = moment();
  let currentDate = date.format("DD/MM/YYYY");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const history = useLocation();
  const { confirm } = Modal;
  const { TextArea } = Input;
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [loading, setLoading] = useState(false);
  const [tripID, setTripID] = useState("");
  const [allEligibleBags, setAllEligibleBags] = useState([]);
  const [driverName, setDriverName] = useState("");
  const [disableButton, setButtonDisable] = useState(false);
  const [disableFieldButton, setButtonFieldDisable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reasonModalOpen, setReasonModalOpen] = useState(false);
  const [vehicleLcr, setVehicleLcr] = useState("");
  const [vehicleWeight, setVehicleWeight] = useState(0);
  const [ewayDisable, setEwayDisable] = useState(false);
  const [scannedBag, setScannedBag] = useState([]);
  const [unScannedBag, setUnScannedBag] = useState([]);
  const [bagIndex, setBagIndex] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [viewTrip, setViewTrip] = useState({});
  const [stopLcr, setStopLcr] = useState([]);
  const [exception, setException] = useState("active");
  const [overLoadStatus, setOverLoadStatus] = useState(false);
  const [eligibleContainer, setEligibleContainer] = useState(true);
  const [routData, setRoutData] = useState([]);
  const [routId, setRoutId] = useState({});
  const [travellByMode, setTravellByMode] = useState("");
  const [reason, setReason] = useState("");
  const [vehicleList, setVehicleList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [getBagButton, setGetBagButton] = useState(false);
  const [singleVehicle, setSingleVehicle] = useState({});
  const [driverId, setDriverId] = useState("");
  const [lcrPayload, setLcrPayLoad] = useState({
    hubUser: `${userId.userInfo.firstName} ${userId.userInfo.lastName}`,
    toDate: currentDate,
    fromHub: userId?.premises?.premiseName,
    toHub: "",
    toPremiseId: 0,
    travelBy: "",
    coloader: "own",
    vehicleNum: "",
    driverName: driverName,
    status: "CREATED",
    lcrShipmemtsDtos: [],
    weight: 0,
  });
  let secSum = 0;
  let barcodeNumber = "";
  let interval;
  const destroyAll = () => {
    Modal.destroyAll();
  };
  const showConfirm = (parentIndex, childIndex) => {
    let allBags = allEligibleBags;
    let sum = 0;
    confirm({
      content: "Vehicle overloaded, Do you wish to proceed ?",
      onOk() {
        destroyAll();
        setOverLoadStatus(true);
      },
      onCancel() {
        percentage > 99 ? setException("exception") : setException("active");
        allBags[parentIndex].barcodesList[childIndex].status = false;
        allBags[parentIndex]?.barcodesList
          ?.filter((item) => item?.status)
          .forEach((e) => (sum += e?.weight));
        // for (let i = 0; i < bag.length; i++) {
        //   sum += bag[i]?.weight;
        // }
        allBags[parentIndex].totalWeight = sum.toFixed(2);
        setAllEligibleBags([...allBags]);
        percentFun();
        Modal.destroyAll();
      },
    });
  };
  const clearFieldValue = () => {
    form.setFieldsValue({
      tripId: "",
      date: "",
      modeOfTransport: "",
      route: "",
      coLoader: "",
      flightNo: "",
      vehicleNo: "",
      driverName: "",
    });
  };
  const tripIdGeneration = async (payload) => {
    let data = tripSchData && tripSchData[tripSchIndex];
    setLoading(true);
    let response = await NormalAPI.Api(POST_TRIP_ID).postIdHeader(payload);
    if (response?.status) {
      const responseData = response?.response?.data?.data;
      setLoading(false);
      setVehicleLcr(responseData.vehicleLcrId);
      setVehicleWeight(data?.loadCapacity);
      setTripID(responseData.tripId);
      createEligibleBag(
        responseData.tripId,
        payload?.travelBy?.toUpperCase() === "SURFACE"
          ? payload?.vehicleNum
          : payload.flightNum
      );
    } else {
      setLoading(false);
      setButtonFieldDisable(true);
      setButtonDisable(true);
      message.error(response?.response?.data?.message);
      clearFieldValue();
    }
  };
  const createEligibleBag = async (tripId, vehicleNum) => {
    setLoading(true);
    let responseEligibleBag = await NormalAPI.Api(
      `${POST_ELIGIBLE_BAGS}${tripId}/vehicle/${vehicleNum}/eligible`
    ).getWithUserNPremiseHeader();
    const responseBag = responseEligibleBag?.response?.data;
    if (responseBag?.length > 0) {
      setLoading(false);
      message.success("Bags fetched successfully");
      setEligibleContainer(true);
      let temp = (responseBag || []).map((item) => ({
        ...item,
        totalWeight: 0,
        buttonDisable: false,
        barcodesList: (item.barcodesList || []).map((barcode) => ({
          ...barcode,
          status: false,
        })),
      }));

      setAllEligibleBags(temp);
      setButtonDisable(true);
      setButtonFieldDisable(true);
      setGetBagButton(true);
    } else {
      setLoading(false);
      message.error(responseBag?.message || "No Bags found for this route");
      noBagHandle();
      setButtonDisable(false);
      setButtonFieldDisable(false);
      setGetBagButton(false);
      if (stopLcr?.length === 0) {
        setViewTrip({});
        if (history?.state?.key === "UPDATE_TRIP") {
          if (Object.keys(viewTrip)?.length === 0) {
            clearFieldValue();
            navigate("/update-trip-list");
          }
        } else {
          clearFieldValue();
        }
      } else {
        showModal(false);
        setButtonDisable(true);
        setButtonFieldDisable(true);
      }
    }
  };
  // ---- //
  const createTripHandle = (e) => {
    let data = tripSchData && tripSchData[tripSchIndex];
    setTripID(e.tripId);
    let surfacePayload = {
      tripId: data?.tripId || tripSchData?.tripId,
      routeId: data?.routeId || routId.value,
      routeName: data?.routeName || routId?.label,
      startDate: data?.scheduleDate || e.date,
      travelBy: data?.mode || e.modeOfTransport,
      vehicleNum: data?.vehicleNo || e.vehicleNo,
      driverName:
        driverName !== "" ? driverName : data?.driverName || e.driverName,
      driverId: data?.driverId || driverId,
    };
    let airPayload = {
      tripId: e.tripId,
      routeId: routId.value,
      routeName: routId?.label,
      startDate: e.date,
      travelBy: e.modeOfTransport,
      flightNum: e.flightNo,
      coloader: e.coLoader,
    };
    if (recoveredTripdata?.premiseId) {
      createEligibleBag(
        recoveredTripdata?.tripId,
        recoveredTripdata?.vehicleNum || recoveredTripdata?.flightNum
      );
      const element = document.getElementById("bag-id");
      element.scrollIntoView({ behavior: "smooth" });
    } else if (history?.state?.key === "UPDATE_TRIP") {
      let data = history?.state?.record;
      createEligibleBag(data?.tripId, data?.vehicleNum || data?.flightNum);
    } else {
      tripIdGeneration(
        e.modeOfTransport.toUpperCase() === "SURFACE"
          ? surfacePayload
          : airPayload
      );
    }

    if (travellBy === "AIR" || e.modeOfTransport.toUpperCase() === "AIR") {
      setLcrPayLoad({
        ...lcrPayload,
        travellBy: "Air",
        vehicleNum: e.flightNo,
        driverName: e.coLoader,
      });
    } else {
      setLcrPayLoad({
        ...lcrPayload,
        travellBy: "Surface",
        vehicleNum: data?.vehicleNo || e.vehicleNo,
        driverName:
          driverName !== "" ? driverName : data?.driverName || e.driverName,
      });
    }
  };

  const getAllRoute = async (mode) => {
    setLoading(true);
    let response = await API.Api(
      GET_ALL_VEHICLE_ROUTS + mode
    ).getWithAllHeaders();
    const routeData = response?.response?.data?.data;
    if (routeData?.length > 0) {
      message.success(response?.response?.data?.message);
      setRoutData(routeData);
      setLoading(false);
      if (mode === "SURFACE") {
        if (history?.state?.key !== "UPDATE_TRIP") getAllVehicles();
      }
    } else {
      form.setFieldsValue({ route: "" });
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  const getAllVehicles = async () => {
    setLoading(true);
    let response = await API.Api(GET_ALL_VEHICLES).getWithPremiseHeader();
    if (response?.status) {
      let list = response?.response?.data;
      setVehicleList(list);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getViewTrip = async (tripId) => {
    setLoading(true);
    let viewTripResponse = await API.Api(
      GET_VIEW_TRIP + tripId
    ).getWithPremiseHeader();
    if (viewTripResponse?.status) {
      setLoading(false);
      let stpLcr = viewTripResponse?.response?.data?.stopLcrs?.filter(
        (data) =>
          data?.viewLCRGeneration?.enable ||
          data?.viewLCRGeneration?.travelBy?.toUpperCase() === "AIR"
      );
      let stoplcrArray = stpLcr;
      stoplcrArray?.forEach((stopLcr) => {
        let stopLcrData = stopLcr?.viewLCRGeneration;
        const { bagList, shipmentLists, childContentList } = stopLcrData;
        stopLcrData.arrOfAwb = [
          ...bagList?.flatMap((bag) => bag?.bagNum),
          ...shipmentLists?.flatMap((shipment) => shipment?.awbNumber),
          ...childContentList?.flatMap(
            (childContent) => childContent?.childBarcode
          ),
        ];
      });
      setViewTrip(viewTripResponse?.response?.data);
      setTripID(viewTripResponse?.response?.data?.tripId);
      setVehicleWeight(viewTripResponse?.response?.data?.loadCapacity);
      setVehicleLcr(viewTripResponse?.response?.data?.vehicleLcrId);
      setStopLcr(stoplcrArray);
    } else {
      setLoading(false);
    }
  };
  const handleGenerateIndex = (idx, destinationHub) => {
    const allBags = allEligibleBags[idx]?.barcodesList || [];
    const bag = allBags.filter((item) => item?.status);
    const unScannedBag = allBags.filter((item) => !item?.status);
    const destId = allEligibleBags[idx]?.destinationId;

    setScannedBag(bag);
    setUnScannedBag(unScannedBag);
    setBagIndex(idx);

    const isAllBagsScanned = bag.length === allBags.length;
    setIsModalOpen(isAllBagsScanned);
    setReasonModalOpen(!isAllBagsScanned);

    const sum = bag.reduce((total, item) => total + (item?.weight || 0), 0);

    const lcrShipment = bag.map((item) => {
      let shipmentType;
      if (item?.type === 4) {
        shipmentType = { awbNumber: item.awbNumber, bag: true };
      } else if (item?.type === 6) {
        shipmentType = { awbNumber: item.awbNumber, childshipment: true };
      } else {
        shipmentType = { awbNumber: item.awbNumber, shipment: true };
      }
      return shipmentType;
    });

    setLcrPayLoad({
      ...lcrPayload,
      toHub: destinationHub,
      lcrShipmemtsDtos: lcrShipment,
      toPremiseId: +destId,
      weight: +sum.toFixed(2),
    });
  };

  const percentFun = (val, parentIndex, childIndex) => {
    const hasEligibleBags = allEligibleBags?.length > 0;
    const totalBagWeight = hasEligibleBags
      ? allEligibleBags.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue?.totalWeight || 0),
          0
        )
      : 0;
    const percentage =
      hasEligibleBags && vehicleWeight !== 0
        ? (totalBagWeight / vehicleWeight) * 100
        : 0;

    const viewTripPercentage = (parseInt(val) / vehicleWeight) * 100;
    setPercentage(
      viewTrip ? +viewTripPercentage.toFixed() : +percentage.toFixed()
    );

    if (+percentage.toFixed() > 99) {
      setException("exception");
      if (travellByMode === "SURFACE") showConfirm(parentIndex, childIndex);
    }
  };

  const scannerHandle = (barcode) => {
    let updatedBags = [...allEligibleBags];

    const parentIndex = updatedBags.findIndex((e) =>
      e?.barcodesList?.some((item) => item?.awbNumber === +barcode)
    );

    const childIndex =
      parentIndex !== -1
        ? updatedBags[parentIndex]?.barcodesList?.findIndex(
            (e) => e?.awbNumber === +barcode
          )
        : -1;

    if (parentIndex !== -1 && childIndex !== -1) {
      updatedBags[parentIndex].barcodesList[childIndex].status = true;

      const bag = updatedBags[parentIndex]?.barcodesList?.filter(
        (item) => item?.status
      );

      const sum = bag.reduce(
        (accumulator, item) => accumulator + (item?.weight || 0),
        0
      );
      updatedBags[parentIndex].totalWeight = sum.toFixed(2);

      setAllEligibleBags(updatedBags);

      const secSum =
        Object.keys(viewTrip).length > 0 ? viewTrip?.utilization : 0;

      const mergedArray = updatedBags.map((data) => data.barcodesList).flat();
      const filterMerge = mergedArray?.filter((val) => val?.status);

      const newSecSum =
        secSum +
        filterMerge.reduce(
          (accumulator, item) => accumulator + (item?.weight || 0),
          0
        );

      percentFun(newSecSum, parentIndex, childIndex);
      message.success("Barcode scanned successfully");
    }
  };

  const handleCallBack = () => {
    setEwayDisable(true);
    let temp = allEligibleBags;
    temp[bagIndex].barcodesList = scannedBag;
    allEligibleBags[bagIndex].buttonDisable = true;
  };

  const ewayUpdateHandle = async () => {
    setLoading(true);
    let lockLcrResponse = await API.Api(`${LOCK_LCR}${tripID}/locked`).put();
    if (history?.state?.key === "UPDATE_TRIP") {
      navigate("/update-trip-list");
      setLoading(false);
    } else if (lockLcrResponse?.status) {
      setLoading(false);
      tabTwo();
    } else {
      setLoading(false);
      message.error(lockLcrResponse?.response?.data?.message);
    }
  };
  // Barcode Scanner
  const handleKeyDown = (event) => {
    if (interval) clearInterval(interval);
    if (event.code == "Enter") {
      if (barcodeNumber) scannerHandle(barcodeNumber);
      barcodeNumber = "";
      return;
    }
    if (event.key != "Shift") barcodeNumber += event.key;
    interval = setInterval(() => (barcodeNumber = ""), 20);
  };

  useEffect(() => {
    form.setFieldValue("date", currentDate);
  }, []);
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [allEligibleBags]);
  useEffect(() => {
    if (recoveredTripdata?.premiseId) {
      setTravellByMode(recoveredTripdata?.travelBy?.toUpperCase());
      setButtonFieldDisable(true);
      setButtonDisable(true);
      form.setFieldsValue({
        modeOfTransport: capitaliseWithHyphen(recoveredTripdata?.travelBy),
        route: capitaliseWithHyphen(recoveredTripdata?.routeName),
        tripId: recoveredTripdata?.tripId,
        vehicleNo: recoveredTripdata?.vehicleNum,
        driverName: recoveredTripdata?.driverName,
        coLoader: recoveredTripdata?.coloader,
        flightNo: recoveredTripdata?.flightNum,
      });

      setDriverName(recoveredTripdata?.driverName);
      getViewTrip(recoveredTripdata?.tripId);
      if (recoveredTripdata?.travelBy?.toUpperCase() === "SURFACE") {
        setLcrPayLoad({
          ...lcrPayload,
          travelBy: recoveredTripdata?.travelBy?.toUpperCase(),
          vehicleNum: recoveredTripdata?.vehicleNum,
          driverName: recoveredTripdata?.driverName,
        });
      } else {
        setLcrPayLoad({
          ...lcrPayload,
          travelBy: recoveredTripdata?.travelBy?.toUpperCase(),
          vehicleNum: recoveredTripdata?.flightNum,
          driverName: recoveredTripdata?.coloader,
        });
        getAllRoute(
          recoveredTripdata?.travelBy?.toUpperCase(),
          recoveredTripdata?.premiseId
        );
      }
    } else {
      setButtonFieldDisable(false);
    }
  }, [recoveredTripdata]);
  useEffect(() => {
    if (history?.state?.key === "UPDATE_TRIP") {
      let data = history?.state?.record;
      setButtonFieldDisable(true);
      setTravellByMode(data?.travelBy?.toUpperCase());
      form.setFieldsValue({
        tripId: data?.tripId,
        date: data?.createdDate?.replaceAll("-", "/").split(" ")[0],
        modeOfTransport: capitaliseWithHyphen(data?.travelBy),
        route: capitaliseWithHyphen(data?.routeName),
        vehicleNo: data?.vehicleNum,
        driverName: data?.driverName,
      });
      getViewTrip(data?.tripId);
      setLcrPayLoad({
        ...lcrPayload,
        travelBy: capitaliseWithHyphen(data?.travelBy),
        vehicleNum: data?.vehicleNum,
        driverName: data?.driverName,
      });
      if (data?.travelBy?.toUpperCase() === "AIR") {
        getAllRoute(data?.travelBy?.toUpperCase(), data?.premiseId);
      }
    }
  }, [history?.state]);

  useEffect(() => {
    if (Object.keys(viewTrip)?.length > 0) {
      secSum += viewTrip?.utilization;
      percentFun(secSum);
    }
    setTripID(viewTrip?.tripId);
  }, [viewTrip]);
  useEffect(() => {
    if (btnDsbl) setButtonDisable(true);
  }, [btnDsbl]);
  useEffect(() => {
    let data =
      travellBy === "SURFACE"
        ? tripSchData && tripSchData[tripSchIndex]
        : tripSchData;

    if (tripSchIndex >= 0 && !recoveredTripdata?.premiseId) {
      form.setFieldsValue({
        tripId: data?.tripId,
        date: data?.scheduleDate,
        modeOfTransport: capitaliseWithHyphen(data?.mode),
        route: capitaliseWithHyphen(data?.routeName),
        vehicleNo: data?.vehicleNo,
        driverName: data?.driverName,
      });
      setLcrPayLoad({
        ...lcrPayload,
        travelBy: capitaliseWithHyphen(travellBy || ""),
        vehicleNum: data?.vehicleNo,
        driverName: data?.driverName,
      });
      setEligibleContainer(false);
      setButtonDisable(true);
      setButtonFieldDisable(true);
    }
  }, [tripSchIndex]);
  useEffect(() => {
    if (isTripModalOpen) {
      clearFieldValue();
      setEligibleContainer(false);
      setStopLcr([]);
      setGetBagButton(false);
      setRoutData([]);
    }
  }, [isTripModalOpen]);

  useEffect(() => {
    if (travellBy === "AIR" && !recoveredTripdata?.premiseId) {
      setStopLcr([]);
      setLcrPayLoad({
        ...lcrPayload,
        travelBy: "Air",
      });
      setFormFieldValues("AIR");
      setEligibleContainer(false);
    } else if (travellBy === "SURFACE" && !recoveredTripdata?.premiseId) {
      setLcrPayLoad({
        ...lcrPayload,
        travelBy: "Surface",
      });
      setFormFieldValues("SURFACE");
      setButtonFieldDisable(false);
    }
  }, [tripSchData]);
  useEffect(() => {
    if (travellBy === "AIR") {
      setButtonFieldDisable(false);
    }
  }, [travellBy]);

  const setFormFieldValues = (travellBy) => {
    setTravellByMode(travellBy);
    getAllRoute(travellBy, userId?.userInfo?.premiseId);
    form.setFieldsValue({
      tripId: tripSchData?.tripId,
      date: currentDate,
      modeOfTransport: capitaliseWithHyphen(tripSchData?.mode),
      route: capitaliseWithHyphen(""),
      vehicleNo: "",
      driverName: "",
    });
    setButtonDisable(false);
  };
  const reasonModalOk = () => {
    setReasonModalOpen(false);
    setIsModalOpen(true);
  };
  const reasonModalCancel = () => {
    setReasonModalOpen(false);
  };
  const vehicelNoHandle = async (e) => {
    let vehicleData = vehicleList?.find((data) => e === data?.vehicleNo);
    setSingleVehicle(vehicleData);

    if (vehicleData?.vehicleNo) {
      form.setFieldValue("route", capitaliseWithHyphen(vehicleData?.routeName));
      setRoutId({
        label: capitaliseWithHyphen(vehicleData?.routeName),
        value: vehicleData?.routeId,
      });
    } else {
      form.setFieldValue("route", null);
    }
    if (vehicleData?.driverName) {
      form.setFieldValue("driverName", vehicleData?.driverName);
      setDriverId(vehicleData?.driverId);
    } else {
      form.setFieldValue("driverName", null);
      // setRoutId({});
    }
    setLoading(true);
    let responseDriver = await API.Api(
      GET_DRIVER_LIST + vehicleData?.vehicleClass
    ).getWithPremiseHeader();
    if (responseDriver?.status) {
      setDriverList(responseDriver?.response?.data);
      setLoading(false);
    } else {
      setLoading(false);
      message.error(responseDriver?.response?.data?.message);
    }
  };
  const driverNameHandle = async (e) => {
    let dName = driverList?.find((data) => data?.driverId === e);
    setDriverName(dName.name);
    setLoading(true);
    setDriverId(e);
    let res = await API.Api(
      `${PUT_DRIVER_DETAILS}${singleVehicle?.vehicleNo}/driver/${e}`
    ).putCustomIdHeader();
    if (res?.status) {
      message.success("Driver assigned successfully");
      setLoading(false);
    } else {
      message.error("Netwok error");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (optionalTripBtn) {
      getAllVehicles();
    }
  }, [optionalTripBtn]);
  return (
    <Spin
      spinning={loading}
      size="large"
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className={history?.state?.key === "UPDATE_TRIP" ? "p-2" : ""}>
        {history?.state?.key === "UPDATE_TRIP" && (
          <div className="align-center mt-1 mb-1 ">
            <img
              src={back_img}
              onClick={() => navigate("/update-trip-list")}
              style={{ cursor: "pointer" }}
            />
            <p
              style={{
                margin: "0",
                paddingLeft: "5px",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "32px",
                letterSpacing: "-0.25px",
                color: "#061624",
              }}
            >
              <b>Update Trip</b>
            </p>
          </div>
        )}

        <div className="lcr-modal-wrapper p-2">
          <CreateTripForm
            form={form}
            createTripHandle={createTripHandle}
            showModal={showModal}
            disableFieldButton={disableFieldButton}
            setRoutId={setRoutId}
            routData={routData}
            capitaliseWithHyphen={capitaliseWithHyphen}
            name={name}
            travellByMode={travellByMode}
            disableButton={disableButton}
            vehicelNoHandle={vehicelNoHandle}
            vehicleList={vehicleList}
            driverNameHandle={driverNameHandle}
            driverList={driverList}
            getBagButton={getBagButton}
          />

          {stopLcr?.length > 0 && (
            <>
              <b className="mt-1">Patched Bags</b>
              <Row gutter={12} className="mt-1">
                {stopLcr?.map((data, index) => (
                  <Col lg={12} md={12} xs={24} xl={8} sm={24} key={index}>
                    <div className="bagOutscanContView p-1">
                      <Row>
                        <Col span={15}>
                          <b>Destination: </b>
                          <span>
                            {capitaliseWithHyphen(
                              data?.viewLCRGeneration?.toHub
                            )}
                          </span>
                        </Col>
                        <Col span={9}>
                          <b>Lcr No: </b>
                          <span>{data?.lcrNum}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={15}>
                          <b>Weight: </b>
                          {data?.viewLCRGeneration?.weight?.toFixed(2) || 0}
                          <span> Kg</span>
                        </Col>
                        <Col span={9}>
                          <b>Mode: </b>
                          {capitaliseWithHyphen(
                            data?.viewLCRGeneration?.travelBy
                          )}
                        </Col>
                      </Row>
                      <p className="mt-2">
                        <b>Bag ID / AWB No</b>
                      </p>
                      <hr className="divider" />
                      <Row gutter={12}>
                        {data?.viewLCRGeneration?.arrOfAwb?.map(
                          (data, index) => (
                            <Col key={index} span={12}>
                              {data}
                            </Col>
                          )
                        )}
                      </Row>
                    </div>
                  </Col>
                ))}
              </Row>
              <div className="d-flex flex-end p-1 footer-btn">
                <Button
                  htmlType="submit"
                  className="btn-style save-btn btn-two"
                  onClick={ewayUpdateHandle}
                >
                  LOCK LCR
                </Button>
              </div>
              <hr className="divider" />
            </>
          )}
          {eligibleContainer && (
            <div>
              {allEligibleBags?.length > 0 ? (
                <Row className="mt-2">
                  <Col span={8}>
                    <Input
                      placeholder="Enter AWB number to scan"
                      onPressEnter={(e) => scannerHandle(e.target.value)}
                    />
                  </Col>
                  {travellByMode === "SURFACE" ? (
                    <Col span={8} offset={8}>
                      <b>Vehicle Weight:</b>
                      <span>&nbsp;{vehicleWeight}&nbsp;</span>
                      <span> Kg</span>
                      <Progress
                        percent={percentage}
                        size="small"
                        status={exception}
                      />
                      {overLoadStatus && (
                        <>
                          <b>Overload Weight:</b>
                          <span>
                            &nbsp;
                            {allEligibleBags?.reduce(
                              (accumulator, currentValue) =>
                                accumulator + +currentValue?.totalWeight,
                              0
                            )}
                            &nbsp;
                          </span>
                          <span> Kg</span>
                        </>
                      )}
                    </Col>
                  ) : null}
                </Row>
              ) : null}

              <EiligibleBagContainer
                allEligibleBags={allEligibleBags}
                capitaliseWithHyphen={capitaliseWithHyphen}
                handleGenerateIndex={handleGenerateIndex}
                status={status}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                lcrPayload={lcrPayload}
                unScannedBag={unScannedBag}
                reason={reason}
                vehicleLcr={vehicleLcr}
                handleCallBack={handleCallBack}
                tripID={tripID}
              />
            </div>
          )}
          {ewayDisable && (
            <div className="d-flex flex-end p-1 footer-btn">
              <Button
                htmlType="submit"
                className="btn-style save-btn btn-two"
                onClick={ewayUpdateHandle}
              >
                LOCK LCR
              </Button>
            </div>
          )}
        </div>
        <Modal
          title="Remark"
          open={reasonModalOpen}
          onOk={reasonModalOk}
          onCancel={reasonModalCancel}
          destroyOnClose
          footer={false}
        >
          <Row gutter={12}>
            {unScannedBag?.length > 0 &&
              unScannedBag?.map((data, index) => (
                <Col span={8} key={index}>
                  {data?.awbNumber}
                </Col>
              ))}
          </Row>
          {unScannedBag?.length > 0 ? <hr className="divider" /> : null}
          <Alert
            message="These bags/shipments will be discarded from the LCR. Please go to the Damage section to take appropriate action."
            type="warning"
            showIcon
            className="mb-1"
          />
          <p>
            <b style={{ color: "red" }}>
              Do you want to proceed without scanning the bags or shipments ?
            </b>
          </p>
          <b>You can add your remarks below.</b>
          <TextArea rows={4} onBlur={(e) => setReason(e.target.value)} />
          <Row className="mt-1" justify="end">
            <div className="d-flex">
              <Col>
                <Button onClick={reasonModalCancel} className="cancel-btn">
                  CANCEL
                </Button>
              </Col>
              <Col className="ml-1">
                <Button onClick={reasonModalOk} className="save-btn">
                  OK
                </Button>
              </Col>
            </div>
          </Row>
        </Modal>
      </div>
    </Spin>
  );
};

export default createTrip;
