import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import { useLocation, useNavigate } from "react-router";
import CreateTrip from "./createTrip";
import ViewTrip from "./ViewTrip";
import back_img from "../../../assests/images/rightVector.svg";
import TripScheduleModal from "./tripScheduleModal";
import "./style.scss";

const LcrTab = () => {
  const history = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState("1");
  const [data, setData] = useState({});
  const [count, setCount] = useState(0);
  const [callViewApi, setCallViewApi] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [btnDsbl, setBtnDsbl] = useState(false);
  const [tripSchData, setTripSchData] = useState([]);
  const [travellBy,setTravellBy] = useState("");
  const [index, setIndex] = useState(null);
  const [optionalTripBtn,setOptionalTripBtn] = useState(false);

  const tabTwo = () => {
    setStep("1");
  };
  const noBagHandle = ()=>{
    setData({});
    setIsModalOpen(true);
  };
  const showModal = (status) => {
    setData({});
    setIsModalOpen(status);
  };
  const optionalTripFun = (e)=>{
    setOptionalTripBtn(e);
  };
  const items = [
    {
      key: "1",
      label: "View Trip",
      children: <ViewTrip handleStep={handleStep} callApi={callViewApi} />,
    },
    {
      key: "2",
      label: "Create Trip",
      children: (
        <CreateTrip
          recoveredTripdata={data}
          callApi={count}
          tabTwo={tabTwo}
          btnDsbl={btnDsbl}
          tripSchData={tripSchData}
          travellBy={travellBy}
          tripSchIndex={index}
          noBagHandle={noBagHandle}
          showModal={showModal}
          isTripModalOpen={isModalOpen}
          optionalTripBtn={optionalTripBtn}
        />
      ),
    },
  ];
  useEffect(() => {
    setCount(1);
    setStep("1");
  }, []);

  useEffect(() => {
    if (!data?.premiseId) if (step === "2") setIsModalOpen(true);
  }, [step]);

  useEffect(() => {
    if (history?.state === "VIEW_TRIP" || history?.state?.key === "HUB_EWAY_BILL") {
      setStep("2");
      setIsModalOpen(false);
    } else {
      setStep("1");
    }
  }, [history]);
  
  function handleStep(params, data, getTripList) {
    setStep("2");
    setData(data);
    getTripList();
  }

  const onChange = (key) => {
    setBtnDsbl(false);
    setIndex(null);
    if (key === "2") {
      setCallViewApi(!callViewApi);
      setTripSchData([]);
      setTravellBy("");
    } 
    setStep(key);
    setData({});
  };
  const modalClickHandle = (status,travellBy,data) => {
    if (status === "SAVE") {
      setStep("2");
      setTripSchData(data);
      setTravellBy(travellBy);
    } else {
      setBtnDsbl(true);
    }
  };
  return (
    <div className="p2 consolidated-tab-cntr">
      <div className="top-wrapper space-between ">
        <div className="heading mb-1 align-center">
          <img
            src={back_img}
            onClick={() => navigate("/create-or-update-trip")}
            style={{ cursor: "pointer" }}
          />
          <p
            style={{
              margin: "0",
              paddingLeft: "5px",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "32px",
              letterSpacing: "-0.25px",
              color: "#061624",
            }}
          >
            <b>Create Trip</b>
          </p>
        </div>
      </div>
      <Tabs
        destroyInactiveTabPane
        defaultActiveKey={"1"}
        activeKey={step}
        items={items}
        onChange={onChange}
      />
      <TripScheduleModal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        modalClickHandle={modalClickHandle}
        setIndex={setIndex}
        optionalTripFun={optionalTripFun}
      />
    </div>
  );
};
export default LcrTab;
