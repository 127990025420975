/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { useState } from "react";
import { message } from "antd";
import API from "../../../api";
import urls from "../../../api/urls";
import { camelCase, capitaliseWithHyphen } from "../../../utils";
import moment from "moment";

const useClientAndDepartment = (userInfo, form, formName = null) => {
  const [loading, setLoading] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [bookingDetails, setBookingDetails] = useState({});
  const [pinCodeServiceableStatus, setPinCodeServiceableStatus] = useState({});
  const [contentDiscriptionData, setContentDiscriptionData] = useState({});
  const [clientDetails, setClientDetails] = useState(null);
  const [setsubmitRespons, setSetsubmitRespons] = useState({});
  const [alertData, setAlertData] = useState(false);

  const [savedReturnAddressList, setSavedReturnAddressList] = useState([]);

  const [reviverNameAndAddress, setReviverNameAndAddress] = useState([]);
  const [reviverStateCity, setReviverStateCity] = useState(null);

  const currentDate = moment();

  const todayDateNTimeFormat = currentDate.format("DD/MM/YYYY hh:mm:ss A");
  const transporterId = "88AABCM9407D1ZS"; // Hardcoded

  const [areaDestinationPremiseId, setAreaDestinationPremiseId] =
    useState(null);

  const [allEwayBills, setAllEwayBills] = useState([
    {
      expiryDate: "",
      ewayBillCreateDate: "",
      ewayBillNumber: "",
    },
  ]);
  const {
    GET_CLIENT_LIST,
    GET_DEPARTMENT_LIST,
    CHECK_PIN_CODE_SERVICEABILITY,
    GET_CONTENTDISCRIPTIN_LIST,
    GET_DESTINATION_PREMISE_ID,
    POST_CREDIT_DATA,
    EWAYBILL,
    ADDRESS_PINCODE,
    RETURN_ADDRESS,
    GET_RECIVER_NAME_ADDRESS,
  } = urls;

  const getClients = async () => {
    setLoading(true);
    let res = await API.Api(GET_CLIENT_LIST).getCustomHeader({
      cpId: userInfo?.premiseId,
    });
    if (res.status) {
      const response = res.response?.data;

      const a = response.map((e) => ({
        label: camelCase(e.clientName),
        value: e.clientId,
      }));
      setClientList(a);

      setLoading(false);
    } else {
      message.error("Something went wrong");
      setLoading(false);
    }
  };

  const getRevicerNameAndAddress = async (type) => {
    setLoading(true);
    let res = await API.Api(GET_RECIVER_NAME_ADDRESS + type).getCustomHeader({
      "PREMISE-ID": userInfo?.premiseId,
    });
    if (res.status) {
      const response = res.response?.data;
      setReviverNameAndAddress(response);

      setLoading(false);
    } else {
      message.error("Something went wrong");
      setLoading(false);
    }
  };

  const getContentDiscriptionData = async () => {
    setLoading(true);
    let res = await API.Api(GET_CONTENTDISCRIPTIN_LIST).getCustomHeader({
      cpId: userInfo?.premiseId,
    });
    if (res.status) {
      const response = res.response?.data;
      setContentDiscriptionData(response);
      setLoading(false);
    } else {
      message.error("Something went wrong");
      setLoading(false);
    }
  };

  const getDepartMent = async (a, clientId) => {
    form.setFieldsValue({
      department: null,
      documentNumber: null,
      receiverAddress: null,
      mobileNumber: null,
      receiverEmail: null,
    });

    setLoading(true);
    let res = await API.Api(GET_DEPARTMENT_LIST + clientId).getCustomHeader({
      cpId: userInfo?.premiseId,
    });
    if (res?.status) {
      const clientOrganizationDetails = res?.response?.data;

      setDepartment(
        clientOrganizationDetails?.clientOrganizationDetails?.departmentName?.map(
          (item) => {
            return { value: item, label: item };
          }
        )
      );

      const deptCheck =
        clientOrganizationDetails?.clientOrganizationDetails?.departmentName;

      if (deptCheck)
        form.setFieldsValue({
          department: deptCheck[0],
        });

      setLoading(false);

      const clientOnboard = res?.response?.data?.clientOnboard;

      setClientDetails({
        clientId: clientOnboard?.clientId,
        clientName: clientOnboard?.clientName,
      });

      const {
        clientName: senderName,
        addressline1: senderAddressLine1,
        addressline2: senderAddressLine2 = "",
        pincode: senderPincode,
        state: senderState,
        primaryNum: senderMobileNumber,
        emailId: senderEmail,
        city: senderCity,
        gstin: senderGst,
        city: areaName,
      } = clientOnboard;

      const addressDetails = {
        senderName,
        senderAddressLine1,
        senderAddressLine2,
        senderPincode,
        senderState,
        senderMobileNumber,
        senderEmail,
        senderCity,
        senderGst,
        areaName,
      };

      setBookingDetails((prevState) => ({
        ...prevState,
        addressDetails,
        clientId: clientOrganizationDetails?.clientId,
      }));
      getReturnAddressList(clientId);
    } else {
      // form.setFieldsValue({
      //   clientName: null,
      // });
      form.setFieldsValue({
        department: null,
      });
      setDepartment([]);
      // setClientDetails(null);
      message.error(res?.response?.data?.message || "Something went wrong");
      setLoading(false);
    }
  };

  const getDeliveryPincode = async (e, fieldName) => {
    form.setFieldsValue({
      areaId: null,
      areaName: null,
      city: null,
      state: null,
      pincode: null,
      returnAreaName: null,
      // receiverAreaId: null,
    });

    setPinCodeServiceableStatus({
      serviceability: null,
      // status: "success",
      msg: null,
      stateCode: null,
      city: null,
    });
    setAreaList([]);
    if (e.target.value.length > 5) {
      setLoading(true);

      let response2 = await API.Api(
        ADDRESS_PINCODE + e.target.value
      ).getCustomHeader();
      if (response2?.status) {
        const res = response2.response.data;

        const serviceability = res?.serviceability.serviceability;
        const city = res?.city;
        const state = res?.stateName;
        const pincode = res?.pincode;
        const status =
          serviceability === "Non-Serviceable" ? "error" : "success";
        const msg =
          serviceability === "Non-Serviceable" ? "Non-Serviceable" : null;

        setPinCodeServiceableStatus({
          serviceability,
          status,
          msg,
          stateCode: res?.stateCode,
          city,
          state,
          pincode,
        });
        if (fieldName == "deliveryPincode") {
          setReviverStateCity({
            city: res?.city,
            state: res?.stateName,
          });
        }
        if (formName === "returnAddressform") {
          const areas =
            serviceability !== "Non-Serviceable"
              ? (res?.areadto || []).map((e) => ({
                  label: capitaliseWithHyphen(e.areaName),
                  value: capitaliseWithHyphen(e.areaName),
                  disabled: e?.areatype == "non-serviceable" ? true : false,
                }))
              : [];

          setAreaList(areas);
        } else {
          const areas =
            serviceability !== "Non-Serviceable"
              ? (res?.areadto || []).map((e) => ({
                  label: capitaliseWithHyphen(e.areaName),
                  value: e.areaId,
                  disabled: e?.areatype == "non-serviceable" ? true : false,
                }))
              : [];
          setAreaList(areas);
        }

        form.setFieldsValue({
          city: capitaliseWithHyphen(res?.city),
          state: capitaliseWithHyphen(res?.stateName),
          pincode: e.target.value,
        });
        setLoading(false);
      } else {
        setPinCodeServiceableStatus({
          serviceability: null,
          status: "error",
          msg: response2?.response?.data?.message,
          stateCode: null,
          city: null,
        });
        setLoading(false);
      }
    }
  };

  const getAreaDestinationPremiseId = async (val, child) => {
    let response = await API.Api(GET_DESTINATION_PREMISE_ID + child?.key).get();
    if (response?.status) {
      setAreaDestinationPremiseId({
        areaDestinationPremiseId: response?.response?.data,
        areaId: child?.key,
      });
      form.setFieldsValue({
        areaName: val,
      });
    } else {
      form.setFieldsValue({
        receiverAreaId: null,
        areaId: null,
        areaName: null,
      });
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
    }
  };

  const submitWithPostDetails = async (payload, values) => {
    setLoading(true);

    let response = await API.Api(POST_CREDIT_DATA).postIdHeader(payload);
    if (response?.status) {
      setLoading(false);
      // setSetsubmitRespons(response);
      setAlertData({
        visible: true,
        message: "Booking successfully completed",
      });
      setPinCodeServiceableStatus({});
    } else {
      message.error(
        response?.response?.data?.message ||
          response?.response?.data ||
          "Network error."
      );
    }
    setLoading(false);
  };

  const eWayBillHandle = async (value, key) => {
    if (value?.length === 12) {
      let response = await API.Api(
        EWAYBILL + "/" + value
      ).getWithPremiseIdHeader();
      if (response?.status) {
        let data = JSON.parse(response?.response?.data?.data);
        const validilityDate = moment(data?.validUpto, "DD/MM/YYYY");
        const todayDateNTime = moment(todayDateNTimeFormat, "DD/MM/YYYY");

        let errorFields = [];

        if (data?.errorCodes) {
          errorFields.push({
            name: ["ewayBill" + key],
            errors: ["Please give correct E-way bill number"],
          });
        }
        if (!data?.errorCodes) {
          if (todayDateNTime.isAfter(validilityDate)) {
            errorFields.push({
              name: ["ewayBill" + key],
              errors: ["E-way bill expired"],
            });
          } else if (data?.transporterId !== transporterId) {
            errorFields.push({
              name: ["ewayBill" + key],
              errors: ["Transporter id is not matching"],
            });
          }
        }

        if (errorFields.length > 0) {
          form.setFields(errorFields);
        } else {
          updateVerifiedEwayBillAtIndex(key, data, value);
          return Promise.resolve(
            message.success("E-way bill request succeeds")
          );
        }
      }
    }
  };

  const updateVerifiedEwayBillAtIndex = (
    indexToUpdate,
    newData,
    ewayBillNumber
  ) => {
    let temp = [...allEwayBills];

    setAllEwayBills(
      temp?.map((field, index) => {
        if (index === indexToUpdate) {
          const updatedItem = {
            ...field,
            expiryDate: moment(
              newData.validUpto,
              "DD/MM/YYYY h:mm:ss A"
            ).format("YYYY-MM-DD HH:mm:ss"),
            ewayBillCreateDate: moment(
              newData.validUpto,
              "DD/MM/YYYY h:mm:ss A"
            ).format("YYYY-MM-DD HH:mm:ss"),
            ewayBillNumber,
          };
          return updatedItem;
        }
        return field;
      })
    );
  };

  const getReturnAddressList = async (cliendID) => {
    let res = await API.Api(RETURN_ADDRESS + cliendID).getWithHeader();
    if (res?.status) {
      let data = res?.response?.data;
      let temp = data?.map((item, i) => ({
        ...item,
        isAddressEnabled: i === 0,
      }));
      setSavedReturnAddressList(temp);

      // setReturnAddress(temp);
    } else {
      message.error(
        res?.response?.data?.message || "Can't fetch return address."
      );
    }
  };

  const updateReturnAddressList = (value) => {
    setSavedReturnAddressList(value);
  };

  const resetCredit = () => {
    setPinCodeServiceableStatus({});
    setAreaList([]);
  };

  return {
    loading,
    clientList,
    department,
    getClients,
    getDepartMent,
    getDeliveryPincode,

    pinCodeServiceableStatus,
    areaList,
    bookingDetails,
    getContentDiscriptionData,
    contentDiscriptionData,
    getAreaDestinationPremiseId,
    areaDestinationPremiseId,
    clientDetails,
    submitWithPostDetails,
    setsubmitRespons,
    setAlertData,
    eWayBillHandle,
    allEwayBills,
    setAllEwayBills,
    alertData,
    savedReturnAddressList,
    setSavedReturnAddressList,
    updateReturnAddressList,
    getRevicerNameAndAddress,
    reviverNameAndAddress,
    reviverStateCity,
    resetCredit,
    // getReturnAddressList,
  };
};

export default useClientAndDepartment;
