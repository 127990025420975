/* eslint-disable no-unused-vars */
import { call } from "redux-saga/effects";

import actions from "../../constants/onboardingActions";
import constants from "../../constants/constants";
import { getDataFromAPI } from "../../utils/sagas";

import { getToken, APIs } from "../../utils/worker";
import { getUserAndPremiseId } from "../../utils";

const getTokenId = getToken() || null;
const authToken = getTokenId || null;

const getUserInfo = getUserAndPremiseId();
const premiseId = getUserInfo?.premiseId;
const userId = getUserInfo?.userId;

//----CLIENT ONBOARD//

export function* patchClientOnboardWorker({ data, id }) {
  const reqParams = {
    url: `${APIs.baseURL}/client-onboard/v1/organization/detail/${id}`,
    successAction: actions.CLIENT_ONBOARD_PATCH_SUCCESS,
    errorAction: actions.CLIENT_ONBOARD_PATCH_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PATCH);
}

export function* getClientOnboardWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/client-onboard/v1/client/details/${data}`,
    successAction: actions.GET_CLIENT_ONBOARD_SUCCESS,
    errorAction: actions.GET_CLIENT_ONBOARD_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getClientPincodeMapping({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/client-onboard/v1/pin-code/${data}`,
    successAction: actions.GET_CLIENT_PINCODE_MAPPING_SUCCESS,
    errorAction: actions.GET_CLIENT_PINCODE_MAPPING_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getStaffOnboardWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/staff-service/v1/staff/${data}`,
    successAction: actions.GET_STAFF_ONBOARD_SUCCESS,
    errorAction: actions.GET_STAFF_ONBOARD_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* updateStaffOnboardWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/staff-service/v1/staff`,
    successAction: actions.UPDATE_STAFF_ONBOARD_SUCCESS,
    errorAction: actions.UPDATE_STAFF_ONBOARD_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PATCH, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}
