import React from "react";
import { Row, Col, Table } from "antd";
import PropTypes from "prop-types";

const TableContainer = ({
  columns,
  dataSource,
  onChange,
  pagination,
  scroll,
  rowKey,
  rowSelection,
  rowClassName,
  loading,
  defaultCurrent,
  current,
}) => {
  return (
    <div>
      <Row>
        <Col span={24}>
          <Table
            dataSource={dataSource}
            loading={loading}
            current={current}
            defaultCurrent={defaultCurrent}
            columns={columns}
            className={"table-head-sticky"}
            onChange={onChange}
            pagination={pagination}
            scroll={scroll}
            rowKey={rowKey || null}
            rowSelection={rowSelection || null}
            rowClassName={rowClassName || null}
          />
        </Col>
      </Row>
    </div>
  );
};

TableContainer.propTypes = {
  history: PropTypes.object,
  data: PropTypes.any,
  current: PropTypes.any,
  defaultCurrent: PropTypes.any,
  columnsData: PropTypes.any,
  classData: PropTypes.any,
  onChange: PropTypes.any,
  pagination: PropTypes.any,
  scroll: PropTypes.any,
  rowKey: PropTypes.any,
  rowSelection: PropTypes.any,
  rowClassName: PropTypes.any,
};

export default React.memo(TableContainer);
