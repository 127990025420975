/* eslint-disable no-unused-vars */
import { Button, Modal, message, Upload, Row, Col } from "antd";
import axios from "axios";
import { APIs, getToken } from "../../../utils/worker";

import React, { useState, useEffect } from "react";
import uploadLogo from "../../../assests/images/bx_upload.svg";
import "./draggerModal.scss";
// import "../../../commonStyles/stepButton.css";
import crossButton from "../../../assests/images/CrossButton.svg";
const { Dragger } = Upload;

const props = {
  name: "files",
  multiple: true,
  showUploadList: true,
  listType: "picture",

  action: `${APIs.baseURL}/file-service/v1/file`,
  className: "upload-list-inline",
  onChange(info) {
    const { status } = info.file;
    // if (status !== "uploading") {
    //   console.log(info.file, info.fileList);
    // }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  // onDrop(e) {
  //   console.log("Dropped files", e.dataTransfer.files);
  // },
};

const DraggerModal = ({
  visible,
  oldAwbNum,
  handleOk = () => {},
  handleCancel = () => {},
}) => {
  const [fileList, setfileList] = useState([]);
  const [file, setfile] = useState("");
  let authToken = getToken();

  const [details, setDetails] = useState();
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userId"))
  );

  const [uploadedFileRes, setUploadedFileRes] = useState([]);

  useEffect(() => {
    setDetails(JSON.parse(localStorage.getItem("details")));
  }, []);

  const handleRemove = (file) => {
    const { confirm } = Modal;
    let config = {
      headers: {
        Authorization: authToken,
      },
    };
    return new Promise((resolve, reject) => {
      confirm({
        title: "Are you sure you want to Delete ?",
        onOk: async () => {
          resolve(true);
          try {
            let res = await axios.delete(
              `${APIs.baseURL}/file-service/v1/file/${file?.response?.data?.id}`,
              config
            );

            if (res) {
              message.success(res.data);
            }
          } catch (error) {
            // message.error(error.data + "world");
          }
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  };

  const beforeUpload = (file) => {
    // console.log(file, "before");

    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (file) {
      let checkSameFile = fileList
        ?.map((val) => val?.name)
        .some((val) => val === file.name);

      if (file.name.includes("jfif")) {
        message.error("File not supported");
        return Upload.LIST_IGNORE;
      }
      if (checkSameFile) {
        message.error("Same file cannot be uploaded again!");
        return Upload.LIST_IGNORE;
      }
      if (checkSameFile) {
        message.error("Same file cannot be uploaded again!");
        return Upload.LIST_IGNORE;
      }
      if (!isLt2M) {
        message.error("file size should be less than 2MB!");
        return Upload.LIST_IGNORE;
      }
      if (!isJpgOrPng) {
        message.error("File not supported");
        return Upload.LIST_IGNORE;
      }
      return isJpgOrPng && isLt2M;
    } else {
      return true;
    }
  };

  // console.log(uploadedFileRes, "res");

  const handleChange = (info) => {
    let allFiles = info?.fileList?.map((val) => val?.response?.data);

    if (info.file.status === "done") {
      message.success(info.file.response.message);
      setUploadedFileRes(allFiles);
    }
    if (info.file.status === "error") {
      message.error("File not supported");
    }
    setfileList(info.fileList);
    setfile(info);
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handlePreview = (file) => {
    // console.log(fileList);
    if (file.response.data.displayName.split(".")[1] == "pdf") {
      window.open(file.response.data.filePath);
    } else {
      let imagePath = uploadedFileRes.filter(
        ({ id }) => file?.response?.data?.id === id
      )[0].filePath;
      setPreviewImage(imagePath);
      setPreviewOpen(true);
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
    }
  };

  // console.log(x, "x");

  return (
    <>
      <Modal
        title="Upload document"
        open={visible}
        // onOk={handleOk}
        width={750}
        onCancel={handleCancel}
        className="dragger-modal"
        maskClosable={false}
        okText={"Ok"}
        closeIcon={<img src={crossButton} />}
        // footer={false}
        footer={[
          <Button key="back" onClick={handleCancel} className="cancelButton">
            CANCEL
          </Button>,
          <Button
            key="submit"
            // type="primary"
            disabled={!fileList.length ? true : false}
            onClick={() => handleOk(fileList)}
            className={fileList.length ? "uploadButton" : "uploadButtonDisable"}
          >
            SUBMIT
          </Button>,
        ]}
      >
        <Modal
          open={previewOpen}
          title={<text style={{ fontSize: "12px" }}>{previewTitle}</text>}
          footer={null}
          onCancel={() => setPreviewOpen(false)}
          width={300}
        >
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal>
        <p className="dragger-heading">Upload files</p>
        <Dragger
          {...props}
          className="modal-dragger"
          maxCount={10}
          onChange={handleChange}
          onRemove={handleRemove}
          beforeUpload={beforeUpload}
          onPreview={handlePreview}
          headers={{
            "SMCS-PREMISE-ID": userInfo?.userInfo?.premiseId,
            awbNumber: oldAwbNum,
            Authorization: authToken,
          }}
        >
          <Row>
            <Col span={7}>
              <img src={uploadLogo} className="Upload_logo" />
            </Col>
            <Col span={1} className="vline"></Col>
            <Col span={16}>
              <p>
                <span>Click to upload</span> or drag and drop i.e. supporting
                documents
              </p>
            </Col>
          </Row>
        </Dragger>
        <p className="ant-upload-hint">
          Supported file type: PDF, JPEG, PNG and Max 10MB file size
        </p>
      </Modal>
    </>
  );
};
export default React.memo(DraggerModal);
