import React from "react";
import { Col, Input, Form, AutoComplete } from "antd";
import { checkNumbervalue } from "../../../utils";

function DoxAndNonDox({
  isNonDox,
  form,
  ewayBillValueCheck,
  shipmentContentOptions,
  handleKeyDown,
}) {
  const lgm = 4;

  const volumCalculation = () => {
    const { length, width, height } = form.getFieldValue();
    const volume = ((+length * +width * +height) / 5000).toFixed(2);
    if (volume) {
      form.setFieldsValue({
        volumatricWeight: volume,
      });
    }
  };

  const autoCompleteOptions = shipmentContentOptions?.map((option) => ({
    value: option?.shipmentContentName,
    label: option?.shipmentContentName,
  }));

  return (
    <>
      {!isNonDox ? (
        <>
          <Col lg={9} sm={9} md={9}>
            <Form.Item
              name="content"
              label="Content"
              rules={[
                {
                  required: true,
                  message: "Select content",
                },
              ]}
            >
              {/* <AutoComplete
                options={autoCompleteOptions}
                placeholder="Select a content"
                optionFilterProp="children"
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              /> */}

              <AutoComplete
                options={autoCompleteOptions}
                placeholder="Select a content"
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                onKeyDown={(e) =>
                  handleKeyDown(e, autoCompleteOptions, "content", "value")
                }
              />
            </Form.Item>
          </Col>
          <Col lg={4} sm={4} md={4}>
            <Form.Item
              name="value"
              label={
                <span className="calculater-container-pikpin">Value (INR)</span>
              }
              rules={[
                {
                  pattern: /^[0-9].*/g,
                  message: "Enter value.",
                },
              ]}
              onKeyPress={(event) => {
                if (event.code == "Enter") {
                  document.getElementById("submit_credit").blur();
                } else if (checkNumbervalue(event) || event.key === ".") {
                  event.preventDefault();
                }
              }}
            >
              <Input
                placeholder="Enter value"
                maxLength={7}
                onChange={(e) =>
                  +e.target.value > 50000
                    ? ewayBillValueCheck(true)
                    : ewayBillValueCheck(false)
                }
              />
            </Form.Item>
          </Col>
          <Col lg={4} sm={4} md={4}>
            <Form.Item
              label="Weight(Gms)"
              name="weight"
              initialValue={50}
              rules={[
                {
                  required: true,
                  message: "Enter weight",
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject();
                    }

                    if (value?.length) {
                      if (value < 50) {
                        return Promise.reject(
                          "Enter a weight of at least 50Gm"
                        );
                      }

                      if (parseInt(value.substring(1, 0)) === 0) {
                        return Promise.reject("Weight should not be 0");
                      }

                      if (value && value?.split(".")?.length > 2) {
                        return Promise.reject(
                          "Please enter only one decimal value"
                        );
                      }
                      if (value > 999.99) {
                        return Promise.reject(
                          "Weight should be less than 1000Gm"
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              onKeyPress={(event) => {
                if (event.code == "Enter") {
                  document.getElementById("submit_credit").blur();
                } else if (checkNumbervalue(event)) {
                  event.preventDefault();
                }

                // const regex = /^\d*\.?\d{0,2}$/;

                // if (regex.test(event.target.value)) {
                //   event.preventDefault();
                // }
              }}
            >
              <Input placeholder="Enter weight" maxLength={7} min={1} />
            </Form.Item>
          </Col>
        </>
      ) : (
        <>
          <Col lg={11} sm={11} md={11}>
            <Form.Item
              name="content"
              label="Content"
              rules={[
                {
                  required: true,
                  message: "Select content",
                },
              ]}
              // onKeyPress={(event) => {
              //   if (checkAlphabets(event)) {
              //     event.preventDefault();
              //   }
              // }}
            >
              <AutoComplete
                options={autoCompleteOptions}
                placeholder="Select a content"
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                onKeyDown={(e) =>
                  handleKeyDown(e, autoCompleteOptions, "content", "value")
                }
              />
            </Form.Item>
          </Col>
          <Col lg={4} sm={6} md={4}>
            <Form.Item
              name="value"
              label={
                <span className="calculater-container-pikpin">Value (INR)</span>
              }
              rules={[
                {
                  required: true,
                  message: "Enter a value",
                },
                {
                  pattern: /^[0-9].*/g,
                  message: "Enter value",
                },
                () => ({
                  validator(rule, value = "") {
                    if (parseInt(value?.toString()?.substring(1, 0)) === 0) {
                      return Promise.reject("Value should not be 0");
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              onKeyPress={(event) => {
                if (event.code == "Enter") {
                  document.getElementById("submit_credit").blur();
                } else if (checkNumbervalue(event) || event.key === ".") {
                  event.preventDefault();
                }
              }}
            >
              <Input
                placeholder="Enter value"
                maxLength={7}
                onChange={(e) =>
                  +e.target.value > 50000
                    ? ewayBillValueCheck(true)
                    : ewayBillValueCheck(false)
                }
              />
            </Form.Item>
          </Col>
          <Col lg={3} sm={6} md={3}>
            <Form.Item
              label={
                <span className="calculater-container-pikpin"> Weight(Kg)</span>
              }
              name="weight"
              rules={[
                {
                  required: true,
                  // maxLength: 3,
                  message: "Enter weight",
                },
                // {
                //   pattern: /^(?!0)(\d+(\.\d{1,2})?)?$/,
                //   message: "Enter weight of at least 1 kg",
                // },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject();
                    }

                    if (parseInt(value?.toString()?.substring(1, 0)) === 0) {
                      return Promise.reject("Enter weight of at least 1 kg");
                    }

                    if (value && value.split(".")?.length > 2) {
                      return Promise.reject("Enter only one decimal value");
                    }

                    if (value > 499.99) {
                      return Promise.reject("Weight should be less than 499kg");
                    }
                    return Promise.resolve();
                  },
                  //validateTrigger: "onSubmit",
                }),
              ]}
              onKeyPress={(event) => {
                if (event.code == "Enter") {
                  document.getElementById("submit_credit").blur();
                } else if (checkNumbervalue(event)) {
                  event.preventDefault();
                }
              }}
            >
              <Input
                placeholder="Enter weight"
                maxLength={6}
                onChange={volumCalculation}
                min={1}
              />
            </Form.Item>
          </Col>

          <Col lg={3} sm={6} md={3}>
            <Form.Item
              label={"Length(Cm)"}
              name="length"
              rules={[
                {
                  required: false,
                  message: "Enter  value",
                },
              ]}
              onKeyPress={(event) => {
                if (event.code == "Enter") {
                  document.getElementById("submit_credit").blur();
                } else if (checkNumbervalue(event) || event.key === ".") {
                  event.preventDefault();
                }
              }}
            >
              <Input
                placeholder="Enter length"
                maxLength={3}
                onChange={volumCalculation}
              />
            </Form.Item>
          </Col>
          <Col lg={3} sm={6} md={3}>
            <Form.Item
              label="Width(Cm)"
              name="width"
              rules={[
                {
                  required: false,
                  message: "Enter  value",
                },
              ]}
              onKeyPress={(event) => {
                if (event.code == "Enter") {
                  document.getElementById("submit_credit").blur();
                } else if (checkNumbervalue(event) || event.key === ".") {
                  event.preventDefault();
                }
              }}
            >
              <Input
                placeholder="Enter width"
                maxLength={3}
                onChange={volumCalculation}
                min={"0"}
              />
            </Form.Item>
          </Col>
          <Col lg={4} sm={6} md={4}>
            <Form.Item
              label={
                <span className="calculater-container-pikpin">Height (Cm)</span>
              }
              name="height"
              rules={[
                {
                  required: false,
                  message: "Enter value",
                },
              ]}
              onKeyPress={(event) => {
                if (event.code == "Enter") {
                  document.getElementById("submit_credit").blur();
                } else if (checkNumbervalue(event) || event.key === ".") {
                  event.preventDefault();
                }
              }}
            >
              <Input
                placeholder="Enter height"
                maxLength={3}
                onChange={volumCalculation}
              />
            </Form.Item>
          </Col>
          <Col lg={lgm} sm={6}>
            <Form.Item label="Vol weight (Kg)" name="volumatricWeight">
              <Input readOnly={true} type="number" />
            </Form.Item>
          </Col>
        </>
      )}
    </>
  );
}

export default DoxAndNonDox;
