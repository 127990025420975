/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Table, Spin } from "antd";
import Heading from "../../../components/Heading";
import API from "../../../api";
import urls from "../../../api/urls";
const { VEHICLE_CHECKIN_CHECKOUT_REPORT } = urls;
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import { capitaliseWithHyphen } from "../../../utils";

const VehicleCkinCkout = () => {
  const [dataSource, setDataSource] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);
  const [transitList, setTransitList] = useState([]);
  let transits = 7;

  useEffect(() => {
    getReportList();
  }, []);
  const getReportList = async () => {
    setLoading(true);
    let response = await API.Api(
      VEHICLE_CHECKIN_CHECKOUT_REPORT
    ).getWithAllHeaders();
    let data = response?.response?.data;
    let temp = [];
    data.map((e, i) => {
      let transitList = {};
      for (let i = 1; i < e.inRouteHubs.length - 1; i++) {
        transitList = {
          ...transitList,
          [`transit${i}`]: capitaliseWithHyphen(e.inRouteHubs[i]?.hubName),
          [`actualCheckIn${i}`]: e.inRouteHubs[i].actualCheckIn || "----",
          [`manualCheckIn${i}`]: e.inRouteHubs[i].manualCheckIn || "----",
          [`actualCheckOut${i}`]: e.inRouteHubs[i].actualCheckOut || "----",
          [`manualCheckOut${i}`]: e.inRouteHubs[i].manualCheckOut || "----",
        };
      }
      let length = e.inRouteHubs.length - 1;
      temp.push({
        key: i + 1,
        tripId: e.tripId,
        vehicleNum: e.vehicleNum,
        sourceName: capitaliseWithHyphen(e.inRouteHubs[0]?.hubName),
        actualCheckOut0: e.inRouteHubs[0]?.actualCheckOut || "----",
        manualCheckOut0: e.inRouteHubs[0]?.manualCheckOut || "----",
        destinstion: capitaliseWithHyphen(e.inRouteHubs[length]?.hubName),
        actualCheckInEnd: e.inRouteHubs[length]?.actualCheckIn || "----",
        manualCheckInEnd: e.inRouteHubs[length]?.manualCheckIn || "----",
        ...transitList,
      });
    });
    let transitColumn = [];
    for (let i = 0; i < transits; i++) {
      transitColumn.push([
        {
          title: `Transit ${i + 1}`,
          dataIndex: `transit${i + 1}`,
          key: `transit${i + 1}`,
        },
        {
          title: "Check-In",
          children: [
            {
              title: "HUB",
              dataIndex: `actualCheckIn${i + 1}`,
              key: `actualCheckIn${i + 1}`,
            },
            {
              title: "Manual",
              dataIndex: `manualCheckIn${i + 1}`,
              key: `manualCheckIn${i + 1}`,
            },
          ],
        },
        {
          title: "Check-Out",
          children: [
            {
              title: "HUB",
              dataIndex: `actualCheckOut${i + 1}`,
              key: `actualCheckOut${i + 1}`,
            },
            {
              title: "Manual",
              dataIndex: `manualCheckOut${i + 1}`,
              key: `manualCheckOut${i + 1}`,
            },
          ],
        },
      ]);
    }
    const mergeAllColumn = transitColumn.flat(1);
    setTransitList([...mergeAllColumn]);
    setDataSource([...temp]);
    setLoading(false);
  };

  const columns = [
    {
      title: "Sl No",
      dataIndex: "key",
      key: "key",
      align: "center",
    },
    {
      title: "Trip ID",
      dataIndex: "tripId",
      key: "tripId",
    },
    {
      title: "Vehicle No.",
      dataIndex: "vehicleNum",
      key: "vehicleNum",
    },
    {
      title: "Source",
      dataIndex: "sourceName",
      key: "sourceName",
    },
    {
      title: "Check-Out",
      children: [
        {
          title: "Auto",
          dataIndex: "actualCheckOut0",
          key: "actualCheckOut0",
        },
        {
          title: "Manual",
          dataIndex: "manualCheckOut0",
          key: "manualCheckOut0",
        },
      ],
    },

    {
      title: "Destination",
      dataIndex: "destinstion",
      key: "destination",
    },
    {
      title: "Check-In",
      children: [
        {
          title: "Auto",
          dataIndex: "actualCheckInEnd",
          key: "actualCheckIn",
        },
        {
          title: "Manual",
          dataIndex: "manualCheckInEnd",
          key: "manualCheckIn",
        },
      ],
    },
    ...transitList,
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  function handlePageChange(pagination) {
    setPageNum(pagination.current);
    setPageSize(pagination.pageSize);
  }

  return (
    // <Spin spinning={loading}>
    <div className="p-2 table-border">
      <Heading title={"Vehicle Check-In Check-Out"} />
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: pageSize,
          itemRender: itemRender,
          current: pageNum,
        }}
        scroll={{
          x: 5000,
        }}
        loading={{
          indicator: (
            <img
              src={Smcs_Loader}
              style={{ height: "100px", width: "100px" }}
            />
          ),
          spinning: loading,
        }}
        onChange={handlePageChange}
      />
    </div>
    // </Spin>
  );
};
export default VehicleCkinCkout;
