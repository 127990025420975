/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, message, Spin } from "antd";
import API from "../../../../../api";
import urls from "../../../../../api/urls";
import {
  capitaliseWithHyphen,
  checkNumbervalue,
  checkSpace,
} from "../../../../../utils";

const { GET_ALL_HUBS, LOADING_UNLOADING, GET_ALL_LOAD_UNLOAD, UPDATE_LOAD } =
  urls;
function LoadingAndUnloading() {
  const [form] = Form.useForm();
  const [Loading, setLoading] = useState(false);
  const [allHubs, setallHubs] = useState([]);
  const [allLoadUnload, setAllLoadUnload] = useState([]);
  const [onChangeDate, setOnChangeDate] = useState({});

  useEffect(() => {
    getAllHubs();
    getTableData();
  }, []);

  const filterData = () => {
    let filtered = [];
    allLoadUnload.filter(function (newData) {
      return allHubs.filter(function (oldData) {
        if (newData.hubName !== oldData.premiseName) {
          filtered.push({
            premiseName: oldData.premiseName,
            premiseId: oldData.premiseId,
          });
        }
      });
    });
  };

  const getAllHubs = async () => {
    setLoading(true);
    let response = await API.Api(GET_ALL_HUBS).get();

    if (response?.status) {
      setLoading(false);
      let data = response?.response?.data?.hubNames;
      setallHubs(data);
    } else {
      setLoading(false);
    }
  };

  const submitData = async () => {
    let b = allHubs.find((a) => a?.premiseName === onChangeDate?.hubName);
    let payload = {
      ...onChangeDate,
      premiseId: b?.premiseId,
    };

    let response = await API.Api(LOADING_UNLOADING).post(payload);
    if (response.status) {
      getTableData();
      form.setFieldValue("hubName", null);
      form.setFieldValue("duration", null);
      form.setFieldValue("vehicleFeet", null);
      form.setFieldValue("loadCapacity", null);
      form.setFieldValue("vehicleType", null);
      setOnChangeDate({});
      message.success("Hub added successfully");
    } else {
      message.error(response?.response?.data?.message);
    }
  };

  const getTableData = async () => {
    setLoading(true);
    let response = await API.Api(GET_ALL_LOAD_UNLOAD).get();

    if (response?.status) {
      setLoading(false);
      let data = response?.response?.data;
      let temp = data.map((e) => ({ ...e, isEdit: true }));
      setAllLoadUnload(temp);
      filterData();
    } else {
      setLoading(false);
    }
  };
  const onChangehandler = (name, e) => {
    setOnChangeDate({ ...onChangeDate, [name]: e });
  };

  const inputHandler = (e, i) => {
    let arr = allLoadUnload.map((item) => {
      return item.id === i ? { ...item, duration: +e } : item;
    });
    setAllLoadUnload(arr);
  };

  const onEdit = (id) => {
    let arr = allLoadUnload.map((e) => {
      if (id == e?.id) {
        return { ...e, isEdit: false };
      } else {
        return { ...e, isEdit: true };
      }
    });
    setAllLoadUnload(arr);
  };

  const onSave = async (data) => {
    let payload;
    if (data?.premiseId !== null) {
      payload = {
        hubName: data?.hubName,
        duration: data?.duration,
        premiseId: data?.premiseId,
      };
    } else {
      payload = {
        hubName: data?.hubName,
        duration: data?.duration,
      };
    }

    let response = await API.Api(UPDATE_LOAD + data?.id).putCustomHeader(
      payload
    );
    if (response.status) {
      getTableData();
      setOnChangeDate({});
      message.success("Date Updated Successfully");
    } else {
      message.error("");
    }
  };

  const onClear = () => {
    form.setFieldValue("hubName", null);
    form.setFieldValue("duration", null);
    form.setFieldValue("vehicleFeet", null);
    form.setFieldValue("loadCapacity", null);
    form.setFieldValue("vehicleType", null);
    setOnChangeDate({});
  };

  let btnstyle = {
    marginTop: "30px",
    minWidth: "130px",
  };
  return (
    <>
      <Spin spinning={Loading}>
        <Form
          form={form}
          name="basicee"
          className="global-form"
          layout="vertical"
          // onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={20} className="p-2 global-form-row">
            <Col lg={8}>
              <Form.Item
                label="Vehicle type"
                name="vehicleType"
                rules={[
                  {
                    required: true,
                    message: "Please enter vehicle type",
                  },
                ]}
              >
                <Input
                  placeholder="Enter vehicle type"
                  onChange={(e) =>
                    onChangehandler("vehicleType", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item
                name="vehicleFeet"
                label="Vehicle feet"
                // {...config}
                rules={[
                  {
                    required: true,
                    message: "Please enter vehicle feet",
                  },
                ]}
                onKeyPress={(event) => {
                  if (checkNumbervalue(event) || checkSpace(event)) {
                    event.preventDefault();
                  }
                }}
              >
                <Input
                  placeholder="Enter vehicle feet"
                  maxLength={3}
                  onChange={(e) =>
                    onChangehandler("vehicleFeet", +e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item
                name="loadCapacity"
                label="Vehicle capacity (Kg)"
                // {...config}
                rules={[
                  {
                    required: true,
                    message: "Please enter vehicle capacity",
                  },
                ]}
                onKeyPress={(event) => {
                  if (checkNumbervalue(event) || checkSpace(event)) {
                    event.preventDefault();
                  }
                }}
              >
                <Input
                  placeholder="Enter vehicle capacity"
                  maxLength={5}
                  onChange={(e) =>
                    onChangehandler("loadCapacity", +e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item
                name="duration"
                label="Duration (Minutes)"
                rules={[
                  {
                    required: true,
                    message: "Please enter duration",
                  },
                ]}
                onKeyPress={(event) => {
                  if (checkNumbervalue(event) || checkSpace(event)) {
                    event.preventDefault();
                  }
                }}
              >
                <Input
                  placeholder="Enter time"
                  maxLength={3}
                  onChange={(e) => onChangehandler("duration", +e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col
              lg={4}
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "10px",
              }}
            >
              <Button
                type="primary"
                className="save-btn"
                form="basic"
                style={btnstyle}
                onClick={submitData}
                disabled={
                  Object.keys(onChangeDate).length !== 4 ||
                  onChangeDate?.duration == "" ||
                  onChangeDate?.hubName == ""
                    ? true
                    : false
                }
              >
                SAVE{" "}
              </Button>
              <Button
                className="clear-btn-restriction"
                style={btnstyle}
                onClick={onClear}
              >
                CANCEL
              </Button>
            </Col>
          </Row>
          {allLoadUnload.length > 0 && (
            <div className="price-states-container ml-2 mb-2">
              <Row className="state-price-col">
                <Col lg={14}>
                  <Row>
                    <Col lg={8} className="pl-2">
                      Vehicle Type
                    </Col>
                    <Col lg={8} className="pl-2">
                      Vehicle Feet
                    </Col>
                    <Col lg={8} className="pl-2">
                      Vehicle capacity
                    </Col>
                  </Row>
                </Col>
                <Col lg={10}>
                  <Row>
                    <Col lg={16} className="pl-2">
                      Duration (in minutes)
                    </Col>
                    <Col lg={8} className="pl-2">
                      Action{" "}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div style={{ maxHeight: "400px", overflow: "scroll" }}>
                {allLoadUnload.map((i, index) => (
                  <Row
                    key={index}
                    className="price-states-form-row"
                    style={{
                      border: "1px solid #DDDDDD",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Col lg={14}>
                      <Row>
                        <Col lg={8} className="pl-2 ">
                          <span className="price-states-heading">
                            {capitaliseWithHyphen(i?.vehicleType)}
                          </span>
                        </Col>
                        <Col lg={8} className="pl-2 ">
                          <span className="price-states-heading">
                            {capitaliseWithHyphen(i?.vehicleFeet)}
                          </span>
                        </Col>
                        <Col lg={8} className="pl-2 ">
                          <span className="price-states-heading">
                            {capitaliseWithHyphen(i?.loadCapacity)}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={10}>
                      <Row>
                        <Col lg={16} className="pl-2">
                          {" "}
                          <Form.Item
                            name={i?.stateName}
                            onKeyPress={(event) => {
                              if (
                                checkNumbervalue(event) ||
                                checkSpace(event)
                              ) {
                                event.preventDefault();
                              }
                            }}
                            rules={[
                              {
                                pattern: /^\d{1,10}(\.\d{0,2})?$/,
                                message: "Only two decimal values allowed",
                              },
                            ]}
                          >
                            <Input
                              placeholder="0"
                              maxLength={8}
                              value={i?.duration}
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                              disabled={i?.isEdit}
                              onChange={(e) =>
                                inputHandler(e.target.value, i.id)
                              }
                            />
                          </Form.Item>
                        </Col>{" "}
                        <Col
                          lg={8}
                          className="pl-1"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {i?.isEdit ? (
                            <Button type="link" onClick={() => onEdit(i.id)}>
                              Edit
                            </Button>
                          ) : (
                            <Button
                              type="primary"
                              size="small"
                              onClick={() => onSave(i)}
                            >
                              Save
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
          )}
        </Form>
      </Spin>
    </>
  );
}

export default LoadingAndUnloading;
