/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { capitaliseWithHyphen } from "../../../utils";
import {
  Row,
  Col,
  Button,
  Form,
  Select,
  message,
  // eslint-disable-next-line no-unused-vars
  Input,
  Spin,
  Checkbox,
  Dropdown,
  Modal,
  Popconfirm,
  Radio,
  Tooltip,
  Space,
} from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getHubList,
  createSortingGuide,
  //getHubBasedSortingGuide,
  clearSortingGuide,
  getAllSortingGuide,
} from "../../../actionsMethods/masterMethods";
import delete_cage from "../../../assests/images/master/delete-cage.svg";
import sorting_filter from "../../../assests/images/master/sorting-filter.svg";
import hub_cross from "../../../assests/images/master/hub-cross.svg";
import box_plus from "../../../assests/images/master/box-plus.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import axios from "axios";
import urls from "../../../api/urls";
// eslint-disable-next-line no-unused-vars
import { getToken, APIs } from "../../../utils/worker";
import "./style.scss";
const {
  UPDATE_SORTING_GUIDE,
  GET_SORTING_GUIDE_DETAIL,
  GET_SORTING_NAME_BASED_DETAIL,
} = urls;

const CreateSorting = ({ editId, changeTab, showPopUp, noPopup }) => {
  let token = getToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [sortingRules, setSortingRules] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [hubList, setHubList] = useState([]);
  const [selectHubList, setSelectHubList] = useState([]);
  const [originalSelectHubList, setOriginalSelectHubList] = useState([]);
  const [designationHubList, setDesignationHubList] = useState([]);
  const [selectedDesignatedId, setSelectedDesignatedId] = useState([]);
  const [currentBox, setCurrentBox] = useState(null);
  const [originHub, setOriginHub] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const [selectSortType, setSelectSortType] = useState("none");
  const [showExistingSort, setShowExistingSort] = useState(false);
  const [selectExistingSortList, setSelectExistingSortList] = useState([]);
  const [editSortDefault, setEditSortDefault] = useState(null);
  const hubData = useSelector(
    (state) => state.masterReducer.getHubListReducer,
    shallowEqual
  );

  const { result: hubResult, error: hubErr, fetching: loadingHub } = hubData;

  const createSortingGuideData = useSelector(
    (state) => state.masterReducer.createSortingGuideReducer,
    shallowEqual
  );

  const {
    result: createSortingResult,
    error: createSortingErr,
    fetching: loading1,
  } = createSortingGuideData;

  const allSorting = useSelector(
    (state) => state.masterReducer.getAllSortingGuideReducer,
    shallowEqual
  );

  const { result: allSortResult, error: AllErr } = allSorting;

  useEffect(() => {
    editId ? "" : dispatch(getAllSortingGuide());
  }, []);

  useEffect(() => {
    if (allSortResult) {
      let allSortTemp = [];
      allSortResult.map((e) => {
        allSortTemp.push({
          value: e.premiseId,
          label: capitaliseWithHyphen(e.name),
        });
      });
      setSelectExistingSortList(allSortTemp);
    }
  }, [allSortResult]);

  useEffect(() => {
    if (hubResult) {
      let hubResultTemp = hubResult;
      let userData = JSON.parse(localStorage.getItem("userinfo"));
      let userid = JSON.parse(localStorage.getItem("userId"));
      let userRole = userData?.roles?.[0];

      //setSortingIsUpdated(hubBasedSortResult?.isUpdated && userData?.roles?.[0] == "ROLE_HUBADMIN");

      if (userRole == "ROLE_HUBADMIN" && editId === undefined) {
        let currentHub = hubResultTemp.find(
          (e) => e.premiseId == userid?.userInfo?.premiseId
        );
        hubResultTemp = [currentHub];
        var temp1 = [];
        hubResultTemp
          .sort((a, b) => a.premiseName.localeCompare(b.premiseName))
          .map((e) => {
            let zone = e.zone ? e.zone : "N/A";
            temp1.push({
              value: e.premiseId,
              label: `${capitaliseWithHyphen(
                e.premiseName
              )} (${capitaliseWithHyphen(zone)})`,

              zone: e.zone ? e.zone : "N/A",
            });
          });
      }
      let temp = [];
      hubResult
        .sort((a, b) => a.premiseName.localeCompare(b.premiseName))
        .map((e) => {
          let zone = e.zone ? e.zone : "N/A";
          temp.push({
            value: e.premiseId,
            label: `${capitaliseWithHyphen(
              e.premiseName
            )} (${capitaliseWithHyphen(zone)})`,
            zone: e.zone ? e.zone : "N/A",
          });
        });
      // setSelectExistingSortList(temp);
      setHubList(
        userRole == "ROLE_HUBADMIN" && editId === undefined ? temp1 : temp
      );
      setOriginalSelectHubList(temp);
      // eslint-disable-next-line no-empty
      if (editId) {
      } else {
        setSelectHubList(temp);
        setDesignationHubList(temp);
      }
    }
    if (hubErr) {
      message.error(hubErr.message);
    }
  }, [hubResult, hubErr]);
  // useEffect(() => {
  //   localStorage.setItem("existingSortId", null);
  // }, []);
  useEffect(() => {
    if (createSortingResult) {
      message.success("Created successfully.");
      dispatch(clearSortingGuide());
      changeTab();
    }
    if (createSortingErr) {
      message.error(createSortingErr?.message);
      dispatch(clearSortingGuide());
    }
  }, [createSortingResult, createSortingErr]);

  useEffect(() => {
    dispatch(getHubList());
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-empty
    if (editId) {
      noPopup();
    } else if (form.getFieldValue("sendToDestination")) {
      showPopUp();
    } else {
      noPopup();
    }
  }, [form.getFieldValue("sendToDestination")]);

  useEffect(() => {
    if (editId && hubResult) {
      getSortingEditData(editId);
    } else {
      setSortingData();
    }
  }, [loadingHub]);

  const getSortingEditData = async (id) => {
    setLoading(true);
    axios
      .get(GET_SORTING_NAME_BASED_DETAIL + id, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        let hubBasedSortResult = res?.data;
        if (editId) {
          form.setFieldValue(
            "sendToDestination",
            hubBasedSortResult?.premiseId
          );
          form.setFieldValue(
            "numberOfColumns",
            hubBasedSortResult?.numberOfColumns || 4
          );
          setOriginHub(hubBasedSortResult?.premiseId);
        }
        let temp1 = hubList.filter(
          (item) => item.value !== hubBasedSortResult?.premiseId
        );
        let allSelectedHub = [];
        hubBasedSortResult?.sortingGuide?.map((item) => {
          item.sendToDestinationList.map((e) => {
            allSelectedHub.push(e);
          });
        });
        let uniqueHub = allSelectedHub.filter(
          (item, i, ar) => ar.indexOf(item) === i
        );
        let tempUniqueHub = temp1.filter(
          (item) => !uniqueHub.includes(item.value)
        );
        setSelectHubList([...tempUniqueHub]);
        setDesignationHubList([...temp1]);
        let sortingData = [];
        let length = hubBasedSortResult?.sortingGuide.length;
        let cageNo = hubBasedSortResult?.sortingGuide[length - 1].cageNumber;

        for (let i = 1; i < cageNo + 1; i++) {
          sortingData.push({
            sortingPremiseId: 0,
            cageNumber: i,
            sendToDestinationList: [],
            sendToDestination: null,
          });
        }
        let temp = hubBasedSortResult?.sortingGuide;
        temp.map((e, index) => {
          if (e.cageNumber === sortingData[e.cageNumber - 1].cageNumber) {
            sortingData[e.cageNumber - 1].sortingPremiseId =
              temp[index].sortingPremiseId;
            sortingData[e.cageNumber - 1].sendToDestinationList =
              temp[index].sendToDestinationList;
            sortingData[e.cageNumber - 1].sendToDestination =
              temp[index].sendToDestination;
          }
        });
        setSortingRules([...sortingData]);
        setLoading(false);
      })
      .catch((err) => {
        setSortingData();
        setLoading(false);
      });
  };

  const setSortingData = () => {
    let sortingData = [];
    let cageNo = 12;
    for (let i = 1; i < cageNo + 1; i++) {
      sortingData.push({
        sortingPremiseId: 0,
        cageNumber: i,
        sendToDestinationList: [],
        sendToDestination: null,
      });
    }
    setSortingRules(sortingData);
  };

  const setDefaultSortData = () => {
    let temp = hubList.filter((item) => item.value !== originHub);
    let sortingData = [];
    temp.map((e, i) => {
      sortingData.push({
        sortingPremiseId: 0,
        cageNumber: i + 1,
        sendToDestinationList: [e.value],
        sendToDestination: e.value,
      });
    });
    setSortingRules(sortingData);
    setSelectHubList([]);
  };

  const items = [
    {
      label: <span onClick={() => filterHub("label")}>Alphabetical Order</span>,
      key: "1",
      style: { fontSize: "14px" },
    },
    {
      label: <span onClick={() => filterHub("zone")}>Sort by Zone</span>,
      key: "2",
      style: { fontSize: "14px" },
    },
  ];

  //Cage Feature//
  const addNewCage = () => {
    let sortingData = sortingRules;
    sortingData.push({
      sortingPremiseId: 0,
      cageNumber: sortingRules[sortingRules.length - 1].cageNumber + 1,
      sendToDestinationList: [],
      sendToDestination: null,
    });
    setSortingRules([...sortingData]);
  };

  const deleteSingleCage = (id) => {
    //setSelectedDesignatedId([]);
    //setCheckedList([]);
    setCurrentBox();
    let x = sortingRules.find((e) => e.cageNumber == id);
    let hubTemp = selectHubList;
    originalSelectHubList.map((e) => {
      if (x.sendToDestinationList.includes(e.value)) {
        hubTemp.push(e);
      }
    });
    setSelectHubList([
      ...hubTemp.sort((a, b) => a.label.localeCompare(b.label)),
    ]);
    setLoading(true);
    let sortingData = sortingRules.filter((e) => e.cageNumber !== id);
    let temp = [];
    sortingData.map((e, i) => {
      temp.push({
        sortingPremiseId: e.sortingPremiseId,
        cageNumber: i + 1,
        sendToDestinationList: e.sendToDestinationList,
        sendToDestination: e.sendToDestination,
      });
    });
    setSortingRules([...temp]);
    message.success("Deleted successfully.");
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const selectDestinationBoxId = (e, id) => {
    let temp = sortingRules;
    let index = sortingRules.findIndex((item) => item.cageNumber == id);
    temp[index].sendToDestination = e;
    setSortingRules([...temp]);
  };

  //--End--//

  //Side Hub Selection Feature//

  const filterHub = (e) => {
    setSelectedDesignatedId([]);
    setCheckedList([]);
    let temp = selectHubList.sort((a, b) => a[e].localeCompare(b[e]));
    setSelectHubList([...temp]);
  };

  const selectOriginHub = (e, type) => {
    setSortingData();
    setCheckedList([]);
    setOriginHub(e);
    let temp = originalSelectHubList.filter((item) => item.value !== e);
    let existingSortTemp = selectExistingSortList.filter(
      (item) => item.value !== e
    );
    editId ? "" : setSelectExistingSortList([...existingSortTemp]);
    setSelectHubList([...temp]);
    setDesignationHubList([...temp]);
    setSelectSortType(type === "setDefault" ? "default" : "none");
    setShowExistingSort(false);
  };

  const changeCheckBox = (e) => {
    setCheckedList(e);
    setSelectedDesignatedId(e);
  };

  const addToSelectedBox = () => {
    setLoading(true);
    let temp = sortingRules;
    let index = sortingRules.findIndex((e) => e.cageNumber == currentBox);

    // temp[index].sendToDestinationList =
    //   temp[index].sendToDestinationList.concat(selectedDesignatedId);
    temp[index].sendToDestinationList = [
      ...selectedDesignatedId,
      ...temp[index].sendToDestinationList,
    ];
    setSortingRules([...temp]);
    setCurrentBox();
    let hubTemp = [];
    selectHubList.map((e) => {
      if (!selectedDesignatedId.includes(e.value)) {
        hubTemp.push(e);
      }
    });
    setSelectHubList([...hubTemp]);
    setTimeout(() => {
      setLoading(false);
    }, 200);
    setSelectedDesignatedId([]);
    setCheckedList([]);
  };

  //--End--//

  //Modal Feature//
  const showBoxDetailsModal = (e) => {
    let temp = sortingRules.find((item) => item.cageNumber == e);
    // let selectedHubList = hubList.filter((item) =>
    //   temp.sendToDestinationList.includes(item.value)
    // );
    let selectedHubList = temp.sendToDestinationList.map((e) =>
      hubList.find((item) => e == item.value)
    );
    setModalContent({
      show: true,
      currentBox: `Box ${e} Details`,
      selectedHubList,
      id: e,
    });
  };

  const cancelBoxModal = () => {
    setModalContent({
      show: false,
      currentBox: null,
    });
  };

  const clearHubModal = (id, e) => {
    let temp = sortingRules;
    let index = temp.findIndex((item) => item.cageNumber === id);
    let tempSortingRule = temp[index].sendToDestinationList;
    let designationIdIndex = tempSortingRule.indexOf(e.value);
    if (designationIdIndex > -1) {
      tempSortingRule.splice(designationIdIndex, 1);
    }
    temp[index].sendToDestinationList = tempSortingRule;
    setSortingRules([...temp]);
    let hubListTemp = selectHubList;
    hubListTemp.push(e);
    hubListTemp.sort((a, b) => a.label.localeCompare(b.label));
    setSelectHubList([...hubListTemp]);

    let tempModalContent = modalContent;
    tempModalContent = tempModalContent.selectedHubList.filter(
      (item) => item.value !== e.value
    );
    setModalContent({ ...modalContent, selectedHubList: tempModalContent });
    if (tempModalContent.length == 0) {
      cancelBoxModal();
    }
  };

  //--End--//

  const cancel = (e) => {};
  const selectSortingType = (e) => {
    setSortingData();
    selectOriginHub(originHub);
    setShowExistingSort(false);
    setSelectSortType(e.target.value);
    switch (e.target.value) {
      case "default":
        {
          setDefaultSortData();
        }
        break;
      case "exitingSorting":
        {
          setShowExistingSort(true);
          setSelectSortType(e.target.value);
        }
        break;
    }
  };

  const selectSingleSort = async (e) => {
    getSortingEditData(e);
  };

  const createSorting = async (values) => {
    const userInfo = JSON.parse(localStorage.getItem("userId"))?.userInfo;
    let sortingPresent = sortingRules.some(
      (e) => e.sendToDestinationList.length > 0
    );
    if (selectHubList.length > 0) {
      message.error("All hubs are not mapped.");
    } else {
      if (!sortingPresent) {
        message.error("No sorting created.");
      }
      let temp = sortingRules.filter(
        (e) => e.sendToDestinationList.length > 0 && e.sendToDestination == null
      );

      if (temp.length > 0) {
        message.error(
          <span>
            Please select destination of{" "}
            {temp.map((e, index) => {
              return (
                <span
                  key={index}
                  className="fw-500"
                  style={{ color: "orange" }}
                >
                  Box {e.cageNumber}{" "}
                </span>
              );
            })}{" "}
          </span>
        );
      } else {
        let checkDuplicateDestination = [];
        let checkDuplicateDestinationCage = [];
        sortingRules.map((e) => {
          if (e.sendToDestination) {
            checkDuplicateDestination.push(e.sendToDestination);
            checkDuplicateDestinationCage.push({
              id: e.sendToDestination,
              cage: e.cageNumber,
            });
          }
        });
        const hasDuplicates = (arr) => arr.length !== new Set(arr).size;
        if (hasDuplicates(checkDuplicateDestination)) {
          message.error("All box's destination should be unique.");
        } else if (checkDuplicateDestination.includes(originHub)) {
          let cageNo = checkDuplicateDestinationCage.find(
            (e) => e.id == originHub
          );
          // eslint-disable-next-line react/no-unescaped-entities
          message.error(
            <span>
              Destination hub of <strong>cage no.{cageNo?.cage} </strong>
              {"can't be same as origin hub."}
            </span>
          );
        } else if (sortingPresent && temp.length == 0) {
          let payload = {
            numberOfColumns: values.numberOfColumns,
            premiseId: values.sendToDestination,
            createdBy: userInfo?.userId,
            sortingGuide: sortingRules,
          };
          if (editId) {
            setLoading(true);
            try {
              let response = await axios.put(
                `${UPDATE_SORTING_GUIDE}/${editId}`,
                { ...payload },
                {
                  headers: {
                    Authorization: token,
                    "SMCS-PREMISE-ID": originHub,
                  },
                }
              );
              setLoading(false);
              if (response.status === 201) {
                message.success("Updated successfully.");
                setLoading(false);
                changeTab();
              }
            } catch (error) {
              message.error(error);
              setLoading(false);
            }
          } else {
            dispatch(createSortingGuide(payload));
          }
        }
      }
    }
  };

  const changeSortDefault = (e) => {
    setEditSortDefault(e.target.value);
    let temp = selectHubList;
    let sortingData = [];
    temp.map((e, i) => {
      sortingData.push({
        sortingPremiseId: 0,
        cageNumber: i + sortingRules.length + 1,
        sendToDestinationList: [e.value],
        sendToDestination: e.value,
      });
    });
    setSortingRules([...sortingRules, ...sortingData]);
    setSelectHubList([]);
  };

  return (
    <Spin
      spinning={loading || loading1}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="master-main-container">
        <Form
          layout="vertical"
          form={form}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          initialValues={{
            travelBy: "surface",
            numberOfColumns: 4,
          }}
          className="global-form"
          style={{ height: "100%" }}
          onFinish={createSorting}
        >
          <div
            className="space-between"
            style={{ padding: "1rem 1.6rem 1rem 1rem" }}
          >
            <div style={{ width: "25%", marginRight: "1.7rem" }}>
              <div className="hub-container">
                <div className="space-between hub-filter">
                  <span>Hub</span>

                  <Dropdown
                    menu={{
                      items,
                      selectable: true,
                      defaultSelectedKeys: ["1"],
                    }}
                    overlayClassName="nav-dropdown-overlay filter-order"
                  >
                    <div className="pointer">
                      <img src={sorting_filter} alt="filter" />
                    </div>
                  </Dropdown>
                </div>
                <div
                  style={{
                    height: "52.5vh",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <Checkbox.Group
                    className="flex-column check-box-hub"
                    style={{ padding: "1rem 1rem" }}
                    options={selectHubList}
                    onChange={(e, list) => changeCheckBox(e, list)}
                    disabled={!originHub && true}
                    value={checkedList}
                  />
                </div>
              </div>
              <div className="hub-container mt-1">
                <div className="flex-column p-1">
                  <span className="destination-title">Choose Box</span>
                  <div className="d-flex mt-03">
                    <Select
                      style={{ width: "80%" }}
                      className="rate-card-select"
                      placeholder="Select Box"
                      options={sortingRules.map((e) => {
                        return {
                          value: e.cageNumber,
                          label: `Box ${e.cageNumber}`,
                        };
                      })}
                      onChange={(e) => setCurrentBox(e)}
                      value={currentBox}
                    />
                    <Button
                      htmlType="submit"
                      className={
                        currentBox &&
                        originHub &&
                        selectedDesignatedId.length > 0
                          ? "ml-1 btn-style save-btn"
                          : "ml-1 btn-style"
                      }
                      disabled={
                        currentBox &&
                        originHub &&
                        selectedDesignatedId.length > 0
                          ? false
                          : true
                      }
                      style={{ height: "30px" }}
                      onClick={addToSelectedBox}
                    >
                      ADD
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: "88%" }}>
              <Row
                gutter={16}
                className="global-form-row"
                style={{ marginTop: "-0.3rem" }}
              >
                <Col xs={12} sm={12} md={9} lg={9} xl={9}>
                  <Form.Item
                    name="sendToDestination"
                    label="Origin Hub"
                    rules={[
                      {
                        required: true,
                        message: "Please select origin  hub",
                      },
                      () => ({
                        async validator(_, value) {
                          if (value && !editId) {
                            try {
                              // eslint-disable-next-line no-unused-vars
                              let res = await axios.get(
                                `${APIs.baseURL}/sorting-service/v1/sorting-guide/search/${value}`,
                                {
                                  headers: {
                                    Authorization: token,
                                  },
                                }
                              );
                              if (res.data) {
                                return Promise.reject(
                                  "Sorting guide already exists for this hub."
                                );
                              } else {
                                return Promise.resolve();
                              }
                            } catch (error) {
                              return Promise.reject(
                                "Sorting guide already exists for this hub."
                              );
                            }
                          }
                        },
                      }),
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select origin hub"
                      options={hubList}
                      onChange={selectOriginHub}
                      disabled={editId ? true : false}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={9} lg={9} xl={9}>
                  <Form.Item
                    name="numberOfColumns"
                    label="No of cages in a row"
                    rules={[
                      {
                        required: true,
                        message: "Please enter no of cages in a row",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter no of cages in a row"
                      onKeyPress={(e) => {
                        if (/[^0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                        if (e.target.length >= 2) {
                          e.preventDefault();
                        }
                      }}
                      maxLength={2}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <hr className="divider" />
              <div
                className="space-between align-center"
                style={{ padding: "0.5rem 0 0.1rem 0" }}
              >
                {editId ? (
                  <Radio.Group
                    value={editSortDefault}
                    onChange={changeSortDefault}
                  >
                    <Radio value="default"> Default </Radio>
                  </Radio.Group>
                ) : (
                  <div>
                    <span>Select format:</span>
                    <br />
                    <Radio.Group
                      value={selectSortType}
                      onChange={selectSortingType}
                      disabled={!originHub && true}
                    >
                      <Radio value="none"> None </Radio>
                      <Radio value="default"> Default </Radio>
                      <Radio value="exitingSorting"> Existing Sorting </Radio>
                    </Radio.Group>
                    {showExistingSort && (
                      <Select
                        style={{ width: "250px" }}
                        placeholder="Select sorting"
                        showSearch
                        className="rate-card-select"
                        options={selectExistingSortList}
                        onChange={selectSingleSort}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    )}
                  </div>
                )}

                <div>
                  <span className="fw-400">
                    {" "}
                    Box Count : <strong>{sortingRules.length}</strong>{" "}
                  </span>

                  <Space size="middle">
                    {selectHubList.length > 0 ? (
                      <Tooltip title={"Add Box"}>
                        <img
                          src={box_plus}
                          alt="add"
                          onClick={addNewCage}
                          className="pointer ml-1"
                        />
                      </Tooltip>
                    ) : (
                      <div
                        className="center ml-1"
                        style={{
                          height: "30px",
                          width: "30px",
                          borderRadius: "50%",
                          background: "#E8E7E7",
                        }}
                      >
                        <span className="f-23 fw-500" style={{ color: "#fff" }}>
                          +
                        </span>
                      </div>
                    )}
                  </Space>
                </div>
              </div>

              <Row
                gutter={16}
                style={{
                  height: "50vh",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  marginRight: "-0.9rem",
                }}
              >
                {sortingRules.map((e) => {
                  return (
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={8}
                      xl={6}
                      key={e.cageNumber}
                    >
                      <div className="cage-container">
                        <div className="space-between">
                          <span className="cage-no left-box">
                            {e.cageNumber}
                          </span>
                          <Popconfirm
                            title="Are you sure to delete the box ?"
                            onConfirm={() => deleteSingleCage(e.cageNumber)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                            icon={
                              <QuestionCircleOutlined
                                style={{
                                  color: "red",
                                }}
                              />
                            }
                          >
                            <span className="cage-no right-box pointer">
                              <img src={delete_cage} alt="delete" />
                            </span>
                          </Popconfirm>
                        </div>
                        <div className="center mt-1">
                          <span className="count">Hub Counts</span>
                          <span
                            className="count-circle ml-1 center pointer"
                            onClick={() => showBoxDetailsModal(e.cageNumber)}
                            style={{
                              background:
                                e?.sendToDestinationList?.length > 0
                                  ? "#2E77D0"
                                  : "#bfbcbc",
                              pointerEvents:
                                e?.sendToDestinationList?.length > 0
                                  ? "auto"
                                  : "none",
                            }}
                          >
                            {e?.sendToDestinationList?.length}
                          </span>
                        </div>
                        <div className="flex-column p-05">
                          <span className="destination-title">Destination</span>
                          <Select
                            style={{ marginTop: "0.3rem" }}
                            showSearch
                            className="rate-card-select select-over-hide"
                            placeholder="Select destination"
                            options={designationHubList}
                            onChange={(event) =>
                              selectDestinationBoxId(event, e.cageNumber)
                            }
                            value={e.sendToDestination}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
          <div>
            <hr className="divider" />
            <div className="d-flex flex-end p-1">
              <Button
                className="btn-style cancel-btn ml-1 w-10"
                onClick={() => navigate("/dashboard")}
              >
                CANCEL
              </Button>
              {editId ? (
                <Button
                  htmlType="submit"
                  className={
                    selectHubList.length > 0
                      ? "btn-style ml-1 w-10"
                      : "btn-style save-btn ml-1 w-10"
                  }
                  disabled={selectHubList.length > 0 ? true : false}
                >
                  UPDATE
                </Button>
              ) : (
                <Button
                  htmlType="submit"
                  className={
                    selectHubList.length > 0
                      ? "btn-style ml-1 w-10"
                      : "btn-style save-btn ml-1 w-10"
                  }
                  disabled={selectHubList.length > 0 ? true : false}
                >
                  SAVE
                </Button>
              )}
              {/* <Button htmlType="submit" className={"save-btn ml-1 w-10"} >
              SAVE
              </Button> */}
            </div>
          </div>
        </Form>
      </div>

      <Modal
        title={modalContent?.currentBox}
        open={modalContent?.show}
        onCancel={cancelBoxModal}
        width={800}
        footer={null}
        className="hub-show-modal"
      >
        <Row gutter={15} className="mb-1">
          {modalContent?.selectedHubList?.map((e) => {
            return (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                key={e.value}
                className="mt-1"
              >
                <div className="d-flex space-between modal-hub">
                  <span>{e.label}</span>
                  <span
                    className="pointer"
                    onClick={() => clearHubModal(modalContent?.id, e)}
                  >
                    <img src={hub_cross} alt="delete" />
                  </span>
                </div>
              </Col>
            );
          })}
        </Row>
      </Modal>
    </Spin>
  );
};
export default CreateSorting;
