import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Space, Table, Button, Row, Spin, Tooltip } from "antd";
import { getPacketingGuideMaster } from "../../../actionsMethods/masterMethods";
import { capitalizeAfterSpacesAndSpecialChars } from "../../../utils";
// import { useNavigate } from "react-router-dom";
import PacketingGuideModal from "./PacketingGuideModal";
import EditPacketingMapping from "./EditPacketingMapping";
import edit_icon from "../../../assests/images/edit-logo.svg";

const PacketingGuideTable = ({ getPacketingGuideSearchResponse }) => {
  const dispatch = useDispatch();
  const getPacketingGuideMasterResponse = useSelector(
    (state) => state?.masterReducer?.getPacketingGuideMasterReducer,
    shallowEqual
  );
  const { fetching, result } = getPacketingGuideMasterResponse;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [modalName, setModalName] = useState("");
  const [hubCpList, setHubCpList] = useState();
  const [packetNameValue, setPacketNameValue] = useState("");
  const [editPktMpingStatus, setEditPktMpingStatus] = useState();
  const [pageSize,setPageSize] = useState(10);
  const { fetching: fetching1 } = useSelector(
    (state) => state?.masterReducer?.putPacketingGuideMasterReducer,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getPacketingGuideMaster());
  }, []);
  useEffect(() => {
    if (!fetching1) {
      dispatch(getPacketingGuideMaster());
    }
  }, [fetching1]);
  const columns = [
    {
      title: "Packet Name",
      dataIndex: "packetName",
      key: "packetName",
      render: (text) => (
        <Row gutter={16} style={{ marginLeft: "0" }}>
          {capitalizeAfterSpacesAndSpecialChars(text)}
        </Row>
      ),
    },
    {
      title: "From Hub",
      dataIndex: "fromHub",
      key: "fromHub",
      render: (text) => (
        <Row gutter={16} style={{ marginLeft: "0" }}>
          {capitalizeAfterSpacesAndSpecialChars(text)}
        </Row>
      ),
    },
    {
      title: "From CP",
      dataIndex: "fromCpList",
      key: "fromCpList",
      render: (text) => (
        <span className="packeting-count-wrapper">
          <Row gutter={16} style={{ width: "90px", marginLeft: "0" }}>
            {capitalizeAfterSpacesAndSpecialChars(text[0])}
          </Row>
          {text.length > 1 ? (
            <Button
              className="packeting-count"
              onClick={() => modalStatusHandle(text, "From CP")}
            >
              {`+${text.length}`}
            </Button>
          ) : null}
        </span>
      ),
    },
    {
      title: "To Hub",
      dataIndex: "toHubList",
      key: "toHubList",
      render: (text) => (
        <span className="packeting-count-wrapper">
          <Row gutter={16} style={{ width: "90px", marginLeft: "0" }}>
            {capitalizeAfterSpacesAndSpecialChars(text[0])}
          </Row>
          {text.length > 1 ? (
            <Button
              className="packeting-count"
              onClick={() => modalStatusHandle(text, "To Hub")}
            >
              {`+${text.length}`}
            </Button>
          ) : null}
        </span>
      ),
    },
    {
      title: "To CP",
      dataIndex: "toCpList",
      key: "toCpList",
      render: (text) => (
        <span className="packeting-count-wrapper">
          <Row gutter={16} style={{ width: "90px", marginLeft: "0" }}>
            {capitalizeAfterSpacesAndSpecialChars(text[0])}
          </Row>
          {text.length > 1 ? (
            <Button
              className="packeting-count"
              onClick={() => modalStatusHandle(text, "To CP")}
            >
              {`+${text.length}`}
            </Button>
          ) : null}
        </span>
      ),
    },
    {
      title: "Packet opening Hub",
      dataIndex: "packetOpeningHub",
      key: "packetOpeningHub",
      render: (text) => (
        <Row gutter={16} style={{ marginLeft: "0" }}>
          {capitalizeAfterSpacesAndSpecialChars(text)}
        </Row>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (value) => (
        <Space size="middle">
          <Tooltip placement="top" title={"Edit"}>
            <Button
              className="edit-button"
              onClick={() => editBtnHandle(value)}
              disabled={false}
            >
              <img src={edit_icon} alt="edit button" />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const editBtnHandle = (value) => {
    if (editModalOpen) setEditModalOpen(false);
    else setEditModalOpen(true);
    setPacketNameValue(value?.packetName);
    // navigate("/addpacket-mapping", { state: "disable" });
  };
  const handlePageChange = (e,a) => {
    setPageSize(a);
    window.scrollTo(0, 0);
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return <span>{originalElement}</span>;
  };
  const modalStatusHandle = (text, name) => {
    setModalName(name);
    if (!isModalOpen) setIsModalOpen(true);
    else setIsModalOpen(false);
    setHubCpList(text);
  };
  let tableData = getPacketingGuideSearchResponse?.result
    ? getPacketingGuideSearchResponse?.result
    : result;
  return (
    <Spin spinning={fetching}>
      <div>
        <Table
          className="table-head-sticky"
          loading={editPktMpingStatus}
          columns={columns}
          dataSource={result?.length > 0 ? tableData : []}
          pagination={{
            pageSize,
            total: getPacketingGuideMasterResponse?.length,
            onChange: handlePageChange,
            itemRender: itemRender,
          }}
        />
        <PacketingGuideModal
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          hubCpList={hubCpList}
          modalName={modalName}
        />
        <EditPacketingMapping
          editModalOpen={editModalOpen}
          setEditModalOpen={setEditModalOpen}
          packetNameValue={packetNameValue}
          setEditPktMpingStatus={setEditPktMpingStatus}
        />
      </div>
    </Spin>
  );
};

export default PacketingGuideTable;
