import React,{useState} from "react";
import moment from "moment";
import { Button, Modal,Form,Row,Col,DatePicker, TimePicker  } from "antd";
import "./style.scss";

const ScheduleMeetingModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const onTimeChange = (time, timeString) => {
    console.log(time, timeString);
  };
  return (
    <div className="p-2">
      <Button type="primary" onClick={showModal}>
        CLICK
      </Button>
      <Modal 
        title="Schedule Meeting" 
        open={isModalOpen} 
        onOk={handleOk} 
        footer={false}
        onCancel={handleCancel}
      >
        <Form
          layout={"vertical"} 
        >
          <Row gutter={12} style={{width:"100%"}} justify={"center"}>
            <Col span={12}>
              <Form.Item
                label="Meeting Scheduled Date & Time "
                name="gstNumber"
              >
                <DatePicker onChange={onDateChange} className="input-field-style" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label=" "
                name=""
              >
                <TimePicker onChange={onTimeChange} defaultOpenValue={moment("00:00:00", "HH:mm:ss")} className="input-field-style" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12} justify={"end"} className="mt-1">
            <Col><Button className="cancel-btn" onClick={handleCancel}>CANCEL</Button></Col>
            <Col><Button className="save-btn" onClick={handleOk}>SAVE</Button></Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default ScheduleMeetingModal;

