/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Tabs,
  Table,
  Space,
  Spin,
  Tooltip,
  Button,
  DatePicker,
  Row,
  Col,
} from "antd";
import { commonSorterForTable, serialNum } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { camelCase, capitaliseWithHyphen } from "../../../utils";
import { getPremiseId } from "../../../utils/worker";
import urls from "../../../api/urls";
import API from "../../../api";
import printerIcon from "../../../assests/images/printIcon.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import search_icon from "../../../assests/images/search-icon.svg";

import "./style.scss";
import moment from "moment";
const CpWiseManifest = () => {
  const { GET_CP_WISE_MANIFEST_HEADER, GET_CP_WISE_MANIFEST_TABLE } = urls;
  const { RangePicker } = DatePicker;

  const navigate = useNavigate();
  const [allCps, setAllCps] = useState();
  const [manifestDetails, setManifestDetails] = useState();
  const [loading, setLoading] = useState(false);
  let todaysDate = moment(new Date(), "ddd MMM DD YYYY HH:mm:ss").format(
    "YYYY-MM-DD"
  );
  let twoDaysBack = moment().subtract(1, "days").format("YYYY-MM-DD");
  const [startAndEndDate, setStartAndEndDate] = useState({
    start: twoDaysBack,
    end: todaysDate,
  });
  const [dateRangeStatus, setDateRangeStatus] = useState(false);
  let tempCps = allCps?.response?.data;
  const [sortedInfo, setSortedInfo] = useState({});
  let userInfo = JSON.parse(localStorage.getItem("userId"));
  let id = userInfo.userInfo.premiseId;

  const [pageNum, setPageNum] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: "Sl No",
      align: "center",
      width: 10,
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "AWB number",
      dataIndex: "awbNumber",
      key: "awbNumber",
      width: 10,

      render: (text) => <span>{text}</span>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 10,

      sorter: (a, b) => commonSorterForTable(a, b, "date"),
      sortOrder: sortedInfo?.columnKey === "date" ? sortedInfo?.order : null,
      ellipsis: true,
    },
    {
      title: "Travel Mode",
      dataIndex: "travelBy",
      key: "travelBy",
      width: 10,

      sorter: (a, b) => commonSorterForTable(a, b, "travelBy"),
      sortOrder:
        sortedInfo?.columnKey === "travelBy" ? sortedInfo?.order : null,
      ellipsis: true,
      render: (text) => <span>{camelCase(text)}</span>,
    },
    // {
    //   title: "No of Shipment(s)",
    //   dataIndex: "shipmentNum",
    //   width: 10,

    //   key: "shipmentNum",
    // },

    {
      title: "Total Weight (Kg)",
      dataIndex: "weight",
      key: "weight",
      width: 10,

      render: (text) => <span>{text?.toFixed(2)}</span>,
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   width: 10,

    //   render: (text) => (
    //     <Space size="middle">
    //       <Button onClick={() => actionHandle(text)}>
    //         <Tooltip title="Print">
    //           <img src={printerIcon} />
    //         </Tooltip>
    //       </Button>
    //     </Space>
    //   ),
    // },
  ];

  useEffect(() => {
    // let todaysDate = moment(new Date(), "ddd MMM DD YYYY HH:mm:ss").format(
    //   "YYYY-MM-DD"
    // );
    // let twoDaysBack = moment().subtract(1, "days").format("YYYY-MM-DD");
    // setStartAndEndDate({ start: twoDaysBack, end: todaysDate });
    getCPDetailsHeader(twoDaysBack, todaysDate);
  }, []);

  const getCPDetailsHeader = async (
    from = startAndEndDate?.start,
    to = startAndEndDate?.end
  ) => {
    setLoading(true);
    let response = await API.Api(
      GET_CP_WISE_MANIFEST_HEADER + `${id}?fromDate=${from}&toDate=${to}`
    ).getWithHeader();
    if (response.status) {
      setLoading(false);
      setAllCps(response);
      const res = response?.response?.data[0]?.premiseId;
      if (response?.response?.data?.length > 0) {
        getCPDetailsTable(res, "pageNum=0&pageSize=10");
      }
    } else {
      setLoading(false);
    }
  };
  const getCPDetailsTable = async (e, page) => {
    setLoading(true);
    let response = await API.Api(
      GET_CP_WISE_MANIFEST_TABLE +
        `${id}/cp/${e}?fromDate=${startAndEndDate?.start}&toDate=${startAndEndDate?.end}&${page}`
    ).getWithHeader();
    if (response.status) {
      setLoading(false);
      setManifestDetails(response.response.data.cpWiseShipments);
      setTotalPageSize(response.response.data.totalCount);
    } else {
      setLoading(false);
    }
  };
  const actionHandle = (e) => {
    navigate("/print-consolidated-manifest/" + e.awbNumber);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleDateChange = (prarms, p2) => {
    const StartDate = moment(p2[0], "DD/MM/YYYY").format("YYYY-MM-DD");
    const endDate = moment(p2[1], "DD/MM/YYYY").format("YYYY-MM-DD");
    setStartAndEndDate({ start: StartDate, end: endDate });
    if (p2[0]?.length === 0) {
      // getTableData();
      setDateRangeStatus(false);
    } else {
      setDateRangeStatus(true);
    }

    // getManifest(pageNum, pageSize, StartDate, endDate);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const handlePageChange = (pagination, premiseId) => {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
    setCurrentPage(pagination.current);
    getCPDetailsTable(
      premiseId,
      `pageNum=${pagination.current - 1}&pageSize=${pagination.pageSize}`
    );
  };

  const tabSwitch = (premiseId) => {
    setPageSize(10);
    setPageNum(1);
    setCurrentPage(1);
    getCPDetailsTable(premiseId, "pageNum=0&pageSize=10");
  };
  return (
    <div className="cp-wise-manifest-cntr">
      <Row className="header" gutter={12} justify={"space-between"}>
        <Col>
          <div>CP Wise Shipment List</div>
        </Col>
        <Col>
          <div className="center">
            <div className="flex-end">
              <RangePicker
                onChange={handleDateChange}
                format={"DD/MM/YYYY"}
                disabledDate={(current) => disabledDate(current)}
                defaultValue={[moment().subtract(1, "days"), moment()]}
                style={{
                  borderRadius: "5px 0 0 5px",
                  height: "40px",
                  width: "230px",
                }}
              />
              <Button
                className="center mr-1"
                disabled={!dateRangeStatus}
                style={{
                  width: "40px",
                  height: "40px",
                  border: "1px solid #d9d9d9",
                  borderRadius: "0 5px 5px 0",
                }}
                onClick={() => getCPDetailsHeader()}
              >
                <img src={search_icon} />
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <Spin
        spinning={loading}
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }
      >
        {allCps?.response.data.length > 0 ? (
          <Tabs
            className="cp-tab"
            defaultActiveKey="1"
            type="card"
            size={"small"}
            onChange={tabSwitch}
            items={tempCps?.map((a) => {
              return {
                label: (
                  <div className="align-center">
                    <div>{capitaliseWithHyphen(a?.premiseName)}</div>
                    <div className="cp-count-style center ml-1">
                      {a?.shipmentCount}
                    </div>
                  </div>
                ),
                key: capitaliseWithHyphen(a?.premiseId),
                children: (
                  <Table
                    className="table-head-sticky"
                    loading={loading}
                    columns={columns}
                    dataSource={manifestDetails}
                    // pagination={false}

                    onChange={(e) => handlePageChange(e, a?.premiseId)}
                    pagination={{
                      pageSize: pageSize,

                      current: currentPage,

                      total: totalPageSize,
                      itemRender: itemRender,
                    }}
                  />
                ),
              };
            })}
          />
        ) : (
          <Table
            className="table-head-sticky"
            loading={loading}
            columns={columns}
            dataSource={null}
            pagination={false}
            onChange={handleChange}
          />
        )}
      </Spin>
    </div>
  );
};

export default CpWiseManifest;
