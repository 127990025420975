/* eslint-disable indent */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Calendar,
  Row,
  Col,
  Tooltip,
  message,
  Modal,
  Spin,
  Popconfirm,
  Radio,
} from "antd";
import API from "../../../../api";
import urls from "../../../../api/urls";
const {
  GET_TRIP_SCHEDULER_LIST,
  GET_CALENDER_VIEW_TRIP,
  DELETE_TRIP_SCHEDULE,
} = urls;
import {
  capitaliseWithHyphen,
  dateFormat_dmy,
  dateFormat_ymd,
} from "../../../../utils";
import driver_user from "../../../../assests/images/driver-user.svg";
import src_dest from "../../../../assests/images/middleMile/src-dest.svg";
import transit from "../../../../assests/images/middleMile/transit.svg";
import break_img from "../../../../assests/images/middleMile/break.svg";
import forward from "../../../../assests/images/middleMile/forward.svg";
import returnJourney from "../../../../assests/images/middleMile/return.svg";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";
import cross_icon from "../../../../assests/images/deleteIcon.svg";
import { DeleteOutlined } from "@ant-design/icons";

import moment from "moment";
import "./style.scss";
import TripView from "./newCalender";

const CalendarView = () => {
  const calendarComponentRef = React.createRef();
  const [calendarData, setCalendarData] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [showTripModal, setShowTripModal] = useState(false);
  const [currentTripDetails, setCurrentTripDetails] = useState(null);
  const [currentRouteDetails, setCurrentRouteDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteOption, setDeleteOption] = useState("Only This");
  let currentDatePast = new Date();
  let currentDateFuture = new Date();
  currentDatePast.setFullYear(currentDatePast.getFullYear() + 1);
  currentDateFuture.setFullYear(currentDateFuture.getFullYear() - 1);
  currentDatePast = moment(currentDatePast).format(dateFormat_dmy);
  currentDateFuture = moment(currentDateFuture).format(dateFormat_dmy);

  useEffect(() => {
    initialize(currentDatePast, currentDateFuture);
  }, []);

  const scheduleDeleteOption = [
    {
      label: "Only for this",
      value: "Only This",
    },
    {
      label: "This and remaining",
      value: "This and Remainings",
    },
  ];

  const initialize = async (date1, date2) => {
    setLoading(true);
    let response = await API.Api(
      GET_TRIP_SCHEDULER_LIST + `${date2}/to/${date1}`
    ).getWithAllHeaders();
    if (response.status) {
      let data = response?.response?.data;
      let newData = data.map((item) => {
        if (item.hasOwnProperty("driverName")) {
          item.openContent = false;
          item.title = item.driverName;
          item.start = `${moment(item.scheduleDate, dateFormat_dmy).format(
            dateFormat_ymd
          )} ${item.scheduleTime}`;
        }

        return item;
      });
      setCalendarData(newData);
    } else {
      message.error(response?.response?.data?.message || "Network error");
    }
    setLoading(false);
  };

  const convertToHour = (time) => {
    return `${parseInt(time / 60)} hrs ${time % 60} min`;
  };

  const onPanelChange = (value) => {
    let date = value.format(dateFormat_ymd);
    let calendarApi = calendarComponentRef.current.getApi();
    calendarApi.gotoDate(date);
    let currentDatePast = new Date(date);
    let currentDateFuture = new Date(date);
    currentDatePast.setFullYear(currentDatePast.getFullYear() + 1);
    currentDateFuture.setFullYear(currentDateFuture.getFullYear() - 1);
    currentDatePast = moment(currentDatePast).format(dateFormat_dmy);
    currentDateFuture = moment(currentDateFuture).format(dateFormat_dmy);
    initialize(currentDatePast, currentDateFuture);
  };
  const changeDate = (value) => {
    const currentDate = moment(value).format(dateFormat_ymd);
    calendarComponentRef.current.getApi().changeView("dayGridDay", currentDate);
  };

  const viewModal = async (eventInfo) => {
    setLoading(true);
    let eventId = eventInfo?.event?._def?.publicId;
    let response = await API.Api(
      GET_CALENDER_VIEW_TRIP + eventId
    ).getWithAllHeaders();
    if (response?.status) {
      setCurrentRouteDetails(response?.response?.data);
    } else {
      message.error(response?.response?.data?.message || "Network error.");
    }
    let tripDetails = eventInfo?.event?._def?.extendedProps;

    setCurrentTripDetails(tripDetails);
    setShowTripModal(true);
    setLoading(false);
  };

  const viewModalContent = () => {
    return <>{<TripView viewTripData={currentRouteDetails} />}</>;
  };

  const deleteSchedule = async (routeDetails) => {
    setLoading(true);

    let id = currentRouteDetails?.id;
    let response = await API.Api(DELETE_TRIP_SCHEDULE + id).deleteIdHeader({
      tripId: id,
      updateTo: deleteOption,
    });
    if (response?.status) {
      message.success(
        response?.response?.data?.message || "Trip deleted Successfully."
      );
      initialize(currentDatePast, currentDateFuture);
      setShowTripModal(false);
    } else {
      message.error(response?.response?.data?.message || "Network error.");
    }
    setLoading(false);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <div className="trip-title d-flex" onClick={() => viewModal(eventInfo)}>
          <div
            style={{
              background: eventInfo?.event?._def?.extendedProps?.driverColor,
              display: "flex",
              alignItems: "center",
              padding: "2px",
            }}>
            <img src={driver_user} />
          </div>
          <div style={{ padding: "0 2px 0 2px" }}>
            <p>{eventInfo?.event?.title}</p>
            <p style={{ marginTop: "-15px", marginBottom: "0" }}>
              {eventInfo?.event?._def?.extendedProps?.scheduleTime}
            </p>
          </div>
        </div>
      </>
    );
  };

  const handleEvents = (events) => {
    setCurrentEvents(events);
  };

  return (
    <div className="master-main-container">
      <Spin
        spinning={loading}
        size="large"
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }>
        <Row>
          <Col xl={6} lg={6} md={8}>
            <Calendar
              fullscreen={false}
              onPanelChange={onPanelChange}
              onSelect={changeDate}
            />
          </Col>
          <Col xl={18} lg={18} md={16} className="view-calendar-new">
            {calendarData && (
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: "prev,next title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                initialView="dayGridMonth"
                weekends={true}
                events={calendarData}
                eventContent={renderEventContent}
                ref={calendarComponentRef}
                dayMaxEvents={true}
                eventsSet={handleEvents}
              />
            )}
          </Col>
        </Row>
        <Modal
          onCancel={() => setShowTripModal(false)}
          open={showTripModal}
          width={1200}
          footer={null}
          maskClosable={false}
          title={
            <div className="space-between">
              <div>View trip schedule</div>

              <div style={{ margin: "0 42px" }}>
                <Popconfirm
                  placement="bottom"
                  title={
                    <>
                      <div>
                        <h3>Delete recurring event?</h3>
                      </div>
                      <Radio.Group
                        defaultValue={"Only This"}
                        onChange={(e) => setDeleteOption(e.target.value)}>
                        <div>
                          <Radio value={"Only This"}>
                            <h4>Only for this</h4>
                          </Radio>
                        </div>

                        <div>
                          <Radio value={"This and Remainings"}>
                            <h4>This and remaining</h4>
                          </Radio>
                        </div>
                      </Radio.Group>
                    </>
                  }
                  // description={description}
                  onConfirm={deleteSchedule}
                  okText="OK"
                  cancelText="CANCEL">
                  <DeleteOutlined />
                </Popconfirm>
              </div>
            </div>
          }>
          {viewModalContent()}
        </Modal>
      </Spin>
    </div>
  );
};
export default CalendarView;
