import React from "react";
import {Col,Descriptions} from "antd";
import vie_certificate from "../../assests/images/view_certificate.svg";
import edit_button from "../../assests/images/edit-logo.svg";


const PobView = ({addPobHandle,stateData}) => {
  const pdfViewHandle = ()=>{
    window.open(stateData?.gstCertification);
  };
  return (
    <Col span={19}>
      <div className="ml-2 mr-2 mt-1 mb-2 form-header space-between">
        <span>Principal Place of Business Details</span>
        <img src={edit_button} style={{cursor:"pointer"}} onClick={()=>addPobHandle({data:stateData,status:"edit"})}/> 
      </div>
      <Descriptions title="" layout="vertical" className="ml-2 mr-2 mt-1 mb-2 pob-view-style" colon={false}>
        <Descriptions.Item label="GST No" span={2}>{stateData?.gstNumber}</Descriptions.Item>
        <Descriptions.Item label="Legal Name" span={2}>{stateData?.legalName}</Descriptions.Item>
        <Descriptions.Item label="Trade Name" span={4}>{stateData?.tradeName}</Descriptions.Item>
        <Descriptions.Item label="Address" className="pob-address">{stateData?.addressLine1}</Descriptions.Item>
        <Descriptions.Item label=""></Descriptions.Item>
        <Descriptions.Item label="GST Certificate" >
          <img src={vie_certificate} style={{cursor:"pointer"}} onClick={pdfViewHandle}/>
        </Descriptions.Item>
      </Descriptions>


      <Descriptions title="Bank Details" layout="vertical" className="ml-2 mr-2 mt-1 mb-2 pob-view-style" colon={false}>
        <Descriptions.Item label="Bank Name" >{stateData?.bankName}</Descriptions.Item>
        <Descriptions.Item label="Branch Name" >{stateData?.branchName}</Descriptions.Item>
        <Descriptions.Item label=""></Descriptions.Item>
        <Descriptions.Item label="IFSC Code" >{stateData?.ifscCode}</Descriptions.Item>
        <Descriptions.Item label="MICR Code" >{stateData?.micrCode}</Descriptions.Item>
        <Descriptions.Item label=""></Descriptions.Item>
        <Descriptions.Item label="Account Type" >{stateData?.accountType}</Descriptions.Item>
        <Descriptions.Item label="Account No" >{stateData?.accountNumber}</Descriptions.Item>
        <Descriptions.Item label=""></Descriptions.Item>
      </Descriptions>
    </Col>
  );
};

export default PobView;
