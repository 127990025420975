import React, {useEffect, useState} from "react";
import { Tabs, Spin, message } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SurfaceTAT from "./surfaceTAT";
import Heading from "../../../../components/Heading";
import { getFromHubTat } from "../../../../actionsMethods/masterMethods";
const MiddleMileTAT = () => {
  const dispatch = useDispatch();
  const [tatData, setTatData] = useState([]);
  const [closeExpandRow, setCloseExpandRow] = useState(true);
  const fromHubData = useSelector(
    (state) => state.masterReducer.getFromHubTatReducer,
    shallowEqual
  );

  const { result: fromHubResult, fetching: loading, error:fromHubErr } =
  fromHubData;

  useEffect(()=>{
    dispatch(getFromHubTat());
  },[]);

  useEffect(()=> {
    if(fromHubResult) {
      let temp = [];
      fromHubResult?.data?.map((e, index)=>{
        temp.push({...e,key:index+1});
      });
      setTatData(temp);
    }
    if(fromHubErr) {
      message.error(fromHubErr.message);
    }
  }, [fromHubResult, fromHubErr]);

  const changeTab = () =>{
    setCloseExpandRow(!closeExpandRow);
  };

  return(
    <Spin spinning={loading}>
      <div className="p-2">
        <Heading title={"Middle Mile TAT Master"} navigateTo={"/master-card-child"} id={7} />
        <div className="rate-card-tab-container">
          <Tabs
            onChange={changeTab}
            items={[
              {
                label: "Surface TAT",
                key: "1",
                children: <SurfaceTAT tatData={tatData} type="surface" closeExpandRow={closeExpandRow}/>,
              },
              {
                label: "Air TAT",
                key: "2",
                children: <SurfaceTAT tatData={tatData} type="air" closeExpandRow={closeExpandRow}/>,
              },
            ]}
          />
        </div>
      </div>
    </Spin>
  );
};
export default MiddleMileTAT;
