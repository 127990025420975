/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Table, Tooltip,Button } from "antd";
import { serialNum } from "../../../utils";
import { capitaliseWithHyphen } from "../../../utils";
import urls from "../../../api/urls";
import API from "../../../api";
import attempted_icon from "../../../assests/images/notDeliveredIcon.svg";
import idle_delivery from "../../../assests/images/idleDelivery.svg";
import delivered_icon from "../../../assests/images/deliveryAttemptSuccessful.svg";
import ReturnTypeModal from "./ReturnTypeModal";
import "./style.scss";

const FailedShipmentList = () => {
  const { FAILED_DRS } = urls;
  // const { RangePicker } = DatePicker;
  // eslint-disable-next-line no-unused-vars
  const [drsListResponse, setDrsListResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [response,setResponse] = useState({});
  let dummy = [{
    awbNumber:"12312312312"
  }];
  const columns = [
    {
      title: "Sl No",
      align: "center",
      with: 10,
      render: (a, b, idx) => serialNum(10, currentPage, idx),
    },
    {
      title: "AWB Number",
      dataIndex: "awbNumber",
      key: "awbNumber",
    },
    {
      title: "In-Scan Date",
      dataIndex: "bookingStartTime",
      key: "bookingStartTime",
      render: (text) => {
        return (
          <span>{text?.split(" ")[0]?.split("-")?.reverse()?.join("-")}</span>
        );
      },
    },
    {
      title: "Booking Type",
      dataIndex: "bookingType",
      key: "bookingType",
      render: (text) => {
        return (
          <>
            <div>{capitaliseWithHyphen(text)}</div>
          </>
        );
      },
    },
    {
      title: "Booking CP Details",
      dataIndex: "",
      key: "",
      render: (text) => {
        return (
          <>
            <div>{capitaliseWithHyphen(text?.cpName)}</div>
            <div>{text?.cpNum}</div>
          </>
        );
      },
    },
    {
      title: "Receiver's Name",
      dataIndex: "addressDetails",
      key: "addressDetails",
      render: (text) => {
        return (
          <>
            <div>
              {" "}
              <b>{capitaliseWithHyphen(text?.receiverName)}</b>{" "}
            </div>
            <div>{capitaliseWithHyphen(text?.receiverAddressLine1)}</div>
            <div>{capitaliseWithHyphen(text?.receiverAddressLine2)}</div>
            <div>{text?.receiverMobileNumber}</div>
          </>
        );
      },
    },
    {
      title: "Delivery Attempts",
      dataIndex: "",
      key: "",
      render: (text) => text?.failedAttempts?.map((data,index)=>
        data?.status === "NOT_DELIVERED" ? 
          <Tooltip title={data?.message} key={index}>
            <img
              src={attempted_icon}
              style={{ width: "16px", height: "16px", marginRight: "5px" }}
            />
          </Tooltip> : data?.status === "DELIVERED" ? 
            
            <img
              src={delivered_icon}
              style={{ width: "16px", height: "16px", marginRight: "5px" }}
            /> 
            :
            <img
              src={idle_delivery}
              style={{ width: "16px", height: "16px", marginRight: "5px" }}
            />
      )         
    },
    {
      title:"Action",
      dataIndex:"delieveryFailureDto",
      key:"action",
      render:(text,record)=>{
        return(<Button disabled={text?.failureCount<3} className="cancel-btn" onClick={()=> returnBtnHandle(record)}>PROCESS TO RETURN</Button>);
      }
    }
  ];
  const returnBtnHandle = (record)=>{
    setResponse(record);
    setIsModalOpen(true);
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };
  const drsListFun = async () => {
    setLoading(true);
    let response = await API.Api(FAILED_DRS).getWithPremiseIdHeader();
    if (response?.status) {
      
      let temp = [];
      let lcrData = response?.response?.data;
      setLoading(false);
      lcrData?.map((data)=>{
        let failedAttempts = [];
        let res = data?.delieveryFailureDto;
        for (let index = 0; index < 3; index++) {
          let status = null;
          data?.bookingStatus === "DELIVERED" ? 
            status = 
              data?.delieveryFailureDto?.failureReason?.length === index ? 
                "DELIVERED" 
                : null 
            : null;
          failedAttempts.push({message:res?.failureReason[index],date:res?.reattemptedDate[index], status: res?.failureReason[index] ? "NOT_DELIVERED" : status});
        }
        temp.push({
          ...data,
          failedAttempts
        });
      });
      setDrsListResponse(temp);
    } else {
      setLoading(false);
    }
  };
  const paginationHandle = (pagination) => {
    setCurrentPage(pagination.current);
  };
  const handleModalOpen=(e)=>{
    setIsModalOpen(e);
  };
  useEffect(() => {
    drsListFun();
  }, []); 
  return (
    <div className="pending-drs-container mt-1">
      {/* <div
        className="p-1"
        style={{ display: "flex", justifyContent: "end", alignItems: "center" }}
      >
        <RangePicker />
      </div> */}
      <Table
        className="table-head-sticky"
        loading={loading}
        columns={columns}
        dataSource={drsListResponse}
        pagination={{
          itemRender: itemRender,
        }}
        onChange={paginationHandle}
      />
      <ReturnTypeModal response={response}   isModalOpen = {isModalOpen} handleModalOpen = {(e)=>handleModalOpen(e)}/>
    </div>
  );
};

export default FailedShipmentList;
