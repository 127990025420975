/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table, message, DatePicker, Tooltip } from "antd";
import {
  camelCase,
  serialNum,
  getStartAndEndDate,
  normalizeString,
  capitaliseWithHyphen,
  formatDateTime,
  routeListCapitalizedFormat,
  toCapitalizedFormat,
} from "../../../../utils";
import urls from "../../../../api/urls";
import API from "../../../../api";
import ExcelJS from "exceljs";

import Smcs_Loader from "../../../../assests/images/loader-new.gif";

import ExpectionModal from "../ExceptionModal";
import nodeURLs from "../../../../api/nodeUrls";
import ExpectionSearchAndDateFilter from "../ExpectionSearchAndDateFilter";
import moment from "moment";

// const { AUDIT_LIST, AUDIT_SEARCH, AUDIT_EXCEL_DOWNLOAD } = urls;
const { GET_EXCEPTION_ACTIONS, UPDATE_EXCEPTION_REPORT, GET_EXCEPTION_REPORT } =
  nodeURLs;

const { RangePicker } = DatePicker;

const BagShortageOverage = ({ activeKey }) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState();
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [startAndEndDate, setStartAndEndDate] = useState({});
  const [serachValue, setSerachValue] = useState(null);

  const [filterType, setFilterType] = useState("ALL");
  const [visible, setVisible] = useState(false);
  const [dateRangeStatus, setDateRangeStatus] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});

  /////////////////////////////////

  useEffect(() => {
    if (activeKey == "3") {
      let todaysDate = moment(new Date(), "ddd MMM DD YYYY HH:mm:ss").format(
        "DD-MM-YYYY"
      );
      let twoDaysBack = moment().subtract(1, "days").format("DD-MM-YYYY");
      if (startAndEndDate?.start) {
        getManifest(
          pageNum,
          pageSize,
          startAndEndDate.start,
          startAndEndDate.end,
          null,
          filterType
        );
      } else {
        setStartAndEndDate({ start: twoDaysBack, end: todaysDate });
        getManifest(
          pageNum,
          pageSize,
          twoDaysBack,
          todaysDate,
          null,
          filterType
        );
      }
    }
  }, [activeKey]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (serachValue?.length > 0) {
        getManifest(
          0,
          pageSize,
          startAndEndDate?.start,
          startAndEndDate?.end,
          serachValue,
          filterType
        ); //search api call
      } else if (serachValue === "") {
        getManifest(
          pageNum,
          pageSize,
          startAndEndDate?.start,
          startAndEndDate?.end,
          null,
          filterType
        );
      }
    }, 900);
    return () => clearTimeout(delayDebounceFn);
  }, [serachValue]);

  const onShowSizeChange = (current, pageSize) => {
    getManifest(
      current,
      pageSize,
      startAndEndDate.start,
      startAndEndDate.end,
      null,
      filterType
    );
  };

  function handlefilterChange(params) {
    setFilterType(params.toUpperCase());
    setSerachValue(null);
    getManifest(
      pageNum,
      pageSize,
      startAndEndDate.start,
      startAndEndDate.end,
      null,
      params.toUpperCase()
    );
  }
  const getColumnSearchProps = (dataIndex, filterList) => ({
    filters: filterList?.map((value) => ({
      text: capitaliseWithHyphen(value),
      value,
    })),
    onFilter: (value, record) => record[dataIndex]?.includes(value),
  });

  const columns = [
    {
      title: "Sl No",
      align: "center",
      width: 10,
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "Bag Number",
      dataIndex: "awb_number",
      width: 15,
      key: "awb_number",
      render: (text) => text,
    },

    {
      title: "Route",
      dataIndex: "route",
      key: "route",
      width: 20,
      render: (text, record) => {
        return text ? (
          <div>
            <Tooltip
              placement="rightTop"
              title={
                record?.route_list &&
                routeListCapitalizedFormat(record?.route_list)
              }
            >
              {toCapitalizedFormat(text)}
              {/* {record?.route} */}
            </Tooltip>
          </div>
        ) : (
          "--"
        );
      },
    },
    {
      title: "Overage Hub",
      dataIndex: "overage_premise",
      key: "overage_premise",
      sorter: (a, b) => {
        const nameA = a.overage_premise || "";
        const nameB = b.overage_premise || "";
        if (!a.overage_premise && b.overage_premise) return 1;
        if (a.overage_premise && !b.overage_premise) return -1;
        return nameA.localeCompare(nameB);
      },
      ...getColumnSearchProps(
        "overage_premise",
        filterOptions?.overage_premise
      ),
      width: 20,

      render: (text) => <div>{capitaliseWithHyphen(text) || "--"}</div>,
    },

    {
      title: "Shortage Hub",
      dataIndex: "shortage_premise",
      key: "shortage_premise",
      // sorter: (a, b) => a?.shortage_premise?.localeCompare(b?.shortage_premise),
      sorter: (a, b) => {
        const nameA = a.shortage_premise || "";
        const nameB = b.shortage_premise || "";

        if (!a.shortage_premise && b.shortage_premise) return 1;
        if (a.shortage_premise && !b.shortage_premise) return -1;
        return nameA.localeCompare(nameB);
      },
      width: 20,
      ...getColumnSearchProps(
        "shortage_premise",
        filterOptions?.shortage_premise
      ),

      render: (text) => <div>{camelCase(text) || "--"}</div>,
    },
    {
      title: "Misroute",
      dataIndex: "exception_type",
      key: "exception_type",
      width: 10,
      render: (text) => <div>{text === "MISSROUTE" ? "Yes" : "No"}</div>,
    },

    {
      title: "Operation",
      dataIndex: "currentstatus",
      width: 15,
      key: "awb_number",
      render: (text, record) => (
        <div>{normalizeString(record?.currentstatus?.operation) || "--"}</div>
      ),
    },
    {
      title: "Last Status",
      dataIndex: "lastStatus",
      key: "lastStatus",
      width: 10,
      render: (text, record) => (
        <div>
          {normalizeString(record?.actions[0]?.action) ||
            camelCase(record?.current?.status) ||
            "--"}
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "exception_type",
      key: "exception_type",
      width: 10,
      render: (text) => <div>{camelCase(text) || "--"}</div>,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      width: 20,
      render: (text, record) => {
        if (typeof record?.actions == "string") {
          return <div>{camelCase(text)}</div>;
        } else {
          return <div>{camelCase(record?.actions[0]?.reason) || "--"}</div>;
        }
      },
    },

    {
      title: "Last Updated(Date and time)",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 20,
      render: (text, rec) => <div>{formatDateTime(rec) || "--"}</div>,
    },
    {
      title: "Last Updated by",
      dataIndex: "lastUpdatedBy",
      key: "lastUpdatedBy",
      width: 20,
      render: (text, rec) => <div>{camelCase(rec?.premise_name) || "--"}</div>,
    },
  ];

  const getManifest = async (
    pageNum = null,
    pageSize = null,
    start = null,
    end = null,
    search = null,
    exceptionType = null
  ) => {
    setLoading(true);

    let params;
    if (search) {
      params = {
        page: pageNum,
        limit: pageSize,
        type: "Bag",
        exceptionType:
          exceptionType == "ALL" ? "MISSROUTE,ROUTE_NOT_FOUND" : exceptionType, // OVERAGE
        startDate: start || null,
        endDate: end || null,
        search,
      };
    } else {
      setSerachValue(null);
      params = {
        page: pageNum,
        limit: pageSize,
        type: "Bag",
        exceptionType:
          exceptionType == "ALL" ? "MISSROUTE,ROUTE_NOT_FOUND" : exceptionType, // OVERAGE
        startDate: start,
        endDate: end,
        // search,
      };
    }

    const response = await API.Api(GET_EXCEPTION_REPORT).NodeGetWithPremise(
      params
    );

    if (response?.status) {
      setLoading(false);
      let temp = [];
      let data = response?.response?.data?.exceptions;

      data?.forEach((e, index) => {
        temp.push({ ...e, slno: index + 1 });
      });
      setDataSource(temp);
      const uniqueOveragePremise = [
        ...new Set(temp?.map((item) => item?.overage_premise)),
      ];
      const uniqueShortagePremise = [
        ...new Set(temp?.map((item) => item?.shortage_premise)),
      ];

      setFilterOptions({
        overage_premise: uniqueOveragePremise.filter(Boolean),
        shortage_premise: uniqueShortagePremise.filter(Boolean),
      });

      setTotal(response?.response?.data?.pagination?.totalFields);
      return temp;
    } else {
      setLoading(false);
      message.error(response?.response?.statusText || "Internal server error");
    }
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  function handlePageChange(pagination) {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);

    getManifest(
      pagination.current,
      pagination.pageSize,
      startAndEndDate?.start,
      startAndEndDate?.end,
      null,
      filterType
    );
  }

  const handleOnclick = () => {
    getManifest(
      pageNum,
      pageSize,
      startAndEndDate?.start,
      startAndEndDate?.end,
      null,
      filterType
    );
  };

  const handleDateChange = (prarms, p2) => {
    const StartDate = moment(p2[0], "DD/MM/YYYY").format("DD-MM-YYYY");
    const endDate = moment(p2[1], "DD/MM/YYYY").format("DD-MM-YYYY");
    setStartAndEndDate({ start: StartDate, end: endDate });
    if (p2[0]?.length === 0) {
      setDateRangeStatus(false);
    } else {
      setDateRangeStatus(true);
    }
  };

  const handleInputChange = (e) => {
    setSerachValue(e.target.value);
  };

  const downloadExcel = async () => {
    const data = await getManifest(
      pageNum,
      total,
      startAndEndDate?.start,
      startAndEndDate?.end,
      null,
      filterType
    );

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data Sheet");

    worksheet.columns = columns?.map((col) => ({
      header: col?.title,
      key: col?.dataIndex || col?.title?.replace(/\s+/g, "")?.toLowerCase(), // Use title as key if dataIndex is not available
      width: col?.width,
    }));

    // Process data to flatten nested objects
    const processedData = data?.map((item) => ({
      ...item,
      is_booking: item?.is_booking ? "YES" : "NO",
      // missroute: item.missroute == true ? "YES" : "NO",
      exception_type: item?.exception_type === "MISSROUTE" ? "YES" : "NO",
      overage_premise: item?.overage_premise ? item?.overage_premise : null,

      overageHub: item?.overageHub ? item?.overageHub?.name : null,
      reason: item?.actions[0]?.reason ? item?.actions[0]?.reason : null,
      shortageHub: item?.shortageHub ? item?.shortageHub?.name : null,
      shipmentRoute: item?.shipmentRoute
        ? item?.shipmentRoute?.join("-")
        : null,
      lastUpdatedBy: item?.premise_name ? item?.premise_name : null,
      updatedAt: moment(item?.updatedAt)?.format("DD-MM-YYYY hh:mm A"),
      lastStatus: item?.actions[0]?.action || item?.current?.status || null,
    }));

    // Add rows
    processedData.forEach((item) => {
      worksheet.addRow(item);
    });

    // Generate Excel file buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a Blob from the buffer
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create an object URL and trigger download
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Bag-exception-report.xlsx";
    a.click();
    URL.revokeObjectURL(url);
  };
  //

  return (
    <>
      <div className="consolidated-manifest-cntr m-1">
        <ExpectionModal visible={visible} setVisible={setVisible} type="bag" />

        <div className="new-header ml-1">
          <ExpectionSearchAndDateFilter
            serachValue={serachValue}
            handleInputChange={handleInputChange}
            handleDateChange={handleDateChange}
            dateRangeStatus={dateRangeStatus}
            handleOnclick={handleOnclick}
            filterType={filterType}
            handlefilterChange={handlefilterChange}
            total={total}
            download={true}
            onClicked={downloadExcel}
            bag={true}
            options={[
              {
                value: "MISSROUTE",
                label: "Miss Route",
              },
              {
                value: "ROUTE_NOT_FOUND",
                label: "Route Not Found",
              },
              {
                value: "ALL",
                label: "All",
              },
            ]}
          />
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          // dataSource={dataSource2}
          scroll={{
            x: 2000,
            y: 500,
          }}
          onChange={handlePageChange}
          pagination={{
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            pageSizeOptions: [50, 100, 150, 200, 500],
            pageSize: pageSize,
            total: total,
            itemRender: itemRender,
            current: pageNum,
          }}
          loading={{
            indicator: (
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            ),
            spinning: loading,
          }}
        />
      </div>
    </>
  );
};

export default BagShortageOverage;
