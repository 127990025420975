/* eslint-disable indent */
import React from "react";
import { Form, Col, Input, Select, Radio } from "antd";
const FormItem = (props) => {
  const styles = {
    background: "#f7f7f7",
    borderRadius: "5px",
    color: "#211f1f",
    fontSize: "14px",
    fontWeight: "400",
    height: "40px",
    lineHeight: "128%",
  };

  const SwitchFunc = (type) => {
    switch (type) {
      case "radio":
        return (
          <Radio.Group
            onKeyPress={
              props?.onKeyPress ||
              ((e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              })
            }
            value={props?.value}
            defaultValue={props?.defaultValue}
            className={props.className}
            onChange={props?.onChange}
          >
            {props?.options?.map((opt) => (
              <Radio value={opt.value} key={opt.value}>
                {opt.label}
              </Radio>
            ))}
          </Radio.Group>
        );

      case "select":
        return (
          <Select
            showSearch
            placeholder={props?.placeholder}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            className="formitem-component"
            onKeyPress={
              props?.onKeyPress ||
              ((e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              })
            }
            onChange={props?.onChange}
            options={props?.options}
          ></Select>
        );

      default:
        return (
          <Input
            value={props?.value}
            minLength={props?.minLength}
            id={props?.id}
            readOnly={props?.readOnly}
            suffix={props?.suffix}
            className={props.className}
            placeholder={props?.placeholder}
            style={props.styles ? props.styles : styles}
            onKeyDown={props?.onKeyDown}
            onChange={props?.onChange}
            onKeyPress={
              props?.onKeyPress ||
              ((e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              })
            }
            maxLength={props?.maxLength}
            disabled={props?.disabled}
            onInput={props.onInput}
          />
        );
    }
  };

  return (
    <Col
      lg={props.lg ? props.lg : 6}
      xl={props.xl ? props.xl : 6}
      sm={props.sm ? props.sm : 6}
      md={props.md ? props.md : 6}
    >
      <Form.Item
        name={props?.name}
        label={props.label}
        rules={props?.rules}
        validateStatus={props?.validateStatus}
        help={props?.help}
        style={props?.style}
      >
        {props?.children ? props?.children : SwitchFunc(props.type)}
      </Form.Item>
    </Col>
  );
};
export default FormItem;
