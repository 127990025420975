/* eslint-disable no-loss-of-precision */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */

import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Spin,
  Button,
  Card,
  message,
  notification,
} from "antd";
import { capitalize, firstLetterCapsInSentence } from "../../../../utils";
import UploadDocument from "../../CrediteBooking/UploadDocument";
import Heading from "../../../../components/Heading";
import EwayBillDrawer from "../../CrediteBooking/EwayBillDrawer/EwayBillDrawer";
import ChildDrawer from "../../CrediteBooking/childDrawer";
import RecentBooking from "../../CrediteBooking/RecentBooking";
import RetailReturnAddressDrawer from "./RetailReturnDrawer";
import API from "../../../../api";
import urls from "../../../../api/urls";
import NewRetailForm from "./NewRetailForm";

import AddressCard from "./AddressCard";
import useClientAndDepartment from "../../CrediteBooking/useClientAndDepartment ";

import { sendSms, sendWhatApp } from "./WhatsAppNSms";
import { focusFirstInputAndScrollToTop } from "../../../../components/CustomFunctions";

import "./style.scss";

const {
  FETCH_RATE_CARD_CP,
  VALIDATE_PUBLIC_GST_NUMBER,
  POST_NEW_RETAIL_BOOKING,
} = urls;

const NewRetailUX = () => {
  const [retailNewForm] = Form.useForm();
  const [ewayForm] = Form.useForm();
  const [returnAddressform] = Form.useForm();
  const [childDrawerform] = Form.useForm();
  const [chargableWeight, setChargableWeight] = useState(0);

  const [returnAddressDrawer, setReturnAddressDrawer] = useState(false);
  const [retailLoading, setRetailLoading] = useState(false);
  const [allEwayBills, setAllEwayBills] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [ewayDrawer, setEwayDrawer] = useState(false);
  const [childDrawer, setChildDrawer] = useState(false);
  const [returnCheck, setReturnCheck] = useState(true);
  const [childShipments, setChildShipments] = useState([]);
  const [returnFormData, setReturnFormData] = useState([]);
  const [mainBaseCharge, setMainBaseCharge] = useState(null);
  const [checkStateTax, setCheckStateTax] = useState(true);
  const [resetForm, setResetForm] = useState(false);
  const [areaIdNDestinationId, setareaIdNDestinationId] = useState(null);

  const [recentAPICall, setRecentAPICall] = useState(false);
  const [checkReturnAdded, setCheckReturnAdded] = useState(null);
  const [filesData, setFileData] = useState([]);
  const user_premiseId = JSON.parse(localStorage?.getItem("userId"));

  const [serviceOptions, setServiceOptions] = useState(null);
  const [rateCardData, setRateCardData] = useState(null);
  const [cessCharge, setCessCharge] = useState(0);
  const [freightCharge, setFreightCharge] = useState(0);

  const [reviverStateCity, setReviverStateCity] = useState(null);

  const [gstStatus, setGstStatus] = useState({
    msg: null,
    status: null,
  });

  const [panStatus, setPanStatus] = useState({
    msg: null,
    status: null,
  });

  const { reviverNameAndAddress, getRevicerNameAndAddress } =
    useClientAndDepartment(user_premiseId?.userInfo, retailNewForm);

  const callMeBack = (value) => {
    setReviverStateCity(value);
  };

  useEffect(() => {
    getRevicerNameAndAddress("CASH");
  }, []);

  useEffect(() => {
    retailNewForm.setFieldsValue({
      travelBy: null,
    });
  }, [childShipments]);

  const [summaryData, setSummaryData] = useState({
    ["Chargeable Weight"]: 0,
    baseCharge: 0,
    fov: 0,
    cess: 0,
    igst: 0,
    sgst: 0,
    cgst: 0,
    charges: 0,
    freight: 0,
  });

  const addresCardStyles = {
    width: "auto",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    marginBottom: "1rem",
    padding: "0",
    background: "#F7F5F5",
  };

  const iconStyles = {
    color: "#2e77d0",
    fontSize: "1.17em",
  };

  const SummaryComponent = ({ summaryData }) => {
    let temp = {};

    if (summaryData && typeof summaryData === "object") {
      if (checkStateTax) {
        temp = Object.fromEntries(
          Object.entries(summaryData).filter(([key]) => key !== "igst")
        );
      } else {
        temp = Object.fromEntries(
          Object.entries(summaryData).filter(
            ([key]) => key !== "cgst" && key !== "sgst"
          )
        );
      }
    }

    let keyMap = {
      sgst: ["sgst (9%)"],
      cgst: ["cgst (9%)"],
      igst: ["igst (18%)"],
      baseCharge: ["Base charge"],
    };

    function changeKeysName(data, keyMap) {
      let newObj = {};
      for (let keys in data) {
        if (Object.prototype.hasOwnProperty.call(keyMap, keys)) {
          newObj[keyMap[keys]] = data[keys];
        } else {
          newObj[keys] = data[keys];
        }
      }
      return newObj;
    }
    let finalObj = changeKeysName(temp, keyMap);

    return (
      <div className="p-0-5">
        {Object.entries(finalObj)?.map(([key, value]) => (
          <div
            key={key}
            className="space-between summary-items"
            style={{ marginBottom: "5px" }}
          >
            <b>{key?.toUpperCase()}</b>
            <b>{value}</b>
          </div>
        ))}
      </div>
    );
  };

  const getRateCard = async () => {
    retailNewForm.setFieldValue("service", null);

    let values = retailNewForm.getFieldsValue();
    setRetailLoading(true);
    let volWeightCheck =
      values?.shipmentType?.toUpperCase() == "NON-DOX" ? true : false;

    if (
      !values?.weight ||
      !values?.receiverAreaId ||
      // volWeightCheck ||
      !values?.deliveryPincode
    ) {
      setRetailLoading(false);
      message.error("Please fill require fields");
      retailNewForm.setFieldsValue({
        service: null,
        travelBy: null,
      });
    } else {
      let ttvw = 0;
      if (childShipments?.length > 0) {
        const childTotalWeight = childShipments?.reduce(
          (sum, item) => sum + parseFloat(item?.weight),
          0
        );
        const childTotalVolWeight = childShipments?.reduce(
          (sum, item) => sum + parseFloat(item?.volumatricWeight),
          0
        );
        if (
          +values?.weight + childTotalWeight <
          +values?.volumatricWeight + childTotalVolWeight
        ) {
          ttvw = +values?.volumatricWeight + childTotalVolWeight;
        } else {
          ttvw = +values?.weight + childTotalWeight;
        }
      } else {
        if (+values?.weight < values?.volumatricWeight) {
          ttvw = +values?.volumatricWeight;
        } else {
          ttvw = +values?.weight;
        }
      }

      let weight =
        values?.shipmentType?.toUpperCase() == "DOX"
          ? values?.weight
          : +ttvw.toFixed(2);
      setChargableWeight(weight);

      let stateName = JSON.parse(localStorage.getItem("userId"))?.premises
        ?.state;
      let payload = {
        // areaId: aresId ? "areaDestinationPremiseId" : null,
        areaId:
          areaIdNDestinationId?.areaId ||
          retailNewForm.getFieldValue("receiverAreaId"), //AREAID HARDCODED
        weight: weight,
        type:
          retailNewForm.getFieldValue("shipmentType")?.toUpperCase() == "DOX"
            ? "DOX"
            : "NON_DOX",
        stateName: stateName?.toUpperCase(),
      };
      let response = await API.Api(
        FETCH_RATE_CARD_CP +
          `${
            user_premiseId?.premises?.pincode + "/to/" + values?.deliveryPincode
          }`
      ).postCustomHeader(payload, {
        "SMCS-PREMISE-ID": user_premiseId?.userInfo?.premiseId,
        clientId: 0,
      });
      if (response?.status) {
        setRetailLoading(false);
        serviceTypesOptions(response, values, weight);
        // setRateAndGetTat(response1, values, weight);
      } else {
        setRetailLoading(false);
        if (
          response?.response?.data?.message ==
          "recvAddress(..) failed: Connection reset by peer"
        ) {
          let response1 = await API.Api(
            FETCH_RATE_CARD_CP +
              `${
                user_premiseId?.premises?.pincode +
                "/to/" +
                values?.deliveryPincode
              }`
          ).postCustomHeader(payload, {
            "SMCS-PREMISE-ID": user_premiseId?.userInfo?.premiseId,
            clientId: 0,
          });
          if (response1?.status) {
            serviceTypesOptions(response1, values, weight);
            // setRateAndGetTat(response1, values, weight);
          } else {
            message.error(
              response1?.response?.data?.message || "Internal server error."
            );
          }
        } else {
          message.error(
            response?.response?.data?.message || "Internal server error."
          );
        }
      }
    }
  };

  function serviceTypesOptions(response, values, weight) {
    let service = response?.response?.data?.data?.services;

    // console.log(service, "service");
    // console.log(responseData, "responseData");
    // console.log(values, "values");

    // console.log(weight, "weight");

    setServiceOptions(
      service.map((val) => {
        return {
          value: val.serviceType,
          label: firstLetterCapsInSentence(val?.serviceType.toLowerCase()),
        };
      })
    );
    setRateCardData({
      response: response,
      values: values,
      weight: weight,
    });
  }

  const setRateAndGetTat = () => {
    let values = retailNewForm.getFieldsValue();
    // console.log(values, "valll");

    let service = rateCardData?.response?.response?.data?.data?.services;
    let responseData = rateCardData?.response?.response?.data?.data;

    function filterData(data, values) {
      const service = data?.find(
        (item) =>
          item?.serviceType?.toLowerCase() === values?.service?.toLowerCase()
      );

      const rate = service?.rates?.find(
        (item) => item.mode.toLowerCase() === values?.travelBy?.toLowerCase()
      );

      return { ...rate, serviceType: values?.service?.toUpperCase() };
    }

    let getServiceTypeRate = filterData(service, values);
    setMainBaseCharge(+getServiceTypeRate?.rate);

    var temp = {
      serive: getServiceTypeRate?.serviceType,
      modeName: getServiceTypeRate?.mode,
      price: getServiceTypeRate?.rate,
      color:
        getServiceTypeRate?.serviceType == "FAST TRACK"
          ? "green"
          : getServiceTypeRate?.serviceType == "STANDARD"
          ? "blue"
          : "orange",
      tatService: null,
      cod: responseData?.codCharges,
      fov: responseData?.fov,
      checkedFov: false,
      checkedCod: false,
    };

    const basePrice = temp?.price || 0;
    const getAllTaxes = calculateCharges(basePrice);
    const freightValue = +basePrice?.toFixed(2) + +cessCharge;

    retailNewForm.setFieldsValue({
      freight: freightValue,
    });

    setSummaryData({
      ...summaryData,
      cess: +cessCharge || 0,
      freight: Math.floor(freightValue)
        ? freightValue?.toFixed(2)
        : freightValue,
      charges: getAllTaxes.chargesAfterGST.toFixed(2) || 0,
      igst: getAllTaxes.igst || 0,
      sgst: getAllTaxes.sgst.toFixed(2) || 0,
      cgst: getAllTaxes.cgst.toFixed(2) || 0,
      baseCharge: temp.price,
      ["Chargeable Weight"]:
        retailNewForm.getFieldValue("shipmentType").toUpperCase() !== "DOX"
          ? chargableWeight
          : retailNewForm.getFieldValue("weight"),
    });
  };

  function containsAllPairs(obj, array) {
    for (let item of array) {
      if (+item.mobileNumber === +obj.mobileNumber && item.name === obj.name) {
        return {
          receiverDetails: [
            {
              userId: item.userId,
              mobileNumber: obj.mobileNumber,
              emailAddress: obj.emailAddress,
              address: obj.address,
              name: obj.name,
              bookingType: obj.bookingType,
              addressType: item.addressType,
              createdDate: item.createdDate,
            },
          ],
        };
      }
    }

    return { receiverDetails: [{ ...obj, userId: null }] };
  }

  function getDestinationId(params) {
    setareaIdNDestinationId(params);
    retailNewForm.setFieldsValue({
      // service: "standard",
      travelBy: null,
      // service: null,
    });
  }

  const onSubmitFormData = async (formData) => {
    setRetailLoading(true);
    if (!returnCheck && returnFormData == null) {
      setRetailLoading(false);
      message.error("Please add return address");
      return;
    }

    if (gstStatus.status == "error") {
      setRetailLoading(false);
      focusFirstInputAndScrollToTop("gst");
      return;
    }

    if (panStatus.status == "error") {
      setRetailLoading(false);
      focusFirstInputAndScrollToTop("pan");
      return;
    }

    let payload;

    const obj = {
      mobileNumber: formData?.mobileNumber,
      emailAddress: formData?.receiverEmail,
      address: formData?.receiverAddress,
      name: formData?.receiverName,
      bookingType: "Cash",
    };

    payload = {
      // ...values,
      pickupPincode: user_premiseId?.premises?.pincode,
      deliveryPincode: +formData?.deliveryPincode,
      areaId: +areaIdNDestinationId?.areaId || +formData?.receiverAreaId, //AREAID HARDCODED
      shipmentType: formData?.shipmentType,
      documentNumber: +formData?.documentNumber,
      weight: +formData?.weight,
      value: formData?.value || 0,
      destinationCpId: +areaIdNDestinationId?.areaDestinationPremiseId,
      destinationHubId: null,
      modeOfPayment: formData?.paymentMode,

      content: formData?.content?.split(" - ")?.[0],
      description: formData?.content?.split(" - ")?.[1],

      service: formData?.service,
      travelBy: formData?.travelBy,

      addressDetail: {
        senderName: formData?.senderName,
        senderAddressLine1: formData?.senderAddress,
        senderPincode: +formData?.senderPincode,
        senderEmail: formData?.senderEmail || null,
        senderMobileNumber: +formData.senderMobileNumber,
        senderArea: formData.senderArea,
        senderGst: formData?.gst || null,
        senderPan: formData?.pan || null,
        // receiverName: formData.receiverName,
        receiverAddressLine1: formData?.receiverAddress,
        receiverPincode: +formData?.deliveryPincode,
        receiverEmail: formData?.receiverEmail || null,
        receiverName: formData.receiverName,
        receiverMobileNumber: +formData.mobileNumber,
      },

      returnAddressDetail: returnCheck
        ? [
            {
              returnName: formData?.senderName,
              returnPincode: +formData?.senderPincode,
              returnAddressLine1: formData?.senderAddress,
              returnAreaName: formData?.senderArea,
              returnMobileNumber: formData?.senderMobileNumber,
              returnEmail: formData?.senderEmail,
            },
          ]
        : [returnFormData],
      childShipments: childShipments?.length ? childShipments : [],

      ewayBills: allEwayBills?.length
        ? allEwayBills?.map(({ id, error, errorMsg, ...rest }) => rest)
        : [],

      chargesDetail: {
        charges: summaryData?.charges,
        basecharge: summaryData?.baseCharge,
        fov: 0, // hardcoded
        cod: 0, // hardcoded
        cess: summaryData?.cess || 0,
        cgst: checkStateTax ? summaryData?.cgst || 0 : 0,
        sgst: checkStateTax ? summaryData?.sgst || 0 : 0,
        freight: summaryData?.freight,
        igst: !checkStateTax ? summaryData?.igst || 0 : 0,
        sez: formData?.IsSez?.toLowerCase() == "yes" ? true : false,
        // promotionId: 1, // Hardcoded
        // discount: 10.5, // Hardcoded
      },

      file: filesData?.length
        ? filesData?.map((val) => ({
            fileName: val?.filePath,
            fileId: val?.id,
          }))
        : [],

      userDetailsDto: containsAllPairs(
        obj,
        reviverNameAndAddress?.receiverDetails
      ),
    };

    if (formData?.shipmentType?.toLowerCase() == "non-dox") {
      payload = {
        ...payload,
        length: formData?.length,
        width: formData?.width,
        height: formData?.height,
        volumatricWeight: formData?.volumatricWeight,
        childSelected: childShipments?.length ? true : false,
      };
    }

    const response = await API.Api(POST_NEW_RETAIL_BOOKING).postIdHeader(
      payload
    );

    if (response?.status) {
      setRetailLoading(false);
      setAllEwayBills(null);
      setChildShipments(null);
      setResetForm(true);
      // sendSms(formData?.documentNumber, +formData?.mobileNumber);
      // sendWhatApp(
      //   formData?.documentNumber,
      //   formData?.receiverName,
      //   formData?.senderMobileNumber,
      //   reviverStateCity?.city,
      //   reviverStateCity?.state
      // );
      openNotificationWithIcon("success");
      // getFocusFirstInput("documentNumber");
    } else {
      setRetailLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  function resetCallBack() {
    const keysToInclude = ["shipmentType", "travelBy", "documentNumber"];
    const values = retailNewForm.getFieldValue();
    retailNewForm.resetFields(
      Object.keys(values).filter((key) => !keysToInclude?.includes(key))
    );

    let documentNumber = +values?.documentNumber;

    retailNewForm.setFieldsValue({
      documentNumber: ++documentNumber,
      weight: values.shipmentType.toLowerCase() == "dox" ? "50" : "1",
    });
    setResetForm(false);
    setRecentAPICall(!recentAPICall);
    setCheckReturnAdded(null);
    setCheckStateTax(true);
    setReturnCheck(true);
    setMainBaseCharge(null);
    setChildShipments(null);
    setReturnFormData([]);
    setFileList([]);
    setAllEwayBills([]);
    setFileData([]);
    setCessCharge(0);
    setFreightCharge(0);
    setareaIdNDestinationId(null);
    setSummaryData({
      ["Chargeable Weight"]: 0,
      baseCharge: 0,
      fov: 0,
      cess: 0,
      igst: 0,
      sgst: 0,
      cgst: 0,
      charges: 0,
      freight: 0,
    });
    ewayForm.resetFields();
    returnAddressform.resetFields();
    childDrawerform.resetFields();
    retailNewForm.setFieldsValue({
      // shipmentType: "Dox",
      // service: "standard",
      service: null,
    });
    focusFirstInputAndScrollToTop("documentNumber");
    getRevicerNameAndAddress("CASH");
  }

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: <h3 className="alert-">Booking successfully completed</h3>,
      duration: 2,
      onClose: resetCallBack,

      description: (
        <>
          <h5 className="order-id">
            Document no.&nbsp;{" "}
            <b>{retailNewForm.getFieldValue("documentNumber")}</b>
          </h5>

          {childShipments?.length > 0 ? (
            <h5>
              Child document no.&nbsp;{" "}
              <b>
                {childShipments?.map((e) => e?.childDocumentNumber)?.join(", ")}
              </b>
            </h5>
          ) : (
            ""
          )}
        </>
      ),
    });
  };

  const uploadDocumentCallback = (files) => {
    setFileData(files);
  };

  function handleReturnCheckBox(params) {
    setReturnCheck(params.target.checked);
    setCheckReturnAdded(null);
    // setCheckReturnAdded(!checkReturnAdded);
    if (params.target.checked) {
      setReturnCheck(true);
    } else {
      setReturnCheck(false);
      setReturnFormData(null);
    }
  }

  function checkGSTMatch(gst1, gst2) {
    const prefix1 = gst1?.substring(0, 2);
    const prefix2 = gst2?.substring(0, 2);
    // Compare the prefixes
    if (prefix1 == prefix2) {
      setCheckStateTax(true);
      return true;
    } else {
      setCheckStateTax(false);
      return false;
    }
  }

  const onFreightChange = (e) => {
    let cessValue = e.target.value;
    setFreightCharge(cessValue);

    const localBaseCharge = +cessValue;
    const getAllTaxes = calculateCharges(localBaseCharge);
    // console.log(getAllTaxes, "getAllTaxes");
    const freightValue = +cessValue;

    if (cessValue?.length) {
      setSummaryData({
        ...summaryData,
        freight: Math.floor(freightValue)
          ? freightValue?.toFixed(2)
          : freightValue,
        charges: getAllTaxes?.chargesAfterGST.toFixed(2) || 0,
        igst: getAllTaxes?.igst || 0,
        sgst: getAllTaxes?.sgst.toFixed(2) || 0,
        cgst: getAllTaxes?.cgst.toFixed(2) || 0,
        // cess: cessValue || 0,
      });
    } else {
      const localBaseCharge = +cessValue;
      const getAllTaxes = calculateCharges(localBaseCharge);

      setSummaryData({
        ...summaryData,
        // freight: mainBaseCharge,
        freight: Math.floor(freightValue),

        charges: getAllTaxes?.chargesAfterGST.toFixed(2) || 0,
        igst: getAllTaxes?.igst || 0,
        sgst: getAllTaxes?.sgst.toFixed(2) || 0,
        cgst: getAllTaxes?.cgst.toFixed(2) || 0,
        // cess: cessValue || 0,
      });
    }
  };

  const onCESSChange = (e) => {
    let cessValue = e.target.value;
    setCessCharge(cessValue);

    const localBaseCharge = +mainBaseCharge + +cessValue + +freightCharge;
    const getAllTaxes = calculateCharges(localBaseCharge);
    // console.log(getAllTaxes, "getAllTaxes");
    const freightValue = +mainBaseCharge + +cessValue + +freightCharge;

    if (cessValue?.length) {
      setSummaryData({
        ...summaryData,
        freight: Math.floor(freightValue)
          ? freightValue?.toFixed(2)
          : freightValue,
        charges: getAllTaxes?.chargesAfterGST.toFixed(2) || 0,
        igst: getAllTaxes?.igst || 0,
        sgst: getAllTaxes?.sgst.toFixed(2) || 0,
        cgst: getAllTaxes?.cgst.toFixed(2) || 0,
        cess: cessValue || 0,
      });
    } else {
      const localBaseCharge = +mainBaseCharge + +freightCharge;
      const getAllTaxes = calculateCharges(localBaseCharge);

      setSummaryData({
        ...summaryData,
        // freight: mainBaseCharge,
        freight: Math.floor(mainBaseCharge)
          ? +freightCharge + +mainBaseCharge?.toFixed(2)
          : +freightCharge + +mainBaseCharge,
        charges: getAllTaxes?.chargesAfterGST.toFixed(2) || 0,
        igst: getAllTaxes?.igst || 0,
        sgst: getAllTaxes?.sgst.toFixed(2) || 0,
        cgst: getAllTaxes?.cgst.toFixed(2) || 0,
        cess: cessValue || 0,
      });
    }
  };

  function calculateCharges(basePrice) {
    let chargesAfterGST;

    const igst = calculateGST(basePrice, 1.18).toFixed(2);
    const sgst = igst / 2;
    const cgst = igst / 2;

    if (!checkStateTax) {
      chargesAfterGST = basePrice - igst;
    } else {
      chargesAfterGST = basePrice - (sgst + cgst);
    }
    return { chargesAfterGST, igst, sgst, cgst };
  }

  function calculateGST(amount, gstRate) {
    const x = amount / gstRate || 0;
    return amount - x;
  }

  async function handleSenderGST(e) {
    const value = e.target.value;

    if (value?.length == 0) {
      setGstStatus({
        status: null,
        msg: null,
      });
    }
    if (value?.length !== 15 && value?.length !== 0) {
      setGstStatus({
        status: "error",
        msg: "GST number should be 15 characters long.",
      });
      return;
    }
    if (
      !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
        value
      ) &&
      value.length !== 0
    ) {
      setGstStatus({
        status: "error",
        msg: "Please enter a valid GST Number",
      });
      return;
    }

    if (value?.length > 14) {
      setRetailLoading(true);

      checkGSTMatch(value, user_premiseId?.premises?.gst);
      const response = await API.Api(
        VALIDATE_PUBLIC_GST_NUMBER + retailNewForm.getFieldValue("gst")
      ).getWithUserNPremiseHeader();

      if (response?.status) {
        setGstStatus({
          status: null,
          msg: null,
        });
        setRetailLoading(false);
        let gstState =
          response?.response?.data?.result?.gstnDetailed?.principalPlaceState;

        if (user_premiseId.premises.state === gstState) {
          setCheckStateTax(true);
        } else {
          setCheckStateTax(false);
        }
      } else {
        setRetailLoading(false);
        setGstStatus({
          status: "error",
          msg: "GST details not found",
        });
        return;
      }
    } else {
      setRetailLoading(false);
      setCheckStateTax(true);
    }
  }

  useEffect(() => {
    focusFirstInputAndScrollToTop("documentNumber");
  }, []);

  function handleTypeChange(temp) {
    const keywords = [
      "weight",
      "shipmentType",
      "documentNumber",
      "areaId",
      "deliveryPincode",
      "width",
      "length",
      "height",
    ];

    function checkForKey(temp, keywords) {
      const tempKeys = Object.keys(temp);
      return keywords.some((keyword) => tempKeys.includes(keyword));
    }

    if (checkForKey(temp, keywords)) {
      setSummaryData({
        ["Chargeable Weight"]: 0,
        baseCharge: 0,
        fov: 0,
        cess: 0,
        igst: 0,
        sgst: 0,
        cgst: 0,
        charges: 0,
        freight: 0,
      });
      retailNewForm.setFieldsValue({
        service: null,
      });
    }
  }

  return (
    <>
      <div className="new-retail-container">
        <EwayBillDrawer
          ewayForm={ewayForm}
          allEwayBills={allEwayBills}
          setOpen={(e) => setEwayDrawer(e)}
          open={ewayDrawer}
          setAllEwayBills={setAllEwayBills}
        />
        <ChildDrawer
          childDrawerform={childDrawerform}
          childShipments={childShipments}
          setChildShipments={setChildShipments}
          setOpen={(e) => setChildDrawer(e)}
          open={childDrawer}
        />
        <RetailReturnAddressDrawer

          returnAddressform={returnAddressform}
          returnFormData={returnFormData}
          setReturnFormData={(e) => {
            setReturnFormData(e);
            setCheckReturnAdded(true);
          }}
          openReturn={returnAddressDrawer}
          setReturnOpen={(e) => setReturnAddressDrawer(e)}
        />
        <div className="p-1-2">
          <Heading title={"Retail Booking"} />
          <Row
            className="new-retail-tab-container"
            style={{ background: "white" }}
          >
            <Col xl={16} lg={18} md={18} style={{ padding: "8px 25px" }}>
              <Form
                form={retailNewForm}
                onFinish={onSubmitFormData}
                onValuesChange={(e, b) => handleTypeChange(e, b)}
                initialValues={{
                  weight: "50",
                  value: 0,
                  IsSez: "No",
                  paymentMode: "cash",
                  cess: "0",
                }}
                layout="vertical"
                className="global-form"
                scrollToFirstError={{
                  behavior: "smooth",
                  block: "center",
                  inline: "center",
                }}
              >
                <NewRetailForm

                  retailLoading={retailLoading}
                  callMeBack={(e) => callMeBack(e)}
                  ewayDrawercallBack={(e) => setEwayDrawer(e)}
                  childDrawerCallBack={() => setChildDrawer(true)}
                  handleSenderGST={handleSenderGST}
                  form={retailNewForm}
                  onCESSChange={onCESSChange}
                  onFreightChange={onFreightChange}
                  serviceOptions={serviceOptions}
                  // onBaseChange={onBaseChargeHandler}
                  getDestinationId={getDestinationId}
                  childShipments={childShipments}
                  allEwayBills={allEwayBills}
                  handleTravelBy={getRateCard}
                  // onBaseChargeHandler={onBaseChargeHandler}
                  resetForm={resetForm}
                  reviverNameAndAddress={reviverNameAndAddress}
                  setRateAndGetTat={setRateAndGetTat}
                  mainBaseCharge={mainBaseCharge}
                  gstStatus={gstStatus}
                  setGstStatus={setGstStatus}
                  panStatus={panStatus}
                  setPanStatus={setPanStatus}
                />

                <div className="vertical-space-between">
                  <div>
                    <UploadDocument
                      uploadDocumentCallback={uploadDocumentCallback}
                      setFileList={setFileList}
                      fileList={fileList}
                      filesData={filesData}
                    >
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: 0,
                        }}
                      >
                        <Button
                          className="save-btn"
                          id="submit_retail"
                          htmlType="submit"
                          onKeyDown={(e) => e.key === "Tab"}
                          // onClick={"onSubmitFormData"}
                          // onClick={onSubmitFormData}
                        >
                          CONFIRM BOOKING
                        </Button>
                      </Col>
                    </UploadDocument>
                  </div>
                </div>
              </Form>
            </Col>
            <Col xl={8} lg={6} md={6} className="new-retail-card-container">
              <div className="pt-1">
                <AddressCard
                  iconStyles={iconStyles}
                  setReturnCheck={setReturnCheck}
                  returnCheck={returnCheck}
                  checkReturnAdded={checkReturnAdded}
                  setReturnAddressDrawer={setReturnAddressDrawer}
                  addresCardStyles={addresCardStyles}
                  capitalize={capitalize}
                  retailNewForm={retailNewForm}
                  handleReturnCheckBox={handleReturnCheckBox}
                  returnFormData={returnFormData}
                />
              </div>

              <div>
                <Card
                  size="small"
                  className="dashboard-container-card-div"
                  title={
                    <div>
                      <span className="announcement-text">
                        {" "}
                        Booking Summary
                      </span>
                    </div>
                  }
                  style={{
                    ...addresCardStyles,
                  }}
                >
                  <SummaryComponent summaryData={summaryData} />
                </Card>
              </div>
            </Col>

            <Col lg={16} sm={16} md={26}>
              <div style={{ padding: "0px 40px" }}>
                <RecentBooking
                  recentAPICall={recentAPICall}
                  bookingType="cash"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default NewRetailUX;
