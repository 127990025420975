/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import CPApprovalLeadList from "./CPLeadApproval";
import ClientApprovalLeadList from "./ClientLeadApproval";
import { useLocation } from "react-router-dom";

// import ClientLeadList from "./CPLeadList";

const AllApprovalTabs = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [destroy, setDestroy] = useState(false);
  const [updateStaff, setUpdateStaff] = useState();

  const { state } = useLocation();
  useEffect(() => {
    if (state) {
      setActiveKey(state.toString());
    }
  }, [state]);

  const changeTab = (key) => {
    setActiveKey(key);
    setDestroy(false);
  };

  function handleEdit(id) {
    setActiveKey("1");
    setUpdateStaff(id);
  }

  function handleSubmit() {
    setActiveKey("2");
    setDestroy(true);
  }

  return (
    <div className="p-2">
      {/* <Heading title="Staff Onboarding" /> */}
      <div className="global-card-tab-container">
        <Tabs
          activeKey={activeKey}
          destroyInactiveTabPane={destroy}
          onChange={changeTab}
          items={[
            {
              label: "CP Approval",
              key: "1",
              children: <CPApprovalLeadList />,
            },

            {
              label: "Client/DP Approval",
              key: "2",
              children: <ClientApprovalLeadList />,
            },
            {
              label: "Branch Approval",
              key: "3",
              // children: <ClientLeadList />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default AllApprovalTabs;
