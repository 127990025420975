import React, { useState, useEffect } from "react";
import { Col, Row, Input, Button, message } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import UploadDrawer from "./uploadDrawer";
import Heading from "../../../components/Heading";
import {
  createPincode,
  getPincodeMastertable,
  getServiceablility,
  enableDisablePincodeMaster,
  clearCreatePincode,
  clearUpdatePincode,
  clearEnableDisablePincodeMaster,
} from "../../../actionsMethods/masterMethods";

import axios from "axios";

import EditPinFormModal from "./EditPinForm";
import { APIs, getToken } from "../../../utils/worker";
import { column } from "./column";
import AddPinFormModal from "./AddPinForm";
import TableContainer from "../../../components/TableContainer";

import search_icon from "../../../assests/images/search-icon.svg";
import print_logo from "../../../assests/images/SVG/master-print.svg";
import upload_pin_icon from "../../../assests/images/SVG/upload-pin-icon.svg";
import { ReactComponent as Download_pin_icon } from "../../../assests/images/SVG/download-pin.svg";

import "./style.scss";

const token = getToken();

const PincodeMaster = () => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editData, seteditData] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [pageNum, setPageNum] = useState(0);
  const [tableDataCopy, settableDataCopy] = useState();

  const [searchLoader, setSearchLoader] = useState(false);
  const [total, setTotal] = useState();
  const [searchInputVal, setsearchInputVal] = useState();

  const [areadata, setAreaData] = useState([]);

  const [premiumAreaData, setPremiumAreaData] = useState([]);

  const [editAreaData, setEditAreaData] = useState([]);
  const [editPremiumData, setEditPremiumData] = useState([]);

  const [serviceability, setServiceability] = useState();
  const [areaServiceType, setAreaServiceType] = useState();

  const url = "/pincode-service/excel-save";
  const sampleUrl = "/pincode-service/v1/sample";

  const getPincodeTableResponse = useSelector(
    (state) => state.masterReducer.pincodeMasterTableReducer,
    shallowEqual
  );

  const getStateResponse = useSelector(
    (state) => state.masterReducer.getStateReducer,
    shallowEqual
  );

  const getServiceabilityResponse = useSelector(
    (state) => state.masterReducer.getServiceabilityReducer,
    shallowEqual
  );

  const updatePincodeResponse = useSelector(
    (state) => state.masterReducer.updatePincodeReducer,
    shallowEqual
  );

  const diablePincodeResponse = useSelector(
    (state) => state.masterReducer.enableDisablePincodeMasterReducer,
    shallowEqual
  );

  const {
    fetching: tableFetch,
    result: tableResult,
    error: tableError,
  } = getPincodeTableResponse;

  const { fetching: stateFetch, result: stateResult } = getStateResponse;
  const { result: serviceResult } = getServiceabilityResponse;

  const {
    fetching: updateFetch,
    result: updateResult,
    error: updateError,
  } = updatePincodeResponse;

  const {
    fetching: disableFetch,
    result: disableResult,
    error: diableError,
  } = diablePincodeResponse;

  useEffect(() => {
    dispatch(clearCreatePincode());
  }, []);

  useEffect(() => {
    if (tableResult !== null) {
      settableDataCopy(tableResult?.data);
      setTotal(tableResult.count);
    }
    if (tableError !== null) {
      settableDataCopy([]);
      message.error(tableError?.message);
    }
  }, [tableResult, tableError]);

  useEffect(() => {
    if (updateResult !== null) {
      message.success(updateResult?.message);
      if (pageNum == 0) {
        dispatch(getPincodeMastertable(pageNum, pageSize));
      } else {
        dispatch(getPincodeMastertable(pageNum - 1, pageSize));
      }
      dispatch(clearUpdatePincode());
      setEditAreaData([]);
      setsearchInputVal("");
      setIsEditModalOpen(false);
    }
    if (updateError !== null) {
      message.error(updateError?.message || "Failed to update");
      setIsEditModalOpen(false);
      dispatch(clearUpdatePincode());
    }
  }, [updateResult, updateError]);

  useEffect(() => {
    if (disableResult !== null) {
      message.success(disableResult?.message);
      // dispatch(getPincodeMastertable(pageNum, pageSize));
      setsearchInputVal("");
      if (pageNum == 0) {
        dispatch(getPincodeMastertable(pageNum, pageSize));
      } else {
        dispatch(getPincodeMastertable(pageNum - 1, pageSize));
      }
      dispatch(clearEnableDisablePincodeMaster());
    }
    if (diableError !== null) {
      message.error(diableError?.message);
      dispatch(clearEnableDisablePincodeMaster());
    }
  }, [disableResult, diableError]);

  useEffect(() => {
    if (getPincodeTableResponse?.result === null) {
      dispatch(getPincodeMastertable(pageNum, pageSize));
      dispatch(getServiceablility());
    }
  }, []);

  const filterServiceType2 = serviceResult?.filter(
    (val) => val?.serviceability !== "Non-Serviceable"
  );

  const filterServiceType = serviceResult?.filter(
    (val) => val?.serviceability === "Regular"
  );

  const getServiceablityType = async () => {
    let token = getToken();
    try {
      let response = await fetch(
        `${APIs.baseURL}/pincode-service/v1/pincode/type`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      let res = await response.json();
      if (res) {
        setServiceability(res);
      }
    } catch (error) {
      message.error(error?.message);
    }
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const handleAddPincode = (params) => {
    let flml = params.mile.label[1].replace("hrs", "");
    let pinServiceType = params.pincodeType.label[0]["props"]["value"];
    let serviceType = params.ServiceabilityType.label[0]["props"]["value"];
    let valueLabel = params.ServiceabilityType.label[1];

    const temp = [];

    if (
      (areaServiceType === "Regular" || areaServiceType === "Both") &&
      areadata.length > 0
    ) {
      areadata.forEach((val) => {
        temp.push({ areaName: val, areatype: "regular" });
      });
    }

    if (
      (areaServiceType === "Premium" || areaServiceType === "Both") &&
      premiumAreaData?.length > 0
    ) {
      premiumAreaData.forEach((val) => {
        temp.push({ areaName: val, areatype: "premium" });
      });
    }
    if (temp?.length > 0) {
      dispatch(
        createPincode({
          pincode: params.pinCode,
          stateName: params.state,
          stateCode: params.stateCode,
          city: params.city,
          zone: params.zone,
          serviceability: {
            serviceableId: pinServiceType,
          },
          pincodeType: {
            typeId: serviceType,
            pincodeType: valueLabel,
          },

          areadto: temp,
          enable: true,
          fmlm: +flml,
        })
      );
    } else {
      message.error("Area cannot be empty");
    }
  };

  const handleOpen = () => {
    setDrawerOpen(true);
  };

  const handleClose = () => {
    setDrawerOpen(false);
  };

  const handleModal = () => {
    setIsModalOpen(true);
    if (!serviceability) {
      getServiceablityType();
    }
    setAreaServiceType("Regular");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEditCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const handleEditPincode = () => {
    setIsEditModalOpen(false);
  };

  const handleEditView = (params) => {
    seteditData(params);
    setIsEditModalOpen(true);
    getServiceablityType();
  };

  const handleAddModal = () => {
    setIsModalOpen(false);
    setAreaData([]);
    setPageSize(20);
    setPageNum(0);
    setsearchInputVal("");
    dispatch(getPincodeMastertable(0, 20));
  };

  const handleDisable = (params) => {
    dispatch(enableDisablePincodeMaster(params?.pincode));
  };

  function handlePageChange(pagination) {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);

    if (searchInputVal === undefined || searchInputVal?.length === 0) {
      dispatch(
        getPincodeMastertable(pagination.current - 1, pagination.pageSize)
      );
    }
  }

  const handleChangeSearch = async (e) => {
    setsearchInputVal(e.target.value);
    if (e.target.value.length >= 3) {
      setSearchLoader(true);
      try {
        let res = await axios.get(
          `${APIs.baseURL}/pincode-service/v1/pincode/search/${e.target.value}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.data) {
          settableDataCopy(res?.data);
          setSearchLoader(false);
          setTotal(res?.data?.length);
          setPageNum(0);
        }
      } catch (error) {
        settableDataCopy(tableResult?.data);
        setSearchLoader(false);
      }
    }
    if (e.target.value.length < 1) {
      settableDataCopy(tableResult?.data);
      setPageNum(0);
      setTotal(tableResult?.count);
    }
  };

  const handleDownload = () => {
    window.open(`${APIs.baseURL}/pincode-service/v1/download`);
  };

  const handlePrint = () => {
    window.open(`${APIs.baseURL}/pincode-service/v1/print`);
  };

  const handleCallBack = () => {
    dispatch(getPincodeMastertable(0, 20));
  };

  function handleAreaType(params) {
    setAreaServiceType(params.target.value);
  }

  useEffect(() => {
    // Check if "regular" and "premium" both are present
    const hasRegular = editData?.areadto?.filter(
      (item) => item?.areatype === "regular" || item?.areatype === null
    );
    const hasPremium = editData?.areadto?.filter(
      (item) => item?.areatype === "premium"
    );

    if (hasRegular?.length > 0 && hasPremium?.length > 0) {
      setAreaServiceType("Both");
      setEditPremiumData(hasPremium);
      setEditAreaData(hasRegular);
    } else if (hasPremium?.length > 0) {
      setAreaServiceType("Premium");
      setEditPremiumData(hasPremium);
    } else {
      setAreaServiceType("Regular");
      setEditAreaData(hasRegular);
    }
  }, [editData]);

  return (
    <div className="pincode-container p-2">
      <Heading title={"PIN Code Master Setting"} />
      <UploadDrawer
        title="Upload PIN Code"
        open={drawerOpen}
        onClose={handleClose}
        url={url}
        fileType={"csv"}
        sampleUrl={sampleUrl}
        type="Pincode Master"
        docType={".csv"}
        label={"only csv formats are supported"}
        handleCallBack={handleCallBack}
      />

      <AddPinFormModal
        handleAddPincode={handleAddPincode}
        serviceResult={filterServiceType2}
        stateResult={stateResult}
        loading={stateFetch}
        handleAddModal={handleAddModal}
        handleCloseModal={handleCloseModal}
        visible={isModalOpen}
        setAreaData={setAreaData}
        areadata={areadata || []}
        serviceability={serviceability}
        premiumAreaData={premiumAreaData || []}
        setPremiumAreaData={setPremiumAreaData}
        areaServiceType={areaServiceType}
        handleAreaType={handleAreaType}
      />
      {isEditModalOpen && (
        <EditPinFormModal
          editData={editData}
          serviceResult={filterServiceType}
          visible={isEditModalOpen}
          handleCloseModal={handleEditCloseModal}
          handleSave={handleEditPincode}
          loading={updateFetch}
          serviceabilityType={serviceability}
          editPremiumData={editPremiumData}
          handleAreaType={handleAreaType}
          areaServiceType={areaServiceType}
          editAreaData={editAreaData}
          setEditPremiumData={setEditPremiumData || []}
          setEditAreaData={setEditAreaData}
        />
      )}

      <div className="pincode-table-container">
        <Row className="filter-container">
          <Col className="search-box">
            <Input
              placeholder="Search by (PIN code / City /State /Zone / PIN code type)"
              bordered
              value={searchInputVal}
              onChange={(e) => handleChangeSearch(e)}
              style={{ borderRadius: "5px", height: "40px", maxWidth: "83%" }}
              suffix={<img src={search_icon} />}
              maxLength={40}
            />
          </Col>
          <Col className="upload-btn">
            <Button onClick={handleOpen}>
              <img src={upload_pin_icon} />
              <span>Upload</span>
            </Button>
          </Col>
          <Col className="pincode-download-btn">
            <Button
              onClick={handleDownload}
              // disabled
            >
              <Download_pin_icon /> Download
            </Button>
          </Col>
          <Col className="print-btn">
            <Button className="print" onClick={handlePrint}>
              <img src={print_logo} />
              Print
            </Button>
          </Col>
          <Col className="add-pincode-btn" type="primary">
            <Button className="" onClick={handleModal}>
              ADD PINCODE DETAILS
            </Button>
          </Col>
        </Row>
        <TableContainer
          columns={column(handleEditView, handleDisable, pageNum, pageSize)}
          defaultCurrent={0}
          loading={tableFetch || searchLoader || disableFetch}
          dataSource={tableDataCopy}
          onChange={handlePageChange}
          rowClassName={(record) => !record?.enable && "disabled-row"}
          pagination={{
            pageSize: pageSize,
            total: total,
            itemRender: itemRender,
            current: pageNum == 0 ? +1 : pageNum,
          }}
          scroll={{
            x: 1500,
            y: 400,
          }}
        />
      </div>
    </div>
  );
};

export default PincodeMaster;
