import React, { useState } from "react";
import { Button, Space, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

// import Heading from "../../../components/Heading";
import PacketingGuideTable from "./PacketingGuideTable";
import upload_pin_icon from "../../../assests/images/SVG/upload-pin-icon.svg";
import download_pin_icon from "../../../assests/images/SVG/download-pin.svg";
import print_logo from "../../../assests/images/SVG/master-print.svg";
import setting_logo from "../../../assests/images/master/setting.svg";
import search_icon from "../../../assests/images/search-icon.svg";
import back_img from "../../../assests/images/rightVector.svg";
import PacketConfigurationModal from "./PacketConfigurationModal";
import UploadDrawer from "../PincodeMaster/uploadDrawer";
import {
  getPacketingGuideSearch,
  getPacketingGuideMaster,
} from "../../../actionsMethods/masterMethods";
import { APIs } from "../../../utils/worker";

import "./style.scss";
const PacketingGuide = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getPacketingGuideSearchResponse = useSelector(
    (state) => state?.masterReducer?.getPacketingGuideSearchReducer,
    shallowEqual
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const sampleUrl = "/packet-service/v1/packeting-guide/download-sample";
  const uploadUrl = "/packet-service/v1/packeting-guide/excel-save";

  const addPacketMappingHandle = () => {
    navigate("/addpacket-mapping");
  };
  const packetConfigHandle = () => {
    if (!isModalOpen) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  };
  const searchHandle = (e) => {
    dispatch(getPacketingGuideSearch(e.target.value));
  };

  const downloadHandle = () => {
    window.open(`${APIs.baseURL}/packet-service/v1/packeting-guide/download`);
  };

  const printHandle = () => {
    window.open(`${APIs.baseURL}/packet-service/v1/packeting-guide/print`);
  };

  const uploadHandle = () => {
    !drawerOpen ? setDrawerOpen(true) : setDrawerOpen(false);
  };
  const handleClose = () => {
    setDrawerOpen(false);
    dispatch(getPacketingGuideMaster());
  };
  return (
    <div className="packeting-guide-container">
      {/* <Heading title={"Packeting Guide"} /> */}
      <p className="master-title-heading">
        <img
          src={back_img}
          onClick={() => navigate("/masters")}
          className="pointer"
        />
        <span>Packeting Guide</span>
      </p>
      <div className="packeting-guid-table">
        <div className="packeting-button-grp">
          <Space direction="vertical">
            <Input
              placeholder="Search by 
              (packet name / From Hub / From CP)"
              bordered
              style={{ borderRadius: "5px", height: "40px", width: "400px" }}
              suffix={<img src={search_icon} />}
              onChange={searchHandle}
            />
            <Space wrap>
              <Button size={"middle"} onClick={packetConfigHandle}>
                <img src={setting_logo} alt="setting button" />
                PACKET CONFIGURATION
              </Button>
              <Button size={"middle"} onClick={uploadHandle}>
                <img src={upload_pin_icon} alt="upload button" /> UPLOAD
              </Button>
              <Button size={"middle"} onClick={downloadHandle}>
                <img src={download_pin_icon} alt="download logo" /> DOWNLOAD
              </Button>
              <Button size={"middle"} onClick={printHandle}>
                <img src={print_logo} alt="print logo" /> PRINT
              </Button>
              <Button
                type="primary"
                size={"middle"}
                onClick={addPacketMappingHandle}
              >
                ADD PACKET MAPPING
              </Button>
            </Space>
          </Space>
        </div>
        <PacketingGuideTable
          getPacketingGuideSearchResponse={getPacketingGuideSearchResponse}
        />
        <PacketConfigurationModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
        <UploadDrawer
          title={"Packeting Guide Upload"}
          type="Packeting Guide"
          open={drawerOpen}
          url={uploadUrl}
          onClose={handleClose}
          sampleUrl={sampleUrl}
        />
      </div>
    </div>
  );
};

export default PacketingGuide;
