import { takeLatest } from "redux-saga/effects";

import { createWatcher } from "../utils/sagas";
import actions from "../constants/manifestActions";

import * as worker from "../workers/cashbooking/manifestWorker";

export const createManifestWatcher = createWatcher(
  actions.CREATE_MANIFEST,
  worker.createManifestWorker,
  takeLatest
);
export const getManifestWatcher = createWatcher(
  actions.GET_MANIFEST,
  worker.getManifestWorker,
  takeLatest
);
export const getManifestVehicleWatcher = createWatcher(
  actions.GET_MANIFEST_VEHICLE,
  worker.getManifestVehicleWorker,
  takeLatest
);
export const updateManifestDriverWatcher = createWatcher(
  actions.PUT_MANIFEST_DRIVER,
  worker.updateManifestDriverWorker,
  takeLatest
);

export const getPacketBarcodeWatcher = createWatcher(
  actions.GET_PACKET_BARCODE,
  worker.getPacketBarcodeWorker,
  takeLatest
);

export const getPacketListBySatusWatcher = createWatcher(
  actions.GET_PACKET_LIST_BY_STATUS,
  worker.getPacketListBySatusWorker,
  takeLatest
);

export const getShipmentListBySatusWatcher = createWatcher(
  actions.GET_SHIPMENT_LIST_BY_STATUS,
  worker.getShipmentListBySatusWorker,
  takeLatest
);

export const updatePacketStatusWatcher = createWatcher(
  actions.UPDATE_PACKET_STATUS,
  worker.updatePacketStatusWorker,
  takeLatest
);

export const getEligiblePacketsWatcher = createWatcher(
  actions.GET_ELIGIBLE_PACKET,
  worker.getEligiblePacketsWorker,
  takeLatest
);

export const createPacketsWatcher = createWatcher(
  actions.CREATE_PACKETS,
  worker.createPacketsWorker,
  takeLatest
);

export const patchPacketShipmentWatcher = createWatcher(
  actions.PATCH_PACKET_SHIPMENT,
  worker.patchPakcetShipmentWorker,
  takeLatest
);

export const deleteShipmentWatcher = createWatcher(
  actions.DELETE_SHIPMENT,
  worker.deleteShipmentWorker,
  takeLatest
);

export const patchManifestScanWatcher = createWatcher(
  actions.PATCH_MANIFEST_SCAN,
  worker.patchManifestScanWorker,
  takeLatest
);

export const getManifestedListWatcher = createWatcher(
  actions.GET_MANIFESTED_LIST,
  worker.getManifestedListWorker,
  takeLatest
);

export const putManifestLockWatcher = createWatcher(
  actions.PUT_MANIFEST_LOCK,
  worker.putManifestLockWorker,
  takeLatest
);

export const updateDriverDetailsWatcher = createWatcher(
  actions.UPDATE_DRIVE_DETAILS,
  worker.updateDriverDetailsWorker,
  takeLatest
);

export const getprintManifestWatcher = createWatcher(
  actions.PRINT_MANIFEST,
  worker.getprintManifestWorker,
  takeLatest
);

export const getViewAllBookingWatcher = createWatcher(
  actions.GET_VIEWALLBOOKINGS,
  worker.getViewAllBookingWorker,
  takeLatest
);

export const patchChildShipmentWatcher = createWatcher(
  actions.PATCH_CHILDSHIPMENT,
  worker.patchChildShipmentWorker,
  takeLatest
);
