import React from "react";
import { Row,Col,Button,Descriptions  } from "antd";
import { useNavigate } from "react-router";
import Heading from "../../components/Heading";
import edit_icon from "../../assests/images/edit-logo.svg";
const KycView = () => {
  const navigate = useNavigate();

  return (
    <div className="p-2">
      <Heading title={"Company Details"} navigateTo={"/profile"} />
      <div className="p-2 kyc-view-wrapper">
        <Row justify={"end"}>
          <Col><Button className="cancel-btn" onClick={()=>navigate("/request-for-branch")}>REQUEST FOR NEW BRANCH</Button></Col>
        </Row>
        <Row className="mt-2 mb-2 p-05 kyc-heading">
          <Col className="ml-2">KYC Details</Col>
        </Row>
        <Row className="kyc-box-container ml-2 mr-2">
          <Col>
            <Descriptions title="" layout="vertical" className="ml-2 mr-2 mt-1 mb-2 pob-view-style" colon={false}>
              <Descriptions.Item label="Business Pan Number" span={1}>ABCTY1234D</Descriptions.Item>              
              <Descriptions.Item label="Type of Business" span={1}>Private Limited</Descriptions.Item>              
              <Descriptions.Item label="" span={1}></Descriptions.Item>  

              <Col span={24} className="mt-2 mb-2">KYC Details (Aadhar Details)</Col>            

              <Descriptions.Item label="Aadhar Number" span={1}>3456 6534 3432</Descriptions.Item>              
              <Descriptions.Item label="Signatory PAN Number" span={1}>ABCTY1234D</Descriptions.Item>   
              <Descriptions.Item label="" span={1}></Descriptions.Item> 

              <Descriptions.Item label="Address" span={1}>#339, 14th B Cross Road, Sector 6, HSR Layout, Bengaluru, Karnataka 560102</Descriptions.Item> 
              <Descriptions.Item label="State Code" span={1}>KA</Descriptions.Item> 


            </Descriptions>
          </Col>
        </Row>
        <Row className="mt-2 mb-2 p-05 kyc-heading">
          <Col className="ml-2">CP Details</Col>
        </Row>
        <Row className="kyc-box-container ml-2 mr-2">
          <Col>
            <Descriptions title="" layout="vertical" className="ml-2 mr-2 mt-1 mb-2 pob-view-style" colon={false}>
              <Descriptions.Item label="Business Pan Number" span={1}>ABCTY1234D</Descriptions.Item>              
              <Descriptions.Item label="Type of Business" span={1}>Private Limited</Descriptions.Item>              
              <Descriptions.Item label="" span={1}></Descriptions.Item>  

              <Col span={24} className="mt-2 mb-2">KYC Details (Aadhar Details)</Col>            

              <Descriptions.Item label="Aadhar Number" span={1}>3456 6534 3432</Descriptions.Item>              
              <Descriptions.Item label="Signatory PAN Number" span={1}>ABCTY1234D</Descriptions.Item>   
              <Descriptions.Item label="" span={1}></Descriptions.Item> 

              <Descriptions.Item label="Address" span={1}>#339, 14th B Cross Road, Sector 6, HSR Layout, Bengaluru, Karnataka 560102</Descriptions.Item> 
              <Descriptions.Item label="State Code" span={1}>KA</Descriptions.Item> 


            </Descriptions>
          </Col>
        </Row>
        <Row className="mt-2 mb-2 p-05 kyc-heading" justify={"space-between"}>
          <Col className="ml-2">Personal Details</Col>
          <Col className="mr-2"> <img src={edit_icon}/></Col>
        </Row>
        <Row className="kyc-box-container ml-2 mr-2">
          <Col>
            <Descriptions title="" layout="vertical" className="ml-2 mr-2 mt-1 mb-2 pob-view-style" colon={false}>
              <Descriptions.Item label="Business Pan Number" span={1}>ABCTY1234D</Descriptions.Item>              
              <Descriptions.Item label="Type of Business" span={1}>Private Limited</Descriptions.Item>              
              <Descriptions.Item label="" span={1}></Descriptions.Item>  

              <Col span={24} className="mt-2 mb-2">KYC Details (Aadhar Details)</Col>            

              <Descriptions.Item label="Aadhar Number" span={1}>3456 6534 3432</Descriptions.Item>              
              <Descriptions.Item label="Signatory PAN Number" span={1}>ABCTY1234D</Descriptions.Item>   
              <Descriptions.Item label="" span={1}></Descriptions.Item> 

              <Descriptions.Item label="Address" span={1}>#339, 14th B Cross Road, Sector 6, HSR Layout, Bengaluru, Karnataka 560102</Descriptions.Item> 
              <Descriptions.Item label="State Code" span={1}>KA</Descriptions.Item> 


            </Descriptions>
          </Col>
        </Row>
        <Row className="mt-2 mb-2 p-05 kyc-heading" justify={"space-between"}>
          <Col className="ml-2">Miscellaneous Details</Col>
          <Col className="mr-2"> <img src={edit_icon}/></Col>
        </Row>
        <Row className="kyc-box-container ml-2 mr-2">
          <Col>
            <Descriptions title="" layout="vertical" className="ml-2 mr-2 mt-1 mb-2 pob-view-style" colon={false}>
              <Descriptions.Item label="Business Pan Number" span={1}>ABCTY1234D</Descriptions.Item>              
              <Descriptions.Item label="Type of Business" span={1}>Private Limited</Descriptions.Item>              
              <Descriptions.Item label="" span={1}></Descriptions.Item>  

              <Col span={24} className="mt-2 mb-2">KYC Details (Aadhar Details)</Col>            

              <Descriptions.Item label="Aadhar Number" span={1}>3456 6534 3432</Descriptions.Item>              
              <Descriptions.Item label="Signatory PAN Number" span={1}>ABCTY1234D</Descriptions.Item>   
              <Descriptions.Item label="" span={1}></Descriptions.Item> 

              <Descriptions.Item label="Address" span={1}>#339, 14th B Cross Road, Sector 6, HSR Layout, Bengaluru, Karnataka 560102</Descriptions.Item> 
              <Descriptions.Item label="State Code" span={1}>KA</Descriptions.Item> 

            </Descriptions>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default KycView;
