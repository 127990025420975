/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tabs, Popconfirm } from "antd";
import { useDispatch } from "react-redux";
import Heading from "../../../components/Heading";
import CreateSorting from "./createSorting";
import ViewSorting from "./viewSorting";
import { clearHubBasedSortingGuide } from "../../../actionsMethods/masterMethods";
import "./style.scss";
const SortingGuide = () => {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState("1");
  const [editId, setEditId] = useState();
  const [showPopUp, setShowPopUp] = useState(false);

  const changeTab = (key) => {
    dispatch(clearHubBasedSortingGuide());
    setActiveKey(key);
    setEditId();
  };

  const jumpCreateTab = (id) => {
    setEditId(id);
    setActiveKey("1");
  };

  const jumpViewTab = () => {
    setActiveKey("2");
    dispatch(clearHubBasedSortingGuide());
  };

  const cancel = (e) => {};
  const showPopup = () => {
    setShowPopUp(true);
  };

  return (
    <div className="p-2-tab">
      <Heading title={"Sorting Guide"} />
      <div className="rate-card-tab-container">
        <Tabs
          destroyInactiveTabPane
          activeKey={activeKey}
          //onChange={changeTab}
          items={[
            {
              label: <span onClick={() => changeTab("1")}>Sorting Guide</span>,
              key: "1",
              children: (
                <CreateSorting
                  changeTab={jumpViewTab}
                  editId={editId}
                  showPopUp={showPopup}
                  noPopup={() => setShowPopUp(false)}
                />
              ),
            },
            {
              label: showPopUp ? (
                <Popconfirm
                  title={
                    <>
                      <span>By switching tab you will lose data,</span>
                      <br />
                      <span>Are you sure to change the tab?</span>
                    </>
                  }
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => changeTab("2")}
                  onCancel={cancel}
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  }>
                  View Sorting
                </Popconfirm>
              ) : (
                <span onClick={() => changeTab("2")}>View Sorting</span>
              ),
              key: "2",
              children: <ViewSorting changeTab={jumpCreateTab} />,
            },
          ]}
        />
      </div>
    </div>
  );
};
export default SortingGuide;
