import keyMirror from "keymirror";

const actions = {
  LOGIN_USER: null,
  LOGIN_USER_SUCCESS: null,
  LOGIN_USER_ERROR: null,
  LOGIN_USER_CLEAR: null,

  GET_USER: null,
  GET_USER_SUCCESS: null,
  GET_USER_ERROR: null,
  GET_USER_CLEAR: null,

  GET_CP_PREMISES: null,
  GET_CP_PREMISES_SUCCESS: null,
  GET_CP_PREMISES_ERROR: null,
  GET_CP_PREMISES_CLEAR: null,

  GET_SENDER_PINCODE: null,
  GET_SENDER_PINCODE_SUCCESS: null,
  GET_SENDER_PINCODE_ERROR: null,
  GET_SENDER_PINCODE_CLEAR: null,

  GET_GST_DETAILS: null,
  GET_GST_DETAILS_SUCCESS: null,
  GET_GST_DETAILS_ERROR: null,
  GET_GST_DETAILS_CLEAR: null,

  GET_BARCODE_ID: null,
  GET_BARCODE_ID_SUCCESS: null,
  GET_BARCODE_ID_ERROR: null,
  GET_BARCODE_ID_CLEAR: null,

  GET_BOOKING_DETAILS: null,
  GET_BOOKING_DETAILS_SUCCESS: null,
  GET_BOOKING_DETAILS_ERROR: null,
  GET_BOOKING_DETAILS_CLEAR: null,

  GET_SERVICEABILITY_DETAILIS: null,
  GET_SERVICEABILITY_DETAILIS_SUCCESS: null,
  GET_SERVICEABILITY_DETAILIS_ERROR: null,
  GET_SERVICEABILITY_DETAILIS_CLEAR: null,

  GET_PICKUP_PIN: null,
  GET_PICKUP_PIN_SUCCESS: null,
  GET_PICKUP_PIN_ERROR: null,
  GET_PICKUP_PIN_CLEAR: null,

  OPEN_CAL: null,
  OPEN_CAL_SUCCESS: null,
  OPEN_CAL_ERROR: null,
  OPEN_CAL_CLEAR: null,
  OPEN_CAL_SUB: null,
  OPEN_CAL_ADD: null,

  GET_CAL_TATCHART: null,
  GET_CAL_TATCHART_SUCCESS: null,
  GET_CAL_TATCHART_ERROR: null,
  GET_CAL_TATCHART_CLEAR: null,

  GET_PINCODE_AREA: null,
  GET_PINCODE_AREASUCCESS: null,
  GET_PINCODE_AREAERROR: null,
  GET_PINCODE_AREACLEAR: null,

  GET_ACCOUNT_SERVICE: null,
  GET_ACCOUNT_SERVICE_SUCCESS: null,
  GET_ACCOUNT_SERVICE_ERROR: null,
  GET_ACCOUNT_SERVICE_CLEAR: null,

  CREATE_SHIPMENT: null,
  CREATE_SHIPMENT_SUCCESS: null,
  CREATE_SHIPMENT_ERROR: null,
  CREATE_SHIPMENT_CLEAR: null,

  UPDATE_SHIPMENT: null,
  UPDATE_SHIPMENT_SUCCESS: null,
  UPDATE_SHIPMENT_ERROR: null,
  UPDATE_SHIPMENT_CLEAR: null,

  UPDATE_AWB_NUM: null,
  UPDATE_AWB_NUM_SUCCESS: null,
  UPDATE_AWB_NUM_ERROR: null,
  UPDATE_AWB_NUM_CLEAR: null,

  GET_SINGLE_VIEWBOOKING: null,
  GET_SINGLE_VIEWBOOKING_SUCCESS: null,
  GET_SINGLE_VIEWBOOKING_ERROR: null,
  GET_SINGLE_VIEWBOOKING_CLEAR: null,

  UPLOADED_FILES: null,
  UPLOADED_FILES_SUCCESS: null,
  UPLOADED_FILES_ERROR: null,
  UPLOADED_FILES_CLEAR: null,

  GET_CHILDPACKETIDs: null,
  GET_CHILDPACKETIDs_SUCCESS: null,
  GET_CHILDPACKETIDS__ERROR: null,
  GET_CHILDPACKETIDs__CLEAR: null,

  GET_MANIFEST: null,
  GET_MANIFEST_SUCCESS: null,
  GET_MANIFEST_ERROR: null,
  GET_MANIFEST_CLEAR: null,

  GET_POSTAL_PINCODE: null,
  GET_POSTAL_PINCODE_SUCCESS: null,
  GET_POSTAL_PINCODE_ERROR: null,
  GET_POSTAL_PINCODE_CLEAR: null,

  CREATE_CHILD_SHIPMENT: null,
  CREATE_CHILD_SHIPMENT_SUCCESS: null,
  CREATE_CHILD_SHIPMENT_ERROR: null,
  CREATE_CHILD_SHIPMENT_CLEAR: null,

  GET_E_WAY_BILL: null,
  GET_E_WAY_BILL_SUCCESS: null,
  GET_E_WAY_BILL_ERROR: null,
  GET_E_WAY_BILL_CLEAR: null,

  //Vehicle onboard
  ONBOARD_VEHICLE_: null,
  ONBOARD_VEHICLE_SUCCESS: null,
  ONBOARD_VEHICLE_ERROR: null,
  ONBOARD_VEHICLE_CLEAR: null,

  GET_SINGLE_ONBOARDED_VEHICLE_: null,
  GET_SINGLE_ONBOARDED_VEHICLE_SUCCESS: null,
  GET_SINGLE_ONBOARDED_VEHICLE_ERROR: null,
  GET_SINGLE_ONBOARDED_VEHICLE_CLEAR: null,

  DISBALE_ONBOARDED_VEHICLE_: null,
  DISBALE_ONBOARDED_VEHICLE_SUCCESS: null,
  DISBALE_ONBOARDED_VEHICLE_ERROR: null,
  DISBALE_ONBOARDED_VEHICLE_CLEAR: null,

  UPDATE_ONBOARDED_VEHICLE_: null,
  UPDATE_ONBOARDED_VEHICLE_SUCCESS: null,
  UPDATE_ONBOARDED_VEHICLE_ERROR: null,
  UPDATE_ONBOARDED_VEHICLE_CLEAR: null,

  GET_ALL_ONBOARDED_VEHICLES_TABLE: null,
  GET_ALL_ONBOARDED_VEHICLES_TABLE_SUCCESS: null,
  GET_ALL_ONBOARDED_VEHICLES_TABLE_ERROR: null,
  GET_ALL_ONBOARDED_VEHICLES_TABLE_CLEAR: null,

  ACCOUNT_CREATE_SHIPMENT: null,
  ACCOUNT_CREATE_SHIPMENT_SUCCESS: null,
  ACCOUNT_CREATE_SHIPMENT_ERROR: null,
  ACCOUNT_CREATE_SHIPMENT_CLEAR: null,

  ACCOUNT_UPDATE_SHIPMENT: null,
  ACCOUNT_UPDATE_SHIPMENT_SUCCESS: null,
  ACCOUNT_UPDATE_SHIPMENT_ERROR: null,
  ACCOUNT_UPDATE_SHIPMENT_CLEAR: null,
};

export default keyMirror(actions);
