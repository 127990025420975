import React, { useState } from "react";
import { Tabs } from "antd";
import { useNavigate } from "react-router";
import PrsGeneration from "./PrsGeneration";
import ViewPrs from "./ViewPrs";
import back_img from "../../assests/images/rightVector.svg";
import "./style.scss";

const PrsGenerationTab = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState("1");
  const [stepStatus,setStepStatus] = useState(false);
  const items = [
    {
      key: "1",
      label: "PRS Generation",
      children: <PrsGeneration setStep={setStep}/>,
    },
    {
      key: "2",
      label: "View PRS",
      children: <ViewPrs stepStatus={stepStatus}/>,
    },
   
  ];

  const onChange = (key) => {
    setStep(key);
    !stepStatus ? setStepStatus(true) : setStepStatus(false);
  };
  
  return (
    <div className="p2 consolidated-tab-cntr">
      <div className="top-wrapper space-between ">
        <div className="heading mb-1 align-center">
          <img
            src={back_img}
            onClick={() => navigate("/last-mile-operations")}
            style={{ cursor: "pointer" }}
          />
          <p
            style={{
              margin: "0",
              paddingLeft: "5px",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "32px",
              letterSpacing: "-0.25px",
              color: "#061624",
            }}
          >
            <b>PRS Generation</b>
          </p>
        </div>
      </div>
      <Tabs
        defaultActiveKey={"1"}
        activeKey={step}
        items={items}
        onChange={onChange}
      />
    </div>
  );
};
export default PrsGenerationTab;

