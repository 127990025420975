import audit from "../assests/images/guides/audit-icon.svg";
import audit_report from "../assests/images/guides/audit-report.svg";
import constants from "../constants/constants";

const { ROLES } = constants;
const { HUB_ADMIN } = ROLES;

export const auditPath = [
  {
    id: 1,
    imgSrc: audit,
    title: "Audit",
    route: "/audit-scanned",
    disable: false,
    childPresent: false,
    role: [HUB_ADMIN],
  },
  {
    id: 2,
    imgSrc: audit_report,
    title: "Audit Report",
    route: "",
    disable: true,
    childPresent: false,
    role: [HUB_ADMIN],
  },
];
