/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import "./style.scss";

import Heading from "../../../components/Heading";
import {
  Input,
  DatePicker,
  Table,
  Button,
  Space,
  message,
  Checkbox,
} from "antd";
import { FilterFilled } from "@ant-design/icons";
import moment from "moment";
import search_icon from "../../../assests/images/search-icon.svg";
import { ReactComponent as Check } from "../../../assests/images/Check.svg";
import { ReactComponent as CloseOutlined } from "../../../assests/images/CloseOutlined.svg";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import nodeAPI from "../../../api/nodeUrls";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import API from "../../../api";
import { camelCase } from "../../../utils";

function LCRGenerationNew() {
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(false);
  const searchInput = useRef(null);
  const [dataSource, setDataSource] = useState([]);
  const { NEW_LCR_REPORT } = nodeAPI;
  const [total, setTotal] = useState();
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [dateRangeStatus, setDateRangeStatus] = useState(false);
  const [startAndEndDate, setStartAndEndDate] = useState({});
  const [serachValue, setSerachValue] = useState(null);

  const [scrollY, setScrollY] = useState(400);
  const calculateScrollY = () => {
    const screenHeight = window.innerHeight;
    const tableOffset = 400;
    setScrollY(screenHeight - tableOffset);
  };

  useEffect(() => {
    calculateScrollY(); // Calculate on mount
    window.addEventListener("resize", calculateScrollY);
    let todaysDate = moment(new Date(), "ddd MMM DD YYYY HH:mm:ss").format(
      "YYYY-MM-DD"
    );

    let twoDaysBack = moment().subtract(1, "days").format("YYYY-MM-DD");
    setStartAndEndDate({ start: todaysDate, end: twoDaysBack });
    let fromAndTo = `&from_date=${twoDaysBack}&to_date=${todaysDate}`;
    getLCRTableData("?page=0&limit=100", fromAndTo);

    return () => {
      window.removeEventListener("resize", calculateScrollY);
    };
  }, []);

  const colorObj = {
    INITIATED: "#007BFF", // Bright Blue
    STARTED: "#17A2B8", // Teal
    "IN-TRANSIT": "#FFC107", // Amber/Yellow
    COMPLETED: "#28A745", // Green
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const navigate = useNavigate();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        // style={{
        //   padding: 8,
        // }}
        className="fitlerDesing"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] || ""}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters); // Clear filters
              confirm();
              document
                .querySelector(".ant-dropdown")
                ?.classList.add("ant-dropdown-hidden"); // Hide dropdown
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* "Filter" and "Close" buttons removed */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),

    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => <div>{text}</div>,
  });

  const handleConfirm = (confirm) => {
    confirm();
  };
  const getColumnCheckboxFilterProps = (dataIndex, potions) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div>
        <Checkbox.Group
          options={potions}
          value={selectedKeys}
          onChange={(checkedValues) => setSelectedKeys(checkedValues)}
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "5px 12px",
          }}
        />
        <div
          className="flex-end"
          style={{
            borderTop: " 1px solid #f0f0f0",
            padding: "5px 0",
            margin: "5px 0",
          }}
        >
          <Button
            type="primary"
            size="small"
            onClick={() => handleConfirm(confirm)}
          >
            OK
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (typeof record[dataIndex] === "boolean") {
        return record[dataIndex] === value;
      }
      return record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : "";
    },
  });

  const columns = [
    {
      title: "Vehicle LCR No.",
      dataIndex: "vehicle_lcr_id",
      key: "vehicle_lcr_id",
      width: 130,
      ...getColumnSearchProps("vehicle_lcr_id"),
      filterDropdownProps: {
        placement: "bottomLeft",
      },

      render: (text, record) => (
        <span
          className="span-link"
          onClick={() => navigate("/lcr-report", { state: record })}
        >
          {text || "------"}
        </span>
      ),
    },
    {
      title: "Created On",
      dataIndex: "created_date",
      key: "created_date",
      width: 150,
      render: (text, rec) => (
        <div>{moment?.utc(text)?.format("DD-MM-YYYY hh:mm A")}</div>
      ),
    },
    {
      title: "Mode",
      dataIndex: "travel_by",
      key: "travel_by",
      width: 100,
      ...getColumnCheckboxFilterProps("travel_by", [
        { label: "Surface", value: "Surface" },
        { label: "Air", value: "air" },
      ]),

      render: (text) => <div>{camelCase(text)}</div>,
    },

    {
      title: "Current trip status",
      dataIndex: "status",
      key: "status",
      width: 150,
      ...getColumnCheckboxFilterProps("status", [
        { label: "Started", value: "STARTED" },
        { label: "Completed", value: "COMPLETED" },
        { label: "Initiated", value: "INITIATED" },
        { label: "In-Transit", value: "IN-TRANSIT" },
      ]),
      render: (text) => (
        <div className="center">
          <div
            style={{
              borderRadius: "15px",
              padding: "4px",
              minWidth: "100px",
              color: "#fff",
              background: colorObj[text],
            }}
          >
            {text}
          </div>
        </div>
      ),
    },
    {
      title: "Vehicle/Flight No.",
      dataIndex: "vehicle_num",
      key: "vehicle_num",
      width: 150,
    },
    {
      title: "Destination Hub",
      dataIndex: "destination_hub",
      key: "destination_hub",
      width: 150,
      ...getColumnSearchProps("destination_hub"),
      render: (text) => <div>{camelCase(text)}</div>,
    },
    {
      title: "Current Hub",
      dataIndex: "current_hub",
      key: "current_hub",
      width: 140,
      ...getColumnSearchProps("current_hub"),
      render: (text) => <div>{camelCase(text)}</div>,
    },
    {
      title: "Bag Out | In",
      dataIndex: "bagInOut",
      key: "bagInOut",
      width: 140,
      ...getColumnCheckboxFilterProps("bagInOut", [
        {
          label: <Check />,
          value: true,
        },
        {
          label: <CloseOutlined />,
          value: false,
        },
      ]),
      render: (text, record) => (
        <div className="bg-col-container">
          <div>{`${record?.bag_out_count} | ${record?.bag_in_count}  `}</div>
          <div>{text !== "" && (text ? <Check /> : <CloseOutlined />)}</div>
        </div>
      ),
    },
    {
      title: "Shipment Out | In",
      dataIndex: "shipmentInOut",
      key: "shipmentInOut",
      width: 150,
      ...getColumnCheckboxFilterProps("shipmentInOut", [
        {
          label: <Check />,
          value: true,
        },
        {
          label: <CloseOutlined />,
          value: false,
        },
      ]),
      render: (text, record) => (
        <div className="bg-col-container">
          <div>{`${record?.shipment_out_count} | ${record?.shipment_in_count}`}</div>
          <div>{text ? <Check /> : <CloseOutlined />}</div>
        </div>
      ),
    },

    {
      title: "Updated Date",
      key: "latest_operation_time",
      dataIndex: "latest_operation_time",
      width: 150,
      render: (text, rec) => (
        <div>
          {/* {moment(text).tz("Asia/Kolkata").format("DD-MM-YYYY hh:mm A")} */}
          {moment?.utc(text)?.format("DD-MM-YYYY hh:mm A")}
        </div>
      ),
    },
  ];

  function checkBagReceived(report, bagOutKey, bagInKey) {
    let bagReceived = "";

    if (+report[bagOutKey] === +report[bagInKey]) {
      if (report[bagOutKey] == 0 && report[bagInKey] == 0) {
        bagReceived = "";
      } else {
        bagReceived = true;
      }
    } else {
      bagReceived = false;
    }

    return bagReceived;
  }

  const getLCRTableData = async (pagination, fromAndTo, searchValue = null) => {
    setLoading(true);

    const response = await API.Api(
      searchValue
        ? NEW_LCR_REPORT + pagination + fromAndTo + "&search=" + searchValue
        : NEW_LCR_REPORT + pagination + fromAndTo
    ).NodeGetWithPremise();
    if (response.status) {
      setLoading(false);
      let data = response?.response?.data;
      const temp = data.data.map((item) => ({
        ...item,
        shipmentInOut: checkBagReceived(
          item,
          "shipment_out_count",
          "shipment_in_count"
        ),

        bagInOut: checkBagReceived(item, "bag_out_count", "bag_in_count"),
      }));
      setDataSource(temp);
      setTotal(data.pagination?.totalItems);
    } else {
      setDataSource([]);
      message.error("Something went wrong");
      setLoading(false);
    }
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  function handlePageChange(pagination, filters, sorter, extra) {
    if (extra?.action !== "filter") {
      setPageSize(pagination.pageSize);
      setPageNum(pagination.current);
      getLCRTableData(
        `?page=${pagination.current - 1}&limit=${pagination.pageSize}`,
        `&from_date=${startAndEndDate?.start}&to_date=${startAndEndDate?.end}`
      );
    }
  }
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleOnclick = () => {
    getLCRTableData(
      `?page=${pageNum - 1}&limit=${pageSize}`,
      `&from_date=${startAndEndDate?.start}&to_date=${startAndEndDate?.end}`
    );
  };

  const handleDateChange = (params, p2) => {
    const StartDate = moment(p2[0], "DD/MM/YYYY").format("YYYY-MM-DD");
    const endDate = moment(p2[1], "DD/MM/YYYY").format("YYYY-MM-DD");
    setStartAndEndDate({ start: StartDate, end: endDate });
    if (p2[0]?.length === 0) {
      setDateRangeStatus(false);
    } else {
      setDateRangeStatus(true);
    }
  };

  const handleInputChange = (e) => {
    setSerachValue(e.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (serachValue?.length > 0) {
        getLCRTableData(
          `?page=${pageNum - 1}&limit=${pageSize}`,
          `&from_date=${startAndEndDate?.start}&to_date=${startAndEndDate?.end}`,
          serachValue
        );
      } else if (serachValue === "") {
        getLCRTableData(
          `?page=${pageNum - 1}&limit=${pageSize}`,
          `&from_date=${startAndEndDate?.start}&to_date=${startAndEndDate?.end}`,
          null
        );
      }
    }, 900);
    return () => clearTimeout(delayDebounceFn);
  }, [serachValue]);

  return (
    <div className="p-2-tab">
      <Heading title={"LCR"} />
      <div className="consolidated-manifest-cntr">
        <div className="header" style={{ flexWrap: "wrap" }}>
          <div className="left">
            <Input
              placeholder="Search LCR Number, Hub Name..."
              bordered
              prefix={<img src={search_icon} />}
              style={{
                borderRadius: "5px",
                height: "40px",
                margin: "15px 50px 15px 12px",
              }}
              onChange={handleInputChange}
            />
          </div>
          <div className="align-center ">
            <RangePicker
              format={"DD/MM/YYYY"}
              onChange={handleDateChange}
              style={{
                borderRadius: "5px 0 0 5px",
                height: "40px",
                width: "230px",
              }}
              disabledDate={(current) => disabledDate(current)}
              defaultValue={[moment().subtract(1, "days"), moment()]}
            />
            <Button
              className="center mr-1"
              disabled={!dateRangeStatus}
              style={{
                width: "40px",
                height: "40px",
                border: "1px solid #d9d9d9",
                borderRadius: "0 5px 5px 0",
              }}
              onClick={handleOnclick}
            >
              <img src={search_icon} />
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={handlePageChange}
          pagination={{
            pageSizeOptions: [50, 100, 150, 200, 500],
            pageSize: pageSize,
            total: total,
            itemRender: itemRender,
            current: pageNum,
          }}
          scroll={{ x: 1300, y: scrollY }}
          loading={{
            indicator: (
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            ),
            spinning: loading,
          }}
        />
      </div>
    </div>
  );
}

export default LCRGenerationNew;
