/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Modal,
  DatePicker,
  Spin,
  message,
  Row,
  Col,
  Button,
  Tooltip,
  Select,
  Form,
} from "antd";
import { dateFormat_dmy } from "../../../utils";
import moment from "moment";
import urls from "../../../api/urls";
import API from "../../../api";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import driver_user_new from "../../../assests/images/middleMile/driver-user.png";
import { capitaliseWithHyphen } from "../../../utils";
import "./style.scss";
const { GET_ROUT_AND_TRIP_SCHEDULE,GET_ROUT_AND_TRIP_SCHEDULE_SURFACE,GET_DRIVER_LIST } = urls;

const tripScheduleModal = ({
  isModalOpen,
  setIsModalOpen,
  modalClickHandle,
  setIndex,
  optionalTripFun
}) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [cardStatus, setCardStatus] = useState(true);
  const [btnDisable, setBtnDisable] = useState(false);
  const [mode,setMode] = useState("SURFACE");
  // const hubType = JSON.parse(localStorage.getItem("userinfo"))?.hubType?.toUpperCase();
  
  const handleCancel = () => {
    setIsModalOpen(false);
    modalClickHandle("CANCEL");
    setResData([]);
  };
  const onDateChange = (date, dateString) => {
    let data = dateString?.replaceAll("/", "-");
    setDate(data);
    if (dateString.length === 0) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  };
  const cardClickHandle = (index) => {
    setIndex(index);
    setResData([]);
    setIsModalOpen(false);
  };
  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };
  useEffect(() => {
    const today = new Date();
    const date =
      (today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()) +
      "-" +
      (today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    const time = moment().format("HH:mm:ss");
    setTime(time);
    setDate(date);
  }, []);

  const finishHandle = async (e) => {
    const today = new Date();
    const presentDate =
      today.getDate() +
      "-" +
      (today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    let dateCheck = presentDate === date;
    setIndex(null);
    setLoading(true);
    let response = await API.Api(e.travellBy === "SURFACE" ?
      `${GET_ROUT_AND_TRIP_SCHEDULE}${date}/andTime/${
        dateCheck ? time : "00:00:00"
      }/for/${e.travellBy}` : `${GET_ROUT_AND_TRIP_SCHEDULE_SURFACE}${e.travellBy}`
    ).getWithAllHeaders();
    if (response?.status) {
      if(e.travellBy === "SURFACE"){
        optionalTripFun(false);
        setLoading(false);
        modalClickHandle("SAVE", "SURFACE", response?.response?.data?.data);
        setResData(response?.response?.data?.data);
        setCardStatus(true);
      } else {
        setLoading(false);
        modalClickHandle("SAVE", "AIR", response?.response?.data?.data);
        setIsModalOpen(false);
        setResData([]);
      }
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message);
      setCardStatus(false);
    }
  };
  const selectHandle = (e)=>{
    setMode(e);
    if(e === "AIR")setResData([]);
  };
  const confirmationHandle = async()=>{
    let response = await API.Api(
      `${GET_ROUT_AND_TRIP_SCHEDULE_SURFACE}SURFACE`
    ).getWithAllHeaders();
    if(mode === "SURFACE"){
      optionalTripFun(true);
      setLoading(false);
      modalClickHandle("SAVE", "SURFACE", response?.response?.data?.data);
      // setResData(response?.response?.data?.data);
      setIsModalOpen(false);
      setResData([]);
      setIndex(null);
    }
  };
  useEffect(()=>{
    if(isModalOpen){
      setCardStatus(true);
      setMode("SURFACE");
      optionalTripFun(false);
    }
  },[isModalOpen]);
  return (
    <Spin
      spinning={loading}
      size="large"
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <Modal
        title="Get Trip"
        destroyOnClose
        open={isModalOpen}
        onCancel={handleCancel}
        maskClosable={false}
        footer={false}
        className="trip-schedule-modal-wrapper"
      >
        <Form
          onFinish={finishHandle}
          initialValues={{
            travellBy: "SURFACE",
          }}
        >
          <Row
            direction="vertical"
            className="m-1"
            gutter={12}
            justify="center"
          >
            <Col span={12}>
              <Form.Item
                name="travellBy"
                rules={[
                  {
                    required: false,
                    message: "Please select mode",
                  },
                ]}
              >
                <Select
                  // disabled={hubType==="MINI HUB"}
                  defaultValue={"Surface"}
                  onChange={selectHandle}
                  className="input-field-style"
                  placeholder="Select mode"
                  optionFilterProp="children"
                  options={[
                    {
                      value: "SURFACE",
                      label: "Surface",
                    },
                    {
                      value: "AIR",
                      label: "Air",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="date">
                <DatePicker
                  style={{ color: "black" }}
                  disabled
                  format={dateFormat_dmy}
                  onChange={onDateChange}
                  disabledDate={disabledDate}
                  defaultValue={moment(new Date(), dateFormat_dmy)}
                  className="input-field-style"
                />
              </Form.Item>
            </Col>
          </Row>
          {mode === "SURFACE" ? 
            <Row justify={"center"}>
              <Col span={24} style={{color:"red"}}>Do you want to fetch trip details from the scheduler?</Col>
              <Col span={24}>If No, then add the trip details like the vehicle and route</Col>
              <Col span={24} className="d-flex mt-1">
                <Button  className="save-btn" onClick={confirmationHandle}>YES</Button>
                <Button htmlType="submit" className="ml-1 cancel-btn">NO</Button>
              </Col>
            </Row> :null }
          {mode === "AIR" ?
            <Row gutter={12} justify="end">
              <Col>
                <Button onClick={handleCancel} className="cancel-btn">
                CANCEL
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  className="save-btn"
                  disabled={btnDisable}
                >
                GET TRIP
                </Button>
              </Col>
            </Row> : null}
        </Form>

        {cardStatus ? (
          <Row gutter={24}>
            {resData?.map((e, index) => {
              return (
                <Col
                  onClick={() => {
                    cardClickHandle(index);
                  }}
                  span={12}
                  title={e?.tripId}
                  key={index}
                  id={e.driverId}
                >
                  <div
                    className="card pointer"
                    style={{ background: e?.driverColor || "#F0FAEF" }}
                  >
                    <div>
                      <div className="d-flex">
                        <img src={driver_user_new} width={35} height={35} />
                        <div className="ml-1">
                          <p className="light-color">Name</p>
                          <p className="fw-500 vehicle-title">
                            {e?.driverName}
                          </p>
                        </div>
                      </div>

                      <div className="space-between vehicle-title">
                        <div>
                          <p className="light-color">Trip Id</p>

                          <p className="fw-500 vehicle-title">{e?.tripId}</p>
                        </div>
                        <div>
                          <p className="light-color">Vehicle Number</p>
                          <p className="fw-500 vehicle-title">{e?.vehicleNo}</p>
                        </div>
                      </div>
                      <p className="light-color" style={{ marginTop: "-8px" }}>
                        Route
                      </p>
                      <Tooltip title={capitaliseWithHyphen(e?.routeName)}>
                        <p
                          className="fw-500 vehicle-title"
                          style={{ maxWidth: "230px" }}
                        >
                          {capitaliseWithHyphen(e?.routeName)}
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        ) : null}
      </Modal>
    </Spin>
  );
};

export default tripScheduleModal;
