/* eslint-disable no-unused-vars */
import React from "react";
import { Row, Col, Spin } from "antd";

import "./style.scss";
const ForgotContainer = ({ page }) => {
  return (
    <>
      <div className="forgot-container">
        <Spin spinning={false}>
          <Row>
            <Col className="img-parent" md={24} lg={12} xl={12}>
              <div className="img-div"></div>
            </Col>
            <Col
              md={24}
              sm={24}
              xs={24}
              lg={12}
              xl={12}
              className="right-container"
            >
              <div className="form-div">
                <div>{page}</div>
              </div>
              <div className="footer">
                <p className="footer-content">
                  Copyright © 2023 Shree Maruti Integrated Logistics Limited.
                </p>
              </div>
            </Col>
          </Row>
        </Spin>
      </div>
    </>
  );
};

export default ForgotContainer;
