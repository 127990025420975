import React from "react";
import { Button, Table } from "antd";
import { serialNum } from "../../../utils";
import print_icon from "../../../assests/images/master/print.svg";
import appLogo from "../../../assests/images/smcsLogo.svg";
import "./style.scss";

const PrintLcr = () => {
  const columns = [
    {
      title: "Sl No",
      align: "center",
      with: 10,
      render: (a, b, idx) => serialNum(10, 1, idx),
    },
    {
      title: "Bag ID",
      dataIndex: "bagID",
      key: "bagID",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "AWB No",
      dataIndex: "awbNo",
      key: "awbNo",
      render: (text) => (
        <div
          style={{ wordWrap: "break-word", width: "250px" }}
        >{`${text},`}</div>
      ),
    },
    {
      title: "Child AWB No",
      dataIndex: "childAWBNo",
      key: "childAWBNo",
      render: (text) => (
        <div
          style={{ wordWrap: "break-word", width: "165px" }}
        >{`${text},`}</div>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      bagID: 4576543567,
      awbNo: [4576543567, 4576543567, 4576543567, 4576543567],
      childAWBNo: [4576543567, 4576543567],
    },
    {
      key: "2",
      bagID: 4576543567,
      awbNo: [4576543567, 4576543567, 4576543567, 4576543567],
      childAWBNo: [4576543567, 4576543567],
    },
    {
      key: "3",
      bagID: 4576543567,
      awbNo: [4576543567, 4576543567, 4576543567, 4576543567],
      childAWBNo: [4576543567, 4576543567],
    },
  ];
  return (
    <div className="p-2">
      <div className="print-lcr-container">
        <div className="heading-lcr p-1-5">Print Manifest</div>
        <div className="lcr-report-wrapper">
          <div className="download-btn">
            <Button>
              <img src={print_icon} /> PRINT
            </Button>
          </div>
          <div className="lcr-report ">
            <div className="wrapper-print m-2">
              <img src={appLogo} />
              <hr />
              <div className="address-print space-between mt-1">
                <div>
                  <p>
                    <b>LCR No: </b>
                    45365656565668
                  </p>
                  <p>
                    <b>Destination: </b>
                    Ahmedabad Aslali Hub
                  </p>
                  <p>
                    <b>Mode of Transport: </b>
                    Air
                  </p>
                  <p>
                    <b>Co-Loader: </b>
                    Indigo
                  </p>
                </div>

                <div className="d-flex">
                  <div className="mr-1-5">
                    <p>
                      <b>FromDate: </b>
                      05/04/2023
                    </p>
                    <p>
                      <b>Vehicle No: </b>
                      556667
                    </p>
                    <p>
                      <b>Driver Name: </b>
                      556667
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>ToDate: </b>
                      05/04/2023
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Table
              columns={columns}
              dataSource={data || "------"}
              pagination={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintLcr;
