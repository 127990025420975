/* eslint-disable indent */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  message,
  Row,
  Col,
  Modal,
  Empty,
  Tooltip,
  Space,
  Collapse,
  Checkbox,
  Table,
  Input,
} from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RightOutlined, DownOutlined, SyncOutlined } from "@ant-design/icons";
import { getToken } from "../../../utils/worker";
import {
  getHubList,
  getHubBasedSortingGuide,
  viewSortingGuide,
} from "../../../actionsMethods/masterMethods";
import disable_edit from "../../../assests/images/master/edit-disable.svg";
import edit_icon from "../../../assests/images/edit-logo.svg";
import search_icon from "../../../assests/images/search-icon.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import { capitaliseWithHyphen } from "../../../utils";

const ViewSorting = ({ changeTab }) => {
  const { Panel } = Collapse;
  let token = getToken();
  const dispatch = useDispatch();
  const [hubList, setHubList] = useState([]);
  const [sortingRules, setSortingRules] = useState([]);
  const [selectSorting, setSelectSorting] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [sortingRulesTemp, setSortingRulesTemp] = useState([]);
  const [sortingIsUpdated, setSortingIsUpdated] = useState(false);
  const [viewHub, setViewHub] = useState([]);
  let userData = JSON.parse(localStorage.getItem("userinfo"));
  let userid = JSON.parse(localStorage.getItem("userId"));
  let roleHub = userData?.roles?.[0] == "ROLE_HUBADMIN";
  const hubData = useSelector(
    (state) => state.masterReducer.getHubListReducer,
    shallowEqual
  );

  const { result: hubResult, error: hubErr, fetching: loading1 } = hubData;

  const hubBasedSorting = useSelector(
    (state) => state.masterReducer.getHubBasedSortingGuideReducer,
    shallowEqual
  );

  const {
    result: hubBasedSortResult,
    error: hubBasedErr,
    fetching: loading2,
  } = hubBasedSorting;

  const tableLoading = {
    spinning: loading1 || loading2,
    indicator: (
      <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
    ),
  };

  useEffect(() => {
    dispatch(getHubList());
  }, []);

  useEffect(() => {
    if (hubResult) {
      let hubResultTemp = hubResult;
      let currentHub = hubResultTemp.find(
        (e) => e.premiseId == userid?.userInfo?.premiseId
      );
      if (currentHub) {
        hubResultTemp = [currentHub];
        const hubTemp = [];
        hubResultTemp
          .sort((a, b) => a.premiseName.localeCompare(b.premiseName))
          .map((e) => {
            hubTemp.push({
              value: e.premiseId,
              label: e.premiseName
                .split(" ")
                .map(
                  (e) => e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()
                )
                .join(" "),
              zone: e.zone,
              key: e.premiseId,
            });
          });
        setViewHub(hubTemp);
      }

      let hubBasedResult = hubResult;
      let temp = [];
      hubBasedResult
        .sort((a, b) => a.premiseName.localeCompare(b.premiseName))
        .map((e) => {
          temp.push({
            value: e.premiseId,
            label: e.premiseName
              .split(" ")
              .map((e) => e.charAt(0).toUpperCase() + e.slice(1).toLowerCase())
              .join(" "),
            zone: e.zone,
            key: e.premiseId,
          });
        });
      setHubList(temp);
      setDataSource(temp);
    }
    if (hubErr) {
      message.error(hubErr.message);
    }
  }, [hubResult, hubErr]);

  useEffect(() => {
    if (hubBasedSortResult) {
      //setSortingIsUpdated(!hubBasedSortResult?.isUpdated && userData?.roles?.[0] == "ROLE_HUBADMIN" && userid.userInfo.premiseId == hubBasedSortResult?.premiseId);
      if (roleHub) {
        setSortingIsUpdated(true);
        if (userid.userInfo.premiseId == hubBasedSortResult?.premiseId) {
          setSortingIsUpdated(hubBasedSortResult.isUpdated ? true : false);
        }
      }
      let sortingData = [];
      let length = hubBasedSortResult?.sortingGuide.length;
      let cageNo = hubBasedSortResult?.sortingGuide[length - 1].cageNumber;

      for (let i = 1; i < cageNo + 1; i++) {
        sortingData.push({
          sortingPremiseId: 0,
          cageNumber: i,
          sendToDestinationList: [],
          sendToDestination: null,
        });
      }
      let sortingGuide = hubBasedSortResult?.sortingGuide;
      sortingGuide.map((e, index) => {
        if (e.cageNumber === sortingData[e.cageNumber - 1].cageNumber) {
          sortingData[e.cageNumber - 1].sortingPremiseId =
            sortingGuide[index].sortingPremiseId;
          sortingData[e.cageNumber - 1].sendToDestinationList =
            sortingGuide[index].sendToDestinationList;
          sortingData[e.cageNumber - 1].sendToDestination =
            sortingGuide[index].sendToDestination;
        }
      });
      let sortingDataTemp = [];
      let numberOfColumns = hubBasedSortResult?.numberOfColumns || 4;

      let numRow = sortingData.length / numberOfColumns;
      let x = numberOfColumns;
      let z = 0;
      for (let i = 0; i < numRow; i++) {
        let temp = [];
        for (let y = z; y < x; y++) {
          if (sortingData[y]) {
            temp.push(sortingData[y]);
          }
        }
        sortingDataTemp.push(temp);
        z = x;
        x += numberOfColumns;
      }
      setSortingRules([...sortingData]);
      setSortingRulesTemp([...sortingDataTemp]);
    }
    if (hubBasedErr) {
      message.error(hubBasedErr.message || "No data present");
      setSortingRules([]);
      setSortingRulesTemp([]);
    }
  }, [hubBasedSortResult, hubBasedErr]);

  const selectSortingHub = (e) => {
    setSortingRules([]);
    setSelectSorting(null);
    dispatch(getHubBasedSortingGuide(e));
  };

  const showBoxDetailsModal = (e) => {
    let currentBoxDetails = sortingRules.find((item) => item.cageNumber == e);
    let selectedHubList = hubList.filter((item) =>
      currentBoxDetails.sendToDestinationList.includes(item.value)
    );
    setModalContent({
      show: true,
      currentBox: `Box ${e} Details`,
      selectedHubList,
      id: e,
    });
  };

  const cancelBoxModal = () => {
    setModalContent({
      show: false,
      currentBox: null,
    });
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return <span>{originalElement} </span>;
  };

  function handlePageChange(pagination) {
    setPageNum(pagination.current);
    setPageSize(pagination.pageSize);
  }

  const callExpand = (e, record) => {
    if (e) {
      setExpandedRowKeys([record.key]);
      selectSortingHub(record.value);
      setSelectSorting(record.value);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const sortingCages = () => {
    let temp = [];
    sortingRulesTemp.map((item) => {
      temp.push(
        <div style={{ display: "flex" }}>
          {item.map((e, i) => {
            return (
              <div
                className="cage-container view-cage"
                style={{ background: "#EAF1FD" }}
                key={`cage_${i}`}
              >
                <div className="space-between">
                  <span className="cage-no left-box">{e.cageNumber}</span>
                </div>
                <div
                  className="flex-end mr-1-5"
                  style={{ marginTop: "-0.5rem" }}
                >
                  <span className="count">Hub Counts</span>
                  <span
                    className="count-circle ml-1 center pointer"
                    style={{
                      background:
                        e?.sendToDestinationList?.length > 0
                          ? "#68B966"
                          : "#bfbcbc",
                      pointerEvents:
                        e?.sendToDestinationList?.length > 0 ? "auto" : "none",
                    }}
                    onClick={() => showBoxDetailsModal(e.cageNumber)}
                  >
                    {e.sendToDestinationList?.length}
                  </span>
                </div>
                <Tooltip
                  title={
                    hubList.filter(
                      (item) => item.value === e.sendToDestination
                    )?.[0]?.label
                      ? capitaliseWithHyphen(
                          hubList.filter(
                            (item) => item.value === e.sendToDestination
                          )?.[0]?.label
                        )
                      : "N/A"
                  }
                >
                  <div className="view-sorting-hub">
                    {hubList.filter(
                      (item) => item.value === e.sendToDestination
                    )?.[0]?.label
                      ? capitaliseWithHyphen(
                          hubList.filter(
                            (item) => item.value === e.sendToDestination
                          )?.[0]?.label
                        )
                      : "N/A"}
                  </div>
                </Tooltip>
              </div>
            );
          })}
        </div>
      );
    });
    return temp;
  };
  const expandedRowRender = () => {
    return (
      <div style={{ background: "#fff", padding: "0.7rem 0.7rem 1rem 0.7rem" }}>
        <Space size="middle" className="flex-start">
          <span>
            Box Count: <b>{sortingRules.length}</b>
          </span>
          <Tooltip title={"Edit"}>
            <img
              src={
                sortingRules.length == 0 || sortingIsUpdated
                  ? disable_edit
                  : edit_icon
              }
              alt="edit"
              className={
                sortingRules.length > 0 && sortingIsUpdated == false
                  ? "pointer"
                  : ""
              }
              onClick={() =>
                sortingRules.length > 0 &&
                sortingIsUpdated == false &&
                changeTab(selectSorting)
              }
            />
          </Tooltip>
        </Space>
        {sortingCages()}
      </div>
    );
  };
  const onSearch = (e) => {
    setPageNum(1);
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(hubList, currValue)
        : filterArray(hubList, currValue);
    let temp = [];
    filteredData.map((item, index) => temp.push({ ...item, key: index + 1 }));
    setDataSource(temp);
    setExpandedRowKeys([]);
  };
  const filterArray = (dataSource, currValue) =>
    dataSource?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );

  return (
    <div className="master-main-container" style={{ minHeight: "68vh" }}>
      {!roleHub && (
        <Input
          placeholder="Search by (origin hub)"
          bordered
          onChange={onSearch}
          style={{ borderRadius: "5px", height: "40px", width: "400px" }}
          suffix={<img src={search_icon} />}
          className="m-1"
        />
      )}
      {roleHub && <br />}
      <div
        style={{
          height: "50vh",
          overflowY: "scroll",
          overflowX: "hidden",
          padding: "0 1rem",
        }}
      >
        <Table
          columns={[
            {
              title: roleHub ? "Origin HUB" : "Origin HUB List",
              dataIndex: "label",
              key: "label",
              render: (text) => <span>{capitaliseWithHyphen(text)}</span>,
            },
          ]}
          dataSource={
            roleHub
              ? viewHub
              : inputSearchValue.length == 0
              ? hubList
              : dataSource
          }
          loading={tableLoading}
          pagination={
            roleHub
              ? false
              : {
                  pageSize: pageSize,
                  itemRender: itemRender,
                  pageSizeOptions: [5, 10, 20, 50, 100],
                  current: pageNum,
                }
          }
          onChange={handlePageChange}
          expandable={{
            expandedRowRender,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <RightOutlined onClick={(e) => onExpand(record, e)} />
              ),
            expandRowByClick: true,
            defaultExpandedRowKeys: ["0"],
          }}
          onExpand={callExpand}
          expandedRowKeys={expandedRowKeys}
          className="tat-table1 sorting-table"
          scroll={{ x: 800 }}
        />
      </div>
      {/* //--Modal--// */}
      <Modal
        title={modalContent?.currentBox}
        open={modalContent?.show}
        onCancel={cancelBoxModal}
        width={700}
        footer={null}
        className="hub-show-modal"
      >
        <Row gutter={15} className="mb-1">
          {modalContent?.selectedHubList?.length > 0 ? (
            modalContent?.selectedHubList?.map((e) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  key={e.value}
                  className="mt-1"
                >
                  <div className="d-flex space-between p-05 modal-hub">
                    <span>{e.label}</span>
                  </div>
                </Col>
              );
            })
          ) : (
            <Col span={24}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Col>
          )}
        </Row>
      </Modal>
      {/* //--// */}
    </div>
  );
};
export default ViewSorting;
