import keyMirror from "keymirror";

const actions = {
  GET_PINCODE_MASTER_TABLE: null,
  GET_PINCODE_MASTER_TABLE_SUCCESS: null,
  GET_PINCODE_MASTER_TABLE_ERROR: null,
  GET_PINCODE_MASTER_TABLE_CLEAR: null,

  GET_PINCODE: null,
  GET_PINCODE_SUCCESS: null,
  GET_PINCODE_ERROR: null,
  GET_PINCODE_CLEAR: null,

  GET_STATE: null,
  GET_STATE_SUCCESS: null,
  GET_STATE_ERROR: null,
  GET_STATE_CLEAR: null,

  GET_SERVICEABILITY: null,
  GET_SERVICEABILITY_SUCCESS: null,
  GET_SERVICEABILITY_ERROR: null,
  GET_SERVICEABILITY_CLEAR: null,

  UPDATE_PINCODE: null,
  UPDATE_PINCODE_SUCCESS: null,
  UPDATE_PINCODE_ERROR: null,
  UPDATE_PINCODE_CLEAR: null,

  CREATE_PINCODE: null,
  CREATE_PINCODE_SUCCESS: null,
  CREATE_PINCODE_ERROR: null,
  CREATE_PINCODE_CLEAR: null,
  //--Rate Card Master--//
  GET_DEFAULT_RATE_CARD: null,
  GET_DEFAULT_RATE_CARD_SUCCESS: null,
  GET_DEFAULT_RATE_CARD_ERROR: null,
  GET_DEFAULT_RATE_CARD_CLEAR: null,

  GET_CATEGORY_BASED_VALUE: null,
  GET_CATEGORY_BASED_VALUE_SUCCESS: null,
  GET_CATEGORY_BASED_VALUE_ERROR: null,
  GET_CATEGORY_BASED_VALUE_CLEAR: null,

  CREATE_CASH_RATE_CARD_TEMPLATE: null,
  CREATE_CASH_RATE_CARD_TEMPLATE_SUCCESS: null,
  CREATE_CASH_RATE_CARD_TEMPLATE_ERROR: null,
  CREATE_CASH_RATE_CARD_TEMPLATE_CLEAR: null,

  GET_CASH_RATE_CARD_TEMPLATES: null,
  GET_CASH_RATE_CARD_TEMPLATES_SUCCESS: null,
  GET_CASH_RATE_CARD_TEMPLATES_ERROR: null,
  GET_CASH_RATE_CARD_TEMPLATES_CLEAR: null,

  GET_CASH_RATE_CARD_DETAILS: null,
  GET_CASH_RATE_CARD_DETAILS_SUCCESS: null,
  GET_CASH_RATE_CARD_DETAILS_ERROR: null,
  GET_CASH_RATE_CARD_DETAILS_CLEAR: null,

  ALLOCATE_CASH_RATE_CARD: null,
  ALLOCATE_CASH_RATE_CARD_SUCCESS: null,
  ALLOCATE_CASH_RATE_CARD_ERROR: null,
  ALLOCATE_CASH_RATE_CARD_CLEAR: null,

  GET_EXISTING_CASH_RATE_CARD_TEMPLATES: null,
  GET_EXISTING_CASH_RATE_CARD_TEMPLATES_SUCCESS: null,
  GET_EXISTING_CASH_RATE_CARD_TEMPLATES_ERROR: null,
  GET_EXISTING_CASH_RATE_CARD_TEMPLATES_CLEAR: null,
  //----//

  GET_PROFILE_DETAILS: null,
  GET_PROFILE_DETAILS_SUCCESS: null,
  GET_PROFILE_DETAILS_ERROR: null,
  GET_PROFILE_DETAILS_CLEAR: null,

  SAVE_PROFILE_DETAILS: null,
  SAVE_PROFILE_DETAILS_SUCCESS: null,
  SAVE_PROFILE_DETAILS_ERROR: null,
  SAVE_PROFILE_DETAILS_CLEAR: null,

  //PACKETING GUID//
  GET_PACKETING_GUIDE_FROM_HUBLIST: null,
  GET_PACKETING_GUIDE_FROM_HUBLIST_SUCCESS: null,
  GET_PACKETING_GUIDE_FROM_HUBLIST_ERROR: null,
  GET_PACKETING_GUIDE_FROM_HUBLIST_CLEAR: null,

  GET_PACKETING_GUIDE_TO_HUBLIST: null,
  GET_PACKETING_GUIDE_TO_HUBLIST_SUCCESS: null,
  GET_PACKETING_GUIDE_TO_HUBLIST_ERROR: null,
  GET_PACKETING_GUIDE_TO_HUBLIST_CLEAR: null,

  GET_PACKETING_GUIDE_FROM_CPLIST: null,
  GET_PACKETING_GUIDE_FROM_CPLIST_SUCCESS: null,
  GET_PACKETING_GUIDE_FROM_CPLIST_ERROR: null,
  GET_PACKETING_GUIDE_FROM_CPLIST_CLEAR: null,

  GET_PACKETING_GUIDE_TO_CPLIST: null,
  GET_PACKETING_GUIDE_TO_CPLIST_SUCCESS: null,
  GET_PACKETING_GUIDE_TO_CPLIST_ERROR: null,
  GET_PACKETING_GUIDE_TO_CPLIST_CLEAR: null,

  GET_PACKETING_GUIDE_MASTER: null,
  GET_PACKETING_GUIDE_MASTER_SUCCESS: null,
  GET_PACKETING_GUIDE_MASTER_ERROR: null,
  GET_PACKETING_GUIDE_MASTER_CLEAR: null,

  GET_PACKETING_GUIDE_SEARCH: null,
  GET_PACKETING_GUIDE_SEARCH_SUCCESS: null,
  GET_PACKETING_GUIDE_SEARCH_ERROR: null,
  GET_PACKETING_GUIDE_SEARCH_CLEAR: null,

  POST_PACKETING_GUIDE_MASTER: null,
  POST_PACKETING_GUIDE_MASTER_SUCCESS: null,
  POST_PACKETING_GUIDE_MASTER_ERROR: null,
  POST_PACKETING_GUIDE_MASTER_CLEAR: null,

  PUT_PACKETING_GUIDE_MASTER: null,
  PUT_PACKETING_GUIDE_MASTER_SUCCESS: null,
  PUT_PACKETING_GUIDE_MASTER_ERROR: null,
  PUT_PACKETING_GUIDE_MASTER_CLEAR: null,

  GET_PACKETING_GUIDE_DOWNLOAD: null,
  GET_PACKETING_GUIDE_DOWNLOAD_SUCCESS: null,
  GET_PACKETING_GUIDE_DOWNLOAD_ERROR: null,
  GET_PACKETING_GUIDE_DOWNLOAD_CLEAR: null,

  GET_PACKET_CONFIGURATION: null,
  GET_PACKET_CONFIGURATION_SUCCESS: null,
  GET_PACKET_CONFIGURATION_ERROR: null,
  GET_PACKET_CONFIGURATION_CLEAR: null,

  PUT_PACKET_CONFIGURATION: null,
  PUT_PACKET_CONFIGURATION_SUCCESS: null,
  PUT_PACKET_CONFIGURATION_ERROR: null,
  PUT_PACKET_CONFIGURATION_CLEAR: null,

  //----///

  // --- pincode mapping ---//
  GET_PINCODE_MAPPING: null,
  GET_PINCODE_MAPPING_SUCCESS: null,
  GET_PINCODE_MAPPING_ERROR: null,
  GET_PINCODE_MAPPING_CLEAR: null,
  // -----------------------//

  // ------Hub Onboarding------//
  GET_ALL_HUB_ONBOARD: null,
  GET_ALL_HUB_ONBOARD_SUCCESS: null,
  GET_ALL_HUB_ONBOARD_ERROR: null,
  GET_ALL_HUB_ONBOARD_CLEAR: null,
  // --------------------------//
  
  ENABLE_DISABLE_PINCODE: null,
  ENABLE_DISABLE_PINCODE_SUCCESS: null,
  ENABLE_DISABLE_PINCODE_ERROR: null,
  ENABLE_DISABLE_PINCODE_CLEAR: null,

  DELETE_PINCODE_AREA: null,
  DELETE_PINCODE_AREA_SUCCESS: null,
  DELETE_PINCODE_AREA_ERROR: null,
  DELETE_PINCODE_AREA_CLEAR: null,

  SEARCH_PINCODE_MASTER_TABLE: null,
  SEARCH_PINCODE_MASTER_TABLE_SUCCESS: null,
  SEARCH_PINCODE_MASTER_TABLE_ERROR: null,
  SEARCH_PINCODE_MASTER_TABLE_CLEAR: null,

  //--TAT MASTER--//
  GET_FROM_HUB_TAT: null,
  GET_FROM_HUB_TAT_SUCCESS: null,
  GET_FROM_HUB_TAT_ERROR: null,
  GET_FROM_HUB_TAT_CLEAR: null,

  GET_TO_HUB_TAT: null,
  GET_TO_HUB_TAT_SUCCESS: null,
  GET_TO_HUB_TAT_ERROR: null,
  GET_TO_HUB_TAT_CLEAR: null,

  GET_FM_LM_TAT: null,
  GET_FM_LM_TAT_SUCCESS: null,
  GET_FM_LM_TAT_ERROR: null,
  GET_FM_LM_TAT_CLEAR: null,
  //----//
  //--Sorting Guide--//
  GET_HUB_LIST: null,
  GET_HUB_LIST_SUCCESS: null,
  GET_HUB_LIST_ERROR: null,
  GET_HUB_LIST_CLEAR: null,

  CREATE_SORTING_GUIDE: null,
  CREATE_SORTING_GUIDE_SUCCESS: null,
  CREATE_SORTING_GUIDE_ERROR: null,
  CREATE_SORTING_GUIDE_CLEAR: null,

  GET_HUB_BASED_SORTING_GUIDE: null,
  GET_HUB_BASED_SORTING_GUIDE_SUCCESS: null,
  GET_HUB_BASED_SORTING_GUIDE_ERROR: null,
  GET_HUB_BASED_SORTING_GUIDE_CLEAR: null,

  GET_ALL_SORTING_GUIDE: null,
  GET_ALL_SORTING_GUIDE_SUCCESS: null,
  GET_ALL_SORTING_GUIDE_ERROR: null,
  GET_ALL_SORTING_GUIDE_CLEAR: null,

  VIEW_SORTING_GUIDE: null,
  VIEW_SORTING_GUIDE_SUCCESS: null,
  VIEW_SORTING_GUIDE_ERROR: null,
  VIEW_SORTING_GUIDE_CLEAR: null,
  //--//
  // onBording //
  PREMISE_TABLE: null,
  PREMISE_TABLE_SUCCESS: null,
  PREMISE_TABLE_ERROR: null,
  PREMISE_TABLE_CLEAR: null,

  ONBOARD_PATCH: null,
  ONBOARD_PATCH_SUCCESS: null,
  ONBOARD_PATCH_ERROR: null,
  ONBOARD_PATCH_CLEAR: null,
  // onBording //

  ///////////
};
export default keyMirror(actions);
