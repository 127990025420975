import React from "react";
import { Modal, Row, Col } from "antd";
import cross_button from "../../../assests/images/CrossButton.svg";
import "./style.scss";

const PinCodeMappingModal = ({
  modalVisible,
  setModalVisible,
  modalName,
  modalData,
}) => {
  const handleOk = () => {
    setModalVisible(false);
  };
  const handleCancel = () => {
    setModalVisible(false);
  };
  return (
    <div>
      <Modal
        className="pincode-mapping-modal"
        title={modalName}
        open={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        closeIcon={<img src={cross_button} />}
      >
        <Row className="pincode-mapping-modal-container" gutter={37}>
          {modalData?.split(",")?.map((data, idx) => (
            <Col key={idx} className="pincode-data">
              {data}
            </Col>
          ))}
        </Row>
      </Modal>
    </div>
  );
};

export default PinCodeMappingModal;
