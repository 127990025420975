/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Tooltip,
  Popconfirm,
  Space,
  message,
} from "antd";
// import { Space, Input, Tooltip, Popconfirm, message } from "antd";

import { ReactComponent as Edit_icon } from "../../../assests/images/edit-logo.svg";
import { ReactComponent as DeleteIcon } from "../../../assests/images/deleteIcon.svg";
import { ReactComponent as Disable_Edit_icon } from "../../../assests/images/SVG/disableEdit.svg";
import { QuestionCircleOutlined } from "@ant-design/icons";

import API from "../../../api";
import urls from "../../../api/urls";
import { capitaliseWithHyphen } from "../../../utils";
import "./style.scss";

const { GET_VIEW_ALL_DELIVERY_AREAS, DELETE_VIRTUAL_AREA } = urls;

const viewVirtualArea = ({ handleEdit = () => {} }) => {
  const [loading, setLoading] = useState([]);
  const [tableData, setTableData] = useState([]);

  const handleDisable = async (params) => {
    setLoading(true);
    let response = await API.Api(DELETE_VIRTUAL_AREA + params).deleteIdHeader();
    if (response.status) {
      setLoading(false);
      getTableData();
      message.success(response.response.data.message);
    } else {
      if (!response.status) {
        setLoading(false);
        message.error(response.response.data.message || "Network error");
      }
    }
  };

  const columns = [
    {
      title: "SI No.",
      dataIndex: "sl",
      render: (a, b, idx) => idx + 1,
    },
    {
      title: "Delivery Area",
      dataIndex: "virtualAreaName",
      render: (text) => {
        return capitaliseWithHyphen(text);
      },
    },
    {
      title: "Created Date",
      dataIndex: "cratedDate",
      render: (text) => {
        return text.split("-").reverse().join("-");
      },
    },

    {
      title: "Area Name",
      dataIndex: "areas",
      render: (text) => {
        return text?.map((val) => {
          return (
            <>
              <div>
                {capitaliseWithHyphen(
                  val?.areaName + " (" + val?.pincode + ")"
                )}
              </div>
            </>
          );
        });
      },
    },
    {
      title: "Actions",
      dataIndex: "",
      className: "actions-col",
      width: 150,

      render: (_, record) => {
        const checkAllocated = record.areas.map((val) => val.allocated);
        return checkAllocated ? (
          <Space size="middle">
            <Tooltip title={"Edit"} placement="bottom">
              <p
                className="pointer"
                style={{ marginBottom: 0 }}
                onClick={() =>
                  handleEdit(record?.virtualAreaId, record.premiseId)
                }>
                <Edit_icon />
              </p>
            </Tooltip>
            <Tooltip title="Delete" placement="bottom">
              <div className="view-all-staff">
                <Popconfirm
                  title={
                    <span
                      className="test"
                      style={{
                        textTransform: "initial",
                      }}>
                      Do you want to delete this area ?
                    </span>
                  }
                  cancelText="No"
                  okText="Yes"
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  }
                  onConfirm={() => handleDisable(record.virtualAreaId)}>
                  <p className="pointer" style={{ marginBottom: 0 }}>
                    <DeleteIcon />
                  </p>
                </Popconfirm>
              </div>
            </Tooltip>
          </Space>
        ) : (
          <Space size="middle">
            <p style={{ marginBottom: 0 }}>
              <Disable_Edit_icon />
            </p>
            <Tooltip title="Enable" placement="bottom">
              <Popconfirm
                title={
                  <span
                    className="test"
                    style={{
                      textTransform: "initial",
                    }}>
                    Do you want to enable this Staff ?
                  </span>
                }
                okText="Yes"
                cancelText="No"
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: "green",
                    }}
                  />
                }></Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const getTableData = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_VIEW_ALL_DELIVERY_AREAS
    ).getWithPremiseIdHeader();
    if (response.status) {
      setLoading(false);
      let data = response?.response?.data;
      setTableData(data);
    } else {
      setLoading(false);
      message.error(response.response.data.message || "Network error");
    }
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <div className="drs-generation-container p-2">
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        className="table-head-sticky"
      />
      {/* <Row justify="end">
        <Col>
          <Button className="save-btn">GENERATE DRS</Button>
        </Col>
      </Row> */}
    </div>
  );
};
// export default CreateVirtualArea;

export default viewVirtualArea;
