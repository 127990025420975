import React from "react";

const Heading = ({title}) => {
  return (
    <div className="heading-style">
      <span style={{fontWeight:"700", fontSize:"16px", lineHeight:"32px"}}>{title}</span>
      <hr className="divider" style={{ marginTop: "10px",marginBottom:"20px" }} />
    </div>
  );
};

export default Heading;
