/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Spin, message } from "antd";

import success from "../../../../assests/images/SVG/success@2x.svg";
import booking_details from "../../../../assests/images/booking_details.svg";
import whatsapp from "../../../../assests/images/whatsapp.svg";
import warning from "../../../../assests/images/warning.svg";

import "./style.scss";
import urls from "../../../../api/urls";
import constants from "../../../../constants/constants";

const { SEND_WHATSPP } = urls;
const { WHATSAPPKEY, CAMPAIGN_NAME } = constants;

// import API from "../../../../api";

const NewBookSuccessfull = () => {
  const navigate = useNavigate();

  const status = false;
  const history = useLocation();

  const [loading, setLoading] = useState(false);
  const deactivate = !status ? "booking_invoice_details" : "deactivate";

  useEffect(() => {
    // sendSms();
    // sendWhatApp();
  }, []);

  const handler = () => {
    navigate("/retail-booking");
  };

  const handlerDone = () => {
    navigate("/dashboard");
  };

  const sendSms = async () => {
    setLoading(true);

    try {
      let res = await axios.get(
        `https://pgapi.vispl.in/fe/api/v1/send?username=shremotpg.trans&password=uNIvT&unicode=false&from=SMILL&dltPrincipalEntityId=1001569800000012636&dltContentId=1007163186467531163&text=Shree Maruti Courier
        Your shipment ${history?.state?.documentNumber} is booked with Shree Maruti Courier. To track your shipment you can visit https://www.shreemaruticourier.com/tracking/?d=${history?.state?.documentNumber}&to=${history?.state?.receiverMobileNumber}`
      );
      if (res?.data) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // message.error("Network error"); //this api always throws error but sms will be sent anyway
    }
  };

  const sendWhatApp = async () => {
    setLoading(true);

    const payload = {
      apiKey: WHATSAPPKEY,

      campaignName: CAMPAIGN_NAME,
      destination: history?.state?.senderMobileNumber?.toString(), // sender phone number
      userName: "smcs",
      media: {
        url: "https://hubops.innofulfill.com/static/media/Smcs_New_Logo.085091870cb846667792.png",
        filename: history?.state?.documentNumber?.toString(),
      },
      templateParams: [
        history?.state?.documentNumber?.toString(),
        history?.state?.receiverName || "",
        history?.state?.destinationCity +
          ", " +
          history?.state?.destinationState || "SHREE MARUTI",
        "https://www.shreemaruti.com/dw/?a=R98s3K",
        "https://www.shreemaruti.com/dw/?a=S79s3P",
        `tracking/?d=${history?.state?.documentNumber?.toString()}`,
      ],
    };

    try {
      let res = await axios.post(SEND_WHATSPP, payload);
      if (res?.data) {
        setLoading(false);
      }
    } catch (error) {
      message.error("Network error");

      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        document.getElementById("add-btn").focus();
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [open]);

  return (
    <Spin spinning={loading}>
      <div className="retail-booking-success">
        <div className="success-container">
          <div className="content">
            <img src={success} />
            <div className="message">
              <p>
                <strong>Booking successfully completed</strong>
              </p>
              <p className="order-id">
                for Order Id:&nbsp;{" "}
                <span>{history?.state?.documentNumber}</span>
              </p>

              <div className={deactivate}>
                {!status ? (
                  <>
                    <img src={whatsapp} />

                    <p>
                      &nbsp;&nbsp;Booking details sent to{" "}
                      {history?.state?.senderMobileNumber}
                      &nbsp;
                    </p>
                  </>
                ) : (
                  <>
                    <img src={warning} />
                    <p>&nbsp;Failed to send booking information.&nbsp;</p>
                    <a>resend</a>
                  </>
                )}
              </div>
              <div className={`${deactivate} invoice-details`}>
                {!status ? (
                  <>
                    <img src={booking_details} />
                    <p>
                      &nbsp; Invoice details sent to{" "}
                      {history?.state?.receiverMobileNumber}
                      &nbsp;
                    </p>
                  </>
                ) : (
                  <>
                    <img src={warning} />
                    <p>&nbsp;Failed to send booking information.&nbsp;</p>
                    <a>resend</a>
                  </>
                )}
              </div>
            </div>

            <div className="btn-cont">
              <Button
                onClick={handler}
                className="back-button add-btn"
                id="add-btn"
              >
                {"Add New Booking"}
              </Button>
              <Button
                type="primary"
                className="done done-btn"
                onClick={handlerDone}
                id="done-btn"
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default NewBookSuccessfull;
