import React, { useEffect } from "react";
import { Modal } from "antd";
import { useState } from "react";
import success from "../../assests/images/success.svg";
import "./index.scss";
const Alert = ({ alertData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    setIsModalOpen(alertData?.visible);
    setTimeout(() => {
      setIsModalOpen(false);
    }, 3000);
  }, [alertData]);

  return (
    <Modal
      open={isModalOpen}
      //width={400}
      header={null}
      footer={null}
      className="alert-class"
    >
      <img src={success} className="alert-image" />
      <p className="alert-message">{alertData?.message}</p>
    </Modal>
  );
};
export default Alert;
