/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Input,
  Select,
  Form,
  Spin,
  Space,
  message,
  notification,
} from "antd";

import {
  capitaliseWithHyphen,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
} from "../../../../utils";
import API from "../../../../api";
import nodeURLs from "../../../../api/nodeUrls";
import urls from "../../../../api/urls";

const { UPDATE_EXCEPTION_REPORT_BAG } = urls;
const {
  GET_EXCEPTION_ACTIONS,
  UPDATE_EXCEPTION_REPORT,
  GET_EXCEPTION_REPORT,
  // UPDATE_EXCEPTION_REPORT_BAG,
  GET_EXCEPTION_REASONS,
} = nodeURLs;

const EditExpectionModal = ({
  editVisible,
  setEditVisible = () => {},
  type,
  handleEditCallBack = () => {},
  editAwbData,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showReason, setShowReason] = useState("");
  const userId = JSON.parse(localStorage.getItem("userId"));
  const userName = JSON.parse(localStorage.getItem("userName"));
  const [actionData, setActionsData] = useState([]);
  const [reasonsData, setReasonsData] = useState();
  const { Option } = Select;


  const openNotification = () => {
    notification.open({
      message: null,
      className: "exception-alert",
      description: (
        <div>
          <strong style={{ marginBottom: "10px" }}>Bag is damaged.</strong>
          <h4>
            Please find the shipments at
            <a href="/lcr-scan-list"> LCR Inscan</a>
          </h4>
        </div>
      ),
      duration: 0,
      onClick: () => {
        notification.destroy();
      },
    });
  };

  useEffect(() => {
    if (editVisible) {
      form.resetFields();
      setShowReason("");
      getActions();
    }
  }, [editVisible]);

  function onClose() {
    form.resetFields();
    setEditVisible(false);
  }

  const getActions = async () => {
    setLoading(true);

    const response = await API.Api(GET_EXCEPTION_ACTIONS).NodeGetWithPremise();

    if (response?.status) {
      setLoading(false);

      form.setFieldsValue({
        hubName: userName || "",
        reasonId: null,
        awbNumber: editAwbData?.awb_number,
      });
      if (editAwbData?.action) {
        getReasons();
        form.setFieldsValue({
          actionId: editAwbData?.action?.id,
        });
      }
      setActionsData(response?.response?.data?.actions);
    } else {
      setLoading(false);
      message.error(response?.statusText || "Internal server error");
    }
  };

  const onFinish = async (data) => {
    setLoading(true);

    let payload = {
      hubId: userId?.userInfo?.premiseId,
      actionId: +data?.actionId,
      awbNumber: +data?.awbNumber,
      reasonId: +data.reasonId || null,
    };

    if (data?.reasonId == undefined) {
      delete payload.reasonId;
    }


    const response = await API.Api(
      UPDATE_EXCEPTION_REPORT + `${editAwbData?.exception_id}`
    ).NodePutPremiseIdHeader(payload);

    if (response?.status) {
      form.resetFields();
      if (showReason == "damage") {
        damageBag(+data?.reasonId, +data?.awbNumber);
      } else {
        setLoading(false);
        // openNotification("topRight");
        setEditVisible(false);
        handleEditCallBack();
      }
      // handleCallBack();

      message.success(response?.data?.message || "Updated successfully");
    } else {
      setLoading(false);
      message.error(response?.response?.statusText || "Internal server error");
    }
  };

  const damageBag = async (id, awbNumber) => {
    const findCurrentReason = reasonsData?.find((val) => val?.id == +id);

    setLoading(true);

    let payload = {
      reason: findCurrentReason?.reason,
    };

    const response = await API.Api(
      UPDATE_EXCEPTION_REPORT_BAG + awbNumber
    ).putIdHeader(payload);

    if (response?.status) {
      setLoading(false);
      openNotification("topRight");
      setEditVisible(false);
      handleEditCallBack();
      message.success(response?.data?.message || "Updated successfully");
    } else {
      // openNotification("topRight");
      setEditVisible(false);
      setLoading(false);
      message.error(
        response?.response?.data?.message || "Internal server error"
      );
    }
  };

  const getReasons = async () => {
    setLoading(true);
    const response = await API.Api(GET_EXCEPTION_REASONS).NodeGetWithPremise();

    if (response?.status) {
      setLoading(false);
      setReasonsData(response?.response?.data?.actions);
    } else {
      setLoading(false);
      message.error(response?.statusText || "Internal server error");
    }
  };

  function handleAction(params, record) {
    if (record?.children?.toLowerCase()?.includes("damage")) {
      setShowReason("damage");
      getReasons();
    } else if (record?.children?.toLowerCase()?.includes("discard")) {
      setShowReason("discard");
    } else {
      setShowReason("");
    }
  }

  return (
    <Modal
      title={"Update Exceptions"}
      open={editVisible}
      width={350}
      footer={null}
      className="create-bag"
      style={{ top: "200px !important" }}
      onCancel={() => {
        setEditVisible(false);
      }}
      maskClosable={false}
    >
      <Spin
        spinning={loading}
        size="large"
        indicator={
          <img
            src={"Smcs_Loader"}
            style={{ height: "100px", width: "100px" }}
          />
        }
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  type == "shipment" ? "Add shipment number" : "Add bag number"
                }
                name="awbNumber"
                rules={[
                  {
                    required: true,
                    message: "Enter bag number!",
                  },
                ]}
              >
                <Input
                  disabled
                  // className="input-without-form"
                  placeholder="Enter bag number"
                  autoComplete="off"
                  maxLength={14}
                  onKeyPress={(event) => {
                    if (
                      checkNumbervalue(event) ||
                      checkSpace(event) ||
                      checkSpecialCharacter(event)
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="mt-1">
              <Form.Item
                label="Hub name"
                name="hubName"
                rules={[{ required: true, message: "Enter hub name!" }]}
              >
                <Input
                  // className="input-without-form"
                  placeholder="Hub Name"
                  autoComplete="off"
                  disabled
                  onKeyPress={(event) => {
                    if (checkSpace(event) || checkSpecialCharacter(event)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="mt-1 mb-1">
              <Form.Item
                label={"Select status"}
                name="actionId"
                rules={[{ required: true, message: "Select action!" }]}
              >
                <Select
                  // className="select-without-form"
                  placeholder="Select action"
                  // defaultValue=""
                  onSelect={handleAction}
                  // options={actionData}
                >
                  {actionData?.map((item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {showReason === "damage" ? (
              <Col span={24} className=" mb-1">
                <Form.Item
                  label={"Reason"}
                  name="reasonId"
                  rules={[{ required: true, message: "Select reason!" }]}
                >
                  <Select
                    // className="select-without-form"
                    placeholder="Select reason"
                    // defaultValue=""
                    // options={actionData}
                  >
                    {reasonsData
                      ?.filter((e) => e.allowFor === "shipment")
                      .map((item) => (
                        <Option key={item?.id} value={item?.id}>
                          {item?.reason}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : showReason === "discard" ? (
              <Col span={24} className=" mb-1">
                <Form.Item
                  name="reason"
                  label="Reason"
                  rules={[
                    {
                      required: true,
                      message: "Please input reason",
                    },
                  ]}
                >
                  <Input.TextArea showCount maxLength={100} />
                </Form.Item>{" "}
              </Col>
            ) : null}

            <Col span={24}>
              <Form.Item>
                <Space style={{ width: "100%", justifyContent: "end" }}>
                  <Button className="cancel-btn" onClick={onClose}>
                    CANCEL
                  </Button>
                  <Button className="save-btn" type="primary" htmlType="submit">
                    SUBMIT
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditExpectionModal;
