import React,{useState,useEffect} from "react";
import {Input,Table,Space,Tag,DatePicker, message,Modal,Form, Button,Tooltip,Spin} from "antd";
import { useNavigate } from "react-router";
import search_icon from "../../../assests/images/search-icon.svg";
import print_icon from "../../../assests/images/printIcon.svg";
import { capitaliseWithHyphen } from "../../../utils";
import deleteIcon from "../../../assests/images/deleteIcon.svg";
// import edit_icon from "../../../assests/images/edit-logo.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import { serialNum } from "../../../utils";
import urls from "../../../api/urls";
import API from "../../../api";

const ViewDrs = ({step}) => {
  const [drsListResponse,setDrsListResponse]= useState();
  const [loading,setLoading]=useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const { GET_DRS_LIST,DELETE_DRS,VIEW_DRS_SEARCH} = urls;
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const getPremiseId = localStorage.getItem("userId");
  const premise = JSON.parse(getPremiseId);
  const columns = [
    {
      title: "Sl No",
      align: "center",
      width: 10,
      render: (a, b, idx) => serialNum(10, currentPage, idx),
    },
    {
      title: "DRS Number",
      dataIndex: "drsId",
      key: "drsId",
      render: (text) => <a onClick={() => navigate("/drs-details",{state:text})}>{text}</a>,
    },
    {
      title: "Date",
      dataIndex: "createDate",
      key: "createDate",
      width:150,
      render: (text) => <div style={{width:"75px"}}>{text.split("-").reverse().join("-")}</div>,
    },
    {
      title: "Area",
      dataIndex: "",
      key: "area",
      render: (text) =>{
        return(
          text?.areaDtos ? 
            text?.areaDtos?.map((data,idx)=><div key={idx} style={{width:"170px"}}>{`${capitaliseWithHyphen(data?.areaName)},`}</div>) :
            text?.virtualAreaDto?.areas?.map((data,idx)=><div key={idx} style={{width:"170px"}}>{`${capitaliseWithHyphen(data?.areaName)},`}</div>)
          
        );
      },
    },
    {
      title: "PIN Code",
      dataIndex: "",
      key: "pincode",
      render: (text) =>{
        return(
          text?.areaDtos ? 
            <div>{text?.areaDtos?.[0].pincode || "------"}</div> :
            <div>{text?.virtualAreaDto?.areas?.pincode || "------"}</div>
        );
      },
    },
    {
      title: "Delivery boy",
      dataIndex: "deliveryBoyName",
      key: "deliveryBoyName",
      render: (text) => <div style={{width:"120px"}}>{capitaliseWithHyphen(text)}</div>,
    },
    {
      title: "Delivered",
      dataIndex: "",
      key: "delivered",
      render:(text)=>{
        return(
          <span>{`${text?.delivered}/${text?.totalshimpments}`}</span>
        );
      }
    },
    {
      title: "Failed to deliver",
      dataIndex: "",
      key: "failedToDeliver",
      render:(text)=>{
        return(
          <span>{`${text?.failed}/${text?.totalshimpments}`}</span>
        );
      }
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text) => {  
        let color="";
        let status= ""; 
        if (text === "CREATED") {
          color = "#2E77D0";
          status= "Created";
        }else if(text === "INITIATED"){
          color = "#F09948";
          status="Initiated";
        }else if(text === "IN_PROGRESS"){
          color = "#3DD63A";
          status= "In progress";
        }else if(text === "COMPLETED"){
          color = "#149D11";
          status="Completed";
        }
        return (
          <Tag color={color} key={text}>
            {status}
          </Tag>
        );
      }
    },
    {
      title: "E-Way Bill",
      key: "action",
      dataIndex: "",
      render: (text,record) => {
        return(
          <Space size="middle">
            <Button
              disabled={record?.ewayBillNo === null}
              className="cancel-btn"
              onClick={() => {
                navigate(`/last-mile-eway-bill/${text?.drsId}`);
              }}
            >
              UPDATE
            </Button>
            <Button
              className="cancel-btn"
              onClick={() =>navigate("/print-eway-bill", { state:{state: text?.drsId,key:"VIEW_DRS",slug:"/generate-drs" }})}
            >
              PRINT
            </Button>
          </Space>);
      },
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "",
      render: (text) => (
        <Space size="middle">
          <Tooltip title="Print">
            <a onClick={()=>navigate("/print-drs",{state:text?.drsId})}><img src={print_icon}/></a>
            {text?.status=== "INITIATED" ?  <a onClick={()=>deleteDrsHandle(text?.drsId)}><img src={deleteIcon}/></a> : null }
          </Tooltip>
        </Space>
      ),
    },
  ];
  useEffect(()=>{
    if(step === "2"){
      drsListFun(premise.userInfo.premiseId);
    }
  },[step]);
  useEffect(()=>{
    drsListFun(premise.userInfo.premiseId);
  },[]);
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };
  let deleteDrsHandle=async(drsNumber)=>{
    let response = await API.Api(
      DELETE_DRS + drsNumber
    ).deleteIdHeader();
    if(response.status)drsListFun(premise.userInfo.premiseId);
  };
  let drsListFun = async(premiseId)=>{ 
    setLoading(true);
    let response = await API.Api(
      `${GET_DRS_LIST}${premiseId}?pageNum=${0}&pageSize=${30}`
    ).getWithPremiseIdHeader();
    if(response?.status){
      setDrsListResponse(response?.response?.data);
      setLoading(false);
    }else {
      setLoading(false);
      message.error(response?.response?.data?.message);
    }
  }; 
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const rangePickerHandle = async (e,date)=>{
    setLoading(true);
    if(date){
      let response = await API.Api(
        `${VIEW_DRS_SEARCH}pageNum=0&pageSize=10&fromDate=${date[0]}&toDate=${date[1]}`
      ).getWithPremiseIdHeader();
      if(response?.status){
        setDrsListResponse(response?.response?.data);
        setLoading(false);
      }
    }
    if(!date[0]){
      drsListFun(premise.userInfo.premiseId);
    }
  };
  const searchHandle= async (e)=>{
    if(e.target.value){
      setLoading(true);
      let response = await API.Api(
        `${VIEW_DRS_SEARCH}pageNum=0&pageSize=10&search=${e.target.value}`
      ).getWithPremiseIdHeader();
      if(response?.status){
        setDrsListResponse(response?.response?.data);
        setLoading(false);
        setCurrentPage(0);
      }
    }else{
      drsListFun(premise.userInfo.premiseId);
    }
  };
  const paginationHandle = (pagination) => {
    setCurrentPage(pagination.current);
  };
  return (
    <Spin spinning={loading}
      size="large"
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }>
      <div className="view-drs-container">
        <div className="p-1" style={{display:"flex" , justifyContent:"end"}}>
          <Input
            placeholder="Search by DRS, Delivery boy, Area"
            bordered
            style={{ borderRadius: "5px", height: "40px", width: "465px" }}
            suffix={<img src={search_icon} />}
            onChange={searchHandle}
          />
          <RangePicker 
            onChange={rangePickerHandle}
          />
        </div>
        <Table 
          className="table-head-sticky"
          columns={columns} 
          dataSource={drsListResponse}  
          pagination={{
            itemRender: itemRender,
          }}
          onChange={paginationHandle}
        />
        <Modal title="Edit Delivery boy details" open={isModalOpen} footer={false} onCancel={handleCancel}>
          <Form >
            <Input/>
            <div className="flex-end m-1">
              <Button className="btn-style mr-1" onClick={handleCancel}>CANCEL</Button>
              <Button htmlType="submit" className="save-btn" onClick={handleOk}>SUBMIT</Button>
            </div>
          </Form>
        </Modal>
      </div>
    </Spin>
  );
};

export default ViewDrs;
