import React, { useEffect, useState } from "react";
import { Table, Spin, Button, Input } from "antd";
import Heading from "../../../../components/Heading";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getFMLMTat } from "../../../../actionsMethods/masterMethods";
import { camelCase, capitaliseWithHyphen } from "../../../../utils";
import { APIs } from "../../../../utils/worker";
import search_icon from "../../../../assests/images/search-icon.svg";
import "./index.scss";
const FirstAndLastMileTAT = () => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const fmLmData = useSelector(
    (state) => state.masterReducer.getFMLMTatReducer,
    shallowEqual
  );
  const { result: fmLmResult, fetching: loading } = fmLmData;
  useEffect(() => {
    dispatch(getFMLMTat());
  }, []);

  useEffect(() => {
    if (fmLmResult) {
      let temp = [];
      fmLmResult?.data?.map((e, index) => {
        temp.push({
          key: index + 1,
          zone: e.zone ? e.zone : "N/A",
          city: e.cityName ? camelCase(e.cityName) : "N/A",
          state: e.stateName ? camelCase(e.stateName) : "N/A",
          pinCode: e.pincode,
          cpName: e.center ? camelCase(e.center) : "N/A",
          hubName: e.hubName ? camelCase(e.hubName) : "N/A",
          firstMile: e.fmTAT,
          lastMile: e.lmTAT,
        });
      });
      setDataSource(temp);
      setData(temp);
    }
  }, [fmLmResult]);

  const columns = [
    {
      title: "Sl No",
      dataIndex: "key",
      key: "key",
      align: "center",
      with: 10,
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
      with: 15,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      sorter: (a, b) => a.state.localeCompare(b.state),
      with: 15,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      with: 15,
    },
    {
      title: "PIN Code",
      dataIndex: "pinCode",
      key: "pinCode",
      with: 15,
    },
    {
      title: "CP Name",
      dataIndex: "cpName",
      key: "cpName",
      with: 15,
      render: (text) => <spna>{capitaliseWithHyphen(text)}</spna>,
    },
    {
      title: "Hub Name",
      dataIndex: "hubName",
      key: "hubName",
      with: 15,
      render: (text) => <spna>{capitaliseWithHyphen(text)}</spna>,
    },
    {
      title: "First Mile TAT",
      dataIndex: "firstMile",
      key: "firstMile",
      with: 15,
    },
    {
      title: "Last Mile TAT",
      dataIndex: "lastMile",
      key: "lastMile",
      with: 15,
    },
  ];

  const onSearch = (e) => {
    if (e.target.value.length < 1) {
      setPageNum(1);
    }
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(data, currValue)
        : filterArray(data, currValue);
    let temp = [];
    filteredData.map((item, index) => temp.push({ ...item, key: index + 1 }));
    setDataSource(temp);
  };

  const filterArray = (dataSource, currValue) =>
    dataSource?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );

  const handleDownload = () => {
    window.open(`${APIs.baseURL}/tat-service/v1/export/fm-lm`);
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  function handlePageChange(pagination) {
    setPageNum(pagination.current);
    setPageSize(pagination.pageSize);
  }

  return (
    <Spin spinning={loading}>
      <div className="p-2">
        <Heading
          title={"First & Last Mile TAT"}
          navigateTo={"/master-card-child"}
          id={7}
        />
        <div className="template-container">
          <div className="d-flex space-between align-center p-2">
            <Input
              placeholder="Search..."
              bordered
              onChange={onSearch}
              style={{ borderRadius: "5px", height: "40px", width: "400px" }}
              suffix={<img src={search_icon} />}
              className="ml-1-5"
            />
            <Button
              className="btn-style w-10 mr-1-5 save-btn"
              onClick={handleDownload}
            >
              DOWNLOAD
            </Button>
          </div>

          <Table
            columns={columns}
            dataSource={inputSearchValue.length == 0 ? data : dataSource}
            pagination={{
              pageSize: pageSize,
              itemRender: itemRender,
              pageSizeOptions: [5, 10, 20, 50, 100],
              current: pageNum,
            }}
            onChange={handlePageChange}
            className="table-head-sticky"
          />
        </div>
      </div>
    </Spin>
  );
};
export default FirstAndLastMileTAT;
