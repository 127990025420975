/* eslint-disable no-unused-vars */
import React from "react";
import { Button, Form, Row, Col } from "antd";
import "antd/dist/antd.css";

import mailIcon from "../../../assests/images/checkMailIcon.svg";
import "./style.scss";

function CheckMailInbox() {
  const onFinish = (values) => {
    delete values.remember;
    window.open("https://mail.google.com/mail/u/0/");
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="check-mail">
        <Row>
          <div className="formIcon">
            <img src={mailIcon} />
          </div>
        </Row>
        <div className="inner-content">
          <Row className="form-label">
            <Col span={24}>
              <div className="wlc-div">Check Your Email</div>
            </Col>
            <Col span={24}>
              <div className="msg">
                We have sent a password recovery instruction to your Email.
              </div>
            </Col>
          </Row>
          <Form
            initialValues={{
              username: "",
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="form"
          >
            <Form.Item>
              <div className="login-btn">
                <Button
                  className="loginBtn"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                >
                  <p className="openMail">OPEN EMAIL</p>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}

export default CheckMailInbox;
