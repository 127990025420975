/* eslint-disable indent */
import React, { useState } from "react";
import { Tabs } from "antd";
import Heading from "../../../components/Heading";
// import ViewAudit from "./ViewAudit";
import ViewAudit from "../AuditScannedTable";

import AuditInputs from "./AuditInputs";
import { useParams } from "react-router-dom";

import "./style.scss";
import constants from "../../../constants/constants";

const AuditTabs = () => {
  let userinfo = JSON.parse(localStorage.getItem("userinfo"));
  const { ROLES } = constants;
  const { HUB_ADMIN } = ROLES;

  const getRole = userinfo?.roles[0];
  let parmas = useParams();
  const [activeKey, setActiveKey] = useState("1");

  const changeTab = (key) => {
    setActiveKey(key);
  };

  const role = getRole == HUB_ADMIN;

  return (
    <div className="p-2">
      <Heading title={"Audit"} />
      <div className="audit-tab-container">
        <Tabs
          activeKey={activeKey}
          onChange={changeTab}
          items={
            role
              ? [
                  {
                    label: "Audit",
                    key: "1",
                    children: (
                      <AuditInputs awb={parmas.id} changeTab={changeTab} />
                    ),
                  },
                  {
                    label: "View Audit",
                    key: "2",
                    children: <ViewAudit activeKey={activeKey} />,
                  },
                ]
              : [
                  {
                    label: "View Audit",
                    key: "1",
                    children: <ViewAudit activeKey={activeKey} />,
                  },
                ]
          }
        />
      </div>
    </div>
  );
};

export default AuditTabs;
