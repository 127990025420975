/* eslint-disable indent */
import React from "react";
import { Row, Col } from "antd";
import constantsMsgs from "../../../constants/messages";
import { camelCase, capitalise } from "../../../utils";
import "../style.scss";
import ServiceSummary from "./ServiceSummary";
import ServiceNonDox from "../NonDox/ServiceNonDox";

const Dox = ({ allAppData, bookingType }) => {
  let addressDetails = allAppData?.addressDetails;
  let returnAddress = allAppData?.returnAddressDetail;

  const senderAddressData = `${
    addressDetails?.senderCity ? addressDetails.senderCity + "," : ""
  }${" "}
  ${
    addressDetails?.senderAddressLine1
      ? addressDetails.senderAddressLine1 + ","
      : ""
  }${" "}
  ${
    addressDetails?.senderAddressLine2
      ? addressDetails?.senderAddressLine2 + ","
      : ""
  }${" "}
  ${addressDetails?.senderState ? addressDetails.senderState + "," : ""}${" "}
  ${addressDetails?.senderPincode || ""}`;

  const receiverAddressData = `${
    addressDetails?.receiverCity ? addressDetails.receiverCity + "," : ""
  }${" "}
  ${
    addressDetails?.receiverAddressLine1
      ? addressDetails.receiverAddressLine1 + ","
      : ""
  }${" "}
  ${
    addressDetails?.receiverAddressLine2
      ? addressDetails?.receiverAddressLine2 + ","
      : ""
  }${" "}
  ${
    addressDetails?.receiverState ? addressDetails.receiverState + "," : ""
  }${" "}
  ${addressDetails?.receiverPincode || ""}`;

  const returnAddressData = (item) => {
    return camelCase(`${item?.returnCity ? item.returnCity + "," : ""}${" "}
    ${item?.returnAddressLine1 ? item.returnAddressLine1 + "," : ""}${" "}
    ${item?.returnAddressLine2 ? item?.returnAddressLine2 + "," : ""}${" "}
    ${item?.returnState ? item.returnState + "," : ""}${" "}
    ${item?.returnPincode || ""}`);
  };
  return (
    <div className="modal-content">
      <Row>
        <Col span={24}>
          <Row>
            <div className="title highlight">
              <h4>{constantsMsgs?.BOOKING_DETAILS}</h4>
            </div>
          </Row>
          <Row className="booking-details">
            <Col md={8} lg={8} xs={12} sm={12}>
              <div className="col">
                <p>
                  Document No. &nbsp;&nbsp;&nbsp;{"  "}
                  <span className="highlight">{allAppData?.awbNumber}</span>
                </p>
              </div>
              <div>
                <p>{constantsMsgs?.BOOKING_FROM}</p>{" "}
              </div>
              <div className="highlight">{camelCase(allAppData?.cpName)}</div>
            </Col>
            <Col md={14} lg={14} xs={12} sm={12}>
              <div className="col">
                <p>
                  {constantsMsgs?.BOOKING_DATE}&nbsp;{" "}
                  <span className="highlight">
                    {allAppData?.shipmentDetails?.bookingCompleteTime}
                    {/* ?.split(" ")[0]
                      ?.split("-")
                      ?.reverse()
                      ?.join("-") */}
                  </span>
                </p>
              </div>
              <div>
                <p>{constantsMsgs?.BOOKING_TO}</p>{" "}
              </div>
              <div className="highlight">
                {camelCase(allAppData?.destinationCpName)}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="address" gutter={16}>
        <Col>
          <Row>
            <div className="title">
              <h4>Address</h4>
            </div>
          </Row>
          <Row>
            <Col md={12} lg={12} sm={24} xs={24}>
              <div className="highlight">
                Sender Address{" "}
                {allAppData?.returnAddressDetail?.length == 0 &&
                  "/ Return Address"}
                {capitalise(addressDetails?.returnAddressDetail)}
              </div>
              <div className="card ">
                <div>
                  <h4>{addressDetails?.senderName}</h4>
                </div>
                <div>
                  <p>{camelCase(senderAddressData)}</p>
                  {addressDetails?.senderArea && (
                    <p>Area: {addressDetails?.senderArea}</p>
                  )}
                  <p>Phone no. {addressDetails?.senderMobileNumber}</p>
                </div>
              </div>
            </Col>
            <Col md={12} lg={12} sm={24} xs={24}>
              <div className="pl-05 highlight">Receiver Address</div>

              <div className="card card-one">
                <div>
                  <h4>{addressDetails?.receiverName}</h4>
                </div>
                <div>
                  <p>{camelCase(receiverAddressData)}</p>
                  {addressDetails?.receiverAreaName && (
                    <p>Area: {addressDetails?.receiverAreaName}</p>
                  )}
                  <p>
                    Phone no. {addressDetails?.receiverMobileNumber || "N/A"}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {returnAddress?.length > 0 && (
        <>
          <Row className="address" gutter={16}>
            <Col>
              <div className="title">
                <h4>Return Address</h4>
              </div>

              <Row>
                {returnAddress?.map((item, i) => (
                  <Col md={12} lg={12} sm={24} xs={24} key={i}>
                    <div className="card card-one">
                      <div>
                        <h4>{item?.returnName}</h4>
                      </div>
                      <div>
                        <p>{returnAddressData(item)}</p>
                        <p>Area: {item?.returnAreaName || "N/A"}</p>
                        <p>Phone no. {item?.returnMobileNumber || "N/A"}</p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </>
      )}
      <Row className="service">
        <Col span={24}>
          <Row>
            <div className="title">
              <h4>Service Details</h4>
            </div>
          </Row>
        </Col>
      </Row>
      <Row>
        {allAppData?.shipmentDetails?.type.toLowerCase() === "dox" ? (
          <ServiceSummary allAppData={allAppData} />
        ) : (
          <ServiceNonDox allAppData={allAppData} />
        )}
      </Row>
      {allAppData?.childShipmentDetails?.length > 0 ? (
        <Row className="payment">
          <Col span={24}>
            <Row>
              <div className="title">
                <h4>Child Details</h4>
              </div>
            </Row>
            <Row gutter={24}>
              {allAppData?.childShipmentDetails?.map((data, index) => (
                <Col md={12} lg={12} sm={24} xs={24} key={index}>
                  <div className="card left-card">
                    <Row>
                      <Col span={12} className="key">
                        <p>Document No. : </p>
                        <p>Weight :</p>
                        <p>Vol weight :</p>
                        <p>Value :</p>
                      </Col>
                      <Col span={12} className="value">
                        <p>{data?.childAwbNumber}</p>
                        <p>{data?.weight}</p>
                        <p>{data?.volumetricWeight}</p>
                        <p>{data?.value}</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ) : null}
      {/* {allAppData?.charges !== null && ( */}
      {bookingType == "Cash" && (
        <Row className="payment">
          <Col span={24}>
            <Row>
              <div className="title">
                <h4>Payment</h4>
              </div>
            </Row>
            <Row>
              <Col md={12} lg={12} sm={24} xs={24}>
                <div className="card left-card">
                  <Row>
                    <Col span={12} className="key">
                      <p>Total charges: </p>
                      <p>SGST @ 9% : </p>
                      <p className="last">CGST @ 9% : </p>
                    </Col>
                    <Col span={12} className="value">
                      <p>{allAppData?.charges?.charges}</p>
                      <p>
                        {allAppData?.charges?.sgst
                          ? allAppData?.charges?.sgst
                          : "00.00"}
                      </p>
                      <p className="last">
                        {allAppData?.charges?.cgst
                          ? allAppData?.charges?.cgst
                          : "00.00"}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={12} lg={12} sm={24} xs={24}>
                <div className="card right-card">
                  <Row>
                    <Col span={12} className="key">
                      {allAppData?.charges?.igst > 0 && (
                        <p> {constantsMsgs?.IGST}: </p>
                      )}
                      <p>{constantsMsgs?.CESS} : </p>
                    </Col>
                    <Col span={12} className="value">
                      {allAppData?.charges?.igst > 0 && (
                        <p>{allAppData?.charges?.igst}</p>
                      )}
                      <p>
                        {allAppData?.charges?.cess
                          ? allAppData?.charges?.cess
                          : "00.00"}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="frieght-class">
            <p id="frieght"> Freight :</p>
            <p id="frieghtValue">{allAppData?.charges?.freight}</p>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Dox;
