/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from "react";
import { Input, message, Button, Row, Col, Spin, Select } from "antd";

import { capitaliseWithHyphen, capitalize } from "../../../utils";
import { getPremiseId } from "../../../utils/worker";
import { useNavigate } from "react-router-dom";
import { Empty } from "antd";

import { ReactComponent as Scan_check } from "../../../assests/images/SVG/check.svg";

import urls from "../../../api/urls";
import API from "../../../api";
import Heading from "../../../components/Heading";
import search_icon from "../../../assests/images/search-icon.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import sorting_filter from "../../../assests/images/master/sorting-filter.svg";

const {
  SORT_LAST_MILE_TYPES,
  GET_SORT_LAST_MILE_ELIGIBLE_DATA,
  GENERATE_MANIFEST,
} = urls;

import "./style.scss";
import ScannedShipment from "../../../components/ScannedShipment";

const premiseId = getPremiseId();

const SortToCp = () => {
  const [loading, setLoading] = useState(false);
  const [mainData, setMainData] = useState({});
  const [outscanMainData, setOutscanMainData] = useState({});
  const [premiseName, setPremiseName] = useState("");
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [outscanType, setOutScanType] = useState([]);
  const [filterType, setFilterType] = useState(null);

  useEffect(() => {
    getSortTypes();
  }, []);

  const getSortTypes = async () => {
    const response = await API.Api(SORT_LAST_MILE_TYPES).getWithHeader();
    if (response?.status) {
      const data =
        response?.response?.data?.map((e) => ({
          id: e.id,
          label: e.name,
          value: e.name,
        })) ?? [];
      setOutScanType([...data]);
      getAllEligibleData(data[0]?.id);
      setFilterType(data[0]?.id);
    } else {
      message.error(
        response?.response?.data?.message || "Internal server error"
      );
    }
  };

  const getAllEligibleData = async (typeId) => {
    setLoading(true);
    const response = await API.Api(
      `${GET_SORT_LAST_MILE_ELIGIBLE_DATA}/${
        premiseId?.split("Bearer ")[1]
      }/type/${typeId}`
    ).getWithHeader();
    if (response?.status) {
      setMainData(groupByType(response?.response?.data));
      setOutscanMainData(groupByType(response?.response?.data));
      setLoading(false);
    } else {
      setLoading(false);
      message.error(
        response?.response?.data?.message || "Internal server error"
      );
    }
  };

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("userId"));
    setPremiseName(data?.premises?.premiseName);
    //getTableData();
  }, []);

  const groupByType = (data) => {
    return data.reduce((acc, item) => {
      if (!acc[item.type]) {
        acc[item.type] = [];
      }
      acc[item.type].push({
        ...item,
        currentScanNum: null,
        driverName: null,
        vehicleNumber: null,
      });
      console.log(acc);

      return acc;
    }, {});
  };

  //--Barcode Scanner--//
  let barcode = "";
  let interval;
  const handleKeyDown = (event) => {
    if (interval) clearInterval(interval);
    if (event.code == "Enter") {
      if (barcode) handleStatus(barcode);
      barcode = "";
      return;
    }
    if (event.key != "Shift") barcode += event.key;
    interval = setInterval(() => (barcode = ""), 20);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [mainData]);

  const handleStatus = async (barcode, id) => {
    const resultNumber = barcode?.replace(/[a-zA-Z]/g, "");
    let temp = JSON.parse(JSON.stringify(mainData));
    Object?.keys(temp)?.forEach((key) => {
      temp[key] = temp[key]?.map((item) => {
        if (item?.sendToId === id) {
          return {
            ...item,
            shipmentList: item?.shipmentList?.map((shipment) => {
              if (shipment?.awbNumber == resultNumber) {
                return { ...shipment, isScanned: true };
              }
              return shipment;
            }),
          };
        }
        return item;
      });
    });
    const sortShipmentList = (data) => {
      for (const key in data) {
        if (Array.isArray(data[key])) {
          data[key].forEach((item) => {
            if (item?.shipmentList && Array?.isArray(item?.shipmentList)) {
              item?.shipmentList?.sort((a, b) => a?.isScanned - b?.isScanned);
            }
          });
        }
      }
      return data;
    };
    const sortedData = sortShipmentList(temp);
    setMainData({ ...sortedData });
    setOutscanMainData({ ...sortedData });
  };

  const changeCurrentValue = (value, id, name) => {
    let temp = JSON.parse(JSON.stringify(mainData));
    Object?.keys(temp)?.forEach((key) => {
      temp[key] = temp[key]?.map((item) => {
        if (item?.sendToId === id) {
          item[name] = value;
          return {
            ...item,
          };
        }
        return item;
      });
    });
    setMainData({ ...temp });
    setOutscanMainData({ ...temp });
  };

  const generateManifest = async (type, id, index, driverName, vehicleNo) => {
    let shipmentList = mainData[type][index]?.shipmentList?.filter(
      (item) => item?.isScanned
    );
    let payload = {
      awbNumbers: shipmentList?.map((item) => item?.awbNumber),
      scannedTo: id,
      vehicleNumber: vehicleNo,
      driverName: driverName,
    };
    if (shipmentList?.length > 0) {
      const response = await API.Api(GENERATE_MANIFEST).postIdHeader(payload);
      if (response?.status) {
        getAllEligibleData(filterType);
        message.success(
          response?.response?.data?.message ||
            "Manifest Generated Successfully."
        );
      } else {
        message.error(
          response?.response?.data?.message || "Internal server error."
        );
      }
    }
  };

  const filterArray = (data, currValue) => {
    let convertedArray = [];

    for (const key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((item) => {
          const convertedItem = {
            type: item?.type,
            name: item?.name,
            hubName: item?.hubName,
            numberOfPackets: item?.numberOfPackets,
            numberOfShipments: item?.numberOfShipments,
            sendToId: item?.sendToId,
            shipmentList: item?.shipmentList?.map((shipment) => ({
              awbNumber: shipment?.awbNumber,
              isScanned: shipment?.isScanned,
            })),
            shipment: item?.shipmentList?.map(
              (shipment) => shipment?.awbNumber
            ),
          };
          convertedArray.push(convertedItem);
        });
      }
    }
    return convertedArray?.filter((o) =>
      Object?.keys(o)?.some((k) =>
        String(o[k])?.toLowerCase()?.includes(currValue?.toLowerCase())
      )
    );
  };

  const onSearch = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);
    const filteredData =
      currValue.length > 1
        ? filterArray(mainData, currValue)
        : filterArray(outscanMainData, currValue);
    let data = groupByType(filteredData);
    setMainData(data);
  };

  const changeFilterType = (val) => {
    let filterId = outscanType?.find((e) => e.value == val).id;
    getAllEligibleData(filterId);
    setFilterType(filterId);
    // if (val === "All") {
    //   setMainData(outscanMainData);
    // } else {
    // let x = outscanMainData[val];
    // setMainData({ [val]: x ? x : [] });
    // }
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }>
      <div className="p-2 in-scan-container sort-to-cp">
        <Row justify="space-between">
          <Heading title="Sort to Last Mile" />
          {/* <div>
            <ScannedShipment
              scannedData={totalScannedShipments}
              totalData={totalShipments}
            />
          </div> */}
        </Row>
        <div className="master-main-container m-1">
          <div className="space-between p-05">
            <Input
              className="mr-1"
              placeholder="Search by Document No, CP Name"
              onChange={onSearch}
              bordered
              style={{
                borderRadius: "5px",
                height: "40px",
                width: 300,
              }}
              suffix={<img src={search_icon} />}
            />
            <div>
              <img src={sorting_filter} alt="filter" />
              <span>Filter</span>
              <Select
                defaultValue="All"
                className="filter-select mr-1 ml-1"
                style={{
                  width: 150,
                }}
                options={outscanType}
                onChange={changeFilterType}
              />
            </div>
          </div>
          <hr className="divider" />
          <div className="content-body">
            {Object.keys(mainData).map((type) => (
              <div key={type}>
                <Row>
                  {mainData[type].map((val, index) => (
                    <Col
                      key={index}
                      className={"sort-to-cp-container"}
                      lg={8}
                      md={12}
                      xs={24}
                      xl={8}
                      sm={24}>
                      <Col span={24} className="card" style={{ padding: 0 }}>
                        <div
                          style={{
                            background:
                              val?.type === "CP"
                                ? "#EE9765"
                                : val.type === "DIRECT"
                                ? "#52BD70"
                                : "#488DE1",
                          }}>
                          <div className="p-05">
                            <span className="sub-title">{val.type} Name: </span>
                            <span className="fw-500">
                              {capitaliseWithHyphen(val.name)}
                            </span>
                          </div>
                        </div>
                        <div className="p-1">
                          <Row>
                            <Col span={9}>
                              <div className="title d-flex">
                                <span className="sub-title">HUB Name: </span>
                              </div>
                            </Col>
                            <Col span={15}>
                              <div className="title d-flex">
                                <span className="fw-500">
                                  {capitalize(premiseName)}
                                </span>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={9}>
                              <div className="title d-flex">
                                <span className="sub-title">
                                  No of shipments:{" "}
                                </span>
                              </div>
                            </Col>
                            <Col span={15}>
                              <div className="title d-flex">
                                <span className="fw-500">
                                  {
                                    (val.shipmentList?.filter(
                                      (e) => e.isScanned
                                    )).length
                                  }{" "}
                                  / {val.shipmentList?.length || 0}
                                </span>
                              </div>
                            </Col>
                          </Row>

                          <Row gutter={16}>
                            {/* <Col span={12}>
                              <div className="title d-flex">
                                <span className="sub-title">
                                  No of packets: {val.numberOfPackets || 0} / 0
                                </span>
                              </div>
                            </Col> */}
                            <Col span={12}>
                              <Input
                                placeholder="Enter Driver Name"
                                bordered
                                style={{
                                  borderRadius: "5px",
                                  height: "35px",
                                }}
                                onChange={(e) =>
                                  changeCurrentValue(
                                    e.target.value,
                                    val?.sendToId,
                                    "driverName"
                                  )
                                }
                                value={val?.driverName}
                              />
                            </Col>
                            <Col span={12}>
                              <Input
                                placeholder="Enter Vehicle Number"
                                bordered
                                style={{
                                  borderRadius: "5px",
                                  height: "35px",
                                }}
                                onChange={(e) =>
                                  changeCurrentValue(
                                    e.target.value,
                                    val?.sendToId,
                                    "vehicleNumber"
                                  )
                                }
                                value={val?.vehicleNumber}
                              />
                            </Col>
                            <Col span={24} className="mt-05">
                              <Input
                                placeholder="Enter AWB number to scan"
                                bordered
                                onPressEnter={(e) => {
                                  if (e?.target?.value?.length > 10) {
                                    handleStatus(e.target.value, val?.sendToId);
                                  } else {
                                    message.error("Enter correct AWB number");
                                  }
                                }}
                                style={{
                                  borderRadius: "5px",
                                  height: "35px",
                                }}
                                onChange={(e) =>
                                  changeCurrentValue(
                                    e.target.value,
                                    val?.sendToId,
                                    "currentScanNum"
                                  )
                                }
                                value={val.currentScanNum}
                              />
                            </Col>
                          </Row>

                          <hr className="divider" />
                          <div className="title list-container">
                            <span className="sub-title">Shipments List: </span>
                            <Row className="barcode-list">
                              {val.shipmentList.map((shipment, i) => (
                                <Col
                                  lg={18}
                                  xl={11}
                                  md={20}
                                  sm={20}
                                  xs={20}
                                  key={i}
                                  style={{
                                    background: "white",
                                    padding: "8px",
                                    margin: "2px",
                                    whiteSpace: "nowrap",
                                    border: "1px solid #EAE0C0",
                                  }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}>
                                    <span>{shipment.awbNumber}</span>

                                    <span>
                                      {shipment.isScanned && <Scan_check />}
                                    </span>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </div>
                          <hr className="divider" />
                          <div className="flex-end">
                            <Button
                              className="save-btn"
                              disabled={
                                !val?.shipmentList?.some((e) => e.isScanned)
                              }
                              onClick={() =>
                                generateManifest(
                                  val.type,
                                  val?.sendToId,
                                  index,
                                  val?.driverName,
                                  val?.vehicleNumber
                                )
                              }>
                              GENERATE MANIFEST
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
            {Object.keys(mainData).length === 0 && (
              <Row>
                <Col span={24}>
                  <Empty />
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default SortToCp;

//
