import React from "react";
import { Row, Col, Button } from "antd";
import GenerateLcrModal from "./GenerateLcrModal";
import checkIcon from "../../../assests/images/SVG/check.svg";


export function EiligibleBagContainer({
  allEligibleBags,
  capitaliseWithHyphen,
  handleGenerateIndex,
  setIsModalOpen,
  isModalOpen,
  lcrPayload,
  unScannedBag,
  reason,
  vehicleLcr,
  handleCallBack,
  tripID
}) {
  return (
    <Row gutter={12} className="mt-1" id="bag-id">
      {allEligibleBags?.map((data, index) => {
        let unscanned = data?.barcodesList?.filter(
          (data) => data.status === false
        );
        let scanned = data?.barcodesList?.filter((data) => data.status);
        let arrScan = [...unscanned, ...scanned];
        return (
          <Col lg={12} md={12} xs={24} xl={8} sm={24} key={index}>
            <div className="bagOutscanCont p-1">
              <Row>
                <Col span={24}>
                  <b>Destination: </b>
                  <span>{capitaliseWithHyphen(data?.destination)}</span>
                </Col>
              </Row>
              <Row>
                <Col span={15}>
                  <b>Weight: </b>
                  {data?.totalWeight || 0}
                  <span> Kg</span>
                </Col>
                <Col span={9}>
                  <b>Mode: </b>
                  {capitaliseWithHyphen(data?.mode)}
                </Col>
              </Row>
              <Row className="mb-1 mt-1">
                <Col span={12}>
                  <Button
                    className="save-btn"
                    onClick={() =>
                      handleGenerateIndex(index, data?.destination)
                    }
                    disabled={data?.buttonDisable}
                  >
                    GENERATE LCR
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="table-head">
                    <b>Bag ID / AWB No</b>
                  </div>
                  <div className="table-col-wrap">
                    {arrScan?.map((item, index) => {
                      return (
                        <div
                          className="table-column align-center space-between"
                          key={"bag" + index}
                        >
                          <div className="align-center">
                            <p>{item?.awbNumber}</p>{" "}
                            {item?.status ? <img src={checkIcon} /> : ""}
                          </div>
                          <p>{item?.weight} Kg</p>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
              <GenerateLcrModal
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                lcrPayload={lcrPayload}
                unScannedBag={unScannedBag}
                reason={reason}
                vehicleLcrId={vehicleLcr}
                handleCallBack={handleCallBack}
                tripID={tripID}
              />
            </div>
          </Col>
        );
      })}
    </Row>
  );
}
