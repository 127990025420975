/* eslint-disable max-len */

const messages = {
  NOT_AUTHORIZED:
    "You are not authorized or session has been expired. Please try again.",
  NO_DATA_AVAILABLE: "No data available.",
  ACCESS_DENIED: "Access Denied/Forbidden.",
  DEFAULT_ERROR_MESSAGE:
    "There seems to be a problem, please retry after sometime.",
  USER_NOT_FOUND: "Invalid user",
  LOGIN: {},
  IMAGE_NOT_AVAILABLE: "Image not available",
  FORGOT_PASSWORD_LINK: "Activation Link sent to Email",
  ARE_YOU_SURE: "Are you sure?",
  LOADING: "Loading...",

  DECLINING_REASON_LABEL: "Declining Reason",

  /*****************************Dashboard constants********************************/
  TRACK_ORDER: "Track Order",
  PHONE_NUMBER: "AWB No",
  TRACK_ID: "Eway Bill No",
  ORDER_ID: "Order ID",
  TRACK: "TRACK",
  CASH_BOOKING: "Retail Booking",
  DAY_TOTAL: "Day Total",
  CASH_CHARGES: "Cash Charges",
  FREIGHT_CHANGES: "Freight Charges",
  ANNOUNCEMENT: " Announcements",
  ACCOUNT_BOOKING: "Account Booking",

  /*************************Shipping calculater88*********************************/
  SHIPPING_RATE_CALCULATOR: "Shipping Rate Calculator",
  PICKUP_PINCODE: "Pickup PIN code",
  DELIVERY_PINCODE: "Delivery PIN code",
  TYPE: "Type",
  CALCULATE: "CALCULATE",
  AREA: "Area",
  CONTENT: "Content",
  DESCRIPTIONS: "Description",
  SERVICES: " Select Service",

  /*************************Address*********************************/
  SENDER_ADDRESS: "Sender's Address",
  RECEIVER_ADDRESS: "Receiver's Address",
  /*************************service details*********************************/
  DOCUMENT: "Document",
  FORMS_CATALOGUE_PAPER: "Forms, Catalogue, Paper",
  CLICK_TO_UPLOAD: "Click to upload",
  OR_DRAG_AND_DROP: "or drag and drop",
  SUPPORTING_DOCUMENTS: "i.e. supporting documents",
  UPLOAD_FILES: "Upload Document",
  /*************************Buttons*********************************/
  BACK: "BACK",
  NEXT: "NEXT",
  SUBMIT: "SUBMIT",

  /*************************Payments*********************************/

  PAYMENT_MESSAGE_ONE: "How would you like to pay?",
  BILL_DETAILS: "Bill Details",
  TOTAL_CHARGES: "Total Charges",
  SGST: "SGST @9%",
  CGST: "CGST @9%",
  IGST: "IGST @18%",
  CESS: "CESS/KFC 0%",
  FRIEGHT: "Freight",
  CLICK_HERE: "Click here",
  PAYMENT_MESSAGE_TWO:
    "to review the address and order details before the submission",

  /*************************Booking SUmmary*********************************/
  BOOKING_DETAILS: "Booking Details",
  AWB_NO: "AWB no:",
  BOOKING_FROM: "Booking from",
  BOOKING_DATE: "Booking date:",
  BOOKING_TO: "Booking to",

  /*************************newRate card*********************************/
  EXPECTED_by: "Expected by",
  DAYS: "days",
  BASE_CHARGES: "Base charges:",
  PREMIUM: "premium:",
  FOV: "FOV:",
  COD: "COD:",
  ESTIMATED: "Estimated",
  CHOOSE: "Choose",
};

export default messages;
