/* eslint-disable no-unused-vars */
import { Button, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import API from "../../../../api";
import urls from "../../../../api/urls";
import Moment from "react-moment";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { capitaliseWithHyphen } from "../../../../utils";

const { GET_RECENT_BOOKING } = urls;

function RecentBooking({ recentAPICall, bookingType }) {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    getRecentBooking(bookingType);
  }, [recentAPICall]);

  function MyComponent(dateToFormat) {
    const toUpperCaseFilter = (d) => {
      return d.slice(0, 10);
    };
    return (
      <Moment filter={toUpperCaseFilter} format="DD/MM/YYYY">
        {dateToFormat}
      </Moment>
    );
  }

  const columns = [
    {
      title: "AWB Number",
      dataIndex: "awbNumber",
      key: "awbNumber",
      className: "awbNumber",
      defaultSortOrder: "descend",
      width: 125,
      //   render: (text, b) => <a onClick={() => handleEyeButton(b)}>{text} </a>,
    },
    {
      title: "Date",
      dataIndex: "bookingTime",
      key: "bookingTime",
      width: 150,
      render: (text, b) => MyComponent(text),
    },

    {
      title: "Booking Type",
      width: 150,
      dataIndex: "bookingType",
      key: "bookingType",
      //   render: (text) =>
      //     text?.toUpperCase() === "ACCOUNT" ? "Credit" : "Retail",
    },
    {
      title: "Type",
      width: 150,
      dataIndex: "type",
      key: "type",
      render: (text) => capitaliseWithHyphen(text),
    },
    {
      width: 150,
      title: "Service Type",
      dataIndex: "serviceType",
      key: "serviceType",
      render: (text) => capitaliseWithHyphen(text),
    },
    {
      width: 150,
      title: "Mode",
      dataIndex: "travelBy",
      key: "travelBy",
      render: (text, val) => capitaliseWithHyphen(text),
    },
  ];

  const getRecentBooking = async (type = "account") => {
    // setLoading(true);
    const response = await API.Api(
      GET_RECENT_BOOKING + type
    ).getWithPremiseIdHeader();

    if (response?.status) {
      setDataSource([response?.response?.data]);
      //   setLoading(false);
      return;
    } else {
      setDataSource([]);
      // message.error(response?.response?.data?.message);
    }
  };

  return (
    <div className="recent-booking-container">
      <div className="space-between">
        <h4 className="ml-1">Recent bookings</h4>
        <Button
          style={{
            border: "none",
            color: "#2e77d0",
          }}
          onClick={() => navigate("/viewBooking")}
        >
          View all
        </Button>
      </div>
      <Table
        dataSource={dataSource?.length ? dataSource : []}
        columns={columns}
        pagination={false}
        size="small"
      />
    </div>
  );
}

export default RecentBooking;
