import React from "react";
import { Row, Col, Table } from "antd";
import { capitalize } from "../utils";
import appLogo from "../assests/images/smcsLogo.svg";
import locationLogo from "../assests/images/LocationIconFomManifest.svg";

export function PrintManifest({
  componentRef,
  packetAndShipmt,
  columnsWOChild,
  packetDataNonDoxWithNoChild,
  columnsWithChild,
  packetDataNonDoxWithChild,
  columnsDox,
  packetDataDox,
  //packets,
  //packetData,
  // filter,
  //Boolean,
}) {
  return (
    <div className="lcr-report " ref={componentRef}>
      <div className="wrapper-print m-2">
        <img src={appLogo} />
        <div className="address-print d-flex mt-1">
          <Row>
            <Col span={8}>
              <b className=".align-center">
                <img src={locationLogo} className="mr-1" />
                CP Address
              </b>
              <p
                className="w-70"
                style={{
                  marginLeft: "20px",
                }}>
                {capitalize(packetAndShipmt?.fromLocation)}
              </p>
            </Col>
            <Col span={8}></Col>
            <Col span={8}>
              <b className=".align-center">
                <img src={locationLogo} className="mr-1" />
                Hub Address
              </b>
              <p
                className="w-70"
                style={{
                  marginLeft: "20px",
                }}>
                {capitalize(packetAndShipmt?.toLocation)}
              </p>
            </Col>
          </Row>
        </div>
        <Row>
          <Col span={8}>
            <b>Manifest ID :</b>
            {packetAndShipmt?.manifestId}
          </Col>
          <Col span={8}>
            <b>Date & Time :</b>
            {packetAndShipmt?.createDate?.replaceAll(" ", " / ")}
          </Col>
        </Row>
      </div>
      <div className="table-lable ml-2 mr-2">
        <b> Non Dox W/O Child Shipment</b>
      </div>
      <Table
        columns={columnsWOChild}
        dataSource={packetDataNonDoxWithNoChild || []}
        pagination={false}
        className="ml-2 mr-2"
      />
      <div className="table-lable ml-2 mr-2">
        <b> Non Dox With Child Shipment</b>
      </div>
      <Table
        columns={columnsWithChild}
        dataSource={packetDataNonDoxWithChild || []}
        pagination={false}
        className="ml-2 mr-2"
      />
      <div className="table-lable ml-2 mr-2">
        <b> Dox </b>
      </div>
      <Table
        columns={columnsDox}
        dataSource={packetDataDox || []}
        pagination={false}
        className="ml-2 mr-2"
      />
      {/* <div className="table-lable ml-2 mr-2">
        <b> Packets </b>
      </div>
      <Table
        columns={packets}
        dataSource={packetData?.filter(Boolean) || []}
        pagination={false}
        className="ml-2 mr-2 mb-2"
      /> */}
    </div>
  );
}
