import React, { useState, useEffect } from "react";
import paperpin from "../../../assests/images/paperpin.svg";

export const PacketFooter = ({ item, childPacket }) => {
  const [ewayBill, setEwayBill] = useState(false);

  useEffect(() => {
    if (childPacket?.ewayBillNumber) {
      setEwayBill(true);
    } else if (
      childPacket?.childShipmentDetails?.some((e) => e.ewayBillNumber)
    ) {
      setEwayBill(true);
    } else {
      setEwayBill(false);
    }
  }, [childPacket]);

  return (
    <div className="packetfooter">
      <div>
        {ewayBill ? (
          <>
            <div className="EwayBill">
              <img src={paperpin} style={{ paddingRight: "5px" }} />
              E-way Bill
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div>
        {item > 0 ? (
          <>
            <div className="EwayBill" style={{ paddingRight: "5px" }}>
              <img src={paperpin} /> Child Packet
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
