/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Input, Row, Col, Dropdown, Checkbox, Menu, Spin } from "antd";
import back_arrow from "../../../assests/images/master/back-arrow.svg";
import search_icon from "../../../assests/images/search-icon.svg";
import tshirt from "../../../assests/images/tshirt.svg";
import filter_icon from "../../../assests/images/master/sorting-filter.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import API from "../../../api";
import urls from "../../../api/urls";

import { firstLetterCapsInSentence } from "../../../utils";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const ProductCP = () => {
  const { GET_PRODUCTS_BY_PREMISE } = urls;
  const user_premiseId = JSON.parse(window?.localStorage?.getItem("userId"));
  const navigate = useNavigate();
  const [productList, setProductList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [productListParent, setProductListParent] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getProductList();
  }, []);

  const getProductList = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_PRODUCTS_BY_PREMISE
    ).getWithUserNPremiseHeader();
    if (response.status) {
      setProductList(
        response?.response?.data.filter((item) => item.name !== null)
      );
      setProductListParent(
        response?.response?.data.filter((item) => item.name !== null)
      );
      let temp = response?.response?.data
        .filter((item) => item.name !== null)
        .map((item) => item.categoryName);
      setCategories([...new Set(temp)]);
    }
    setLoading(false);
  };
  const search = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);
    const filteredData = productListParent?.filter((o) =>
      o.name.toLowerCase().includes(currValue.toLowerCase())
    );
    setProductList(filteredData);
  };
  const changeCheck = (e, x) => {
    if (filteredData.includes(e.target.value)) {
      let temp = filteredData.filter((item) => item !== e.target.value);
      if (temp.length === 0) {
        setProductList(productListParent);
        setFilteredData(temp);
      } else {
        setProductList(
          productListParent.filter((item) => temp.includes(item.categoryName))
        );
        setFilteredData(temp);
      }
    } else {
      let temp = [...filteredData, e.target.value];
      if (temp.length === 0) {
        setProductList(productListParent);
      } else {
        setProductList(
          productListParent.filter((item) => temp.includes(item.categoryName))
        );
        setFilteredData(temp);
      }
    }
  };
  // const items = [
  //   {
  //     label: (
  //       <>
  //         <span>By Category</span>
  //         <hr className="divider" />
  //       </>
  //     ),
  //     key: 199,
  //     style: { fontSize: "13px" },
  //   },
  //   ...categories.map((item, index) => ({
  //     label: (
  //       <div key={"drop_" + index}>
  //         <Checkbox onChange={changeCheck} value={item} />
  //         &nbsp;<span>{item}</span>
  //       </div>
  //     ),
  //     key: { index },
  //     style: { fontSize: "14px" },
  //   })),
  // ];
  const menu = (
    <Menu
      style={{ cursor: "default", backgroundColor: "#fff" }}
      items={[
        {
          label: (
            <>
              <span>By Category</span>
              <hr className="divider" />
            </>
          ),
          key: 199,
          style: { fontSize: "13px" },
        },
        ...categories.map((item, index) => ({
          label: (
            <Checkbox onChange={changeCheck} key={"drop_" + index} value={item}>
              &nbsp;<span>{item}</span>
            </Checkbox>
          ),
          key: { index },
          style: { fontSize: "14px", cursor: "default" },
        })),
      ]}
    />
  );

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }>
      <div className="p-2">
        <div className="master-search-container">
          <span className="d-flex">
            <img
              src={back_arrow}
              className="pointer"
              onClick={() => navigate("/dashboard")}
            />
            &nbsp;&nbsp;{"Product"}
          </span>
          <div className="align-center">
            <Input
              placeholder={"Search Product"}
              bordered
              style={{ borderRadius: "5px", height: "40px", width: "350px" }}
              suffix={<img src={search_icon} />}
              onChange={search}
            />
            &nbsp;&nbsp;
            <div className="pointer">
              <Dropdown
                overlay={menu}
                overlayClassName="nav-dropdown-overlay filter-order"
                // open={true}
              >
                <div className="pointer" onClick={(e) => e.preventDefault()}>
                  <img src={filter_icon} height={20} />
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>
                    Filter
                  </span>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        <div
          className="master-main-container p-2"
          style={{ borderRadius: "0px 0px 5px 5px" }}>
          <Row gutter={20}>
            {productList?.map((item, index) => (
              <Col sm={12} md={8} lg={6} xl={4} key={"product_" + index}>
                <div
                  style={{
                    textAlign: "center",
                    border: "1px solid #E0E1E2",
                    borderRadius: "5px",
                    marginBottom: "2rem",
                    padding: "1rem 0rem",
                    cursor: "pointer",
                    minHeight: "220px",
                    minWidth: "160px",
                  }}
                  className="hover-zoom"
                  onClick={() => navigate("/product-details/" + item.id)}>
                  <img
                    src={
                      item?.imageDtos.length > 0
                        ? item?.imageDtos[0].imagePath
                        : tshirt
                    }
                    style={{
                      height: "200px",
                      width: "150px",
                      objectFit: "contain",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      padding: "10px",
                    }}>
                    {/* {firstLetterCapsInSentence(item?.name) || "N/A"} */}
                    {item?.name || "N/A"}
                  </div>
                  <div style={{ fontSize: "16px", fontWeight: "500" }}>
                    ₹&nbsp;
                    {item?.stateWisePriceDto.find(
                      (e) => e.stateName === user_premiseId?.premises?.state
                    )?.price || item?.price}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </Spin>
  );
};
export default ProductCP;
