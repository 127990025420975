import { takeLatest } from "redux-saga/effects";

import { createWatcher } from "../utils/sagas";
import actions from "../constants/cashBookingActions";

import * as cashBookingWorker from "../workers/cashbooking/cashBookingworker";

export const loginUser = createWatcher(
  actions.LOGIN_USER,
  cashBookingWorker.loginUser,
  takeLatest
);

export const getCpPremises = createWatcher(
  actions.GET_CP_PREMISES,
  cashBookingWorker.getCpPremises,
  takeLatest
);
export const getSenderPincode = createWatcher(
  actions.GET_SENDER_PINCODE,
  cashBookingWorker.getsenderPincode,
  takeLatest
);
export const getGSTDetails = createWatcher(
  actions.GET_GST_DETAILS,
  cashBookingWorker.getGSTDetails,
  takeLatest
);

export const getBookingId = createWatcher(
  actions.GET_BARCODE_ID,
  cashBookingWorker.getBookingId,
  takeLatest
);

export const getBookingDetails = createWatcher(
  actions.GET_BOOKING_DETAILS,
  cashBookingWorker.getBookingDetails,
  takeLatest
);

export const getServiceAbilityCheck = createWatcher(
  actions.GET_SERVICEABILITY_DETAILIS,
  cashBookingWorker.getServiceAbilityCheck,
  takeLatest
);

export const getCalculaterTatChart = createWatcher(
  actions.GET_CAL_TATCHART,
  cashBookingWorker.getCalculaterTatChart,
  takeLatest
);

export const getPinCodeArea = createWatcher(
  actions.GET_PINCODE_AREA,
  cashBookingWorker.getPinCodeArea,
  takeLatest
);

export const getAccountServiceWatcher = createWatcher(
  actions.GET_ACCOUNT_SERVICE,
  cashBookingWorker.getAccountService,
  takeLatest
);

export const postShipmentWatcher = createWatcher(
  actions.CREATE_SHIPMENT,
  cashBookingWorker.postShipmentWorker,
  takeLatest
);

export const updateAWBNumWatcher = createWatcher(
  actions.UPDATE_AWB_NUM,
  cashBookingWorker.updateAWBNumWorker,
  takeLatest
);

export const getSingleBookingWatcher = createWatcher(
  actions.GET_SINGLE_VIEWBOOKING,
  cashBookingWorker.getSingleBookingWorker,
  takeLatest
);

export const getchildPacketIdsWatcher = createWatcher(
  actions.GET_CHILDPACKETIDs,
  cashBookingWorker.getchildPacketIdsWorker,
  takeLatest
);

export const getPostalPincodeWatcher = createWatcher(
  actions.GET_POSTAL_PINCODE,
  cashBookingWorker.getPostalPincodeWorker,
  takeLatest
);

export const createChildShipmentWatcher = createWatcher(
  actions.CREATE_CHILD_SHIPMENT,
  cashBookingWorker.createChildShipmentWorker,
  takeLatest
);

export const getUpdateShipmentWatcher = createWatcher(
  actions.UPDATE_SHIPMENT,
  cashBookingWorker.updateShipmentWorker,
  takeLatest
);

// eway-bill
export const getEWayBillWatcher = createWatcher(
  actions.GET_E_WAY_BILL,
  cashBookingWorker.getEWayBillWorker,
  takeLatest
);

//vehicle onboard
export const onboardVehicleWatcher = createWatcher(
  actions.ONBOARD_VEHICLE_,
  cashBookingWorker.onboardVehicleWorker,
  takeLatest
);

export const updateOnboardedVehicleWatcher = createWatcher(
  actions.UPDATE_ONBOARDED_VEHICLE_,
  cashBookingWorker.updateOnboardedVehicleWorker,
  takeLatest
);

export const disableOnboardedVehicleWatcher = createWatcher(
  actions.DISBALE_ONBOARDED_VEHICLE_,
  cashBookingWorker.disableOnboardedVehicleWorker,
  takeLatest
);

export const getSingleOnboarededVehicleWatcher = createWatcher(
  actions.GET_SINGLE_ONBOARDED_VEHICLE_,
  cashBookingWorker.getSingleOnboarededVehicleWorker,
  takeLatest
);

export const getAllOnboarededVehiclesWatcher = createWatcher(
  actions.GET_ALL_ONBOARDED_VEHICLES_TABLE,
  cashBookingWorker.getAllOnboarededVehiclesWorker,
  takeLatest
);

export const accountPostShipmentWatcher = createWatcher(
  actions.ACCOUNT_CREATE_SHIPMENT,
  cashBookingWorker.accountPostShipmentWorker,
  takeLatest
);

export const accountgetUpdateShipmentWatcher = createWatcher(
  actions.ACCOUNT_UPDATE_SHIPMENT,
  cashBookingWorker.accountUpdateShipmentWorker,
  takeLatest
);
