/* eslint-disable no-empty */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  Empty,
  Spin,
  message,
} from "antd";
import back_arrow from "../../../assests/images/master/back-arrow.svg";
import dec_item from "../../../assests/images/dec-item.svg";
import inc_item from "../../../assests/images/inc-item.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import API from "../../../api";
import urls from "../../../api/urls";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const CartPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [cartDetails, setCartDetails] = useState(null);
  const [modeOfPayment, setModeOfPayments] = useState([]);
  const [isSpining, setSpinning] = useState(false);
  const [mode, setMode] = useState(null);
  const user_premiseId = JSON.parse(window?.localStorage?.getItem("userId"));
  const {
    GET_CART_DETAILS,
    UPDATE_DELIVERY_DETAILS,
    REMOVE_ITEM,
    UPDATE_PAYMENT_DETAILS,
    PLACE_ORDER,
    UPDATE_ITEM_DATA,
    MODE_PAYMENT_DROPDOWN,
  } = urls;
  useEffect(() => {
    getProductList();
    getModeOfPayments();
  }, []);

  const getProductList = async () => {
    setSpinning(true);
    let response = await API.Api(GET_CART_DETAILS).getWithUserNPremiseHeader();
    if (response.status) {
      updateDeliveryDetails();
      setCartDetails(response?.response?.data);
      form.setFieldValue(
        "amountPayable",
        response?.response?.data?.price?.orderTotal
      );
      let res = JSON.stringify(response?.response?.data?.items.length);
      localStorage.setItem("cartDetails", res);
    } else {
      setSpinning(false);
      setCartDetails(null);
      localStorage.setItem("cartDetails", "0");
    }
  };
  const getModeOfPayments = async () => {
    let response = await API.Api(
      MODE_PAYMENT_DROPDOWN
    ).getWithUserNPremiseHeader();
    if (response.status) {
      let temp = [];
      response?.response?.data.map((item) => {
        temp.push({
          label: item.name,
          value: item.name,
        });
      });
      setModeOfPayments(temp);
    } else {
      setModeOfPayments([]);
    }
  };

  const updateDeliveryDetails = async () => {
    let response = await API.Api(UPDATE_DELIVERY_DETAILS).putCustomHeader(
      {
        deliveryPremise: user_premiseId?.userInfo?.premiseId,
        receiver:
          user_premiseId?.userInfo?.firstName +
          " " +
          user_premiseId?.userInfo?.lastName,
        receiverPhone: user_premiseId?.userInfo?.mobileNum,
      },
      {
        "USER-ID": user_premiseId?.userInfo?.userId,
      }
    );
    if (response.status) {
      setSpinning(false);
    } else {
      setSpinning(false);
    }
  };
  const deleteItem = async (id) => {
    setSpinning(true);
    let response = await API.Api(REMOVE_ITEM + id).deleteIdHeader();
    if (response.status) {
      let res = JSON.stringify(response?.response?.data?.items.length);
      localStorage.setItem("cartDetails", res);
      message.success("Item removed successfully");
      setSpinning(false);
      getProductList();
    } else {
      setSpinning(false);
    }
  };

  const placeOrder = async (values) => {
    setSpinning(true);
    let response = await API.Api(UPDATE_PAYMENT_DETAILS).putCustomHeader(
      {
        mode: mode,
        referenceNumber: values.referenceID || "",
        comments: "",
        chequeNo: values.chequeNo || "",
        bankName: values.bankName || "",
        branchName: values.branchName || "",
      },
      {
        "USER-ID": user_premiseId?.userInfo?.userId,
      }
    );
    if (response.status) {
      placeOrderData();
      setSpinning(false);
    } else {
      setSpinning(false);
    }
  };
  const placeOrderData = async () => {
    setSpinning(true);
    let response = await API.Api(PLACE_ORDER).postIdHeader();
    if (response.status) {
      getProductList();
      message.success("Order placed successfully");
      setSpinning(false);
      form.resetFields();
      navigate("/order-history");
    } else {
      setSpinning(false);
    }
  };

  const increment = async (id, qty, min, inc) => {
    setSpinning(true);
    let response = await API.Api(UPDATE_ITEM_DATA).putIdHeader({
      itemId: id,
      quantity: qty + inc,
    });
    if (response.status) {
      getProductList();
      setSpinning(false);
    } else {
      setSpinning(false);
    }
  };
  const decrement = async (id, qty, min, dec) => {
    if (qty === min) {
    } else {
      setSpinning(true);
      let response = await API.Api(UPDATE_ITEM_DATA).putIdHeader({
        itemId: id,
        quantity: qty - dec,
      });
      if (response.status) {
        getProductList();
        setSpinning(false);
      } else {
        setSpinning(false);
      }
    }
  };
  const handleMode = (value) => {
    setMode(value);
  };

  return (
    <div className="p-2">
      <Spin
        spinning={isSpining}
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }
      >
        <Row gutter={40}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="master-search-container">
              <span className="d-flex">
                <img
                  src={back_arrow}
                  className="pointer"
                  onClick={() => window.history.back()}
                />
                &nbsp;{"Cart"}
              </span>
            </div>
            <div
              className="master-main-container p-2"
              style={{
                borderRadius: "0px 0px 5px 5px",
                height: "75vh",
                overflow: "scroll",
              }}
            >
              {cartDetails?.items.length === 0 || cartDetails === null ? (
                <Empty />
              ) : (
                ""
              )}
              {cartDetails?.items.map((item, index) => (
                <Row
                  gutter={20}
                  style={{
                    borderBottom: "1px solid #DDDDDD",
                    paddingBottom: "1.5rem",
                    marginBottom: "1.5rem",
                  }}
                  key={item + "_" + index}
                >
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    <div
                      style={{
                        textAlign: "center",
                        border: "1px solid #E0E1E2",
                        borderRadius: "5px",
                        marginBottom: "1rem",
                        padding: "1rem 0rem",
                        cursor: "pointer",
                      }}
                      className="hover-zoom"
                    >
                      <img
                        src={item?.imagePath || ""}
                        style={{
                          height: 200,
                          width: 130,
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div
                      className="d-flex align-center"
                      style={{ justifyContent: "center" }}
                    >
                      <img
                        src={dec_item}
                        height={35}
                        onClick={() =>
                          decrement(
                            item?.itemId,
                            item?.quantity,
                            item?.minOrderQty,
                            item?.incrementBy
                          )
                        }
                        className="pointer"
                      />
                      &nbsp;&nbsp;
                      <div
                        style={{
                          border: "1px solid #C2C2C2",
                          padding: "5px 20px",
                          borderRadius: "5px",
                        }}
                      >
                        <span>{item?.quantity}</span>
                      </div>
                      &nbsp;&nbsp;
                      <img
                        src={inc_item}
                        height={35}
                        onClick={() =>
                          increment(
                            item?.itemId,
                            item?.quantity,
                            item?.minOrderQty,
                            item?.incrementBy
                          )
                        }
                        className="pointer"
                      />
                    </div>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        height: "50px",
                      }}
                    >
                      {item?.imageName || "N/A"}
                    </div>
                    <div
                      style={{ fontSize: "16px", fontWeight: "500" }}
                      className="mt-1"
                    >
                      ₹ {item?.unitPrice}
                    </div>
                    <Button
                      className="btn-style"
                      style={{ color: "#686868", marginTop: "10rem" }}
                      onClick={() => deleteItem(item?.itemId)}
                    >
                      REMOVE
                    </Button>
                  </Col>
                </Row>
              ))}
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div
              className="master-search-container"
              style={{ padding: "1.2rem" }}
            >
              <span
                className="d-flex"
                style={{ color: "#646769", fontSize: "14px" }}
              >
                {"Payment Details"}
              </span>
            </div>
            <div
              className="master-main-container p-2"
              style={{
                borderRadius: "0px 0px 5px 5px",
                height: "75vh",
                overflow: "scroll",
              }}
            >
              <Form
                layout="vertical"
                form={form}
                scrollToFirstError={{
                  behavior: "smooth",
                  block: "center",
                  inline: "center",
                }}
                className="global-form"
                onFinish={placeOrder}
              >
                <div className="d-flex space-between">
                  <span style={{ fontSize: "15px", fontWeight: "600" }}>
                    Total Amount
                  </span>
                  <span style={{ fontSize: "18px", fontWeight: "600" }}>
                    ₹ {cartDetails?.price?.orderTotal}
                  </span>
                </div>
                <Row gutter={20} className="mt-1 global-form-row">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="selectBank" label="Select bank">
                      <Input
                        placeholder="Enter amount"
                        defaultValue={"HDFC Bank"}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="accountNo" label="Bank account number">
                      <Input
                        placeholder="Enter amount"
                        defaultValue={"50200068829453"}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="ifsc" label="IFSC code">
                      <Input
                        placeholder="Enter amount"
                        defaultValue={"HDFC0000048"}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="micr" label="MICR code">
                      <Input
                        placeholder="Enter amount"
                        defaultValue={"380240003"}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="modeOfPayment"
                      label="Mode of payment"
                      rules={[
                        {
                          required: true,
                          message: "Select Mode",
                        },
                      ]}
                    >
                      <Select
                        onSelect={handleMode}
                        // onSelect={}
                        placeholder={"Select Mode"}
                        options={modeOfPayment}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="amountPayable"
                      label="Amount payable"
                      // initialValue={cartDetails?.price?.orderTotal}
                      rules={[
                        {
                          required: true,
                          message: "Enter amount",
                        },
                        // {
                        //   pattern: new RegExp("^[0-9]+.[0-9][0-9]$"),
                        //   message: "Please enter a valid amount",
                        // },
                      ]}
                    >
                      <Input placeholder="Enter amount" type="number" />
                    </Form.Item>
                  </Col>
                  {mode === "CHEQUE" ? (
                    ""
                  ) : (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="referenceID"
                        label="Payment reference ID"
                        rules={[
                          {
                            required: true,
                            message: "Enter reference ID",
                          },
                        ]}
                      >
                        <Input placeholder="Enter reference ID" />
                      </Form.Item>
                    </Col>
                  )}
                  {mode === "CHEQUE" ? (
                    <>
                      {" "}
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="chequeNo"
                          label="Cheque no."
                          rules={[
                            {
                              required: true,
                              message: "Enter checque no.",
                            },
                          ]}
                        >
                          <Input placeholder="Enter cheque no." />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="bankName"
                          label="Bank name"
                          rules={[
                            {
                              required: true,
                              message: "Enter bank name",
                            },
                          ]}
                        >
                          <Input placeholder="Enter bank name" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="branchName"
                          label="Branch name"
                          rules={[
                            {
                              required: true,
                              message: "Enter branch name",
                            },
                          ]}
                        >
                          <Input placeholder="Enter branch name" />
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
                <hr className="divider" />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {cartDetails?.items.length === 0 || cartDetails === null ? (
                    <Button className="btn btn-style" disabled>
                      PLACE ORDER
                    </Button>
                  ) : (
                    <Button className="btn save-btn" htmlType="submit">
                      PLACE ORDER
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
export default CartPage;
