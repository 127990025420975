import React from "react";
import { Row, Col, Button, Input, DatePicker, Select } from "antd";
import TotalCount from "./countCompo";
import {
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
} from "../../../utils";
import moment from "moment";
import search_icon from "../../../assests/images/search-icon.svg";

function ExpectionSearchAndDateFilter({
  serachValue,
  handleInputChange,
  handleDateChange,
  dateRangeStatus,
  handleOnclick,
  filterType,
  handlefilterChange,
  total,
  onClicked,
  options,
  bag,
  download = false,
}) {
  const { RangePicker } = DatePicker;

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <Row gutter={12} justify={"space-between"}>
      <Col className="center" span={6}>
        <Input
          placeholder={
            bag ? "Search by Bag ID" : "Search by shipment AWB number"
          }
          bordered
          style={{
            borderRadius: "5px",
            height: "40px",
            width: "100%",
          }}
          onKeyPress={(event) => {
            if (
              checkNumbervalue(event) ||
              checkSpace(event) ||
              checkSpecialCharacter(event)
            ) {
              event.preventDefault();
            }
          }}
          maxLength={bag ? 11 : 14}
          value={serachValue}
          suffix={<img src={search_icon} />}
          onChange={handleInputChange}
        />
      </Col>
      <div className="center">
        <div className="center">
          <div className="flex-end">
            <RangePicker
              onChange={handleDateChange}
              format={"DD/MM/YYYY"}
              disabledDate={(current) => disabledDate(current)}
              defaultValue={[moment().subtract(1, "days"), moment()]}
              style={{
                borderRadius: "5px 0 0 5px",
                height: "40px",
                width: "230px",
              }}
            />
            <Button
              className="center mr-1"
              disabled={!dateRangeStatus}
              style={{
                width: "40px",
                height: "40px",
                border: "1px solid #d9d9d9",
                borderRadius: "0 5px 5px 0",
              }}
              onClick={handleOnclick}
            >
              <img src={search_icon} />
            </Button>
          </div>
        </div>

        <div className="center">
          <Select
            className="filter-select mr-1"
            style={{
              width: 140,
            }}
            value={filterType}
            onChange={handlefilterChange}
            options={options}
          />
        </div>
        <div className="center">
          <TotalCount count={total} />
        </div>
        <div className="center">
          {download ? (
            <Button
              className="cancel-btn"
              disabled={total == 0}
              onClick={onClicked}
            >
              DOWNLOAD
            </Button>
          ) : (
            <Button className="save-btn" onClick={onClicked}>
              ADD NEW EXCEPTIONS
            </Button>
          )}
        </div>
      </div>
    </Row>
  );
}

export default ExpectionSearchAndDateFilter;
