/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useEffect, useState, useRef } from "react";
import { Input, Table, message, Row, DatePicker, Button } from "antd";

import {
  serialNum,
  capitalize,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
} from "../../../utils";
import urls from "../../../api/urls";
import API from "../../../api";

import search_icon from "../../../assests/images/search-icon.svg";
import checkIcon from "../../../assests/images/SVG/check.svg";
import OrangeCheckIcon from "../../../assests/images/SVG/orange-check.svg";

import blue_bag_plus from "../../../assests/images/middleMile/blue-bag.svg";
import green_bag from "../../../assests/images/middleMile/green-bag.svg";
import white_bag from "../../../assests/images/middleMile/white-bag.svg";
import yellow_bag from "../../../assests/images/middleMile/yellow-bag.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import "./style.scss";
import Heading from "../../../components/Heading";
import BagContentModal from "./bagContentModal";
import ScannedShipment from "../../../components/ScannedShipment";
import moment from "moment";
import { damageBagModal } from "../Bagging/damageBagModal";

const { GET_LCR_IN_SCAN_LIST, UPDATE_ALL_BAGs } = urls;
const { RangePicker } = DatePicker;

const InScanLCRList = () => {
  const inputRef = useRef(null);

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataSource, setdataSource] = useState(tableData);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [totalScanned, setTotalScanned] = useState(0);
  const [totalShipments, setTotalShipments] = useState(0);

  const [pageSize, setPageSize] = useState(20);
  const [pageNum, setPageNum] = useState(0);
  const [showBagModal, setShowBagModal] = useState(false);
  const [currentBarCode, setCurrentBarCode] = useState(null);
  const [showDamageBagModal, setShowDamageBagModal] = useState(false);

  const HUB_INSCANNED_LABEL = "HUB-IN-SCANNED";
  const white = "#fff";
  const blur = "#2E7FE1";
  const green = "#70C36E";
  const yello = "#D2DD3F";

  const colorDictionary = {
    "#fff": white_bag,
    "#2E7FE1": blue_bag_plus,
    "#70C36E": green_bag,
    "#D2DD3F": yellow_bag,
  };

  useEffect(() => {
    getLcrInscanList();
  }, []);

  const closeModal = () => {
    setShowBagModal(false);
  };

  const getLcrInscanList = async (start, end) => {
    setLoading(true);

    const StartDate = moment(start, "DD/MM/YYYY").format("DD-MM-YYYY");
    const endDate = moment(end, "DD/MM/YYYY").format("DD-MM-YYYY");
    let response;

    if (start) {
      response = await API.Api(
        GET_LCR_IN_SCAN_LIST + "?fromDate=" + StartDate + "&toDate=" + endDate
      ).getWithPremiseIdHeader();
    } else {
      response = await API.Api(GET_LCR_IN_SCAN_LIST).getWithPremiseIdHeader();
    }

    if (response?.status) {
      setLoading(false);
      let data = response?.response?.data?.data;
      setTableData(data);
      setdataSource(data);
      calculateTotals(data);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  function handleDateChange(params, p2) {
    getLcrInscanList(p2[0], p2[1]);
  }

  const filterData = () => {
    const unique = [
      ...new Map(tableData?.map((item) => [item["lcrNum"], item])).values(),
    ];

    const data = unique?.map((val) => {
      return { text: val?.lcrNum, value: val?.lcrNum };
    });
    return data;
  };

  function handleIdClick(val, barcode) {
    if (
      val?.barcodeStatus !== "HUB-OUTSCAN" &&
      val?.destroyPremiseId === null &&
      val?.isBag
    ) {
      setCurrentBarCode(barcode);
      setShowBagModal(true);
    }
  }

  const checkStatus = (val) => {
    return (
      val?.barcodeStatus !== "HUB-OUTSCAN" &&
      val?.destroyPremiseId === null &&
      val?.isBag
    );
  };

  const columns = [
    {
      title: "Sl No",
      align: "center",
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "Bag ID/ Individul shipment",
      dataIndex: "barcode",
      key: "barcode",
      render: (text, val) => (
        <div
          // onClick={() => navigate("/lcr-packets-scan/" + text)}
          className="align-center"
        >
          <span
            className={checkStatus(val) ? "mr-1 pointer" : "mr-1"}
            style={{
              color: checkStatus(val) ? "#4394d3" : "black",
            }}
            onClick={() => handleIdClick(val, text)}
          >
            {text}
          </span>
          {val?.isBag ? (
            <img src={colorDictionary[val?.color]} style={{ width: "10px" }} />
          ) : null}
          {val?.barcodeStatus !== "HUB-OUTSCAN" ? (
            <img
              src={
                val?.shipmentNum == val?.scanCount ? checkIcon : OrangeCheckIcon
              }
              className="ml-1"
            />
          ) : null}
        </div>
      ),
    },

    {
      title: "LCR ID",
      dataIndex: "lcrNum",
      key: "lcrNum",
      filters: filterData(),
      onFilter: (value, record) => record?.lcrNum === value,
      render: (text, record) => {
        return (
          <>
            <div
              // onClick={() => navigate("/lcr-packets-scan/" + text)}
              className="align-center"
            >
              {text}
            </div>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "fromDate",
      key: "fromDate",

      render: (text) => (
        <div style={{ width: "100px" }}>
          {text?.split(" ")[0]?.split("-")?.reverse()?.join("-")}
        </div>
      ),
    },
    {
      title: "Mode",
      dataIndex: "travelBy",
      key: "travelBy",
      render: (text) => <div>{capitalize(text)}</div>,
    },
    {
      title: "No of shipment(s)",
      dataIndex: "shipmentNum",
      key: "shipmentNum",
      render: (text) => <div>{text === 0 ? "------" : text}</div>,
    },

    {
      title: "Scanned shipment(s)",
      dataIndex: "scanCount",
      key: "scanCount",
      render: (text) => <div>{text !== null ? text : "---"}</div>,
    },
    {
      title: "From Hub",
      dataIndex: "fromHub",
      key: "fromHub",
      render: (text) => <div>{capitalize(text)}</div>,
    },

    {
      title: "To Hub",
      dataIndex: "toHub",
      key: "toHub",
      render: (text) => <div>{capitalize(text)}</div>,
    },
    {
      title: "Vehicle/Flight No",
      dataIndex: "vehicleNum",
      key: "vehicleNum",
    },
  ];

  const filterArray = (data, currValue) => {
    return data?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );
  };

  const onSearch = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(dataSource, currValue)
        : filterArray(dataSource, currValue);
    setTableData(filteredData);
  };

  //--Barcode Scanner--//
  let barcode = "";
  let interval;
  const handleKeyDown = (event) => {
    if (interval) clearInterval(interval);
    if (event.code == "Enter") {
      if (barcode) handleScan(barcode);
      barcode = "";
      return;
    }
    if (event.key != "Shift") barcode += event.key;
    interval = setInterval(() => (barcode = ""), 20);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [tableData]);

  const handleScan = async (id) => {
    setLoading(true);
    const resultNumber = id?.replace(/[a-zA-Z]/g, "");

    let res = await API.Api(
      UPDATE_ALL_BAGs + `${+resultNumber}/status/` + HUB_INSCANNED_LABEL
    ).putIdHeader();

    let response = res?.response?.data;

    if (res?.status) {
      setLoading(false);
      if (response) {
        message.success(response?.message);
        let scanData = [...tableData];
        const barcodeToMatch = response?.isShipment && response?.bagId !== null;
        if (barcodeToMatch) {
          setTotalScanned(totalScanned + 1);
          const updateIndex = scanData?.findIndex(
            (item) => item?.barcode == response?.bagId
          );
          scanData[updateIndex].barcodeStatus = "HUB-IN-SCANNED";
          scanData[updateIndex].scanCount =
            (scanData[updateIndex]?.scanCount || 0) + 1;
        } else if (response?.isBag && !response?.isShipment) {
          const updateIndex = scanData?.findIndex(
            (item) => item?.barcode == response?.bagId
          );
          scanData[updateIndex].barcodeStatus = "HUB-IN-SCANNED";
        } else {
          if (response?.isShipment && response?.bagId === null) {
            setTotalScanned(totalScanned + 1);

            const updateIndex = scanData?.findIndex(
              (item) => item?.barcode == response?.shipmentId
            );
            scanData[updateIndex].barcodeStatus = "HUB-IN-SCANNED";
            scanData[updateIndex].scanCount =
              (scanData[updateIndex]?.scanCount || 0) + 1;
          }
        }
        setTableData(scanData);
      }
    } else {
      message.error(response?.message || "Network error");
      setLoading(false);
    }
  };

  function calculateTotals(filteredData) {
    let temp = 0;
    let totalShips = 0;

    filteredData.forEach((item) => {
      if (item?.scanCount !== null) {
        temp += item?.scanCount;
      }
      if (item?.shipmentNum !== null && !isNaN(item?.shipmentNum)) {
        totalShips += item?.shipmentNum;
      }
    });
    setTotalShipments(totalShips);
    setTotalScanned(temp);
  }

  function handlePageChange(pagination, filters, sorter, extra) {
    if (extra.action === "filter") {
      const filteredData = extra.currentDataSource;
      calculateTotals(filteredData);
    }
  }

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  return (
    <div className="p-2">
      <div>
        <Row justify={"space-between"}>
          <Heading title="LCR List" />
          <h1 className="flex-end">
            <Button
              className="save-btn mr-1"
              style={{ height: "42px" }}
              onClick={() => {
                setShowDamageBagModal(true);
              }}
            >
              DAMAGE BAG
            </Button>
            <ScannedShipment
              scannedData={totalScanned}
              totalData={totalShipments}
            />
          </h1>
        </Row>
      </div>

      <div className="consolidated-manifest-cntr">
        <div className="header" style={{ flexWrap: "wrap" }}>
          <div className="left">
            <Input
              placeholder="Enter Bag Id/AWB number to scan"
              bordered
              ref={inputRef}
              onPressEnter={(e) => {
                if (e.target.value.length > 6) {
                  handleScan(e.target.value);
                } else {
                  message.error("Enter correct Bag Id/AWB number");
                }
              }}
              onKeyPress={(event) => {
                if (
                  checkNumbervalue(event) ||
                  checkSpace(event) ||
                  checkSpecialCharacter(event)
                ) {
                  event.preventDefault();
                }
              }}
              style={{
                borderRadius: "5px",
                height: "40px",
                margin: "15px 50px 15px 12px",
              }}
              // suffix={<img src={search_icon} />}
            />
          </div>
          <div className="align-center ">
            <div>
              <RangePicker onChange={handleDateChange} format={"DD/MM/YYYY"} />
            </div>
            <div className="right">
              <Input
                placeholder="Enter keyword to search"
                bordered
                onChange={onSearch}
                // style={{ borderRadius: "5px", height: "40px", width: "400px" }}
                style={{
                  borderRadius: "5px",
                  height: "40px",
                  // width: "100%",
                  margin: "15px 12px 15px 12px",
                }}
                suffix={<img src={search_icon} />}
              />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          className="lcr-inscan-container table-head-sticky"
          dataSource={[
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...tableData?.sort(function (a, b) {
              return a?.barcodeStatus !== "HUB-OUTSCAN"
                ? 1
                : b?.barcodeStatus !== "HUB-OUTSCAN"
                ? -1
                : 0;
            }),
          ]}
          pagination={false}
          onChange={handlePageChange}
          loading={{
            indicator: (
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            ),
            spinning: loading,
          }}
        />
      </div>
      {damageBagModal(showDamageBagModal, setShowDamageBagModal)}
      {showBagModal && (
        <BagContentModal
          currentBarcode={currentBarCode}
          showModal={showBagModal}
          modalClose={closeModal}
        />
      )}
    </div>
  );
};

export default InScanLCRList;
