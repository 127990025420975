/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Space, Input, Tooltip, Spin, Popconfirm, message } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  commonSorterForTable,
  serialNum,
  camelCase,
  tableLoading,
} from "../../../utils";
import TableContainer from "../../../components/TableContainer";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import {
  disableOnboardedVehicle,
  disableOnboardedVehicleClear,
  getAllOnboardedVehicles,
} from "../../../actionsMethods/cashBookingMethods";

import Smcs_Loader from "../../../assests/images/loader-new.gif";

import { ReactComponent as Edit_icon } from "../../../assests/images/edit-logo.svg";
import { ReactComponent as Disable_icon } from "../../../assests/images/SVG/disable.svg";
import { ReactComponent as Eye_Icon } from "../../../assests/images/eyeIcon.svg";
import search_icon from "../../../assests/images/search-icon.svg";
import "./style.scss";

const ViewAllVehiclesTable = ({ handleEdit = () => {}, activeKey }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [tableData, setTableData] = useState([]);
  const [dataSource, setdataSource] = useState(tableData);
  const [pageSize, setPageSize] = useState(20);
  const [pageNum, setPageNum] = useState(1);
  const [sortedInfo, setSortedInfo] = useState({});

  useEffect(() => {
    if (activeKey == "2" || activeKey == undefined) {
      if (Tableresult === null) {
        dispatch(getAllOnboardedVehicles());
      }
    }
  }, [activeKey]);

  const getAllOnboardedVehiclesResponse = useSelector(
    (state) => state.appReducer.getAllOnboarededVehiclesTableReducer,
    shallowEqual
  );

  const getDisableVehicleResponse = useSelector(
    (state) => state.appReducer.disableOnboaredVehcileReducer,
    shallowEqual
  );

  const {
    fetching,
    result: Tableresult,
    error: tableError,
  } = getAllOnboardedVehiclesResponse;

  const {
    fetching: disableFetching,
    result: disableResult,
    error: disableError,
  } = getDisableVehicleResponse;

  useEffect(() => {
    if (Tableresult !== null) {
      setTableData(Tableresult);
    }
    if (tableError !== null) {
      message.error(tableError?.message);
    }
  }, [Tableresult, tableError]);

  useEffect(() => {
    if (disableResult !== null) {
      message.success(disableResult?.message);
      dispatch(getAllOnboardedVehicles());
      dispatch(disableOnboardedVehicleClear());
    }
    if (disableError !== null) {
      message.success(disableError?.message);
      dispatch(disableOnboardedVehicleClear());
    }
  }, [disableResult, disableError]);

  const handleDisable = (values) => {
    let payload = {
      status: !values.status ? true : false,
    };
    dispatch(disableOnboardedVehicle(payload, values?.vehicleNo));
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: "Sl No",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: 50,
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "Vehicle  Number",
      dataIndex: "vehicleNo",
      key: "vehicleNo",
      //align: "center",
      width: 150,
      // sorter: (a, b) => a.vehicleNo.localeCompare(b.vehicleNo),
    },

    {
      title: "Local",
      dataIndex: "isLocal",
      key: "isLocal",
      // align: "center",
      width: 100,
      render: (text) => (text == true ? "Yes" : "No"),
    },
    {
      title: "GPS Installed",
      dataIndex: "gpsInstalled",
      key: "gpsInstalled",
      //align: "center",
      width: 100,
      render: (text) => (text == true ? "Yes" : "No"),
    },
    {
      title: "Vehicle Class",
      dataIndex: "vehicleClass",
      key: "vehicleClass",
      //align: "center",
      width: 100,
    },
    {
      title: "Vehicle Feet",
      dataIndex: "vehicleFeet",
      key: "vehicleFeet",
      //align: "center",
      sortOrder:
        sortedInfo.columnKey === "vehicleFeet" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "vehicleFeet"),
      ellipsis: true,
      width: 100,

      render: (text) => {
        return text;
      },
    },
    {
      title: "Load Capacity(ton)",
      dataIndex: "loadCapacityInTons",
      key: "loadCapacityInTons",
      //align: "center",
      sortOrder:
        sortedInfo.columnKey === "loadCapacityInTons" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "loadCapacityInTons"),
      ellipsis: true,
      width: 100,

      render: (text) => {
        return text;
      },
    },
    {
      title: "Permit Type",
      dataIndex: "permitType",
      key: "permitType",
      width: 100,
    },
    {
      title: "Current Vehicle Location",
      dataIndex: "availableInPremiseName",
      ellipsis: true,

      key: "availableInPremiseName",
      width: 150,
      render: (text) => <span>{camelCase(text)}</span>,
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      className: "actions-col",
      width: 150,

      render: (_, record) => {
        // console.log(record, "record");
        return activeKey === undefined ? (
          <Space size="middle">
            <Tooltip title={"View Utilization"} placement="bottom">
              <p
                className="pointer"
                onClick={() =>
                  navigate("/vehicle-trip-list/" + record.vehicleNo)
                }
              >
                <Eye_Icon />
              </p>
            </Tooltip>
          </Space>
        ) : record?.status == "ACTIVE" ? (
          <Space size="middle">
            <Tooltip title={"Edit"} placement="bottom">
              <p
                className="pointer"
                onClick={() => handleEdit(record.vehicleNo, "1")}
              >
                <Edit_icon />
              </p>
            </Tooltip>
            <Tooltip title="Disable" placement="bottom">
              <Popconfirm
                title={
                  <span
                    className="test"
                    style={{
                      textTransform: "initial",
                    }}
                  >
                    Do you want to disable this Vehicle ?
                  </span>
                }
                cancelText="No"
                okText="Yes"
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
                onConfirm={() => handleDisable(record)}
              >
                <p className="pointer">
                  <Disable_icon />
                </p>
              </Popconfirm>
            </Tooltip>
          </Space>
        ) : (
          <></>
        );
      },
    },
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const onSearch = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(tableData, currValue)
        : filterArray(tableData, currValue);
    setdataSource(filteredData);
    setPageNum(0);
  };

  const filterArray = (dataSource, currValue) =>
    dataSource?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );

  function handlePageChange(current, size) {
    setPageSize(size);
    setPageNum(current);
  }

  return (
    <div className="master-main-container">
      <div className="align-center space-between">
        <div className="pl-1-5 fw-600"></div>
        <div className="text-right p-1">
          <Input
            placeholder="Search by keyword"
            bordered
            onChange={onSearch}
            style={{ borderRadius: "5px", height: "40px", width: "200px" }}
            suffix={<img src={search_icon} />}
          />
        </div>
      </div>
      <TableContainer
        columns={columns}
        loading={{
          indicator: (
            <img
              src={Smcs_Loader}
              style={{ height: "100px", width: "100px" }}
            />
          ),
          spinning: fetching || disableFetching,
        }}
        defaultCurrent={1}
        scroll={{ x: 1300 }}
        onChange={handleChange}
        dataSource={inputSearchValue.length == 0 ? tableData : dataSource}
        pagination={{
          pageSize: pageSize,
          onChange: handlePageChange,
          // total: inputSearchValue.length == 0 ? tableData.length : 10,
          itemRender: itemRender,
        }}
        rowClassName={(record) => record.status == "INACTIVE" && "disabled-row"}
      />
    </div>
  );
};

export default ViewAllVehiclesTable;
