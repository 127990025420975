import { combineReducers } from "redux";

import actions from "../constants/masterActions";
import {
  getFetchingState,
  getSuccessState,
  getErrorState,
  getResetState,
  createReducer,
} from "../utils/reducers";

import { defaultState } from "./config";

const pincodeMasterTableReducer = createReducer(defaultState, {
  [actions.GET_PINCODE_MASTER_TABLE]: getFetchingState,
  [actions.GET_PINCODE_MASTER_TABLE_SUCCESS]: getSuccessState,
  [actions.GET_PINCODE_MASTER_TABLE_ERROR]: getErrorState,
  [actions.GET_PINCODE_MASTER_TABLE_CLEAR]: getResetState,
});

const getPincodeReducer = createReducer(defaultState, {
  [actions.GET_PINCODE]: getFetchingState,
  [actions.GET_PINCODE_SUCCESS]: getSuccessState,
  [actions.GET_PINCODE_ERROR]: getErrorState,
  [actions.GET_PINCODE_CLEAR]: getResetState,
});

const getStateReducer = createReducer(defaultState, {
  [actions.GET_STATE]: getFetchingState,
  [actions.GET_STATE_SUCCESS]: getSuccessState,
  [actions.GET_STATE_ERROR]: getErrorState,
  [actions.GET_STATE_CLEAR]: getResetState,
});

const getServiceabilityReducer = createReducer(defaultState, {
  [actions.GET_SERVICEABILITY]: getFetchingState,
  [actions.GET_SERVICEABILITY_SUCCESS]: getSuccessState,
  [actions.GET_SERVICEABILITY_ERROR]: getErrorState,
  [actions.GET_SERVICEABILITY_CLEAR]: getResetState,
});

const updatePincodeReducer = createReducer(defaultState, {
  [actions.UPDATE_PINCODE]: getFetchingState,
  [actions.UPDATE_PINCODE_SUCCESS]: getSuccessState,
  [actions.UPDATE_PINCODE_ERROR]: getErrorState,
  [actions.UPDATE_PINCODE_CLEAR]: getResetState,
});

const createPincodeReducer = createReducer(defaultState, {
  [actions.CREATE_PINCODE]: getFetchingState,
  [actions.CREATE_PINCODE_SUCCESS]: getSuccessState,
  [actions.CREATE_PINCODE_ERROR]: getErrorState,
  [actions.CREATE_PINCODE_CLEAR]: getResetState,
});

//--Rate Card--//
const getDefaultRateCardReducer = createReducer(defaultState, {
  [actions.GET_DEFAULT_RATE_CARD]: getFetchingState,
  [actions.GET_DEFAULT_RATE_CARD_SUCCESS]: getSuccessState,
  [actions.GET_DEFAULT_RATE_CARD_ERROR]: getErrorState,
  [actions.GET_DEFAULT_RATE_CARD_CLEAR]: getResetState,
});

const getCategoryBasedValueReducer = createReducer(defaultState, {
  [actions.GET_CATEGORY_BASED_VALUE]: getFetchingState,
  [actions.GET_CATEGORY_BASED_VALUE_SUCCESS]: getSuccessState,
  [actions.GET_CATEGORY_BASED_VALUE_ERROR]: getErrorState,
  [actions.GET_CATEGORY_BASED_VALUE_CLEAR]: getResetState,
});

const createCashRateCardReducer = createReducer(defaultState, {
  [actions.CREATE_CASH_RATE_CARD_TEMPLATE]: getFetchingState,
  [actions.CREATE_CASH_RATE_CARD_TEMPLATE_SUCCESS]: getSuccessState,
  [actions.CREATE_CASH_RATE_CARD_TEMPLATE_ERROR]: getErrorState,
  [actions.CREATE_CASH_RATE_CARD_TEMPLATE_CLEAR]: getResetState,
});

const getCashRateCardTemplatesReducer = createReducer(defaultState, {
  [actions.GET_CASH_RATE_CARD_TEMPLATES]: getFetchingState,
  [actions.GET_CASH_RATE_CARD_TEMPLATES_SUCCESS]: getSuccessState,
  [actions.GET_CASH_RATE_CARD_TEMPLATES_ERROR]: getErrorState,
  [actions.GET_CASH_RATE_CARD_TEMPLATES_CLEAR]: getResetState,
});

const getCashRateCardDetailsReducer = createReducer(defaultState, {
  [actions.GET_CASH_RATE_CARD_DETAILS]: getFetchingState,
  [actions.GET_CASH_RATE_CARD_DETAILS_SUCCESS]: getSuccessState,
  [actions.GET_CASH_RATE_CARD_DETAILS_ERROR]: getErrorState,
  [actions.GET_CASH_RATE_CARD_DETAILS_CLEAR]: getResetState,
});

const allocateCashRateCardReducer = createReducer(defaultState, {
  [actions.ALLOCATE_CASH_RATE_CARD]: getFetchingState,
  [actions.ALLOCATE_CASH_RATE_CARD_SUCCESS]: getSuccessState,
  [actions.ALLOCATE_CASH_RATE_CARD_ERROR]: getErrorState,
  [actions.ALLOCATE_CASH_RATE_CARD_CLEAR]: getResetState,
});

const getExistingCashRateCardTemplatesReducer = createReducer(defaultState, {
  [actions.GET_EXISTING_CASH_RATE_CARD_TEMPLATES]: getFetchingState,
  [actions.GET_EXISTING_CASH_RATE_CARD_TEMPLATES_SUCCESS]: getSuccessState,
  [actions.GET_EXISTING_CASH_RATE_CARD_TEMPLATES_ERROR]: getErrorState,
  [actions.GET_EXISTING_CASH_RATE_CARD_TEMPLATES_CLEAR]: getResetState,
});

//----//
const getProfileDetailsReducer = createReducer(defaultState, {
  [actions.GET_PROFILE_DETAILS]: getFetchingState,
  [actions.GET_PROFILE_DETAILS_SUCCESS]: getSuccessState,
  [actions.GET_PROFILE_DETAILS_ERROR]: getErrorState,
  [actions.GET_PROFILE_DETAILS_CLEAR]: getResetState,
});

const saveProfileDetailsReducer = createReducer(defaultState, {
  [actions.SAVE_PROFILE_DETAILS]: getFetchingState,
  [actions.SAVE_PROFILE_DETAILS_SUCCESS]: getSuccessState,
  [actions.SAVE_PROFILE_DETAILS_ERROR]: getErrorState,
  [actions.SAVE_PROFILE_DETAILS_CLEAR]: getResetState,
});

//packeting--guide//
const getPacketingGuideFromHublistReducer = createReducer(defaultState, {
  [actions.GET_PACKETING_GUIDE_FROM_HUBLIST]: getFetchingState,
  [actions.GET_PACKETING_GUIDE_FROM_HUBLIST_SUCCESS]: getSuccessState,
  [actions.GET_PACKETING_GUIDE_FROM_HUBLIST_ERROR]: getErrorState,
  [actions.GET_PACKETING_GUIDE_FROM_HUBLIST_CLEAR]: getResetState,
});
const getPacketingGuideToHublistReducer = createReducer(defaultState, {
  [actions.GET_PACKETING_GUIDE_TO_HUBLIST]: getFetchingState,
  [actions.GET_PACKETING_GUIDE_TO_HUBLIST_SUCCESS]: getSuccessState,
  [actions.GET_PACKETING_GUIDE_TO_HUBLIST_ERROR]: getErrorState,
  [actions.GET_PACKETING_GUIDE_TO_HUBLIST_CLEAR]: getResetState,
});
const getPacketingGuideFromCplistReducer = createReducer(defaultState, {
  [actions.GET_PACKETING_GUIDE_FROM_CPLIST]: getFetchingState,
  [actions.GET_PACKETING_GUIDE_FROM_CPLIST_SUCCESS]: getSuccessState,
  [actions.GET_PACKETING_GUIDE_FROM_CPLIST_ERROR]: getErrorState,
  [actions.GET_PACKETING_GUIDE_FROM_CPLIST_CLEAR]: getResetState,
});
const getPacketingGuideToCplistReducer = createReducer(defaultState, {
  [actions.GET_PACKETING_GUIDE_TO_CPLIST]: getFetchingState,
  [actions.GET_PACKETING_GUIDE_TO_CPLIST_SUCCESS]: getSuccessState,
  [actions.GET_PACKETING_GUIDE_TO_CPLIST_ERROR]: getErrorState,
  [actions.GET_PACKETING_GUIDE_TO_CPLIST_CLEAR]: getResetState,
});
const getPacketingGuideMasterReducer = createReducer(defaultState, {
  [actions.GET_PACKETING_GUIDE_MASTER]: getFetchingState,
  [actions.GET_PACKETING_GUIDE_MASTER_SUCCESS]: getSuccessState,
  [actions.GET_PACKETING_GUIDE_MASTER_ERROR]: getErrorState,
  [actions.GET_PACKETING_GUIDE_MASTER_CLEAR]: getResetState,
});
const getPacketingGuideSearchReducer = createReducer(defaultState, {
  [actions.GET_PACKETING_GUIDE_SEARCH]: getFetchingState,
  [actions.GET_PACKETING_GUIDE_SEARCH_SUCCESS]: getSuccessState,
  [actions.GET_PACKETING_GUIDE_SEARCH_ERROR]: getErrorState,
  [actions.GET_PACKETING_GUIDE_SEARCH_CLEAR]: getResetState,
});
const getPacketingGuideDownloadReducer = createReducer(defaultState, {
  [actions.GET_PACKETING_GUIDE_DOWNLOAD]: getFetchingState,
  [actions.GET_PACKETING_GUIDE_DOWNLOAD_SUCCESS]: getSuccessState,
  [actions.GET_PACKETING_GUIDE_DOWNLOAD_ERROR]: getErrorState,
  [actions.GET_PACKETING_GUIDE_DOWNLOAD_CLEAR]: getResetState,
});
const getPacketConfigurationReducer = createReducer(defaultState, {
  [actions.GET_PACKET_CONFIGURATION]: getFetchingState,
  [actions.GET_PACKET_CONFIGURATION_SUCCESS]: getSuccessState,
  [actions.GET_PACKET_CONFIGURATION_ERROR]: getErrorState,
  [actions.GET_PACKET_CONFIGURATION_CLEAR]: getResetState,
});

const putPacketConfigurationReducer = createReducer(defaultState, {
  [actions.PUT_PACKET_CONFIGURATION]: getFetchingState,
  [actions.PUT_PACKET_CONFIGURATION_SUCCESS]: getSuccessState,
  [actions.PUT_PACKET_CONFIGURATION_ERROR]: getErrorState,
  [actions.PUT_PACKET_CONFIGURATION_CLEAR]: getResetState,
});
const postPacketingGuideMasterReducer = createReducer(defaultState, {
  [actions.POST_PACKETING_GUIDE_MASTER]: getFetchingState,
  [actions.POST_PACKETING_GUIDE_MASTER_SUCCESS]: getSuccessState,
  [actions.POST_PACKETING_GUIDE_MASTER_ERROR]: getErrorState,
  [actions.POST_PACKETING_GUIDE_MASTER_CLEAR]: getResetState,
});
const putPacketingGuideMasterReducer = createReducer(defaultState, {
  [actions.PUT_PACKETING_GUIDE_MASTER]: getFetchingState,
  [actions.PUT_PACKETING_GUIDE_MASTER_SUCCESS]: getSuccessState,
  [actions.PUT_PACKETING_GUIDE_MASTER_ERROR]: getErrorState,
  [actions.PUT_PACKETING_GUIDE_MASTER_CLEAR]: getResetState,
});
//-----//

// pincode mapping //
const getPincodeMappingReducer = createReducer(defaultState, {
  [actions.GET_PINCODE_MAPPING]: getFetchingState,
  [actions.GET_PINCODE_MAPPING_SUCCESS]: getSuccessState,
  [actions.GET_PINCODE_MAPPING_ERROR]: getErrorState,
  [actions.GET_PINCODE_MAPPING_CLEAR]: getResetState,
});
//----------------//

// hub onboarding//
const getAllHubOnboardReducer = createReducer(defaultState, {
  [actions.GET_ALL_HUB_ONBOARD]: getFetchingState,
  [actions.GET_ALL_HUB_ONBOARD_SUCCESS]: getSuccessState,
  [actions.GET_ALL_HUB_ONBOARD_ERROR]: getErrorState,
  [actions.GET_ALL_HUB_ONBOARD_CLEAR]: getResetState,
});
// --------------//

const enableDisablePincodeMasterReducer = createReducer(defaultState, {
  [actions.ENABLE_DISABLE_PINCODE]: getFetchingState,
  [actions.ENABLE_DISABLE_PINCODE_SUCCESS]: getSuccessState,
  [actions.ENABLE_DISABLE_PINCODE_ERROR]: getErrorState,
  [actions.ENABLE_DISABLE_PINCODE_CLEAR]: getResetState,
});

const deletePincodeAreaReducer = createReducer(defaultState, {
  [actions.DELETE_PINCODE_AREA]: getFetchingState,
  [actions.DELETE_PINCODE_AREA_SUCCESS]: getSuccessState,
  [actions.DELETE_PINCODE_AREA_ERROR]: getErrorState,
  [actions.DELETE_PINCODE_AREA_CLEAR]: getResetState,
});

//--TAT MASTER--//
const getFromHubTatReducer = createReducer(defaultState, {
  [actions.GET_FROM_HUB_TAT]: getFetchingState,
  [actions.GET_FROM_HUB_TAT_SUCCESS]: getSuccessState,
  [actions.GET_FROM_HUB_TAT_ERROR]: getErrorState,
  [actions.GET_FROM_HUB_TAT_CLEAR]: getResetState,
});

const getToHubTatReducer = createReducer(defaultState, {
  [actions.GET_TO_HUB_TAT]: getFetchingState,
  [actions.GET_TO_HUB_TAT_SUCCESS]: getSuccessState,
  [actions.GET_TO_HUB_TAT_ERROR]: getErrorState,
  [actions.GET_TO_HUB_TAT_CLEAR]: getResetState,
});

const getFMLMTatReducer = createReducer(defaultState, {
  [actions.GET_FM_LM_TAT]: getFetchingState,
  [actions.GET_FM_LM_TAT_SUCCESS]: getSuccessState,
  [actions.GET_FM_LM_TAT_ERROR]: getErrorState,
  [actions.GET_FM_LM_TAT_CLEAR]: getResetState,
});
//----//
//--Sorting Guide--//
const getHubListReducer = createReducer(defaultState, {
  [actions.GET_HUB_LIST]: getFetchingState,
  [actions.GET_HUB_LIST_SUCCESS]: getSuccessState,
  [actions.GET_HUB_LIST_ERROR]: getErrorState,
  [actions.GET_HUB_LIST_CLEAR]: getResetState,
});
const createSortingGuideReducer = createReducer(defaultState, {
  [actions.CREATE_SORTING_GUIDE]: getFetchingState,
  [actions.CREATE_SORTING_GUIDE_SUCCESS]: getSuccessState,
  [actions.CREATE_SORTING_GUIDE_ERROR]: getErrorState,
  [actions.CREATE_SORTING_GUIDE_CLEAR]: getResetState,
});
const getHubBasedSortingGuideReducer = createReducer(defaultState, {
  [actions.GET_HUB_BASED_SORTING_GUIDE]: getFetchingState,
  [actions.GET_HUB_BASED_SORTING_GUIDE_SUCCESS]: getSuccessState,
  [actions.GET_HUB_BASED_SORTING_GUIDE_ERROR]: getErrorState,
  [actions.GET_HUB_BASED_SORTING_GUIDE_CLEAR]: getResetState,
});
const getAllSortingGuideReducer = createReducer(defaultState, {
  [actions.GET_ALL_SORTING_GUIDE]: getFetchingState,
  [actions.GET_ALL_SORTING_GUIDE_SUCCESS]: getSuccessState,
  [actions.GET_ALL_SORTING_GUIDE_ERROR]: getErrorState,
  [actions.GET_ALL_SORTING_GUIDE_CLEAR]: getResetState,
});
const viewSortingGuideReducer = createReducer(defaultState, {
  [actions.VIEW_SORTING_GUIDE]: getFetchingState,
  [actions.VIEW_SORTING_GUIDE_SUCCESS]: getSuccessState,
  [actions.VIEW_SORTING_GUIDE_ERROR]: getErrorState,
  [actions.VIEW_SORTING_GUIDE_CLEAR]: getResetState,
});
//--//

const premiseTableReducer = createReducer(defaultState, {
  [actions.PREMISE_TABLE]: getFetchingState,
  [actions.PREMISE_TABLE_SUCCESS]: getSuccessState,
  [actions.PREMISE_TABLE_ERROR]: getErrorState,
  [actions.PREMISE_TABLE_CLEAR]: getResetState,
});

const patchOnboardFormReducer = createReducer(defaultState, {
  [actions.ONBOARD_PATCH]: getFetchingState,
  [actions.ONBOARD_PATCH_SUCCESS]: getSuccessState,
  [actions.ONBOARD_PATCH_ERROR]: getErrorState,
  [actions.ONBOARD_PATCH_CLEAR]: getResetState,
});

export default combineReducers({
  pincodeMasterTableReducer,
  getPincodeReducer,
  getStateReducer,
  updatePincodeReducer,
  createPincodeReducer,
  getServiceabilityReducer,
  getDefaultRateCardReducer,
  getCategoryBasedValueReducer,
  getPacketingGuideFromHublistReducer,
  getPacketingGuideToHublistReducer,
  getPacketingGuideFromCplistReducer,
  getPacketingGuideToCplistReducer,
  getPacketingGuideMasterReducer,
  getPacketConfigurationReducer,
  putPacketConfigurationReducer,
  postPacketingGuideMasterReducer,
  putPacketingGuideMasterReducer,
  getPacketingGuideSearchReducer,
  getPacketingGuideDownloadReducer,
  createCashRateCardReducer,
  enableDisablePincodeMasterReducer,
  deletePincodeAreaReducer,
  getCashRateCardTemplatesReducer,
  getCashRateCardDetailsReducer,
  allocateCashRateCardReducer,
  getExistingCashRateCardTemplatesReducer,
  premiseTableReducer,
  getProfileDetailsReducer,
  saveProfileDetailsReducer,
  patchOnboardFormReducer,
  getFromHubTatReducer,
  getToHubTatReducer,
  getFMLMTatReducer,
  getHubListReducer,
  getPincodeMappingReducer,
  getAllHubOnboardReducer,
  createSortingGuideReducer,
  getHubBasedSortingGuideReducer,
  getAllSortingGuideReducer,
  viewSortingGuideReducer
});
