import React,{useRef,useEffect,useState} from "react";
import { Row,Col,Table,Button,message } from "antd";
import { useReactToPrint } from "react-to-print";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate,useLocation } from "react-router";
import print_icon from "../../../assests/images/master/print.svg";
import { serialNum } from "../../../utils";
import smcs_logo from "../../../assests/images/smcsLogo.svg";
import urls from "../../../api/urls";
import { capitaliseWithHyphen } from "../../../utils";
import {getProfileDetails} from "../../../actionsMethods/masterMethods";
import back_img from "../../../assests/images/rightVector.svg";
import API from "../../../api";

const printDrs = () => {
  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  let userInfo = JSON.parse(localStorage.getItem("userId"));
  let userEmailId = JSON.parse(localStorage.getItem("userinfo"))?.username;
  const { GET_SINGLE_DRS} = urls;
  const componentRef = useRef();
  const history = useLocation();
  const [singleDrs,setSIngleDrs]=useState(0);
  const profileDetails = useSelector(
    (state) => state.masterReducer.getProfileDetailsReducer,
    shallowEqual
  );
  const { result: profileDetailsResult } = profileDetails;
  useEffect(()=>{
    dispatch(getProfileDetails(userEmailId));
  },[]);
  const columns = [
    {
      title: "Sl.No",
      align: "center",
      render: (a, b, idx) => serialNum(10, 1, idx),
    },
    {
      title: "Receiver’s Details",
      dataIndex: "addressDetails",
      key: "addressDetails",
      width:150,
      render:(text,record)=>{
        return(
          <div style={{border:"none"}}>
            <div> <b>{record?.childDetails?.addressDetails?.receiverName || text?.receiverName}</b> </div>
            <div> {`Ph- ${record?.childDetails?.addressDetails?.receiverMobileNumber || text?.receiverMobileNumber}`} </div>
            <div>{`${record?.childDetails?.addressDetails?.receiverPincode || text?.receiverPincode}, 
            ${record?.childDetails?.addressDetails?.receiverAreaName || text?.receiverAreaName}, 
            ${record?.childDetails?.addressDetails?.receiverCity || text?.receiverCity}, 
            ${record?.childDetails?.addressDetails?.receiverState || text?.receiverState}`}</div>
          </div>
        );
      }
      
    },
    {
      title: "Shipment Details",
      dataIndex: "",
      key: "",
      render:(text)=>{
        let shipment = text?.childDetails?.type === 1 ? "Dox" : "Non-Dox";
        let booking = text?.childDetails?.service === 1 ? "standard" : text?.childDetails?.service === 2 ? "fast track" : "value plus";
        return(
          <div>
            <div> Shipment type: <b>{capitaliseWithHyphen(shipment || text?.shipmentDetails?.type)}</b></div>
            <div>Booking type: <b>{capitaliseWithHyphen(booking || text?.serviceDetails?.service)}</b></div>
            <div>Mode: <b>{capitaliseWithHyphen(text?.childDetails?.travelBy || text?.serviceDetails?.travelBy)}</b></div>
          </div>
        );
      }
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render:()=><div style={{
        transition: "background 0.3s",
        border: "1.5px dotted #9A9999",
        width:"0",
        height:"150px",
        padding:"0",
        margin:"0",
      }}></div>,
    },
    {
      title: "AWB No",
      dataIndex: "awbNumber",
      key: "awbNumber",
      render:(text,record)=><span>{record?.childDetails?.childAwbNumber || record?.awbNumber}</span>,
    },
    {
      title: "Seal/Signature",
      dataIndex: "Seal/Signature",
      key: "Seal/Signature",
    },
  ];
  useEffect(()=>{
    drsListFun(history?.state);
  },[history]);
  const printHandle = useReactToPrint({
    content: () => componentRef.current,
  });
  let drsListFun = async(drsNumber)=>{ 
    let response = await API.Api(
      GET_SINGLE_DRS+drsNumber
    ).getWithPremiseIdHeader();
    if(response?.status){
      setSIngleDrs(response?.response?.data);
    }else{
      message.error(response?.response?.data?.message);
    }
  };
  return (
    <div className="p-2 print-drs-container">
      <div style={{display :"flex",justifyContent:"space-between",width:"80%",margin:"auto"}}>
        <span className="align-center">
          <img
            src={back_img}
            onClick={() => navigate("/generate-drs",{state:"PRINT_DRS"})}
            style={{ cursor: "pointer" }}
            className="mr-1"
          />
          <b className="align-center">Print DRS</b>
        </span>
        <Button onClick={printHandle} className="mt-2 mb-2">
          <img src={print_icon} /> <b>PRINT</b> 
        </Button>
      </div>
      
      <div className="main-div p-2" ref={componentRef}>
        <Row >
          <Col span={8}>
            <img src={smcs_logo}/>
            <div className="mt-1">
              <span> <b>{userInfo?.premises?.premiseName}</b> </span>
              <div>{capitaliseWithHyphen(profileDetailsResult?.data?.hubAddress)}</div>
            </div>
          </Col>
          <Col span={8}></Col>
          <Col span={8}>
            <div><b>DRS ID: </b>{singleDrs?.drsId}</div>
            <div><b>Date: </b>{singleDrs?.createDate}</div>
            <div><b>Delivery boy: </b>{singleDrs?.deliveryBoyName}</div>
            <div><b>Area: </b>{singleDrs?.areaDtos?.map((data)=>`${capitaliseWithHyphen(data?.areaName)}, `)}</div>
          </Col>
        </Row>
       
        <Table dataSource={singleDrs?.shipments} columns={columns} pagination={false}/>
       
      </div>
    </div>
  );
};

export default printDrs;
