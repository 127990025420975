import constants from "../constants/constants";
import AddCategory from "../assests/images/addCategory.svg";
import my_order from "../assests/images/my-order.svg";
import product_request from "../assests/images/product-request.svg";
import orderRequest from "../assests/images/OrderRequest.svg";
import addProduct from "../assests/images/addProduct.svg";

const { ROLES } = constants;
const { HUB_ADMIN, HO_ADMIN, CP_ADMIN } = ROLES;

export const SubPathStationery = [
  {
    imgSrc: product_request,
    title: "Order Products",
    id: "my-order",
    additionalClass: "",
    route: "/product-list",
    role: [HUB_ADMIN, CP_ADMIN],
  },
  {
    imgSrc: my_order,
    title: "My Orders",
    id: "my-order",
    additionalClass: "",
    route: "/order-history",
    role: [HUB_ADMIN, CP_ADMIN],
  },
  {
    imgSrc: AddCategory,
    title: "Add Categories",
    id: "my-order",
    additionalClass: "",
    route: "/add-category",
    role: [HO_ADMIN],
  },
  {
    imgSrc: orderRequest,
    title: "Order Request",
    id: "my-order",
    additionalClass: "",
    route: "/order-request",
    role: [HO_ADMIN],
  },
  {
    imgSrc: addProduct,
    title: "Add Product",
    id: "my-order",
    additionalClass: "",
    route: "/product",
    role: [HO_ADMIN],
  },
];

