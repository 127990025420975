/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Radio,
  Row,
  Col,
  Form,
  Input,
  Space,
  Button,
  Select,
  message,
  Spin,
  Alert,
} from "antd";
import axios from "axios";
// import { APIs } from "../../utils/worker";
import API from "../../api";
import urls from "../../api/urls";
import { APIs } from "../../utils/worker";
import BottomButton from "./BottomButton";
import Heading from "./Heading";
import {
  capitaliseWithHyphen,
  checkAlphabets,
  checkNumbervalue,
  checkSpace,
} from "../../utils";
import { ReactComponent as Reason } from "../../assests/images/SVG/check.svg";
import KycDetailsWOGST from "./kycDetailsWOgst";
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";

const {
  POST_GST,
  POST_WO_GST,
  UPDATE_CP_KYC,
  VERIFY_KYC_OTP,
  CP_KYC_SAVE,
  GET_KYC_DETAILS,
  GET_HUB_PINCODE,
} = urls;

const KycDetails = ({ handleStepsChange, goBackStep, kycData }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [isGstRegister, setIsGstRegister] = useState(true);
  const [panBusinees, setPanBusinees] = useState(false);
  const [aadharVerify, setAadharVerify] = useState(false);
  const [panVerify, setPanVerify] = useState(false);
  const [postResponse, setPostResponse] = useState(null);
  const [viewOnly, setViewOnly] = useState(false);

  const typeOfBusinessOptions = [
    {
      value: "Individual",
      label: "Individual",
    },
    {
      value: "Proprietorship",
      label: "Proprietorship",
    },
    {
      value: "Private limited",
      label: "Private limited",
    },
    {
      value: "Public limited",
      label: "Public limited",
    },
    {
      value: "LLP",
      label: "LLP",
    },
    {
      value: "Partnership",
      label: "Partnership",
    },
  ];

  useEffect(() => {
    if (kycData?.data?.premiseWalletDetails !== null) {
      // setViewOnly(true);
      setIsGstRegister(kycData?.data?.premiseWalletDetails?.isGstRegistered);
      form.setFieldsValue({
        businessPAN: kycData?.data?.premiseWalletDetails?.businessPAN,
        nameOfCompany: kycData?.data?.premiseWalletDetails?.nameOfCompany,
        addressLine1: kycData?.data?.premiseWalletDetails?.addressLine1,
        addressLine2: kycData?.data?.premiseWalletDetails?.addressLine2,
        pincode: kycData?.data?.premiseWalletDetails?.pincode,
        state: kycData?.data?.premiseWalletDetails?.state,
        city: kycData?.data?.premiseWalletDetails?.city,
        country: kycData?.data?.premiseWalletDetails?.country,
        aadharNumber: kycData?.data?.premiseWalletDetails?.aadharNumber,
        signatoryName: kycData?.data?.premiseWalletDetails?.signatoryName,
        signatoryPAN: kycData?.data?.premiseWalletDetails?.signatoryPan,
        isGSTRegistered: kycData?.data?.premiseWalletDetails?.isGstRegistered,
      });
    }
  }, [kycData]);

  const showMessageError = (errorMessage) => {
    message.error({
      content: <>{errorMessage}</>,
      onClick: (event) => {
        if (event.target.className.baseVal == "") {
          message.destroy();
        }
      },
      duration: 10, // Optional, how long the message is displayed (in seconds)
    });
  };

  const radioChange = (e) => {
    setPostResponse(null);
    if (e.target.value == false && postResponse === null) {
      PostWithPan();
    }
    form.resetFields();
    setIsGstRegister(e.target.value);
  };

  const PostWithPan = async () => {
    let response = await API.Api(`${POST_WO_GST}`).postWithPrmiseHeader({
      KYC: {
        isGSTRegistered: false,
      },
    });
    if (response?.status) {
      setPostResponse(response?.response?.data?.data);
    } else {
      message.error(response?.response.data?.message || "Newtork error");
    }
  };

  const onFinish = async (e) => {
    setLoading(true);
    let payload;
    if (isGstRegister) {
      payload = {
        customerId: postResponse?.customerId,
        isGstRegistered: e.isGSTRegistered || true,
        gstNumber: e.gstNumber,
        businessPan: e.businessPan,
        businessType: e.businessType,
        signatoryName: e.signatoryName,
        signatoryPan: e.signatoryPAN,
        aadharNumber: e.aadhar,
        address: {
          line1: e.addressLine1,
          line2: e.addressLine2,
          pincode: e.pincode,
          state: e.state,
          city: e.city,
          country: e.country,
        },
      };
    } else {
      payload = {
        customerId: postResponse?.customerId,
        isGstRegistered: e.isGSTRegistered || false,
        businessType: e.businessType,
        signatoryName: e.signatoryName,
        signatoryPan: e.signatoryPAN,
        aadharNumber: e.aadhar,
        address: {
          line1: e.addressLine1,
          line2: e.addressLine2,
          pincode: e.pincode,
          state: e.state,
          city: e.city,
          country: e.country,
        },
      };
    }

    let response = await API.Api(`${CP_KYC_SAVE}`).postWithPrmiseHeader(
      payload
    );
    if (response.status) {
      setLoading(false);
      message.success(
        response?.response?.data?.message || "KYC updated successfully"
      );
      handleStepsChange();
    } else {
      setLoading(false);
      showMessageError(response?.response?.data?.message || "Error");
    }
  };

  const checkGST = async (event, name) => {
    form.resetFields([
      "businessPAN",
      "nameOfCompany",
      "businessType",
      "signatoryPAN",
      "signatoryName",
      "aadharNumber",
      "otp",
    ]);

    if (event.target.value.length === 15) {
      setLoading(true);
      let payload = {
        KYC: {
          isGSTRegistered: true,
          GST: event.target.value,
        },
      };
      let response = await API.Api(POST_GST).postWithPrmiseHeader(payload);
      if (response?.status) {
        setLoading(false);
        let res = response?.response?.data?.data.customerData?.kyc;
        setPostResponse(response?.response?.data?.data);
        form.setFieldsValue({
          businessPAN: response?.response?.data?.data.customerData.businessPAN,
          nameOfCompany: res?.nameasPerGst,
          addressLine1: res.addressAsperGst.gstAddressLine1,
          addressLine2: res.addressAsperGst.gstAddressLine2,
          pincode: res.addressAsperGst.gstPinCode,
          state: res.addressAsperGst.gstState,
          city: res.addressAsperAadhar.city,
          country: res.addressAsperGst.gstCountry,
        });
      } else {
        setLoading(false);
        showMessageError(response?.response?.data?.message || "Network error");
      }
    }
  };

  function handleBusinessType(params) {
    if (!isGstRegister) {
      if (params == "Proprietorship" || params == "Individual") {
        setPanBusinees(false);
      } else {
        setPanBusinees(true);
      }
    }
  }

  async function sendOTP() {
    let formData = form.getFieldsValue();

    let payload = {
      KYC: {
        isGSTRegistered: formData.isGSTRegistered,
        signatoryName: formData.signatoryName,
        signatoryPAN: formData.signatoryPAN,
        aadhar: formData.aadharNumber,
        customerConsent: true, //need to check
        businessType: formData?.businessType,
      },
      address: {
        line1: formData?.addressLine1,
        line2: formData?.addressLine2,
        pincode: formData?.pincode,
        state: formData?.state,
        city: formData?.city,
        country: formData?.country,
        //"stateCode": "AP"   //maybe not needed
      },
    };

    setLoading(true);
    let response = await API.Api(
      `${UPDATE_CP_KYC + postResponse?.customerId}`
    ).patchIdHeader(payload);

    if (response.status) {
      setLoading(false);
      let data = response?.response.data?.data;
      // setPostResponse(response?.response.data?.data);
      setAadharVerify(false);
      if (data?.kycData?.aadharResponse?.isValid) {
        if (data?.kycData?.message.includes("does not match")) {
          showMessageError(data?.kycData?.message);
        } else {
          message.success(data?.kycData?.aadharResponse?.message);
        }
      } else {
        if (data?.kycData?.aadharResponse?.isValid == false) {
          showMessageError(data?.kycData?.aadharResponse?.message);
        }
      }
    } else {
      setLoading(false);
    }
  }

  async function handleVerifyOTP() {
    let formOTP = form.getFieldValue("otp");
    setLoading(true);
    let response = await API.Api(
      `${VERIFY_KYC_OTP + postResponse?.customerId}`
    ).patchIdHeader({
      KYC: {
        OTP: formOTP,
      },
    });

    if (response?.status) {
      setLoading(false);

      let data = response?.response.data?.data;
      setPanVerify(false);

      if (data?.kycData?.aadharResponse?.isValid) {
        message.success(data?.kycData?.message || "KYC verified successfully");
        let address = data?.kycData?.aadharResponse?.addressAsPerAadhar;

        form.setFieldsValue({
          addressLine1: address?.addressLine1,
          addressLine2: address?.addressLine2,
          pinCode: address?.pincode,
          state: address?.state,
          city: address?.city,
          country: address?.country,
        });
      } else {
        if (data?.kycData?.aadharResponse?.isValid == false) {
          showMessageError(data?.kycData?.aadharResponse?.message);
        }
      }
    } else {
      setLoading(false);
      setPanVerify(!panVerify);
    }
  }

  const validateWrapper = (event) => {
    if (checkNumbervalue(event) || checkSpace(event)) {
      event.preventDefault();
    }
  };

  const handlePincodeInput = async (values) => {
    if (values.target.value.length > 5) {
      setLoading(true);
      let response = await API.Api(
        GET_HUB_PINCODE + values.target.value
      ).getWithPremiseHeader();
      if (response?.status) {
        setLoading(false);
        form.setFieldValue("state", response?.response?.data?.stateName);
        form.setFieldValue("city", response?.response?.data?.city);
      } else {
        message.error(response?.response?.data.message || "Invalid pincode");
        setLoading(false);
        form.setFieldValue("state", null);
        form.setFieldValue("city", null);
      }
    }
  };

  function AddressDetail() {
    return (
      <>
        <Col md={12} lg={12} xl={12} sm={24} xs={24}>
          <Form.Item
            label="Address line 1"
            name="addressLine1"
            rules={[
              {
                required: true,
                message: "Please enter address line 1",
              },
            ]}>
            <Input placeholder="Enter address line 1" />
          </Form.Item>
        </Col>
        <Col md={12} lg={12} xl={12} sm={24} xs={24}>
          <Form.Item
            label="Address line 2"
            name="addressLine2"
            rules={[
              {
                required: false,
                message: "Please enter address line 2",
              },
            ]}>
            <Input placeholder="Enter address line 2" />
          </Form.Item>
        </Col>
        <Col md={12} lg={12} xl={12} sm={24} xs={24}>
          <Form.Item
            label="Pincode"
            name="pincode"
            rules={[
              {
                required: true,
                message: "Please enter pincode",
              },
              () => ({
                validator(rule, value = "") {
                  if (value.length > 0 && value.length < 6) {
                    return Promise.reject("Pincode should be 6 digits");
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            onKeyPress={validateWrapper}>
            <Input
              placeholder="Enter pincode"
              maxLength={6}
              onChange={handlePincodeInput}
            />
          </Form.Item>
        </Col>
        <Col md={12} lg={12} xl={12} sm={24} xs={24}>
          <Form.Item label="State" name="state">
            <Input
              placeholder="Enter state" // disabled
            />
          </Form.Item>
        </Col>
        <Col md={12} lg={12} xl={12} sm={24} xs={24}>
          <Form.Item label="City" name="city">
            <Input
              placeholder="Enter city" // disabled
            />
          </Form.Item>
        </Col>
        <Col md={12} lg={12} xl={12} sm={24} xs={24}>
          <Form.Item label="Country" name="country">
            <Input
              placeholder="Enter country" // disabled
            />
          </Form.Item>
        </Col>
      </>
    );
  }

  return (
    <Spin spinning={loading}>
      <div className="cp-detail-wrapper">
        <Heading title={"KYC Details (GST Details)"} />
        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          className="global-form"
          // disabled={!viewOnly}
        >
          <Row gutter={24} className="global-form-row">
            <Row gutter={24}>
              <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                <Form.Item label="Is GST registered" name="isGSTRegistered">
                  <Radio.Group
                    onChange={radioChange}
                    defaultValue={isGstRegister}
                    value={isGstRegister}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {isGstRegister ? (
                <>
                  <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                    <Form.Item
                      label="GSTIN"
                      name="gstNumber"
                      rules={[
                        {
                          message: "Please enter GSTIN",
                          required: true,
                        },
                        {
                          pattern:
                            /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/g,
                          message: "Invalid GSTIN",
                        },
                      ]}>
                      <Input
                        placeholder="Enter GSTIN"
                        maxLength={15}
                        onChange={(e) => checkGST(e, "gstNumber")}
                        // suffix={checkStatus ? <Reason /> : null}
                        onKeyPress={(e) => {
                          if (/[^A-Za-z0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onInput={(e) =>
                          (e.target.value = e.target.value.toUpperCase())
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                    <Form.Item
                      label="Business PAN number "
                      name="businessPAN"
                      rules={[
                        {
                          required: true,
                          message: "Please enter business pan number",
                        },
                      ]}>
                      <Input
                        placeholder="Enter business pan number"
                        maxLength={12}
                        onInput={(e) =>
                          (e.target.value = e.target.value.toUpperCase())
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                    <Form.Item
                      label="Name of company"
                      name="nameOfCompany"
                      rules={[
                        {
                          required: true,
                          message: "Please enter company name",
                        },
                      ]}>
                      <Input placeholder="Enter name of company" disabled />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                    <Form.Item
                      label="Type of business"
                      name="businessType"
                      rules={[
                        {
                          required: true,
                          message: "Please select type of business",
                        },
                      ]}>
                      <Select
                        placeholder="Please select an option"
                        onChange={handleBusinessType}
                        options={typeOfBusinessOptions}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                    <Form.Item
                      label="Signatory PAN number GST"
                      name="signatoryPAN"
                      rules={[
                        {
                          required: true,
                          message: "Please enter signatory PAN number",
                        },
                      ]}>
                      <Input
                        onInput={(e) =>
                          (e.target.value = e.target.value.toUpperCase())
                        }
                        placeholder="Enter signatory PAN number"
                        maxLength={12}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={24} xl={24} sm={24} xs={24}>
                    {postResponse?.kycData?.GSTResponse?.directorNames?.length >
                    0 ? (
                      <Form.Item
                        label={<strong>Name of proprietor / partner</strong>}
                        name="signatoryName"
                        rules={[
                          {
                            required: true,
                            message: "Please select",
                          },
                        ]}>
                        <Radio.Group>
                          {postResponse?.kycData?.GSTResponse?.directorNames?.map(
                            (val, idx) => {
                              return (
                                <div key={idx}>
                                  <Radio value={val} style={{ margin: "6px" }}>
                                    {val}
                                  </Radio>
                                </div>
                              );
                            }
                          )}
                          {/* {["Abhishek", "Amit"]?.map((val, idx) => {
                            return (
                              <div key={idx}>
                                <Radio value={val} style={{ margin: "6px" }}>
                                  {val}
                                </Radio>
                              </div>
                            );
                          })} */}
                        </Radio.Group>
                      </Form.Item>
                    ) : null}
                  </Col>

                  <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                    <Form.Item
                      label="Aadhar number"
                      name="aadharNumber"
                      help={
                        viewOnly && (
                          <div style={{ margin: "5px 0 15px 0" }}>
                            <InfoCircleOutlined />
                            {" Please fill all the above fields "}
                          </div>
                        )
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please enter aadhar number",
                        },
                      ]}
                      onKeyPress={validateWrapper}>
                      <Input
                        placeholder="Enter aadhar number"
                        maxLength={12}
                        suffix={
                          viewOnly && (
                            <Button
                              className="save-btn mt-05 mb-05"
                              disabled={!aadharVerify}
                              onClick={sendOTP}>
                              SEND OTP
                            </Button>
                          )
                        }
                        // disabled
                        onChange={(e) => {
                          if (e.target.value.length > 11) {
                            setAadharVerify(true);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                    <Form.Item
                      label="OTP"
                      name="otp"
                      rules={[
                        {
                          required: true,
                          message: "Please enter OTP",
                        },
                      ]}
                      onKeyPress={validateWrapper}>
                      <Input
                        placeholder="Enter OTP"
                        maxLength={6}
                        suffix={
                          viewOnly && (
                            <Button
                              className="save-btn mt-05 mb-05"
                              disabled={!panVerify}
                              onClick={handleVerifyOTP}>
                              VERIFY OTP
                            </Button>
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                  <AddressDetail />
                </>
              ) : (
                <>
                  <KycDetailsWOGST
                    form={form}
                    address={<AddressDetail />}
                    postResponse={postResponse}
                    validateWrapper={validateWrapper}
                    setPostResponse={setPostResponse}
                    typeOfBusinessOptions={typeOfBusinessOptions}
                  />

                  <Row
                    gutter={12}
                    className="mt-2"
                    justify={"end"}
                    style={{ flex: "auto" }}>
                    <BottomButton goBackStep={goBackStep} />
                  </Row>
                </>
              )}
            </Row>
            <hr
              className="divider"
              style={{ marginTop: "20px", marginBottom: "30px" }}
            />
            {isGstRegister && (
              <Row
                gutter={12}
                className="mt-2"
                justify={"end"}
                style={{ flex: "auto" }}>
                <BottomButton goBackStep={goBackStep} />
              </Row>
            )}
          </Row>
        </Form>
        {/* {!viewOnly && (
          <Row
            gutter={12}
            className="mt-2"
            justify={"end"}
            style={{ flex: "auto" }}
          >
            <Button className="cancel-btn" onClick={handleStepsChange}>
              NEXT
            </Button>
          </Row>
        )} */}
      </div>
    </Spin>
  );
};

export default KycDetails;
