/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Table,
  Spin,
  Input,
  DatePicker,
  Modal,
  Steps,
  Row,
  Col,
  Progress,
  Tooltip,
  Button,
  Form,
  Select,
  message,
} from "antd";
import Heading from "../../../components/Heading";
import { capitaliseWithHyphen, commonSorterForTable } from "../../../utils";
import { ReactComponent as Edit_icon } from "../../../assests/images/edit-logo.svg";
import { APIs, getToken } from "../../../utils/worker";
import axios from "axios";
import { useParams } from "react-router-dom";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import urls from "../../../api/urls";
import API from "../../../api";
import { FastForwardOutlined } from "@ant-design/icons";

const { UPDATE_MASTER_VEHICLE } = urls;

const VehicleMaster = () => {
  const formLayout = "vertical";
  const [form] = Form.useForm();
  const userId = JSON.parse(localStorage.getItem("userId"));
  const params = useParams();
  const { RangePicker } = DatePicker;
  const token = getToken();
  const [dataSource, setDataSource] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [routeAvailable, setRouteAvailable] = useState(false);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [modalTitle, setModalTitle] = useState("Add Vehicle");
  const [modalData, setModalData] = useState(null);
  // const [driverChange, setDriverChange] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [updateFormChange, setUpdateFormChange] = useState(false);

  const tableLoading = {
    spinning: loading,
    indicator: (
      <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
    ),
  };

  useEffect(() => {
    getVehicleList();
  }, []);

  useEffect(() => {}, [modalVisible]);

  const getVehicleList = async () => {
    setLoading(true);
    try {
      let url = APIs.baseURL + "/surface-route-service/v1/getVehicleMasters";
      let response = await axios.get(url, {
        headers: {
          Authorization: token,
          "SMCS-PREMISE-ID": userId?.userInfo?.premiseId,
          "USER-ID": userId?.userInfo?.userId,
        },
      });
      let temp = [];
      if (response?.data) {
        setLoading(false);
      }
      response?.data?.map((item, index) => {
        temp.push({
          key: index + 1,
          vehicleNo: item?.vehicleNo,
          vehicleCapacity: item?.vehicleCapacity,
          route: item?.routeName,
          driverName: item?.driverName,
          phoneNumber: item?.phoneNumber,
          routeId: item?.routeId,
          driverId: item?.driverId,
          id: item?.id,
          vehicleClass: item?.vehicleClass,
        });
      });
      setDataSource(temp);
      setData(temp);
    } catch (err) {
      setLoading(false);
      message.error(err?.data?.message || "Internal server error");
    }
  };

  const openModal = (title, record) => {
    if (title === "Update Vehicle") {
      getDriverList(record?.vehicleClass);
      // form.setFieldValue("route", record?.routeId);
      form.setFieldValue("vehicle", record?.vehicleNo);
      form.setFieldValue("driverName", record?.driverId);
      setModalData(record);
    } else {
      setModalData({});
    }
    setModalTitle(title);
    getVehicles();
    getRouteList(record);
    setModalVisible(true);
  };

  const getVehicles = async () => {
    setLoading(true);

    try {
      let url = APIs.baseURL + "/vehicle-service/v1/getvehicles";
      let response = await axios.get(url, {
        headers: {
          Authorization: token,
          "SMCS-PREMISE-ID": userId?.userInfo?.premiseId,
        },
      });
      let temp = [];
      response?.data?.map((item) => {
        temp.push({
          label: item?.vehicleNo,
          value: item?.vehicleNo,
          vehicleClass: item?.vehicleClass,
          routeId: item?.routeId,
        });
      });
      if (response?.data) {
        setLoading(false);
      }
      setVehicleList(temp);
    } catch (err) {
      setLoading(false);

      message.error(err?.data?.message || "Internal server error");
    }
  };

  const getRouteList = async (record) => {
    setLoading(true);
    try {
      let url = APIs.baseURL + "/vehicle-route-service/v1/getAllRoute/SURFACE";
      let response = await axios.get(url, {
        headers: {
          Authorization: token,
          "SMCS-PREMISE-ID": userId?.userInfo?.premiseId,
          "USER-ID": userId?.userInfo?.userId,
        },
      });

      if (response?.data) {
        setLoading(false);
      }

      let temp = [];
      response?.data?.data?.map((item) => {
        temp.push({
          label: capitaliseWithHyphen(item?.name),
          value: item?.routeId,
        });
      });

      setRouteList(temp);
      if (record) {
        form.setFieldValue("route", record?.routeId);
      }
    } catch (err) {
      message.error(err?.data?.message || "Internal server error");
    }
  };

  const handleSelectVehicle = (data, record) => {
    getDriverList(record?.vehicleClass);
    if (record?.routeId) {
      form.setFieldValue("route", record?.routeId);
      setRouteAvailable(true);
    } else {
      setRouteAvailable(false);
      form.setFieldValue("route", "");
    }
  };

  // const handleSelectDriver = () => {
  //   setDriverChange(true);
  // };

  const getDriverList = async (vehicleClass) => {
    setLoading(true);
    try {
      let url =
        APIs.baseURL + "/driver-service/v1/driver/getdriver/" + vehicleClass;
      let response = await axios.get(url, {
        headers: {
          Authorization: token,
          "SMCS-PREMISE-ID": userId?.userInfo?.premiseId,
        },
      });
      let temp = [];
      if (response?.data) {
        setLoading(false);
      }
      response?.data?.map((item, index) => {
        temp.push({
          label: item?.name,
          value: item?.driverId,
          disabled: item?.driverStatus == true ? true : false,
        });
      });
      setDriverList(temp);
    } catch (err) {
      setLoading(false);
      message.error(err.response?.data?.message || "Internal server error");
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: "Sl No",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: 10,
    },
    {
      title: "Vehicle No.",
      dataIndex: "vehicleNo",
      key: "vehcileNo",
      width: 15,
    },
    {
      title: "Vehicle Capacity",
      dataIndex: "vehicleCapacity",
      key: "vehicleCapacity",
      width: 15,
    },
    {
      title: "Route",
      dataIndex: "route",
      key: "route",
      width: 30,

      sortOrder: sortedInfo.columnKey === "route" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "route"),
      ellipsis: true,
      render: (text) => (
        <>
          <span>{capitaliseWithHyphen(`${text.split("-").join(" - ")}`)}</span>
        </>
      ),
    },
    {
      title: "Driver Details",
      dataIndex: "driverName",
      key: "phoneNumber",
      width: 15,

      render: (text, record) => (
        <>
          <div>{record.driverName}</div>
          <div>{record.phoneNumber}</div>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: 15,

      render: (text, record) => (
        <>
          <Tooltip title={"Edit"} className="pointer">
            <Edit_icon onClick={() => openModal("Update Vehicle", record)} />
          </Tooltip>
        </>
      ),
    },
  ];

  const onSearch = (e) => {
    if (e.target.value.length < 1) {
      setPageNum(1);
    }
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(data, currValue)
        : filterArray(data, currValue);
    let temp = [];
    filteredData.map((item, index) => temp.push({ ...item, key: index + 1 }));
    setDataSource(temp);
  };

  const filterArray = (dataSource, currValue) =>
    dataSource?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );

  const handleDownload = () => {
    window.open(`${APIs.baseURL}/tat-service/v1/export/fm-lm`);
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  function handlePageChange(current, pageSize) {
    setPageNum(current);
    setPageSize(pageSize);
  }

  const formProps = {
    name: "address",
    className: "register-form",
    autoComplete: "off",
    onFinish: (values) => handleCreateMaster(values),
  };

  const handleUpdateVehicleMaster = async () => {
    let values = form.getFieldsValue();
    let payload = {
      id: modalData?.id,
      vehicleNo: values.vehicle,
      routeId: values.route,
      driverId: values.driverName,
    };
    setLoading(true);

    let response = await API.Api(
      UPDATE_MASTER_VEHICLE + modalData?.id
    ).putIdHeader(payload);
    if (response?.status) {
      setLoading(false);
      setModalVisible(false);
      getVehicleList();
      message.success(
        response?.response?.data?.message || "Updated successfully"
      );
    } else {
      setLoading(false);

      message.error(
        response?.response?.data?.message || "Internal server Error"
      );
    }
  };

  const handleCreateMaster = async (values) => {
    let payload = {
      vehicleNo: values.vehicle,
      routeId: values.route,
      driverId: values.driverName,
    };
    setLoading(true);
    try {
      let response = await axios.post(
        `${APIs.baseURL}/surface-route-service/v1/saveVehicleMaster`,
        payload,
        {
          headers: {
            Authorization: token,
            "SMCS-PREMISE-ID": userId?.userInfo?.premiseId,
            "USER-ID": userId?.userInfo?.userId,
          },
        }
      );
      if (response?.status === 200) {
        setLoading(false);
        setModalVisible(false);
        getVehicleList();
        setUpdateFormChange(false);
        form.resetFields();
      }
    } catch (err) {
      message.error(err?.response?.data?.message);
      setLoading(false);
    }
  };

  const closeModal = () => {
    form.resetFields();
    setModalVisible(false);
  };

  return (
    <Spin spinning={false}>
      <div className="p-2">
        <Heading title="Vehicle Master" id={7} />
        <div className="vehicle-container">
          <div className="d-flex space-between align-cente p-1">
            <Input
              style={{ borderRadius: "5px", height: "40px", width: "350px" }}
              placeholder="Search by Vehicle no, Driver name & Route"
              onChange={onSearch}
            />
            <div className="d-flex">
              {/* <Button className="save-btn" disabled>
                UPLOAD
              </Button>
              &nbsp;&nbsp;
              <Button className="save-btn" disabled>
                DOWNLOAD
              </Button>
              &nbsp;&nbsp; */}
              <Button
                onClick={() => openModal("Add Vehicle")}
                className="save-btn"
              >
                ADD VEHICLE
              </Button>
            </div>
          </div>

          <Table
            columns={columns}
            dataSource={inputSearchValue.length == 0 ? data : dataSource}
            pagination={{
              pageSize: pageSize,
              itemRender: itemRender,
              pageSizeOptions: [5, 10, 20, 50, 100],
              onChange: handlePageChange,
              current: pageNum,
            }}
            onChange={handleChange}
            className="tat-table-fm table-head-sticky"
            loading={tableLoading}
          />
        </div>
      </div>
      <Modal
        visible={modalVisible}
        title={modalTitle}
        onCancel={closeModal}
        footer={false}
        width={1200}
        maskClosable={false}
        destroyOnClose
      >
        <Spin spinning={loading}>
          <div className="p-1">
            <Form
              {...formProps}
              form={form}
              onFieldsChange={() => {
                if (modalTitle === "Update Vehicle") {
                  setUpdateFormChange(true);
                }
              }}
              layout={formLayout}
              className="route-table global-form"
            >
              <Row className={"global-form-row route-main"} gutter={20}>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item
                    name="vehicle"
                    label="Vehicle"
                    rules={[
                      {
                        required: true,
                        message: "Please select vehicle",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      disabled={modalTitle === "Update Vehicle"}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      placeholder="Please select vehicle"
                      options={vehicleList}
                      onChange={(data, record) =>
                        handleSelectVehicle(data, record)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="route"
                    label="Route"
                    rules={[
                      {
                        required: true,
                        message: "Please select route",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      placeholder="Please select route"
                      options={routeList}
                      // disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item
                    name="driverName"
                    label="Driver name"
                    rules={[
                      {
                        required: true,
                        message: "Please select driver",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      placeholder="Please select driver"
                      options={driverList}
                      // onChange={handleSelectDriver}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <hr className="divider mt-3" />
              <div
                className="d-flex flex-end"
                style={{ padding: "1rem 0 0 0" }}
              >
                <Button className="cancel-btn" onClick={closeModal}>
                  CANCEL
                </Button>
                {modalTitle === "Update Vehicle" ? (
                  <Button
                    className="save-btn ml-1"
                    // htmlType="submit"
                    onClick={handleUpdateVehicleMaster}
                    disabled={!updateFormChange}
                  >
                    UPDATE
                  </Button>
                ) : (
                  <Button htmlType="submit" className="save-btn ml-1">
                    SUBMIT
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </Spin>
      </Modal>
    </Spin>
  );
};
export default VehicleMaster;
