/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  Select,
  DatePicker,
  Spin,
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { APIs, getToken } from "../../../utils/worker";
import "./style.scss";
import moment from "moment";
import axios from "axios";
import { capitalize } from "../../../utils";

const token = getToken();
const toolbarConfig = {
  fontColor: {
    colors: [
      {
        color: "hsl(0, 0%, 0%)",
        label: "Black",
      },
      {
        color: "hsl(0, 0%, 30%)",
        label: "Dim grey",
      },
      {
        color: "hsl(0, 0%, 60%)",
        label: "Grey",
      },
      {
        color: "hsl(0, 0%, 90%)",
        label: "Light grey",
      },
    ],
  },
  fontBackgroundColor: {
    colors: [
      {
        color: "hsl(0, 75%, 60%)",
        label: "Red",
      },
      {
        color: "hsl(30, 75%, 60%)",
        label: "Orange",
      },
      {
        color: "hsl(60, 75%, 60%)",
        label: "Yellow",
      },
      {
        color: "hsl(90, 75%, 60%)",
        label: "Light green",
      },
      {
        color: "hsl(120, 75%, 60%)",
        label: "Green",
      },

      // ...
    ],
  },
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "|",
      "fontColor",
      "|",
      "italic",
      "|",
      "link",
      "|",
      "underline",
      "|",
      "strikethrough",
      "|",
      "superscript",
      "subscript",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "fontBackgroundColor",
      "|",
      "undo",
      "redo",
    ],
  },
  // image: {
  //   toolbar: [
  //     'imageStyle:full',
  //     'imageStyle:side',
  //     '|',
  //     'imageTextAlternative'
  //   ]
  // },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};

const CreateBroadcast = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [currentDate, setCurrentDate] = useState();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [messageData, setMessageData] = useState("");
  const [broadcastTo, setBroadcastTo] = useState("");
  const [hubCpZoneList, setHubCpZoneList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const broadcastToOptions = [
    { label: "All", value: "ALL" },
    // { label: "All Zone", value: "ALL ZONE" },
    { label: "All Hub", value: "ALL HUB" },
    { label: "All CP", value: "ALL CP" },
    // { label: "Zone", value: "ZONE" },
    { label: "Hub", value: "HUB" },
    { label: "CP", value: "CP" },
  ];
  const priorityOptions = [
    { label: "High", value: "HIGH" },
    { label: "Medium", value: "MEDIUM" },
    { label: "Low", value: "LOW" },
  ];

  useEffect(() => {
    let date = new Date();
    let today = formatedDate(date);
    form.setFieldValue("createdOn", today);
    setCurrentDate(today);
    setTimeout(() => {
      window.scroll(0, 0);
    }, 100);
  }, []);

  useEffect(() => {
    let new_ = messageData.substring(0, 350);
    setMessageData(new_);
  }, [messageData]);

  const formatedDate = (date) => {
    let dd = date.getDate();

    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    let today = dd + "/" + mm + "/" + yyyy;
    return today;
  };

  const dateFormat = "DD/MM/YYYY";

  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };

  const disabledDateNext = (current) => {
    return current <= moment(fromDate, "DD/MM/YYYY").startOf("day");
  };

  const changeFromDate = (date, dateString) => {
    setFromDate(dateString);
    form.setFieldValue("validTo", null);
  };

  const changeBroadcast = (data) => {
    setBroadcastTo(data);
    form.setFieldValue("hub", []);
    getHubCpZone(data);
  };

  const changeHubData = (data) => {
    // console.log(data);
    form.setFieldValue("hub", data);
  };

  const getHubCpZone = async (data) => {
    try {
      let response = await axios.get(
        APIs.baseURL + "/broadcast-service/v1/broadcast/premises/" + data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response.status === 200) {
        let temp = [];
        response.data.map((e) => {
          temp.push({
            label: capitalize(e.name),
            value: e.name,
          });
        });
        setHubCpZoneList(temp);
        setIsLoading(false);
      }
    } catch (error) {
      message.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  const createBroadcast = async (values) => {
    setIsLoading(true);
    let temp = [];
    if (values.hub !== undefined) {
      values.hub.map((e) => {
        temp.push({
          name: e,
        });
      });
    }
    let payload = {
      createdOn: currentDate,
      validFrom: fromDate,
      validTo: toDate,
      title: values.title,
      message: messageData,
      broadcastTo: values.broadcastTo,
      priorityType: values.priority,
      broadcastToDtos: temp,
    };
    try {
      let response = await axios.post(
        APIs.baseURL + "/broadcast-service/v1/broadcast",
        payload,
        {
          headers: {
            Authorization: token,
            token: token.split("Bearer ")[1],
          },
        }
      );
      if (response.status === 200) {
        message.success(
          response?.data?.message || "Broadcast Created Successfully"
        );
        form.resetFields();
        setIsLoading(false);
      }
    } catch (error) {
      message.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div className="master-main-container">
        <div className="p-1">
          <p className="f-16 fw-500 title-color">Broadcast Message</p>
          <hr className="divider" />
        </div>
        <Form
          className="global-form"
          layout="vertical"
          form={form}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          onFinish={createBroadcast}
        >
          <Row className="global-form-row">
            <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
            <Col xs={22} sm={22} md={16} lg={16} xl={16}>
              {/* <div className="profile d-flex center ml-2">
                {profileDetailsResult?.data?.firstName.substring(1, 0).toUpperCase()}
              </div> */}
              <Row gutter={20} className="global-form-row">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="createdOn"
                    label="Created on"
                    initialValue={currentDate}
                  >
                    <Input placeholder="Created on" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}></Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="validFrom"
                    label="Valid from"
                    // initialValue={moment(fromDate, dateFormat)}
                    rules={[
                      {
                        required: true,
                        message: "Please select valid from",
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={disabledDate}
                      format={dateFormat}
                      onChange={(date, dateString) =>
                        changeFromDate(date, dateString)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="validTo"
                    label="Valid to"
                    // initialValue={moment(toDate, dateFormat)}
                    rules={[
                      {
                        required: true,
                        message: "Please select valid to",
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={disabledDateNext}
                      format={dateFormat}
                      onChange={(date, dateString) => setToDate(dateString)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="broadcastTo"
                    label="Broadcast to"
                    // initialValue={moment(toDate, dateFormat)}
                    rules={[
                      {
                        required: true,
                        message: "Please select",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Broadcast To"
                      options={broadcastToOptions}
                      onChange={(data) => changeBroadcast(data)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  {broadcastTo === "HUB" ||
                  broadcastTo === "CP" ||
                  broadcastTo === "ZONE" ? (
                    <Form.Item
                      name={"hub"}
                      label={broadcastTo + " names"}
                      // initialValue={moment(toDate, dateFormat)}
                      rules={[
                        {
                          required: true,
                          message: "Please select",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        options={hubCpZoneList}
                        placeholder={"Select " + broadcastTo + " names"}
                      />
                    </Form.Item>
                  ) : (
                    ""
                  )}
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                      {
                        required: true,
                        message: "Please input title",
                      },
                    ]}
                  >
                    <Input placeholder="Title" maxLength={70} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="priority"
                    label="Priority"
                    // initialValue={moment(toDate, dateFormat)}
                    rules={[
                      {
                        required: true,
                        message: "Please select priority",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select priority type"
                      options={priorityOptions}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}></Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="message" label="Message">
                    <CKEditor
                      config={toolbarConfig}
                      editor={ClassicEditor}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        if (data.length > 350) {
                          message.error(
                            "message cannot be more than 350 characters"
                          );
                          event.preventDefault();
                        } else {
                          setMessageData(data);
                        }
                      }}
                      maxLength={10}
                    />
                    {messageData.length + "/350"}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
          </Row>
          <hr className="divider" />
          <div className="d-flex flex-end p-1">
            <Button
              className="btn-style cancel-btn ml-1 w-10"
              onClick={() => navigate("/dashboard")}
            >
              CANCEL
            </Button>
            <Button htmlType="submit" className="btn-style save-btn ml-1 w-10">
              SAVE
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};
export default CreateBroadcast;
