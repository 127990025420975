import React from "react";
import { Tooltip, Popconfirm } from "antd";
import {
  capitalize,
  dateFormat,
  overflowFunc,
  serialNum,
} from "../../../utils";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { ReactComponent as Edit_icon } from "../../../assests/images/edit-logo.svg";
import { ReactComponent as Disable_icon } from "../../../assests/images/SVG/disableGrey.svg";
import { ReactComponent as Disable_Edit_icon } from "../../../assests/images/SVG/disableEdit.svg";
import disable_icon from "../../../assests/images/SVG/disable.svg";

function filterNullDate(params) {
  if (params !== null) {
    return dateFormat(params);
  } else return "NA";
}

export const column = (handleEditView, handleDisable, pageNum, pageSize) => [
  {
    title: "Sl No",
    dataIndex: "key",
    width: 5,
    fixed: "left",
    render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
  },

  {
    title: "PIN Code",
    width: 10,
    dataIndex: "pincode",
    fixed: "left",
    key: "pincode",
  },
  {
    title: "City",
    dataIndex: "city",
    width: 10,
    key: "city",
    render: (text) => {
      return capitalize(text) || "NA";
    },
  },

  {
    title: "State Name",
    dataIndex: "stateName",
    key: "stateName",
    width: 10,
    render: (text) => {
      return capitalize(text);
    },
  },
  {
    title: "State Code",
    dataIndex: "stateCode",
    width: 8,
    key: "stateCode",
  },
  {
    title: "Zone",
    dataIndex: "zone",
    key: "zone",
    width: 8,
    render: (text) => {
      return text;
    },
  },

  {
    title: "PIN Code Type",
    dataIndex: "serviceability",
    key: "serviceability",
    width: 10,

    render: (text) => {
      return text?.serviceability;
    },
  },

  {
    title: "Serviceablility Type",
    dataIndex: "pincodeType",
    key: "pincodeType",
    width: 15,

    render: (text) => {
      return text?.pincodeType;
    },
  },
  {
    title: "Update Date",
    dataIndex: "updateDate",
    key: "updateDate",
    width: 10,
    render: (text) => {
      let getDate = dateFormat(text) || "NA";
      let filterNull = getDate?.props?.children;
      return filterNullDate(filterNull);
    },
  },

  {
    title: "Regular Area List",
    dataIndex: "areadto",
    key: "areadto",
    width: 15,
    render: (text) => {
      let areas = text
        ?.filter((val) => val?.areatype == "regular")
        ?.map((item, idx) =>
          text?.length - 1 === idx
            ? capitalize(item?.areaName + "")
            : capitalize(item?.areaName + ", ")
        );
      return overflowFunc(areas, 180);
    },
  },
  {
    title: "Premium Area List",
    dataIndex: "areadto",
    key: "areadto",
    width: 15,
    render: (text) => {
      let areas = text
        ?.filter((val) => val?.areatype == "premium")
        ?.map((item, idx) =>
          text?.length - 1 === idx
            ? capitalize(item?.areaName + "")
            : capitalize(item?.areaName + ", ")
        );
      return overflowFunc(areas, 180);
    },
  },

  {
    title: "Actions",
    key: "operation",
    fixed: "right",
    className: "col-actions",
    width: 10,
    render: (item, idx) => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            // cursor: "pointer",
          }}
        >
          <>
            {item?.enable ? (
              <>
                <Tooltip title="Edit" placement="bottom">
                  <p onClick={() => handleEditView(idx)} className="pointer">
                    <Edit_icon />
                  </p>
                </Tooltip>

                <Tooltip title="Disable" placement="bottom">
                  <Popconfirm
                    title={
                      <span
                        className="test"
                        style={{
                          textTransform: "initial",
                        }}
                      >
                        Do you want to disable this Pincode ?
                      </span>
                    }
                    cancelText="No"
                    okText="Yes"
                    icon={
                      <QuestionCircleOutlined
                        style={{
                          color: "red",
                        }}
                      />
                    }
                    onConfirm={() => handleDisable(idx)}
                  >
                    <p className="pointer">
                      <img src={disable_icon} />
                    </p>
                  </Popconfirm>
                </Tooltip>
              </>
            ) : (
              <>
                <Disable_Edit_icon />
                <Disable_icon />
              </>
            )}
          </>
        </div>
      );
    },
  },
];
