/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  message,
  Spin,
  Radio,
} from "antd";
import NormalAPI from "../../../api";
import urls from "../../../api/urls";
import { contentDiscriptionData } from "../../../components/contentDiscriptionData";
import { getProfileDetails } from "../../../actionsMethods/masterMethods";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import {
  firstLetterCapitalise,
  capitaliseWithHyphen,
  firstLetterCapsInSentence,
  checkNumbervalue,
  camelCase,
  checkSpace,
} from "../../../utils";
import {
  getServiceAbilityCheck,
  getServiceAbilityCheckReset,
} from "../../../actionsMethods/cashBookingMethods";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
const AuditInputs = ({ awb, changeTab }) => {
  const dispatch = useDispatch();
  const { provinceData } = contentDiscriptionData();

  const profileDetails = useSelector(
    (state) => state.masterReducer.getProfileDetailsReducer,
    shallowEqual
  );
  const { result: profileDetailsResult, fetching: loader } = profileDetails;
  const [form] = Form.useForm();
  const { GET_SHIPMENT, GET_CP_DETAILS } = urls;
  const { TextArea } = Input;
  const [selectData, setSelectData] = useState([]);
  const [apiData, setApiData] = useState({});
  const [loading, setLoading] = useState(false);
  const user_premiseId = JSON.parse(window?.localStorage?.getItem("userId"));
  const [cpName, setCpName] = useState("");
  const [openDiv, setOpenDiv] = useState(false);
  const [optionFor, setOptionFor] = useState([]);
  const [validorNot, setValidorNot] = useState({});
  const userToken = JSON.parse(window?.localStorage?.getItem("userinfo"));
  const [inputValue, setInputValue] = useState("");
  const { AUDIT_POST } = urls;
  const today = new Date();

  let date =
    today.getFullYear() +
    "-" +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + today.getDate()).slice(-2);
  let showDate =
    today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear();
  let time =
    ("0" + today.getHours()).slice(-2) +
    ":" +
    ("0" + today.getMinutes()).slice(-2) +
    ":" +
    ("0" + today.getSeconds()).slice(-2);
  let CurrentDateTime = date + " " + time;

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    dispatch(
      getProfileDetails(JSON.parse(localStorage.getItem("userinfo")).username)
    );
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getShipmet(inputValue); //search api call
    }, 900);
    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  function handleChange(value) {
    if (value.includes("all")) {
      const allValue = "all";
      const remainingValues = optionFor
        .map((item) => item?.value)
        .filter((e) => e !== allValue);

      setSelectData(remainingValues);
      form.setFieldsValue({
        auditType: remainingValues,
      });
    } else {
      setSelectData(value);
    }
  }

  const filterFunction = (key) => {
    return selectData.find((e) => e == key);
  };

  let barcode = "";
  let interval;

  const handleKeyDown = (event) => {
    if (interval) clearInterval(interval);
    if (event.code == "Enter") {
      if (barcode) getShipmet(barcode);
      barcode = "";
      return;
    }
    if (event.key != "Shift") barcode += event.key;
    interval = setInterval(() => (barcode = ""), 20);
  };

  const getShipmet = async (awb) => {
    form.resetFields();
    // setInputValue(awb);
    setSelectData([]);

    if (awb.length !== 14 && awb.length !== 11) {
      setOpenDiv(false);
      return;
    }

    setLoading(true);
    setOpenDiv(false);

    try {
      const hubId = JSON.parse(localStorage.getItem("userId"))?.userInfo
        ?.premiseId;
      const response = await NormalAPI.Api(GET_SHIPMENT + awb).getCustomHeader({
        "HUB-ID": hubId,
      });

      const resDate = response?.response?.data;

      if (response.status === true) {
        const optionsFor = [
          {
            value: "all",
            label: "---SELECT ALL---",
            disabled: true,
          },
          {
            value: "weight",
            label: "Weight",
          },
          {
            value: "destinationPincode",
            label: "Destination PIN Code",
            disabled: true,
          },
          {
            value: "service",
            label: "Service",
            disabled: true,
          },
          {
            value: "contentType",
            label: "Content Type",
            disabled: true,
          },
          {
            value: "PhysicalCondition",
            label: "Physical Condition",
            disabled: true,
          },
          {
            value: "documentPresent",
            label: "Document Present",
            disabled: true,
          },
          {
            value: "travelBy",
            label: "Mode",
            disabled: true,
          },
          {
            value: "value",
            label: "Value of the shipment",
            disabled: true,
          },
          {
            value: "shipment",
            label: "Shipment",
            disabled: true,
          },
        ];
        const newarr = optionsFor.filter(
          (item) => resDate[item.value] !== null
        );

        setOptionFor(newarr);

        const fieldsValueMap = {
          amountChargedCP: resDate?.charges,
          weightDeclaredWeightByClient: resDate?.weight,
          weightVolumetricWeightCP: resDate?.volumetricWeight,
          serviceValueEnteredCP: camelCase(resDate?.service),
          contentTypeValueEnteredCP: camelCase(resDate?.contentType),
          physicalConditionValueEnteredCP: resDate?.charges,
          destinationPINCodeValueEnteredCP: resDate?.destinationPincode,
          documentPresentValueEnteredCP: resDate?.documentPresent,
          ModeDeclaredByClient: camelCase(resDate?.travelBy),
          ShipmentDeclaredByClient: camelCase(resDate?.type),
          ValueDeclaredByClient: resDate?.value,
          bookedLength: resDate?.length,
          bookedWidth: resDate?.width,
          bookedHeight: resDate?.height,
        };
        form.setFieldsValue(fieldsValueMap);
        setApiData(resDate);
        if (resDate?.premiseId) {
          const cpDetailsResponse = await NormalAPI.Api(
            GET_CP_DETAILS + resDate?.premiseId
          ).getWithHeader();
          const cpName = cpDetailsResponse.response?.data?.premiseName;
          setCpName(cpName);

          setOpenDiv(true);
        }
      } else {
        message.error(response?.response?.data?.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    const data = [
      apiData?.type == "Non-dox"
        ? conditionallyCreateObject(values.weightAuditedWeight, {
            bookedWeight: values.weightDeclaredWeightByClient?.toString(),
            auditedWeight: values.weightAuditedWeight?.toString(),
            volumetricBookedValue: values.weightVolumetricWeightCP?.toString(),
            volumetricAuditedValue: values.auditVolumetricWeight?.toString(),
            bookedLength: values?.bookedLength?.toString(),
            auditedLength: values?.auditedLength?.toString(),
            bookedWidth: values?.bookedWidth?.toString(),
            auditedWidth: values?.auditedWidth?.toString(),
            bookedHeight: values?.bookedHeight?.toString(),
            auditedHeight: values?.auditedHeight?.toString(),
            type: "WEIGHT",
          })
        : conditionallyCreateObject(values.weightAuditedWeight, {
            bookedWeight: values.weightDeclaredWeightByClient?.toString(),
            auditedWeight: values.weightAuditedWeight?.toString(),
            volumetricBookedValue: 0,
            volumetricAuditedValue: 0,
            bookedLength: 0,
            auditedLength: 0,
            bookedWidth: 0,
            auditedWidth: 0,
            bookedHeight: 0,
            auditedHeight: 0,
            type: "WEIGHT",
          }),
      conditionallyCreateObject(values?.serviceAuditedValue, {
        bookedWeight: values?.serviceValueEnteredCP?.toString(),
        auditedWeight: values?.serviceAuditedValue,
        // bookedCharge: "4",
        // auditedCharge: "3.9",
        type: "SERVICE",
      }),
      conditionallyCreateObject(values?.destinationAuditedValue, {
        bookedWeight: values?.destinationPINCodeValueEnteredCP?.toString(),
        auditedWeight: values?.destinationAuditedValue,
        type: "DESTINATION_PINCODE",
      }),
      conditionallyCreateObject(values?.contentAuditedValue, {
        bookedWeight: values?.contentTypeValueEnteredCP,
        auditedWeight: values?.contentAuditedValue,
        type: "CONTENT_TYPE",
      }),
      conditionallyCreateObject(values?.physicalConditionAuditedValue, {
        bookedWeight: null,
        auditedWeight: values?.physicalConditionAuditedValue,
        type: "PHYSICAL_CONDITION",
      }),
      conditionallyCreateObject(values?.documentPresentAuditedValue, {
        bookedWeight: values?.documentPresentValueEnteredCP,
        auditedWeight: values?.documentPresentAuditedValue,
        type: "DOCUMENT_PRESENT",
      }),
      conditionallyCreateObject(values?.weightAuditedMode, {
        bookedWeight: values?.modeEnterByCp,
        auditedWeight: values?.weightAuditedMode,
        type: "TRAVEL_BY",
      }),
      conditionallyCreateObject(values?.ShipmentAudited, {
        bookedWeight: values?.ShipmentDeclaredByClient,
        auditedWeight: values?.ShipmentAudited,
        type: "TYPE",
      }),
      conditionallyCreateObject(values?.ValueAuditedCp, {
        bookedWeight: values?.ValueDeclaredByClient,
        auditedWeight: values?.ValueAuditedCp,
        type: "VALUE",
      }),
    ];

    const filteredData = data.filter((item) => item !== null);

    const payload = {
      comments: values.remarks,
      auditEventDetails: filteredData,
      awbNo: apiData?.awbNumber,
      premiseId: user_premiseId?.userInfo?.premiseId,
      userId: user_premiseId?.userInfo?.userId,
    };

    try {
      const response = await NormalAPI.Api(AUDIT_POST).postCustomHeader(
        payload,
        {
          "SMCS-PREMISE-ID": user_premiseId?.userInfo?.premiseId,
          "USER-ID": user_premiseId?.userInfo?.userId,
        }
      );

      if (response.status) {
        message.success(response?.data?.message || "Data Audited Successfully");
        resetInputField();
        changeTab("2");
      } else {
        message.error(response.response.data.message);
      }
    } catch (error) {
      message.error("Something went wrong", error);
    }
    setLoading(false);
  };

  const conditionallyCreateObject = (condition, dataObject) => {
    return condition ? dataObject : null;
  };

  const { Option } = Select;

  let optService = [
    {
      value: "Standard",
      label: "Standard",
    },
    {
      value: "Value plus",
      label: "Value plus",
    },

    {
      value: "fast track",
      label: "Fast Track",
    },
  ];

  const myFUnction = (e) => {
    dispatch(getServiceAbilityCheck(e.target.value, userToken.accessToken));
  };

  const inputHandler = (e) => {
    dispatch(getServiceAbilityCheckReset());
    form.setFieldsValue({ area: null });
    if (e.target.value.length > 5) {
      myFUnction(e);
    }
  };
  const serviceabilityCheckResponse = useSelector(
    (state) => state?.appReducer?.getServiceAbilityCheckReducer,
    shallowEqual
  );
  let suffixStatus = serviceabilityCheckResponse?.result?.serviceability;

  useEffect(() => {
    if (serviceabilityCheckResponse.fetching == false) {
      pinCodeValidator();
    }
  }, [serviceabilityCheckResponse.fetching]);

  const pinCodeValidator = () => {
    if (
      !serviceabilityCheckResponse.fetching &&
      serviceabilityCheckResponse?.error
    ) {
      setValidorNot({
        validateStatus: "error",
        errorMsg:
          serviceabilityCheckResponse?.error.message ||
          serviceabilityCheckResponse?.result.message,
      });
    } else {
      setValidorNot({
        validateStatus: "success",
        errorMsg: null,
      });
    }
  };

  const resetInputField = () => {
    getShipmet("");
    setInputValue("");
  };

  const volumCalculation = () => {
    const { auditedLength, auditedWidth, auditedHeight } = form.getFieldValue();

    const volume = (
      (+auditedLength * +auditedWidth * +auditedHeight) /
      5000
    ).toFixed(2);
    if (volume !== "NaN") {
      form.setFieldsValue({
        auditVolumetricWeight: volume,
      });
    }
  };

  const headerArray = [
    { key: "Date", value: showDate },
    {
      key: "Hub",
      value: capitaliseWithHyphen(profileDetailsResult?.data?.premiseName),
    },
    { key: "CP", value: capitaliseWithHyphen(cpName) },
    { key: "AWB No", value: apiData?.awbNumber },
    { key: "Type", value: apiData?.type },
  ];

  return (
    <Spin
      spinning={loading || loader}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="master-main-container">
        <div
          style={{ width: "30%", padding: "10px 10px 20px 7%" }}
          className="global-form"
        >
          <span>Enter AWB number</span>
          <Input
            className="indepentedInput"
            placeholder="Enter AWB number"
            type="text"
            value={inputValue}
            maxLength={14}
            defaultValue={apiData?.awbNumber}
            // onChange={(e) => getShipmet(e.target.value)}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
        {openDiv && (
          <div style={{ padding: " 10px 7%" }}>
            <div className="space-between" style={{ padding: "20px 0" }}>
              {headerArray.map((e, i) => (
                <div key={i}>
                  <span className="master-main-container-key">{e.key}</span>
                  <span className="master-main-container-value">
                    : {e.value}
                  </span>
                </div>
              ))}
            </div>
            <hr className="divider m-0" />

            <Form
              layout="vertical"
              id="myForm"
              onFinish={onFinish}
              className="global-form"
              form={form}
              scrollToFirstError={{
                behavior: "smooth",
                block: "center",
                inline: "center",
              }}
            >
              <Row className="pt-1 global-form-row" gutter={20}>
                <Col
                  md={10}
                  sm={24}
                  xsm={24}
                  className="mb-1 small-screen-width"
                >
                  <Form.Item
                    name="auditType"
                    label="Audit Type"
                    rules={[
                      {
                        required: true,
                        message: "Please select  value",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      onChange={handleChange}
                      placeholder="Select audit type"
                      value={selectData}
                    >
                      {apiData?.shipmentDetails?.ewayBillNumber == null
                        ? optionFor
                            .filter((e) => e.label !== "E-Way Bill Number")
                            .map((item) => (
                              <Option
                                key={item.value}
                                value={item.value}
                                disabled={item.disabled}
                              >
                                {item.label}
                              </Option>
                            ))
                        : optionFor?.map((item) => (
                            <Option key={item.value} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                {/* {apiData?.charges !== null && (
                  <Col
                    md={10}
                    sm={24}
                    xsm={24}
                    className="mb-1 small-screen-width"
                  >
                    <Form.Item
                      name="amountChargedCP"
                      label="Amount Charged @ CP"
                    >
                      <Input
                        placeholder="Enter charges"
                        type="text"
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                )}
                {apiData?.charges !== null && (
                  <Col
                    md={10}
                    sm={24}
                    xsm={24}
                    className="mb-1 small-screen-width"
                  >
                    <Form.Item
                      name="newChargesasperAudit"
                      label="New Charges as per Audit"
                      rules={[
                        {
                          required: true,
                          message: "Please enter charges",
                        },
                      ]}
                      onKeyPress={(event) => {
                        if (checkNumbervalue(event)) {
                          event.preventDefault();
                        }
                      }}
                    >
                      <Input
                        placeholder="Enter charges"
                        maxLength={6}
                        type="text"
                      />
                    </Form.Item>
                  </Col>
                )} */}
              </Row>
              {filterFunction("weight") && (
                <>
                  <div className="parent-heading">Weight</div>
                  <hr className="divider m-0" />
                  {apiData?.type !== "Dox" && (
                    <>
                      <Row className="pt-1 global-form-row" gutter={20}>
                        {apiData?.weight !== null && (
                          <>
                            <Col md={18} sm={12} xsm={12}>
                              <Row gutter={20}>
                                <Col
                                  md={10}
                                  sm={24}
                                  xsm={24}
                                  className="mb-1 small-screen-width"
                                >
                                  <Form.Item
                                    name="weightDeclaredWeightByClient"
                                    label="Declared Weight by CP(Kg)"
                                  >
                                    <Input
                                      placeholder="Enter weight"
                                      maxLength={4}
                                      type="text"
                                      disabled={true}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={14} sm={12} xsm={12}>
                                  <Row gutter={20}>
                                    {[
                                      {
                                        name: "bookedLength",
                                        label: "Length @ CP(Cm)",
                                        disabled: true,
                                      },

                                      {
                                        name: "bookedWidth",
                                        label: "Width @ CP(Cm)",
                                        disabled: true,
                                      },
                                      {
                                        name: "bookedHeight",
                                        label: "Height @ CP(Cm)",
                                        disabled: true,
                                      },
                                    ].map((item, index) => (
                                      <Col
                                        md={8}
                                        sm={6}
                                        xsm={6}
                                        key={index}
                                        className="mb-1 small-screen-width"
                                      >
                                        <Form.Item
                                          name={item?.name}
                                          label={item?.label}
                                          onKeyPress={(event) => {
                                            if (
                                              checkNumbervalue(event) ||
                                              checkSpace(event)
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please enter value",
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder="Enter number"
                                            maxLength={4}
                                            type="text"
                                            onChange={volumCalculation}
                                            disabled={item?.disabled}
                                          />
                                        </Form.Item>
                                      </Col>
                                    ))}
                                  </Row>
                                </Col>
                              </Row>
                            </Col>

                            <Col
                              md={6}
                              sm={24}
                              xsm={24}
                              className="mb-1 small-screen-width"
                            >
                              <Form.Item
                                name="weightVolumetricWeightCP"
                                label="Volumetric Weight @ CP(Kg)"
                                onKeyPress={(event) => {
                                  if (
                                    checkNumbervalue(event) ||
                                    checkSpace(event)
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter value",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Enter volumetric"
                                  maxLength={4}
                                  type="text"
                                  disabled={true}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row className="pt-1 global-form-row" gutter={20}>
                        {apiData?.weight !== null && (
                          <>
                            <Col md={18} sm={12} xsm={12}>
                              <Row gutter={20}>
                                <Col
                                  md={10}
                                  sm={24}
                                  className="mb-1 small-screen-width"
                                >
                                  <Form.Item
                                    name="weightAuditedWeight"
                                    label="Audited Weight(Kg)"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please enter value",
                                      },
                                    ]}
                                    onKeyPress={(event) => {
                                      if (
                                        checkNumbervalue(event) ||
                                        checkSpace(event)
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                  >
                                    <Input
                                      placeholder="Enter weight"
                                      maxLength={4}
                                      type="text"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={14} sm={12} xsm={12}>
                                  <Row gutter={20}>
                                    {[
                                      {
                                        name: "auditedLength",
                                        label: "Audit Length(Cm)",
                                        disabled: false,
                                        ph: "enter length",
                                      },

                                      {
                                        name: "auditedWidth",
                                        label: "Audit Width(Cm)",
                                        disabled: false,
                                        ph: "enter width",
                                      },

                                      {
                                        name: "auditedHeight",
                                        label: "Audit Height(Cm)",
                                        disabled: false,
                                        ph: "enter height",
                                      },
                                    ].map((item, index) => (
                                      <Col
                                        lg={8}
                                        md={8}
                                        sm={24}
                                        xsm={24}
                                        key={index}
                                        className="small-screen-width"
                                      >
                                        <Form.Item
                                          name={item?.name}
                                          label={item?.label}
                                          onKeyPress={(event) => {
                                            if (
                                              checkNumbervalue(event) ||
                                              checkSpace(event)
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          rules={[
                                            {
                                              required: true,
                                              message: `Please ${item?.ph}`,
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder={firstLetterCapsInSentence(
                                              item.ph
                                            )}
                                            maxLength={4}
                                            type="text"
                                            onChange={volumCalculation}
                                            disabled={item?.disabled}
                                          />
                                        </Form.Item>
                                      </Col>
                                    ))}
                                  </Row>
                                </Col>
                              </Row>
                            </Col>

                            <Col
                              md={6}
                              sm={24}
                              xsm={24}
                              className="mb-1 small-screen-width"
                            >
                              <Form.Item
                                name="auditVolumetricWeight"
                                label="Audit Volumetric Weight(Kg)"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter value",
                                  },
                                ]}
                                onKeyPress={(event) => {
                                  if (
                                    checkNumbervalue(event) ||
                                    checkSpace(event)
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              >
                                <Input
                                  placeholder="Enter volumetric"
                                  maxLength={4}
                                  readOnly={true}
                                  type="text"
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Row>
                    </>
                  )}
                  {apiData?.weight !== null && apiData?.type === "Dox" && (
                    <Row gutter={20} className=" pt-1 global-form-row">
                      <Col
                        md={12}
                        sm={24}
                        xsm={24}
                        className="mb-1 small-screen-width"
                      >
                        <Form.Item
                          name="weightDeclaredWeightByClient"
                          label="Declared Weight by Client(Kg)"
                        >
                          <Input
                            placeholder="Enter weight"
                            maxLength={4}
                            type="text"
                            disabled={true}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={12} sm={12}>
                        <Form.Item
                          name="weightAuditedWeight"
                          label="Audit Weight(Kg)"
                          rules={[
                            {
                              required: true,
                              message: "Please enter value",
                            },
                          ]}
                          onKeyPress={(event) => {
                            if (checkNumbervalue(event) || checkSpace(event)) {
                              event.preventDefault();
                            }
                          }}
                        >
                          <Input
                            placeholder="Enter weight"
                            maxLength={4}
                            type="text"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </>
              )}

              {filterFunction("service") && (
                <>
                  <div className="parent-heading">Service</div>
                  <hr className="divider m-0" />
                  <Row className="pt-1 global-form-row" gutter={20}>
                    <Col
                      md={10}
                      sm={24}
                      xsm={24}
                      className="mb-1 small-screen-width"
                    >
                      <Form.Item
                        name="serviceValueEnteredCP"
                        label="Value Entered CP"
                      >
                        <Select
                          className="pincodeType-placecholder"
                          placeholder="Select a service"
                          optionFilterProp="children"
                          // onChange={onChange}
                          disabled={true}
                        >
                          {user_premiseId?.userInfo?.premiseId == 43
                            ? optService?.map((item) => (
                                <Option key={item.value} value={item.value}>
                                  {item.label}
                                </Option>
                              ))
                            : optService
                                .filter((e) => e.label !== "Value plus")

                                .map((item) => (
                                  <Option key={item.value} value={item.value}>
                                    {item.label}
                                  </Option>
                                ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      md={10}
                      sm={24}
                      xsm={24}
                      className="mb-1 small-screen-width"
                    >
                      <Form.Item
                        name="serviceAuditedValue"
                        label="Audit Value"
                        rules={[
                          {
                            required: true,
                            message: "Please select service",
                          },
                        ]}
                      >
                        <Select
                          className="pincodeType-placecholder"
                          placeholder="Select a service"
                          optionFilterProp="children"
                          // onChange={onChange}
                        >
                          {user_premiseId?.userInfo?.premiseId == 43
                            ? optService?.map((item) => (
                                <Option key={item.value} value={item.value}>
                                  {item.label}
                                </Option>
                              ))
                            : optService
                                .filter((e) => e.label !== "Value plus")

                                .map((item) => (
                                  <Option key={item.value} value={item.value}>
                                    {item.label}
                                  </Option>
                                ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              {/* {filterFunction("E-WayBillNumber") && (
              <>
                <div>E-way Bill number</div>
                <hr className="divider m-0" />
                <Row className="pt-1 global-form-row" gutter={20}>
                  <Col span={6} className="mb-1 small-screen-width">
                    <Form.Item
                      name="EwayValueEnteredCP"
                      label="Value Entered CP"
                    >
                      <Input
                        placeholder="Enter Value"
                        maxLength={4}
                        type="text"
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6} className="mb-1 small-screen-width">
                    <Form.Item name="EwayAuditedValue" label="Audited Value">
                      <Input
                        placeholder="Enter Value"
                        maxLength={4}
                        type="text"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )} */}

              {filterFunction("contentType") && (
                <>
                  <div className="parent-heading">Content type</div>
                  <hr className="divider m-0" />
                  <Row className="pt-1 global-form-row" gutter={20}>
                    <Col
                      md={10}
                      sm={24}
                      xsm={24}
                      className="mb-1 small-screen-width"
                    >
                      <Form.Item
                        name="contentTypeValueEnteredCP"
                        label="Value Entered CP"
                      >
                        <Input
                          placeholder="Enter Value"
                          maxLength={4}
                          type="text"
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      md={10}
                      sm={24}
                      xsm={24}
                      className="mb-1 small-screen-width"
                    >
                      <Form.Item
                        name="contentAuditedValue"
                        label="Audited Value"
                        rules={[
                          {
                            required: true,
                            message: "Please select content",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select a content"
                          optionFilterProp="children"
                          options={
                            apiData?.type == "Non-dox"
                              ? provinceData.map((province) => ({
                                  label: province,
                                  value: province,
                                }))
                              : [
                                  {
                                    value: "Document",
                                    label: "Document",
                                  },
                                ]
                          }
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              {filterFunction("PhysicalCondition") && (
                <>
                  <div className="parent-heading">Physical Condition</div>
                  <hr className="divider m-0" />
                  <Row className="pt-1 global-form-row" gutter={20}>
                    <Col
                      md={10}
                      sm={24}
                      xsm={24}
                      className="mb-1 small-screen-width"
                    >
                      <Form.Item
                        name="physicalConditionAuditedValue"
                        label="Audited Value"
                        rules={[
                          {
                            required: true,
                            message: "Please enter value",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Value"
                          maxLength={25}
                          type="text"
                          onInput={(e) =>
                            (e.target.value = firstLetterCapitalise(e))
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              {filterFunction("destinationPincode") && (
                <>
                  <div className="parent-heading">Destination PIN Code</div>
                  <hr className="divider m-0" />
                  <Row className="pt-1 global-form-row" gutter={20}>
                    <Col
                      md={10}
                      sm={24}
                      xsm={24}
                      className="mb-1 small-screen-width"
                    >
                      <Form.Item
                        name="destinationPINCodeValueEnteredCP"
                        label="Value Entered CP"
                      >
                        <Input
                          placeholder="Enter value"
                          maxLength={6}
                          type="text"
                          disabled={true}
                          className="accpickupPincodeDisable"
                          suffix={
                            <div className="green">{apiData?.stateCode}</div>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      md={10}
                      sm={24}
                      xsm={24}
                      className="mb-1 small-screen-width"
                    >
                      <Form.Item
                        name="destinationAuditedValue"
                        label="Audited Value"
                        onKeyPress={(event) => {
                          if (checkNumbervalue(event)) {
                            event.preventDefault();
                          }
                        }}
                        validateStatus={validorNot?.validateStatus}
                        help={validorNot?.errorMsg}
                        rules={[
                          {
                            required: true,
                            message: "Please enter value",
                            validateTrigger: "onSubmit",
                          },
                          () => ({
                            validator(rule, value = "") {
                              if (value.length > 0 && value.length < 6) {
                                return Promise.reject(
                                  "Pincode should be 6 digits"
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },

                            validateTrigger: "onSubmit",
                          }),
                        ]}
                      >
                        <Input
                          placeholder="Enter value"
                          maxLength={6}
                          onChange={inputHandler}
                          className="accpickupPincode"
                          type="text"
                          suffix={
                            <div
                              className={
                                suffixStatus?.serviceability === "Regular"
                                  ? "green"
                                  : suffixStatus?.serviceability === " Premium"
                                  ? "orange"
                                  : "red"
                              }
                            >
                              {suffixStatus?.stateCode}
                            </div>
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              {filterFunction("documentPresent") && (
                <>
                  <div className="parent-heading">Document Present</div>
                  <hr className="divider m-0" />
                  <Row className="pt-1 global-form-row" gutter={20}>
                    <Col
                      md={10}
                      sm={24}
                      xsm={24}
                      className="mb-1 small-screen-width"
                    >
                      <Form.Item
                        name="documentPresentValueEnteredCP"
                        label="Value Entered CP"
                      >
                        <Radio.Group
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "20%",
                          }}
                          disabled={true}
                          rules={[
                            {
                              required: true,
                              message: "Please select value",
                            },
                          ]}
                          defaultChecked={false}
                        >
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col
                      md={10}
                      sm={24}
                      xsm={24}
                      className="mb-1 small-screen-width"
                    >
                      <Form.Item
                        name="documentPresentAuditedValue"
                        label="Audited Value"
                        rules={[
                          {
                            required: true,
                            message: "Please select value",
                          },
                        ]}
                      >
                        <Radio.Group
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "20%",
                          }}
                          rules={[
                            {
                              required: true,
                              message: "Please select value",
                            },
                          ]}
                        >
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              {filterFunction("travelBy") && (
                <>
                  <div className="parent-heading">Mode</div>
                  <hr className="divider m-0" />
                  <Row className="pt-1 global-form-row" gutter={20}>
                    <Col md={10} sm={24} xsm={24} className="mb-1">
                      <Form.Item
                        name="ModeDeclaredByClient"
                        label="Value Entered CP"
                      >
                        <Input
                          placeholder="Enter Weight"
                          maxLength={4}
                          type="text"
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={10} sm={24} xsm={24} className="mb-1">
                      <Form.Item
                        name="modeEnterByCp"
                        label="Audited value"
                        rules={[
                          {
                            required: true,
                            message: "Please select mode",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select a content"
                          optionFilterProp="children"
                          options={[
                            {
                              value: "Air",
                              label: "Air",
                            },
                            {
                              value: "Surface",
                              label: "Surface",
                            },
                          ]}
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>{" "}
                </>
              )}
              {filterFunction("shipment") && (
                <>
                  <div className="parent-heading">Shipment</div>
                  <hr className="divider m-0" />
                  <Row className="pt-1 global-form-row" gutter={20}>
                    <Col md={10} sm={24} xsm={24} className="mb-1">
                      <Form.Item
                        name="ShipmentDeclaredByClient"
                        label="Value Entered CP"
                      >
                        <Input
                          placeholder="Enter Weight"
                          maxLength={4}
                          type="text"
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={10} sm={24} xsm={24} className="mb-1">
                      <Form.Item
                        name="ShipmentAudited"
                        label="Audited value"
                        rules={[
                          {
                            required: true,
                            message: "Please select mode",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select a content"
                          optionFilterProp="children"
                          options={[
                            {
                              value: "dox",
                              label: "Dox",
                            },
                            {
                              value: "non-dox",
                              label: "Non-Dox",
                            },
                          ]}
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>{" "}
                </>
              )}
              {filterFunction("value") && (
                <>
                  <div className="parent-heading">Value of the shipment</div>
                  <hr className="divider m-0" />
                  <Row className="pt-1 global-form-row" gutter={20}>
                    <Col md={10} sm={24} xsm={24} className="mb-1">
                      <Form.Item
                        name="ValueDeclaredByClient"
                        label="Value Entered CP"
                      >
                        <Input
                          placeholder="Enter value"
                          maxLength={4}
                          type="text"
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={10} sm={24} xsm={24} className="mb-1">
                      <Form.Item
                        name="ValueAuditedCp"
                        label="Audited value"
                        rules={[
                          {
                            required: true,
                            message: "Please enter value",
                          },
                        ]}
                        onKeyPress={(event) => {
                          if (checkNumbervalue(event) || checkSpace(event)) {
                            event.preventDefault();
                          }
                        }}
                      >
                        <Input
                          placeholder="Enter value"
                          maxLength={7}
                          type="text"
                        />
                      </Form.Item>
                    </Col>
                  </Row>{" "}
                </>
              )}

              <div className="parent-heading">Remarks</div>

              <Row className="pt-1 global-form-row">
                <Col span={24} className="mb-1 small-screen-width">
                  <Form.Item name="remarks">
                    <TextArea
                      placeholder="Enter remarks"
                      autoSize={{
                        minRows: 3,
                        maxRows: 5,
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col span={24}>
                <hr className="divider m-0" />
              </Col>
              <Col span={24} className="flex-end p-1">
                <Button
                  className="cancel-btn w-13"
                  onClick={() => resetInputField("")}
                >
                  CANCEL
                </Button>

                <Button
                  form="myForm"
                  htmlType="submit"
                  disabled={loading}
                  className="save-btn ml-1 w-13"
                >
                  SAVE
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default AuditInputs;
