/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Form,
  Button,
  Table,
  Spin,
  message,
  Modal,
  From,
  Select,
  Space,
} from "antd";
import "./style.scss";
import API from "../../../api";
import urls from "../../../api/urls";
import { capitalize, convertDate } from "../../../utils";
import Heading from "../../../components/Heading";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getProfileDetails } from "../../../actionsMethods/masterMethods";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

const {
  GET_EWAY_VALIDITY,
  EXTEND_EWAY_VALIDITY,
  EWAYBILL,
  POST_EWAY_VALIDITY,
} = urls;

const ExtendEWayBill = ({ handleTabChange = () => {} }) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const [extendData, setExtendData] = useState({});
  const [distance, setDistance] = useState("");
  let userId = JSON.parse(localStorage.getItem("userId"));

  const profileDetails = useSelector(
    (state) => state.masterReducer.getProfileDetailsReducer,
    shallowEqual
  );

  const profileDetailsdata = profileDetails?.result?.data;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (record) => {
    setIsModalOpen(true);
    setExtendData(record);
    form.setFieldsValue({
      fromPincode: userId?.premises?.pincode,
      toPincode: record?.toPincode,
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setDistance("");
    setIsModalOpen(false);
  };

  const user_details = JSON.parse(window?.localStorage?.getItem("userinfo"));

  useEffect(() => {
    getTableData();
    dispatch(getProfileDetails(user_details?.username));
  }, []);

  let fun = (str) => {
    const date = new Date(str.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
    const formattedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()} ${date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;
    return formattedDate;
  };

  function getModifiedGST(item) {
    const gstNumber = item?.premises?.gst;
    const firstTwoDigits = gstNumber?.substring(0, 2);
    const modifiedDigits = firstTwoDigits?.startsWith("0")
      ? firstTwoDigits?.substring(1)
      : firstTwoDigits;
    return modifiedDigits;
  }

  const columns = [
    {
      title: "AWb No",
      dataIndex: "awbNumber",
      key: "awbNumber",
      render: (text, record) => (
        <span>
          {record?.childAwbNumber == null
            ? record?.awbNumber
            : record?.childAwbNumber}
        </span>
      ),
    },
    {
      title: "Booking Date",
      dataIndex: "bookingStartTime",
      key: "bookingStartTime",
      render: (text) => <span>{text !== null ? fun(text) : null}</span>,
    },

    {
      title: "E-way Number",
      dataIndex: "ewayBillNumber",
      key: "ewayBillNumber",
    },
    {
      title: "E-way Date Time",
      dataIndex: "ewayBillCreateDate",
      key: "ewayBillCreateDate",
      render: (text) => <span>{text !== null ? fun(text) : null}</span>,
    },
    {
      title: "Status",
      dataIndex: "bookingStatus",
      key: "bookingStatus",
      sorter: (a, b) => a.bookingStatus.localeCompare(b.bookingStatus),

      render: (text) => (
        <div
          style={{
            padding: "6px 2px",

            background: "#52C150",
            borderRadius: "3px",
            color: "#FFF",
          }}
          className="center fw-500"
        >
          {text}
        </div>
      ),
    },

    {
      title: "Valid Upto",
      dataIndex: "expiryDate",
      key: "expiryDate",
      render: (text) => <span>{text !== null ? fun(text) : null}</span>,
    },
    {
      title: "Action",
      // dataIndex: "expiryDate",
      // key: "expiryDate",
      render: (text, record) => {
        return (
          <div>
            <Button
              className=""
              // disabled={_data?.ewayUpdated}
              style={{
                borderRadius: "5px",
                color: "#2E77D0",
                background: "white",
              }}
              onClick={() => showModal(record)}
            >
              {/* <img src={whiteUpdateIcon} /> */}
              <span className="ml-1 fw-500">E-WAY BILL UPDATED</span>
            </Button>
          </div>
        );
      },
    },
  ];

  const getTableData = async () => {
    setLoading(true);
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    let date = year + "-" + month + "-" + day;
    let params = {
      fromDate: date,
    };
    let response = await API.Api(GET_EWAY_VALIDITY).getwithParams(params);
    if (response.status) {
      setLoading(false);
      setTableData(response.response.data);
    } else {
      setLoading(false);
    }
  };

  const [form] = Form.useForm();

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;

  const onSubmit = async (e) => {
    setLoading(true);
    const stateGST = getModifiedGST(userId);

    const temp = [];
    const obj = {
      ewbNo: extendData.ewayBillNumber,
      fromPlace: profileDetailsdata?.city,
      fromState: +stateGST,
      transDocDate: formattedToday,
      fromPincode: profileDetailsdata?.pincode,
      addressLine1: profileDetailsdata?.hubAddress,
      addressLine2: profileDetailsdata?.hubAddress,
      addressLine3: profileDetailsdata?.hubAddress,
      remainingDistance: +distance,
    };
    temp.push(obj);
    // for (let i = 0; i < selectedRowKeys.length; i++) {
    //   const obj = {
    //     ewbNo: selectedRowKeys[i].ewayBillNumber,
    //     fromPlace: profileDetailsdata?.city,
    //     fromState: +stateGST,
    //     transDocDate: formattedToday,
    //     fromPincode: profileDetailsdata?.pincode,
    //     addressLine1: profileDetailsdata?.hubAddress,
    //     addressLine2: profileDetailsdata?.hubAddress,
    //     addressLine3: profileDetailsdata?.hubAddress,
    //     remainingDistance: distance,
    //   };
    //   temp.push(obj);
    // }
    setLoading(false);

    let response = await API.Api(POST_EWAY_VALIDITY).patchIdHeader(temp);

    if (response?.response?.data?.status == "1") {
      setLoading(false);
      handleCancel();
      let x = response.response.data.data;
      setSelectedRowKeys([]);
      message.success("E-way bill extended successfully");
      getTableData();
    } else {
      setLoading(false);
      message.error(
        response?.response?.data?.data || "Failed to Update E-way bill"
      );
    }
  };

  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows, x) => {
  //     setSelectedRowKeys(selectedRowKeys);
  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === "Disabled User",
  //     name: record.name,
  //   }),
  // };

  // const hasSelected = selectedRowKeys.length > 0;

  return (
    // <Spin spinning={loading}>
    <div className="m-2">
      <Modal
        title="E-Way bill Validity Extension"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        // footer={
        //   <>
        //     <div className="d-flex flex-end">
        //       <Button className="cancel-btn w-20" onClick={handleCancel}>
        //         CANCEL
        //       </Button>
        //       <Button
        //         htmlType="submit"
        //         // form={form}
        //         onClick={form.submit}
        //         className="save-btn ml-1 w-20"
        //       >
        //         SAVE
        //       </Button>
        //     </div>
        //   </>
        // }
        footer={false}
      >
        <>
          <Form form={form} layout="vertical" className="global-form ">
            <Row className="global-form-row" gutter={16}>
              <Col lg={24}>
                <Form.Item name="fromPincode" label="From Pincode">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Form.Item name="toPincode" label="To Pincode ">
                  <Input value={extendData?.toPincode} disabled={true} />
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Form.Item name="distance" label="Distance">
                  <Input
                    value={distance}
                    onChange={(e) => setDistance(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className="center col-gap-10px pt-2 ">
            <Button
              className="back-button "
              style={{
                borderRadius: "4px",
                background: "#FFF",
                width: "100px",
                height: "35px",
                border: "1px solid #2E77D0",
                color: "#2E77D0",
              }}
            >
              {"NO"}
            </Button>
            <Button
              htmlType="submit"
              className="next-button "
              form="firmDetails"
              key="submit"
              style={{
                borderRadius: "4px",
                background: "#2E77D0",
                color: "#FFF",
                width: "100px",
                height: "35px",
              }}
              onClick={onSubmit}
            >
              {"YES"}
            </Button>
          </div>
        </>
      </Modal>
      <Heading title={"Extend E-way Validity"} />
      <div className="virtual-area-container p-2">
        {/* <Row justify="end mt-1">
            <Col>
              <Button
                className="save-btn mb-1"
                htmlType="submit"
                disabled={!hasSelected}
                // onClick={form.submit}
                onClick={showModal}
              >
                EXTEND EWAY VALIDITY
              </Button>
            </Col>
          </Row> */}
        <Table
          className="table-head-sticky"
          columns={columns}
          dataSource={tableData}
          pagination={false}
          rowKey={(record) => record}
          loading={{
            indicator: (
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            ),
            spinning: loading,
          }}
          // rowSelection={{
          //   ...rowSelection,
          // }}
        />
      </div>
    </div>
    // </Spin>
  );
};
export default ExtendEWayBill;
