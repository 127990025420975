/* eslint-disable indent */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  message,
  Select,
  Radio,
  DatePicker,
  Spin,
  Modal,
} from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  capitalize,
  checkAlphabets,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
} from "../../../../../utils";
import {
  getServiceablility,
  getClientOnboard,
} from "../../../../../actionsMethods/onboardingMethods";

import { getState } from "../../../../../actionsMethods/masterMethods";
import { APIs, getToken } from "../../../../../utils/worker";
import axios from "axios";
import API from "../../../../../api";
import urls from "../../../../../api/urls";
import { ReactComponent as Reason } from "../../../../../assests/images/SVG/check.svg";
import "../style.scss";
import cross_button from "../../../../../assests/images/CrossButton.svg";
import Smcs_Loader from "../../../../../assests/images/loader-new.gif";

const { VERIFY_GST, GET_HUB_PINCODE } = urls;

export const BusineesDetails = ({
  handleStepsChange = () => {},

  patchClient,
  getData,
}) => {
  const token = getToken();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [checkStatus, setCheckStatus] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isGSTRegister, setIsGSTRegister] = useState(true);
  const userId = JSON.parse(localStorage.getItem("userId"));

  useEffect(() => {
    if (getData) {
      setIsGSTRegister(getData?.organizationDetails?.gstRegistred);
      form.setFieldsValue({
        clientName: getData?.organizationDetails?.clientName,
        addressLine1: getData?.organizationDetails?.addressLine1,
        addressLine2: getData?.organizationDetails?.addressLine2,
        emailId: getData?.organizationDetails?.emailId,
        pan: getData?.organizationDetails?.pan,
        officialNumber: getData?.organizationDetails?.officialNumber,
        personalNumber: getData?.organizationDetails?.personalNumber,
        gst: getData?.organizationDetails?.gst,
        gstRegistred: getData?.organizationDetails?.gstRegistred,
        organizationName:
          getData?.organizationDetails?.organizationName ||
          getData?.organizationDetails?.clientName,
        pincode: getData?.organizationDetails?.pincode,
        city: getData?.organizationDetails?.city,
        state: getData?.organizationDetails?.state,
      });
    } else {
      form.setFieldValue("gstRegistred", true);
    }
  }, [getData]);

  const handlePincodeInput = async (values) => {
    if (values.target.value.length > 5) {
      setLoading(true);
      let response = await API.Api(
        GET_HUB_PINCODE + values?.target?.value
      ).getWithPremiseHeader();
      if (response?.status) {
        setLoading(false);
        form.setFieldValue("state", response?.response?.data?.stateName);
        form.setFieldValue("city", response?.response?.data?.city);
      } else {
        message.error("Invalid Pincode");
        setLoading(false);
        form.setFieldValue("state", null);
        form.setFieldValue("city", null);
      }
    }
  };

  const changeClientName = (event) => {
    let gstin = form.getFieldValue("gstin");
    if (!gstin) {
      form.setFieldValue("organizationName", event.target.value);
    }
  };

  const checkGST = async (event) => {
    if (event.target.value.length > 14) {
      let response = await API.Api(
        VERIFY_GST + event.target.value.toUpperCase()
      ).get();
      if (response.status) {
        if (response?.response?.data?.result) {
          setCheckStatus(true);
          form.setFieldValue(
            "organizationName",
            response?.response?.data?.result?.gstnDetailed?.tradeNameOfBusiness
          );
          form.setFieldValue("gstin", event.target.value.toUpperCase());
          let clientName = form.getFieldValue("clientName");
          if (
            clientName?.toLowerCase() !==
            response?.response?.data?.result?.gstnDetailed?.tradeNameOfBusiness.toLowerCase()
          ) {
            setModalVisible(true);
          }
        } else {
          form.setFieldValue(
            "organizationName",
            form.getFieldValue("clientName")
          );
        }
      } else {
        form.setFieldValue(
          "organizationName",
          form.getFieldValue("clientName")
        );
      }
    } else {
      setCheckStatus(false);
    }
  };
  const updateClientName = () => {
    let orgName = form.getFieldValue("organizationName");
    form.setFieldValue("clientName", orgName);
    setModalVisible(false);
  };

  const createBasicDetails = async (formValues) => {
    setLoading(true);
    let clientLeadId = localStorage.getItem("clientLeadId");
    if (clientLeadId === null) {
      try {
        let res = await axios.post(
          `${APIs.baseURL}/lead-service-client/v1/client/lead`,
          { organizationDetails: formValues },
          {
            headers: {
              Authorization: token,
              "SMCS-PREMISE-ID": userId?.userInfo?.premiseId,
            },
          }
        );
        if (res) {
          setLoading(false);
          message.success(res.message || "Client added successfully");
          localStorage.setItem("clientLeadId", res?.data?.clientLeadId);
          form.resetFields();
          handleStepsChange();
        }
      } catch (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      }
    } else {
      setLoading(false);
      patchClient({ organizationDetails: formValues }, clientLeadId);
    }
  };
  const cancelReset = () => {
    localStorage.removeItem("clientId");
    window.history.back();
  };

  function handleIsGSTRegistered(params) {
    setIsGSTRegister(params.target.value);
  }

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div>
        <Form
          className="global-form profile-container-form pl-4 pr-4 p-2"
          layout="vertical"
          form={form}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          onFinish={createBasicDetails}
        >
          <Row gutter={40} className="global-form-row">
            <Col span={24} className="mb-1">
              <span className="master-title">Organisation Details</span>
              <hr className="divider" />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="clientName"
                label="Client name"
                rules={[
                  {
                    message: "Please enter client name",
                    required: true,
                  },
                  () => ({
                    validator(rule, value = "") {
                      if (value.substring(1, 0) === " ") {
                        return Promise.reject("Invalid client name ");
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
                onChange={changeClientName}
                onKeyPress={(event) => {
                  if (checkAlphabets(event)) {
                    event.preventDefault();
                  }
                }}
              >
                <Input
                  // style={{ textTransform: "capitalize" }}
                  placeholder="Enter client name"
                  // disabled={getData ? true : false}
                  onInput={(e) => (e.target.value = capitalize(e.target.value))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="gstRegistred"
                label={"Is GST registred"}
                rules={[
                  {
                    required: true,
                    message: "Please select ",
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => handleIsGSTRegistered(e)}
                  className="calculater-container-div-radio"

                  // disabled={getData?.organizationDetails?.gst !== null && true}
                >
                  <Radio value={true} className="radioButton">
                    Yes
                  </Radio>
                  <Radio value={false} className="radioButton">
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {isGSTRegister && (
              <Col xs={20} sm={20} md={12} lg={12} xl={12}>
                <Form.Item
                  name="gst"
                  label="GSTIN"
                  rules={[
                    {
                      message: "Please enter GSTIN",
                      required: true,
                    },
                    {
                      pattern:
                        /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/g,
                      message: "Invalid GSTIN",
                    },
                  ]}
                  className="accpickupPincodeParent"
                >
                  <Input
                    className="accpickupPincode"
                    placeholder="Enter GSTIN"
                    suffix={checkStatus ? <Reason /> : ""}
                    maxLength={15}
                    onChange={checkGST}
                    // disabled={getData ? true : false}
                    onKeyPress={(e) => {
                      if (/[^A-Za-z0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onInput={(e) =>
                      (e.target.value = e.target.value.toUpperCase())
                    }
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="pan"
                label={
                  <span className="calculater-container-pikpin">
                    PAN number
                  </span>
                }
                onKeyPress={(event) => {
                  if (checkSpecialCharacter(event) || checkSpace(event)) {
                    event.preventDefault();
                  }
                }}
                rules={[
                  {
                    required: !isGSTRegister ? true : false,
                    message: "Enter value",
                  },
                  {
                    pattern: new RegExp(/([A-Z]){5}([0-9]){4}([A-Z]){1}$/g),
                    message: "Enter a valid PAN number",
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Input
                  placeholder="Enter PAN number"
                  // disabled={getData?.organizationDetails?.pan !== null && true}
                  type="text"
                  maxLength={10}
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item name="organizationName" label="Organisation name">
                <Input
                  // style={{ textTransform: "capitalize" }}
                  placeholder="Enter organisation name"
                  maxLength={30}
                  disabled
                  // onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="addressLine1"
                label="Address line 1"
                rules={[
                  {
                    message: "Please enter address line 1",
                    required: true,
                  },
                  () => ({
                    validator(rule, value = "") {
                      if (value.substring(1, 0) === " ") {
                        return Promise.reject("Invalid address line 1");
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Enter Address line 1"
                  // disabled={getData ? true : false}
                  maxLength={500}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="addressLine2"
                label="Address line 2"
                rules={
                  getData
                    ? []
                    : [
                        () => ({
                          validator(rule, value = "") {
                            if (value.substring(1, 0) === " ") {
                              return Promise.reject("Invalid address line 2");
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]
                }
              >
                <Input
                  placeholder="Enter Address line 2"
                  // disabled={getData ? true : false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="pincode"
                label="PIN code"
                rules={[
                  {
                    message: "Please enter pincode",
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Enter PIN code"
                  onChange={handlePincodeInput}
                  maxLength={6}
                  onKeyPress={(event) => {
                    if (
                      checkNumbervalue(event) ||
                      checkSpace(event) ||
                      checkSpecialCharacter()
                    ) {
                      event.preventDefault();
                    }
                  }}
                  // disabled={getData ? true : false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item name="city" label="City">
                <Input placeholder="Enter city" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    message: null,
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Enter state" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="emailId"
                label="Email-ID"
                rules={[
                  {
                    message: "Please enter email",
                    required: true,
                  },
                  {
                    type: "email",
                    message: "The input is not valid E-mail",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Email"
                  // disabled={getData ? true : false}
                  onInput={(e) =>
                    (e.target.value = e.target.value.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="officialNumber"
                label="Mobile number"
                rules={[
                  {
                    required: true,
                    message: "Please enter primary mobile number",
                  },
                  {
                    pattern: /^([1-9]{1}[0-9]{9}){0,10}$/g,
                    message: "Invalid mobile number",
                  },
                ]}
              >
                <Input
                  placeholder="Enter phone number"
                  onKeyPress={(e) => {
                    if (/[^0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                    if (e.target.value.length >= 10) {
                      e.preventDefault();
                    }
                  }}
                  disabled={getData ? true : false}
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="personalNumber"
                label="Secondary phone number"
                onKeyPress={(e) => {
                  if (/[^0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                  if (e.target.value.length >= 10) {
                    e.preventDefault();
                  }
                }}
                rules={[
                  {
                    pattern: /^([1-9]{1}[0-9]{9}){0,10}$/g,
                    message: "Invalid mobile number",
                  },
                ]}
              >
                <Input
                  placeholder="Enter secondary phone number"
                  maxLength={10}
                  onKeyPress={(e) => {
                    if (/[^0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                    if (e.target.value.length >= 10) {
                      e.preventDefault();
                    }
                  }}
                  // disabled={getData ? true : false}
                />
              </Form.Item>
            </Col>
          </Row>
          <hr className="divider" />
          <div className="flex-end mt-1">
            <Button className="cancel-btn ml-1 w-15" onClick={cancelReset}>
              CANCEL
            </Button>
            <Button
              htmlType="submit"
              className="save-btn w-15 ml-1"
              // onClick={handleStepsChange}
            >
              CONTINUE
            </Button>
          </div>
        </Form>
        <Modal
          open={modalVisible}
          footer={null}
          onCancel={() => setModalVisible(false)}
          width={380}
          destroyOnClose
          closeIcon={<img src={cross_button} />}

          // maskClosable={false}
        >
          <Row className="global-form-row center" gutter={37}>
            <div style={{ width: "380px", padding: "2rem" }}>
              <div className="mb-1">
                Do you want to update client name as per GST Validated name?
              </div>
              <div className="center">
                <Button
                  className="btn-style"
                  onClick={() => setModalVisible(false)}
                >
                  NO
                </Button>
                <Button
                  type="primary ml-1"
                  className="save-btn"
                  onClick={updateClientName}
                >
                  YES
                </Button>
              </div>
            </div>
          </Row>
        </Modal>
      </div>
    </Spin>
  );
};

export default BusineesDetails;
