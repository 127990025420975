import actions from "../constants/manifestActions";

export const getViewAllBookings = (pageNo,pageSize) => ({
  type: actions.GET_VIEWALLBOOKINGS,pageNo,pageSize
});

export const createManifest = (payload) => ({
  type: actions.CREATE_MANIFEST,
  payload,
});

export const getManifestTableData = (data, id) => ({
  type: actions.GET_MANIFEST,
  data,
  id,
});

export const clearGetManifestTable = (data, id) => ({
  type: actions.GET_MANIFEST_CLEAR,
  data,
  id,
});

export const getManifestVehicle = (data, id) => ({
  type: actions.GET_MANIFEST_VEHICLE,
  data,
  id,
});
export const updateManifestDriver = (data, id) => ({
  type: actions.PUT_MANIFEST_DRIVER,
  data,
  id,
});

export const clearManifestDriver = () => ({
  type: actions.PUT_MANIFEST_DRIVER_CLEAR,
});

export const clearCreatedManifest = () => ({
  type: actions.CREATE_MANIFEST_CLEAR,
});

export const getPacketBarcode = (payload, query) => ({
  type: actions.GET_PACKET_BARCODE,
  payload,
  query,
});

export const getEligiblePackets = (data, id) => ({
  type: actions.GET_ELIGIBLE_PACKET,
  data,
  id,
});

export const createPackets = (data) => ({
  type: actions.CREATE_PACKETS,
  data,
});

export const clearCreatePackets = () => ({
  type: actions.CREATE_PACKETS_CLEAR,
});

export const getPacketListBySatus = (data, q2) => ({
  type: actions.GET_PACKET_LIST_BY_STATUS,
  data,
  q2,
});

export const getPacketListClear = () => ({
  type: actions.GET_PACKET_LIST_BY_STATUS_CLEAR,
});

export const getShipmentByStatus = (data) => ({
  type: actions.GET_SHIPMENT_LIST_BY_STATUS,
  data,
});

export const patchPacketShipment = (data, q2) => ({
  type: actions.PATCH_PACKET_SHIPMENT,
  data,
  q2,
});

export const patchPacketShipmentClear = () => ({
  type: actions.PATCH_PACKET_SHIPMENT_CLEAR,
});

export const deleteShipment = (data) => ({
  type: actions.DELETE_SHIPMENT,
  data,
});

export const patchManifestScan = (data) => ({
  type: actions.PATCH_MANIFEST_SCAN,
  data,
});

export const updatePacketStatus = (query, data) => ({
  type: actions.UPDATE_PACKET_STATUS,
  query,
  data,
});

export const getManifestedList = (data) => ({
  type: actions.GET_MANIFESTED_LIST,
  data,
});

export const putManifestLock = (data, id) => ({
  type: actions.PUT_MANIFEST_LOCK,
  data,
  id,
});
export const putManifestLockClear = () => ({
  type: actions.PUT_MANIFEST_LOCK_CLEAR,
});

export const updateDriverDetails = (data, id) => ({
  type: actions.UPDATE_DRIVE_DETAILS,
  data,
  id,
});

export const getprintManifest = (data) => ({
  type: actions.PRINT_MANIFEST,
  data,
});

export const patchChildShipment = (awbNumber, data) => ({
  type: actions.PATCH_CHILDSHIPMENT,
  awbNumber,
  data,
});

export const patchChildShipmentClear = () => ({
  type: actions.PATCH_CHILDSHIPMENT_CLEAR,
});
