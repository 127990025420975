import { all, fork } from "redux-saga/effects";

import * as appWatcher from "./watchers/cashBookingWatcher";

import * as appManifestWatcher from "./watchers/manifestWatcher";

import * as masterWatcher from "./watchers/masterWatcher";

import * as onboardingWatcher from "./watchers/onboardingWatcher";

function* appRootSaga() {
  yield all([fork(appWatcher.loginUser)]);
  yield all([fork(appWatcher.getCpPremises)]);
  yield all([fork(appWatcher.getBookingId)]);
  yield all([fork(appWatcher.getBookingDetails)]);
  yield all([fork(appWatcher.getServiceAbilityCheck)]);
  yield all([fork(appWatcher.getAccountServiceWatcher)]);
  yield all([fork(appWatcher.updateAWBNumWatcher)]);
  yield all([fork(appWatcher.getSingleBookingWatcher)]);

  yield all([fork(appWatcher.onboardVehicleWatcher)]);
  yield all([fork(appWatcher.updateOnboardedVehicleWatcher)]);
  yield all([fork(appWatcher.disableOnboardedVehicleWatcher)]);
  yield all([fork(appWatcher.getSingleOnboarededVehicleWatcher)]);
  yield all([fork(appWatcher.getAllOnboarededVehiclesWatcher)]);
  // yield all([fork(appWatcher.accountPostShipmentWatcher)]);

  yield all([fork(appManifestWatcher.getViewAllBookingWatcher)]);
  yield all([fork(appManifestWatcher.createManifestWatcher)]);
  yield all([fork(appManifestWatcher.getManifestWatcher)]);
  yield all([fork(appManifestWatcher.getManifestVehicleWatcher)]);
  yield all([fork(appManifestWatcher.updateManifestDriverWatcher)]);
  yield all([fork(appManifestWatcher.getPacketBarcodeWatcher)]);
  yield all([fork(appManifestWatcher.getPacketListBySatusWatcher)]);
  yield all([fork(appManifestWatcher.getShipmentListBySatusWatcher)]);
  yield all([fork(appManifestWatcher.createPacketsWatcher)]);
  yield all([fork(appManifestWatcher.updatePacketStatusWatcher)]);
  yield all([fork(appManifestWatcher.getEligiblePacketsWatcher)]);
  yield all([fork(appManifestWatcher.patchPacketShipmentWatcher)]);
  yield all([fork(appManifestWatcher.deleteShipmentWatcher)]);
  yield all([fork(appManifestWatcher.patchManifestScanWatcher)]);
  yield all([fork(appManifestWatcher.getManifestedListWatcher)]);
  yield all([fork(appManifestWatcher.putManifestLockWatcher)]);
  yield all([fork(appManifestWatcher.updateDriverDetailsWatcher)]);
  yield all([fork(appManifestWatcher.getprintManifestWatcher)]);
  yield all([fork(appManifestWatcher.patchChildShipmentWatcher)]);

  //--Master--//
  yield all([fork(masterWatcher.getPincodeMasterTableWatcher)]);
  yield all([fork(masterWatcher.getPincodeWatcher)]);
  yield all([fork(masterWatcher.getStateWatcher)]);
  yield all([fork(masterWatcher.getserviceabilityWatcher)]);
  yield all([fork(masterWatcher.createPincodeWatcher)]);
  yield all([fork(masterWatcher.updatePincodeWatcher)]);
  yield all([fork(masterWatcher.getDefaultRateCardWatcher)]);
  yield all([fork(masterWatcher.getCategoryBasedValueWatcher)]);
  yield all([fork(masterWatcher.getPacketingGuideFromHublistWatcher)]);
  yield all([fork(masterWatcher.getPacketingGuideToHublistWatcher)]);
  yield all([fork(masterWatcher.getPacketingGuideFromCplistWatcher)]);
  yield all([fork(masterWatcher.getPacketingGuideToCplistWatcher)]);
  yield all([fork(masterWatcher.getPacketingGuideMasterWatcher)]);
  yield all([fork(masterWatcher.getPacketingGuideSearchWatcher)]);
  yield all([fork(masterWatcher.getPacketingGuideDownloadWatcher)]);
  yield all([fork(masterWatcher.getPacketConfigurationWatcher)]);
  yield all([fork(masterWatcher.putPacketConfigurationWatcher)]);
  yield all([fork(masterWatcher.postPacketingGuideMasterWatcher)]);
  yield all([fork(masterWatcher.putPacketingGuideMasterWatcher)]);
  yield all([fork(masterWatcher.createCashRateCardWatcher)]);
  yield all([fork(masterWatcher.getCashRateCardTemplatesWatcher)]);
  yield all([fork(masterWatcher.getCashRateCardDetailsWatcher)]);
  yield all([fork(masterWatcher.allocateCashRateCardWatcher)]);
  yield all([fork(masterWatcher.getExistingCashRateCardTemplatesWatcher)]);
  yield all([fork(masterWatcher.getPincodeMappingWatcher)]);
  yield all([fork(masterWatcher.getAllHubOnboardWatcher)]);
  yield all([fork(masterWatcher.searchPincodeMasterTableWatcher)]);
  yield all([fork(masterWatcher.disableMasterPincodeWatcher)]);
  yield all([fork(masterWatcher.deletePincodeAreaWatcher)]);
  yield all([fork(masterWatcher.premiseTableWatcher)]);

  yield all([fork(masterWatcher.patchOnboardFormWatcher)]);
  yield all([fork(masterWatcher.getprofileDetailsWatcher)]);
  yield all([fork(masterWatcher.saveprofileDetailsWatcher)]);
  yield all([fork(masterWatcher.getFromHubTatWatcher)]);
  yield all([fork(masterWatcher.getToHubTatWatcher)]);
  yield all([fork(masterWatcher.getFMLMTatWatcher)]);

  yield all([fork(masterWatcher.getHubListWatcher)]);
  yield all([fork(masterWatcher.createSortingGuideWatcher)]);
  yield all([fork(masterWatcher.getHubBasedSortingGuideWatcher)]);
  yield all([fork(masterWatcher.viewAllSortingGuideWatcher)]);
  //onboarding
  yield all([fork(masterWatcher.getAllSortingGuideWatcher)]);
  //client onboard
  yield all([fork(onboardingWatcher.patchClientOnboardWatcher)]);
  yield all([fork(onboardingWatcher.getClientOnboardWatcher)]);
  yield all([fork(onboardingWatcher.getClientPincodeMapping)]);
  yield all([fork(onboardingWatcher.getStaffOnboardWatcher)]);
  yield all([fork(onboardingWatcher.updateStaffOnboardWatcher)]);
}

export default appRootSaga;
