import React from "react";
import { Button, Form, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";

import passIcon from "../../../assests/images/SVG/passwordChanged.svg";

const ConfirmPassword = () => {
  const navigate = useNavigate();

  const onFinish = () => {
    navigate("/");
  };

  return (
    <>
      <div className="confirm-password">
        <Row>
          <div className="formIcon">
            <img src={passIcon} />
          </div>
        </Row>
        <div className="inner-content">
          <Row align="center" className="first-div">
            <Col span={24}>
              <div className="wlc-div">Password Changed!</div>
            </Col>
            <Col span={24}>
              <div className="msg">
                Your password has been successfully reset.
              </div>
              <div className="msg">Click below to log in</div>
            </Col>
          </Row>
          <Form
            initialValues={{
              username: "",
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="form"
          >
            <Form.Item>
              <div className="login-btn">
                <Button
                  className="loginBtn"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                >
                  CONTINUE
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ConfirmPassword;
