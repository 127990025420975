import React, { useState } from "react";
import AttachmentModal from "../../AttachmentModal/index";
import "./style.scss";

const ServiceNonDox = ({ allAppData }) => {
  // const childAwbNo = allAppData?.childShipmentDetails?.map((data) => {
  //   return `${data?.childAwbNumber}${data?.ewayBillNumber ? `(${data?.ewayBillNumber})` : ""}`;
  // });
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="service-summary">
      <AttachmentModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        dataFile={allAppData?.files}
      />
      <table>
        {/* left */}
        <tr>
          <td className="table-data top-left">
            <div className="heading">Type:</div>{" "}
            <div className="item">
              {allAppData?.shipmentDetails?.type
                ? allAppData?.shipmentDetails?.type
                : null}
            </div>{" "}
          </td>
          <td className="table-data">
            <div className="heading">Service :</div>{" "}
            <div className="item">{allAppData?.serviceDetails?.service}</div>{" "}
          </td>

          <td className="table-data">
            <div className="heading">Travel by :</div>{" "}
            <div className="item">{allAppData?.serviceDetails?.travelBy}</div>{" "}
          </td>
          <td className="table-data">
            <div className="heading">Content :</div>{" "}
            <div className="item">
              {allAppData?.shipmentDetails?.content +
                " - " +
                allAppData?.shipmentDetails?.description}
            </div>{" "}
          </td>
          {/* <td className="table-data ">
            <div className="heading">Description :</div>{" "}
            <div className="item">
              {allAppData?.shipmentDetails?.description || ""}
            </div>{" "}
          </td> */}
          <td className="table-data ">
            <div className="heading">Value:</div>{" "}
            <div className="item">{allAppData?.shipmentDetails?.value}</div>{" "}
          </td>

          <td className="table-data">
            <div className="heading">Uploaded files :</div>{" "}
            <div className="item">
              {allAppData?.files?.length > 0 ? (
                <a onClick={() => setIsModalOpen(true)}>
                  {allAppData?.files?.length} Attachment(s)
                </a>
              ) : (
                `${allAppData?.files?.length} Attachment(s)`
              )}
            </div>
            {/* <a className="item">{allAppData?.files?.length} attachment(s)</a> */}
          </td>
          <td className="table-data no-packet">
            <div className="content">
              {" "}
              <div className="heading">No of child packets :</div>{" "}
              <div className="item">
                {allAppData?.childShipmentDetails?.length || 0}
              </div>
            </div>
            {/* <div className="itemone">{childAwbNo?.join(", ") || ""}</div>{" "} */}
          </td>
        </tr>

        {/* //right */}
        <tr>
          <td className="table-data top-right">
            <div className="heading">Weight (Kg) :</div>{" "}
            <div className="item">{allAppData?.shipmentDetails?.weight}</div>{" "}
          </td>
          <td className="table-data">
            <div className="heading">Length (Cm) :</div>{" "}
            <div className="item">{allAppData?.shipmentDetails?.length}</div>{" "}
          </td>
          <td className="table-data">
            <div className="heading">Width (Cm) :</div>{" "}
            <div className="item">{allAppData?.shipmentDetails?.width}</div>{" "}
          </td>
          <td className="table-data">
            <div className="heading">Height (Cm) :</div>{" "}
            <div className="item">{allAppData?.shipmentDetails?.height}</div>{" "}
          </td>
          <td className="table-data">
            <div className="heading">Vol weight (Kg) :</div>{" "}
            <div className="item">
              {allAppData?.shipmentDetails?.volumetricWeight}
            </div>{" "}
          </td>
          <td className="table-data">
            <div className="heading">E-Way bill no :</div>{" "}
            <div className="item">
              {allAppData?.ewaybills
                ?.map((val) => val?.ewayBillNumber)
                .join(", ") || "N/A"}
            </div>{" "}
          </td>
          <td className="table-data">
            <div className="heading">Sender’s GST number :</div>{" "}
            <div className="item">
              {allAppData?.addressDetails?.senderGst
                ? allAppData?.addressDetails?.senderGst
                : "N/A"}
            </div>{" "}
          </td>
          {/* <td className="table-data child-packet">
            <div className="heading">Child packet tracking no :</div>{" "}
            <div className="item">{childAwbNo?.join(", ") || ""}</div>{" "}
          </td> */}
        </tr>
      </table>
    </div>
  );
};

export default ServiceNonDox;
