import React from "react";
import { Row,Col, Form, Select,Button,Table } from "antd";
import { serialNum } from "../../utils";


const PrsGeneration = () => {
  const { Option } = Select;
  let data = [
    {
      value: "jack",
      label: "Jack",
    },
    {
      value: "lucy",
      label: "Lucy",
    },
    {
      value: "tom",
      label: "Tom",
    },
  ];
  const columns = [
    {
      title: "Sl No",
      align: "center",
      with: 10,
      render: (a, b, idx) => serialNum(10, 1, idx),
    },
    {
      title: "AWB Number",
      dataIndex: "AWB Number",
      key: "AWBNumber",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Order ID",
      dataIndex: "Order ID",
      key: "Order ID",
    },
    {
      title: "Pickup Pincode",
      dataIndex: "Pickup Pincode",
      key: "Pickup Pincode",
    },
    {
      title: "Booking Seller Details",
      dataIndex: "Booking Seller Details",
      key: "Booking Seller Details",
    },
    {
      title: "Shipment Details",
      dataIndex: "Shipment Details",
      key: "Shipment Details",
    },
  ];
  return (
    <div className="prs-container master-main-container">
      <div className="header p-1">
        <b>Generate Pickup Run Sheet</b>
      </div>
      <Form 
        className="p-2"
        layout= "vertical"
        style={{width:"85%",margin:"auto"}}
      >
        <Row gutter={12}>
          <Col span={8} >
            <Form.Item
              colon = {false}
              label = "Select Field Executive"
              name="fieldExecutive"
            >
              <Select
                className="input-field-style"
                placeholder="Select Field Executive"
              >
                {data?.map((data,index)=>
                  <Option key={index}>
                    {data?.value}
                  </Option>
                )}
                
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} >
            <Form.Item
              colon = {false}
              label = "Select Pickup Pincode"
              name="pickupPincode"
            >
              <Select
                className="input-field-style"
                placeholder="Select Pickup Pincode"
              >
                {data?.map((data,index)=>
                  <Option key={index}>
                    {data?.value}
                  </Option>
                )}
                
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} >
            <Form.Item
              colon = {false}
              label = "Select Seller"
              name="selectSeller"
            >
              <Select
                className="input-field-style"
                mode="multiple"
                placeholder="Select Seller"
              >
                {data?.map((data,index)=>
                  <Option key={index}>
                    {data?.value}
                  </Option>
                )}
                
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <hr className="divider" />
        <Row justify="end">
          <Button type="primary" htmlType="submit" className="save-btn">
           INITIATE PRS
          </Button>
        </Row>
      </Form>
      <Table columns={columns} dataSource={""} />
      <Row justify="end" className="p-1">
        <Button className="save-btn">GENERATE PRS</Button>
      </Row>
    </div>
  );
};

export default PrsGeneration;
