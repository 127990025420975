/* eslint-disable no-case-declarations */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { AutoComplete, Form } from "antd";
import {
  checkAlphabets,
  checkSpecialCharacter,
  firstLetterCapitaliseDropDown,
} from "../../../utils";

export default function AutoSelectAndType({ reviverNameAndAddress, form }) {
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [dropDownChanges, setdropDownChanges] = useState(false);

  useEffect(() => {
    setOptions(
      reviverNameAndAddress?.map((val) => {
        return {
          ...val,
          value: val?.userId,
          key: val?.userId,
          id: val?.userId,
          label: val?.name + "-" + val?.mobileNumber?.toString(),
          name: val?.name,
        };
      })
    );
  }, [reviverNameAndAddress]);

  useEffect(() => {
    document.querySelectorAll(".ant-select-item-option").forEach((option) => {
      option.classList.remove("ant-select-item-option-active");
    });
  }, [dropDownChanges]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const onSelect = (e, b) => {
    if (e == "keyDown") {
      form.setFieldsValue({
        receiverAddress: b?.address,
        mobileNumber: b?.mobileNumber,
        receiverEmail: b?.emailAddress,
      });
    } else {
      const findAddress = options?.find((obj) => obj?.userId === +b.key);

      form.setFieldsValue({
        receiverName: firstLetterCapitaliseDropDown(findAddress?.name),
        receiverAddress: findAddress?.address,
        mobileNumber: findAddress?.mobileNumber,
        receiverEmail: findAddress?.emailAddress,
      });
    }
  };

  const handleKeyDown = (event, dataOptions, formItemName) => {
    switch (event.key) {
      case "Tab":
        const activeOption = document.querySelector(
          ".ant-select-item-option-active"
        );

        if (activeOption) {
          setdropDownChanges(!dropDownChanges);
          const id = activeOption.id;

          const optionExists = dataOptions?.find((e) => e.userId === +id);
          if (optionExists) {
            form.setFieldsValue({ [formItemName]: optionExists?.name });
          }
          onSelect("keyDown", optionExists);
        }
        break;
      default:
        break;
    }
  };

  const onChangeHandler = (value, b) => {
    if (value !== "") {
      if (typeof value !== "number") {
        const filteredData = options?.filter((item) =>
          item?.name?.toLowerCase()?.includes(value?.toLowerCase())
        );
        setFilteredOptions(filteredData);
      } else {
        const filteredData = options?.find((item) => item?.userId == value);
        form.setFieldValue(
          ["receiverName"],
          firstLetterCapitaliseDropDown(filteredData.name)
        );
      }
    } else {
      setFilteredOptions(options);
    }
  };

  return (
    <Form.Item
      label="Receiver Name"
      id="rName"
      name="receiverName"
      rules={[
        {
          required: true,
          message: "Enter receiver name",
        },
      ]}
      onKeyPress={(event) => {
        event.target.value = firstLetterCapitaliseDropDown(
          event?.target?.value
        );
        if (event.code == "Enter") {
          document.getElementById("submit_credit").blur();
        }
      }}
    >
      <AutoComplete
        showSearch
        onKeyDown={(event) =>
          handleKeyDown(event, filteredOptions, "receiverName")
        }
        onChange={onChangeHandler}
        onSelect={onSelect}
        placeholder="Enter receiver name"
        options={filteredOptions}
        // onBlur={(e) =>
        //   (e.target.value = firstLetterCapitaliseDropDown(e.target.value))
        // }
      >
        {/* {filteredOptions?.map((option) => (
          <AutoComplete.Option
            key={option?.userId}
            value={option?.userId}
            id={option?.userId}
          >
            {firstLetterCapitaliseDropDown(option?.name)} -{" "}
            {option?.mobileNumber}
          </AutoComplete.Option>
        ))} */}
      </AutoComplete>
    </Form.Item>
  );
}
