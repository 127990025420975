/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import Heading from "../../components/Heading";
import {
  Row,
  Col,
  Form,
  Spin,
  Select,
  Radio,
  DatePicker,
  Space,
  Button,
  Input,
  Switch,
  message,
} from "antd";
import "./style.scss";
import moment from "moment";
import { ReactComponent as Mines } from "../../assests/images/addInputCollectionMinuesBtn.svg";
import { ReactComponent as Pencial } from "../../assests/images/pencialIcon.svg";
import { ReactComponent as Plus } from "../../assests/images/inputCollectionPlusBtn.svg";
import { HeaderWithBottomLine } from "../Master/CpOnbordingSteps/HeaderWithBottomLine";
import { ReactComponent as DeleteIcon } from "../../assests/images/deleteIcon.svg";
import {
  checkSpecialCharacter,
  checkSpace,
  checkNumbervalue,
  dateFormateSlashFormat,
  capitaliseWithHyphen,
  camelCase,
} from "../../utils";
import urls from "../../api/urls";
import API from "../../api";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export function BillingConfiguration() {
  const params = useLocation();
  const navigate = useNavigate();
  const { GET_BILLING_NAME_TYPE_CYCLE, GET_CP_RATECARD } = urls;
  const [form] = Form.useForm();
  const [radioGrup, setRadioGrup] = useState("");
  const [radioCp, setRadioCp] = useState("");
  const [rateCardType, setRateCardType] = useState("");
  const [loading, setLoading] = useState(false);
  const [editingNameIndex, setEditingNameIndex] = useState(null);
  const [newName, setNewName] = useState(null);
  const [basicComponent, setBasicComponent] = useState([]);
  const [disabledMode, setDisabledMode] = useState(false);
  const [additionalComponents, setAdditionalComponents] = useState([]);
  const arr = [
    {
      componentName: "Name3",
      component: [
        {
          name: "Name4",
          chargeType: null,
          value: 10,
          appliedTo: null,
          addOrSub: null,
          appliedType: null,
          active: false,
        },
        {
          name: "Name5",
          chargeType: null,
          value: 10,
          appliedTo: null,
          addOrSub: null,
          appliedType: null,
          active: false,
        },
      ],
    },
    {
      componentName: "Name6",
      component: [
        {
          name: "Name7",
          chargeType: null,
          value: 10,
          appliedTo: null,
          addOrSub: null,
          appliedType: null,
          active: false,
        },
        {
          name: "Name8",
          chargeType: null,
          value: 10,
          appliedTo: null,
          addOrSub: null,
          appliedType: null,
          active: false,
        },
      ],
    },
  ];
  const [pieceRateCardeCharges, setPieceRateCardeCharges] = useState([
    {
      fromRange: "0",
      toRange: "100000",
      chargeType: "Absolute",
      value: 10,
    },
    {
      fromRange: "100000",
      toRange: "above",
      chargeType: "Percentage",
      value: 12,
    },
  ]);
  const [cpOrDp, setCpOrDp] = useState([]);
  useEffect(() => {
    if (params.state) {
      getBillingJson(params.state);
    } else {
      getBasicComponent();
    }
  }, [params.id]);

  const onChangePieceRateCard = (value, index) => {
    const toUpdate = [...pieceRateCardeCharges];
    toUpdate[index].value = value;
    setPieceRateCardeCharges(toUpdate);
  };

  const getCpRateCard = async (event) => {
    setRadioCp(event);
    if (event == "cp") {
      let response = await API.Api(GET_CP_RATECARD + event).getWithAllHeaders();
      if (response.status) {
        let res = response?.response?.data?.data.map((e) => {
          return {
            label: e,
            value: e,
          };
        });
        setCpOrDp(res);
      }
    } else {
      setCpOrDp([]);
    }
  };

  const getBasicComponent = async () => {
    setLoading(true);
    let res = await API.Api(
      GET_BILLING_NAME_TYPE_CYCLE + "/basic-components"
    ).getWithAllHeaders();
    if (res.status) {
      const response = res.response?.data?.data;

      setBasicComponent(response);
      setAdditionalComponents(arr);
      setLoading(false);
    } else {
      message.error("Somthing went wrong");
      setLoading(false);
    }
  };

  const getBillingJson = async (name) => {
    setLoading(true);
    let res = await API.Api(
      GET_BILLING_NAME_TYPE_CYCLE + "/" + name
    ).getWithAllHeaders();
    if (res.status) {
      const { data } = res.response;
      const { billingConfigJson } = data;
      const transformedArray = billingConfigJson?.basicComponent.map(
        (item, index) => ({
          basicComponentId: index + 1,
          basicComponentName: item.basicComponentName,
          basicComponentActive: item?.active,
          default: !item?.isDefault,
        })
      );
      setBasicComponent(transformedArray);
      setAdditionalComponents(billingConfigJson?.additionalComponent);
      setPieceRateCardeCharges(billingConfigJson?.pieceSoftwareCharge);
      const { billingType } = billingConfigJson;
      setRadioGrup(billingType?.billingCycle);
      form.setFieldValue("radio", billingType?.billingCycle);
      const { templateName, appliedType, bussType, rateCardType } = data;
      setRadioCp(bussType);

      form.setFieldsValue({
        templateName,
        appliedType,
        bussType,
        rateCardType,
      });
      setDisabledMode(true);
      setLoading(false);
    } else {
      message.error("Somthing went wrong");
      setLoading(false);
    }
  };

  const updateOrAddObject = (value, nameOfComponent, toAddRemoveChange) => {
    const toUpdate = [...basicComponent];

    const actions = {
      remove: (indexToRemove) => {
        toUpdate.splice(indexToRemove, 1);
        setBasicComponent(toUpdate);
      },
      changeStatus: () => {
        const index = basicComponent.findIndex(
          (e) => e.basicComponentName === nameOfComponent
        );
        if (index !== -1) {
          toUpdate[index].basicComponentActive = value;
          setBasicComponent(toUpdate);
        }

        let data = form.getFieldsValue();
        for (const key in data) {
          if (data[key] == camelCase(nameOfComponent)) {
            form.setFieldValue(key, null);
          }
        }

        let newArr = additionalComponents.map((item) => {
          let component = item.component.map((subItem) => {
            if (
              subItem?.appliedTo?.toLowerCase() ===
              nameOfComponent?.toLowerCase()
            ) {
              return { ...subItem, appliedTo: null };
            } else if (
              subItem?.appliedType?.toLowerCase() ===
              nameOfComponent?.toLowerCase()
            ) {
              return { ...subItem, appliedType: null };
            } else {
              return { ...subItem };
            }
          });

          return { componentName: item.componentName, component };
        });
        setAdditionalComponents(newArr);
        //   const update = [...additionalComponents];
        // let upDatedState = update.map((item) => {
        //   let component = item.component.map((subItem) => ({
        //     ...subItem,
        //     appliedTo:
        //       subItem.appliedTo === nameOfComponent
        //         ? null
        //         : subItem.appliedTo,
        //   }));
        //   return { componentName: item.componentName, component };
        // });

        // setAdditionalComponents(upDatedState);

        // setAdditionalComponents((prevState) => {
        //   const cloneAdditional = [...prevState];
        // });
      },
      add: () => {
        setBasicComponent((prevComponent) => [
          ...prevComponent,
          {
            basicComponentName: nameOfComponent,
            basicComponentActive: false,
            default: true,
          },
        ]);
      },
    };

    const actionFunction = actions[toAddRemoveChange];
    if (actionFunction) {
      actionFunction(value);
    }
  };

  const handleAdditionalComponents = (action, ...args) => {
    const toUpdate = [...additionalComponents];

    const actions = {
      add: (index) => {
        toUpdate[index].component.push({
          name: "name",
          chargeType: "absolute",
          value: 10,
          appliedTo: "Cash Business",
          addOrSub: "add",
          appliedType: "Cash Business",
          active: true,
        });
        return toUpdate;
      },
      remove: (i, itemIndex) => {
        if (toUpdate[i].component.length === 1) {
          toUpdate.splice(i, 1);
        } else {
          toUpdate[i].component.splice(itemIndex, 1);
        }
        return toUpdate;
      },
      switch: (event, additionalComponentsIndex, additionalItemIndex) => {
        toUpdate[additionalComponentsIndex].component[
          additionalItemIndex
        ].active = event;

        return toUpdate;
      },
    };

    const actionFunction = actions[action];
    if (actionFunction) {
      const result = actionFunction(...args);
      setAdditionalComponents(result);
    }
  };

  const handleChargeTypeChange = (value, i, index, key) => {
    const updatedAdditionalComponents = [...additionalComponents];
    updatedAdditionalComponents[i].component[index][key] = value;
    setAdditionalComponents(updatedAdditionalComponents);
  };

  const addNewComponent = () => {
    const newObject = {
      componentName: "new name",
      component: [
        {
          name: "new name",
          chargeType: "absolute",
          value: 10,
          appliedTo: null,
          addOrSub: "add",
          appliedType: null,
          active: false,
        },
      ],
    };
    const toUpdate = [...additionalComponents];
    // toUpdate.push();
    toUpdate.unshift(newObject);
    setAdditionalComponents(toUpdate);
  };

  const deleteComponent = (index) => {
    const toUpdate = [...additionalComponents];
    toUpdate.splice(index, 1);
    setAdditionalComponents(toUpdate);
  };

  const handleEditName = (additionalComponentsIndex, additionalItemIndex) => {
    const components =
      additionalComponents[additionalComponentsIndex].component;
    const nameToUpdate =
      additionalItemIndex !== null
        ? components[additionalItemIndex].name
        : additionalComponents[additionalComponentsIndex].componentName;

    setEditingNameIndex(
      additionalItemIndex !== null
        ? `${additionalComponentsIndex}_${additionalItemIndex}`
        : additionalComponentsIndex
    );
    setNewName(nameToUpdate);
  };

  const handleEditNameBasic = (index) => {
    setEditingNameIndex(index);
    const numbersArray = index.match(/\d+/g);
    setNewName(basicComponent[numbersArray].basicComponentName);
  };

  const handleEditSaveBasic = (index) => {
    if (newName) {
      const numbersArray = index.match(/\d+/g);
      const updateBasice = [...basicComponent];
      updateBasice[numbersArray].basicComponentName = newName;
      setBasicComponent(updateBasice);
      setEditingNameIndex(null);
    } else {
      message.error("Input should not be empty");
    }
  };

  const handleSaveName = (additionalComponentsIndex, additionalItemIndex) => {
    if (!newName) {
      message.error("Input should not be empty");
      return;
    }
    const updatedAdditionalItem = [...additionalComponents];
    if (additionalItemIndex !== null) {
      updatedAdditionalItem[additionalComponentsIndex].component[
        additionalItemIndex
      ].name = newName;
    } else {
      updatedAdditionalItem[additionalComponentsIndex].componentName = newName;
    }
    setAdditionalComponents(updatedAdditionalItem);
    setEditingNameIndex(null);
  };

  const onSubmit = async (value) => {
    const { templateName, bussType, rateCardType } = value;
    setLoading(true);

    let billingConfigJson = {
      billingType: {
        billingCycle: value?.radio,
      },
      basicComponent: basicComponent.map((item) => ({
        basicComponentName: item.basicComponentName,
        active: item.basicComponentActive,
        isDefault: item.basicComponentId ? true : false,
      })),
      additionalComponent: additionalComponents,
      pieceSoftwareCharge: pieceRateCardeCharges,
    };

    if (value?.radio === "custom") {
      billingConfigJson.billingType.fromDate = moment(value?.fromDate).format(
        dateFormateSlashFormat
      );
      billingConfigJson.billingType.toDate = moment(value?.toDate).format(
        dateFormateSlashFormat
      );
    }

    let postPayload = {
      templateName,
      bussType,
      rateCardType,
      billingConfigJson,
    };

    const endpoint = params?.state
      ? `${GET_BILLING_NAME_TYPE_CYCLE}/${params.state}`
      : GET_BILLING_NAME_TYPE_CYCLE;
    const response = await (params?.state
      ? API.Api(endpoint).putIdHeader(billingConfigJson)
      : API.Api(endpoint).postIdHeader(postPayload));

    if (response?.status) {
      setLoading(false);
      navigate("/rate-card-list");

      message.success(response?.response?.data?.data);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  const appliedTOoptionsFun = () => {
    const options = basicComponent
      ?.filter((e) => e.basicComponentActive)
      .map((item) => ({
        value: capitaliseWithHyphen(item?.basicComponentName),
        label: capitaliseWithHyphen(item?.basicComponentName),
      }));
    return options;
  };

  return (
    <Spin spinning={loading}>
      <div className="p-2 billing-configuration">
        <Heading title={"Billing Configuration Template"} />
        <div className="billing-configuration-layout">
          <div className="center">
            <div className="billing-configuration-container pt-2">
              <Form
                layout={"vertical"}
                form={form}
                onFinish={onSubmit}
                autoComplete="off"
              >
                <Row gutter={12}>
                  <Col lg={8} md={12} xs={24} xl={8} sm={24}>
                    <Form.Item
                      name="templateName"
                      label={"Template name"}
                      rules={[
                        {
                          required: true,
                          message: "Please enter value",
                        },
                      ]}
                    >
                      <Input
                        className="input-field-style"
                        type="text"
                        placeholder="Enter template name"
                        disabled={disabledMode}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} xs={24} xl={6} sm={24} className="center">
                    <Form.Item
                      label="Template applied to"
                      rules={[
                        {
                          required: true,
                          message: "Please enter value",
                        },
                      ]}
                      name="bussType"
                    >
                      <Radio.Group
                        size="large"
                        style={{ display: "flex", columnGap: "20px" }}
                        onChange={(e) => getCpRateCard(e.target.value)}
                        disabled={disabledMode}
                      >
                        <Radio value="cp"> CP </Radio>
                        <Radio value="dp"> DP </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={12} xs={24} xl={8} sm={24}>
                    {cpOrDp.length !== 0 && (
                      <Form.Item
                        name="rateCardType"
                        label={"Select CP"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter value",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select a person"
                          optionFilterProp="children"
                          className="input-field-style"
                          options={cpOrDp}
                          onChange={(e) => setRateCardType(e)}
                          disabled={disabledMode}
                        />
                      </Form.Item>
                    )}
                    {disabledMode && radioCp == "cp" ? (
                      <Form.Item
                        name="rateCardType"
                        label={"Select CP"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter value",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select a person"
                          optionFilterProp="children"
                          className="input-field-style"
                          // options={cpOrDp}
                          disabled={disabledMode}
                        />
                      </Form.Item>
                    ) : null}
                  </Col>
                </Row>
                <div className="billing-configuration-container-box mt-5">
                  <div className="billing-configuration-container-box-header">
                    Billing Cycle
                  </div>
                  <div className="billing-configuration-container-box-container p-2">
                    <Row gutter={12} className="center">
                      <Col xl={12} lg={12}>
                        <Form.Item name="radio">
                          <Radio.Group
                            name="billingCycle"
                            value={radioGrup}
                            onChange={(e) => setRadioGrup(e.target.value)}
                          >
                            <Space direction={"horizontal"} size={30}>
                              <Radio value={"weekly"}>Weekly</Radio>
                              <Radio value={"fortnightly"}>Fortnightly</Radio>
                              <Radio value={"monthly"}>Monthly</Radio>
                              <Radio value={"custom"}>Custom</Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      {radioGrup === "custom" && (
                        <>
                          <Col xl={4} lg={4}>
                            <Form.Item name="fromDate" label="From">
                              <DatePicker className="input-field-style" />
                            </Form.Item>
                          </Col>
                          <Col xl={4} lg={4}>
                            <Form.Item name="toDate" label="To">
                              <DatePicker className="input-field-style" />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Row>
                  </div>
                </div>
              </Form>
              <Form form={form} onFinish={onSubmit} autoComplete="off">
                <div className="billing-configuration-container-box mt-5">
                  <div className="billing-configuration-container-box-header">
                    Collection Component
                  </div>
                  <div className="billing-configuration-container-box-container  p-1">
                    <HeaderWithBottomLine
                      text={
                        <div style={{ paddingTop: "10px" }}>
                          Basic Component
                        </div>
                      }
                      icon={false}
                    >
                      <Button
                        style={{
                          background: "none",
                          border: " 1px solid #2E77D0",
                          color: "#2E77D0",
                          width: "auto",
                          margin: "10px 0",
                          borderRadius: "4px",
                        }}
                        className="child-baggage-modal-inputList-button-style"
                        onClick={addNewComponent}
                        type="dashed"
                      >
                        <div className="">+ Add Component</div>
                      </Button>
                    </HeaderWithBottomLine>
                    <Row gutter={[18, 32]} className="p-1">
                      {basicComponent?.map((basicItem, sectionIndex) => (
                        <Col lg={8} xl={8} key={sectionIndex}>
                          <Row gutter={[16, 16]}>
                            <Col lg={10} xl={10} className="flex-start">
                              <div className="billing-configuration-container-box-label">
                                {editingNameIndex === sectionIndex + "index" ? (
                                  <>
                                    <Input
                                      className="input-field-style"
                                      type="text"
                                      value={newName}
                                      onChange={(e) =>
                                        setNewName(e.target.value)
                                      }
                                      suffix={
                                        <Button
                                          type="primary"
                                          size="small"
                                          onClick={() =>
                                            handleEditSaveBasic(
                                              sectionIndex + "index",
                                              null
                                            )
                                          }
                                        >
                                          save
                                        </Button>
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    {capitaliseWithHyphen(
                                      basicItem?.basicComponentName
                                    )}
                                  </>
                                )}
                              </div>
                            </Col>
                            <Col lg={3} xl={3} className="center">
                              {basicItem?.default &&
                                (editingNameIndex ===
                                sectionIndex + "index" ? null : (
                                  <>
                                    <Button
                                      type="text"
                                      onClick={() =>
                                        handleEditNameBasic(
                                          sectionIndex + "index",
                                          null
                                        )
                                      }
                                      icon={<Pencial />}
                                    />
                                  </>
                                ))}
                            </Col>
                            <Col lg={4} xl={4} className="center">
                              <Switch
                                checked={basicItem.basicComponentActive}
                                onChange={(e) =>
                                  updateOrAddObject(
                                    e,
                                    basicItem.basicComponentName,
                                    "changeStatus"
                                  )
                                }
                              />
                            </Col>
                            <Col lg={3} xl={3} className="center">
                              {basicItem?.default && (
                                <Button
                                  type="text"
                                  icon={<Mines />}
                                  onClick={() =>
                                    updateOrAddObject(
                                      sectionIndex,
                                      null,
                                      "remove"
                                    )
                                  }
                                />
                              )}
                            </Col>
                            {basicComponent.length - 1 === sectionIndex && (
                              <Col lg={2} xl={2} className="center">
                                <Button
                                  type="text"
                                  icon={<Plus />}
                                  onClick={() =>
                                    updateOrAddObject(true, "Enter text", "add")
                                  }
                                />
                              </Col>
                            )}
                          </Row>
                        </Col>
                      ))}
                    </Row>
                    {additionalComponents?.map((additionalItem, i) => (
                      <div key={i} style={{ paddingTop: "10px" }}>
                        <HeaderWithBottomLine
                          text={
                            editingNameIndex === i ? (
                              <Input
                                className="input-field-style"
                                type="text"
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                suffix={
                                  <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => handleSaveName(i, null)}
                                  >
                                    save
                                  </Button>
                                }
                              />
                            ) : (
                              <>
                                {additionalItem?.componentName}
                                <Button
                                  type="text"
                                  icon={<Pencial />}
                                  onClick={() => handleEditName(i, null)}
                                />
                              </>
                            )
                          }
                          icon={false}
                        >
                          <Button
                            type="text"
                            icon={<DeleteIcon />}
                            onClick={() => deleteComponent(i)}
                          />
                        </HeaderWithBottomLine>
                        <Row gutter={[16, 16]} className="p-1">
                          {additionalItem?.component?.map((item, index) => (
                            <React.Fragment key={index}>
                              <Col
                                lg={3}
                                xl={4}
                                md={8}
                                sm={2}
                                className="flex-start"
                              >
                                <div className="billing-configuration-container-box-label">
                                  {editingNameIndex === `${i}_${index}` ? (
                                    <Input
                                      className="input-field-style"
                                      type="text"
                                      value={newName}
                                      onChange={(e) =>
                                        setNewName(e.target.value)
                                      }
                                      suffix={
                                        <Button
                                          type="primary"
                                          size="small"
                                          onClick={() =>
                                            handleSaveName(i, index)
                                          }
                                        >
                                          save
                                        </Button>
                                      }
                                    />
                                  ) : (
                                    <>
                                      {item.name}
                                      <Button
                                        type="text"
                                        icon={<Pencial />}
                                        onClick={() => handleEditName(i, index)}
                                      />
                                    </>
                                  )}
                                </div>
                              </Col>{" "}
                              <Col lg={3} xl={3} md={7} sm={2}>
                                <Form.Item
                                  name={`chargeType_${i}_${index}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select type",
                                    },
                                  ]}
                                  initialValue={item?.chargeType}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Select a charge type"
                                    optionFilterProp="children"
                                    className="input-field-style"
                                    value={item?.chargeType}
                                    options={[
                                      {
                                        value: "absolute",
                                        label: "Absolute",
                                      },
                                      {
                                        value: "percentage",
                                        label: "Percentage",
                                      },
                                    ]}
                                    onChange={(value) =>
                                      handleChargeTypeChange(
                                        value,
                                        i,
                                        index,
                                        "chargeType"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col
                                lg={2}
                                xl={2}
                                md={8}
                                sm={2}
                                className="align-center"
                              >
                                <Form.Item
                                  name={`inputInpersent_${i}_${index}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select type",
                                    },
                                  ]}
                                  onKeyPress={(event) => {
                                    if (
                                      checkNumbervalue(event) ||
                                      checkSpecialCharacter(event) ||
                                      checkSpace(event)
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                  initialValue={
                                    item?.chargeType === "percentage"
                                      ? item?.value + String.fromCharCode(37)
                                      : item?.value
                                  }
                                >
                                  <Input
                                    className="input-field-style"
                                    placeholder="Enter value"
                                    onBlur={(e) =>
                                      form.setFieldValue(
                                        `inputInpersent_${i}_${index}`,
                                        item?.chargeType === "percentage"
                                          ? e.target.value +
                                              String.fromCharCode(37)
                                          : e.target.value
                                      )
                                    }
                                    value={
                                      item?.chargeType === "percentage"
                                        ? item?.value + String.fromCharCode(37)
                                        : item?.value
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col
                                lg={1}
                                xl={1}
                                md={2}
                                sm={2}
                                className="pt-05"
                              >
                                {item?.chargeType === "percentage"
                                  ? "Of"
                                  : "On"}
                              </Col>
                              <Col lg={3} xl={3} md={7} sm={2}>
                                <Form.Item
                                  name={`appliedTo1_${i}_${index}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select type",
                                    },
                                  ]}
                                  initialValue={camelCase(item?.appliedTo)}
                                >
                                  <Select
                                    showSearch
                                    value={camelCase(item?.appliedTo)}
                                    placeholder="Select a person"
                                    optionFilterProp="children"
                                    className="input-field-style"
                                    options={appliedTOoptionsFun()}
                                    onChange={(value) =>
                                      handleChargeTypeChange(
                                        value,
                                        i,
                                        index,
                                        "appliedTo"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={2} xl={3} md={7} sm={2}>
                                <Form.Item
                                  name={`businessName_${i}_${index}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select type",
                                    },
                                  ]}
                                  initialValue={item?.addOrSub}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Select a person"
                                    optionFilterProp="children"
                                    className="input-field-style"
                                    options={[
                                      {
                                        value: "addto",
                                        label: "Add to",
                                      },
                                      {
                                        value: "subtractFrom",
                                        label: "Subtract from",
                                      },
                                    ]}
                                    onChange={(value) =>
                                      handleChargeTypeChange(
                                        value,
                                        i,
                                        index,
                                        "addOrSub"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={3} xl={3} md={8} sm={2}>
                                <Form.Item
                                  name={`appliedTo_${i}_${index}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select type",
                                    },
                                  ]}
                                  initialValue={camelCase(item?.appliedType)}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Select a person"
                                    optionFilterProp="children"
                                    className="input-field-style"
                                    options={appliedTOoptionsFun()}
                                    onChange={(value) =>
                                      handleChargeTypeChange(
                                        value,
                                        i,
                                        index,
                                        "appliedType"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col
                                lg={2}
                                xl={2}
                                md={3}
                                sm={2}
                                className="pt-05"
                              >
                                <Switch
                                  checked={item.active}
                                  onChange={(e) =>
                                    handleAdditionalComponents(
                                      "switch",
                                      e,
                                      i,
                                      index
                                    )
                                  }
                                />
                              </Col>
                              <Col lg={1} xl={1} md={3} sm={2}>
                                <Button
                                  type="text"
                                  icon={<Mines />}
                                  onClick={() =>
                                    handleAdditionalComponents(
                                      "remove",
                                      i,
                                      index
                                    )
                                  }
                                />
                              </Col>
                              <Col lg={1} xl={1} md={2} sm={2}>
                                {additionalItem?.component?.length - 1 ===
                                  index && (
                                  <Button
                                    type="text"
                                    icon={<Plus />}
                                    onClick={() =>
                                      handleAdditionalComponents("add", i)
                                    }
                                  />
                                )}
                              </Col>
                              <Col lg={1} xl={1} md={16} sm={2}></Col>
                            </React.Fragment>
                          ))}
                        </Row>
                      </div>
                    ))}
                    {rateCardType.includes("Piece") && (
                      <>
                        <HeaderWithBottomLine
                          text={
                            <div style={{ paddingTop: "10px" }}>
                              Piece Rate Card
                            </div>
                          }
                          icon={false}
                        ></HeaderWithBottomLine>
                        <div className="billing-configuration-container-piece-heading center pt-05">
                          Software Charges for Exclusive CP
                        </div>
                        <div
                          className="space-between billing-configuration-container-piece-label"
                          style={{
                            backgroundColor: "#F7F7F7",
                            padding: "16px 80px",
                          }}
                        >
                          <div className="center">
                            <div>Monthly TS Billing Slab wise (Rs.)</div>
                          </div>
                          <div className="center">
                            <div>Monthly Software Charges (Rs.)</div>
                          </div>
                        </div>

                        <Row gutter={[12, 0]}>
                          {pieceRateCardeCharges.map((item, i) => (
                            <React.Fragment key={i}>
                              <Col xl={4} md={2} sm={2} lg={2}></Col>
                              <Col xl={6} md={8} sm={8} lg={8} className="pt-1">
                                <Input
                                  className="input-field-style"
                                  value={`${item?.fromRange} - ${item?.toRange}`}
                                />
                              </Col>
                              <Col xl={2} md={2} sm={4} lg={2}></Col>
                              <Col xl={2} md={2} sm={4} lg={2}>
                                <div className="vertical-line"></div>
                              </Col>
                              <Col xl={3} md={4} sm={4} lg={4} className="pt-1">
                                <Select
                                  showSearch
                                  placeholder="Select a charge type"
                                  optionFilterProp="children"
                                  className="input-field-style"
                                  defaultValue={item?.chargeType}
                                  options={[
                                    {
                                      value: "absolute",
                                      label: "Absolute",
                                    },
                                    {
                                      value: "percentage",
                                      label: "Percentage",
                                    },
                                  ]}
                                />
                              </Col>
                              <Col xl={3} md={4} sm={4} lg={4} className="pt-1">
                                <Input
                                  className="input-field-style"
                                  value={item?.value}
                                  onChange={(e) =>
                                    onChangePieceRateCard(e.target.value, i)
                                  }
                                />
                              </Col>
                              <Col xl={4} md={2} sm={4} lg={2}></Col>
                            </React.Fragment>
                          ))}
                        </Row>
                      </>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <hr className="divider" />

          <div className="d-flex flex-end m-1 pb-1">
            <Button className="btn-style cancel-btn ml-1 w-10">CANCEL</Button>
            <Button
              htmlType="submit"
              onClick={() => form.submit()}
              disabled={editingNameIndex !== null ? true : false}
              className="save-btn ml-1"
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  );
}
