/* eslint-disable no-unused-vars */
import React from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { clearGetState } from "../actionsMethods/masterMethods";

import back_img from "../assests/images/rightVector.svg";
import right_arrow_white from "../assests/images/right-arrow-white.png";

const Heading = ({
  title,
  navigateTo,
  id,
  nextPage,
  nextPageTitle,
  imgSrc,
  buttonClick,
  shipmentScanning,
  children,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goBack = () => {
    if (navigateTo) {
      navigate(navigateTo, { state: id });
    } else {
      if (title === "Client List") {
        localStorage.removeItem("clientId");
      }
      window.history.back();
      dispatch(clearGetState());
    }
  };
  const nextButtonClick = () => {
    if (buttonClick) {
      buttonClick();
    } else {
      navigate(nextPage);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
      }}
    >
      <p className="master-title-head" style={{ marginBottom: "5px" }}>
        <img src={back_img} onClick={goBack} className="pointer" />
        <span>{title}</span>
      </p>
      <div className="d-flex">
        {shipmentScanning}
        {nextPageTitle && (
          <button className="next-btn" onClick={nextButtonClick}>
            {imgSrc && (
              <img src={imgSrc} style={{ height: "18px", width: "18px" }} />
            )}
            &nbsp;&nbsp;{nextPageTitle}&nbsp;
            {imgSrc && (
              <img
                src={right_arrow_white}
                className="pointer"
                style={{ height: "12px", width: "12px" }}
              />
            )}
          </button>
        )}
      </div>
      {children && <div>{children}</div>}
    </div>
  );
};
export default Heading;
