/* eslint-disable indent */
import React, { useRef, useEffect } from "react";
import { Button, Spin, message } from "antd";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import { useState } from "react";

import print_icon from "../../assests/images/master/print.svg";
import API from "../../api";
import { APIs } from "../../utils/worker";
import { PrintManifest } from "../../components/PrintManifest";
import { capitalize, camelCase } from "../../utils";
import back_img from "../../assests/images/rightVector.svg";
import Smcs_Loader from "../../assests/images/loader-new.gif";

function PrintGenerateManifest() {
  const componentRef = useRef();
  const manifestId = useParams();
  const [loading, setLoading] = useState(false);
  const [printData, setPrintData] = useState();

  useEffect(() => {
    getManifestById();
  }, [manifestId]);

  const packetDataDox = printData?.shipmentScanDtos?.filter(
    (data) => data?.shipmentDetails?.type == "dox"
  );
  const packetData = printData?.shipmentScanDtos?.map(
    (data) => data?.packetsdto
  );

  let arr = printData?.shipmentScanDtos;

  const nonDoxWithChild = [];
  for (let i = 0; i < arr?.length; i++) {
    let childDto = arr[i]?.childShipmentResponseDto;
    // if (childDto) {
    for (let j = 0; j < arr?.length; j++) {
      if (childDto?.awbNumber === arr[j]?.shipmentDetails?.awbNumber) {
        let childDetails = arr[j]?.shipmentDetails?.childShipmentDetails;
        for (let k = 0; k < childDetails?.length; k++) {
          nonDoxWithChild.push({
            parentAwbNumber: childDetails[k]?.awbNumber,
            childAwbNumber: childDetails[k]?.childAwbNumber,
            connectedWithManifest: childDetails[k]?.manifestConnected,
            weight: childDetails[k]?.weight,
          });
        }
      } else {
        if (childDto?.awbNumber || childDto?.childAwbNumber) {
          nonDoxWithChild.push({
            parentAwbNumber: childDto?.awbNumber,
            childAwbNumber: childDto?.childAwbNumber,
            connectedWithManifest: true,
            weight: childDto?.weight,
            parentConnectedManifest: childDto?.parentAwbNumber,
          });
        }

        let childDetails = arr[j]?.shipmentDetails?.childShipmentDetails;
        for (let k = 0; k < childDetails?.length; k++) {
          nonDoxWithChild.push({
            parentAwbNumber: childDetails[k]?.awbNumber,
            childAwbNumber: childDetails[k]?.childAwbNumber,
            connectedWithManifest: childDetails[k]?.manifestConnected,
            weight: childDetails[k]?.weight,
            parentConnectedManifest: true,
          });
        }
      }
    }
    // }
  }

  const arrayUniqueByKey = [
    ...new Map(
      nonDoxWithChild.map((item) => [item["childAwbNumber"], item])
    ).values(),
  ];

  const packetDataNonDoxWithChild = arrayUniqueByKey;

  const packetDataNonDoxWithNoChild = printData?.shipmentScanDtos?.filter(
    (data) =>
      data?.shipmentDetails?.childShipmentDetails?.length === 0 &&
      data?.shipmentDetails?.type == "non-dox"
  );

  const getManifestById = async () => {
    setLoading(true);
    let response = await API.Api(
      `${APIs.baseURL}/last-mile-manifest-service/v1/manifest/${manifestId?.id}`
    ).getWithAllHeaders();
    if (response.status) {
      setLoading(false);
      setPrintData(response?.response?.data?.data);
    } else {
      if (!response.status) {
        message.error(response.response.data.message || "Network error");
        setLoading(false);
      }
    }
  };

  const columnsWOChild = [
    {
      title: "AWB No",
      dataIndex: "",
      key: "",
      render: (text) => (
        <span className="fw-600">
          {text?.shipmentDetails?.awbNumber || "------"}
        </span>
      ),
    },
    {
      title: "Weight",
      dataIndex: "",
      key: "",
      render: (text) => (
        <div>{`${text?.shipmentDetails?.weight} Kg` || "------"}</div>
      ),
    },
    {
      title: "Mode",
      dataIndex: "",
      key: "",
      render: (text) => (
        <div>{capitalize(text?.shipmentDetails?.travelBy) || "------"}</div>
      ),
    },
  ];

  const columnsWithChild = [
    {
      title: "AWB No",
      dataIndex: "",
      key: "",
      render: (text, r) => (
        <span className={r?.parentConnectedManifest && "fw-600"}>
          {text?.parentAwbNumber || "------"}
        </span>
      ),
    },
    {
      title: "Child AWB No",
      dataIndex: "",
      key: "",
      render: (text, r) => (
        <span className={r?.connectedWithManifest && "fw-600"}>
          {text?.childAwbNumber || "------"}
        </span>
      ),
    },
    {
      title: "Weight",
      dataIndex: "",
      key: "",
      render: (text) => <div>{`${text?.weight} Kg` || "------"}</div>,
    },
    {
      title: "Mode",
      dataIndex: "",
      key: "",
      render: () => <div>{capitalize(printData?.travelBy) || "------"}</div>,
    },
  ];

  const columnsDox = [
    {
      title: "AWB No",
      dataIndex: "",
      key: "",
      render: (text) => (
        <span className="fw-600">
          {text?.shipmentDetails?.awbNumber || "------"}
        </span>
      ),
    },
    {
      title: "Weight",
      dataIndex: "",
      key: "",
      render: (text) => (
        <div>{`${text?.shipmentDetails?.weight || "------"} Kg`}</div>
      ),
    },
    {
      title: "Mode",
      dataIndex: "",
      key: "",
      render: (text) => (
        <div>{camelCase(text?.shipmentDetails?.travelBy) || "------"}</div>
      ),
    },
  ];

  const packets = [
    {
      title: "Packet ID",
      dataIndex: "",
      key: "",
      render: (text) => <span className="fw-600">{text?.packetBarcode}</span>,
    },
    {
      title: "Packet Content",
      dataIndex: "",
      key: "",
      render: (text) => (
        <>
          {text?.packetShipments?.map((data, idx) => (
            <div key={idx}>{data?.awbNo}</div>
          ))}
        </>
      ),
    },
    {
      title: "Weight",
      dataIndex: "",
      key: "",
      render: (text) => (
        <>
          {text?.packetShipments?.map((data, idx) => (
            <div key={idx}>{`${data?.weight} Kg`}</div>
          ))}
        </>
      ),
    },
    {
      title: "Mode",
      dataIndex: "",
      key: "",
      render: (record) => <div>{capitalize(record?.travelBy)}</div>,
    },
  ];

  const printHandle = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="p-2">
        <div className="print-consolidated-manifest">
          <div className="heading-lcr p-1-5">
            {" "}
            <span>
              {" "}
              <img
                src={back_img}
                onClick={() => window.history.back()}
                style={{ cursor: "pointer" }}
                className="mr-1"
              />
            </span>{" "}
            Print Manifest
          </div>
          <div className="lcr-report-wrapper">
            <div className="download-btn">
              <Button onClick={printHandle}>
                <img src={print_icon} /> PRINT
              </Button>
            </div>
            <PrintManifest
              componentRef={componentRef}
              packetAndShipmt={printData}
              manifestId={manifestId}
              columnsWOChild={columnsWOChild}
              packetDataNonDoxWithNoChild={packetDataNonDoxWithNoChild}
              columnsWithChild={columnsWithChild}
              packetDataNonDoxWithChild={packetDataNonDoxWithChild}
              columnsDox={columnsDox}
              packetDataDox={packetDataDox}
              packets={packets}
              packetData={packetData}
              Boolean={Boolean}
            />
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default PrintGenerateManifest;
