import React from "react";
import Heading from "./Heading";
import { Row, Col,Form,Input} from "antd";

const DeliveryDetails = () => {
  return (
    <div className="p-2">
      <Heading title={"Delivery Details"}/>
      <Form layout={"vertical"}>
        <Row>
          <Col md={12} lg={12} xl={12} sm={24} xs={24}>
            <Form.Item
              label="Branch PIN code"
              name="branchPinCode"
              rules={[
                {
                  required: true,
                  message: "Please enter branch PIN code",
                },
              ]}
            >
              <Input className="input-field-style" disabled/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default DeliveryDetails;
