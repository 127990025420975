import React from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  Input,
  Select,
  Radio,
  Spin,
  Checkbox,
} from "antd";
import { isValidChildAwb } from "../../../utils";

export function bagInitializeModal(
  showCreateBagModal,
  form,
  setShowCreateBagModal,
  setContentTypeRequired,
  createBag,
  changeMode,
  changeContentType,
  changeTravelBy,
  hubList,
  bagColor,
  bagLoading,
  filterDestinationList
) {
  const productType = [
    { label: "Standard", value: "standard" },
    { label: "Fast Track", value: "fast track" },
    { label: "Value Plus", value: "value plus" },
    { label: "E-Com", value: "E_COMM" },
  ];

  const contentTypeData = [
    { label: "Dox", value: "DOX" },
    { label: "Non-Dox", value: "NON-DOX" },
  ];
  return (
    <Modal
      title={"Create Bag"}
      open={showCreateBagModal}
      onCancel={() => {
        form.resetFields();
        setShowCreateBagModal(false);
        setContentTypeRequired(false);
      }}
      width={450}
      footer={null}
      className="create-bag"
      style={{ top: "200px !important" }}
      maskClosable={false}>
      <Form
        layout="vertical"
        form={form}
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
        onFinish={createBag}
        className="global-form"
        name="form">
        <Spin spinning={bagLoading}>
          <Row gutter={20} className="global-form-row">
            <Col span={24}>
              <Form.Item
                name="mode"
                label="Product"
                rules={[
                  { required: true, message: "Please choose product type." },
                ]}>
                <Radio.Group
                  onChange={changeMode}
                  style={{ marginTop: "-10px" }}
                  options={productType}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="contentType"
                label="Content type"
                rules={[
                  {
                    required: true,
                    message: "Please choose content type.",
                  },
                ]}>
                <Radio.Group
                  onChange={changeContentType}
                  options={contentTypeData}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="travelBy"
                label="Mode"
                rules={[
                  {
                    required: true,
                    message: "Please choose mode.",
                  },
                ]}>
                <Radio.Group onChange={changeTravelBy}>
                  <Radio value="surface">
                    <span className="f-14" style={{ color: "#061624" }}>
                      Surface
                    </span>
                  </Radio>
                  <Radio value="air" className="ml-1">
                    <span className="f-14" style={{ color: "#061624" }}>
                      Air
                    </span>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="bagBarcodeNo"
                label="Bag number"
                rules={[
                  {
                    required: true,
                    message: "Please enter bag number",
                  },
                  {
                    validator: (_, value) => {
                      if (value && !isValidChildAwb(value)) {
                        return Promise.reject(
                          "Please enter a valid Bag number."
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}>
                <Input
                  placeholder="Bag number"
                  autoComplete="off"
                  onKeyPress={(e) => {
                    if (/[^0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                    if (e.target.value.length === 11) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Checkbox.Group onChange={filterDestinationList}>
                <Checkbox value={1}>Individual</Checkbox>
                <Checkbox value={"mix_38"}>Mix</Checkbox>
              </Checkbox.Group>
            </Col>
            <Col span={24}>
              <Form.Item
                name="destinationHubId"
                label="Bag destination"
                rules={[
                  {
                    required: true,
                    message: "Please select bag destination",
                  },
                ]}>
                <Select
                  showSearch
                  placeholder="Bag destination"
                  options={hubList}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="bagColour"
                label="Bag colour"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}>
                <Radio.Group>
                  {bagColor.map((e, i) => {
                    return (
                      <Radio value={e.value} key={i} disabled>
                        <span className="f-14" style={{ color: "#061624" }}>
                          {e.label}
                        </span>
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Spin>

        <div className="d-flex flex-end p-1">
          <Button htmlType="submit" className="save-btn ml-1">
            CREATE
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
