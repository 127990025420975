/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Button,
  Table,
  Modal,
  TimePicker,
  message,
  Tooltip,
  Space,
  Spin,
  Popconfirm,
  Input,
  DatePicker,
} from "antd";
import {
  serialNum,
  capitaliseWithHyphen,
  dateFormat_dmy,
} from "../../../utils";
import { useNavigate } from "react-router";
import urls from "../../../api/urls";
import API from "../../../api";
import NormalAPI from "../../../api";
import check_out_img from "../../../assests/images/middleMile/check_out.svg";
import search_icon from "../../../assests/images/search-icon.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

import "./style.scss";
const ViewTrip = ({ handleStep = () => {}, callApi }) => {
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const { GET_TRIP_LIST, POST_CHECKOUT_TIME, SEARCH_TRIP_LIST } = urls;
  const [tripList, setTripList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tripId, setTripId] = useState("");
  const [vehicleNum, setVehicleNum] = useState("");
  const [checkoutTime, setCheckoutTime] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);
  const [dateRangeData, setDateRangeData] = useState([]);
  const columns = [
    {
      title: "Sl No",
      align: "center",
      with: 10,
      render: (a, b, idx) => serialNum(5, currentPage, idx),
    },
    {
      title: "Trip ID",
      dataIndex: "tripId",
      key: "tripId",
      render: (text, record) => (
        <div>
          {record?.status?.toLowerCase() === "in-transit" ||
          record?.status?.toLowerCase() === "completed" ||
          record?.vehicleLcrStatus?.toLowerCase() === "locked" ? (
            <div>{text || "------"}</div>
          ) : (
            <a onClick={() => handleStep("2", record, getTripList)}>
              {text || "------"}
            </a>
          )}
        </div>
      ),
    },
    {
      title: "Vehicle/Flight LCR ID",
      dataIndex: "vehicleLcrId",
      key: "vehicleLcrId",
      render: (text) => <div>{text || "------"}</div>,
    },
    {
      title: "Vehicle/Flight LCR Status",
      dataIndex: "vehicleLcrStatus",
      key: "vehicleLcrStatus",
      render: (text) => <div>{text || "------"}</div>,
    },
    {
      title: "Mode",
      dataIndex: "travelBy",
      key: "travelBy",
      render: (text) => <div>{capitaliseWithHyphen(text) || "------"}</div>,
    },
    {
      title: "Vehicle/Flight No.",
      dataIndex: "",
      key: "",
      render: (text) => (
        <span>{text?.vehicleNum ? text?.vehicleNum : text?.flightNum}</span>
      ),
    },
    {
      title: "Driver Name",
      dataIndex: "",
      key: "",
      render: (text) => (
        <span>
          {text?.driverName ? capitaliseWithHyphen(text?.driverName) : "------"}
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => (
        <span>
          {`${text?.split(" ")[0]?.split("-")?.join("-")}` || "------"}
        </span>
      ),
    },
    {
      title: "Trip Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <div>{text || "------"}</div>,
    },
    {
      title: "E-way Bill",
      dataIndex: "EwayUpdate",
      key: "EwayUpdate",
      fixed: "right",
      render: (record, _record) => {
        return (
          <Space>
            <Button
              disabled={!_record?.isEwayBill}
              className="cancel-btn"
              onClick={() => {
                navigate(`/hub-eway-bill/${_record.tripId}`, {
                  state: { state: _record, slug: "/lcr-generation" },
                });
              }}
            >
              E-WAY BILL UPDATE
            </Button>
            <Button
              className="cancel-btn"
              onClick={() =>
                navigate("/print-eway-bill", {
                  state: {
                    state: _record?.tripId,
                    key: "VIEW_TRIP",
                    slug: "/lcr-generation",
                  },
                })
              }
            >
              PRINT
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "checkin",
      key: "checkin",
      fixed: "right",
      render: (e, record) => {
        return (
          <Tooltip title={"CHECK-OUT"}>
            <Button
              style={{ border: "none", background: "none", boxShadow: "none" }}
              onClick={() => showModal(record)}
              disabled={record?.travelBy?.toUpperCase() === "AIR"}
            >
              <img src={check_out_img} />
            </Button>
          </Tooltip>
        );
      },
    },
  ];
  const showModal = (record) => {
    setIsModalOpen(true);
    setTripId(record?.tripId);
    setVehicleNum(record?.vehicleNum);
    setBtnDisable(true);
  };
  const handleOk = async () => {
    setLoading(true);
    let response = await NormalAPI.Api(
      `${POST_CHECKOUT_TIME}check_out/${tripId}`
    ).postWithPrmiseHeader({
      vehicleNum: vehicleNum,
      checkOutTime: checkoutTime,
    });
    if (response?.status) {
      message.success("successfully checked out");
      setLoading(false);
      setIsModalOpen(false);
    } else {
      setLoading(false);
      setIsModalOpen(false);
      message.error(response?.response?.data?.message);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getTripList = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_TRIP_LIST + "?pageNum=" + currentPage + "&pageSize=" + 50
    ).getWithUserNPremiseHeader();
    if (response?.status) {
      setLoading(false);
      setTripList(response?.response?.data?.data);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    getTripList();
  }, []);
  const paginationHandle = (pagination) => {
    setCurrentPage(pagination.current);
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };
  const onTimeChange = (time, timeString) => {
    setCheckoutTime(timeString);
    setBtnDisable(false);
  };
  const searchHandle = async (e) => {
    setCurrentPage(0);
    if (e.target.value.includes("-") === false) {
      if (e.target.value.length > 10) {
        setLoading(true);
        let response = await API.Api(
          SEARCH_TRIP_LIST + e.target.value
        ).getWithAllHeaders();
        if (response?.status) {
          setTripList(response?.response?.data?.data);
          setLoading(false);
        } else {
          message.error(response?.response?.data?.message);
          setTripList([]);
          setLoading(false);
        }
      } else if (e.target.value.length < 1) {
        if (dateRangeData?.length === 0) {
          setLoading(false);
          getTripList();
        } else if (dateRangeData?.length > 0) {
          setLoading(false);
          dateRangeHandle(e, dateRangeData);
        }
      } else {
        return false;
      }
    } else {
      {
        if (e.target.value.length >= 13) {
          setLoading(true);
          let response = await API.Api(
            SEARCH_TRIP_LIST + e.target.value
          ).getWithAllHeaders();
          if (response?.status) {
            setTripList(response?.response?.data?.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } else if (e.target.value.length < 1) {
          setLoading(false);
          getTripList();
        } else {
          return false;
        }
      }
    }
  };

  const dateRangeHandle = (e, a) => {
    setDateRangeData(a);
    if (a[0]?.length === 0) {
      getTripList();
    }
  };
  const dateSearchFun = async () => {
    let fromDate = dateRangeData[0].replaceAll("/", "-");
    let toDate = dateRangeData[1].replaceAll("/", "-");
    setLoading(true);
    let response = await API.Api(
      GET_TRIP_LIST +
        "?pageNum=" +
        currentPage +
        "&pageSize=" +
        50 +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate
    ).getWithUserNPremiseHeader();
    if (response?.status) {
      setLoading(false);
      setTripList(response?.response?.data?.data);
    } else {
      setLoading(false);
      getTripList();
    }
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  return (
    <Spin
      spinning={loading}
      size="large"
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className=" bagging-shipment" style={{ padding: "0" }}>
        <div className="lcr-table-container">
          <div className="mb-1 mr-1 flex-end">
            <RangePicker
              format={dateFormat_dmy}
              onChange={dateRangeHandle}
              style={{
                borderRadius: "5px 0 0 5px",
                height: "36px",
                width: "250px",
              }}
              disabledDate={(current) => disabledDate(current)}
            />
            <div
              className="center mr-1"
              style={{
                width: "40px",
                border: "1px solid #d9d9d9",
                borderRadius: "0 5px 5px 0",
                cursor: "pointer",
              }}
              onClick={dateSearchFun}
            >
              <img src={search_icon} />
            </div>
            <Input
              placeholder={"Search by Trip ID & Vehicle/Flight LCR ID"}
              bordered
              onChange={searchHandle}
              style={{ borderRadius: "5px", height: "36px", width: "320px" }}
              suffix={<img src={search_icon} />}
            />
          </div>
          <Table
            className="table-head-sticky"
            columns={columns}
            dataSource={tripList}
            pagination={{
              pageSize: 10,
              total: tripList?.length,
              itemRender: itemRender,
            }}
            onChange={paginationHandle}
          />
          <Modal
            className="timepicker-modal"
            title="Check-out"
            open={isModalOpen}
            maskClosable={false}
            footer={false}
            onCancel={handleCancel}
            destroyOnClose
          >
            <TimePicker onChange={onTimeChange} format={"HH:mm"} />
            <div className="flex-end mt-1">
              <Button onClick={handleCancel} className="cancel-btn mr-1">
                {"CANCEL"}
              </Button>
              <Popconfirm
                title="Do you wish to check-out"
                onConfirm={handleOk}
                okText="Yes"
                cancelText="No"
                style={{ background: "none" }}
              >
                <Button
                  className={btnDisable ? "disable-popup" : "save-btn"}
                  disabled={btnDisable}
                >
                  {"SAVE"}
                </Button>
              </Popconfirm>
            </div>
          </Modal>
        </div>
      </div>
    </Spin>
  );
};

export default ViewTrip;
