/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Form,
  Tooltip,
  Row,
  Select,
  Table,
  Radio,
  Spin,
} from "antd";
import React, { useState, useEffect } from "react";
import Heading from "../../../../components/Heading";
import { dateFormat_dmy } from "../../../../utils";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";
import print_icon from "../../../../assests/images/printIcon.svg";

// import { column } from "./columns";
import API from "../../../../api";
import urls from "../../../../api/urls";
const { GET_DISCOUNT_LIST } = urls;

function Discount() {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState();
  useEffect(() => {
    getSenderNames();
  }, []);

  const columns = [
    {
      title: "Sender Name",
      dataIndex: "senderName",
      key: "senderName",
      render: (text) => text?.toUpperCase(),
    },
    {
      title: "Discount Percentage",
      dataIndex: "discountPercentage",
      key: "discountPercentage",
    },

    {
      title: "Booking Date",
      dataIndex: "bookingdate",
      key: "bookingdate",
    },

    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: () => (
        <Tooltip title={"Print"} placement="bottom">
          <img src={print_icon} style={{ width: "33px" }} className="pointer" />
        </Tooltip>
      ),
    },
  ];

  const getSenderNames = async () => {
    setLoading(true);

    let response = await API.Api(GET_DISCOUNT_LIST).getWithUserNPremiseHeader();
    if (response?.status) {
      setLoading(false);

      let res = response?.response?.data;
      console.log(res, "res");
      setDataSource(res);
    } else {
      setLoading(false);
    }
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="p-2">
        <div>
          <Heading title={"Bulk Discount List"} />
        </div>
        <>
          <div className="mt-1">
            <Table
              columns={columns}
              pagination={false}
              bordered
              // total={data?.totalCount}
              dataSource={dataSource}
              // headerClassName="header"
              // className="view-table table-head-sticky"
            />
          </div>
        </>
      </div>
    </Spin>
  );
}

//total
// and discount amt show in all

export default Discount;
