import React from "react";
import { Modal, Table } from "antd";
import cross_button from "../../../assests/images/CrossButton.svg";
import { capitalizeAfterSpacesAndSpecialChars } from "../../../utils";

const PacketingGuideModal = ({
  setIsModalOpen,
  isModalOpen,
  hubCpList,
  modalName,
}) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const firstElement = hubCpList;
  const columns = [
    {
      dataIndex: "",
      key: "",
      render: (text) => <div>{capitalizeAfterSpacesAndSpecialChars(text)}</div>,
    },
  ];
  return (
    <div>
      <>
        <Modal
          title={modalName}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          maskClosable={false}
          closeIcon={<img src={cross_button} />}
          className="packeting-guid-list-modal"
        >
          <Table
            dataSource={firstElement}
            columns={columns}
            pagination={false}
            showHeader={false}
          />
        </Modal>
      </>
    </div>
  );
};

export default PacketingGuideModal;
