import { combineReducers } from "redux";

import actions from "../constants/onboardingActions";
import {
  getFetchingState,
  getSuccessState,
  getErrorState,
  getResetState,
  createReducer,
} from "../utils/reducers";

import { defaultState } from "./config";

const patchClientOnboardReducer = createReducer(defaultState, {
  [actions.CLIENT_ONBOARD_PATCH]: getFetchingState,
  [actions.CLIENT_ONBOARD_PATCH_SUCCESS]: getSuccessState,
  [actions.CLIENT_ONBOARD_PATCH_ERROR]: getErrorState,
  [actions.CLIENT_ONBOARD_PATCH_CLEAR]: getResetState,
});

const getClientOnboardReducer = createReducer(defaultState, {
  [actions.GET_CLIENT_ONBOARD]: getFetchingState,
  [actions.GET_CLIENT_ONBOARD_SUCCESS]: getSuccessState,
  [actions.GET_CLIENT_ONBOARD_ERROR]: getErrorState,
  [actions.GET_CLIENT_ONBOARD_CLEAR]: getResetState,
});

const getClientPincodeMappingReducer = createReducer(defaultState, {
  [actions.GET_CLIENT_PINCODE_MAPPING]: getFetchingState,
  [actions.GET_CLIENT_PINCODE_MAPPING_SUCCESS]: getSuccessState,
  [actions.GET_CLIENT_PINCODE_MAPPING_ERROR]: getErrorState,
  [actions.GET_CLIENT_PINCODE_MAPPING_CLEAR]: getResetState,
});

const getStaffOnboardReducer = createReducer(defaultState, {
  [actions.GET_STAFF_ONBOARD]: getFetchingState,
  [actions.GET_STAFF_ONBOARD_SUCCESS]: getSuccessState,
  [actions.GET_STAFF_ONBOARD_ERROR]: getErrorState,
  [actions.GET_STAFF_ONBOARD_CLEAR]: getResetState,
});

const updateStaffOnboardReducer = createReducer(defaultState, {
  [actions.UPDATE_STAFF_ONBOARD]: getFetchingState,
  [actions.UPDATE_STAFF_ONBOARD_SUCCESS]: getSuccessState,
  [actions.UPDATE_STAFF_ONBOARD_ERROR]: getErrorState,
  [actions.UPDATE_STAFF_ONBOARD_CLEAR]: getResetState,
});

export default combineReducers({
  patchClientOnboardReducer,
  getClientPincodeMappingReducer,
  getClientOnboardReducer,
  getStaffOnboardReducer,
  updateStaffOnboardReducer,
});
