/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row } from "antd";
import { parentMaster } from "../../config/parentMaster";
import MasterCard from "../../components/Master/MasterCard/MasterCard";
import MasterSearch from "../../components/Master/MasterSearch";
import "./index.scss";

const Master = () => {
  const [masterList, setMasterList] = useState([]);
  const [currentPageRules, setCurrentPageRules] = useState({});
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    let role = JSON.parse(localStorage.getItem("userinfo"));
    let temp = [];
    let currentPageData = parentMaster.find((e)=>e.mainRoute === window.location.pathname);
    currentPageData.masterData.map((e) => {
      e.role.some((item) => {
        if (role && role.roles.includes(item)) {
          temp.push(e);
        }
      });
    });
    setCurrentPageRules(currentPageData.masterProperty);
    setMasterList(temp);
  }, []);
  
  const {
    searchPresent,
    searchPlaceholder,
    headingTitle
  } = currentPageRules;

  const searchSetting = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);
    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(masterList, currValue)
        : filterArray(masterList, currValue);
    setDataSource(filteredData);
  };

  const filterArray = (dataSource, currValue) =>
    dataSource?.filter((o) =>
      o.title.toLowerCase().includes(currValue.toLowerCase())
    );

  const onClickHandler = (route, childPresent, id) => {
    if (childPresent) {
      navigate("/master-card-child", {
        state: id,
      });
    } else {
      if (route === "/client-onboarding") {
        localStorage.removeItem("clientId");
      }
      if (route === "/staff-onboarding") {
        localStorage.removeItem("staff");
      }
      navigate(route);
    }
  };
  return (
    <div className="master-container" style={{padding: "3rem 5rem"}}>
      <Row gutter={30}>
        <MasterSearch
          title={headingTitle}
          search={searchSetting}
          masterSearch={true}
          masterSearchPlaceholder={searchPlaceholder}
          isSearchPresent={searchPresent}
        />
        {inputSearchValue.length == 0
          ? masterList.map((e) => {
              return (
                <MasterCard
                  key={e.id}
                  id={e.id}
                  title={e.title}
                  imgSrc={e.imgSrc}
                  onClickHandler={onClickHandler}
                  route={e.route}
                  searchTitle={searchPlaceholder}
                  disable={e.disable}
                  childPresent={e.childPresent}
                />
              );
            })
          : dataSource.map((e) => {
              return (
                <MasterCard
                  key={e.id}
                  id={e.id}
                  title={e.title}
                  imgSrc={e.imgSrc}
                  onClickHandler={onClickHandler}
                  route={e.route}
                  searchTitle={searchPlaceholder}
                  disable={e.disable}
                  childPresent={e.childPresent}
                />
              );
            })}
      </Row>
    </div>
  );
};
export default Master;
