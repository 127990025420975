/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import messages from "../../../../constants/messages";
import edit from "../../../../assests/images/EditBasePriceIcon.svg";
import { camelCase } from "../../../../utils";
import truck from "../../../../assests/images/truckicon.svg";
import plane from "../../../../assests/images/calculaterAirIcon.svg";
import {
  checkNumbervalue,
  checkSpecialCharacter,
  checkSpace,
} from "../../../../utils";
import {
  Row,
  Col,
  Checkbox,
  Button,
  Form,
  Input,
  Tooltip,
  message,
} from "antd";

function newRateCard({
  itemList,
  premiumOrnot,
  setSelectedId,
  borderColor,
  setErrorMsg,
  buttonColor,
  buttonTextColor,
  ritTick,
}) {
  const [checkboxList, setCheckboxList] = useState({});
  const [defaultValue, setDefaultValue] = useState(false);
  const [checkBoxGrpVal, setCheckBoxGrpVal] = useState([]);
  const [item, setItem] = useState(itemList);
  let premium = premiumOrnot === "serviceable_premium" ? true : false;
  const [form] = Form.useForm();

  useEffect(() => {
    setItem(itemList);
  }, [itemList]);

  const [basePriceState, setBasePriceState] = useState(
    premium ? ~~item?.price + 20 : ~~item?.price
  );

  const {
    EXPECTED_by,
    DAYS,
    BASE_CHARGES,
    PREMIUM,
    FOV,
    COD,
    ESTIMATED,
    CHOOSE,
  } = messages;

  useEffect(() => {
    if (premiumOrnot === "serviceable_premium") {
      setDefaultValue(true);
    }
  }, [premium, defaultValue]);

  const onClickHandler = (data) => {
    if (premium ? ~~item?.price + 20 : ~~item?.price <= basePriceState) {
      let x = { ...data, estimatedPrice: parseInt(basePriceState) };
      setSelectedId(x);
    } else {
      message.error(
        `Baseprice should be greater then or equal to ${
          premium ? ~~item?.price + 20 : ~~item?.price
        } `
      );
    }
  };

  const onChange = (e, b) => {
    let addCodFov = e?.map((r) => r?.split("_")[0]);
    setCheckboxList({
      ...checkboxList,
      id: b,
      estimatedPrice: addCodFov.reduce((a, b) => +a + +b, basePriceState),
    });
  };

  const onChangeFov = () => {
    let temp = { ...item };
    temp.checkedFov = !temp.checkedFov;
    setItem(temp);
  };

  const onChangeCod = () => {
    let temp = { ...item };
    temp.checkedCod = !temp.checkedCod;
    setItem(temp);
  };

  const basePriceHandler = (e) => {
    setBasePriceState(e.target.value);
    setSelectedId(undefined);
  };

  return (
    <div className={`border-${borderColor}`}>
      <div className="newRateCart">
        <Row className={`newRateCart-scrvice ${item?.color} `} align="middle">
          <Col lg={9} md={9}>
            {" "}
            <img
              src={item?.modeName?.toUpperCase() === "AIR" ? plane : truck}
            />
          </Col>
          <Col lg={12} md={12} className="newRateCart-scrvice-heading">
            {camelCase(item?.serive)}
          </Col>
        </Row>
        <Row className="newRateCart-eed" align="middle" justify="center">
          <Col>
            <div className="newRateCart-edd-date">
              <span className="newRateCart-edd-label">{EXPECTED_by} </span>:
              <span
                className="newRateCart-edd-month"
                style={{ paddingLeft: "3px" }}>
                {Math.floor(item?.tatService)} {DAYS}
              </span>
            </div>
          </Col>
        </Row>
        <Row
          className="newRateCart-base-price"
          style={{ background: "#F4F9FF" }}
          justify="start">
          <Col lg={12} md={12} className="newRateCart-base-label">
            {BASE_CHARGES}
          </Col>
          <Col
            lg={12}
            md={12}
            className="newRateCart-base-price-value"
            style={{ textAlign: "right" }}
            // style={{ paddingLeft: "33px" }}
          >
            <Input
              className="edit-base-price"
              style={{ width: "80px", height: "21px" }}
              defaultValue={basePriceState}
              onKeyPress={(event) => {
                if (
                  checkNumbervalue(event) ||
                  checkSpecialCharacter(event) ||
                  checkSpace(event)
                ) {
                  event.preventDefault();
                }
              }}
              maxLength={6}
              onChange={(e) => basePriceHandler(e)}
            />
          </Col>
        </Row>
        <Form layout="vertical" form={form}>
          <Checkbox.Group
            onChange={(e, b) => onChange(e, item?.id)}
            style={{ padding: "0.8rem" }}
            value={[
              item?.checkedFov && item?.fov + "_fov",
              item?.checkedCod && item?.cod + "_cod",
            ]}>
            <Checkbox
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onChangeFov();
                }
              }}
              onChange={(e, b) => onChangeFov(e?.target?.checked, item?.id)}
              checked={item?.checkedFov}
              className="newRateCart-base-price-value"
              value={item?.fov + "_fov"}>
              <div>{FOV}</div>
            </Checkbox>
            ₹{item?.fov}{" "}
            <Checkbox
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onChangeCod();
                }
              }}
              className="newRateCart-base-price-value"
              onChange={(e, b) => onChangeCod(e?.target?.checked, item?.id)}
              checked={item?.checkedCod}
              value={item?.cod + "_cod"}>
              <div>{COD}</div>
            </Checkbox>
            ₹{item?.cod}
          </Checkbox.Group>
        </Form>
        <hr width="100%" />
        {/* <div style={{ borderBottom: "1px solid #DDDDDD" }}></div> */}
        <Row
          className="newRateCart-estimated-base-price"
          style={{ padding: "0 0 0 0" }}
          justify="start">
          <Col lg={12} md={12} className="newRateCart-base-estimated-label">
            {ESTIMATED}
          </Col>
          <Col
            lg={12}
            md={12}
            className="newRateCart-base-estimated-price"
            style={{ textAlign: "right" }}>
            ₹{checkboxList?.estimatedPrice || basePriceState}
          </Col>
        </Row>
        <div className="newRateCart-choose-box">
          <Button
            className="newRateCart-choose-box-button"
            onClick={() => onClickHandler(item)}
            style={{ backgroundColor: buttonColor }}
            // disabled={createChildShipmentResult !== null && true}
          >
            <span
              className="newRateCart-choose-box-button-text"
              style={{ color: buttonTextColor }}>
              {" "}
              <img
                src={ritTick}
                className="newRateCart-choose-box-button-text-img"
              />
              {CHOOSE}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default newRateCard;
