/* eslint-disable no-loss-of-precision */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable max-len */

import React from "react";
import axios from "axios";
import { Button, Spin, message } from "antd";
import urls from "../../../../api/urls";
import constants from "../../../../constants/constants";
const { WHATSAPPKEY, CAMPAIGN_NAME } = constants;

const { SEND_WHATSPP } = urls;

export const sendSms = async (documentNumber, receiverMobileNumber) => {
  //   setLoading(true);
  try {
    let res = await axios.get(
      `https://pgapi.vispl.in/fe/api/v1/send?username=shremotpg.trans&password=uNIvT&unicode=false&from=SMILL&dltPrincipalEntityId=1001569800000012636&dltContentId=1007163186467531163&text=Shree Maruti Courier
            Your shipment ${documentNumber} is booked with Shree Maruti Courier. To track your shipment you can visit https://www.shreemaruticourier.com/tracking/?d=${documentNumber}&to=${receiverMobileNumber}`
    );
    if (res?.data) {
      return;
      //   setLoading(false);
    }
  } catch (error) {
    return;
  }
};

export const sendWhatApp = async (
  documentNumber,
  receiverName,
  senderMobileNumber,
  destinationCity,
  destinationState
) => {
  const payload = {
    apiKey: WHATSAPPKEY,

    campaignName: CAMPAIGN_NAME,
    destination: senderMobileNumber?.toString(), // sender phone number
    userName: "smcs",
    media: {
      url: "https://hubops.innofulfill.com/static/media/Smcs_New_Logo.085091870cb846667792.png",
      filename: documentNumber?.toString(),
    },
    templateParams: [
      documentNumber?.toString(),
      receiverName || "",
      destinationCity + ", " + destinationState || "SHREE MARUTI",
      "https://www.shreemaruti.com/dw/?a=R98s3K",
      "https://www.shreemaruti.com/dw/?a=S79s3P",
      `tracking/?d=${documentNumber?.toString()}`,
    ],
  };

  try {
    let res = await axios.post(SEND_WHATSPP, payload);
    if (res?.status == 200) {
      return true;
      //   setLoading(false);
    }
  } catch (error) {
    return false;
    // setLoading(false);
  }
};
