import Login from "../views/Login";
import Dashboard from "../views/Dashboard";
import ForgotPasswordMain from "../components/ForgotPassword";
import ProfileTab from "../views/ProfileTab";
import CreateNewPassword from "../views/CreateNewPassword";
import Notification from "../views/Notification";

import "antd/dist/antd.css";
import "../App.scss";

export const commonRoutes = [
  {
    component: Login,
    route: "/",
  },
  {
    component: Dashboard,
    route: "/dashboard",
  },
  {
    component: ForgotPasswordMain,
    route: "/forgotPassword",
  },
  {
    component: ForgotPasswordMain,
    route: "/newPassword",
  },
  {
    component: ForgotPasswordMain,
    route: "/confirmpassword",
  },
  {
    component: ForgotPasswordMain,
    route: "/confirmMail",
  },
  {
    component: ProfileTab,
    route: "/profile",
  },
  {
    component: CreateNewPassword,
    route: "/create-new-password",
  },
  {
    component: Notification,
    route: "/notification"
  }
];
