import React, { useState } from "react";
import { Tabs } from "antd";
import { useNavigate } from "react-router";

import CreateVirtualArea from "./createVirtualArea";
import EditVirtualArea from "./editVirtualArea";
import ViewVirtualArea from "./viewVirtualArea";

import back_img from "../../../assests/images/rightVector.svg";

const CreateTrip = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState("1");
  const [editView, setEditView] = useState(false);

  const [editId, setEditId] = useState(false);

  const handleTabChange = (params) => {
    setStep(params);
    setEditView(false);
  };

  const handleEdit = (id, pId) => {
    let data = { id, pId };
    setStep("1");
    setEditView(true);
    setEditId(data);
  };

  const onChange = (key) => {
    setStep(key);
    setEditView(false);
  };

  const items = [
    {
      key: "1",
      label: "Create Delivery Area",
      children: editView ? (
        <EditVirtualArea handleTabChange={handleTabChange} editId={editId} />
      ) : (
        <CreateVirtualArea
          handleTabChange={handleTabChange}
          editView={editView}
        />
      ),
    },
    {
      key: "2",
      label: "View Delivery Area",
      children: (
        <ViewVirtualArea
          handleTabChange={handleTabChange}
          handleEdit={handleEdit}
        />
      ),
    },
  ];

  return (
    <div className="p2 consolidated-tab-cntr">
      <div className="top-wrapper space-between ">
        <div className="heading mb-1 align-center">
          <img
            src={back_img}
            onClick={() => navigate("/last-mile-operations")}
            style={{ cursor: "pointer" }}
          />
          <p
            style={{
              margin: "0",
              paddingLeft: "5px",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "32px",
              letterSpacing: "-0.25px",
              color: "#061624",
            }}
          >
            <b>Create Delivery Area</b>
          </p>
        </div>
      </div>
      <Tabs
        defaultActiveKey={"1"}
        activeKey={step}
        items={items}
        onChange={onChange}
        destroyInactiveTabPane={true}
      />
    </div>
  );
};
export default CreateTrip;
