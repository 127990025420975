import { takeLatest } from "redux-saga/effects";

import { createWatcher } from "../utils/sagas";
import actions from "../constants/onboardingActions";

import * as worker from "../workers/onboarding/onboardingWorker";

export const patchClientOnboardWatcher = createWatcher(
  actions.CLIENT_ONBOARD_PATCH,
  worker.patchClientOnboardWorker,
  takeLatest
);

export const getClientOnboardWatcher = createWatcher(
  actions.GET_CLIENT_ONBOARD,
  worker.getClientOnboardWorker,
  takeLatest
);

export const getClientPincodeMapping = createWatcher(
  actions.GET_CLIENT_PINCODE_MAPPING,
  worker.getClientPincodeMapping,
  takeLatest
);

export const getStaffOnboardWatcher = createWatcher(
  actions.GET_STAFF_ONBOARD,
  worker.getStaffOnboardWorker,
  takeLatest
);

export const updateStaffOnboardWatcher = createWatcher(
  actions.UPDATE_STAFF_ONBOARD,
  worker.updateStaffOnboardWorker,
  takeLatest
);
