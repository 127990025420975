/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Table,
  Spin,
  Input,
  DatePicker,
  Modal,
  Steps,
  Row,
  Col,
  Progress,
  Tooltip,
  Button,
  message,
} from "antd";
import Heading from "../../../components/Heading";
import { camelCase } from "../../../utils";
import truck_icon from "../../../assests/images/manifestTruckIcon.svg";
import truck_unload from "../../../assests/images/truck-unload.png";
import truck_load from "../../../assests/images/truck-load.png";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import { APIs, getToken } from "../../../utils/worker";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./style.scss";
import { capitaliseWithHyphen } from "../../../utils";
import API from "../../../api";
import urls from "../../../api/urls";
import moment from "moment";

const TripListByVehicle = () => {
  const userId = JSON.parse(localStorage.getItem("userId"));
  const params = useParams();
  const { RangePicker } = DatePicker;
  const token = getToken();
  const [dataSource, setDataSource] = useState([]);
  const [routeDetails, setRouteDetails] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [utilization, setUtilization] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);
  const { GET_TRIP_UTILIZAION } = urls;
  useEffect(() => {
    getTripList();
  }, []);

  const getTripList = async (fromDate_, toDate_) => {
    setLoading(true);
    try {
      let url =
        fromDate_ && toDate_
          ? APIs.baseURL +
            "/lcr-service/v1/trip/report/" +
            userId?.userInfo?.premiseId +
            "?fromDate=" +
            fromDate_ +
            "&toDate=" +
            toDate_
          : APIs.baseURL +
            "/lcr-service/v1/trip/report/" +
            userId?.userInfo?.premiseId;
      let response = await API.Api(url, {
        headers: {
          Authorization: token,
        },
      }).getWithHeader();

      let temp = [];
      response.response?.data?.data?.map((item, index) => {
        let total_ = 0;
        item?.totalUtilization?.map((e) => {
          total_ = total_ + e;
        });
        let total = item?.totalUtilization
          ? item?.totalUtilization[item?.totalUtilization.length - 1]
          : 0;
        let route = "";
        item?.routesDTOs?.route.map((it, index) => {
          route =
            index === 0
              ? route + capitaliseWithHyphen(it?.hubName)
              : route + " - " + capitaliseWithHyphen(it?.hubName);
        });

        temp.push({
          key: index + 1,
          tripId: item?.tripId,
          startDate: item?.startDate,
          endDate: item?.endDate || "N/A",
          vehicleNum: item?.vehicleNum,
          driverName: item?.driverName,
          tripStatus: item?.tripStatus,
          routesDTOs: item?.routesDTOs,
          loadCapacity: item?.loadCapacity,
          totalUtilization: total,
          routeName: route,
        });
      });
      setDataSource(temp);
      setData(temp);
    } catch (err) {
      message.error(err.response.message);
    }
    setLoading(false);
  };

  const openModal = async (route, tripId, uti) => {
    if (uti) {
      setRouteDetails([...route]);
      getUtilization(tripId);
      setModalVisible(true);
    } else {
      message.warning("Utilization not available");
    }
  };

  const getUtilization = async (tripId) => {
    setLoading(true);
    try {
      let response = await API.Api(
        GET_TRIP_UTILIZAION + tripId
      ).getWithPremiseIdHeader();
      if (response.status) {
        response = response.response;
        let x = {
          vehicleLcrId: response?.data?.vehicleLcrId,
          tripId: response?.data?.tripId?.tripId,
          vehicleNum: response?.data?.tripId?.vehicleNum,
          fromPremise: response?.data?.fromPremise,
          status: response?.data?.status,
          createdDate: response?.data?.createdDate,
          updatedDate: response?.data?.updatedDate,
          loadCapacity: response?.data?.loadCapacity,
          removeWeight: response?.data?.removeWeight,
          utilization: response?.data?.utilization,
          totalutilizations: response?.data?.totalutilizations,
          totalRemoveweight: response?.data?.totalRemoveweight,
        };
        setUtilization(x);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      message.error("error");
      setLoading(false);
    }
  };

  const rangePickerHandle = async (e, date) => {
    setFromDate(date[0]);
    setToDate(date[1]);
    getTripList(date[0], date[1]);
  };

  const columns = [
    {
      title: "Sl No",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: 10,
    },
    {
      title: "Trip ID",
      dataIndex: "tripId",
      key: "tripId",
      render: (text, record) => {
        return (
          <span
            style={{ color: "#175eb6", cursor: "pointer", fontWeight: "600" }}
            onClick={() =>
              openModal(
                record?.routesDTOs.route,
                record?.tripId,
                record?.totalUtilization
              )
            }
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      with: 15,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      with: 15,
      render: (text, record) => (
        <>
          <span>{`${text.split(" ")[0].split("-").join("/")}`}</span>
        </>
      ),
    },
    {
      title: "Route",
      dataIndex: "routeName",
      key: "routeName",
      width: "auto",

      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "100px",
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Vehicle Number",
      dataIndex: "vehicleNum",
      key: "vehicleNum",
      with: 15,
    },
    {
      title: "Vehicle Capacity",
      dataIndex: "loadCapacity",
      key: "loadCapacity",
      with: 15,
      render: (text, record) => <span>{text + " kg"}</span>,
    },
    {
      title: "Average Utilization(%)",
      dataIndex: "totalUtilization",
      key: "totalUtilization",
      with: 15,
      render: (text, record) => <span>{text + "%"}</span>,
    },
    {
      title: "Driver Name",
      dataIndex: "driverName",
      key: "driverName",
      with: 15,
    },
    {
      title: "Status",
      dataIndex: "tripStatus",
      key: "tripStatus",
      with: 15,
      render: (text) => (
        <div
          style={{
            color: "#fff",
            backgroundColor:
              text === "INITIATED"
                ? "#F7B757"
                : text === "IN-TRANSIT"
                ? "#2A7CE0"
                : text === "COMPLETED"
                ? "#4DC64A"
                : "#4DC64A",
            padding: "5px",
            fontWeight: "500",
            borderRadius: "7px",
            width: "110px",
            textAlign: "center",
          }}
        >
          {text === "INITIATED"
            ? "Initiated"
            : text === "IN-TRANSIT"
            ? "In-Transit"
            : text === "Completed"
            ? "Completed"
            : "Completed"}
        </div>
      ),
    },
  ];

  const onSearch = (e) => {
    if (e.target.value.length < 1) {
      setPageNum(1);
    }
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(data, currValue)
        : filterArray(data, currValue);
    let temp = [];
    filteredData.map((item, index) => temp.push({ ...item, key: index + 1 }));
    setDataSource(temp);
  };

  const filterArray = (dataSource, currValue) =>
    dataSource?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );

  const handleDownload = () => {
    window.open(`${APIs.baseURL}/tat-service/v1/export/fm-lm`);
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  function handlePageChange(pagination) {
    setPageNum(pagination.current);
    setPageSize(pagination.pageSize);
  }
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="p-2">
        <Heading title="Trip List" navigateTo={"/reports"} id={7} />
        <div className="vehicle-container" style={{ backgroundColor: "#fff" }}>
          <div className="d-flex space-between align-cente p-1">
            <RangePicker
              disabledDate={disabledDate}
              style={{ borderRadius: "5px", height: "40px", width: "350px" }}
              onChange={rangePickerHandle}
            />
            <Button
              onClick={() =>
                window.open(
                  APIs.baseURL +
                    "/lcr-service/v1/export/utilization/" +
                    userId?.userInfo?.premiseId
                )
              }
              className="save-btn"
            >
              DOWNLOAD
            </Button>
          </div>

          <Table
            columns={columns}
            dataSource={inputSearchValue.length == 0 ? data : dataSource}
            pagination={{
              pageSize: pageSize,
              itemRender: itemRender,
              pageSizeOptions: [5, 10, 20, 50, 100],
              current: pageNum,
            }}
            onChange={handlePageChange}
            className="tat-table-fm table-head-sticky"
          />
        </div>
      </div>
      <Modal
        visible={modalVisible}
        title={"Utilization"}
        onCancel={() => setModalVisible(false)}
        footer={false}
        width={800}
      >
        <div className="p-1">
          <div className="d-flex space-between mb-2">
            <div
              style={{
                fontSize: "16px",
                color: "#444444",
                fontWeight: "500",
              }}
            >
              Trip ID :{" "}
              <span style={{ color: "#000" }}>{utilization?.tripId}</span>
            </div>
            <div
              style={{
                fontSize: "16px",
                color: "#444444",
                fontWeight: "500",
              }}
            >
              Vehicle No :{" "}
              <span style={{ color: "#000" }}>{utilization?.vehicleNum}</span>
            </div>
          </div>
          <Row>
            <Col span={16}>
              <Steps
                progressDot
                current={routeDetails.length - 1}
                direction="vertical"
                items={routeDetails?.map((item, index) => ({
                  title: item?.hubName,
                  description: (
                    <div className="mt-1 mb-1">
                      {utilization?.totalutilizations[index] ? (
                        <div>
                          <div
                            style={{
                              fontSize: "16px",
                              color: "gray",
                              fontWeight: "500",
                            }}
                          >
                            Load Capacity :{" "}
                            <span style={{ color: "#000" }}>
                              {utilization?.loadCapacity}
                            </span>
                          </div>
                          {index === 0
                            ? " "
                            : utilization?.totalRemoveweight && (
                                <div style={{ margin: "10px" }}>
                                  <img src={truck_unload} height={25} />
                                  &nbsp;&nbsp;&nbsp;
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      color: "#000",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {utilization?.totalRemoveweight[index - 1] +
                                      " Kg"}
                                  </span>
                                </div>
                              )}
                          <div style={{ margin: "10px" }}>
                            <img src={truck_load} height={25} />
                            &nbsp;&nbsp;&nbsp;
                            <span
                              style={{
                                fontSize: "16px",
                                color: "#000",
                                fontWeight: "500",
                              }}
                            >
                              {utilization?.totalutilizations[index] + " Kg"}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              color: "gray",
                              fontWeight: "500",
                            }}
                          >
                            Utilization :{" "}
                            <span style={{ color: "#000" }}>
                              {"(" +
                                (
                                  (100 *
                                    utilization?.totalutilizations[index]) /
                                  utilization?.loadCapacity
                                ).toFixed(2) +
                                "%)"}
                            </span>
                            &nbsp;&nbsp;
                            <Progress
                              percent={(
                                (100 * utilization?.totalutilizations[index]) /
                                utilization?.loadCapacity
                              ).toFixed(2)}
                              strokeColor={
                                (
                                  (100 *
                                    utilization?.totalutilizations[index]) /
                                  utilization?.loadCapacity
                                ).toFixed(2) < 40
                                  ? "#B70000"
                                  : (
                                      (100 *
                                        utilization?.totalutilizations[index]) /
                                      utilization?.loadCapacity
                                    ).toFixed(2) < 70
                                  ? "#ffbf00"
                                  : "green"
                              }
                              style={{ width: "200px" }}
                              status={"active"}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* {utilization?.totalutilizations[index] ? (
                        <div className="d-flex align-center">
                          <img src={truck_icon} height={25} />
                          &nbsp;
                          <span
                            style={{
                              fontSize: "16px",
                              color: "green",
                              fontWeight: "600",
                            }}
                          >
                            {utilization?.totalutilizations[index] + " Kg"}
                          </span>
                          &nbsp;
                          <span
                            style={{
                              fontSize: "16px",
                              color: "blue",
                              fontWeight: "600",
                            }}
                          >
                            {"(" +
                              (
                                (100 * utilization?.totalutilizations[index]) /
                                utilization?.loadCapacity
                              ).toFixed(2) +
                              "%)"}
                          </span>
                        </div>
                      ) : (
                        ""
                      )} */}
                    </div>
                  ),
                }))}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </Spin>
  );
};
export default TripListByVehicle;
