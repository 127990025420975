/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Input, Button, Table, Row, Col, message, Tooltip } from "antd";
import { useNavigate } from "react-router";
import {
  capitalize,
  serialNum,
  convertDate,
  capitaliseWithHyphen,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
} from "../../../utils";
import search_icon from "../../../assests/images/search-icon.svg";
import { ReactComponent as Copy_Icon } from "../../../assests/images/SVG/copy-icon.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import urls from "../../../api/urls";
import API from "../../../api";
import { APIs, getPremiseId } from "../../../utils/worker";
import { ReactComponent as Scan_check } from "../../../assests/images/SVG/check.svg";
import Heading from "../../../components/Heading";
import "./style.scss";
import ScannedShipment from "../../../components/ScannedShipment";

const { GET_CP_IN_SCAN_LIST } = urls;
const premiseId = getPremiseId();

const InScanCP = () => {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [showtoolTip, setShowtoolTip] = useState(false);
  const [loading, setLoading] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [totalPage, setTotalPage] = useState();

  const [totalScanned, setTotalScanned] = useState({
    tableTotal: 0,
    scannedCount: 0,
  });
  const tableLoading = {
    spinning: loading,
    indicator: (
      <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
    ),
  };

  useEffect(() => {
    getTableData();
  }, []);

  const getTableData = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_CP_IN_SCAN_LIST +
        premiseId?.split("Bearer ")[1] +
        "?pageNum=0&pageSize=500"
    ).getWithUserNPremiseHeader();
    if (response.status) {
      setLoading(false);

      setDataSource(response?.response?.data?.data);
      let totalScann = response?.response?.data?.data?.filter((val) => {
        return val.barcodeStatus !== "MANIFESTED_FOR_CP";
      });
      setTotalScanned({
        tableTotal: response?.response?.data?.data?.length,
        scannedCount: totalScann?.length,
      });
    } else {
      setLoading(false);
      message.error(response?.response?.data?.data?.message || "Network error");
    }
    // SetManifestedCp(mock);
  };

  const filterData = () => {
    const unique = [
      ...new Map(
        dataSource?.map((item) => [item["manifestId"], item])
      ).values(),
    ];

    let data = unique?.map((val) => {
      return { text: val.manifestId, value: val.manifestId };
    });

    return data;
  };

  function copyText(text) {
    setShowtoolTip(!showtoolTip);
    navigator.clipboard.writeText(text);
  }

  const columns = [
    {
      title: "Sl No",
      align: "center",
      render: (a, b, idx) => serialNum(10, 1, idx),
    },
    {
      title: "Awb No/Packet Id",
      dataIndex: "barcode",
      width: 200,
      key: "barcode",
      render: (text, val) => {
        return (
          <div className="d-flex">
            <div style={{ width: "170px" }}>
              <span style={{ marginRight: "15px" }}>{text}</span>
            </div>
            <Tooltip title={showtoolTip ? "Copied" : "Copy"}>
              <span
                style={{ marginRight: "10px" }}
                className="pointer"
                onClick={() => copyText(text)}
              >
                <Copy_Icon />
              </span>
            </Tooltip>

            {val?.barcodeStatus !== "MANIFESTED_FOR_CP" &&
              val?.barcodeStatus !== "DELIVERY_FAILED" && <Scan_check />}
          </div>
        );
      },
    },

    {
      title: "Manifest ID",
      dataIndex: "manifestId",
      filters: filterData(),
      onFilter: (value, record) => record.manifestId === value,
      key: "manifestId",
      width: 150,
    },

    {
      title: "Date",
      dataIndex: "createDate",
      key: "createDate",
      render: (text) => (
        <div style={{ whiteSpace: "nowrap" }}>{convertDate(text)}</div>
      ),
    },

    {
      title: "Mode",
      dataIndex: "travelBy",
      key: "travelBy",
      render: (text) => <div>{capitalize(text)}</div>,
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      key: "serviceType",
      render: (text) => <div>{capitalize(text)}</div>,
    },
    {
      title: "Status",
      dataIndex: "barcodeStatus",
      key: "barcodeStatus",
      render: (text) => capitaliseWithHyphen(text),
    },
  ];

  function openModal() {
    navigate("/last-mile-operations");
  }

  const onChange = (pagination, filters, sorter, extra) => {
    let totalScann = extra.currentDataSource.filter((val) => {
      return val.barcodeStatus !== "MANIFESTED_FOR_CP";
    });

    return setTotalScanned({
      tableTotal: extra.currentDataSource.length,
      scannedCount: totalScann?.length,
    });
  };

  const handleScan = async (id) => {
    setLoading(true);
    const replaceId = id?.toString()?.replace("ArrowDown", "");
    const geManifestId = dataSource?.find((val) => val?.barcode == +replaceId);
    const barcodes = {
      manifestId: geManifestId?.manifestId,
      awbNumbers: [+replaceId],
    };
    const response = await API.Api(
      `${APIs.baseURL}/last-mile/in-scan/v1/`
    ).postIdHeader(barcodes);
    if (response.status) {
      setLoading(false);
      let temp = [...dataSource];
      temp?.forEach((item) => {
        if (item.barcode === +replaceId) {
          item.barcodeStatus = "INSCANNED_AT_CP";
        }
      });
      setDataSource(temp);
      setTotalScanned({
        tableTotal: totalScanned.tableTotal,
        scannedCount: totalScanned.scannedCount + 1,
      });
      message.success(`${id} scanned successfully`);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  //--Barcode Scanner--//
  let barcode = "";
  let interval;
  const handleKeyDown = (event) => {
    if (interval) clearInterval(interval);
    if (event.code == "Enter") {
      if (barcode) handleScan(barcode);
      barcode = "";
      return;
    }
    if (event.key != "Shift") barcode += event.key;
    interval = setInterval(() => (barcode = ""), 20);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dataSource]);

  return (
    <div className="inscan-cp-container">
      <Row justify={"space-between"}>
        <Heading title={"CP In-scan Shipments"} />
      </Row>
      <div className="cp-in-scan-container">
        <div className="header">
          <Row justify={"space-between"}>
            <Col xs={24} lg={20} sm={20} md={20}>
              <div className="right">
                <Input
                  placeholder="Enter AWB number to scan"
                  bordered
                  style={{
                    borderRadius: "5px",
                    height: "40px",
                    maxWidth: "250px",
                  }}
                  suffix={<img src={search_icon} />}
                  onKeyPress={(event) => {
                    if (
                      checkNumbervalue(event) ||
                      checkSpace(event) ||
                      checkSpecialCharacter(event)
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onPressEnter={(e) => {
                    if (e.target.value.length > 6) {
                      handleScan(parseInt(e.target.value));
                    } else {
                      message.error("Enter correct AWB number");
                    }
                  }}
                />
              </div>
            </Col>
            <Col>
              <ScannedShipment
                scannedData={totalScanned?.scannedCount}
                totalData={totalScanned?.tableTotal}
              />
            </Col>
          </Row>
        </div>
        {/* <Row> */}
        <Table
          columns={columns}
          dataSource={dataSource?.sort(function (a, b) {
            return a.barcodeStatus !== "MANIFESTED_FOR_CP"
              ? 1
              : b.barcodeStatus !== "MANIFESTED_FOR_CP"
              ? -1
              : 0;
          })}
          className="table-head-sticky"
          rowKey={dataSource?.key}
          // scroll={{ x: 800, y: 1000 }}
          loading={tableLoading}
          pagination={false}
          onChange={onChange}
          table-head-sticky
        />
        {/* </Row> */}
      </div>
      <Row className="flex-end mt-2">
        <Button
          className="save-btn"
          style={{ textAlign: "end" }}
          onClick={openModal}
          // disabled={checkInScanned}
        >
          DONE
        </Button>
      </Row>
    </div>
  );
};

export default InScanCP;
