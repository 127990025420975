/* eslint-disable no-loss-of-precision */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */

import React, { useState, useEffect } from "react";
import { Form, Row, Col, Card, Checkbox } from "antd";

import { ReactComponent as Location } from "../../../../assests/images/locationicon.svg";
import { ReactComponent as Mail } from "../../../../assests/images/mail.svg";
import { ReactComponent as Phone } from "../../../../assests/images/credit-phone.svg";

import ReturnDrawer from "./RetailReturnDrawer";

const AddressCard = ({
  iconStyles,
  setReturnAddressDrawer,
  addresCardStyles,
  capitalize,
  setReturnCheck,
  returnCheck,
  handleReturnCheckBox,
  returnFormData,
  sameAsSenderAddress = true,
  checkReturnAdded,
  setCheckReturnAdded = () => {},
}) => {
  return (
    <div className="mt-1">
      <ReturnDrawer />
      <Card
        size="small"
        title={
          <div className="space-between">
            <span>Return Address</span>
            <span
              style={iconStyles}
              className="pointer"
              onClick={() => setReturnAddressDrawer(true)}
            >
              {!returnCheck ? "Add" : null}
            </span>
          </div>
        }
        style={addresCardStyles}
      >
        {checkReturnAdded == null ? (
          <div>
            <Checkbox
              // onChange={(e) => handleReturnCheck(e.target.checked)}
              onClick={handleReturnCheckBox}
              // checked={returnCheck}
              defaultChecked={returnCheck}
              className="mb-1"
            >
              Do you want to make the return address same as sender address?
            </Checkbox>
          </div>
        ) : (
          <>
            <p style={iconStyles}>{capitalize(returnFormData?.returnName)}</p>
            <Row>
              <Col lg={3} className="flex-start">
                <Location />
              </Col>
              <Col lg={21}>
                <p>{capitalize(returnFormData?.returnAddressLine1)}</p>
                <p style={{ marginTop: "-1rem" }}>
                  {capitalize(returnFormData?.returnAreaName) + ","}
                  {/* {"returnAreaName" && capitalize("returnAreaName") + ","} */}
                </p>
              </Col>
            </Row>
            {returnFormData?.returnMobileNumber && (
              <Row>
                <Col lg={3} className="flex-start ">
                  <Phone />
                </Col>
                <Col lg={21}>
                  <p>{returnFormData?.returnMobileNumber}</p>
                </Col>
              </Row>
            )}
            {returnFormData?.returnEmail && (
              <Row>
                <Col lg={3} className="flex-start">
                  <Mail />
                </Col>
                <Col lg={21}>
                  <p>{returnFormData?.returnEmail}</p>
                </Col>
              </Row>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default AddressCard;
