import { combineReducers } from "redux";

import actions from "../constants/manifestActions";
import {
  getFetchingState,
  getSuccessState,
  getErrorState,
  getResetState,
  createReducer,
} from "../utils/reducers";

import { defaultState } from "./config";

const getViewAllBookingsReducer = createReducer(defaultState, {
  [actions.GET_VIEWALLBOOKINGS]: getFetchingState,
  [actions.GET_VIEWALLBOOKINGS_SUCCESS]: getSuccessState,
  [actions.GET_VIEWALLBOOKINGS_ERROR]: getErrorState,
  [actions.GET_VIEWALLBOOKINGS_CLEAR]: getResetState,
});

const createManifestReducer = createReducer(defaultState, {
  [actions.CREATE_MANIFEST]: getFetchingState,
  [actions.CREATE_MANIFEST_SUCCESS]: getSuccessState,
  [actions.CREATE_MANIFEST_ERROR]: getErrorState,
  [actions.CREATE_MANIFEST_CLEAR]: getResetState,
});
const getManifestReducer = createReducer(defaultState, {
  [actions.GET_MANIFEST]: getFetchingState,
  [actions.GET_MANIFEST_SUCCESS]: getSuccessState,
  [actions.GET_MANIFEST_ERROR]: getErrorState,
  [actions.GET_MANIFEST_CLEAR]: getResetState,
});
///dev-b3
const getPacketBarcodeReducer = createReducer(defaultState, {
  [actions.GET_PACKET_BARCODE]: getFetchingState,
  [actions.GET_PACKET_BARCODE_SUCCESS]: getSuccessState,
  [actions.GET_PACKET_BARCODE_ERROR]: getErrorState,
  [actions.GET_PACKET_BARCODE_CLEAR]: getResetState,
});
const getManifestVehicleReducer = createReducer(defaultState, {
  [actions.GET_MANIFEST_VEHICLE]: getFetchingState,
  [actions.GET_MANIFEST_VEHICLE_SUCCESS]: getSuccessState,
  [actions.GET_MANIFEST_VEHICLE_ERROR]: getErrorState,
  [actions.GET_MANIFEST_VEHICLE_CLEAR]: getResetState,
});
const updateManifestDriverReducer = createReducer(defaultState, {
  [actions.PUT_MANIFEST_DRIVER]: getFetchingState,
  [actions.PUT_MANIFEST_DRIVER_SUCCESS]: getSuccessState,
  [actions.PUT_MANIFEST_DRIVER_ERROR]: getErrorState,
  [actions.PUT_MANIFEST_DRIVER_CLEAR]: getResetState,
});

const getPacketListBySatusReducer = createReducer(defaultState, {
  [actions.GET_PACKET_LIST_BY_STATUS]: getFetchingState,
  [actions.GET_PACKET_LIST_BY_STATUS_SUCCESS]: getSuccessState,
  [actions.GET_PACKET_LIST_BY_STATUS_ERROR]: getErrorState,
  [actions.GET_PACKET_LIST_BY_STATUS_CLEAR]: getResetState,
});

const getShipmentListBySatusReducer = createReducer(defaultState, {
  [actions.GET_SHIPMENT_LIST_BY_STATUS]: getFetchingState,
  [actions.GET_SHIPMENT_LIST_BY_STATUS_SUCCESS]: getSuccessState,
  [actions.GET_SHIPMENT_LIST_BY_STATUS_ERROR]: getErrorState,
  [actions.GET_SHIPMENT_LIST_BY_STATUS_CLEAR]: getResetState,
});

const updatePacketStatusReducer = createReducer(defaultState, {
  [actions.UPDATE_PACKET_STATUS]: getFetchingState,
  [actions.UPDATE_PACKET_STATUS_SUCCESS]: getFetchingState,
  [actions.UPDATE_PACKET_STATUS_ERROR]: getFetchingState,
  [actions.UPDATE_PACKET_STATUS_CLEAR]: getFetchingState,
});

const createPacketsReducer = createReducer(defaultState, {
  [actions.CREATE_PACKETS]: getFetchingState,
  [actions.CREATE_PACKETS_SUCCESS]: getSuccessState,
  [actions.CREATE_PACKETS_ERROR]: getErrorState,
  [actions.CREATE_PACKETS_CLEAR]: getResetState,
});

const getEligiblePacketsReducer = createReducer(defaultState, {
  [actions.GET_ELIGIBLE_PACKET]: getFetchingState,
  [actions.GET_ELIGIBLE_PACKET_SUCCESS]: getSuccessState,
  [actions.GET_ELIGIBLE_PACKET_ERROR]: getErrorState,
  [actions.GET_ELIGIBLE_PACKET_CLEAR]: getResetState,
});

const patchPacketShipmentReducer = createReducer(defaultState, {
  [actions.PATCH_PACKET_SHIPMENT]: getFetchingState,
  [actions.PATCH_PACKET_SHIPMENT_SUCCESS]: getSuccessState,
  [actions.PATCH_PACKET_SHIPMENT_ERROR]: getErrorState,
  [actions.PATCH_PACKET_SHIPMENT_CLEAR]: getResetState,
});

const patchManifestScanReducer = createReducer(defaultState, {
  [actions.PATCH_MANIFEST_SCAN]: getFetchingState,
  [actions.PATCH_MANIFEST_SCAN_SUCCESS]: getSuccessState,
  [actions.PATCH_MANIFEST_SCAN_ERROR]: getErrorState,
  [actions.PATCH_MANIFEST_SCAN_CLEAR]: getResetState,
});

const getManifestedListReducer = createReducer(defaultState, {
  [actions.GET_MANIFESTED_LIST]: getFetchingState,
  [actions.GET_MANIFESTED_LIST_SUCCESS]: getSuccessState,
  [actions.GET_MANIFESTED_LIST_ERROR]: getErrorState,
  [actions.GET_MANIFESTED_LIST_CLEAR]: getResetState,
});

const putManifestLockReducer = createReducer(defaultState, {
  [actions.PUT_MANIFEST_LOCK]: getFetchingState,
  [actions.PUT_MANIFEST_LOCK_SUCCESS]: getSuccessState,
  [actions.PUT_MANIFEST_LOCK_ERROR]: getErrorState,
  [actions.PUT_MANIFEST_LOCK_CLEAR]: getResetState,
});

const updateDriverDetailsReducer = createReducer(defaultState, {
  [actions.UPDATE_DRIVE_DETAILS]: getFetchingState,
  [actions.UPDATE_DRIVE_DETAILS_SUCCESS]: getSuccessState,
  [actions.UPDATE_DRIVE_DETAILS_ERROR]: getErrorState,
  [actions.UPDATE_DRIVE_DETAILS_CLEAR]: getResetState,
});

const getprintManifestReducer = createReducer(defaultState, {
  [actions.PRINT_MANIFEST]: getFetchingState,
  [actions.PRINT_MANIFEST_SUCCESS]: getSuccessState,
  [actions.PRINT_MANIFEST_ERROR]: getErrorState,
  [actions.PRINT_MANIFEST_CLEAR]: getResetState,
});

const patchChildShipmentReducer = createReducer(defaultState, {
  [actions.PATCH_CHILDSHIPMENT]: getFetchingState,
  [actions.PATCH_CHILDSHIPMENT_SUCCESS]: getSuccessState,
  [actions.PATCH_CHILDSHIPMENT_ERROR]: getErrorState,
  [actions.PATCH_CHILDSHIPMENT_CLEAR]: getResetState,
});

export default combineReducers({
  getViewAllBookingsReducer,
  createManifestReducer,
  getManifestReducer,
  getPacketBarcodeReducer,
  getPacketListBySatusReducer,
  getShipmentListBySatusReducer,
  updatePacketStatusReducer,
  createPacketsReducer,
  getEligiblePacketsReducer,
  getManifestVehicleReducer,
  patchPacketShipmentReducer,
  patchManifestScanReducer,
  getManifestedListReducer,
  updateManifestDriverReducer,
  putManifestLockReducer,
  updateDriverDetailsReducer,
  getprintManifestReducer,
  patchChildShipmentReducer,
});
