/* eslint-disable no-loss-of-precision */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */

import {
  Drawer,
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  Card,
  Radio,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  checkAlphabets,
  checkNumbervalue,
  checkSpecialCharacter,
  firstLetterCapitaliseDropDown,
} from "../../../../../utils";

// import "./style.scss";
import AutoCompleteAddress from "../../../CrediteBooking/AutoCompleteAddress";
import useClientAndDepartment from "../../../CrediteBooking/useClientAndDepartment ";
import { focusFirstInputAndScrollToTop } from "../../../../../components/CustomFunctions";

import Smcs_Loader from "../../../../../assests/images/loader-new.gif";

function RetailReturnAddressDrawer({
  setReturnFormData = () => {},
  openReturn,
  setReturnOpen = () => {},
  returnAddressform,
}) {
  const size = "large";

  const { userInfo } = JSON.parse(localStorage?.getItem("userId"));

  const { pinCodeServiceableStatus, getDeliveryPincode, areaList, loading } =
    useClientAndDepartment(userInfo, returnAddressform, "returnAddressform");

  const onClose = () => {
    setReturnOpen(false);
  };
  const onSubmitFormData = (value) => {
    setReturnFormData(value);
    setReturnOpen(false);
  };

  useEffect(() => {
    if (openReturn) {
      focusFirstInputAndScrollToTop("returnPincode");
    }
  }, [openReturn]);

  const [dropDownChanges, setdropDownChanges] = useState(false);

  useEffect(() => {
    document.querySelectorAll(".ant-select-item-option").forEach((option) => {
      option.classList.remove("ant-select-item-option-active");
    });
  }, [dropDownChanges]);

  const handleKeyDown = (event, dataOptions, formItemName, value = "value") => {
    switch (event.key) {
      case "Tab":
        setdropDownChanges(!dropDownChanges);
        // eslint-disable-next-line no-case-declarations
        let activeOption = document.querySelector(
          ".ant-select-item-option-active"
        );

        if (activeOption) {
          const value = activeOption.getAttribute("title");
          const optionExists = dataOptions.some(
            (option) => option?.value?.toLowerCase() == value?.toLowerCase()
          );
          if (optionExists) {
            returnAddressform.setFieldsValue({ [formItemName]: value });
          }
        }

        break;

      default:
        break;
    }
  };

  return (
    <div>
      <Drawer
        className="retail-return-drawer"
        title={"Add Return Address"}
        placement="right"
        size={size}
        onClose={onClose}
        open={openReturn}
      >
        <Spin
          spinning={loading}
          indicator={
            <img
              src={Smcs_Loader}
              style={{
                height: "50px",
                width: "50px",

                margin: "0 -25px",
              }}
            />
          }
        >
          <div style={{ padding: "5px 10px" }}>
            <Form
              //   {...formProps}
              form={returnAddressform}
              onFinish={onSubmitFormData}
              layout="vertical"
              className="global-form"
              scrollToFirstError={{
                behavior: "smooth",
                block: "center",
                inline: "center",
              }}
            >
              <Row className="global-form-row" gutter={20}>
                <Col lg={12}>
                  <Form.Item
                    id="returnPincode"
                    name="returnPincode"
                    label="Return PIN code"
                    validateStatus={pinCodeServiceableStatus?.status}
                    help={pinCodeServiceableStatus?.msg}
                    rules={[
                      {
                        required: true,
                        message: "Enter return PIN code",
                      },
                      () => ({
                        validator(rule, value = "") {
                          if (value.length > 0 && value.length < 6) {
                            return Promise.reject("Pincode should be 6 digits");
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                    onKeyPress={(event) => {
                      if (
                        checkNumbervalue(event) ||
                        checkSpecialCharacter(event)
                      ) {
                        event.preventDefault();
                      }
                    }}
                  >
                    <Input
                      onChange={getDeliveryPincode}
                      className="accpickupPincode"
                      placeholder="Enter return PIN code"
                      maxLength={6}
                      suffix={
                        <span
                          className={
                            pinCodeServiceableStatus?.serviceability ===
                            "Regular"
                              ? "green"
                              : pinCodeServiceableStatus?.serviceability ===
                                "Premium"
                              ? "orange"
                              : "red"
                          }
                        >
                          {pinCodeServiceableStatus?.stateCode}
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    lg={6}
                    sm={6}
                    label="Area"
                    type="select"
                    name="returnAreaName"
                    placeholder="Search area"
                    rules={[
                      {
                        required: true,
                        message: "Select area",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select area"
                      options={areaList}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onKeyDown={(event) =>
                        handleKeyDown(
                          event,
                          areaList,
                          "returnAreaName",
                          "value"
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} sm={12}>
                  <Form.Item
                    label="Name"
                    name="returnName"
                    rules={[
                      {
                        required: true,
                        message: "Enter name",
                      },
                    ]}
                    onKeyPress={(event) => {
                      event.target.value = firstLetterCapitaliseDropDown(
                        event?.target?.value
                      );
                    }}
                  >
                    <Input placeholder="Enter name" />
                  </Form.Item>
                </Col>
                <Col lg={12} sm={12}>
                  <Form.Item
                    label="Address"
                    name="returnAddressLine1"
                    placeholder="Enter address"
                    rules={[
                      {
                        required: true,
                        message: null,
                      },
                    ]}
                  >
                    <AutoCompleteAddress
                      form={returnAddressform}
                      addressDetailsCallback={null}
                      fieldName="returnAddressLine1"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    label="Mobile number"
                    name="returnMobileNumber"
                    rules={[
                      {
                        required: true,
                        message: "Enter mobile number",
                      },

                      () => ({
                        validator(rule, value = "") {
                          if (value?.length > 0 && value?.length < 10) {
                            return Promise.reject(
                              "Please enter 10 digits phone number"
                            );
                          } else if (parseInt(value.substring(1, 0)) === 0) {
                            return Promise.reject(
                              "Mobile number should not be 0"
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Enter phone number"
                      onKeyPress={(e) => {
                        if (/[^0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                        if (e.target.value.length >= 10) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    label="Email address"
                    name="returnEmail"
                    rules={[
                      {
                        type: "email",
                        message: "Give the correct email",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter email address"
                      // onBlur={(e) =>
                      //   form.setFieldValue(
                      //     "returnEmail",
                      //     e.target.value.toLocaleLowerCase()
                      //   )
                      // }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="flex-end">
                <Button
                  className=" save-btn"
                  onClick={returnAddressform?.submit}
                  // htmlType="submit"
                >
                  SAVE
                </Button>
              </Row>
            </Form>
          </div>
        </Spin>
      </Drawer>
    </div>
  );
}

export default RetailReturnAddressDrawer;
