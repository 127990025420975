/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Radio,
  DatePicker,
  TimePicker,
  Checkbox,
  InputNumber,
  Popconfirm,
} from "antd";
import moment from "moment";
import {
  capitalize,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
} from "../../../../utils";

import forward from "../../../../assests/images/middleMile/forward.svg";
import recurrenceIcon from "../../../../assests/images/middleMile/recurrence.svg";
import returnJourney from "../../../../assests/images/middleMile/return.svg";
import "./style.scss";

export const UpdateTripScheduleForm = ({
  form,
  updateTripSchdule,
  recurrence,
  kindOfJourney,
  allForwardTransitData,
  disabledDate,
  allReturnTransitData,
  recurrenceType,
  currentDayWeek,
  disableReturnDate,
  apiRecurrence,
  days,

  // handleCheckboxChange = () => {},
  handleForwardDate = () => {},
  handleForwardTime = () => {},
  handleReturnOriginDate = () => {},
  handleKindOfJourney = () => {},
  handleRecursivePattern = () => {},
  handleRecurrenceType = () => {},
  handleModalCancel = () => {},
  handleThisRemainEvent = () => {},
}) => {
  const [checkedValues, setCheckedValues] = useState();
  const [defaultDay, setdefaultDay] = useState();

  const weekdays = [
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
  ];

  useEffect(() => {
    if (recurrenceType === "weekly") {
      if (days && days[0] !== null) {
        setCheckedValues([currentDayWeek, ...days]);
      } else {
        setCheckedValues([currentDayWeek]);
      }
      setdefaultDay([currentDayWeek]);
    }
  }, [recurrenceType, currentDayWeek]);

  useEffect(() => {
    if (checkedValues) {
      const uniqueValues = new Set(checkedValues);
      const uniqueCheckvalues = [...uniqueValues];

      form.setFieldsValue({ days: uniqueCheckvalues });
    }
  }, [checkedValues]);

  const confirm = () => {
    const uniqueValues = new Set(checkedValues);
    const uniqueCheckvalues = [...uniqueValues];

    form.setFieldsValue({ days: uniqueCheckvalues });
    form.submit();
  };

  const handleCheckboxChange = (value) => {
    const updatedCheckedValues = checkedValues?.includes(value)
      ? checkedValues?.filter((val) => val !== value)
      : [...checkedValues, value];

    setCheckedValues(updatedCheckedValues);
    setdefaultDay(currentDayWeek);
  };

  return (
    <div>
      <Form
        layout="vertical"
        className="global-form"
        form={form}
        onFinish={updateTripSchdule}
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
        initialValues={{
          days: days,
        }}
      >
        <Row
          className="pt-1 global-form-row create-shedule-container"
          gutter={24}
        >
          <Col span={12} className="mb-1">
            <Form.Item name="tripId" label={"Trip Id"}>
              <Input placeholder="Enter trip name" disabled />
            </Form.Item>
          </Col>

          <Col span={12} className="mb-1">
            <Form.Item
              label="Kind of journey"
              name={"journeyType"}
              rules={[
                {
                  required: true,
                  message: "Please select Kind of journey",
                },
              ]}
            >
              <Radio.Group
                value="forward"
                onChange={(e) => handleKindOfJourney(e.target.value)}
              >
                <Radio value={"both"}>Forward & RL</Radio>
                <Radio value={"forward"}>Forward</Radio>
                <Radio value={"return"}>RL</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={12} className="mb-1">
            <Form.Item name={"vehicleId"} label="Vehicle number">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12} className="mb-1">
            <Form.Item name="routeName" label="Route">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12} className="mb-1">
            <Form.Item name="driverName" label="Driver name">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12} className="mb-1">
            <Form.Item name="driverNumber" label={"Driver’s phone number"}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12} className="mb-1">
            <Form.Item name="vehicleFeet" label={"Vehicle Feet"}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12} className="mb-1">
            <Form.Item name="vehicleCapacity" label={"Vehicle capacity (KG)"}>
              <Input disabled />
            </Form.Item>
          </Col>

          {kindOfJourney === "forward" || kindOfJourney === "both" ? (
            <>
              <Col span={24} className="">
                <Row>
                  <div>
                    <img src={forward} />
                  </div>
                  <div>
                    <h3 className="ml-1">Forward Journey</h3>
                  </div>
                </Row>
                <hr className="divider mb-2" />
              </Col>

              {allForwardTransitData?.map((val, index) => {
                return (
                  <>
                    <Col
                      span={index == 0 ? 8 : 8}
                      className="mb-1 arrival-container"
                    >
                      <Form.Item
                        label={
                          <Row className="arrival-label-container">
                            <div>
                              {index === allForwardTransitData?.length - 1
                                ? "Destination"
                                : index == 0
                                ? "Origin"
                                : "Transit " + index}
                            </div>
                          </Row>
                        }
                      >
                        <div className="d-flex">
                          <Input
                            disabled
                            // capitalize
                            defaultValue={capitalize(val?.premiseName)}
                          />
                        </div>
                      </Form.Item>
                    </Col>

                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"arrivalDate" + index}
                        label="Arrival date"
                        rules={[
                          {
                            required: true,
                            message: "Select Date",
                          },
                        ]}
                      >
                        <DatePicker
                          onChange={handleForwardDate}
                          disabled={index !== 0}
                          format={"DD-MM-YYYY"}
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"arrivalTime" + index}
                        label="Arrival time"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <TimePicker
                          use12Hours
                          format="h:mm A"
                          onChange={(e) => handleForwardTime(e, "forward")}
                          disabled={index !== 0}
                        />
                      </Form.Item>
                    </Col>
                    {/* // */}
                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"departureDate" + index}
                        label="Departure date"
                        rules={[
                          {
                            required: true,
                            message: "Select Date",
                          },
                        ]}
                      >
                        <DatePicker
                          onChange={handleForwardDate}
                          // disabled={index !== 0}
                          disabled
                          format={"DD-MM-YYYY"}
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"departureTime" + index}
                        label="Departure time"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <TimePicker
                          use12Hours
                          format="h:mm A"
                          onChange={(e) => handleForwardTime(e, "forward")}
                          // disabled={index !== 0}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </>
                );
              })}
            </>
          ) : null}
          {kindOfJourney === "return" || kindOfJourney === "both" ? (
            <>
              <Col span={24} className="mt-2">
                <Row>
                  <div>
                    <img src={returnJourney} />
                  </div>
                  <div>
                    <h3 className="ml-1">Return Journey</h3>
                  </div>
                </Row>
                <hr className="divider mb-2" />
              </Col>

              {allReturnTransitData?.map((val, index) => {
                return (
                  <>
                    <Col
                      span={index == 0 ? 8 : 8}
                      className="mb-1 arrival-container"
                    >
                      <Form.Item
                        label={
                          <Row className="arrival-label-container">
                            <div>
                              {index === allReturnTransitData?.length - 1
                                ? "Destination"
                                : index == 0
                                ? "Origin"
                                : "Transit " + index}
                            </div>
                          </Row>
                        }
                      >
                        <Input
                          disabled
                          defaultValue={capitalize(val?.premiseName)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"returnArrivalDate" + index}
                        label="Arrival date"
                        rules={[
                          {
                            required: true,
                            message: "Select Date",
                          },
                        ]}
                      >
                        <DatePicker
                          onChange={handleReturnOriginDate}
                          disabled={index !== 0}
                          format={"DD-MM-YYYY"}
                          disabledDate={disableReturnDate}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"returnArrivalTime" + index}
                        label="Arrival time"
                        rules={[
                          {
                            required: true,
                            message: "Select time",
                          },
                        ]}
                      >
                        <TimePicker
                          use12Hours
                          format="h:mm A"
                          onChange={(e) => handleForwardTime(e, "return")}
                          disabled={index !== 0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"returnDepartureDate" + index}
                        label="Departure date"
                        rules={[
                          {
                            required: true,
                            message: "Select Date",
                          },
                        ]}
                      >
                        <DatePicker
                          onChange={handleReturnOriginDate}
                          // disabled={index !== 0}
                          disabled
                          format={"DD-MM-YYYY"}
                          disabledDate={disableReturnDate}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"returnDepartureTime" + index}
                        label="Departure time"
                        rules={[
                          {
                            required: true,
                            message: "Select time",
                          },
                        ]}
                      >
                        <TimePicker
                          use12Hours
                          format="h:mm A"
                          onChange={(e) => handleForwardTime(e, "return")}
                          // disabled={index !== 0}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </>
                );
              })}

              {/*  */}
            </>
          ) : null}

          <Col span={12} className="mb-1">
            <Form.Item
              label="Recurrence Required?"
              name={"recursive"}
              rules={[
                {
                  required: true,
                  message: "Please select Kind of journey",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => handleRecursivePattern(e.target.value)}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24} className="mb-1"></Col>

          {recurrence && (
            <>
              <Col span={24} className="">
                <Row>
                  <div>
                    <img src={recurrenceIcon} />
                  </div>
                  <div>
                    <h3 className="ml-1">Recurrence Pattern</h3>
                  </div>
                </Row>
              </Col>
              <Col span={24}>
                <hr className="divider mb-2" />
              </Col>
              <Col span={8} className="mb-1">
                <Form.Item
                  label=""
                  name={"recursiveType"}
                  rules={[
                    {
                      required: true,
                      message: "Please select recurrence",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => handleRecurrenceType(e.target.value)}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Radio value={"daily"}>Daily</Radio>
                      <Radio value={"weekly"}>Weekly</Radio>
                    </div>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={16} className="mb-1">
                {recurrenceType === "weekly" ? (
                  <>
                    <h4>Recur every Week(s) on </h4>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name={"days"}
                          rules={[
                            {
                              required: true,
                              message: "Please select recurrence",
                            },
                          ]}
                        >
                          <Row>
                            {weekdays?.map((day, index) => (
                              <Col span={6} key={index} className="mb-1">
                                <Checkbox
                                  key={index}
                                  checked={checkedValues?.includes(day?.value)}
                                  disabled={defaultDay?.includes(day?.value)}
                                  onChange={() =>
                                    handleCheckboxChange(day?.value)
                                  }
                                >
                                  {day?.label}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Col>
              <Col span={24} className="mb-1">
                <div className="app-container">
                  <Row>
                    <h4>Ends After</h4>
                    <Form.Item
                      label=""
                      name={"noOfRecursion"}
                      rules={[
                        {
                          required: true,
                          message: "Please select recurrence",
                        },
                      ]}
                    >
                      <InputNumber
                        min={1}
                        step={1}
                        maxLength={3}
                        className="custom-input"
                        onKeyPress={(event) => {
                          if (
                            checkSpecialCharacter(event) ||
                            checkSpace(event) ||
                            checkNumbervalue(event)
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                    <h4>Occurences</h4>
                  </Row>
                </div>
              </Col>
            </>
          )}

          <Col span={24}>
            <hr className="divider m-0" />
          </Col>
          <Col span={24} className="flex-end p-1">
            <Button
              className="cancel-btn w-13"
              onClick={() => {
                handleModalCancel(0);
              }}
            >
              CANCEL
            </Button>

            {apiRecurrence && recurrence ? (
              <Popconfirm
                title={
                  <>
                    <div>
                      <h3>Do you want to change the recurrence for?</h3>
                    </div>
                    <Radio.Group
                      defaultValue={"This and Remainings"}
                      onChange={(e) => handleThisRemainEvent(e.target.value)}
                    >
                      <div>
                        <Radio value={"Only This"}>
                          <h4>Only for this</h4>
                        </Radio>
                      </div>

                      <div>
                        <Radio value={"This and Remainings"}>
                          <h4>This and remaining</h4>
                        </Radio>
                      </div>
                    </Radio.Group>
                  </>
                }
                onConfirm={confirm}
                okText="Yes"
                cancelText="No"
              >
                <Button htmlType="submit" className="save-btn ml-1 w-13">
                  SAVE
                </Button>
              </Popconfirm>
            ) : (
              <Button htmlType="submit" className="save-btn ml-1 w-13">
                SAVE
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};
