import React from "react";
import { Tabs } from "antd";
import CpWiseManifest from "./CpWiseManifest";
import ConsolidatedManifest from "./ConsolidatedList";
import "./style.scss";
import Heading from "../../../components/Heading";
import sorting from "../../../assests/images/SVG/sort-white.svg";

const items = [
  {
    key: "1",
    label: "Consolidated Shipment List",
    children: <ConsolidatedManifest />,
  },
  {
    key: "2",
    label: "CP Wise Shipment List",
    children: <CpWiseManifest />,
  },
];
const index = () => {
  return (
    <div className="p2 consolidated-tab-cntr">
      <Heading
        title={"In Scan"}
        nextPage={"/sort-shipment"}
        nextPageTitle={"Sort Shipment"}
        imgSrc={sorting}
      />
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};
export default index;
