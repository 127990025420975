//client onboard

import actions from "../constants/onboardingActions";

export const patchClientOnboard = (data, id) => ({
  type: actions.CLIENT_ONBOARD_PATCH,
  data,
  id,
});

export const patchClientOnboardClear = () => ({
  type: actions.CLIENT_ONBOARD_PATCH_CLEAR,
});

export const getClientOnboard = (data) => ({
  type: actions.GET_CLIENT_ONBOARD,
  data,
});

export const getClientOnboardClear = () => ({
  type: actions.GET_CLIENT_ONBOARD_CLEAR,
});

export const getClientPincodeMapping = (data) => ({
  type: actions.GET_CLIENT_PINCODE_MAPPING,
  data,
});

export const getStaffOnboard = (data) => ({
  type: actions.GET_STAFF_ONBOARD,
  data,
});

export const getStaffOnboardClear = () => ({
  type: actions.GET_STAFF_ONBOARD_CLEAR,
});

export const updateStaffOnboard = (data) => ({
  type: actions.UPDATE_STAFF_ONBOARD,
  data,
});

export const updateStaffOnboardClear = () => ({
  type: actions.UPDATE_STAFF_ONBOARD_CLEAR,
});
