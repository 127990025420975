import React from "react";
import {  Table,DatePicker } from "antd";
import { serialNum } from "../../utils";



const ViewPrs = () => {
  const { RangePicker } = DatePicker;
  const columns = [
    {
      title: "Sl No",
      align: "center",
      with: 10,
      render: (a, b, idx) => serialNum(10, 1, idx),
    },
    {
      title: "PRS Number",
      dataIndex: "PRS Number",
      key: "PRSNumber",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "PIN Code",
      dataIndex: "PIN Code",
      key: "PIN Code",
    },
    {
      title: "Field Executive",
      dataIndex: "Field Executive",
      key: "Field Executive",
    },
    {
      title: "Successful Pick-up",
      dataIndex: "Successful Pick-up",
      key: "Successful Pick-up",
    },
    {
      title: "Failed to Pick-up",
      dataIndex: "Failed to Pick-up",
      key: "Failed to Pick-up",
    },
    {
      title: "PRS Type",
      dataIndex: "PRS Type",
      key: "PRS Type",
    },
  ];
  return (
    <div className="prs-container master-main-container">
      <div
        className="p-1 header"
        style={{ display: "flex", justifyContent: "end", alignItems: "center" }}
      >
        <RangePicker className="input-field-style"/>
      </div>
      <Table columns={columns} dataSource={""}/>
    </div>
  );
};

export default ViewPrs;
