/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Table, Input, Button } from "antd";
import { useState } from "react";
import { getToken } from "../../../utils/worker";
import { APIs } from "../../../utils/worker";
import { camelCase } from "../../../utils";
import axios from "axios";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getPincodeMapping } from "../../../actionsMethods/masterMethods";
import Heading from "../../../components/Heading";
import postcard from "../../../assests/images/master/postcard.svg";
import zone_location_icon from "../../../assests/images/master/zone-location-icon.svg";
import zone_location_circle from "../../../assests/images/master/zone-location-circle.svg";
import city_icon from "../../../assests/images/master/city.svg";
import scooter from "../../../assests/images/master/scooter.svg";
import with_in_state from "../../../assests/images/master/with_in_state.svg";
import print_icon from "../../../assests/images/master/print.svg";
import download_icon from "../../../assests/images/master/download.svg";
import rest_of_india from "../../../assests/images/master/restofindia-icon.svg";
import search_icon from "../../../assests/images/search-icon.svg";
import PinCodeMappingModal from "./PinCodeMappingModal";
import "./style.scss";
import { serialNum } from "../../../utils";

const index = () => {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [modalName, setModalName] = useState();
  const [modalData, setModalData] = useState();
  const [loading, setLoading] = useState(false);
  const [mainData, setMainData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationShow, setPaginationShow] = useState(false);
  const [inputSearchVal, setInputSearchVal] = useState("");
  const token = getToken();
  const getPincodeMappingResponse = useSelector(
    (state) => state?.masterReducer?.getPincodeMappingReducer,
    shallowEqual
  );
  const { result, fetching } = getPincodeMappingResponse;
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const columns = [
    {
      title: "Sl No",
      width: 70,
      fixed: "left",
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={postcard} />
          <span>PIN Code</span>
        </div>
      ),
      dataIndex: "pincode",
      width: 130,
      fixed: "left",
      render: (text) => (
        <span className="pincode-count-wrapper">
          <div className="text">{text || "------"}</div>
          {text?.split(",")?.length > 2 ? (
            <Button
              className="pin-code-count"
              onClick={() => modalStatusHandle("PIN code", text)}
            >
              +{text?.split(",")?.length - 2}
            </Button>
          ) : null}
        </span>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={zone_location_icon} />
          <span>Zone</span>
        </div>
      ),
      dataIndex: "zone",
      render: (text) => (
        <div className="text">{camelCase(text) || "------"}</div>
      ),
      width: 100,
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={zone_location_icon} />
          <span>State</span>
        </div>
      ),
      dataIndex: "state",
      render: (text) => (
        <div className="text">{camelCase(text) || "------"}</div>
      ),
      width: 140,
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={city_icon} />
          <span>City</span>
        </div>
      ),
      dataIndex: "city",
      render: (text) => (
        <div className="text">{camelCase(text) || "------"}</div>
      ),
      width: 140,
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={zone_location_circle} />
          <span>My Zone 1</span>
        </div>
      ),
      dataIndex: "myZoneOne",
      width: 130,
      render: (text) => (
        <span className="pincode-count-wrapper">
          <div className="text">{text || "------"}</div>
          {text?.split(",")?.length > 2 ? (
            <Button
              className="pin-code-count"
              onClick={() => modalStatusHandle("My Zone 1", text)}
            >
              +{text?.split(",").length - 2}
            </Button>
          ) : null}
        </span>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={zone_location_circle} />
          <span>My Zone 2</span>
        </div>
      ),
      dataIndex: "myZoneTwo",
      width: 130,
      render: (text) => (
        <span className="pincode-count-wrapper">
          <div className="text">{text || "------"}</div>
          {text?.split(",")?.length > 2 || "" ? (
            <Button
              className="pin-code-count"
              onClick={() => modalStatusHandle("My Zone 2", text)}
            >
              +{text?.split(",")?.length - 2 || ""}
            </Button>
          ) : null}
        </span>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={scooter} />
          <span>Local</span>
        </div>
      ),
      dataIndex: "local",
      width: 130,
      render: (text) => (
        <span className="pincode-count-wrapper">
          <div className="text">{text || "------"}</div>
          {text?.split(",")?.length > 2 || "" ? (
            <Button
              className="pin-code-count"
              onClick={() => modalStatusHandle("Local", text)}
            >
              +{text?.split(",")?.length - 2 || ""}
            </Button>
          ) : null}
        </span>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={with_in_state} />
          <span>Within State</span>
        </div>
      ),
      dataIndex: "withInState",
      width: 130,
      render: (text) => (
        <span className="pincode-count-wrapper">
          <div className="text">{text || "------"}</div>
          {text?.split(",")?.length > 2 || "" ? (
            <Button
              className="pin-code-count"
              onClick={() => modalStatusHandle("Within State", text)}
            >
              +{text?.split(",")?.length - 2 || ""}
            </Button>
          ) : null}
        </span>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={city_icon} />
          <span>Metro</span>
        </div>
      ),
      dataIndex: "metro",
      width: 130,
      render: (text) => (
        <span className="pincode-count-wrapper">
          <div className="text">{text || "------"}</div>
          {text?.split(",")?.length > 2 || "" ? (
            <Button
              className="pin-code-count"
              onClick={() => modalStatusHandle("Metro", text)}
            >
              +{text?.split(",")?.length - 2 || ""}
            </Button>
          ) : null}
        </span>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={zone_location_circle} />
          <span>Special locations</span>
        </div>
      ),
      dataIndex: "specialPlaces",
      width: 130,
      render: (text) => (
        <span className="pincode-count-wrapper">
          <div className="text">{text || "------"}</div>
          {text?.split(",")?.length > 2 || "" ? (
            <Button
              className="pin-code-count"
              onClick={() => modalStatusHandle("Special Places", text)}
            >
              +{text?.split(",")?.length - 2 || ""}
            </Button>
          ) : null}
        </span>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={rest_of_india} />
          <span>Rest of India</span>
        </div>
      ),
      dataIndex: "restOfIndia",
      width: 130,
      render: (text) => (
        <span className="pincode-count-wrapper">
          <div className="text">{text || "------"}</div>
          {text?.split(",")?.length > 2 || "" ? (
            <Button
              className="pin-code-count"
              onClick={() => modalStatusHandle("Rest Of India", text)}
            >
              +{text?.split(",")?.length - 2 || ""}
            </Button>
          ) : null}
        </span>
      ),
    },
  ];
  const downloadHandle = () => {
    window.open(`${APIs.baseURL}/pincode-service/v1/pincode-mapping/download`);
  };
  const printHandle = () => {
    window.open(`${APIs.baseURL}/pincode-service/v1/pincode-mapping/print`);
  };
  const searchPincodeMapping = async (e) => {
    setInputSearchVal(e.target.value);
    if (e.target.value.length > 2) {
      setLoading(true);
      setPaginationShow(true);
      try {
        let res = await axios.get(
          `${APIs.baseURL}/pincode-service/v1/pincode-mapping/search/${e.target.value}`,
          {
            headers: {
              Authorization: token,
              token: token.split("Bearer ")[1],
            },
          }
        );
        setCurrentPage(0);
        setMainData(res?.data);
        setTotalCount(res?.data?.length);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    if (e.target.value.length < 3) {
      setPageNum(0);
      dispatch(getPincodeMapping(0, 10));
      setCurrentPage(0);
      // setPageSize(10);
      setLoading(false);
      // setPaginationShow(false);
      setTotalCount(result?.count);
    }
  };
  const modalStatusHandle = (name, text) => {
    setModalName(name);
    setModalData(text);
    if (modalVisible) setModalVisible(true);
    else setModalVisible(true);
  };
  const pageChangeHandle = (pagination) => {
    inputSearchVal
      ? ""
      : dispatch(getPincodeMapping(pagination?.current, pagination?.pageSize));

    setPageNum(pagination?.current);
    setPageSize(pagination?.pageSize);
    setCurrentPage(pagination?.current);
  };
  useEffect(() => {
    dispatch(getPincodeMapping(currentPage, pageSize));
  }, []);
  useEffect(() => {
    if (result !== null) {
      // setPinCodeData(result?.data);
      setMainData(result?.data);
      setTotalCount(result?.count);
    }
  }, [result]);
  return (
    <div className="pincode-mapping-container p-2">
      <Heading title={"PIN Code Mapping Guide"} />
      <div className="pincode-mapping-wrapper">
        <div className="search-container">
          <Input
            placeholder="Search by keywords (PIN code / City / State / Zone)"
            bordered
            onChange={searchPincodeMapping}
            style={{ borderRadius: "5px", height: "40px", width: "400px" }}
            suffix={<img src={search_icon} />}
          />
          <div className="buttons">
            <Button onClick={downloadHandle}>
              <img src={download_icon}></img> DOWNLOAD
            </Button>
            {/* <Button onClick={printHandle} disabled={true}>
              <img src={print_icon}></img>PRIENT
            </Button> */}
          </div>
        </div>

        <Table
          className="table-head-sticky"
          onChange={pageChangeHandle}
          loading={fetching || loading}
          defaultCurrent={1}
          columns={columns}
          dataSource={[...mainData]}
          pagination={{
            pageSize: pageSize,
            total: totalCount,
            itemRender: itemRender,
            current: currentPage == 0 ? currentPage + 1 : currentPage,
          }}
        />

        <PinCodeMappingModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          modalName={modalName}
          modalData={modalData}
        />
      </div>
    </div>
  );
};

export default index;
