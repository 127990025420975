import React, { useState } from "react";
import { Tabs } from "antd";
import BaggingAutomate from "./baggingAutomate";
import Heading from "../../../components/Heading";
import lcr_white from "../../../assests/images/SVG/lcr-white.svg";
import BaggingAndShipment from "../LCRGeneration/BaggingAndShipment";

const baggingTab = () => {
  const [step, setStep] = useState("1");
  const onChange = (key) => {
    setStep(key);
  };
  const items = [
    {
      key: "1",
      label: "Bag Finalization",
      children: <BaggingAutomate />,
    },
    {
      key: "2",
      label: "Eligible Bags & Shipments",
      children: <BaggingAndShipment />,
    },
  ];
  return (
    <div className="p-2">
      <Heading
        title={"Bagging"}
        nextPage={"/create-or-update-trip"}
        nextPageTitle={"LCR GENERATION"}
        imgSrc={lcr_white}
      />
      <Tabs
        destroyInactiveTabPane
        defaultActiveKey={"1"}
        activeKey={step}
        items={items}
        onChange={onChange}
        style={{ marginTop: "-1.5rem" }}
      />
    </div>
  );
};

export default baggingTab;
