import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import { useNavigate } from "react-router";
import TripList from "./tripList";
// import CreateLCR from "./createLCR";
import back_img from "../../../assests/images/rightVector.svg";
import "./style.scss";

const LCR = () => {
  // const history = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState("1");

  const items = [
    {
      key: "1",
      label: "View Trip",
      children: <TripList />,
    },
  ];

  useEffect(() => {
    setStep("1");
  }, []);

  const onChange = (key) => {
    setStep(key);
  };

  return (
    <div className="consolidated-tab-cntr">
      <div className="top-wrapper space-between">
        <div className="heading align-center">
          <img
            src={back_img}
            onClick={() => navigate("/create-or-update-trip")}
            style={{ cursor: "pointer" }}
          />
          <p
            style={{
              margin: "0",
              paddingLeft: "5px",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "32px",
              letterSpacing: "-0.25px",
              color: "#061624",
            }}
          >
            <b>LCR</b>
          </p>
        </div>
      </div>
      <Tabs
        destroyInactiveTabPane
        defaultActiveKey={"1"}
        activeKey={step}
        items={items}
        onChange={onChange}
      />
    </div>
  );
};
export default LCR;
