import cash_booking from "../assests/images/master/cash-booking-ratecard.svg";
import booking_ratecard from "../assests/images/master/account-booking-ratecard.svg";
import middle_mile_tat from "../assests/images/master/middle-mile-tat.svg";
import first_last_tat from "../assests/images/master/first-last-tat.svg";
import tatConfigurations from "../assests/images/master/tatConfigurations.svg";
import tatCalculator from "../assests/images/SVG/tat-calc.svg";
import addStateMaster from "../assests/images/master/state_master.svg";
import addRateCardConfig from "../assests/images/master/rate_card_config.svg";
import LCRThrshold from "../assests/images/LCRThrshold.svg";

export const childMaster = [
  {
    id: 1,
    parentId: 5,
    parentHeading: "Rate Card Master",
    imgSrc: cash_booking,
    title: "Retail Booking Rate Card",
    route: "/cash-booking-ratecard",
    disable: false,
  },
  {
    id: 2,
    parentId: 5,
    parentHeading: "Rate Card Master",
    imgSrc: booking_ratecard,
    title: "Credit Booking Rate Card",
    route: "/account-booking-ratecard",
    disable: false,
  },
  {
    id: 3,
    parentId: 7,
    parentHeading: "TAT master",
    imgSrc: first_last_tat,
    title: "First & Last Mile TAT",
    route: "/first-last-mile-tat",
    disable: false,
  },
  {
    id: 4,
    parentId: 7,
    parentHeading: "TAT master",
    imgSrc: middle_mile_tat,
    title: "Middle Mile TAT",
    route: "/middle-mile-tat",
    disable: false,
  },
  {
    id: 5,
    parentId: 7,
    parentHeading: "TAT master",
    imgSrc: tatConfigurations,
    title: "TAT Configurations",
    route: "/tat-config",
    disable: false,
  },
  {
    id: 6,
    parentId: 7,
    parentHeading: "TAT master",
    imgSrc: tatCalculator,
    title: "TAT Calculator",
    route: "/tat-calculator",
    disable: false,
  },
  {
    id: 7,
    parentId: 9,
    parentHeading: "Billing Configuration",
    imgSrc: addStateMaster,
    title: "Add State Master",
    route: "/add-state-master",
    disable: false,
  },
  {
    id: 8,
    parentId: 9,
    parentHeading: "Billing Configuration",
    imgSrc: addRateCardConfig,
    title: "Add Billing Config",
    route: "/rate-card-list",
    disable: false,
  },
  {
    id: 9,
    parentId: 10,
    parentHeading: "HubOps Configurations",
    imgSrc: LCRThrshold,
    title: "LCR Threshold",
    route: "/lcr-threshold",
    disable: false,
  },
];
