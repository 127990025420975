// import { Logger } from "aws-amplify";
import { createStore, applyMiddleware, compose } from "redux";
// import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import RootReducer from "../root-reducers";
import RootSaga from "../sagas";

const sagaMiddleware = createSagaMiddleware();

const initialValues = {};

const store = createStore(
  RootReducer,
  initialValues,
  compose(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(RootSaga);

export default store;
