import React, { useState, useEffect, useRef } from "react";
import Heading from "../../../../components/Heading";
import "./style.scss";
import { Tabs, Form, Row, Col, Button, Input, Spin, message } from "antd";
import LoadingAndUnloading from "./LoadingAndUnloadin";
import HeavyVehicleRestrictions from "./HeavyVehicleRestrictions";
import urls from "../../../../api/urls";
import API from "../../../../api";
import { checkSpace, checkNumbervalue } from "../../../../utils";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";

function TatConfiguration() {
  const [activeKey, setActiveKey] = useState("1");
  const [loading, setLoading] = useState(false);
  const [vehicleNumber, setVehicleNumber] = useState({});
  const [breakTime, setBreakTime] = useState({});
  // const [sendVehicleOrbreakTime, setsendVehicleOrbreakTime] = useState(null);
  const [onChangeId, setOnChangeId] = useState(null);
  let { GET_CONFIG_LIST, GET_CONFIG_LIST_UPDATE } = urls;
  const inputRef = useRef(null);

  const scrollToRef = () =>
    inputRef.current.scrollIntoView({ behavior: "smooth" });
  const [form] = Form.useForm();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    let getConfigResponse = await API.Api(
      GET_CONFIG_LIST
    ).getWithPremiseHeader();
    if (getConfigResponse?.status) {
      const arrOfData = getConfigResponse?.response?.data;
      let a = arrOfData?.find((e) => e.id == 5);
      let b = arrOfData?.find((e) => e.id == 6);
      setVehicleNumber(a);
      setBreakTime(b);
      form.setFieldValue("vehicleNumber", a?.value);
      form.setFieldValue("breakTime", b?.value);
    }
    setLoading(false);
  };

  const changeTab = (key) => {
    setActiveKey(key);
  };

  const onChangeHandlervehicleNumber = (e) => {
    setVehicleNumber({
      ...vehicleNumber,
      value: e,
    });
    setOnChangeId(vehicleNumber?.id || "");
  };

  const onChangeHandlervehicleBreak = (e) => {
    setBreakTime({
      ...breakTime,
      value: e,
    });
    setOnChangeId(breakTime?.id || "");
  };

  const saveData = async () => {
    let payload = [vehicleNumber, breakTime];
    let response = await API.Api(GET_CONFIG_LIST_UPDATE + "5,6").put(payload);
    if (response.status) {
      setOnChangeId(null);
      getData();
      message.success("Data Updated Successfully");
    } else {
      message.error(response?.response?.data?.message);
    }
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="p-2">
        <Heading title={"TAT Configuration"} />
        <div className="config-template mb-2">
          <p className="f-16 fw-500 title-color pt-1 pb-1 pl-1">
            Break Time Configuration
          </p>
          <hr className="divider" />
          <>
            <Form
              form={form}
              name="basic"
              className="global-form "
              layout="vertical"
              // onFinish={onFinish}
              autoComplete="off"
            >
              <Row
                gutter={20}
                style={{ minWidth: "65%" }}
                className="p-2 global-form-row "
              >
                <Col className="center">
                  <span className="pt-1">For every </span>
                </Col>
                <Col lg={8}>
                  <Form.Item
                    label="Driving time (in hours)"
                    name="vehicleNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter driving time",
                      },
                    ]}
                    onKeyPress={(event) => {
                      if (
                        checkNumbervalue(event) ||
                        checkSpace(event) ||
                        event.key === "."
                      ) {
                        event.preventDefault();
                      }
                    }}
                  >
                    <Input
                      placeholder="Enter time"
                      maxLength={2}
                      onChange={(e) =>
                        onChangeHandlervehicleNumber(e.target.value)
                      }
                      // disabled={sendVehicleOrbreakTime}
                    />
                  </Form.Item>
                </Col>
                <Col lg={8}>
                  <Form.Item
                    name="breakTime"
                    label="Equivalent break time (in minutes)"
                    rules={[
                      {
                        required: true,
                        message: "Please enter break time",
                      },
                    ]}
                    onKeyPress={(event) => {
                      if (
                        checkNumbervalue(event) ||
                        checkSpace(event) ||
                        event.key === "."
                      ) {
                        event.preventDefault();
                      }
                    }}
                  >
                    <Input
                      placeholder="Enter time"
                      maxLength={2}
                      onChange={(e) =>
                        onChangeHandlervehicleBreak(e.target.value)
                      }
                      // disabled={sendVehicleOrbreakTime}
                    />
                  </Form.Item>
                </Col>
                <Col lg={2}>
                  <Button
                    type="primary"
                    className="save-btn"
                    form="basic"
                    style={{ marginTop: "30px", padding: "0px 40px" }}
                    onClick={saveData}
                    disabled={
                      onChangeId == null ||
                      typeof vehicleNumber?.value !== "string" ||
                      typeof breakTime?.value !== "string"
                        ? true
                        : false
                    }
                  >
                    SAVE{" "}
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        </div>
        <div className="config-template" ref={inputRef}>
          <div className="rate-card-tab-container">
            <Tabs
              activeKey={activeKey}
              onChange={changeTab}
              items={[
                {
                  label: "Loading & Unloading Time",
                  key: "1",
                  children: <LoadingAndUnloading />,
                },
                {
                  label: "Heavy Vehicle Restrictions",
                  key: "2",
                  children: (
                    <HeavyVehicleRestrictions scrollToRef={scrollToRef} />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default TatConfiguration;
