/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Input,
  Button,
  Table,
  Row,
  Col,
  message,
  Spin,
  DatePicker,
} from "antd";
import {
  capitaliseWithHyphen,
  serialNum,
  dateFormat_dmy,
  commonSorterForTable,
} from "../../../utils";
const { RangePicker } = DatePicker;
import { camelCase } from "../../../utils";
import search_icon from "../../../assests/images/search-icon.svg";
import urls from "../../../api/urls";
import API from "../../../api";
import { getPremiseId } from "../../../utils/worker";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

const { ALL_MANIFEST_LIST } = urls;
const premiseId = getPremiseId();

const AllManifestList = () => {
  const [tableData, setTableData] = useState([]);
  const [, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [listCount, setListCount] = useState(0);
  const [dateRangeData, setDateRangeData] = useState([]);
  const [lastDate, setLastDate] = useState({});
  const [dateRangeStatus, setDateRangeStatus] = useState(false);

  const [sortedInfo, setSortedInfo] = useState({});

  let filterByManifest = () => {
    const unique = [
      ...new Map(tableData?.map((item) => [item["manifestId"], item])).values(),
    ];

    let data = unique?.map((val) => {
      return { text: val?.manifestId, value: val?.manifestId };
    });

    return data;
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: "Sl No",
      align: "center",
      // width: 10,
      render: (a, b, idx) => serialNum(pageSize, currentPage, idx),
    },
    {
      title: "Awb No/Packet Id",
      dataIndex: "awbNumber",
      key: "awbNumber",
    },

    {
      title: "Manifest ID",
      dataIndex: "manifestId",
      filters: filterByManifest(),
      width: 150,

      onFilter: (value, record) => record?.manifestId === value,
      key: "manifestId",
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => commonSorterForTable(a, b, "date"),
      sortOrder: sortedInfo?.columnKey === "date" ? sortedInfo?.order : null,
      render: (text) => {
        let parsedDate = moment(text, "YYYY-MM-DD HH:mm:ss");
        let formattedDate = parsedDate?.format("DD-MM-YYYY HH:mm:ss");
        return <div>{formattedDate ? formattedDate : null}</div>;
      },
    },
    {
      title: "CP Name",
      dataIndex: "premiseName",
      key: "premiseName",
      sorter: (a, b) => commonSorterForTable(a, b, "premiseName"),
      sortOrder:
        sortedInfo?.columnKey === "premiseName" ? sortedInfo?.order : null,
      render: (text) => <div>{capitaliseWithHyphen(text)}</div>,
    },
    {
      title: "Booking Type",
      dataIndex: "serviceType",
      key: "serviceType",
      sorter: (a, b) => commonSorterForTable(a, b, "serviceType"),
      sortOrder:
        sortedInfo?.columnKey === "serviceType" ? sortedInfo?.order : null,
      render: (text) => <div>{capitaliseWithHyphen(text)}</div>,
    },
    {
      title: "Service Type",
      dataIndex: "shipmentType",
      key: "shipmentType",
      sorter: (a, b) => commonSorterForTable(a, b, "serviceType"),
      sortOrder:
        sortedInfo?.columnKey === "serviceType" ? sortedInfo?.order : null,
      render: (text) => <div>{camelCase(text)}</div>,
    },
    {
      title: "Mode",
      dataIndex: "travelType",
      key: "travelType",
      sorter: (a, b) => commonSorterForTable(a, b, "travelType"),
      sortOrder:
        sortedInfo?.columnKey === "travelType" ? sortedInfo?.order : null,
      // filters: filterByMode(),

      render: (text) => <div>{camelCase(text)}</div>,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => commonSorterForTable(a, b, "status"),
      sortOrder: sortedInfo?.columnKey === "status" ? sortedInfo?.order : null,
      render: (text) => <div>{camelCase(text)}</div>,
    },
  ];

  function onPageChange(currentPage, pageSize) {
    // console.log(currentPage, "currentPage");
    // console.log(pageSize, "pageSize");

    if (dateRangeStatus) {
      dateSearchFun(currentPage, pageSize);
    }
    setCurrentPage(currentPage);
    setPageSize(pageSize);
  }

  const onSearch = () => {
    // if (e.target.value.length > 10 && e.target.value.length < 15) {
    //   dateSearchFun(undefined, undefined, "", e.target.value);
    // }
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = dd + "-" + mm + "-" + yyyy;
    const formattedYes =
      dd == "01"
        ? dd + "-" + mm + "-" + yyyy
        : (dd <= 10 ? "0" + (+dd - 1) : +dd - 1) + "-" + mm + "-" + yyyy;
    // console.log(formattedYes);
    let rangeData = [formattedYes, formattedToday];
    dateRangeHandle("", rangeData);
    setLastDate({ fromDate: rangeData[0] || "", toDate: rangeData[1] || "" });
    dateSearchFun(undefined, undefined, "", rangeData);
  }, []);

  const dateRangeHandle = (e, a) => {
    setLastDate({ fromDate: a[0] || "", toDate: a[1] || "" });
    setDateRangeData(a);
    if (a[0]?.length === 0) {
      // getTableData();
      setDateRangeStatus(false);
    } else {
      setDateRangeStatus(true);
    }
  };

  const dateSearchFun = async (
    currentPageNo,
    pageSiz,
    searchInput,
    rangeData
  ) => {
    setLoading(true);
    let dateData = rangeData ? rangeData : dateRangeData;
    let queryParams = `&search=${searchInput}`;
    let response = await API.Api(
      `${ALL_MANIFEST_LIST}${+premiseId.split(" ")[1]}?pageNo=${
        currentPageNo - 1 || 0
      }&pageSize=${pageSiz || 100}&startDate=${dateData[0]}&endDate=${
        dateData[1]
      }` + queryParams
    ).getWithHeader();
    if (response?.status) {
      let list = response?.response?.data;
      let data = response?.response?.data;
      setTableData(list?.response);

      setDataSource(list?.response);
      setListCount(data?.totalCount);
      setLoading(false);
    } else {
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
      setLoading(false);
    }
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <>
      <Spin
        spinning={loading}
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }
      >
        <div className="consolidated-manifest-cntr">
          <div className="new-header ml-1">
            <Row gutter={12} justify={"space-between"}>
              <Col span={4} className="center"></Col>
              <div className="center">
                <div className="center">
                  <div className="flex-end">
                    <RangePicker
                      disabledDate={(current) => disabledDate(current)}
                      onChange={dateRangeHandle}
                      value={[
                        Object.keys(lastDate)?.length > 0 &&
                        lastDate?.fromDate !== ""
                          ? moment(
                              lastDate?.fromDate?.replaceAll("-", "/"),
                              dateFormat_dmy
                            )
                          : "",
                        Object.keys(lastDate)?.length > 0 &&
                        lastDate?.toDate !== ""
                          ? moment(
                              lastDate?.toDate?.replaceAll("-", "/"),
                              dateFormat_dmy
                            )
                          : "",
                      ]}
                      format={dateFormat_dmy}
                      style={{
                        borderRadius: "5px 0 0 5px",
                        height: "40px",
                        width: "230px",
                      }}
                    />
                    <Button
                      className="center mr-1"
                      disabled={!dateRangeStatus}
                      style={{
                        width: "40px",
                        height: "40px",
                        border: "1px solid #d9d9d9",
                        borderRadius: "0 5px 5px 0",
                      }}
                      onClick={dateSearchFun}
                    >
                      <img src={search_icon} />
                    </Button>
                  </div>
                </div>
                <div className="center">
                  <Input
                    className="mr-1"
                    placeholder="Enter keyword to search"
                    onChange={onSearch}
                    bordered
                    style={{
                      borderRadius: "5px",
                      height: "40px",
                    }}
                    suffix={<img src={search_icon} />}
                  />
                </div>
              </div>
            </Row>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            // loading={loading}
            onChange={handleChange}
            className="table-head-sticky"
            pagination={{
              pageSizeOptions: ["50", "100", "500", "1000"],
              pageSize: pageSize,
              total: listCount,
              current: currentPage,
              itemRender: itemRender,
              onChange: onPageChange,
            }}
          />
        </div>
      </Spin>
    </>
  );
};

export default AllManifestList;
