/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Tabs } from "antd";
// import Heading from "../../../components/Heading";
// import OnboardHub from "./CreateHubOnboard";
// import ViewHub from "./ListHubOnboard";
// import "./style.scss";
import Heading from "../../../components/Heading";
import LeadClientOnboardingSteps from "./LeadClientOnboardingSteps";
import { useNavigate } from "react-router-dom";
// import ViewClientTable from "./viewClientTable";

const LeadClientOnboarding = () => {
  // const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("1");
  // const [check, setCheck] = useState(false);

  // const changeTab = (key) => {
  //   setActiveKey(key);
  //   setCheck(!check);
  //   if (key === "2") {
  //     navigate("/client-list");
  //   }
  // };

  return (
    <div className="p-2">
      <Heading title="Lead Client Onboarding" />
      <div className="global-card-tab-container">
        <Tabs
          activeKey={activeKey}
          // onChange={changeTab}
          items={[
            {
              label: "Client Onboarding",
              key: "1",
              children: <LeadClientOnboardingSteps />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default LeadClientOnboarding;
