/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  Table,
  Space,
  Button,
  TimePicker,
  Modal,
  message,
  Tooltip,
  Spin,
  Popconfirm,
} from "antd";
import { serialNum } from "../../../utils";
import { useNavigate } from "react-router";
import back_img from "../../../assests/images/rightVector.svg";
import check_in_img from "../../../assests/images/middleMile/check_in.svg";
import check_out_img from "../../../assests/images/middleMile/check_out.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import urls from "../../../api/urls";
import API from "../../../api";
import NormalAPI from "../../../api";
let premiseId = JSON.parse(localStorage.getItem("userId"));

import "./style.scss";
const UpdateTripList = () => {
  const navigate = useNavigate();
  const [tripList, setTripList] = useState();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);
  const [checkoutTime, setCheckoutTime] = useState("");
  const [tripId, setTripId] = useState("");
  const [vehicleNum, setVehicleNum] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const { UPDATE_TRIP_LIST, POST_CHECKOUT_TIME } = urls;
  const getTripList = async () => {
    setLoading(true);
    let response = await API.Api(UPDATE_TRIP_LIST).getWithUserNPremiseHeader();
    if (response?.status) {
      setTripList(response?.response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    getTripList();
  }, []);

  const columns = [
    {
      title: "Sl No",
      align: "center",
      with: 10,
      render: (a, b, idx) => serialNum(10, currentPage, idx),
    },
    {
      title: "Trip ID",
      dataIndex: "tripId",
      key: "tripId",
      render: (text) => <span>{text || "------"}</span>,
    },
    {
      title: "Vehicle/Flight LCR ID",
      dataIndex: "vehicleLcrId",
      key: "vehicleLcrId",
      render: (text) => <span>{text || "------"}</span>,
    },
    {
      title: "Vehicle/Flight LCR Status",
      dataIndex: "vehicleLcrStatus",
      key: "vehicleLcrStatus",
      render: (text) => <span>{text || "------"}</span>,
    },
    {
      title: "Mode",
      dataIndex: "travelBy",
      key: "travelBy",
      render: (text) => <span>{text || "------"}</span>,
    },
    {
      title: "Vehicle/Flight No.",
      dataIndex: "",
      key: "",
      render: (text) => (
        <span>{text?.vehicleNum ? text?.vehicleNum : text?.flightNum}</span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => <span>{text?.split(" ")[0] || "------"}</span>,
    },

    {
      title: "Trip Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <span>{text || "------"}</span>,
    },
    {
      title: "E-way Bill",
      key: "action",
      render: (data, record) => (
        <Space size="middle">
          <Button
            disabled={!record?.isEwayBill}
            className="cancel-btn"
            onClick={() => {
              navigate(`/hub-eway-bill/${record?.tripId}`, {
                state: { state: record, slug: "/update-trip-list" },
              });
            }}
          >
            E-WAY BILL UPDATE
          </Button>
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (data, record) => {
        return(
        <Space size="middle">
          {record?.destinationId !== +premiseId.userInfo.premiseId &&
          record?.status?.toLowerCase() === "in-transit" &&
          record?.vehicleLcrStatus?.toLowerCase() === "unlocked" ? (
            <Button
              className="cancel-btn"
              onClick={() =>
                navigate("/create-trip", {
                  state: { record, key: "UPDATE_TRIP" },
                })
              }
            >
              UPDATE TRIP
            </Button>
          ) : null}
          <Tooltip title={"CHECK-IN"}>
            <Button 
            disabled={record?.travelBy?.toUpperCase() === "AIR"} 
            onClick={() => showModal(record, "Check-in")}
            style={{border:"none",background:"none"}}
            >
              <img src={check_in_img} />
            </Button>
          </Tooltip>
          <Tooltip title={"CHECK-OUT"}>
            <Button 
            disabled={record?.travelBy?.toUpperCase() === "AIR"} 
            onClick={() => showModal(record, "Check-out")}
            style={{border:"none",background:"none"}}
            >
              <img src={check_out_img} />
            </Button>
          </Tooltip>
        </Space>);
    },
    },
  ];
  const showModal = (record, status) => {
    setIsModalOpen(true);
    setModalStatus(status);
    setTripId(record?.tripId);
    setVehicleNum(record?.vehicleNum);
    setBtnDisable(true);
  };
  const onTimeChange = (time, timeString) => {
    setCheckoutTime(timeString);
    setBtnDisable(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = async () => {
    setLoading(true);
    let checkOutPayload = {
      vehicleNum: vehicleNum,
      checkOutTime: checkoutTime,
    };
    let checkInPayload = {
      vehicleNum: vehicleNum,
      checkInTime: checkoutTime,
    };
    let response = await NormalAPI.Api(
      `${POST_CHECKOUT_TIME}${
        modalStatus === "Check-out" ? "check_out/" : "check_in/"
      }${tripId}`
    ).postWithPrmiseHeader(
      modalStatus === "Check-out" ? checkOutPayload : checkInPayload
    );
    if (response?.status) {
      modalStatus === "Check-out"
        ? message.success("successfully checked out")
        : message.success("successfully checked in");
      setLoading(false);
      setIsModalOpen(false);
    } else {
      setLoading(false);
      setIsModalOpen(false);
      message.error(response?.response?.data?.message);
    }
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };
  const paginationHandle = (pagination) => {
    setCurrentPage(pagination.current);
  };
  return (
    <Spin
      spinning={loading}
      size="large"
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className=" bagging-shipment p-2">
        <div className="align-center mt-1 mb-1">
          <img
            src={back_img}
            onClick={() => navigate("/create-or-update-trip")}
            style={{ cursor: "pointer" }}
          />
          <p
            style={{
              margin: "0",
              paddingLeft: "5px",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "32px",
              letterSpacing: "-0.25px",
              color: "#061624",
            }}
          >
            <b>Update Trip List</b>
          </p>
        </div>
        <div className="lcr-table-container">
          <Table
            className="table-head-sticky"
            columns={columns}
            dataSource={tripList}
            pagination={{
              itemRender: itemRender,
            }}
            onChange={paginationHandle}
          />
        </div>
        <Modal
          className="timepicker-modal"
          title={modalStatus}
          open={isModalOpen}
          maskClosable={false}
          footer={false}
          onCancel={handleCancel}
          destroyOnClose
        >
          <TimePicker onChange={onTimeChange} format={"HH:mm"} />
          <div className="flex-end mt-1">
            <Button onClick={handleCancel} className="cancel-btn mr-1">
              {"CANCEL"}
            </Button>
            <Popconfirm
              title={`Do you wish to ${modalStatus.toLowerCase()}`}
              onConfirm={handleOk}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className={btnDisable ? "disable-popup" : "save-btn"}
                disabled={btnDisable}
              >
                {"SAVE"}
              </Button>
            </Popconfirm>
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default UpdateTripList;
