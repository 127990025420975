import React, { useEffect, useState } from "react";
import axios from "axios";
import "./style.scss";
import { APIs, getToken } from "../../../utils/worker";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Form,
  Input,
  Col,
  Row,
  Select,
  Button,
  Spin,
  message,
} from "antd";
import cross_button from "../../../assests/images/CrossButton.svg";
import {
  getPacketingGuideFromHublist,
  getPacketingGuideFromCplist,
  getPacketingGuideToCplist,
  putPacketingGuideMaster,
  putPacketingGuideMasterClear,
} from "../../../actionsMethods/masterMethods";
import "./style.scss";
import messages from "./../../../constants/messages";
import { capitalizeAfterSpacesAndSpecialChars,capitaliseWithHyphen } from "../../../utils";
const EditPacketingMapping = ({
  editModalOpen,
  setEditModalOpen,
  packetNameValue,
  setEditPktMpingStatus,
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const token = getToken();
  const putPacketingGuideMasterResponse = useSelector(
    (state) => state?.masterReducer?.putPacketingGuideMasterReducer,
    shallowEqual
  );
  const getFromCpListResponse = useSelector(
    (state) => state?.masterReducer?.getPacketingGuideFromCplistReducer,
    shallowEqual
  );

  const getToCpListResponse = useSelector(
    (state) => state?.masterReducer?.getPacketingGuideToCplistReducer,
    shallowEqual
  );
  const getFromHubListResponse = useSelector(
    (state) => state?.masterReducer?.getPacketingGuideFromHublistReducer,
    shallowEqual
  );
  const { error, fetching, result } = putPacketingGuideMasterResponse;
  const { fetching: loading1, result: ToCpListResult } = getToCpListResponse;
  const { fetching: loading2, result: fromCpListResult } =
    getFromCpListResponse;
  const { fetching: loading3, result: fromHubListResult } =
    getFromHubListResponse;

  const [allDbData, setAllDbData] = useState();
  const [filterToHub, setFilterToHub] = useState();

  const handleSave = async (value) => {
    const packetOpeningHub = fromHubListResult?.filter((data) => {
      return value?.packetOpeningHub?.indexOf(data?.premiseName) !== -1;
    });
    const fromHub = fromHubListResult?.filter((data) => {
      return value?.fromHub?.indexOf(data?.premiseName) !== -1;
    });
    const fromCp = fromCpListResult?.filter((data) => {
      return value?.fromCpEdit?.indexOf(data?.premiseName) !== -1;
    });
    const toHub = fromHubListResult?.filter((data) => {
      return value?.toHub?.indexOf(data?.premiseName) !== -1;
    });
    const toCp = ToCpListResult?.filter((data) => {
      return value?.toCpEdit?.indexOf(data?.premiseName) !== -1;
    });
    const payload = {
      packetingGuideName: value?.packetName,
      packetOpeningHub: packetOpeningHub[0]?.premiseId,
      fromHub: fromHub[0]?.premiseId,
      fromCp: fromCp?.map((data) => data?.premiseId),
      toHub: toHub?.map((data) => data?.premiseId),
      toCp: toCp?.map((data) => data?.premiseId),
    };
    await dispatch(putPacketingGuideMaster(payload));
    messages.success(result);
  };

  const handleCancel = () => {
    setEditModalOpen(false);
  };

  const getAllData = async () => {
    try {
      let response = await axios.get(
        APIs.baseURL + `/packet-service/v1/packeting-guide/${packetNameValue}`,
        {
          headers: {
            Authorization: token,
            token: token.split("Bearer ")[1],
          },
        }
      );
      setAllDbData(response);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    if (result) {
      setEditPktMpingStatus(fetching);
    }
  }, [fetching]);

  useEffect(() => {
    if (editModalOpen) {
      getAllData();
      dispatch(getPacketingGuideFromHublist());
    }
  }, [editModalOpen]);
  useEffect(() => {
    if (allDbData) {
      dispatch(
        getPacketingGuideFromCplist(allDbData?.data?.fromHub?.toString())
      );
      dispatch(getPacketingGuideToCplist(allDbData?.data?.toHub?.toString()));
      form.setFieldValue("packetName", capitaliseWithHyphen(allDbData?.data?.packetingGuideName));
    }
  }, [allDbData]);

  useEffect(() => {
    const packetOpeningHub = fromHubListResult?.filter(
      (data) => data?.premiseId === allDbData?.data?.packetOpeningHub
    );
    const fromHub = fromHubListResult?.filter(
      (data) => data?.premiseId === allDbData?.data?.fromHub
    );
    const toHub = fromHubListResult?.filter((data) => {
      return allDbData?.data?.toHub?.indexOf(data?.premiseId) !== -1;
    });
    form.setFieldValue(
      "fromHub",
      fromHub?.map((data) => capitaliseWithHyphen(data?.premiseName))
    );
    form.setFieldValue(
      "toHub",
      toHub?.map((data) => capitaliseWithHyphen(data?.premiseName))
    );
    form.setFieldValue(
      "packetOpeningHub",
      packetOpeningHub?.map((data) => capitaliseWithHyphen(data?.premiseName))
    );
    setFilterToHub(toHub);
  }, [fromHubListResult]);

  useEffect(() => {
    if (fromCpListResult || ToCpListResult) {
      const toCp = ToCpListResult?.filter((data) => {
        return allDbData?.data?.toCp?.indexOf(data?.premiseId) !== -1;
      });
      const fromCp = fromCpListResult?.filter((data) => {
        return allDbData?.data?.fromCp?.indexOf(data?.premiseId) !== -1;
      });
      form.setFieldValue(
        "fromCpEdit",
        fromCp?.map((data) => capitalizeAfterSpacesAndSpecialChars(data?.premiseName))
      );
      form.setFieldValue(
        "toCpEdit",
        toCp?.map((data) => capitalizeAfterSpacesAndSpecialChars(data?.premiseName))
      );
    }
  }, [ToCpListResult, fromCpListResult]);

  useEffect(() => {
    if (result) {
      message.success(result);
      dispatch(putPacketingGuideMasterClear());
      setEditModalOpen(false);
    } else if (error?.message) {
      message.error(error?.message);
      dispatch(putPacketingGuideMasterClear());
    }
  }, [error, result]);
  return (
    <Modal
      title="Edit Packet Mapping"
      open={editModalOpen}
      onCancel={handleCancel}
      maskClosable={false}
      closeIcon={<img src={cross_button} />}
      autoComplete="off"
      className="master-modal edit-packet-mapping-modal"
      footer={null}
    >
      <Spin spinning={fetching || loading1 || loading2 || loading3}>
        <Form
          layout={"vertical"}
          className="edit-packeting-container .master-main-container  global-form "
          form={form}
          onFinish={handleSave}
        >
          <Row gutter={32} className="global-form-row">
            <Col span={12}>
              <Form.Item label="Packet name" name="packetName">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Packet opening hub" name="packetOpeningHub">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="From hub" name="fromHub">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="From CP"
                name="fromCpEdit"
                rules={[
                  {
                    required: true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select
                  placeholder="Select CP"
                  className=" multiple-input-field cp-list-scroll mul-input"
                  mode="multiple"
                >
                  {fromCpListResult?.map((data) => (
                    <Option key={data?.premiseName} value={data?.premiseName}>
                      {data?.premiseName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="To hub" name="toHub">
                <Select
                  placeholder="Select CP"
                  className=" multiple-input-field cp-list-scroll mul-input"
                  mode="multiple"
                  disabled={true}
                >
                  {filterToHub?.map((data) => (
                    <Option key={data?.premiseName} value={data?.premiseName}>
                      {data?.premiseName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="To CP"
                name="toCpEdit"
                rules={[
                  {
                    required: true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select
                  placeholder="Select CP"
                  className="multiple-input-field cp-list-scroll mul-input"
                  mode="multiple"
                >
                  {ToCpListResult?.map((data) => (
                    <Option key={data?.premiseName} value={data?.premiseName}>
                      {data?.premiseName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <hr className="divider mt-3" />
          <div className="d-flex flex-end p-1">
            <Button
              className="btn-one btn-style cancel-btn "
              onClick={handleCancel}
            >
              CANCEL
            </Button>
            <Button
              htmlType="submit"
              className="btn-one btn-style save-btn ml-1 "
            >
              SAVE
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditPacketingMapping;
