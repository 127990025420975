import React, { useState } from "react";
import { Tabs } from "antd";
import Heading from "../../../components/Heading";
import OnboardHub from "./CreateHubOnboard";
import ViewHub from "./ListHubOnboard";
import "./style.scss";
const HubOnboard = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [check, setCheck] = useState(false);

  const changeTab = (key) => {
    setActiveKey(key);
    setCheck(!check);
  };
  const onboardHubHandle = () => {
    setActiveKey("2");
  };
  const viewHubHandle = () => {
    setActiveKey("1");
  };
  return (
    <div className="p-2">
      <Heading title="Hub Onboarding" />
      <div className="rate-card-tab-container">
        <Tabs
          activeKey={activeKey}
          onChange={changeTab}
          items={[
            {
              label: "Hub Onboarding",
              key: "1",
              children: (
                <OnboardHub
                  onboardHubHandle={onboardHubHandle}
                  activeKey={activeKey}
                />
              ),
            },
            {
              label: "View All Hub",
              key: "2",
              children: (
                <ViewHub
                  check={check}
                  viewHubHandle={viewHubHandle}
                  activeKey={activeKey}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};
export default HubOnboard;
