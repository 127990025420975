import React, { useState, useEffect } from "react";
import "./style.scss";
import API from "../../../api";
import urls from "../../../api/urls";
import { Row, Col, Table, message, Spin, Empty } from "antd";
// import print_icon from "../../../assests/images/master/print.svg";
import smcs_logo from "../../../assests/images/smcsLogo.svg";
import nation_tax_logo from "../../../assests/images/nation_tax_logo.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

import { serialNum } from "../../../utils";
import { useParams } from "react-router-dom";
import Heading from "../../../components/Heading";

function EwayTracker() {
  const { GET_EWAY_TRACKER } = urls;
  const [loading, setLoading] = useState(false);
  const [ewayBillData, setEwayBillDara] = useState([]);
  let parmas = useParams();

  const columns = [
    {
      title: "Sl.No",
      align: "center",
      render: (a, b, idx) => serialNum(10, 1, idx),
    },
    {
      title: "From Place",
      dataIndex: "ewbOprPlace",
      key: "ewbOprPlace",
      width: 150,
    },
    {
      title: "Vehicle No",
      dataIndex: "vehicleNo",
      key: "vehicleNo",
    },
    {
      title: "Updation Date & Time",
      dataIndex: "updateDate",
      key: "updateDate",
    },
    {
      title: "Consolidated E-way Bill no",
      dataIndex: "consEwbNo",
      key: "consEwbNo",
    },
    {
      title: "Mode",
      dataIndex: "transMode",
      key: "transMode",
      render: (text) => <span>{text || "N/A"}</span>,
    },
    {
      title: "Valid Until:",
      dataIndex: "ewbBillExpDate",
      key: "ewbBillExpDate",
      render: (text) => <span>{text || "N/A"}</span>,
    },
  ];

  useEffect(() => {
    if (parmas.id !== "") {
      getEWayBill();
    }
  }, [parmas.id]);

  const getEWayBill = async () => {
    setLoading(true);
    let response = await API.Api(GET_EWAY_TRACKER + parmas.id).getWithHeader();
    if (response?.status) {
      setEwayBillDara([response?.response?.data]);
      setLoading(false);
    } else {
      message.error(response?.response?.data?.message || "Network error");
      setLoading(false);
    }
  };
  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="pt-2 pl-2">
        <Heading title="E-way Tracking" />
      </div>
      <div className="print-drs-container2">
        {ewayBillData?.length ? (
          <div>
            {ewayBillData?.map((data, index) => (
              <div
                key={index}
                className="main-div p-2"
                style={{ marginBottom: "20px" }}
              >
                <Row className="space-between mb-2">
                  {data?.consolidateEwayBill === "0" ? (
                    <Col span={8} className="vertical-space-between">
                      <img src={smcs_logo} style={{ width: "164px" }} />
                      <b className="mt-1">Individual Part-B Update List</b>
                    </Col>
                  ) : (
                    <Col span={8} className="vertical-space-between">
                      <img src={smcs_logo} style={{ width: "164px" }} />
                      <b className="mt-1">Consolidated Eway Bill</b>
                    </Col>
                  )}
                  <Col span={8}>
                    <img src={nation_tax_logo} />
                  </Col>
                </Row>
                <div
                  className="heading p-05"
                  style={{
                    backgroundColor: "#F5F8FD",
                    border: "1px solid #C8DFFB",
                    borderRadius: "5px",
                  }}
                >
                  {data?.consolidateEwayBill === "0" ? (
                    <b>Individual Part-B Update</b>
                  ) : (
                    <b>Consolidated Eway Bill Details</b>
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid #DEDEDE",
                    borderCollapse: "collapse",
                  }}
                  className="mb-1 mt-1"
                >
                  <Row>
                    <Col span={12} style={{ border: "1px solid #DEDEDE" }}>
                      <Row style={{ margin: "8px" }}>
                        <Col span={13}>E-way Bill No.</Col>
                        <Col span={11}>
                          <b>{data?.ewaybillNo}</b>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} style={{ border: "1px solid #DEDEDE" }}>
                      <Row style={{ margin: "8px" }}>
                        <Col span={13}>AWB No.</Col>
                        <Col span={11}>
                          <b>{data?.awbnumber}</b>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} style={{ border: "1px solid #DEDEDE" }}>
                      <Row style={{ margin: "8px" }}>
                        <Col span={13}>E-way Bill Date</Col>
                        <Col span={11}>
                          <b>{data?.ewayBillCrtDate}</b>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} style={{ border: "1px solid #DEDEDE" }}>
                      <Row style={{ margin: "8px" }}>
                        <Col span={13}>E-way Bill By</Col>
                        <Col span={11}>
                          <b>{data?.ewaybillNo}</b>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} style={{ border: "1px solid #DEDEDE" }}>
                      <Row style={{ margin: "8px" }}>
                        <Col span={13}>Transporter ID</Col>
                        <Col span={11}>
                          <b>88AABCM9407D1ZS</b>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} style={{ border: "1px solid #DEDEDE" }}>
                      <Row style={{ margin: "8px" }}>
                        <Col span={13}>Value.</Col>
                        <Col span={11}>
                          <b>{data?.shipmentValue}</b>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} style={{ border: "1px solid #DEDEDE" }}>
                      <Row style={{ margin: "8px" }}>
                        <Col span={24}>Destination address:</Col>
                        <Col span={11}>
                          <b>{data?.shipmentAddress}</b>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div
                  className="heading p-05 mb-1"
                  style={{
                    backgroundColor: "#F5F8FD",
                    border: "1px solid #C8DFFB",
                    borderRadius: "5px",
                  }}
                >
                  <b>Item Details</b>
                </div>
                <Table
                  dataSource={data?.trackingHistories}
                  columns={columns}
                  pagination={false}
                />
              </div>
            ))}
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </Spin>
  );
}

export default EwayTracker;
