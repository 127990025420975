import React from "react";
import ErrorLoader from "./ErrorLoader";
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    // console.log(info);
    // console.log(error);
  }

  render() {
    if (this.state.hasError == true) {
      return (
        <div className="d-flex center">
          <ErrorLoader />
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
