/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Checkbox,
  Spin,
} from "antd";
import BottomButton from "./BottomButton";
import Heading from "./Heading";
import { capitaliseWithHyphen } from "../../utils";
import { dateFormat_dmy, timeFormater } from "../../utils";

const PersonalDetails = ({ handleStepsChange, goBackStep }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [marriageStatus, setMarriageStatus] = useState("married");
  const onPersonalDetailsFinish = (e) => {};
  const onPersonalAddressFinish = (e) => {
    handleStepsChange();
  };
  const checkboxHandle = (e) => {
    let formData = form.getFieldsValue();
    if (e.target.checked) {
      form.setFieldsValue({
        cityadditional: formData?.city,
        pinCodeadditional: formData?.pinCode,
        residentialAddressLine1additional: formData?.residentialAddressLine1,
        residentialAddressLine2additional: formData?.residentialAddressLine2,
        stateadditional: formData?.state,
      });
    } else {
      form.setFieldsValue({
        cityadditional: "",
        pinCodeadditional: "",
        residentialAddressLine1additional: "",
        residentialAddressLine2additional: "",
        stateadditional: "",
      });
    }
  };
  const pinCodeHandler = async (e, name) => {
    if (e.target.value.length > 5) {
      try {
        setLoading(true);
        let response = await axios.get(
          `https://api.postalpincode.in/pincode/${e.target.value}`
        );
        let res = response.data;
        let data = res[0]?.PostOffice[0];
        if (res[0]?.Status === "Success") {
          if (name === "pinCode") {
            form.setFieldsValue({
              city: capitaliseWithHyphen(data?.District),
              state: capitaliseWithHyphen(data?.State),
              country: capitaliseWithHyphen(data?.Country),
            });
          } else {
            form.setFieldsValue({
              cityadditional: capitaliseWithHyphen(data?.District),
              stateadditional: capitaliseWithHyphen(data?.State),
            });
          }
          setLoading(false);
        } else {
          setLoading(false);
          form.setFields([
            {
              name: name,
              errors: [res[0]?.Message],
            },
          ]);
        }
      } catch (error) {
        setLoading(false);
        form.setFields([
          {
            name: name,
            errors: ["Please give correct pincode"],
          },
        ]);
      }
    } else if (e.target.value.length < 6) {
      if (name === "pinCode") {
        form.setFieldsValue({
          city: "",
          state: "",
        });
      } else {
        form.setFieldsValue({
          cityadditional: "",
          stateadditional: "",
        });
      }
    }
  };
  return (
    <div className="cp-detail-wrapper">
      <Spin spinning={loading}>
        <Heading title={"Personal Details"} />
        <Form
          className="global-form"
          layout={"vertical"}
          onFinish={onPersonalDetailsFinish}>
          <Row gutter={12} className="global-form-row">
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter name",
                  },
                ]}>
                <Input className="input-field-style" placeholder="Enter name" />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Contact no"
                name="contactNo"
                rules={[
                  {
                    required: true,
                    message: "Please enter contact no.",
                  },
                ]}>
                <Input
                  className="input-field-style"
                  placeholder="Enter contact no."
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Alternate contact no"
                name="alternateContactno"
                rules={[
                  {
                    required: false,
                    message: "Please enter alternate contact no.",
                  },
                ]}>
                <Input
                  className="input-field-style"
                  placeholder="Enter alternate contact no."
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="E-mail ID"
                name="emailId"
                rules={[
                  {
                    required: true,
                    message: "Please enter E-mail ID",
                  },
                ]}>
                <Input
                  className="input-field-style"
                  placeholder="Enter E-mail ID"
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="DOB"
                name="dob"
                rules={[
                  {
                    required: true,
                    message: "Please enter DOB",
                  },
                ]}>
                <DatePicker
                  className="input-field-style"
                  format={dateFormat_dmy}
                  placeholder="Select DOB"
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Educational qualification"
                name="educationalQualification"
                rules={[
                  {
                    required: false,
                    message: "Please enter educational qualification",
                  },
                ]}>
                <Select
                  className="input-field-style"
                  placeholder="Select educational qualification"
                  defaultValue="M Tech"
                  options={[{ value: "M Tech", label: "M Tech" }]}
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Marriage status"
                name="marriageStatus"
                rules={[
                  {
                    required: false,
                    message: "Please enter marriage status",
                  },
                ]}>
                <Select
                  className="input-field-style"
                  placeholder="Select marriage status"
                  defaultValue="Married"
                  options={[
                    { value: "married", label: "Married" },
                    { value: "single", label: "Single" },
                  ]}
                  onChange={(e) => setMarriageStatus(e)}
                />
              </Form.Item>
            </Col>
            {marriageStatus === "married" ? (
              <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                <Form.Item
                  label="Marriage date"
                  name="marriageDate"
                  rules={[
                    {
                      required: false,
                      message: "Please enter marriage date",
                    },
                  ]}>
                  <DatePicker
                    className="input-field-style"
                    format={dateFormat_dmy}
                    placeholder="Select marriage date"
                  />
                </Form.Item>
              </Col>
            ) : null}
          </Row>

          <Heading title={"Personal Address"} />
          <Row gutter={12}>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Residential address line 1"
                name="residentialAddressLine1"
                rules={[
                  {
                    required: true,
                    message: "Please enter residential address line 1",
                  },
                ]}>
                <Input
                  className="input-field-style"
                  placeholder="Enter residential address line 1"
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Residential address line 2"
                name="residentialAddressLine2"
                rules={[
                  {
                    required: false,
                    message: "Please enter residential address line 2",
                  },
                ]}>
                <Input
                  className="input-field-style"
                  placeholder="Enter residential address line 2"
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="PIN code"
                name="pinCode"
                rules={[
                  {
                    required: true,
                    message: "Please enter PIN code",
                  },
                  () => ({
                    validator(rule, value = "") {
                      if (value.length > 0 && value.length < 6) {
                        return Promise.reject("Pincode should be 6 digits");
                      } else {
                        return Promise.resolve();
                      }
                    },
                    // validateTrigger: "onSubmit",
                  }),
                ]}>
                <Input
                  className="input-field-style"
                  placeholder="Enter PIN code"
                  onChange={(e) => pinCodeHandler(e, "pinCode")}
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item label="City" name="city">
                <Input
                  className="input-field-style"
                  placeholder="Enter city"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item label="State" name="state">
                <Input
                  className="input-field-style"
                  placeholder="Enter state"
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Heading title={"Residential Address"} />
          <Checkbox onChange={checkboxHandle} className="mt-1 mb-1">
            <b>Same as personal address</b>
          </Checkbox>
          <Row gutter={12}>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Residential address line 1"
                name="residentialAddressLine1additional"
                rules={[
                  {
                    required: true,
                    message: "Please enter residential address line 1",
                  },
                ]}>
                <Input
                  className="input-field-style"
                  placeholder="Enter residential address line 1"
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Residential address line 2"
                name="residentialAddressLine2additional"
                rules={[
                  {
                    required: false,
                    message: "Please enter residential address line 2",
                  },
                ]}>
                <Input
                  className="input-field-style"
                  placeholder="Enter residential address line 2"
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="PIN code"
                name="pinCodeadditional"
                rules={[
                  {
                    required: true,
                    message: "Please enter PIN code",
                  },
                  () => ({
                    validator(rule, value = "") {
                      if (value.length > 0 && value.length < 6) {
                        return Promise.reject("Pincode should be 6 digits");
                      } else {
                        return Promise.resolve();
                      }
                    },
                    // validateTrigger: "onSubmit",
                  }),
                ]}>
                <Input
                  className="input-field-style"
                  placeholder="Enter PIN code"
                  onChange={pinCodeHandler}
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="City"
                name="cityadditional"
                rules={[
                  {
                    required: true,
                    message: "Please enter city",
                  },
                ]}>
                <Input className="input-field-style" placeholder="Enter city" />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="State"
                name="stateadditional"
                rules={[
                  {
                    required: true,
                    message: "Please enter state",
                  },
                ]}>
                <Input
                  className="input-field-style"
                  placeholder="Enter state"
                />
              </Form.Item>
            </Col>
          </Row>
          <hr
            className="divider"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          />
          <Row
            gutter={12}
            className="mt-2"
            justify={"end"}
            style={{ flex: "auto" }}>
            <BottomButton goBackStep={goBackStep} htmlType="submit" />
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default PersonalDetails;
