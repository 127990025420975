/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Space,
  Input,
  Tooltip,
  Popconfirm,
  message,
  Table,
  Button,
  Row,
  Form,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { capitalize, serialNum } from "../../../utils";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { ReactComponent as Edit_icon } from "../../../assests/images/edit-logo.svg";
import { ReactComponent as Mail_sent } from "../../../assests/images/SVG/mail-sent-icon.svg";
import { ReactComponent as Pfd_icon } from "../../../assests/images/SVG/pdf-icon.svg";
import { ReactComponent as Pfd_red_icon } from "../../../assests/images/SVG/pdf-red-icon.svg";

import { ReactComponent as Mail_in } from "../../../assests/images/SVG/mail-in-icon.svg";
import { ReactComponent as Disable_Edit_icon } from "../../../assests/images/SVG/disableEdit.svg";

import search_icon from "../../../assests/images/search-icon.svg";
import check_mark from "../../../assests/images/SVG/check.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import Heading from "../../../components/Heading";
import urls from "../../../api/urls";
import API from "../../../api";
import { ReactComponent as EyeIcon } from "../../../assests/images/eyeIcon.svg";

const { GET_LEAD_CP_QUOTE_TABLE } = urls;

const CPQuotationList = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [cpName, setCpName] = useState(null);

  useEffect(() => {
    getQuoteTable();
  }, []);

  const getQuoteTable = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_LEAD_CP_QUOTE_TABLE + param?.id
    ).getWithUserNPremiseHeader();
    if (response?.status) {
      setLoading(false);
      let temp = response?.response?.data?.data?.leadQuotationDtos;
      setCpName(response?.response?.data?.data?.cpName);
      setTableData(temp);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const columns = [
    {
      title: "Quote Number",
      dataIndex: "rateCardId",
      key: "rateCardId",
      align: "center",
      width: 50,
      render: (a, b, idx) => {
        return (
          <a onClick={() => navigate("/cp-quotation-edit/" + param?.id)}>{a}</a>
        );
      },
    },
    {
      title: "Quotation",
      dataIndex: "rateCardName",
      key: "rateCardName",
      align: "center",
      width: 100,
      render: (text, val) => {
        return (
          <>
            <div>{text || "N/A"}</div>
          </>
        );
      },
    },
    {
      title: "Declared amount",
      dataIndex: "declaredAmount",
      key: "declaredAmount",
      align: "center",
      width: 100,
    },
    {
      title: "Date",
      dataIndex: "updatedDate",
      key: "updatedDate",
      align: "center",
      width: 100,
      render: (text, val) => {
        return (
          <>
            <div>{text?.slice(0, 10)?.split("-")?.reverse()?.join("-")}</div>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 100,

      render: (text) => {
        return (
          <div className="lead-list-status">{capitalize(text) || "--"}</div>
        );
      },
    },

    {
      title: "Actions",
      key: "action",
      align: "center",
      className: "actions-col",
      width: 150,

      //   render: (_, record) => {
      //     return record?.status ? (
      //       <Space size="middle">
      //         <Tooltip title={"Edit"} placement="bottom">
      //           <p
      //             className="pointer"
      //             style={{ marginBottom: 0 }}
      //             // onClick={() => handleEdit(record?.staffId)}
      //             onClick={() => navigate("/new-quotation/" + param?.id)}
      //           >
      //             <Edit_icon />
      //           </p>
      //         </Tooltip>
      //         <Tooltip title={"View"} placement="bottom">
      //           <p
      //             className="pointer"
      //             style={{ marginBottom: 0 }}
      //             onClick={() => handleEdit(record?.staffId)}
      //           >
      //             <EyeIcon />
      //           </p>
      //         </Tooltip>
      //         <Tooltip title={"PDF"} placement="bottom">
      //           <p
      //             className="pointer"
      //             style={{ marginBottom: 0 }}
      //             onClick={() => handleEdit()}
      //           >
      //             <Pfd_icon />
      //           </p>
      //         </Tooltip>
      //         <Tooltip title={"Send mail to client"} placement="bottom">
      //           <p
      //             className="pointer"
      //             style={{ marginBottom: 0 }}
      //             onClick={() => showModal()}
      //           >
      //             <Mail_in />
      //           </p>
      //         </Tooltip>
      //         <Tooltip title="Send mail to HO" placement="bottom">
      //           <div className="view-all-staff">
      //             <p
      //               className="pointer"
      //               style={{ marginBottom: 0 }}
      //               onClick={() => showModal()}
      //             >
      //               <Mail_sent />
      //             </p>
      //           </div>
      //         </Tooltip>
      //       </Space>
      //     ) : (
      //       <Space size="middle">
      //         {/* <Tooltip title={"Edit"} placement="bottom"> */}
      //         <p style={{ marginBottom: 0 }}>
      //           <Disable_Edit_icon />
      //         </p>
      //         {/* </Tooltip> */}
      //         <Tooltip title="Enable" placement="bottom">
      //           <Popconfirm
      //             title={
      //               <span
      //                 className="test"
      //                 style={{
      //                   textTransform: "initial",
      //                 }}
      //               >
      //                 Do you want to enable this Staff ?
      //               </span>
      //             }
      //             // description="Are you sure to Enable this staff?"
      //             okText="Yes"
      //             cancelText="No"
      //             icon={
      //               <QuestionCircleOutlined
      //                 style={{
      //                   color: "green",
      //                 }}
      //               />
      //             }
      //             // onConfirm={() => handleEnable(record?.staffId)}
      //           >
      //             <p className="pointer">
      //               <img src={check_mark} style={{ width: "22px" }} />
      //             </p>
      //           </Popconfirm>
      //         </Tooltip>
      //       </Space>
      //     );
      //   },
    },
  ];

  return (
    <>
      <div className="lead-gen-list-container mt-1 ml-2">
        <Heading title="CP Quotation List" />
      </div>
      <div className="master-main-container m-1">
        <div className="align-center space-between">
          {/* <div className="pl-1-5 fw-600"></div> */}
          <h2 className="p-1">{cpName}</h2>
          <Row className="p-1">
            <Button
              className="save-btn"
              onClick={() => navigate("/cp-quotation-new/" + param?.id)}
            >
              {" "}
              + New Quote
            </Button>
          </Row>
        </div>
        <Table
          className="table-head-sticky"
          loading={{
            indicator: (
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            ),
            spinning: loading,
          }}
          columns={columns}
          pagination={false}
          // loading={loading}
          dataSource={tableData}

          //   rowClassName={(record) => !record?.status && "disabled-row"}
        />
      </div>
    </>
  );
};

export default CPQuotationList;
