import { combineReducers } from "redux";

import actions from "../constants/cashBookingActions";
import {
  getFetchingState,
  getSuccessState,
  getErrorState,
  getResetState,
  createReducer,
} from "../utils/reducers";

import { defaultState } from "./config";

const loginUser = createReducer(defaultState, {
  [actions.LOGIN_USER]: getFetchingState,
  [actions.LOGIN_USER_SUCCESS]: getSuccessState,
  [actions.LOGIN_USER_ERROR]: getErrorState,
  [actions.LOGIN_USER_CLEAR]: getResetState,
});

const getUser = createReducer(defaultState, {
  [actions.GET_USER]: getFetchingState,
  [actions.GET_USER_SUCCESS]: getSuccessState,
  [actions.GET_USER_ERROR]: getErrorState,
  [actions.GET_USER_CLEAR]: getResetState,
});

const getCpPremiseReducer = createReducer(defaultState, {
  [actions.GET_CP_PREMISES]: getFetchingState,
  [actions.GET_CP_PREMISES_SUCCESS]: getSuccessState,
  [actions.GET_CP_PREMISES_ERROR]: getErrorState,
  [actions.GET_CP_PREMISES_CLEAR]: getResetState,
});

const getAccountServiceReducer = createReducer(defaultState, {
  [actions.GET_ACCOUNT_SERVICE]: getFetchingState,
  [actions.GET_ACCOUNT_SERVICE_SUCCESS]: getSuccessState,
  [actions.GET_ACCOUNT_SERVICE_ERROR]: getErrorState,
  [actions.GET_ACCOUNT_SERVICE_CLEAR]: getResetState,
});
const getSenderPincode = createReducer(defaultState, {
  [actions.GET_SENDER_PINCODE]: getFetchingState,
  [actions.GET_SENDER_PINCODE_SUCCESS]: getSuccessState,
  [actions.GET_SENDER_PINCODE_ERROR]: getErrorState,
  [actions.GET_SENDER_PINCODE_CLEAR]: getResetState,
});

const getGSTDetails = createReducer(defaultState, {
  [actions.GET_GST_DETAILS]: getFetchingState,
  [actions.GET_GST_DETAILS_SUCCESS]: getSuccessState,
  [actions.GET_GST_DETAILS_ERROR]: getErrorState,
  [actions.GET_GST_DETAILS_CLEAR]: getResetState,
});

const getBarcodeIdReducer = createReducer(defaultState, {
  [actions.GET_BARCODE_ID]: getFetchingState,
  [actions.GET_BARCODE_ID_SUCCESS]: getSuccessState,
  [actions.GET_BARCODE_ID_ERROR]: getErrorState,
  [actions.GET_BARCODE_ID_CLEAR]: getResetState,
});

const getBookingDetailsReducer = createReducer(defaultState, {
  [actions.GET_BOOKING_DETAILS]: getFetchingState,
  [actions.GET_BOOKING_DETAILS_SUCCESS]: getSuccessState,
  [actions.GET_BOOKING_DETAILS_ERROR]: getErrorState,
  [actions.GET_BOOKING_DETAILS_CLEAR]: getResetState,
});

const getPostShipmentReducer = createReducer(defaultState, {
  [actions.CREATE_SHIPMENT]: getFetchingState,
  [actions.CREATE_SHIPMENT_SUCCESS]: getSuccessState,
  [actions.CREATE_SHIPMENT_ERROR]: getErrorState,
  [actions.CREATE_SHIPMENT_CLEAR]: getResetState,
});

const getServiceAbilityCheckReducer = createReducer(defaultState, {
  [actions.GET_SERVICEABILITY_DETAILIS]: getFetchingState,
  [actions.GET_SERVICEABILITY_DETAILIS_SUCCESS]: getSuccessState,
  [actions.GET_SERVICEABILITY_DETAILIS_ERROR]: getErrorState,
  [actions.GET_SERVICEABILITY_DETAILIS_CLEAR]: getResetState,
});

const getCalculaterTatChartReducer = createReducer(defaultState, {
  [actions.GET_CAL_TATCHART]: getFetchingState,
  [actions.GET_CAL_TATCHART_SUCCESS]: getSuccessState,
  [actions.GET_CAL_TATCHART_ERROR]: getErrorState,
  [actions.GET_CAL_TATCHART_CLEAR]: getResetState,
});

const getPinCodeAreaReducer = createReducer(defaultState, {
  [actions.GET_PINCODE_AREA]: getFetchingState,
  [actions.GET_PINCODE_AREASUCCESS]: getSuccessState,
  [actions.GET_PINCODE_AREAERROR]: getErrorState,
  [actions.GET_PINCODE_AREACLEAR]: getResetState,
});

const getUpdateShipmentReducer = createReducer(defaultState, {
  [actions.UPDATE_SHIPMENT]: getFetchingState,
  [actions.UPDATE_SHIPMENT_SUCCESS]: getSuccessState,
  [actions.UPDATE_SHIPMENT_ERROR]: getErrorState,
  [actions.UPDATE_SHIPMENT_CLEAR]: getResetState,
});

const getUpdateAWBNumReducer = createReducer(defaultState, {
  [actions.UPDATE_AWB_NUM]: getFetchingState,
  [actions.UPDATE_AWB_NUM_SUCCESS]: getSuccessState,
  [actions.UPDATE_AWB_NUM_ERROR]: getErrorState,
  [actions.UPDATE_AWB_NUM_CLEAR]: getResetState,
});

const getSingleBookingReducer = createReducer(defaultState, {
  [actions.GET_SINGLE_VIEWBOOKING]: getFetchingState,
  [actions.GET_SINGLE_VIEWBOOKING_SUCCESS]: getSuccessState,
  [actions.GET_SINGLE_VIEWBOOKING_CLEAR]: getErrorState,
  [actions.GET_SINGLE_VIEWBOOKING_CLEAR]: getResetState,
});

const getChildPacketIdsReducer = createReducer(defaultState, {
  [actions.GET_CHILDPACKETIDs]: getFetchingState,
  [actions.GET_CHILDPACKETIDs_SUCCESS]: getSuccessState,
  [actions.GET_CHILDPACKETIDS__ERROR]: getErrorState,
  [actions.GET_CHILDPACKETIDs__CLEAR]: getResetState,
});

const getManifestReducer = createReducer(defaultState, {
  [actions.GET_MANIFEST]: getFetchingState,
  [actions.GET_MANIFEST_SUCCESS]: getFetchingState,
  [actions.GET_MANIFEST_ERROR]: getFetchingState,
  [actions.GET_MANIFEST_CLEAR]: getFetchingState,
});

const getPostalPincodeReducer = createReducer(defaultState, {
  [actions.GET_POSTAL_PINCODE]: getFetchingState,
  [actions.GET_POSTAL_PINCODE_SUCCESS]: getSuccessState,
  [actions.GET_POSTAL_PINCODE_ERROR]: getErrorState,
  [actions.GET_POSTAL_PINCODE_CLEAR]: getResetState,
});

const createChildShipmentReducer = createReducer(defaultState, {
  [actions.CREATE_CHILD_SHIPMENT]: getFetchingState,
  [actions.CREATE_CHILD_SHIPMENT_SUCCESS]: getSuccessState,
  [actions.CREATE_CHILD_SHIPMENT_ERROR]: getErrorState,
  [actions.CREATE_CHILD_SHIPMENT_CLEAR]: getResetState,
});
// eway-bill
const getEWayBillReducer = createReducer(defaultState, {
  [actions.GET_E_WAY_BILL]: getFetchingState,
  [actions.GET_E_WAY_BILL_SUCCESS]: getSuccessState,
  [actions.GET_E_WAY_BILL_ERROR]: getErrorState,
  [actions.GET_E_WAY_BILL_CLEAR]: getResetState,
});

//vehicle onboard

const getAllOnboarededVehiclesTableReducer = createReducer(defaultState, {
  [actions.GET_ALL_ONBOARDED_VEHICLES_TABLE]: getFetchingState,
  [actions.GET_ALL_ONBOARDED_VEHICLES_TABLE_SUCCESS]: getSuccessState,
  [actions.GET_ALL_ONBOARDED_VEHICLES_TABLE_ERROR]: getErrorState,
  [actions.GET_ALL_ONBOARDED_VEHICLES_TABLE_CLEAR]: getResetState,
});

const onboardVehicleReducer = createReducer(defaultState, {
  [actions.ONBOARD_VEHICLE_]: getFetchingState,
  [actions.ONBOARD_VEHICLE_SUCCESS]: getSuccessState,
  [actions.ONBOARD_VEHICLE_ERROR]: getErrorState,
  [actions.ONBOARD_VEHICLE_CLEAR]: getResetState,
});

const updateOnboardedVehicleReducer = createReducer(defaultState, {
  [actions.UPDATE_ONBOARDED_VEHICLE_]: getFetchingState,
  [actions.UPDATE_ONBOARDED_VEHICLE_SUCCESS]: getSuccessState,
  [actions.UPDATE_ONBOARDED_VEHICLE_ERROR]: getErrorState,
  [actions.UPDATE_ONBOARDED_VEHICLE_CLEAR]: getResetState,
});

const getSingleOnboarededVehicleReducer = createReducer(defaultState, {
  [actions.GET_SINGLE_ONBOARDED_VEHICLE_]: getFetchingState,
  [actions.GET_SINGLE_ONBOARDED_VEHICLE_SUCCESS]: getSuccessState,
  [actions.GET_SINGLE_ONBOARDED_VEHICLE_ERROR]: getErrorState,
  [actions.GET_SINGLE_ONBOARDED_VEHICLE_CLEAR]: getResetState,
});

const disableOnboaredVehcileReducer = createReducer(defaultState, {
  [actions.DISBALE_ONBOARDED_VEHICLE_]: getFetchingState,
  [actions.DISBALE_ONBOARDED_VEHICLE_SUCCESS]: getSuccessState,
  [actions.DISBALE_ONBOARDED_VEHICLE_ERROR]: getErrorState,
  [actions.DISBALE_ONBOARDED_VEHICLE_CLEAR]: getResetState,
});

const getAccountPostShipmentReducer = createReducer(defaultState, {
  [actions.ACCOUNT_CREATE_SHIPMENT]: getFetchingState,
  [actions.ACCOUNT_CREATE_SHIPMENT_SUCCESS]: getSuccessState,
  [actions.ACCOUNT_CREATE_SHIPMENT_ERROR]: getErrorState,
  [actions.ACCOUNT_CREATE_SHIPMENT_CLEAR]: getResetState,
});

const getAccountUpdateShipmentReducer = createReducer(defaultState, {
  [actions.ACCOUNT_UPDATE_SHIPMENT]: getFetchingState,
  [actions.ACCOUNT_UPDATE_SHIPMENT_SUCCESS]: getSuccessState,
  [actions.ACCOUNT_UPDATE_SHIPMENT_ERROR]: getErrorState,
  [actions.ACCOUNT_UPDATE_SHIPMENT_CLEAR]: getResetState,
});

export default combineReducers({
  getUser,
  loginUser,
  getCpPremiseReducer,
  getAccountServiceReducer,
  getGSTDetails,
  getUpdateShipmentReducer,
  getBarcodeIdReducer,
  getBookingDetailsReducer,
  getServiceAbilityCheckReducer,
  getPostShipmentReducer,
  getSenderPincode,
  getCalculaterTatChartReducer,
  getPinCodeAreaReducer,
  getUpdateAWBNumReducer,
  getSingleBookingReducer,
  getChildPacketIdsReducer,
  getManifestReducer,
  getPostalPincodeReducer,
  createChildShipmentReducer,
  getEWayBillReducer,

  onboardVehicleReducer,
  getSingleOnboarededVehicleReducer,
  updateOnboardedVehicleReducer,
  disableOnboaredVehcileReducer,
  getAllOnboarededVehiclesTableReducer,
  getAccountPostShipmentReducer,
  getAccountUpdateShipmentReducer,
});
