import keyMirror from "keymirror";

const actions = {
  //client onboard//
  CLIENT_ONBOARD_PATCH: null,
  CLIENT_ONBOARD_PATCH_SUCCESS: null,
  CLIENT_ONBOARD_PATCH_ERROR: null,
  CLIENT_ONBOARD_PATCH_CLEAR: null,

  GET_CLIENT_ONBOARD: null,
  GET_CLIENT_ONBOARD_SUCCESS: null,
  GET_CLIENT_ONBOARD_ERROR: null,
  GET_CLIENT_ONBOARD_CLEAR: null,

  GET_CLIENT_PINCODE_MAPPING: null,
  GET_CLIENT_PINCODE_MAPPING_SUCCESS: null,
  GET_CLIENT_PINCODE_MAPPING_ERROR: null,
  GET_CLIENT_PINCODE_MAPPING_CLEAR: null,

  GET_STAFF_ONBOARD: null,
  GET_STAFF_ONBOARD_SUCCESS: null,
  GET_STAFF_ONBOARD_ERROR: null,
  GET_STAFF_ONBOARD_CLEAR: null,

  UPDATE_STAFF_ONBOARD: null,
  UPDATE_STAFF_ONBOARD_SUCCESS: null,
  UPDATE_STAFF_ONBOARD_ERROR: null,
  UPDATE_STAFF_ONBOARD_CLEAR: null,
};
export default keyMirror(actions);
