import React, { useEffect, useState } from "react";
import { Space, Input, Tooltip, Popconfirm, message } from "antd";
import { useDispatch } from "react-redux";

import { capitalize, commonSorterForTable, serialNum } from "../../../utils";
import TableContainer from "../../../components/TableContainer";
import { getAllOnboardedVehicles } from "../../../actionsMethods/cashBookingMethods";
import { getPremiseId } from "../../../utils/worker";

import urls from "../../../api/urls";
import API from "../../../api";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { ReactComponent as Edit_icon } from "../../../assests/images/edit-logo.svg";
import { ReactComponent as Disable_icon } from "../../../assests/images/SVG/disable.svg";
import { ReactComponent as Disable_Edit_icon } from "../../../assests/images/SVG/disableEdit.svg";

import search_icon from "../../../assests/images/search-icon.svg";
import check_mark from "../../../assests/images/SVG/check.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

const { UPDATE_STAFF, GET_ALL_STAFF } = urls;

// import "./style.scss";

const ViewStaffTable = ({ handleEdit = () => {}, activeKey }) => {
  const dispatch = useDispatch();
  const premiseId = getPremiseId();
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [tableData, setTableData] = useState([]);
  const [dataSource, setdataSource] = useState(tableData);
  const [pageSize, setPageSize] = useState(20);
  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});

  useEffect(() => {
    getStaffTable();
  }, [premiseId]);

  useEffect(() => {
    if (activeKey == "2") {
      dispatch(getAllOnboardedVehicles());
    }
  }, [activeKey]);

  const getStaffTable = async () => {
    setLoading(true);
    let response = await API.Api(GET_ALL_STAFF).getWithUserNPremiseHeader();
    if (response?.status) {
      setLoading(false);
      let temp = response?.response?.data?.map((val) => {
        val["fullname"] = val?.firstName + " " + val?.lastName;
        return val;
      });
      setTableData(temp);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const updateStaffOnboard = async (values) => {
    setLoading(true);
    let response = await API.Api(UPDATE_STAFF).putWithHeader(values);
    if (response?.status) {
      setLoading(false);
      message.success(response?.response?.data?.message);
      getStaffTable();
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  const handleDisable = (id) => {
    let payload = {
      staffId: id,
      status: false,
    };
    updateStaffOnboard(payload);
  };

  const handleEnable = (id) => {
    let payload = {
      staffId: id,
      status: true,
    };
    updateStaffOnboard(payload);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: "Sl No",
      dataIndex: "key",
      key: "key",
      align: "center",
      // width: 50,
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "User name",
      dataIndex: "firstName",
      key: "firstName",
      // width: 100,

      sortOrder: sortedInfo.columnKey === "firstName" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "firstName"),
      ellipsis: true,

      render: (text, val) => {
        return text + " " + val?.lastName;
      },
    },
    {
      title: "Email",
      dataIndex: "emailId",
      key: "emailId",
      width: 300,
    },
    {
      title: "Phone",
      dataIndex: "contactNumber",
      key: "contactNumber",
      // width: 90,
    },
    {
      title: "Role",
      dataIndex: "label",
      key: "label",
      // width: 50,
      sortOrder: sortedInfo.columnKey === "label" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "label"),
      ellipsis: true,

      render: (text) => {
        return capitalize(text) || "--";
      },
    },
    {
      title: "Date of joining",
      dataIndex: "createdDate",
      key: "createdDate",
      // width: 90,
      render: (text) => text,
    },

    {
      title: "Actions",
      key: "action",
      align: "center",
      className: "actions-col",
      // width: 150,

      render: (_, record) => {
        return record?.status ? (
          <Space size="middle">
            <Tooltip title={"Edit"} placement="bottom">
              <p
                className="pointer"
                style={{ marginBottom: 0 }}
                onClick={() => handleEdit(record?.staffId)}
              >
                <Edit_icon />
              </p>
            </Tooltip>
            <Tooltip title="Disable" placement="bottom">
              <div className="view-all-staff">
                <Popconfirm
                  title={
                    <span
                      className="test"
                      style={{
                        textTransform: "initial",
                      }}
                    >
                      Do you want to disable this Staff ?
                    </span>
                  }
                  cancelText="No"
                  okText="Yes"
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  }
                  onConfirm={() => handleDisable(record?.staffId)}
                >
                  <p className="pointer" style={{ marginBottom: 0 }}>
                    <Disable_icon />
                  </p>
                </Popconfirm>
              </div>
            </Tooltip>
          </Space>
        ) : (
          <Space size="middle">
            {/* <Tooltip title={"Edit"} placement="bottom"> */}
            <p style={{ marginBottom: 0 }}>
              <Disable_Edit_icon className="pointer" />
            </p>
            {/* </Tooltip> */}
            <Tooltip title="Enable" placement="bottom">
              <Popconfirm
                title={
                  <span
                    className="test"
                    style={{
                      textTransform: "initial",
                    }}
                  >
                    Do you want to enable this Staff ?
                  </span>
                }
                // description="Are you sure to Enable this staff?"
                okText="Yes"
                cancelText="No"
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: "green",
                    }}
                  />
                }
                onConfirm={() => handleEnable(record?.staffId)}
              >
                <p className="pointer">
                  <img
                    src={check_mark}
                    style={{ width: "22px", marginBottom: "10px" }}
                  />
                </p>
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const filterArray = (data, currValue) => {
    return data?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );
  };

  const onSearch = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(tableData, currValue)
        : filterArray(tableData, currValue);
    setdataSource(filteredData);
  };

  const handlePageChange = (currentPage, pageSize) => {
    setPageSize(pageSize);
    setPageNum(currentPage);
  };

  return (
    <div className="master-main-container view-all-staff">
      <div className="align-center space-between">
        <div className="pl-1-5 fw-600"></div>
        <div className="text-right p-1">
          <Input
            placeholder="Search by keyword"
            bordered
            onChange={onSearch}
            style={{ borderRadius: "5px", height: "40px", width: "400px" }}
            suffix={<img src={search_icon} />}
          />
        </div>
      </div>
      <TableContainer
        className="table-head-sticky"
        loading={{
          indicator: (
            <img
              src={Smcs_Loader}
              style={{ height: "100px", width: "100px" }}
            />
          ),
          spinning: loading,
        }}
        columns={columns}
        // loading={loading}
        defaultCurrent={1}
        onChange={handleChange}
        dataSource={inputSearchValue?.length == 0 ? tableData : dataSource}
        pagination={{
          // pageSize: pageSize,
          // total: inputSearchValue.length == 0 ? tableData.length : 10,
          itemRender: itemRender,
          onChange: handlePageChange,
        }}
        rowClassName={(record) => !record?.status && "disabled-row"}
      />
    </div>
  );
};

export default ViewStaffTable;
