/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Col, Row, Select, Form, Input, message, Button, Radio } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  clearCreatePincode,
  getState,
} from "../../../actionsMethods/masterMethods";
import { checkNumbervalue } from "../../../utils";
import MasterModal from "../../../components/Master/Modal";
import x_icon from "../../../assests/images/SVG/removeItem.svg";
import { ReactComponent as Clock } from "../../../assests/images/timerIcon.svg";
import "./style.scss";

const AddPinFormModal = ({
  loading,
  serviceResult,
  stateResult,
  visible,
  areadata,
  premiumAreaData,
  setPremiumAreaData,
  setAreaData,
  serviceability,
  areaServiceType,
  handleAreaType = () => {},
  handleAddModal = () => {},
  handleCloseModal = () => {},
  handleAddPincode = () => {},
}) => {
  const dispatch = useDispatch();
  const formLayout = "vertical";
  const [form] = Form.useForm();

  const { Option } = Select;
  const [pincodeDetails, setPincodeDetails] = useState();

  const mockMileData = [24, 48, 72, 96];

  const createPincodeResponse = useSelector(
    (state) => state?.masterReducer?.createPincodeReducer,
    shallowEqual
  );

  const {
    fetching: createPincodeFetch,
    result: createPinResult,
    error: CreatePinError,
  } = createPincodeResponse;

  useEffect(() => {
    form.resetFields();
    setAreaData([]);
    setPremiumAreaData([]);
  }, [visible]);

  useEffect(() => {
    if (createPinResult !== null) {
      message.success(createPinResult?.message);
      handleAddModal();
      dispatch(clearCreatePincode());
      form.setFieldValue("area", "");
      form.resetFields();
    }
    if (CreatePinError !== null) {
      message.error(CreatePinError?.message);
      dispatch(clearCreatePincode());
    }
  }, [createPinResult, CreatePinError]);

  useEffect(() => {
    form.setFieldValue("stateCode", stateResult?.stateCode);
    form.setFieldValue("zone", stateResult?.zone);
  }, [stateResult]);

  useEffect(() => {
    form.setFieldValue("state", pincodeDetails?.State);
    form.setFieldValue("city", pincodeDetails?.District);
    if (pincodeDetails?.State) {
      dispatch(getState(pincodeDetails?.State));
    }
  }, [pincodeDetails]);

  const formProps = {
    name: "address",
    onFinish: handleAddPincode,
    autoComplete: "off",
  };

  const handlePincodeInput = async (values) => {
    setAreaData([]);
    setPremiumAreaData([]);
    form.setFieldValue("pincodeType", null);
    form.setFieldValue("mile", null);
    form.setFieldValue("stateCode", null);
    form.setFieldValue("zone", null);
    form.setFieldValue("state", null);
    form.setFieldValue("city", null);

    if (values.target.value.length > 5) {
      try {
        let response = await fetch(
          `https://api.postalpincode.in/pincode/${values.target.value}`
        );
        let res = await response.json();

        if (res[0].Status === "Success") {
          setPincodeDetails(res[0]["PostOffice"][0]);
        } else {
          message.error("Invalid Pincode");
          form.resetFields();
          form.setFieldValue("pinCode", values.target.value);
        }
      } catch (error) {
        message.error("Invalid Pincode");
      }
    }
  };

  function handleAreaDel(params, type) {
    if (type == "Regular") {
      let delAreas = areadata?.filter((val, idx) => params !== idx);
      setAreaData(delAreas);
    } else {
      let delPremiumAreas = premiumAreaData?.filter(
        (val, idx) => params !== idx
      );
      setPremiumAreaData(delPremiumAreas);
    }
  }

  const handleAreaChange = (value, type) => {
    let inputvalue = value.target.value;

    function capitalizeFirstLetter(str) {
      const capitalized = str?.charAt(0)?.toUpperCase() + str?.slice(1);
      return capitalized;
    }

    if (inputvalue.length > 1) {
      const isDuplicateInRegular = areadata.some(
        (val) => val?.toLowerCase() === inputvalue?.toLowerCase()
      );

      const isDuplicateInPremium = premiumAreaData.some(
        (val) => val?.toLowerCase() === inputvalue?.toLowerCase()
      );

      if (
        type === "Regular" &&
        (isDuplicateInRegular || isDuplicateInPremium)
      ) {
        form.setFieldValue("Regular area", "");
        message.error("Area name already exists");
      } else if (
        type === "Premium" &&
        (isDuplicateInRegular || isDuplicateInPremium)
      ) {
        form.setFieldValue("Premium area", "");
        message.error("Area name already exists");
      } else {
        if (type === "Regular") {
          setAreaData([...areadata, capitalizeFirstLetter(inputvalue)]);
        } else if (type === "Premium") {
          setPremiumAreaData([
            ...premiumAreaData,
            capitalizeFirstLetter(inputvalue),
          ]);
        }
      }
    }

    form.setFieldValue("Regular area", "");
    form.setFieldValue("Premium area", "");
  };

  function showAreaFormItem(params, data) {
    return (
      <>
        <Col span={12}>
          <Form.Item
            name={params + " area"}
            label={params + " area"}
            rules={[
              {
                required:
                  params == "Regular"
                    ? areadata?.length == 0
                    : premiumAreaData?.length == 0,
                message: "Area is required",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              className="tag-items"
              placeholder="Enter area name"
              onPressEnter={(e) => handleAreaChange(e, params)}
              onKeyPress={(e) => {
                if (/[^A-Za-z0-9-/ ]/.test(e.key)) {
                  e.preventDefault();
                }
                if (e.target.value.length >= 20) {
                  e.preventDefault();
                }
              }}
              onKeyDown={(e) => {
                if (!/[a-zA-Z0-9]/.test(e.target.value)) e.target.value = "";
              }}
            />
          </Form.Item>

          {data?.length > 0 && (
            <div className="area-list-box">
              {data?.map((val, idx) => {
                return (
                  <>
                    <div className="area-list-item" key={idx}>
                      {val}
                      <span
                        onClick={() => handleAreaDel(idx, params)}
                        className="pointer"
                        style={{ marginLeft: "10px" }}
                      >
                        <img src={x_icon} alt="X" width={11} />
                      </span>
                    </div>
                  </>
                );
              })}
            </div>
          )}
        </Col>
      </>
    );
  }

  return (
    <MasterModal
      title={"Add PIN Code Details"}
      visible={visible}
      handleCloseModal={handleCloseModal}
      width={900}
      destroyOnClose
      loading={loading || createPincodeFetch}
      className="pincode-master-modal"
      footer={
        <>
          <div className="d-flex flex-end" style={{ padding: "1rem 0 0 0" }}>
            <Button className=" cancel-btn w-13" onClick={handleCloseModal}>
              CANCEL
            </Button>
            <Button
              htmlType="submit"
              onClick={form.submit}
              className="save-btn ml-1 w-13"
            >
              SAVE
            </Button>
          </div>
        </>
      }
      content={
        <div>
          <Form
            {...formProps}
            form={form}
            initialValues={{
              areaType: "Regular",
            }}
            layout={formLayout}
            className="global-form"
          >
            <Row
              justify="space-evenly"
              span={24}
              className="global-form-row"
              gutter={20}
            >
              <Col span={12}>
                <Form.Item
                  name="pinCode"
                  label="PIN code"
                  rules={[
                    {
                      message: "Pincode is required",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter PIN code"
                    onChange={handlePincodeInput}
                    maxLength={6}
                    onKeyPress={(event) => {
                      if (checkNumbervalue(event)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="PIN code type"
                  name="pincodeType"
                  rules={[
                    {
                      required: true,
                      message: "Pincode type is required",
                    },
                  ]}
                >
                  <Select
                    labelInValue
                    className="app-selector"
                    placeholder={"Select PIN code type"}
                  >
                    {serviceResult?.map((val, idx) => {
                      return (
                        <div key={idx}>
                          <Option
                            className="dropDown"
                            value={val?.serviceableId}
                          ></Option>

                          {val?.serviceability?.includes(",")
                            ? "Both"
                            : val?.serviceability}
                        </div>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Serviceability type"
                  name="ServiceabilityType"
                  rules={[
                    {
                      required: true,
                      message: "Service type is required",
                    },
                  ]}
                >
                  <Select
                    labelInValue
                    className="app-selector"
                    placeholder={"Select Service type"}
                    // onChange={}
                  >
                    {serviceability
                      ?.sort((a, b) => b?.typeId - a?.typeId)
                      ?.map((val, idx) => {
                        return (
                          <div key={idx}>
                            <Option
                              className="dropDown"
                              value={val?.typeId}
                            ></Option>
                            {val?.pincodeType?.includes(",")
                              ? "All"
                              : val?.pincodeType}
                          </div>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="city"
                  label="City"
                  className="bookingAreaItem"
                  rules={[
                    {
                      message: null,
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="City name" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={11}>
                    <Form.Item
                      name="state"
                      label="State name"
                      rules={[
                        {
                          message: null,
                          required: true,
                        },
                      ]}
                      className="bookingAreaItem"
                    >
                      <Input placeholder="State name" disabled />
                    </Form.Item>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Form.Item
                      name="stateCode"
                      label="State code"
                      rules={[
                        {
                          message: null,
                          required: true,
                        },
                      ]}
                      className="bookingAreaItem"
                    >
                      <Input placeholder="State code" disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="zone"
                  label="Zone"
                  className="bookingAreaItem"
                  rules={[
                    {
                      message: null,
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Zone name" disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={"areaType"}
                  label="Select area type"
                  rules={[
                    {
                      message: "Select area type",
                      required: true,
                    },
                  ]}
                >
                  <Radio.Group defaultValue={false} onChange={handleAreaType}>
                    <Radio value={"Regular"}>Regular</Radio>
                    <Radio value={"Premium"}>Premium</Radio>
                    <Radio value={"Both"}>Both</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              {(areaServiceType === "Regular" || areaServiceType === "Both") &&
                showAreaFormItem("Regular", areadata)}

              {(areaServiceType === "Premium" || areaServiceType === "Both") &&
                showAreaFormItem("Premium", premiumAreaData)}

              <Col span={12} className="">
                <Form.Item
                  label="First mile & Last mile TAT "
                  name="mile"
                  rules={[
                    {
                      required: true,
                      message: "First mile & Last mile TAT is required",
                    },
                  ]}
                >
                  <Select
                    labelInValue
                    className="app-selector"
                    placeholder={"Select time"}
                    suffixIcon={<Clock />}
                  >
                    {mockMileData?.map((val, idx) => {
                      return (
                        <div key={idx}>
                          <Option className="dropDown" value={val}></Option>
                          {val + " " + "hrs"}
                        </div>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Form>
        </div>
      }
    ></MasterModal>
  );
};

export default AddPinFormModal;
