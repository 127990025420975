import React, { useEffect } from "react";
import ViewAllVehiclesTable from "../../Master/VehicleOnboard/viewAllVehicle";
import Heading from "../../../components/Heading";

const VehicleList = () => {
  useEffect(() => {}, []);

  return (
    <div className="p-2 xyz">
      <Heading title={"Vehicle List"} />
      <div className="master-main-container">
        <ViewAllVehiclesTable />
      </div>
    </div>
  );
};

export default VehicleList;
