import React from "react";
import editIcon from "../../../assests/images/onbording/editIcon.svg";
import "./style.scss";

export const HeaderWithBottomLine = ({ text, icon, children }) => (
  <div className="header-with-bottom-line">
    <div>
      {" "}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="header-with-bottom-line-text">{text}</div>
        {icon && (
          <div>
            <img src={editIcon} alt="editIcon" style={{ padding: "5px 0" }} />
          </div>
        )}
        {children && <div>{children}</div>}
      </div>
      <div className="header-with-bottom-line-border"></div>
    </div>
  </div>
);
