import React from "react";
import "./style.scss";

const AppFooter = () => {
  return (
    <div className="footer">
      <p>Copyright © 2023 Shree Maruti Integrated Logistics Limited.</p>
    </div>
  );
};

export default AppFooter;
