import React from "react";
import { Row, Col, Form, Button, Input, Select } from "antd";

export function CreateTripForm({
  form,
  createTripHandle,
  showModal,
  disableFieldButton,
  setRoutId,
  routData,
  capitaliseWithHyphen,
  travellByMode,
  disableButton,
  vehicelNoHandle,
  vehicleList,
  driverNameHandle,
  driverList,
  getBagButton,
}) {
  const filterOption = (input,option)=>(option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <Form
      layout={"vertical"}
      form={form}
      autoComplete="off"
      onFinish={createTripHandle}
    >
      <div className="flex-end ">
        <Button onClick={() => showModal(true)} className="save-btn">
          LCR
        </Button>
      </div>
      <Row gutter={12}>
        <Col lg={12} md={12} xs={24} xl={12} sm={24}>
          <Form.Item label="Trip ID" name="tripId">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} xs={24} xl={12} sm={24}>
          <Form.Item label="Date" name="date">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        {travellByMode === "SURFACE" ? (
          <>
            <Col lg={12} md={12} xs={24} xl={12} sm={24}>
              <Form.Item
                label="Vehicle no"
                name="vehicleNo"
                rules={[
                  {
                    required: true,
                    message: "Select vehicle no",
                  },
                ]}
              >
                {/* <Input disabled={disableButton} /> */}
                <Select
                  showSearch
                  filterOption={filterOption}
                  disabled={disableButton}
                  onChange={vehicelNoHandle}
                  options={vehicleList?.map((data) => {
                    return {
                      label: data?.vehicleNo,
                      value: data?.vehicleNo,
                    };
                  })}
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24} xl={12} sm={24}>
              <Form.Item
                label="Driver name"
                name="driverName"
                rules={[
                  {
                    required: true,
                    message: "Select Driver Name",
                  },
                ]}
              >
                {/* <Input disabled={disableButton} /> */}
                <Select
                  showSearch
                  filterOption={filterOption}
                  disabled={disableButton}
                  onChange={driverNameHandle}
                  options={driverList?.map((data) => {
                    return {
                      label: data?.name,
                      value: data?.driverId,
                    };
                  })}
                />
              </Form.Item>
            </Col>
          </>
        ) : (
          <>
            <Col lg={12} md={12} xs={24} xl={12} sm={24}>
              <Form.Item
                label="Flight no."
                name="flightNo"
                rules={[
                  {
                    required: true,
                    message: "Select Flight No",
                  },
                ]}
              >
                <Input disabled={disableFieldButton} />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24} xl={12} sm={24}>
              <Form.Item
                label="Co-Loader"
                name="coLoader"
                rules={[
                  {
                    required: true,
                    message: "Select Co-Loader",
                  },
                ]}
              >
                <Input disabled={disableFieldButton} />
              </Form.Item>
            </Col>
          </>
        )}
        <Col lg={12} md={12} xs={24} xl={12} sm={24}>
          <Form.Item
            label="Mode of transport"
            name="modeOfTransport"
            rules={[
              {
                required: true,
                message: "Select mode of transport",
              },
            ]}
          >
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} xs={24} xl={12} sm={24}>
          <Form.Item
            label="Route"
            name="route"
            rules={[
              {
                required: true,
                message: "Select route",
              },
            ]}
          >
            <Select
              showSearch
              disabled={disableFieldButton}
              onChange={(e, b) => setRoutId(b)}
              className="input-field-style"
              placeholder="Select mode"
              optionFilterProp="children"
              filterOption={filterOption}
              options={routData?.map((item) => {
                return {
                  value: item?.routeId,
                  label: capitaliseWithHyphen(item?.name),
                };
              })}
            />
          </Form.Item>
        </Col>
      
      </Row>

      <div
        className="d-flex flex-end footer-btn"
        style={{
          marginTop: "1rem",
        }}
      >
        <Button className="save-btn" htmlType="submit" disabled={getBagButton}>
          GET BAGS
        </Button>
      </div>
    </Form>
  );
}
