/* eslint-disable no-unused-vars */
import { Steps, Row, Col, Spin, Button, message } from "antd";
import React, { useState, useEffect } from "react";
import KycDetails from "./KycDetails";
import NewMiscellaneous from "./NewMiscellaneous";
import PersonalDetails from "./PersonalDetails";
import CpDetails from "./CpDetails";
import Smcs_Loader from "../../assests/images/loader-new.gif";
import "./style.scss";
import API from "../../api";
import urls from "../../api/urls";
const { GET_KYC_DETAILS } = urls;

export const CpOnboardingNew = () => {
  const [current, setCurrent] = useState(0);
  const [kycData, setKycData] = useState(null);

  const [sideStepHide, setSideStepHide] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChange = (value) => {
    setCurrent(value);
  };

  const handleStepsChange = () => {
    if (current === 3) {
      setCurrent(3);
    } else {
      setCurrent(current + 1);
    }
  };

  const goBackStep = () => {
    window.scrollTo(0, 0);
    if (current == 0) {
      setCurrent(0);
    } else {
      setCurrent(current - 1);
    }
  };

  const getKYCDetails = async () => {
    let response = await API.Api(
      `${GET_KYC_DETAILS}`
    ).getWithUserNPremiseHeader();
    if (response?.status) {
      setKycData(response.response.data);
    } else {
      message.error(response?.response?.data?.message);
    }
  };

  useEffect(() => {
    getKYCDetails();
  }, [current]);

  const items = [
    {
      title: "KYC Details",
      description: "Please verify your KYC details",
      content: (
        <KycDetails
          handleStepsChange={handleStepsChange}
          goBackStep={goBackStep}
          kycData={kycData}
        />
      ),
    },
    {
      title: "CP Details",
      description: "Please provide CP details",
      content: (
        <CpDetails
          handleStepsChange={handleStepsChange}
          goBackStep={goBackStep}
          kycData={kycData}
        />
      ),
    },
    {
      title: "Personal Details",
      description: "Please provide personal details",
      content: (
        <PersonalDetails
          handleStepsChange={handleStepsChange}
          goBackStep={goBackStep}
        />
      ),
    },
    {
      title: "Miscellaneous Details",
      description: "Please provide miscellaneous details",
      content: (
        <NewMiscellaneous
          handleStepsChange={handleStepsChange}
          goBackStep={goBackStep}
        />
      ),
    },
  ];
  return (
    <div className="pl-2 pr-2 mb-2">
      <div className="master-main-container cp-details-main-container">
        <Spin
          spinning={loading}
          indicator={
            <img
              src={Smcs_Loader}
              style={{ height: "100px", width: "100px" }}
            />
          }>
          <Row className={sideStepHide ? "" : "p-1"}>
            {!sideStepHide && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={6}
                className="global-steps-container">
                <Steps
                  current={current}
                  direction="vertical"
                  onChange={onChange}
                  items={items}
                />
              </Col>
            )}
            <Col xs={24} sm={24} md={24} lg={24} xl={sideStepHide ? 24 : 18}>
              <div>{items[current]?.content}</div>
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
};

export default CpOnboardingNew;
