import { Modal, Spin } from "antd";
import React from "react";

const MasterModal = ({
  title,
  content,
  visible,
  handleCloseModal,
  width,
  handleSave,
  loading,
  footer,
  className,
}) => {
  return (
    <>
      <Modal
        title={title}
        open={visible}
        onOk={handleSave}
        destroyOnClose={true}
        okText={"Save"}
        width={width ? width : 800}
        footer={footer ? footer : false}
        onCancel={handleCloseModal}
        maskClosable={true}
        className={`${className} master-modal`}
      >
        <Spin spinning={loading}>
          <div>{content}</div>
        </Spin>
      </Modal>
    </>
  );
};
export default MasterModal;
