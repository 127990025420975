/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { message, Upload, Row, Col, Modal } from "antd";
import uploadLogo from "../../assests/images/bx_upload.svg";
import "./style.scss";

import { InboxOutlined } from "@ant-design/icons";
import { getToken } from "../../utils/worker";

const { Dragger } = Upload;

const DraggerComponent = ({
  action,

  handleRemove,
  fileList,
  form,
  // handlePreview,
  className,
  awbNumber,
  setFileList,
}) => {
  const authToken = getToken();
  const [userInfo, setUserInfo] = useState();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem("userId")));
  }, []);

  const handleChange = (info) => {
    let allFiles = info?.fileList?.map((val) => val?.response?.data);
    if (info?.file?.status === "done") {
      form.setFieldValue("idUrl", allFiles);
      message.success(
        info?.file?.response?.message || "Document uploaded successully"
      );
      form.setFields([
        {
          name: "idUrl",
          errors: null,
          message: null,
        },
      ]);
    }
    if (info?.file?.status === "error") {
      message.error("File not Supported");
    }
    setFileList(info?.fileList);
  };

  const handlePreview = (file) => {
    if (file?.type == "application/pdf" || file?.name?.split(".")[1] == "pdf") {
      window.open(file?.url || file?.response?.data?.filePath);
    } else {
      // setPreviewImage(file?.thumbUrl || file?.url);
      setPreviewImage(file?.response?.data?.filePath || file?.thumbUrl);
      setPreviewOpen(true);
      setPreviewTitle(
        file?.name || file?.url?.substring(file?.url?.lastIndexOf("/") + 1)
      );
    }
  };
  const handleCancel = () => setPreviewOpen(false);

  const beforeUpload = (file, onSuccess) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (file) {
      let checkSameFile = fileList
        ?.map((val) => val?.name)
        .some((val) => val === file?.name);

      if (file?.name?.includes("jfif")) {
        message.error("File not supported");
        return Upload.LIST_IGNORE;
      }
      if (checkSameFile) {
        message.error("Same file cannot be uploaded again");
        return Upload.LIST_IGNORE;
      }
      if (!isLt2M) {
        message.error("File size should be less than 10MB");
        return Upload.LIST_IGNORE;
      }
      if (!isJpgOrPng) {
        message.error("File not supported");
        return Upload.LIST_IGNORE;
      }
      return isJpgOrPng && isLt2M;
    } else {
      if (onSuccess) {
        message.success("File uploaded");
      }
      onSuccess("ok");
      return true;
    }
  };

  const props = {
    name: "files",
    listType: "picture",
    showUploadList: true,
    action: action,
    headers: {
      "SMCS-PREMISE-ID": userInfo?.userInfo?.premiseId,
      awbNumber: awbNumber,
      Authorization: `${authToken}`,
    },
    className: className,
    onChange: handleChange,
    onRemove: handleRemove,
    maxCount: 5,
    fileList: fileList,
    // fileList:
    // editFile !== null
    //   ? [
    //       {
    //         name: editFile && editFile?.split("/")?.pop(),
    //         url: editFile,
    //       },
    //     ]
    //   : fileList,
    beforeUpload: beforeUpload,
    onPreview: handlePreview,
    multiple: false,
  };

  return (
    <>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
      <Dragger {...props} className="">
        <Row gutter={24}>
          {/* <Col span={8} style={{ borderRight: "2px dashed #DFDFDF" }}>
            <img src={uploadLogo} style={{ width: "36px", height: "36px" }} />
          </Col> */}
          <Col>
            <Row>
              <Col span={6}>
                <img src={uploadLogo} className="upload-logo" />
              </Col>

              <Col span={17}>
                <p>
                  Supported file type: PDF, JPEG, PNG and Max 10MB file size
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Dragger>
    </>
  );
};

export default DraggerComponent;
