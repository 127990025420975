/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import Heading from "../../../components/Heading";
import API from "../../../api";
import urls from "../../../api/urls";
import { useParams } from "react-router-dom";
import "./style.scss";
import { firstLetterCapsInSentence } from "../../../utils";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
const OrderDetails = () => {
  const params = useParams();

  const { ORDER_DETAILS_BY_ID } = urls;
  const [dataSource, setDataSource] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [spinning, setSpinning] = useState(false);

  const tableLoading = {
    spinning: spinning,
    indicator: <SyncOutlined spin />,
  };
  useEffect(() => {
    getOrderList();
  }, []);

  const getOrderList = async () => {
    setSpinning(true);
    let response = await API.Api(
      ORDER_DETAILS_BY_ID + params.id
    ).getWithUserNPremiseHeader();
    if (response.status) {
      setSpinning(false);
      setDataSource(response?.response?.data?.items);
      setOrderDetails(response?.response?.data);
    } else {
      setSpinning(false);
    }
  };

  const columns = [
    {
      title: "Product Name",
      dataIndex: "itemName",
      key: "itemName",
      width: 40,
      render: (text) => (
        <span style={{ color: "#2E77D0" }}>
          {firstLetterCapsInSentence(text)}
        </span>
      ),
    },
    {
      title: "No. of Unit",
      dataIndex: "quantity",
      key: "quantity",
      width: 30,
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 30,
    },
    {
      title: "Total Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (text, _record) => <span>{text * _record?.quantity}</span>,
      width: 30,
    },
  ];

  return (
    <div className="p-2">
      <Heading title={"Order Details"} />
      <div className="master-main-container profile-container">
        <div className="p-1">
          {orderDetails ? (
            <div className="d-flex align-center space-between">
              <div>
                <span
                  style={{
                    color: "#202124",
                    fontSize: "14px",
                  }}
                >
                  Order Id :
                </span>
                &nbsp;
                <span
                  style={{
                    textTransform: "uppercase",
                    color: "#202124",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  {orderDetails?.orderId}
                </span>
              </div>
              <div>
                <span
                  style={{
                    color: "#202124",
                    fontSize: "15px",
                    fontWeight: "400",
                  }}
                >
                  {firstLetterCapsInSentence(orderDetails?.status)} On :
                </span>
                &nbsp;
                <span
                  style={{
                    color: "#202124",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  &nbsp;
                  {orderDetails?.updatedDate
                    ? orderDetails?.updatedDate
                    : orderDetails?.createdDate}{" "}
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          <br />
          <Table
            columns={columns}
            dataSource={dataSource}
            loading={tableLoading}
            pagination={false}
          />
          <div
            style={{
              textAlign: "right",
              paddingRight: "10rem",
              marginTop: "1rem",
            }}
          >
            <span
              style={{
                textTransform: "uppercase",
                color: "#202124",
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
              Sub Total : {orderDetails?.price?.orderTotal || 0}
            </span>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderDetails;
