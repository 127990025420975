/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {message, Spin } from "antd";
import { useNavigate } from "react-router";
import Heading from "../../../../components/Heading";
import "../../../../commonStyles/crossButton.scss";
import CommonRTO from "..";
import "./style.scss";
import API from "../../../../api";
import urls from "../../../../api/urls";
import { useParams } from "react-router-dom";
import { capitalise } from "../../../../utils";
const { UPDATE_CASH, GET_RTO_SHIPMENT_BY_AWBNUMBER } = urls;

const CreditRTO = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_RTO_SHIPMENT_BY_AWBNUMBER + params?.id
    ).getWithAllHeaders();
    if (response.status) {
      setLoading(false);
      setData(response?.response?.data);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
    const dummyData ={
      "awbNumber": 23495100000979,
      "bookingStartTime": null,
      "premiseId": 688,
      "userId": 346,
      "destinationPincode": 110031,
      "location": null,
      "cancellationReason": null,
      "addressDetails": {
        "awbNumber": 23495100000979,
        "senderName": "Govardhan",
        "senderAddressLine1": "8/16 MEHRAM NAGAR OPP.",
        "senderArea": "Leader S O",
        "senderCity": "Allahabad",
        "senderState": "Uttar Pradesh",
        "senderPincode": 211001,
        "senderMobileNumber": 8088131573,
        "senderEmail": "",
        "senderGst": "",
        "customerId": 1960581322055966000,
        "receiverName": "Govardhan",
        "receiverAddressLine1": "8/16 MEHRAM NAGAR OPP.",
        "receiverAddressLine2": "8/16 MEHRAM NAGAR OPP.DOMESTIC AIRPORT PALAM",
        "receiverCity": "Ambedkar Nagar",
        "receiverState": "Uttar Pradesh",
        "receiverPincode": 211001,
        "receiverAreaId": 4,
        "receiverAreaName": "ALLAHAB AD NEW CANTT S O",
        "receiverMobileNumber": 8088131572,
        "receiverEmail": "",
        "oldAwbNumber": 23489100000991
      },
      "shipmentDetails": {
        "shipmentDetailsId": "4afb58be-4d99-4877-b2a6-43ca0a6460b5",
        "awbNumber": 23495100000979,
        "type": "non-dox",
        "description": "Games",
        "content": "Books & Stationary",
        "value": 567,
        "weight": 55,
        "length": 55,
        "width": 5,
        "height": 55,
        "volumetricWeight": 3.02,
        "destinationCpId": 689,
        "destinationHubId": 687,
        "destinationHub": "687",
        "destinationPincode": 110031,
        "reattemptedDate": [
          2023,
          9,
          27,
          10,
          3,
          51,
          139662000
        ],
        "premiseId": 688,
        "bookingStartTime": "2023-09-27 08:53:47",
        "bookingCompleteTime": "2023-09-26 08:57:16",
        "bookingStatus": "COMPLETED",
        "service": "1",
        "readinessStatus": "READY_TO_MANIFEST",
        "updatedDate": "2023-09-27 08:53:47",
        "oldAwbNumber": 23489100000991,
        "bookingType": "Cash"
      },
      "serviceDetails": {
        "service": "standard",
        "travelBy": "surface"
      },
      "charges": {
        "charges": 42.37,
        "igst": 7.63,
        "freight": 50,
        "promotionId": "1",
        "discount": 10.5
      },
      "childShipmentDetails": [],
      "files": [],
      "bookingStatus": null,
      "booking_Status": "COMPLETED",
      "cpName": null,
      "cpNum": null,
      "delieveryFailureDto": null,
      "oldAwbNumber": 23489100000991,
      "bookingType": null,
      "local": false
    };
    // setData(data2);
    //
  };

  useEffect(() => {
    if (params) {
      getData();
    }
  }, [params]);


  const handleSave = async (formData) => {
    let payload =
    {
      bookingType: data?.shipmentDetails?.bookingType,
      ewayBillNumber: null,
      serviceDetailDto: {
        service:formData ? +formData?.split("_")[1] :  data?.serviceDetails?.service,
        travelBy:formData ? +formData?.split("_")[2] :  data?.serviceDetails?.travelBy
      },
      chargesRequestDto: {
        charges: null,
        igst: null,
        sgst: null,
        cgst: null,
        cess: null,
        freight: null
      }
    };
    let response = await API.Api(`${UPDATE_CASH}${data?.awbNumber}`).putIdHeader(payload);
    if (response.status) {
      message.success(`${capitalise(response?.response?.data?.message)} ${response?.response?.data?.awbNumber}`);
      navigate("/pending-drs");
    } else {
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="p-2">
        <Heading title="Return Shipment Details" />{" "}
        <div className="rto-container">
          <CommonRTO data={data} handleSave={handleSave}/>
        </div>
      </div>
    </Spin>
  );
};

export default CreditRTO;
