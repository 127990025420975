/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./style.scss";
import activeListData from "../../../store/activeGstNumbers.json";
import { Row, Col, Spin, Input, Form, Tooltip } from "antd";
import editButton from "../../../assests/images/penEdit.svg";
import AttachmentModal from "../../AttachmentModal/index";
import moment from "moment";
import API from "../../../api";
import urls from "../../../api/urls";

import {
  checkSpecialCharacter,
  checkSpace,
  checkNumbervalue,
} from "../../../utils";
const ServiceSummary = ({
  allAppData,
  inputHandler,
  gstEditHandler,
  gstState,
  eWayState,
  bookingType,
  ewayEditHandler,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { EWAYBILL } = urls;
  const currentDate = moment();
  const transporterId = "88AABCM9407D1ZS";
  const todayDateNTimeFormat = currentDate.format("DD/MM/YYYY hh:mm:ss A");

  return (
    <div className="service-summary-dox">
      <AttachmentModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        dataFile={allAppData?.files}
      />
      <Spin spinning={loading}>
        <table>
          {/* left */}
          <tr>
            <td className="table-data top-left">
              <Row>
                <Col span={12} className="heading">
                  Type:
                </Col>
                <Col span={12}>
                  {" "}
                  <b>
                    {allAppData?.shipmentDetails?.type?.toUpperCase() || null}
                  </b>{" "}
                </Col>
              </Row>
            </td>
            <td className="table-data">
              <Row>
                <Col span={12} className="heading">
                  Service :
                </Col>
                <Col span={12}>
                  {" "}
                  <b>
                    {allAppData?.serviceDetails?.service?.toUpperCase() || null}
                  </b>{" "}
                </Col>
              </Row>
            </td>

            <td className="table-data">
              <Row>
                <Col span={12} className="heading">
                  Travel by :
                </Col>
                <Col span={12}>
                  {" "}
                  <b>
                    {allAppData?.serviceDetails?.travelBy?.toUpperCase() ||
                      null}
                  </b>{" "}
                </Col>
              </Row>
            </td>
            <td className="table-data">
              <Row>
                <Col span={12} className="heading">
                  Content :{" "}
                </Col>
                <Col span={12}>
                  {" "}
                  <b>
                    {/* {allAppData?.shipmentDetails?.content?.toUpperCase() ||
                      null} */}
                    <div className="item">
                      {allAppData?.shipmentDetails?.content?.toUpperCase() +
                        " - " +
                        allAppData?.shipmentDetails?.description || null}
                    </div>{" "}
                  </b>{" "}
                </Col>
              </Row>
            </td>
            <td className="table-data gst-field bottom-left">
              <div className="heading">Sender’s GST number :</div>{" "}
              {!gstState ? (
                <div className="item-gst">
                  {allAppData?.addressDetails?.senderGst
                    ? allAppData?.addressDetails?.senderGst
                    : "N/A"}
                </div>
              ) : (
                <Form.Item
                  name="senderGstNumber"
                  onKeyPress={(event) => {
                    if (checkSpecialCharacter(event) || checkSpace(event)) {
                      event.preventDefault();
                    }
                  }}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (
                          value.length === 15 &&
                          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
                            value
                          ) === false
                        ) {
                          return Promise.reject({
                            max: 15,
                            min: 15,
                            message: "Please enter a valid GST Number",
                          });
                        } else if (activeListData.data.includes(value)) {
                          return Promise.reject({
                            message:
                              "GST belongs to a CP. Please enter another GST number.",
                          });
                        } else if (value.length > 0 && value.length < 15) {
                          return Promise.reject({
                            message: "Please enter a valid GST Number",
                          });
                        }
                        return Promise.resolve({});
                      },
                      validateTrigger: "onSubmit",
                    }),
                  ]}
                >
                  <Input
                    maxLength={15}
                    defaultValue={
                      allAppData?.data?.addressDetails?.senderGst
                        ? allAppData?.data?.addressDetails?.senderGst
                        : ""
                    }
                    onChange={inputHandler}
                    onInput={(e) =>
                      (e.target.value = e.target.value.toUpperCase())
                    }
                  />
                </Form.Item>
              )}
              <Tooltip title="Edit">
                <button
                  onClick={gstEditHandler}
                  className="address-edit"
                  style={
                    bookingType === "Account"
                      ? { visibility: "hidden" }
                      : { visibility: "inherit" }
                  }
                >
                  <img src={editButton} />
                </button>
              </Tooltip>
            </td>
          </tr>
          {/* //right */}
          <tr>
            <td className="table-data top-right">
              <Row>
                <Col span={12} className="heading">
                  Description :
                </Col>
                <Col span={12}>
                  {" "}
                  <b>{allAppData?.shipmentDetails?.description || ""}</b>{" "}
                </Col>
              </Row>
            </td>
            <td className="table-data">
              <Row>
                <Col span={12} className="heading">
                  Value:
                </Col>
                <Col span={12}>
                  {" "}
                  <b>{allAppData?.shipmentDetails?.value || " "}</b>{" "}
                </Col>
              </Row>
            </td>
            <td className="table-data">
              <Row>
                <Col span={12} className="heading">
                  Weight (Kg) :
                </Col>
                <Col span={12}>
                  {" "}
                  <b>{allAppData?.shipmentDetails?.weight || " "}</b>{" "}
                </Col>
              </Row>
            </td>
            <td className="table-data eway-field">
              <Row>
                <Col span={12} className="heading">
                  E-Way Bill No :
                </Col>
                <Col span={12}>
                  {!eWayState ? (
                    <b style={{ marginLeft: "10px" }}>
                      {allAppData?.shipmentDetails?.ewayBillNumber || " "}
                    </b>
                  ) : (
                    <Form.Item
                      name="ewayBill"
                      onKeyPress={(event) => {
                        if (
                          checkNumbervalue(event) ||
                          checkSpecialCharacter(event) ||
                          checkSpace(event)
                        ) {
                          event.preventDefault();
                        }
                      }}
                      rules={[
                        () => ({
                          async validator(_, value) {
                            if (value.length === 12) {
                              try {
                                setLoading(true);
                                let response = await API.Api(
                                  EWAYBILL + "/" + value
                                ).getWithPremiseIdHeader();
                                let data = JSON.parse(
                                  response?.response?.data?.data
                                );
                                const validilityDate = moment(
                                  data?.validUpto,
                                  "DD/MM/YYYY"
                                );
                                const todayDateNTime = moment(
                                  todayDateNTimeFormat,
                                  "DD/MM/YYYY"
                                );
                                if (response?.response?.data?.status === "1") {
                                  setLoading(false);
                                  if (todayDateNTime.isAfter(validilityDate)) {
                                    return Promise.reject({
                                      message: "E-way bill expired",
                                    });
                                  } else if (
                                    data?.transporterId !== transporterId
                                  ) {
                                    return Promise.reject({
                                      message: "Transporter id is not matching",
                                    });
                                  }
                                } else {
                                  setLoading(false);
                                  return Promise.reject({
                                    message:
                                      "Please enter a valid E-Way bill no.",
                                  });
                                }
                              } catch (error) {
                                setLoading(false);
                                return Promise.reject({
                                  message: "Network error",
                                });
                              }
                            } else if (value.length > 0 && value.length < 12) {
                              setLoading(false);
                              return Promise.reject({
                                message: "Please enter a valid E-Way bill no.",
                              });
                            }
                            return Promise.resolve({});
                          },
                          validateTrigger: "onSubmit",
                        }),
                      ]}
                    >
                      <Input
                        maxLength={12}
                        defaultValue={
                          allAppData?.shipmentDetails?.ewayBillNumber || ""
                        }
                        onChange={inputHandler}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Tooltip title="Edit">
                <button
                  onClick={ewayEditHandler}
                  className="address-edit"
                  // style={
                  //   bookingType === "Account"
                  //     ? { visibility: "hidden" }
                  //     : { visibility: "inherit" }
                  // }
                >
                  <img src={editButton} />
                </button>
              </Tooltip>
            </td>

            <td className="table-data">
              <Row>
                <Col span={12} className="heading">
                  Uploaded files :
                </Col>
                <Col span={12}>
                  {" "}
                  <b>
                    {allAppData?.files?.length > 0 ? (
                      <a onClick={() => setIsModalOpen(true)}>
                        {allAppData?.files?.length} Attachment(s)
                      </a>
                    ) : (
                      `${allAppData?.files?.length} Attachment(s)`
                    )}
                    {gstState ? (
                      <Input style={{ width: "20px", visibility: "hidden" }} />
                    ) : null}
                  </b>{" "}
                </Col>
              </Row>
            </td>
          </tr>
        </table>
      </Spin>
    </div>
  );
};

export default ServiceSummary;
