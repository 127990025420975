/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useEffect } from "react";
import "./style.scss";
import {
  Button,
  Drawer,
  Space,
  Table,
  message,
  Row,
  Col,
  Input,
  Tooltip,
  notification,
  Form,
} from "antd";
import {
  checkNumbervalue,
  checkSpecialCharacter,
  isValidDocumentNum,
} from "../../../../utils";
// import useEwayBill from "../../RetailBooking/AddressForm/ewayBillCompo";

import { ReactComponent as Edit_icon } from "../../../../assests/images/edit-logo.svg";
import { ReactComponent as DeleteIcon } from "../../../../assests/images/deleteIcon.svg";
import { focusFirstInputAndScrollToTop } from "../../../../components/CustomFunctions";
import urls from "../../../../api/urls";
import API from "../../../../api";

const ChildDrawer = ({
  childShipments,

  setOpen,
  open,
  setSummaryData = () => {},
  setChildShipments = () => {},
  childDrawerform,
}) => {
  // const [open, setOpen] = useState(true);
  const layoutSizes = { md: 6, lg: 6, xl: 6, sm: 6 };
  const [edit, setEdit] = useState(null);

  const [loading, setLoading] = useState(false);
  const { CHECK_CHILD_AWB_USED } = urls;

  const [childAwbError, setChildAwbError] = useState({});

  const columns = [
    {
      title: "Document number",
      dataIndex: "childDocumentNumber",
      key: "childDocumentNumber",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: "Length",
      dataIndex: "length",
      key: "length",
      render: (text) => text || "--",
    },
    {
      title: "Width",
      dataIndex: "width",
      key: "width",
      render: (text) => text || "--",
    },
    {
      title: "Height",
      dataIndex: "height",
      key: "height",
      render: (text) => text || "--",
    },

    {
      title: "Vol weight",
      dataIndex: "volumatricWeight",
      key: "volumatricWeight",
      render: (text) => text || "--",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "action",
      render: (text, item, idx) => (
        <div className="d-flex">
          <Tooltip title="Edit" placement="bottom">
            <Space onClick={() => handleEdit(idx)}>
              <Edit_icon />
            </Space>
          </Tooltip>
          <Tooltip title="Delete" placement="bottom">
            <Space onClick={() => handleDelete(idx)}>
              <DeleteIcon />
            </Space>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (open) {
      focusFirstInputAndScrollToTop("child-1");
    }
  }, [open]);

  const onClose = () => {
    setOpen(false);
  };

  const handleDelete = (params) => {
    setEdit(null);
    let temp = childShipments.filter((val, idx) => idx !== params);
    setChildShipments(temp);
  };

  const handleEdit = (params) => {
    setEdit(params);
    let temp = childShipments[params];
    childDrawerform.setFieldsValue(temp);
  };

  const volumCalculation = () => {
    let { length, width, height } = childDrawerform.getFieldValue();

    // Set length, width, and height to 0 if they are null or undefined
    // length = length ? +length : 0;
    // width = width ? +width : 0;
    // height = height ? +height : 0;
    if (length && width && height) {
      const volume = ((+length * +width * +height) / 5000).toFixed(2);
      childDrawerform.setFieldsValue({
        volumatricWeight: volume || 0,
      });
    } else {
      childDrawerform.setFieldsValue({
        volumatricWeight: "0.00",
      });
    }
  };

  const formProps = {
    name: "retail-address-form",
    className: "retail-address-form",
    autoComplete: "off",
  };

  const openNotification = (type, message) => {
    notification[type]({
      message: null,
      className: "custom-top-right-drawer-notification",
      duration: 2,
      description: message,
      placement: "topRight",
    });
  };

  const replaceUndefinedWithNull = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        newObj[key] = obj[key] === undefined || obj[key] === "" ? 0 : obj[key];
      }
    }
    return newObj;
  };

  const onSubmitFormData = (params) => {
    if (childAwbError?.status == "error") {
      return true;
    }

    if (childShipments?.length) {
      const isDuplicate = childShipments?.some(
        (item) => item?.childDocumentNumber === params?.childDocumentNumber
      );

      const currentIdx = childShipments?.findIndex(
        (item) => item?.childDocumentNumber === params?.childDocumentNumber
      );

      if (isDuplicate && currentIdx !== edit) {
        setChildAwbError({
          status: "error",
          msg: "Doc no. already used",
        });
        // childDrawerform.setFields([
        //   {
        //     name: "childDocumentNumber",
        //     errors: ["Doc no. already exists"],
        //   },
        // ]);
        return;
      }
    }

    if (edit === null) {
      setChildShipments((prevItems) => {
        const paramsWithNull = replaceUndefinedWithNull(params);
        const prevItemsArray = Array.isArray(prevItems) ? prevItems : [];
        const updatedItems = [...prevItemsArray, paramsWithNull];

        openNotification("success", "Child added successfully");
        focusFirstInputAndScrollToTop("child-1");
        setSummaryData({
          ["Estimation Days"]: 0,
          ["Chargeable Weight"]: 0,
          baseCharge: 0,
          fov: 0,
          igst: 0,
          sgst: 0,
          cgst: 0,
          cess: 0,
          charges: 0,
          freight: 0,
        });
        return updatedItems;
      });
    } else {
      setChildShipments((prevItems) => {
        const temp = [...prevItems];
        // const removeNull = replaceUndefinedWithNull(temp);
        temp[edit] = replaceUndefinedWithNull(params);
        return temp;
      });
    }

    let childDocumentNumber = +params?.childDocumentNumber;
    childDrawerform.resetFields();
    handleAPI(childDocumentNumber);

    childDrawerform.setFieldsValue({
      childDocumentNumber: childDocumentNumber + 1,
    });

    setEdit(null);
  };

  async function handleAPI(value) {
    setLoading(true);

    if (value?.toString()?.length > 10) {
      const response = await API.Api(
        CHECK_CHILD_AWB_USED + +value
      ).getWithUserNPremiseHeader();
      if (response?.status) {
        setLoading(false);

        if (response.response.data == false) {
          setChildAwbError({
            status: "error",
            msg: "Doc no. already used",
          });
        } else {
          setLoading(false);
          setChildAwbError({
            status: "success",
            msg: null,
          });
        }
      }
    } else {
      setLoading(false);
    }
  }

  return (
    <>
      <Drawer
        title={"Add/ View child shipment"}
        placement="right"
        // size={size}
        width={650}
        onClose={onClose}
        open={open}
        className="credit-child-drawer"
      >
        <div className="child-drawer-container">
          <Fragment>
            <Form
              form={childDrawerform}
              {...formProps}
              onFinish={onSubmitFormData}
              layout="vertical"
              //   onFieldsChange={onFeildsChange} // layout={formLayout}
              scrollToFirstError={{
                behavior: "smooth",
                block: "center",
                inline: "center",
              }}
              className="global-form"
            >
              <Row className="global-form-row">
                <Row align={"center"}>
                  <Col {...layoutSizes}>
                    <Form.Item
                      label="Child doc number"
                      validateStatus={childAwbError?.status}
                      help={childAwbError?.msg}
                      className=""
                      name="childDocumentNumber"
                      type="input"
                      onKeyPress={(event) => {
                        if (
                          checkNumbervalue(event) ||
                          checkSpecialCharacter(event)
                        ) {
                          event.preventDefault();
                        }
                      }}
                      rules={[
                        {
                          required: true,
                          message: null,
                        },
                        () => ({
                          validator: (_, value) => {
                            if (!value) {
                              setChildAwbError({
                                status: "error",
                                msg: "Enter doc no.",
                              });
                              // return Promise.reject(
                              //   new Error("Enter valid doc no.")
                              // );
                            }
                            if (value?.length > 0 && value?.length < 10) {
                              setChildAwbError({
                                status: "error",
                                msg: "Doc no. should be 11 digits",
                              });
                              // return Promise.reject(
                              //   new Error("Doc no. should be 11 digits")
                              // );
                            }
                            if (
                              value.length > 10 &&
                              !isValidDocumentNum(value, "child")
                            ) {
                              setChildAwbError({
                                status: "error",
                                msg: "Enter valid doc no.",
                              });
                            }
                            return Promise.resolve();
                          },
                          validateTrigger: "onSubmit",
                        }),
                      ]}
                    >
                      <Input
                        placeholder="Enter doc number"
                        id="child-1"
                        maxLength={11}
                        onChange={(e) => handleAPI(e?.target?.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...layoutSizes}>
                    <Form.Item
                      label="Weight(Kg)"
                      name="weight"
                      rules={[
                        {
                          required: true,
                          maxLength: 3,
                          message: "Enter weight",
                        },
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.reject();
                            }

                            if (
                              parseInt(value?.toString()?.substring(1, 0)) === 0
                            ) {
                              return Promise.reject(
                                "Enter weight of at least 1 kg"
                              );
                            }

                            if (value && value?.split(".")?.length > 2) {
                              return Promise.reject(
                                "Enter only one decimal value"
                              );
                            }

                            if (value > 499.99) {
                              return Promise.reject(
                                "Weight should be less than 499kg"
                              );
                            }
                            return Promise.resolve();
                          },
                          //validateTrigger: "onSubmit",
                        }),
                      ]}
                      onKeyPress={(event) => {
                        if (checkNumbervalue(event)) {
                          event.preventDefault();
                        }
                      }}
                    >
                      <Input
                        placeholder="Enter weight"
                        maxLength={6}
                        onChange={volumCalculation}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...layoutSizes}>
                    <Form.Item
                      label={
                        <span className="calculater-container-pikpin">
                          {" "}
                          Length(Cm)
                        </span>
                      }
                      name="length"
                      className=""
                      rules={[
                        {
                          required: false,
                          message: "Enter length",
                        },
                      ]}
                      onKeyPress={(event) => {
                        if (checkNumbervalue(event) || event.key === ".") {
                          event.preventDefault();
                        }
                      }}
                    >
                      <Input
                        placeholder="Enter length"
                        maxLength={3}
                        onChange={volumCalculation}
                      />
                    </Form.Item>
                  </Col>
                  <Col {...layoutSizes}>
                    <Form.Item
                      label="Width(Cm)"
                      className=""
                      name="width"
                      rules={[
                        {
                          required: false,
                          message: "Enter width",
                        },
                      ]}
                      onKeyPress={(event) => {
                        if (checkNumbervalue(event) || event.key === ".") {
                          event.preventDefault();
                        }
                      }}
                    >
                      <Input
                        placeholder="Enter width"
                        maxLength={3}
                        onChange={volumCalculation}
                        min={"0"}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{ alignItems: "center" }}>
                  <Col {...layoutSizes}>
                    <Form.Item
                      className=""
                      label={
                        <span className="calculater-container-pikpin">
                          Height (Cm)
                        </span>
                      }
                      name="height"
                      rules={[
                        {
                          required: false,
                          message: "Enter height",
                        },
                      ]}
                      onKeyPress={(event) => {
                        if (checkNumbervalue(event) || event.key === ".") {
                          event.preventDefault();
                        }
                      }}
                    >
                      <Input
                        className=""
                        placeholder="Enter height"
                        maxLength={3}
                        onChange={volumCalculation}
                      />
                    </Form.Item>
                  </Col>

                  <Col {...layoutSizes}>
                    <Form.Item
                      label="Vol weight (Kg)"
                      name="volumatricWeight"
                      className=""
                    >
                      <Input
                        className=""
                        placeholder="Enter Volumetric"
                        readOnly={true}
                        disabled
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                  <Col {...layoutSizes}>
                    <Form.Item
                      name="value"
                      className=""
                      label={
                        <span className="calculater-container-pikpin">
                          Value (INR)
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Enter value",
                        },
                        {
                          pattern: /^[0-9].*/g,
                          message: "Enter value",
                        },

                        // () => ({
                        //   validator(rule, value = "") {
                        //     if (parseInt(value.substring(1, 0)) === 0) {
                        //       return Promise.reject("Value should not be 0");
                        //     } else {
                        //       return Promise.resolve();
                        //     }
                        //   },
                        // }),
                      ]}
                      onKeyPress={(event) => {
                        if (checkNumbervalue(event) || event.key === ".") {
                          event.preventDefault();
                        }
                      }}
                    >
                      <Input
                        placeholder="Enter value"
                        maxLength={7}
                        // onChange={(e) =>
                        //   +e.target.value > 50000
                        //     ? ewayBillValueCheck(true)
                        //     : ewayBillValueCheck(false)
                        // }
                      />
                    </Form.Item>
                  </Col>

                  <Col md={3} sm={3}>
                    <Button
                      className="child-cancel-btn ml-1"
                      onClick={() => childDrawerform.resetFields()}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col md={3} sm={3}>
                    <Button className="child-save-btn ml-1" htmlType="submit">
                      + Add
                    </Button>
                  </Col>
                  <Col lg={24} style={{ paddingLeft: "10px" }}>
                    <b style={{ fontSize: "13px" }}>
                      Note: Please provide accurate Length, Width and Height
                      values to avoid reconciliation charges.
                    </b>
                  </Col>
                </Row>
              </Row>
            </Form>
          </Fragment>
          <Table
            className="mt-1"
            dataSource={childShipments}
            columns={columns}
            pagination={false}
            size="small"
          />
        </div>
      </Drawer>
    </>
  );
};

export default ChildDrawer;
