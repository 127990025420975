import React, {useEffect, useState} from "react";
import { Table, Button, Spin, Input } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { capitaliseWithHyphen } from "../../../../utils";
import urls from "../../../../api/urls";
import { getToHubTat, clearGetToHubTat } from "../../../../actionsMethods/masterMethods";
import { RightOutlined , DownOutlined} from "@ant-design/icons";
import search_icon from "../../../../assests/images/search-icon.svg";
import "./index.scss";
const SurfaceTAT = ({tatData, type, closeExpandRow}) => {
  const { GET_TAT_DOWNLOAD_BUTTON } = urls;
  const dispatch = useDispatch();
  const [toTATData, setToTATData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizeInner, setPageSizeInner] = useState(5);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [inputToSearchValue, setInputToSearchValue] = useState("");
  const [dataSourceTo, setDataSourceTo] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pageNumInner, setPageNumInner] = useState(1);
  const toHubData = useSelector(
    (state) => state.masterReducer.getToHubTatReducer,
    shallowEqual
  );

  const { result: toHubResult, fetching: loading } = toHubData; 
  useEffect(()=>{ 
    if(toHubResult){
      let temp = [];
      toHubResult?.data?.map((e, index)=>{
        temp.push({...e, key: index+1});
      });
      setToTATData(temp);
      setDataSourceTo(temp);
    }
    else {
      setToTATData([]);
      setDataSourceTo([]);
    }
  }, [toHubResult]);
    
  useEffect(()=>{
    setExpandedRowKeys([]);
    setInputToSearchValue("");
    setInputSearchValue("");
  }, [closeExpandRow]);
  const expandedRowRender = () => {
    const columns = [
      {
        title: "Sl No",
        dataIndex: "key",
        key: "key",
        align: "center",
        with: 10,
      },
      { title: "To Hub", dataIndex: "toHub", key: "toHub", render: (a) => capitaliseWithHyphen(a)},
      { title: "To City", dataIndex: "toCity", key: "toCity", render: (a) => capitaliseWithHyphen(a)},
      { title: "To State", dataIndex: "toState", key: "toState",render: (a) => capitaliseWithHyphen(a),
        sorter: (a, b) => a.toState.localeCompare(b.toState)},
      { title: "To Zone", dataIndex: "toZone", key: "toZone" },
      { title: "TAT", dataIndex: type === "surface"?"valueSurface":"valueAir", key: type === "surface"?"valueSurface":"valueAir" }
    ];
    const itemRender = (_, type, originalElement) => {
      if (type === "prev") {
        return <a>Previous</a>;
      }
      if (type === "next") {
        return <a>Next</a>;
      }
      return (
        <div>
          <p>{originalElement} </p>
        </div>
      );
    };
 
    function handlePageChangeInner(pagination) {
      setPageNumInner(pagination.current);
      setPageSizeInner(pagination.pageSize);
    }
  
    const onSearchInner = (e) => {
      if(e.target.value.length<1){
        setPageNumInner(1);
      }
      const currValue = e.target.value;
      setInputToSearchValue(currValue);
  
      const filteredData =
      inputToSearchValue.length > 0 || inputToSearchValue.length == 0
        ? filterArray(toTATData, currValue)
        : filterArray(toTATData, currValue);
      let temp = [];
      filteredData.map((item, index)=>temp.push({...item, key:index+1}));
      setDataSourceTo(temp);
    };
  
    const filterArray = (dataSource, currValue) =>
      dataSource?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(currValue.toLowerCase())
        )
      );
    return(
      <Spin spinning={loading}>
        <Input
          placeholder="Search..."
          bordered
          onChange={onSearchInner}
          style={{ borderRadius: "5px", height: "40px", width: "400px" }}
          suffix={<img src={search_icon} />}
          className="ml-1-5 mb-1"
          value={inputToSearchValue}
        />
        <Table columns={columns} 
          dataSource={inputToSearchValue.length == 0 ?  toTATData: dataSourceTo}
          onChange={handlePageChangeInner}
          pagination={{
            pageSize: pageSizeInner,
            //total: 10,
            itemRender: itemRender,
            pageSizeOptions: [5, 10, 20, 50, 100],
            current: pageNumInner
          }} className="tat-table table-head-sticky" />
      </Spin>) ;
  };

    
  const columns = [
    {
      title: "Sl No",
      dataIndex: "key",
      key: "key",
      align: "center",
      with: 10
    },
    {
      title: "From Hub",
      dataIndex: "fromHub",
      key: "fromHub",
      //sorter: (a, b) => a.fromHub.localeCompare(b.fromHub),
      with: 15,
      render: (a) => capitaliseWithHyphen(a),
    },
    {
      title: "From City",
      dataIndex: "fromCity",
      key: "fromCity",
      //sorter: (a, b) => a.fromCity.localeCompare(b.fromCity),
      with: 15,
      render: (a) => capitaliseWithHyphen(a),
    },
    {
      title: "From State",
      dataIndex: "fromState",
      key: "fromState",
      sorter: (a, b) => a.fromState.localeCompare(b.fromState),
      with: 15,
      render: (a) => capitaliseWithHyphen(a),
    },
    {
      title: "From Zone",
      dataIndex: "fromZone",
      key: "fromZone",
      //sorter: (a, b) => a.fromZone.localeCompare(b.fromZone),
      with: 15
    },
  ];

  //const dataSource = tatData;
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const callExpand = (e, record) => {
    dispatch(clearGetToHubTat());
    if(e){
      setExpandedRowKeys([record.key]);
      dispatch(getToHubTat(type, record.fromPremiseId));
    }
    else{
      setExpandedRowKeys([]);
      setToTATData([]);
    }
    
  };

  function handlePageChange(pagination) {
    setPageNum(pagination.current);
    setPageSize(pagination.pageSize);
  }

  const onSearch = (e) => {
    if(e.target.value.length <1) {
      setPageNum(1);
    }
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(tatData, currValue)
        : filterArray(tatData, currValue);
    let temp = [];
    filteredData.map((item, index)=>temp.push({...item, key:index+1}));
    setDataSource(temp);
    setExpandedRowKeys([]);
    setInputToSearchValue("");
  };

  const filterArray = (dataSource, currValue) =>
    dataSource?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );
  const downloadButtonHandle = async()=>{
    if(type === "surface"){
      window.open(`${GET_TAT_DOWNLOAD_BUTTON}SURFACE`);
    }else{
      window.open(`${GET_TAT_DOWNLOAD_BUTTON}AIR`);
    }
  };

  return (
    <div className="template-container">
      <div className="d-flex space-between align-center p-2">
        <Input
          placeholder="Search..."
          bordered
          onChange={onSearch}
          style={{ borderRadius: "5px", height: "40px", width: "400px" }}
          suffix={<img src={search_icon} />}
          className="ml-1-5"
          value={inputSearchValue}
        />
        <Button className="save-btn w-10 mr-1-5" onClick={downloadButtonHandle}>DOWNLOAD</Button>
      </div>
      <Table
        columns={columns}
        dataSource={inputSearchValue.length == 0 ? tatData : dataSource}
        pagination={{
          pageSize: pageSize,
          itemRender: itemRender,
          pageSizeOptions: [5, 10, 20, 50, 100],
          current: pageNum,
        }}
        
        onChange={handlePageChange}
        expandable={{ expandedRowRender, 
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <DownOutlined onClick={e => onExpand(record, e)} />
            ) : (
              <RightOutlined onClick={e => onExpand(record, e)} />
            ),
          expandRowByClick: true
        }}
        onExpand={callExpand}
        expandedRowKeys={expandedRowKeys}
        className="tat-table1 table-head-sticky"
      />
    </div>
  );
};
export default SurfaceTAT;
