import React, { useState } from "react";
import { Button, message, Form, Input, Row, Col, Spin } from "antd";

import { forgotPassword } from "../Auth/auth";

import lockIcon from "../../assests/images/forgotLockIcon.svg";
import "./style.scss";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    delete values.remember;
    try {
      await forgotPassword(values, () => {
        message.error("Email cannot be empty");
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <Row>
          <div className="formIcon">
            <img src={lockIcon} />
          </div>
        </Row>
        <div className="inner-content">
          <Row className="first-div">
            <div className="wlc-div">{"Forgot Password?"}</div>
            <Col span={24}>
              <div className="msg">
                Enter your email address to reset your password.
              </div>
            </Col>
            <div className="error"></div>
          </Row>
          <Form
            onFinish={onFinish}
            autoComplete="off"
            className="form"
            // validateTrigger="onSubmit"
          >
            <div className="email-div">
              <p>Email</p>
            </div>
            <div className="emailInput-div">
              <Form.Item
                name="username"
                rules={[
                  {
                    message: null,
                    required: false,
                  },
                  {
                    type: "email",
                    message: "The input is not valid E-mail",
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Input
                  className="input-style"
                  placeholder="Enter your email address"
                  bordered={false}
                />
              </Form.Item>
            </div>

            <Form.Item>
              <div className="login-btn">
                <Button
                  className="loginBtn"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                >
                  SEND
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default ForgotPassword;
