/* eslint-disable no-unused-vars */
import { React, useState, useEffect } from "react";
import { Spin } from "antd";
import Geocode from "react-geocode";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import style from "./places.module.css";
import aim_icon from "../../assests/images/aim.png";
const Places_ = ({ setDetailsHandler }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const [selected, setSelected] = useState(null);
  const [isSpinning, setIsSpinning] = useState(false);

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCsvXDft5BRwE1TiqtbFHyX2xoKWE3EC1c",
    libraries: ["places"],
  });

  useEffect(() => {}, [isLoaded]);
  if (!isLoaded) return <div>Loading...</div>;

  const center = {
    lat: selected?.lat || 20.94092,
    lng: selected?.lng || 84.803467,
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
    localStorage.setItem("latLng", JSON.stringify({ lat, lng }));
    if (ready) {
      const { place_id } = data[0];
      const place = await getGeocode({ placeId: place_id });
      setDetailsHandler(lat, lng);
    }
  };

  const getCurrentLoc = () => {
    setIsSpinning(true);
    navigator.geolocation.getCurrentPosition((pos) => {
      setDetailsHandler(pos.coords.latitude, pos.coords.longitude);
      setSelected({ lat: pos.coords.latitude, lng: pos.coords.longitude });
      setIsSpinning(false);
    });
  };

  const onMapClick = async (id) => {
    try {
      const { placeId } = id;
      const place = await getGeocode({ placeId });
      const address = await place[0].formatted_address;
      setDetailsHandler(place[0]);

      const result = await getGeocode({ address });
      const { lat, lng } = await getLatLng(result[0]);
      setSelected({ lat, lng });
    } catch (err) {
      console.log(err);
    }
  };

  const onDragEndFunction = async (e) => {
    try {
      const { latLng } = e;
      const lat = latLng.lat();
      const lng = latLng.lng();
      const latLngProps = {
        lat,
        lng,
      };
      setSelected({ lat, lng });
      setDetailsHandler(lat, lng);
      const check = await Geocode.fromLatLng(lat.toString(), lng.toString());
      const data = await check?.results;
      const place = await getGeocode({ placeId: data[0].place_id });
      setDetailsHandler(place[0]);
    } catch (err) {
      console.log("err");
    }
  };
  return (
    <>
      {isLoaded ? (
        <div
          style={{ border: "10px solid #F1F1F1", backgroundColor: "#F1F1F1" }}
        >
          <div className="places-container">
            <Combobox onSelect={handleSelect}>
              <ComboboxInput
                style={{
                  // border: '1px solid red',
                  // position: 'absolute',
                  zIndex: "900000",
                  width: "100%",
                  // margin: '70px 20px',
                  padding: "5px 10px",
                  // borderRadius: '5px',
                  outline: "none",
                  background: "#fff",
                  // padding: '5px',
                  border: "2px solid rgba(0, 0, 0, 0.06)",
                }}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready}
                placeholder="Search an address"
              />
              <ComboboxPopover>
                <ComboboxList>
                  {status === "OK" &&
                    data.map(({ place_id, description }) => (
                      <ComboboxOption key={place_id} value={description} />
                    ))}
                </ComboboxList>
              </ComboboxPopover>
            </Combobox>
          </div>
          <Spin spinning={isSpinning}>
            <GoogleMap
              zoom={selected === null ? 4 : 20}
              center={center}
              mapContainerClassName={style.map_container}
              onClick={onMapClick}
            >
              <MarkerF
                draggable={true}
                onDragEnd={(e) => onDragEndFunction(e)}
                position={selected}
              />
            </GoogleMap>
          </Spin>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Places_;
