/* eslint-disable no-unused-vars */
import Cookies from "universal-cookie";
import axios from "axios";

import { checkForSetOfStrings } from "../utils";
import { notToCheckUrls } from "../config/exclude-urls";
import envConfig from "../config/env-urls";
import nodeEnvConfig from "../config/node-env-urls";

const cookies = new Cookies();

// eslint-disable-next-line no-undef
const REACT_APP_ENV = process.env.REACT_APP_ENV || "dev";

export const APIs = envConfig[REACT_APP_ENV].API;

export const NodeAPIs = nodeEnvConfig[REACT_APP_ENV]?.API;

export const authDetails = envConfig[REACT_APP_ENV].auth;

export const queryString = (data) => {
  const keys = Object.keys(data);
  let qs = "";

  // eslint-disable-next-line array-callback-return
  keys.map((key) => {
    qs += `&${key}=${data[key]}`;
  });

  return qs;
};

export const getToken = () => {
  let tokendata = JSON.parse(localStorage.getItem("userinfo"));
  let data = `Bearer ${tokendata?.accessToken}`;
  return data;
};
export const getPremiseId = () => {
  let data_ = JSON.parse(localStorage.getItem("userId"));
  let data = `Bearer ${data_?.userInfo?.premiseId}`;
  return data;
};

axios.interceptors.request.use(
  (config) => {
    const originalRequest = config;

    if (!checkForSetOfStrings(config.url, notToCheckUrls)) {
      if (originalRequest.headers.Authorization) {
        return config;
      } else {
        let token = getToken();
        const interval = setInterval(() => {
          if (token) {
            clearInterval(interval);
          } else {
            token = getToken();
          }
        }, 1);

        // if (token) {
        //   originalRequest.headers.Authorization = `Bearer ${token}`;
        //   return Promise.resolve(originalRequest);
        // }
      }
      return Promise.resolve(originalRequest);
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
