/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import axios from "axios";
import API from "../../api";
import urls from "../../api/urls";
import { capitaliseWithHyphen } from "../../utils";
import Heading from "./Heading";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Radio,
  Spin,
  message,
  Select,
  Space,
} from "antd";
import { ReactComponent as Reason } from "../../assests/images/SVG/check.svg";
import BottomButton from "./BottomButton";
import OTPModal from "./OTPModal";

const BranchAdmin = ({ goBackStep, handleStepsChange }) => {
  const { VERIFY_GST } = urls;
  const [form] = Form.useForm();
  const [radioValue, setRadioValue] = useState("Yes");
  const [checkStatus, setCheckStatus] = useState(false);
  const [partnerRadio, setPartnerRadio] = useState("Yes");
  const [loading, setLoading] = useState(false);
  const [buttonStatus, setButtonStatus] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onFinish = (e) => {
    handleStepsChange();
  };
  const radioChange = (e) => {
    setRadioValue(e.target.value);
  };
  const checkGST = async (event, name) => {
    if (event.target.value.length === 15) {
      setLoading(true);
      let response = await API.Api(
        VERIFY_GST + event.target.value.toUpperCase()
      ).get();
      if (response?.status) {
        let data = response?.response?.data;
        setCheckStatus(true);
        setLoading(false);
        form.setFieldsValue({
          nameOfCompany: capitaliseWithHyphen(
            data?.result?.gstnDetailed?.tradeNameOfBusiness
          ),
        });
      } else {
        setLoading(false);
        setCheckStatus(false);
        form.setFields([
          {
            name: name,
            errors: ["Please give correct GSTIN number"],
          },
        ]);
        form.setFieldsValue({
          nameOfCompany: "",
        });
        message.error("No result found");
      }
    } else {
      setCheckStatus(false);
    }
  };
  const pinCodeHandler = async (e, name) => {
    if (e.target.value.length > 5) {
      try {
        setLoading(true);
        let response = await axios.get(
          `https://api.postalpincode.in/pincode/${e.target.value}`
        );
        let res = response.data;
        let data = res[0]?.PostOffice[0];
        if (res[0]?.Status === "Success") {
          form.setFieldsValue({
            city: capitaliseWithHyphen(data?.District),
            state: capitaliseWithHyphen(data?.State),
            country: capitaliseWithHyphen(data?.Country),
          });
          setLoading(false);
        } else {
          setLoading(false);
          form.setFields([
            {
              name: name,
              errors: [res[0]?.Message],
            },
          ]);
        }
      } catch (error) {
        setLoading(false);
        form.setFields([
          {
            name: name,
            errors: ["Please give correct pincode"],
          },
        ]);
      }
    } else if (e.target.value.length < 6) {
      form.setFieldsValue({
        city: "",
        state: "",
      });
    }
  };
  const partnerRadioHandle = (e) => {
    setPartnerRadio(e.target.value);
  };
  let verifyBtn = "";
  const emailOnchange = (e) => {
    verifyBtn = e.target.value;
  };
  const emailHandle = (e) => {
    setButtonStatus([...buttonStatus, e]);
  };
  const otpHandle = () => {
    setIsModalOpen(true);
  };
  return (
    <div className="p-2">
      <Heading title={"Branch Admin"} />
      <OTPModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <Spin spinning={loading}>
        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={24}>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item label="Name" name="name">
                <Input className="input-field-style" />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Email ID"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                ]}>
                <Input
                  className="input-field-style"
                  onChange={emailOnchange}
                  suffix={
                    buttonStatus.includes("email") ? (
                      <Reason />
                    ) : (
                      <Button
                        className="save-btn"
                        onClick={() => emailHandle("email")}>
                        VERIFY
                      </Button>
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                ]}>
                <Input
                  className="input-field-style"
                  onChange={emailOnchange}
                  suffix={
                    buttonStatus.includes("phone") ? (
                      <Reason />
                    ) : (
                      <Button
                        className="save-btn"
                        onClick={() => emailHandle("phone")}>
                        VERIFY
                      </Button>
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Use Existing GST"
                name="useExistingGST"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                ]}>
                <Radio.Group
                  onChange={radioChange}
                  defaultValue={radioValue}
                  value={radioValue}>
                  <Radio value={"Yes"}>Yes</Radio>
                  <Radio value={"No"}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {radioValue === "No" ? (
              <>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item
                    label="GSTIN"
                    name="gstNumber"
                    rules={[
                      {
                        message: "Please enter GSTIN",
                        required: true,
                      },
                      {
                        pattern:
                          /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/g,
                        message: "Invalid GSTIN",
                      },
                    ]}>
                    <Input
                      className="input-field-style"
                      placeholder="Enter GSTIN"
                      maxLength={15}
                      onChange={(e) => checkGST(e, "gstNumber")}
                      suffix={checkStatus ? <Reason /> : null}
                      onKeyPress={(e) => {
                        if (/[^A-Za-z0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onInput={(e) =>
                        (e.target.value = e.target.value.toUpperCase())
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item
                    label="Business Pan Number"
                    name="panNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter business pan number",
                      },
                    ]}>
                    <Input
                      className="input-field-style"
                      placeholder="Enter business pan number"
                    />
                  </Form.Item>
                </Col>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item label="Name of company" name="nameOfCompany">
                    <Input
                      className="input-field-style"
                      placeholder="Enter name of company"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item
                    label="Type of Business"
                    name="typeOfBusiness"
                    rules={[
                      {
                        required: true,
                        message: "Please select type of business",
                      },
                    ]}>
                    <Select
                      className="input-field-style select-field"
                      placeholder="Please select an option"
                      options={[
                        {
                          value: "Individual",
                          label: "Individual",
                        },
                        {
                          value: "Proprietorship",
                          label: "Proprietorship",
                        },
                        {
                          value: "Private Limited",
                          label: "Private Limited",
                        },
                        {
                          value: "Public Limited",
                          label: "Public Limited",
                        },
                        {
                          value: "LLP",
                          label: "LLP",
                        },
                        {
                          value: "Partnership",
                          label: "Partnership",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item
                    label="Address line 1"
                    name="addressLine1"
                    rules={[
                      {
                        required: true,
                        message: "Please enter address line 1",
                      },
                    ]}>
                    <Input
                      className="input-field-style"
                      placeholder="Enter address line 1"
                    />
                  </Form.Item>
                </Col>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item
                    label="Address line 2"
                    name="addressLine2"
                    rules={[
                      {
                        required: false,
                        message: "Please enter address line 2",
                      },
                    ]}>
                    <Input
                      className="input-field-style"
                      placeholder="Enter address line 2"
                    />
                  </Form.Item>
                </Col>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item
                    label="Pincode"
                    name="pincode"
                    rules={[
                      {
                        required: true,
                        message: "Please enter pincode",
                      },
                      () => ({
                        validator(rule, value = "") {
                          if (value.length > 0 && value.length < 6) {
                            return Promise.reject("Pincode should be 6 digits");
                          } else {
                            return Promise.resolve();
                          }
                        },
                        // validateTrigger: "onSubmit",
                      }),
                    ]}>
                    <Input
                      className="input-field-style"
                      placeholder="Enter pincode"
                      maxLength={6}
                      onChange={(e) => pinCodeHandler(e, "pincode")}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item label="State" name="state">
                    <Input
                      className="input-field-style"
                      placeholder="Enter state"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item label="City" name="city">
                    <Input
                      className="input-field-style"
                      placeholder="Enter city"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item label="Country" name="country">
                    <Input
                      className="input-field-style"
                      placeholder="Enter country"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </>
            ) : null}
          </Row>
          {radioValue === "No" ? (
            <>
              <Row gutter={12} className="mt-2 mb-2">
                <Col>
                  <Form.Item
                    label="Name of proprietor / partner"
                    name="nameOfProprietor"
                    rules={[
                      {
                        required: true,
                        message: "Please select",
                      },
                    ]}>
                    <Radio.Group
                      onChange={partnerRadioHandle}
                      value={partnerRadio}>
                      <Space direction="vertical">
                        <Radio value={1}>Option A</Radio>
                        <Radio value={2}>Option B</Radio>
                        <Radio value={3}>Option C</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Heading title={"KYC Details (Aadhar Details)"} />
              <h4>
                <b>Please enter Kiran Kumar’s Aadhar and PAN Number</b>
              </h4>
              <Row gutter={12}>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item
                    label="Aadhar Number"
                    name="aadharNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter adhar number",
                      },
                    ]}>
                    <Input
                      className="input-field-style"
                      placeholder="Enter adhar number"
                    />
                  </Form.Item>
                </Col>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item
                    label=" "
                    name="otpButton"
                    rules={[
                      {
                        required: false,
                        message: "Please input your username!",
                      },
                    ]}>
                    <Button className="save-btn" onClick={otpHandle}>
                      SEND OTP
                    </Button>
                  </Form.Item>
                </Col>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item
                    label="OTP"
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: "Please enter OTP",
                      },
                    ]}>
                    <Input
                      className="input-field-style"
                      placeholder="Enter OTP"
                    />
                  </Form.Item>
                </Col>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item
                    label="Signatory PAN Number"
                    name="signatoryPanNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter signatory PAN number",
                      },
                    ]}>
                    <Input
                      className="input-field-style"
                      placeholder="Enter signatory PAN number"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : null}
          <hr
            className="divider"
            style={{ marginTop: "20px", marginBottom: "30px" }}
          />
          <BottomButton goBackStep={goBackStep} />
        </Form>
      </Spin>
    </div>
  );
};

export default BranchAdmin;
