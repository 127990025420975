/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row } from "antd";
import MasterCard from "../../../components/Master/MasterCard/MasterCard";
import create_trip from "../../../assests/images/middleMile/create-trip.svg";
// import update_trip from "../../../assests/images/middleMile/update-trip-icon.svg";
import constants from "../../../constants/constants";
import back_img from "../../../assests/images/rightVector.svg";

const { ROLES } = constants;
const { HUB_ADMIN } = ROLES;

const stationaryTypes = [
  {
    id: 1,
    imgSrc: create_trip,
    title: "Create & View Trip",
    route: "/lcr-generation",
    disable: false,
    childPresent: false,
    role: [HUB_ADMIN],
  },
  // {
  //   id: 2,
  //   imgSrc: update_trip,
  //   title: "Patch & Update Trip",
  //   route: "/update-trip-list",
  //   disable: false,
  //   childPresent: false,
  //   role: [HUB_ADMIN],
  // },
  // {
  //   id: 2,
  //   imgSrc: create_trip,
  //   title: "LCR new design",
  //   route: "/new-lcr-generation",
  //   disable: false,
  //   childPresent: false,
  //   role: [HUB_ADMIN],
  // },
];
function CreateOrUpdateTrip() {
  const [globalSettingList, setglobalSettingList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let role = JSON.parse(localStorage.getItem("userinfo"));
    let temp = [];
    stationaryTypes.map((e) => {
      e.role.some((item) => {
        if (role && role.roles.includes(item)) {
          temp.push(e);
        }
      });
    });
    setglobalSettingList(temp);
  }, []);

  const onClickHandler = (route, childPresent, id) => {
    if (childPresent) {
      navigate("/master-card-child", {
        state: id,
      });
    } else {
      if (route === "/client-onboarding") {
        localStorage.removeItem("clientId");
      }
      if (route === "/staff-onboarding") {
        localStorage.removeItem("staff");
      }
      navigate(route);
    }
  };
  return (
    <div className="p-2">
      <div className="align-center">
        <img
          src={back_img}
          onClick={() => navigate("/middle-mile")}
          style={{ cursor: "pointer" }}
        />
        <p
          style={{
            margin: "0",
            paddingLeft: "5px",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "32px",
            letterSpacing: "-0.25px",
            color: "#061624",
          }}
        >
          <b>LCR Generation</b>
        </p>
      </div>
      <Row gutter={30}>
        {globalSettingList.map((e) => {
          return (
            <MasterCard
              key={e.id}
              id={e.id}
              title={e.title}
              imgSrc={e.imgSrc}
              onClickHandler={onClickHandler}
              route={e.route}
              disable={e.disable}
              childPresent={e.childPresent}
            />
          );
        })}
      </Row>
    </div>
  );
}

export default CreateOrUpdateTrip;
