/* eslint-disable indent */
import React from "react";
import { useLocation } from "react-router-dom";

import ForgotPassword from "../../views/ForgotPassword";
import ForgotContainer from "./ForgotContainer";
import CheckMailInbox from "../../views/ForgotPassword/CheckMailInbox";
import NewPassword from "../../views/ForgotPassword/NewPassword";
import ConfirmPassword from "../../views/ForgotPassword/ConfirmPassword";

const ForgotMain = () => {
  let currentPath = useLocation();
  let path = currentPath.pathname;

  const componentsSwtich = (key) => {
    switch (key) {
      case "/forgotPassword":
        return (
          <div>
            <ForgotPassword />
          </div>
        );
      case "/confirmMail":
        return (
          <div>
            <CheckMailInbox />
          </div>
        );
      case "/newPassword":
        return (
          <div>
            <NewPassword />
          </div>
        );
      case "/confirmPassword":
        return (
          <div>
            <ConfirmPassword />
          </div>
        );
      default:
        return <p>Default</p>;
    }
  };

  return (
    <div>
      <ForgotContainer page={componentsSwtich(path)} />
    </div>
  );
};

export default ForgotMain;
