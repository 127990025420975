/* eslint-disable indent */
import React, { useState } from "react";
import { Input, Select } from "antd";
import next from "../../../../assests/images/master/next.png";
import down from "../../../../assests/images/master/down.png";
import zone_location_Icon from "../../../../assests/images/master/zone-location-icon.svg";
import local from "../../../../assests/images/master/scooter.svg";
import within_state from "../../../../assests/images/master/with_in_state.svg";
import metro from "../../../../assests/images/master/city.svg";
import spcl_location from "../../../../assests/images/master/spcl-location.svg";
import india from "../../../../assests/images/master/india.svg";
// import "./index.scss";
const RateCardTable = ({
  standardDocsData,
  standardNonDocsData,
  fastTrackSurfaceData,
  fastTrackAirData,
  fastTrackNonDoxData,
  valuePlusDoxData,
  valuePlusNonDoxData,
  handleChangeDocs,
  templateType,
  handleDynamicSelect,
}) => {
  const [showStandardDox, setShowStandardDox] = useState(true);
  const [showStandardNonDox, setShowStandardNonDox] = useState(false);
  const [showFastTrackSurface, setShowFastTrackSurface] = useState(false);
  const [showFastTrackAir, setShowFastTrackAir] = useState(false);
  const [showFastTrackNonDox, setShowFastTrackNonDox] = useState(false);
  const [showValuePlusDox, setShowValuePlusDox] = useState(false);
  const [showValuePlusNonDox, setShowValuePlusNonDox] = useState(false);
  const tableHeading = [
    { title: "Local", imgSrc: local },
    { title: "Within State", imgSrc: within_state },
    { title: "My Zone 1", imgSrc: zone_location_Icon },
    { title: "My Zone 2", imgSrc: zone_location_Icon },
    { title: "My Zone 3", imgSrc: zone_location_Icon },
    { title: "My Zone 4", imgSrc: zone_location_Icon },
    { title: "Metro", imgSrc: metro },
    { title: "Special location", imgSrc: spcl_location },
    { title: "Rest of India", imgSrc: india },
  ];

  const handleDoxInput = (data, e, title, innerTitle, docType) => {
    let temp = data;
    let outerIndex = temp.findIndex((item) => item.title == title);
    let innerIndex = temp[outerIndex].value.findIndex(
      (item) => item.title == innerTitle
    );

    let { value } = e.target;
    const regex = /^\d{1,3}(\.\d{0,2})?$/;
    if (regex.test(value) || value === "") {
      temp[outerIndex].value[innerIndex].value = value;
    }
    handleChangeDocs([...temp], docType);
  };

  const changeSelectBox = (e, data, title) => {
    let temp = data;
    temp.map((item) => {
      if (item.design) {
        item.title = e;
      }
    });
    handleDynamicSelect([...temp], title);
  };

  return (
    <table
      className={
        templateType === "view" || templateType === "allocate"
          ? "rate-card-table mt-2"
          : "rate-card-table"
      }
    >
      <thead>
        <tr>
          <th
            style={{ width: "15%", background: "#e9eaec" }}
            className="span-color-tbl"
          >
            Weight Slab
          </th>
          {tableHeading.map((e, index) => {
            return (
              <th key={e.title + "_" + index} style={{ width: "9%" }}>
                <span className="align-center d-flex">
                  <img src={e.imgSrc} alt={e.title} />
                  <span style={{ paddingLeft: "4px" }}>{e.title}</span>
                </span>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {/* ///--- STANDARD DOX ---/// */}
        <tr onClick={() => setShowStandardDox(!showStandardDox)}>
          <td colSpan={10} className="span-color-tbl tr-hover pointer">
            <span className="d-flex align-center">
              <img src={showStandardDox ? down : next} alt="arrow" /> Standard
              Dox (Surface/Air)
            </span>
          </td>
        </tr>
        {standardDocsData?.map((e, index) => {
          return (
            <tr
              key={e.title + "_" + index}
              style={{ display: showStandardDox ? "revert" : "none" }}
              className="tr-animation"
            >
              <td className="f-13 text-left">
                {e.design &&
                (templateType === "create" || templateType === "edit") ? (
                  <Select
                    className="rate-card-select"
                    placeholder="Add 250 Gms"
                    defaultValue="Add 250 Gms"
                    options={[
                      {
                        value: "Add 250 Gms",
                        label: "Add 250 Gms",
                      },
                    ]}
                  />
                ) : (
                  e?.title
                )}
              </td>
              {e.value.map((item, index) => {
                return (
                  <td key={item + "_" + index} style={{ padding: "0.5rem" }}>
                    {templateType === "view" || templateType === "allocate" ? (
                      <span className="f-12">
                        {item.value === "0.00" ? "1.00 " : item.value}
                      </span>
                    ) : (
                      <Input
                        value={item.value === "0.00" ? "1.00 " : item.value}
                        className="td-input"
                        onChange={(event) =>
                          handleDoxInput(
                            standardDocsData,
                            event,
                            e.title,
                            item.title,
                            "standardDocs"
                          )
                        }
                        min="0"
                      />
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
        {/* ///------/// */}
        {/* ///--- STANDARD NON DOX ---/// */}
        <tr onClick={() => setShowStandardNonDox(!showStandardNonDox)}>
          <td colSpan={10} className="span-color-tbl tr-hover pointer">
            <span className="d-flex align-center">
              <img src={showStandardNonDox ? down : next} alt="arrow" />{" "}
              Standard - Non - Dox
            </span>
          </td>
        </tr>

        {showStandardNonDox &&
          standardNonDocsData.map((e, index) => {
            return (
              <tr key={e.title + "_" + index} className="tr-animation">
                <td className="f-13 text-left">
                  {e.design &&
                  (templateType === "create" || templateType === "edit") ? (
                    <Select
                      className="rate-card-select"
                      onChange={(e) =>
                        changeSelectBox(
                          e,
                          standardNonDocsData,
                          "standardNonDocs"
                        )
                      }
                      defaultValue={e.title}
                      options={[
                        {
                          value: "Add 500 Gms",
                          label: "Add 500 Gms",
                        },
                        {
                          value: "Add 1000 Gms",
                          label: "Add 1000 Gms",
                        },
                      ]}
                    />
                  ) : (
                    e.title
                  )}
                </td>
                {e.value.map((item, index) => {
                  return (
                    <td key={item + "_" + index} style={{ padding: "0.5rem" }}>
                      {templateType === "view" ||
                      templateType === "allocate" ? (
                        <span className="f-12">
                          {item.value === "0.00" ? "1.00 " : item.value}
                        </span>
                      ) : (
                        <Input
                          value={item.value === "0.00" ? "1.00 " : item.value}
                          className="td-input"
                          min="0"
                          onChange={(event) =>
                            handleDoxInput(
                              standardNonDocsData,
                              event,
                              e.title,
                              item.title,
                              "standardNonDocs"
                            )
                          }
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        {/* ///------/// */}
        {/* ///--- Fast Track - Dox - Surface Mode ---/// */}
        <tr onClick={() => setShowFastTrackSurface(!showFastTrackSurface)}>
          <td colSpan={10} className="span-color-tbl tr-hover pointer">
            <span className="d-flex align-center">
              <img src={showFastTrackSurface ? down : next} alt="arrow" /> Fast
              Track - Dox - Surface Mode
            </span>
          </td>
        </tr>
        {showFastTrackSurface &&
          fastTrackSurfaceData.map((e, index) => {
            return (
              <tr key={e.title + "_" + index} className="tr-animation">
                <td className="f-13 text-left">
                  {e.design &&
                  (templateType === "create" || templateType === "edit") ? (
                    <Select
                      className="rate-card-select"
                      placeholder="Add 250 Gms"
                      defaultValue="Add 250 Gms"
                      options={[
                        {
                          value: "Add 250 Gms",
                          label: "Add 250 Gms",
                        },
                      ]}
                    />
                  ) : (
                    e.title
                  )}
                </td>
                {e.value.map((item, index) => {
                  return (
                    <td key={item + "_" + index} style={{ padding: "0.5rem" }}>
                      {templateType === "view" ||
                      templateType === "allocate" ? (
                        <span className="f-12">
                          {item.value === "0.00" ? "1.00 " : item.value}
                        </span>
                      ) : (
                        <Input
                          value={item.value === "0.00" ? "1.00 " : item.value}
                          className="td-input"
                          min="0"
                          onChange={(event) =>
                            handleDoxInput(
                              fastTrackSurfaceData,
                              event,
                              e.title,
                              item.title,
                              "fastTrackSurface"
                            )
                          }
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        {/* ///------/// */}
        {/* ///--- Fast Track - Dox - Air Mode ---/// */}
        <tr onClick={() => setShowFastTrackAir(!showFastTrackAir)}>
          <td colSpan={10} className="span-color-tbl tr-hover pointer">
            <span className="d-flex align-center">
              <img src={showFastTrackAir ? down : next} alt="arrow" /> Fast
              Track - Dox - Air Mode
            </span>
          </td>
        </tr>
        {showFastTrackAir &&
          fastTrackAirData.map((e, index) => {
            return (
              <tr key={e.title + "_" + index} className="tr-animation">
                <td className="f-13 text-left">
                  {e.design &&
                  (templateType === "create" || templateType === "edit") ? (
                    <Select
                      className="rate-card-select"
                      placeholder="Add 250 Gms"
                      defaultValue="Add 250 Gms"
                      options={[
                        {
                          value: "Add 250 Gms",
                          label: "Add 250 Gms",
                        },
                      ]}
                    />
                  ) : (
                    e.title
                  )}
                </td>
                {e.value.map((item, index) => {
                  return (
                    <td key={item + "_" + index} style={{ padding: "0.5rem" }}>
                      {templateType === "view" ||
                      templateType === "allocate" ? (
                        <span className="f-12">
                          {item.value === "0.00" ? "1.00 " : item.value}
                        </span>
                      ) : (
                        <Input
                          value={item.value === "0.00" ? "1.00 " : item.value}
                          className="td-input"
                          min="0"
                          onChange={(event) =>
                            handleDoxInput(
                              fastTrackAirData,
                              event,
                              e.title,
                              item.title,
                              "fastTrackAir"
                            )
                          }
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        {/* ///------/// */}
        {/* ///--- Fast Track - Non - Dox  ---/// */}
        <tr onClick={() => setShowFastTrackNonDox(!showFastTrackNonDox)}>
          <td colSpan={10} className="span-color-tbl tr-hover pointer">
            <span className="d-flex align-center">
              <img src={showFastTrackNonDox ? down : next} alt="arrow" /> Fast
              track -Non - Dox
            </span>
          </td>
        </tr>
        {showFastTrackNonDox &&
          fastTrackNonDoxData.map((e, index) => {
            return (
              <tr key={e.title + "_" + index} className="tr-animation">
                <td className="f-13 text-left">
                  {e.design &&
                  (templateType === "create" || templateType === "edit") ? (
                    <Select
                      className="rate-card-select"
                      onChange={(e) =>
                        changeSelectBox(
                          e,
                          fastTrackNonDoxData,
                          "fastTrackNonDox"
                        )
                      }
                      defaultValue={e.title}
                      options={[
                        {
                          value: "Add 500 Gms",
                          label: "Add 500 Gms",
                        },
                        {
                          value: "Add 1000 Gms",
                          label: "Add 1000 Gms",
                        },
                      ]}
                    />
                  ) : (
                    e.title
                  )}
                </td>
                {e.value.map((item, index) => {
                  return (
                    <td key={item + "_" + index} style={{ padding: "0.5rem" }}>
                      {templateType === "view" ||
                      templateType === "allocate" ? (
                        <span className="f-12">
                          {item.value === "0.00" ? "1.00 " : item.value}
                        </span>
                      ) : (
                        <Input
                          value={item.value === "0.00" ? "1.00 " : item.value}
                          className="td-input"
                          min="0"
                          onChange={(event) =>
                            handleDoxInput(
                              fastTrackNonDoxData,
                              event,
                              e.title,
                              item.title,
                              "fastTrackNonDox"
                            )
                          }
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        {/* ///------/// */}
        {/* ///--- Value Plus DOX ---/// */}
        <tr onClick={() => setShowValuePlusDox(!showValuePlusDox)}>
          <td colSpan={10} className="span-color-tbl tr-hover pointer">
            <span className="d-flex align-center">
              <img src={showValuePlusDox ? down : next} alt="arrow" /> Value
              Plus Dox (Surface/Air)
            </span>
          </td>
        </tr>
        {showValuePlusDox &&
          valuePlusDoxData.map((e, index) => {
            return (
              <tr
                key={e.title + "_" + index}
                style={{ display: showStandardDox ? "revert" : "none" }}
                className="tr-animation"
              >
                <td className="f-13 text-left">
                  {e.design &&
                  (templateType === "create" || templateType === "edit") ? (
                    <Select
                      className="rate-card-select"
                      placeholder="Add 250 Gms"
                      defaultValue="Add 250 Gms"
                      options={[
                        {
                          value: "Add 250 Gms",
                          label: "Add 250 Gms",
                        },
                      ]}
                    />
                  ) : (
                    e.title
                  )}
                </td>
                {e.value.map((item, index) => {
                  return (
                    <td key={item + "_" + index} style={{ padding: "0.5rem" }}>
                      {templateType === "view" ||
                      templateType === "allocate" ? (
                        <span className="f-12">
                          {item.value === "0.00" ? "1.00 " : item.value}
                        </span>
                      ) : (
                        <Input
                          value={item.value === "0.00" ? "1.00 " : item.value}
                          className="td-input"
                          onChange={(event) =>
                            handleDoxInput(
                              valuePlusDoxData,
                              event,
                              e.title,
                              item.title,
                              "valueplusDox"
                            )
                          }
                          min="0"
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        {/* ///------/// */}
        {/* ///--- Vakue Plus NON DOX ---/// */}
        <tr onClick={() => setShowValuePlusNonDox(!showValuePlusNonDox)}>
          <td colSpan={10} className="span-color-tbl tr-hover pointer">
            <span className="d-flex align-center">
              <img src={showValuePlusNonDox ? down : next} alt="arrow" /> Value
              Plus - Non - Dox
            </span>
          </td>
        </tr>

        {showValuePlusNonDox &&
          valuePlusNonDoxData.map((e, index) => {
            return (
              <tr key={e.title + "_" + index} className="tr-animation">
                <td className="f-13 text-left">
                  {e.design &&
                  (templateType === "create" || templateType === "edit") ? (
                    <Select
                      className="rate-card-select"
                      onChange={(e) =>
                        changeSelectBox(
                          e,
                          valuePlusNonDoxData,
                          "valueplusNonDox"
                        )
                      }
                      defaultValue={e.title}
                      options={[
                        {
                          value: "Add 500 Gms",
                          label: "Add 500 Gms",
                        },
                        {
                          value: "Add 1000 Gms",
                          label: "Add 1000 Gms",
                        },
                      ]}
                    />
                  ) : (
                    e.title
                  )}
                </td>
                {e.value.map((item, index) => {
                  return (
                    <td key={item + "_" + index} style={{ padding: "0.5rem" }}>
                      {templateType === "view" ||
                      templateType === "allocate" ? (
                        <span className="f-12">
                          {item.value === "0.00" ? "1.00 " : item.value}
                        </span>
                      ) : (
                        <Input
                          value={item.value === "0.00" ? "1.00 " : item.value}
                          className="td-input"
                          min="0"
                          onChange={(event) =>
                            handleDoxInput(
                              valuePlusNonDoxData,
                              event,
                              e.title,
                              item.title,
                              "valueplusNonDox"
                            )
                          }
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        {/* ///------/// */}
      </tbody>
    </table>
  );
};
export default React.memo(RateCardTable);
