import React from "react";
import { Modal,Spin,Carousel  } from "antd";
import crossButton from "../../assests/images/CrossButton.svg";
import pdf_icon from "../../assests/images/pdf-svg-icon.svg";
import "./style.scss";

const index = ({isModalOpen,setIsModalOpen,dataFile}) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const contentStyle = {
    lineHeight: "160px",
    textAlign: "center",
    background: "white",
  };
  const pdfHandle = (data)=>{
    window.open(data?.filePath || data?.fileName);
  };
  return (
    <Modal 
      title="Attachments" 
      open={isModalOpen} 
      onOk={handleOk} 
      onCancel={handleCancel} 
      footer={false} 
      className="attachment-container"
      closeIcon={<img src={crossButton} />}>
      <Spin spinning={!dataFile}>
        <Carousel autoplay style={contentStyle}>
          {dataFile?.map((data,index)=>{
            return(
              data?.filePath?.substring(data?.filePath?.length - 3) === "pdf" || data?.fileName?.substring(data?.fileName?.length - 3) === "pdf" ? 
                <>
                  <div className="center">
                    <img src={pdf_icon} alt="pdf" key={index}></img>
                  </div>
                  <a onClick={()=>pdfHandle(data)}>Click here</a>
                </> :
                <img src={data?.filePath || data?.fileName} alt="attachment" key={index}></img>
            );
          }
          )}
        </Carousel>
      </Spin>
    </Modal>
  );
};

export default index;
