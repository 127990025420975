/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, Input, Form, Button, Table, Spin, message } from "antd";
import "./style.scss";
import API from "../../../api";
import urls from "../../../api/urls";
import { capitaliseWithHyphen } from "../../../utils";

const { GET_DELIVERY_AREAS, CREATE_DELIVERY_AREA } = urls;

const CreateVirtualArea = ({ handleTabChange = () => {} }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState();
  const user_premiseId = JSON.parse(window?.localStorage?.getItem("userId"));

  const columns = [
    {
      title: "Area Name",
      dataIndex: "areaName",
      width: 350,
      key: "areaName",
      render: (text) => {
        return capitaliseWithHyphen(text);
      },
    },
    {
      title: "PIN Code",
      width: 650,
      dataIndex: "pincode",
      key: "pincode",
    },
  ];

  const onSelectChange = (newSelectedRowKeys, e) => {
    setSelectedAreas(newSelectedRowKeys);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      selectedRowKeys, onSelectChange(selectedRows);
    },
  };

  const handleSubmit = async (id) => {
    if (selectedAreas?.length) {
      let areas = selectedAreas.map((val) => {
        delete val["key"];
        delete val["allocated"];
        delete val["pincode"];
        return val;
      });

      let payload = {
        virtualAreaName: id?.deliveryName,
        areas: areas,
      };
      let response = await API.Api(CREATE_DELIVERY_AREA).postIdHeader(payload);
      if (response.status) {
        setLoading(false);
        message.success(response?.response?.data?.message || "Network error");
        handleTabChange("2");
      } else {
        setLoading(false);
        message.error(response?.response?.data?.message || "Network error");
      }
    } else {
      message.error("Select area");
    }
  };

  const getTableData = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_DELIVERY_AREAS + user_premiseId?.userInfo?.premiseId
    ).getWithPremiseIdHeader();
    if (response.status) {
      setLoading(false);
      let x = response?.response?.data.map((val) => val);
      let data = x.map((val, idx) => {
        val.areas["key"] = idx;
        val.areas["pincode"] = val?.pincode;
        return val.areas;
      });
      setTableData(data);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="virtual-area-container p-2">
        <Form
          // {...layout}
          form={form}
          onFinish={handleSubmit}
          name="accountBasicDetails"
          layout="vertical"
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
        >
          <Row gutter={16} className="m-1">
            <Col span={6}>
              <Form.Item
                name="deliveryName"
                label="Delivery area name"
                rules={[
                  {
                    required: true,
                    message: "Enter area name",
                  },
                ]}
              >
                <Input placeholder="Enter area name" />
              </Form.Item>
            </Col>
          </Row>
          <Table
            onChange={onSelectChange}
            rowSelection={{
              preserveSelectedRowKeys: true,
              getCheckboxProps: (a) => {
                return a;
              },
              ...rowSelection,
            }}
            className="table-head-sticky"
            columns={columns}
            dataSource={tableData}
            pagination={false}
            // rowClassName={(record) => !record.status && "disabled-row"}
          />
        </Form>
        <Row justify="end mt-1">
          {/* <Col>
            <Button className="cancel-btn" onClick={handleCancelClick}>
              CANCEL
            </Button>
          </Col> */}
          <Col>
            <Button
              className="save-btn"
              htmlType="submit"
              onClick={form.submit}
            >
              CREATE DELIVERY AREA
            </Button>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};
export default CreateVirtualArea;
