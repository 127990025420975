/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from "react";
import { Row, Col, Table, Button, message, Spin, Empty } from "antd";
import { useLocation, useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import { serialNum } from "../../../utils";
import API from "../../../api";
import urls from "../../../api/urls";
import print_icon from "../../../assests/images/master/print.svg";
import smcs_logo from "../../../assests/images/smcsLogo.svg";
import nation_tax_logo from "../../../assests/images/nation_tax_logo.svg";
import back_img from "../../../assests/images/rightVector.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import "./style.scss";
const { EWAYBILL_PRINT } = urls;

const printEwayBill = () => {
  const navigate = useNavigate();
  const history = useLocation();
  const componentRef = useRef();
  const [printData, setPrintData] = useState();
  const [loading, setLoading] = useState(false);

  // const columns = [
  //   {
  //     title: "Description",
  //     dataIndex: "description",
  //     key: "description",
  //   },
  //   {
  //     title: "Quantity",
  //     dataIndex: "quantity",
  //     key: "quantity",
  //   },
  //   {
  //     title: "Weight",
  //     dataIndex: "weight",
  //     key: "weight",
  //   },
  //   {
  //     title: "Value",
  //     dataIndex: "value",
  //     key: "value",
  //   },
  // ];
  const columns = [
    {
      title: "Sl.No",
      align: "center",
      render: (a, b, idx) => serialNum(10, 1, idx),
    },
    {
      title: "Eway Bill No. & Date",
      dataIndex: "ewaybillNo",
      key: "ewaybillNo",
      width: 150,
      render: (text, record) =>
        (
          <>
            <span>{text}</span>
            <br />
            <span>{record?.ewayBillCrtDate || ""}</span>
          </>
        ) || "N/A",
    },
    {
      title: "Eway Bill By ",
      dataIndex: "shipmentGst",
      key: "shipmentGst",
      render: (text) => text || "N/A",
    },
    {
      title: "AWB No. & Date",
      dataIndex: "awbnumber",
      key: "awbnumber",
      render: (text, record) =>
        (
          <>
            <span>{text}</span>
            <br />
            <span>{record?.awbcrtDate || ""}</span>
          </>
        ) || "N/A",
    },
    {
      title: "Value",
      dataIndex: "shipmentValue",
      key: "shipmentValue",
      render: (text) => <span>{text || "N/A"}</span>,
    },
    {
      title: "To",
      dataIndex: "shipmentAddress",
      key: "shipmentAddress",
      render: (text) => <span>{text || "N/A"}</span>,
    },
    {
      title: "Valid Until",
      dataIndex: "ewbBillExpDate",
      key: "ewbBillExpDate",
      render: (text) => <span>{text || "N/A"}</span>,
    },
  ];
  const printHandle = useReactToPrint({
    content: () => componentRef.current,
  });
  const getEwayBill = async () => {
    setLoading(true);
    let response = await API.Api(
      EWAYBILL_PRINT + history?.state?.state
    ).getWithUserNPremiseHeader();
    if (response?.status) {
      setPrintData(response?.response?.data);
      setLoading(false);
    } else {
      message.error(response?.response?.data?.message || "Network error");
      setLoading(false);
    }
  };
  useEffect(() => {
    getEwayBill();
  }, []);

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="print-drs-container">
        <div className="header p-1 mb-1">
          <span className="align-center">
            <img
              src={back_img}
              onClick={() =>
                navigate(history?.state?.slug, { state: history?.state?.key })
              }
              style={{ cursor: "pointer" }}
              className="mr-1"
            />
            <b className="align-center">Print Eway Bill</b>
          </span>
          <Button onClick={printHandle}>
            <img src={print_icon} /> <b>PRINT</b>
          </Button>
        </div>
        {printData?.length ? (
          <div ref={componentRef}>
            {printData?.map((data, index) => (
              <div
                key={index}
                className="main-div p-2"
                style={{ marginBottom: "20px" }}
              >
                <Row className="space-between mb-2">
                  {data?.consolidateEwayBill === "0" ? (
                    <Col span={8} className="vertical-space-between">
                      <img src={smcs_logo} style={{ width: "164px" }} />
                      <b className="mt-1">Individual Part-B Update List</b>
                    </Col>
                  ) : (
                    <Col span={8} className="vertical-space-between">
                      <img src={smcs_logo} style={{ width: "164px" }} />
                      <b className="mt-1">Consolidated Eway Bill</b>
                    </Col>
                  )}
                  <Col span={8}>
                    <img src={nation_tax_logo} />
                  </Col>
                </Row>
                <div
                  className="heading p-05"
                  style={{
                    backgroundColor: "#F5F8FD",
                    border: "1px solid #C8DFFB",
                    borderRadius: "5px",
                  }}
                >
                  {data?.consolidateEwayBill === "0" ? (
                    <b>Individual Part-B Update</b>
                  ) : (
                    <b>Consolidated Eway Bill Details</b>
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid #DEDEDE",
                    borderCollapse: "collapse",
                  }}
                  className="mb-1 mt-1"
                >
                  <Row>
                    <Col span={12} style={{ border: "1px solid #DEDEDE" }}>
                      <Row style={{ margin: "8px" }}>
                        <Col span={13}>Consolidated Eway Bill No.</Col>
                        <Col span={11}>
                          <b>
                            {data?.consEwbNo === "0" ? "N/A" : data?.consEwbNo}
                          </b>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} style={{ border: "1px solid #DEDEDE" }}>
                      <Row style={{ margin: "8px" }}>
                        <Col span={13}>Vehicle No.</Col>
                        <Col span={11}>
                          <b>{data?.vehicleNo}</b>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} style={{ border: "1px solid #DEDEDE" }}>
                      <Row style={{ margin: "8px" }}>
                        <Col span={13}>Date</Col>
                        <Col span={11}>
                          <b>{data?.updateDate}</b>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} style={{ border: "1px solid #DEDEDE" }}>
                      <Row style={{ margin: "8px" }}>
                        <Col span={13}>From</Col>
                        <Col span={11}>
                          <b>{data?.ewbOprPlace}</b>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} style={{ border: "1px solid #DEDEDE" }}>
                      <Row style={{ margin: "8px" }}>
                        <Col span={13}>Transporter ID</Col>
                        <Col span={11}>
                          <b>88AABCM9407D1ZS</b>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} style={{ border: "1px solid #DEDEDE" }}>
                      <Row style={{ margin: "8px" }}>
                        <Col span={13}>Mode & RR No.</Col>
                        <Col span={11}>
                          <b>{data?.transMode}</b>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div
                  className="heading p-05 mb-1"
                  style={{
                    backgroundColor: "#F5F8FD",
                    border: "1px solid #C8DFFB",
                    borderRadius: "5px",
                  }}
                >
                  <b>Item Details</b>
                </div>
                <Table
                  dataSource={data?.shipments}
                  columns={columns}
                  pagination={false}
                />
              </div>
            ))}
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </Spin>
  );
};

export default printEwayBill;
