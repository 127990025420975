import React, { useState,useEffect } from "react";
import { Tabs } from "antd";
import { useNavigate,useLocation } from "react-router";
import DrsGeneration from "./DrsGeneration";
import ViewDrs from "./ViewDrs";
import back_img from "../../../assests/images/rightVector.svg";

const CreateTrip = () => {
  const history = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState("1");
  const items = [
    {
      key: "1",
      label: "DRS Generation",
      children: <DrsGeneration setStep={setStep}/>,
    },
    {
      key: "2",
      label: "View DRS",
      children: <ViewDrs step={step}/>,
    },
   
  ];

  const onChange = (key) => {
    setStep(key);
  };
  useEffect(()=>{
    if (history?.state) {
      setStep("2");
    }
  },[history]);
  return (
    <div className="p2 consolidated-tab-cntr">
      <div className="top-wrapper space-between ">
        <div className="heading mb-1 align-center">
          <img
            src={back_img}
            onClick={() => navigate("/last-mile-operations")}
            style={{ cursor: "pointer" }}
          />
          <p
            style={{
              margin: "0",
              paddingLeft: "5px",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "32px",
              letterSpacing: "-0.25px",
              color: "#061624",
            }}
          >
            <b>DRS Generation</b>
          </p>
        </div>
      </div>
      <Tabs
        defaultActiveKey={"1"}
        activeKey={step}
        items={items}
        onChange={onChange}
      />
    </div>
  );
};
export default CreateTrip;
