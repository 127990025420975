/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Row, Col, Form, Input, Button, message, Select, Spin } from "antd";
import urls from "../../../api/urls";
import API from "../../../api";
import {
  capitalise,
  checkSpecialCharacter,
  capitaliseWithHyphen,
} from "../../../utils";
import Places from "../../../components/places";
import {
  getPacketingGuideFromHublist,
  getPacketingGuideFromCplist,
} from "../../../actionsMethods/masterMethods";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import "./style.scss";
const {
  GET_HUB_PINCODE,
  GET_HUB_NAME_VALIDATE,
  GET_HUB_EMAIL_VALIDATE,
  GET_HUB_GST_VALIDATE,
  GET_HUB_PINCODE_VALIDATE,
  POST_CREATE_HUB,
  PATCH_CP_ALLOCATION,
} = urls;

const HubOnboard = ({ onboardHubHandle, activeKey }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Option } = Select;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pinCodeDetails, setPinCodeDetails] = useState();
  const [validateStatus, setValidateStatus] = useState("none");
  const [enable, setEnable] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [responseStatus, setResponseStatus] = useState();
  const [cpArray, setCpArray] = useState([]);
  const [cpArrayDlt, setCpArrayDlt] = useState([]);
  const [cpAllocationVisible, setCpAllocationVisible] = useState(false);
  const [onboardBtnDisable, setOnboardBtnDisable] = useState(false);

  const getFromHubListResponse = useSelector(
    (state) => state?.masterReducer?.getPacketingGuideFromHublistReducer,
    shallowEqual
  );
  const getFromCpListResponse = useSelector(
    (state) => state?.masterReducer?.getPacketingGuideFromCplistReducer,
    shallowEqual
  );
  const { result: allHubs } = getFromHubListResponse;
  const { result: allCps, fetching } = getFromCpListResponse;

  useEffect(() => {
    let arr = [];
    allCps?.map((data) => arr.push(data));
    setCpArray([...cpArray, ...arr]);
    setCpArrayDlt([...cpArray, ...arr]);
  }, [allCps]);
  useEffect(() => {
    if (cpArray.length > 0)
      form.setFieldValue(
        "selectcp",
        cpArray?.map((data) => data?.premiseName)
      );
    else {
      form.setFieldsValue({
        selectcp: [],
      });
    }
  }, [cpArray]);
  useEffect(() => {
    if (enable === false) {
      setButtonDisabled(true);
      form.setFieldsValue({
        city: "",
        state: "",
        zone: "",
      });
    } else {
      setButtonDisabled(false);
    }
  }, [enable]);
  useEffect(() => {
    dispatch(getPacketingGuideFromHublist());
    setCpArray([]);
    setCpArrayDlt([]);
  }, []);
  useEffect(() => {
    if (activeKey === "1") form.resetFields();
  }, [activeKey]);
  useEffect(() => {
    if (enable === false) {
      message.error("Non-serviceable pincode");
    }
  }, [enable]);

  const hubTypeOptions = [
    { label: "Mother Hub", value: "Mother Hub" },
    { label: "Mini Hub", value: "Mini Hub" },
  ];
  const hubModeOptions = [
    { label: "Surface", value: "Surface" },
    { label: "Air", value: "Air" },
    { label: "Both", value: "Both" },
  ];
  const createHubOnboard = async (values) => {
    const {
      GSTIN,
      addressLine1,
      addressLine2,
      city,
      emailId,
      hubName,
      hubType,
      latitude,
      longitude,
      phoneNumber1,
      phoneNumber2,
      pincode,
      state,
      zone,
      hubMode,
    } = values;
    setIsLoading(true);
    let temp = [];
    if (values.hub !== undefined) {
      values.hub.map((e) => {
        temp.push({
          name: e,
        });
      });
    }
    let payload = {
      premiseDetails: {
        premiseName: hubName,
        hubType: hubType,
        gst: GSTIN,
        officialEmailId: emailId,
        officialNumber: +phoneNumber1,
        personalNumber: +phoneNumber2,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        pincode: pincode,
        city: city,
        state: state,
        latitude: latitude,
        longitude: longitude,
        zone: zone,
        hubMode: hubMode,
      },
    };
    let response = await API.Api(POST_CREATE_HUB).postIdHeader(payload);
    if (response?.status) {
      setResponseStatus(response?.response);
      setIsLoading(false);
      setValidateStatus("none");
      setCpAllocationVisible(true);
      setOnboardBtnDisable(true);
      message.success(
        response?.response?.data?.message || "Hub onboard Successfully"
      );
    } else {
      message.error(response?.response?.data?.message || "Hub onboard failed");
      setIsLoading(false);
    }
  };

  const setDetailsHandler = (lat, lng) => {
    form.setFieldValue("latitude", lat);
    form.setFieldValue("longitude", lng);
  };
  const pincodeHandler = async (values) => {
    if (values.target.value.length > 5) {
      setIsLoading(true);
      let response = await API.Api(
        `${GET_HUB_PINCODE}${values.target.value}`
      ).getWithPremiseIdHeader();
      if (response?.status) {
        let res = response?.response?.data;
        setEnable(res?.enable);
        form.setFieldsValue({
          city: capitaliseWithHyphen(res?.city),
          state: capitaliseWithHyphen(res?.stateName),
          zone: capitaliseWithHyphen(res?.zone),
        });
        setPinCodeDetails(null);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setPinCodeDetails(response?.response?.data?.message);
      }
    } else if (values.target.value.length < 6) {
      form.setFieldsValue({
        city: "",
        state: "",
        zone: "",
      });
      setEnable(true);
      setPinCodeDetails(null);
    }
  };
  const upperCase = (e, name) => {
    const caps = capitalise(e);
    form.setFieldValue(name, caps);
  };
  const selectHubHandle = (data, key) => {
    dispatch(getPacketingGuideFromCplist(key?.key));
  };
  const deselectHubHandle = (data, key) => {
    let deletedHub = cpArray?.filter(
      (hubData) => hubData?.parentId !== parseInt(key?.key)
    );
    setCpArray(deletedHub);
    setCpArrayDlt(deletedHub);
  };
  const deselectCpHandle = (data, key) => {
    let deletedHub = cpArrayDlt?.filter(
      (cpData) => cpData?.premiseId !== parseInt(key?.key)
    );
    setCpArrayDlt(deletedHub);
  };
  const selectCpHandle = (data, key) => {
    let addHub = cpArray?.find(
      (cpData) => cpData?.premiseId == parseInt(key?.key)
    );
    let arr = [];
    arr.push(addHub);
    setCpArrayDlt([...cpArrayDlt, ...arr]);
  };
  const saveHandle = async (e) => {
    e.preventDefault();
    const cpPayload = {
      cpId: cpArrayDlt?.map((data) => data?.premiseId),
    };

    let response = await API.Api(
      `${PATCH_CP_ALLOCATION}${responseStatus?.data?.premiseDetails?.premiseId}`
    ).patchIdHeader(cpPayload);

    if (response?.status) {
      form.setFieldsValue({
        city: "",
        state: "",
        zone: "",
      });
      form.resetFields();
      setCpAllocationVisible(false);
      if (response?.status) {
        message.success("CP allocated");
      } else {
        message.error("CP can't be allocated");
      }
    }

    if (responseStatus?.status === 200) {
      onboardHubHandle();
    }
  };

  return (
    <Spin
      spinning={isLoading || fetching}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="master-main-container profile-container">
        <Form
          autoComplete="off"
          className="global-form profile-container-form"
          layout="vertical"
          form={form}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          onFinish={createHubOnboard}
        >
          <Row className="global-form-row">
            <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
            <Col xs={22} sm={22} md={16} lg={16} xl={16}>
              <Row
                gutter={20}
                className="mt-1 profile-details-container mainForm"
              >
                <Col span={24} className="mb-1">
                  <span className="master-title">Basic Details</span>
                  <hr className="divider" />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="hubName"
                    label="Hub name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter hub name",
                      },
                      {
                        min: 3,
                        message: "Hub name must be minimum of 3 characters.",
                      },
                      () => ({
                        async validator(_, value) {
                          let res = await API.Api(
                            `${GET_HUB_NAME_VALIDATE}${value}`
                          ).getWithPremiseIdHeader();
                          if (res?.status) {
                            if (value && value.substring(2, 3) === " ") {
                              return Promise.reject(
                                "Please enter valid hub name"
                                );
                              } else {
                                return Promise.resolve();
                              }
                            } else {
                            if (value.length >= 3) {
                              return Promise.reject(
                                res?.response?.data?.message
                              );
                            }
                          }
                        },
                      }),
                    ]}
                  >
                    <Input
                      style={{ textTransform: "capitalize" }}
                      placeholder="Enter hub name"
                      onBlur={(e) => upperCase(e, "hubName")}
                      maxLength={40}
                      onKeyPress={(e) => {
                        if (/[^A-Za-z-(-) ]/.test(e.key)) {
                          e.preventDefault();
                        }
                        if (e.target.value.length >= 20) {
                          e.preventDefault();
                        }
                      }}
                      onKeyDown={(e) => {
                        if (!/[a-zA-Z]/.test(e.target.value))
                          e.target.value = "";
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="hubType"
                    label="Hub type"
                    rules={[
                      {
                        required: true,
                        message: "Please select hub type",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select hub type"
                      options={hubTypeOptions}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="hubMode"
                    label="Hub mode"
                    rules={[
                      {
                        required: true,
                        message: "Please select hub mode",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select hub mode"
                      options={hubModeOptions}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="emailId"
                    label="Email ID"
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid Email-ID",
                        type: "email",
                      },
                      () => ({
                        async validator(_, value) {
                          let res = await API.Api(
                            `${GET_HUB_EMAIL_VALIDATE}${value}`
                          ).getWithPremiseIdHeader();
                          if (res?.status) {
                            if (value && value.substring(2, 3) === " ") {
                              return Promise.reject(
                                "Please enter valid email id"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          } else {
                            if (value.length >= 3) {
                              return Promise.reject(
                                res?.response?.data?.message
                              );
                            }
                          }
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Enter Email-ID"
                      onInput={(e) =>
                        (e.target.value = e.target.value.toLowerCase())
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    className="gstin-style"
                    name="GSTIN"
                    label="GSTIN"
                    rules={[
                      {
                        message: null,
                        required: true,
                      },
                      () => ({
                        async validator(_, value) {
                          if (!value) {
                            return Promise.reject({
                              message: "Please enter a valid GST Number",
                            });
                          }
                          if (
                            value.length === 15 &&
                            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[0-9A-Z]{1}$/.test(
                              value
                            ) === false
                          ) {
                            setValidateStatus("error");
                            return Promise.reject({
                              max: 15,
                              min: 15,
                              message: "Please enter a valid GST Number",
                            });
                          } else if (value.length > 0 && value.length < 15) {
                            setValidateStatus("error");
                            return Promise.reject({
                              message: "Please enter a valid GST Number",
                            });
                          }
                          setValidateStatus("success");

                          let res = await API.Api(
                            `${GET_HUB_GST_VALIDATE}${value}`
                          ).getWithPremiseIdHeader();
                          if (res?.status) {
                            if (value && value.substring(2, 3) === " ") {
                              return Promise.reject(
                                "Please enter valid hub name"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          } else {
                            if (value.length >= 3) {
                              setValidateStatus("error");
                              return Promise.reject(
                                res?.response?.data?.message
                              );
                            }
                          }

                          if (!value) {
                            setValidateStatus("none");
                            return Promise.reject({
                              message: "Please enter a valid GST Number",
                            });
                          }
                          return Promise.resolve({});
                        },
                      }),
                    ]}
                    hasFeedback
                    validateStatus={validateStatus}
                  >
                    <Input
                      placeholder="Enter GSTIN"
                      onInput={(e) =>
                        (e.target.value = e.target.value.toUpperCase())
                      }
                      onKeyPress={(e) => {
                        if (checkSpecialCharacter(e)) {
                          e.preventDefault();
                        }
                        if (e.target.value.length >= 15) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="phoneNumber1"
                    label="Phone number 1"
                    rules={[
                      {
                        required: true,
                        message: "Please enter phone number",
                      },
                      () => ({
                        validator(rule, value = "") {
                          if (parseInt(value.substring(1, 0)) === 0) {
                            return Promise.reject(
                              "Mobile number should not be 0"
                            );
                          }
                          if (value.length > 0 && value.length < 10) {
                            return Promise.reject(
                              "Please enter 10 digits phone number"
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Enter phone number"
                      onKeyPress={(e) => {
                        if (/[^0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                        if (e.target.value.length >= 10) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="phoneNumber2"
                    label="Phone number 2"
                    rules={[
                      {
                        required: false,
                        message: "Please enter phone number",
                      },
                      () => ({
                        validator(rule, value = "") {
                          if (parseInt(value.substring(1, 0)) === 0) {
                            return Promise.reject(
                              "Mobile number should not be 0"
                            );
                          }
                          if (value.length > 0 && value.length < 10) {
                            return Promise.reject(
                              "Please enter 10 digits phone number"
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Enter Phone number"
                      onKeyPress={(e) => {
                        if (/[^0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                        if (e.target.value.length >= 10) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} className="mb-1 mt-2">
                  <span className="master-title">Address Details</span>
                  <hr className="divider" />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="addressLine1"
                    label="Address line 1"
                    rules={[
                      {
                        required: true,
                        message: "Please enter address ",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Address line 1" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="addressLine2" label="Address line 2">
                    <Input placeholder="Enter Address line 2" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="pincode"
                    label="PIN code"
                    validateStatus={pinCodeDetails ? "error" : "none"}
                    help={pinCodeDetails}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a valid PIN code",
                      },
                      () => ({
                        async validator(rule, value = "") {
                          let response = await API.Api(
                            `${GET_HUB_PINCODE_VALIDATE}${value}`
                          ).getWithPremiseIdHeader();
                          if (!response?.status) {
                            if (value.length === 6) {
                              return Promise.reject(
                                response?.response?.data?.message
                              );
                            }
                          }

                          if (enable === false) {
                            return Promise.reject("PIN code is disabled");
                          }
                          if (parseInt(value.substring(1, 0)) === 0) {
                            return Promise.reject("PIN code should not be 0");
                          }
                          if (value.length > 0 && value.length < 6) {
                            return Promise.reject(
                              "Please enter 6 digits PIN code"
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Enter PIN code"
                      onChange={pincodeHandler}
                      onKeyPress={(e) => {
                        if (/[^0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                        if (e.target.value.length >= 6) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="city" label="City">
                    <Input placeholder="Enter City" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="state" label="State">
                    <Input placeholder="Enter State" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="zone" label="Zone">
                    <Input placeholder="Enter Zone" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Places
                    setDetailsHandler={(lat, lng) =>
                      setDetailsHandler(lat, lng)
                    }
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="latitude"
                    label="Latitude"
                    rules={[
                      {
                        required: true,
                        message: "Please give latitude",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Latitude" type="number" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="longitude"
                    label="Longitude"
                    rules={[
                      {
                        required: true,
                        message: "Please give longitude",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Longitude" type="number" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <hr className="divider" />
                  <div className="d-flex flex-end p-1">
                    <Button
                      onClick={() => {
                        form.resetFields();
                        navigate("/onboarding-settings");
                      }}
                      className="btn-style cancel-btn ml-1 "
                    >
                      CANCEL
                    </Button>
                    <Button
                      htmlType="submit"
                      className="save-btn ml-1"
                      disabled={onboardBtnDisable}
                    >
                      ONBOARD
                    </Button>
                  </div>
                </Col>
                {cpAllocationVisible ? (
                  <>
                    <Col span={24} className="mb-1 mt-2">
                      <span className="master-title">Allocate CPs to Hub</span>
                      <hr className="divider" />
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label="Select hub" name="selecthub">
                        <Select
                          showSearch
                          placeholder="Select hub name"
                          onSelect={(data, key) => selectHubHandle(data, key)}
                          onDeselect={(data, key) =>
                            deselectHubHandle(data, key)
                          }
                          mode="multiple"
                          className="tohub-style multiple-input-field cp-list-scroll mul-input"
                        >
                          {allHubs?.map((hubs) => (
                            <Option
                              key={hubs?.premiseId}
                              value={hubs?.premiseName}
                            >
                              {capitaliseWithHyphen(hubs?.premiseName)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label="Select CP" name="selectcp">
                        <Select
                          placeholder="Select CP"
                          className="fromcp-style multiple-input-field cp-list-scroll mul-input"
                          mode="multiple"
                          onDeselect={(data, key) =>
                            deselectCpHandle(data, key)
                          }
                          onSelect={(data, key) => selectCpHandle(data, key)}
                        >
                          {cpArray?.map((option) => (
                            <Option
                              key={option?.premiseId}
                              value={option?.premiseName}
                            >
                              {capitaliseWithHyphen(option?.premiseName)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                ) : null}
              </Row>
            </Col>
            <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
          </Row>
          {cpAllocationVisible ? (
            <>
              <hr className="divider" />
              <div className="d-flex flex-end p-1">
                <Button
                  className="btn-style cancel-btn ml-1 w-10"
                  onClick={() => {
                    navigate("/onboarding-settings");
                    form.resetFields();
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  className={
                    buttonDisabled ? "btn-style ml-1" : "save-btn ml-1"
                  }
                  onClick={saveHandle}
                >
                  SAVE
                </Button>
              </div>
            </>
          ) : null}
        </Form>
      </div>
    </Spin>
  );
};
export default HubOnboard;
