import React from "react";
// import Heading from "../../components/Heading";
import { Form, Button, Row, Col, Input, Select, Tooltip } from "antd";
// import { ColumnHeightOutlined } from "@ant-design/icons";
import {
  checkSpecialCharacter,
  checkNumbervalue,
  checkSpace,
} from "../../utils";
import { HeaderWithBottomLine } from "../Master/CpOnbordingSteps/HeaderWithBottomLine";
import crosImgForChildBagagge from "../../assests/crosImgForChildBagagge.svg";

function NewMiscellaneous({ handleStepsChange, goBackStep }) {
  const [form] = Form.useForm();

  // eslint-disable-next-line no-unused-vars
  const onFinish = (values) => {};
  const md = 12;
  const lg = 12;
  const xl = 12;
  const sm = 12;

  const getPinCodeDetails = (e, n) => {
    const fields = form.getFieldsValue("users");
    const { users } = fields;

    users[n] = {
      ...users[n],
      ["city"]: "new property value",
      ["StateCode"]: "new property value",
      ["state"]: "new property value",
    };
    form.setFieldValue("users", users);
  };
  return (
    <div className="p-5-box">
      {/* <Heading title={"Miscellaneous Details"} /> */}
      <HeaderWithBottomLine text={"Miscellaneous Details"} icon={false} />{" "}
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={12} style={{ paddingBottom: "20px" }}>
          <Col md={md} xl={xl} sm={sm} lg={lg}>
            <Form.Item
              name="Experience"
              label={"Experience in courier industry (in years)"}
              onKeyPress={(event) => {
                if (checkNumbervalue(event) || checkSpace(event)) {
                  event.preventDefault();
                }
              }}
              rules={[
                {
                  pattern: /^[0-9].*/g,
                  message: "Initial value should be numeric.",
                },
                {
                  pattern: /^\d{1,2}(\.\d{0,2})?$/,
                  message: "Invalid experience",
                },
              ]}>
              <Input
                placeholder="0"
                type="text"
                maxLength={5}
                className="input-field-style"
                onKeyPress={(e) => {
                  if (/[^0-9.]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col md={md} xl={xl} sm={sm} lg={lg}>
            <Form.Item
              name="previousCompany"
              label={"Name of the previous courier company"}>
              <Input
                placeholder="Enter previous company name"
                type="text"
                maxLength={50}
                className="input-field-style"
              />
            </Form.Item>
          </Col>
          <Col md={md} xl={xl} sm={sm} lg={lg}>
            <Form.Item
              name="MonthlyTurnover"
              label={"Monthly turnover (approximate)"}
              onKeyPress={(event) => {
                if (checkNumbervalue(event) || checkSpace(event)) {
                  event.preventDefault();
                }
              }}
              rules={[
                {
                  pattern: /^\d{1,10}(\.\d{0,2})?$/,
                  message: "Invalid turnover",
                },
                () => ({
                  validator(rule, value = "") {
                    if (value.substring(1, 0) === " ") {
                      return Promise.reject("Invalid turnover");
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}>
              <Input
                placeholder="0"
                type="text"
                maxLength={20}
                className="input-field-style"
              />
            </Form.Item>
          </Col>
          <Col md={md} xl={xl} sm={sm} lg={lg}>
            <Form.Item
              name="OwnerShip"
              label={
                <span className="calculater-container-pikpin">
                  Office ownership
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Please select value",
                },
              ]}>
              <Select
                placeholder="Select ownership"
                options={[
                  {
                    value: "Owned",
                    label: "Owned",
                  },
                  {
                    value: "Rented",
                    label: "Rented",
                  },
                ]}
                className="input-field-style"
              />
            </Form.Item>
          </Col>
          <Col md={md} xl={xl} sm={sm} lg={lg}>
            <Form.Item
              name="officeArea"
              label={"Office area in sq. ft."}
              onKeyPress={(event) => {
                if (checkNumbervalue(event) || checkSpace(event)) {
                  event.preventDefault();
                }
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter value",
                },
                {
                  pattern: /^\d{1,10}(\.\d{0,2})?$/,
                  message: "Invalid office area",
                },
                () => ({
                  validator(rule, value = "") {
                    if (value.substring(1, 0) === " ") {
                      return Promise.reject("Invalid Office area");
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}>
              <Input
                placeholder="0"
                type="text"
                maxLength={20}
                className="input-field-style"
              />
            </Form.Item>
          </Col>
          <Col md={md} xl={xl} sm={sm} lg={lg}>
            <Form.Item
              name="staff"
              label={"No. of staff"}
              onKeyPress={(event) => {
                if (
                  checkNumbervalue(event) ||
                  checkSpecialCharacter(event) ||
                  checkSpace(event)
                ) {
                  event.preventDefault();
                }
              }}
              rules={[
                {
                  required: true,
                  message: "Please select value",
                },
              ]}>
              <Input
                placeholder="Enter number of staff"
                type="text"
                maxLength={3}
                className="input-field-style"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.List name="users" initialValue={[""]}>
          {(fields, { add, remove }) => (
            <>
              <HeaderWithBottomLine
                text={"Branch Details"}
                icon={false}
                className="pt-1">
                <Form.Item>
                  <Button
                    //   type="dashed"
                    className="cancel-btn"
                    onClick={() => {
                      add("", 0);
                    }}
                    //   block
                    // icon={<PlusOutlined />}
                  >
                    + ADD BRANCH
                  </Button>
                </Form.Item>
              </HeaderWithBottomLine>
              {fields.map(({ key, name, ...restField }, index) => (
                <div key={key} style={{ padding: "10px 20px 0 20px" }}>
                  <div className="space-between">
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#061624",
                      }}>
                      Branch {index + 1}
                    </div>
                    <Tooltip placement="right" title={"Delete"}>
                      <Button
                        type="text"
                        style={{ background: "none", width: "0" }}
                        //   disabled={childInput.length == 1 ? true : false}
                      >
                        <img
                          src={crosImgForChildBagagge}
                          className="delete-child-input-button"
                          onClick={() => {
                            remove(name);
                          }}
                        />
                      </Button>
                    </Tooltip>
                  </div>
                  <hr
                    className="divider"
                    // style={{ marginTop: "20px", marginBottom: "30px" }}
                  />
                  <Row gutter={12} style={{ paddingBottom: "20px" }}>
                    <Col md={md} xl={xl} sm={sm} lg={lg}>
                      <Form.Item
                        name={[name, "branch_name"]}
                        label={"Branch name"}
                        {...restField}
                        rules={[
                          {
                            required: true,
                            message: "Please enter value",
                          },
                        ]}>
                        <Input
                          placeholder="Enter CP name"
                          className="input-field-style"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={md} xl={xl} sm={sm} lg={lg}>
                      <Form.Item
                        name={[name, "CP type"]}
                        label={"CP type"}
                        {...restField}
                        rules={[
                          {
                            required: true,
                            message: "Please enter value",
                          },
                        ]}>
                        <Input
                          placeholder="Enter CP name"
                          className="input-field-style"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={md} xl={xl} sm={sm} lg={lg}>
                      <Form.Item
                        name={[name, "Mother hub name"]}
                        label={"Mother hub name"}
                        {...restField}
                        rules={[
                          {
                            required: true,
                            message: "Please enter value",
                          },
                        ]}>
                        <Input
                          placeholder="Enter CP name"
                          className="input-field-style"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={md} xl={xl} sm={sm} lg={lg}>
                      <Form.Item
                        name={[name, "Zone"]}
                        label={"zone"}
                        {...restField}
                        rules={[
                          {
                            required: true,
                            message: "Please enter value",
                          },
                        ]}>
                        <Input
                          placeholder="Enter CP name"
                          className="input-field-style"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={md} xl={xl} sm={sm} lg={lg}>
                      <Form.Item
                        name={[name, "Rate card type"]}
                        label={"Rate card type"}
                        {...restField}
                        rules={[
                          {
                            required: true,
                            message: "Please enter value",
                          },
                        ]}>
                        <Input
                          placeholder="Enter CP name"
                          className="input-field-style"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={md} xl={xl} sm={sm} lg={lg}>
                      <Form.Item
                        name={[name, "Billing cyle"]}
                        label={"Billing cyle"}
                        {...restField}
                        rules={[
                          {
                            required: true,
                            message: "Please enter value",
                          },
                        ]}>
                        <Input
                          placeholder="Enter CP name"
                          className="input-field-style"
                        />
                      </Form.Item>
                    </Col>

                    {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                  </Row>
                  <HeaderWithBottomLine text={"Address Details"} icon={false} />

                  <Row gutter={12} style={{ paddingBottom: "20px" }}>
                    <Col md={md} xl={xl} sm={sm} lg={lg}>
                      <Form.Item
                        name={[name, "ResidentialAddressLine1"]}
                        label={"Address line 1"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter address",
                          },
                          () => ({
                            validator(rule, value = "") {
                              if (value.substring(1, 0) === " ") {
                                return Promise.reject("Invalid address line 1");
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}>
                        <Input
                          placeholder="Enter address"
                          type="text"
                          className="input-field-style"

                          // onChange={handlerChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={md} xl={xl} sm={sm} lg={lg}>
                      <Form.Item
                        name={[name, "ResidentialAddressLine2"]}
                        label={"Address line 2"}
                        rules={[
                          () => ({
                            validator(rule, value = "") {
                              if (value.substring(1, 0) === " ") {
                                return Promise.reject("Invalid address line 2");
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}>
                        <Input
                          placeholder="Enter address"
                          type="text"
                          className="input-field-style"

                          // onChange={handlerChange2}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={md} xl={xl} sm={sm} lg={lg}>
                      <Form.Item
                        name={[name, "PINCode"]}
                        label={"PIN code"}
                        // validateStatus={apiError?.validateStatus}
                        // help={apiError?.errorMsg}
                        rules={[
                          {
                            required: true,
                            message: "Please enter pincode",
                          },
                          () => ({
                            validator(rule, value = "") {
                              if (value.length < 0) {
                                return Promise.reject(
                                  "Please enter a valid PIN code"
                                );
                              } else if (value.length > 0 && value.length < 6) {
                                return Promise.reject(
                                  "Please enter 6 digits PIN code"
                                );
                              } else if (
                                parseInt(value.substring(1, 0)) === 0
                              ) {
                                return Promise.reject(
                                  "PIN number should not be 0"
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                            validateTrigger: "onSubmit",
                          }),
                        ]}
                        onKeyPress={(event) => {
                          if (
                            checkNumbervalue(event) ||
                            checkSpecialCharacter(event) ||
                            checkSpace(event)
                          ) {
                            event.preventDefault();
                          }
                        }}>
                        <Input
                          placeholder="Enter pincode"
                          type="text"
                          maxLength={6}
                          className="input-field-style"
                          onChange={(e) => getPinCodeDetails(e, index)}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={md} xl={xl} sm={sm} lg={lg}>
                      <Form.Item name={[name, "city"]} label={"City"}>
                        <Input
                          placeholder="City name"
                          type="text"
                          maxLength={20}
                          disabled={true}
                          className="input-field-style"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={md} xl={xl} sm={sm} lg={lg}>
                      <Form.Item name={[name, "state"]} label={"State"}>
                        <Input
                          placeholder="State name"
                          type="text"
                          maxLength={20}
                          disabled={true}
                          className="input-field-style"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={md} xl={xl} sm={sm} lg={lg}>
                      <Form.Item name={[name, "StateCode"]} label={"State"}>
                        <Input
                          placeholder="State name"
                          type="text"
                          maxLength={20}
                          disabled={true}
                          className="input-field-style"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <HeaderWithBottomLine
                    text={"Branch Admin Details"}
                    icon={false}
                    style={{ paddingTop: "30px" }}
                  />
                  <Row gutter={12}>
                    <Col md={md} xl={xl} sm={sm} lg={lg}>
                      <Form.Item name={[name, "state"]} label={"Name"}>
                        <Input
                          placeholder="State name"
                          type="text"
                          className="input-field-style"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={md} xl={xl} sm={sm} lg={lg}>
                      <Form.Item
                        name={[name, "emailId"]}
                        label={"Email ID"}
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Please enter E-mail!",
                          },
                        ]}>
                        <Input
                          placeholder="Enter email ID"
                          type="text"
                          className="input-field-style"
                          onInput={(e) =>
                            (e.target.value = e.target.value.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col md={md} xl={xl} sm={sm} lg={lg}>
                      <Form.Item
                        name={[name, "AlternateContacNo"]}
                        label={"Phone Number"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter mobile number",
                          },
                          {
                            pattern: /^([1-9]{1}[0-9]{9}){0,10}$/g,
                            message: "Invalid mobile number",
                          },
                        ]}
                        onKeyPress={(event) => {
                          if (
                            checkNumbervalue(event) ||
                            checkSpecialCharacter(event) ||
                            checkSpace(event)
                          ) {
                            event.preventDefault();
                          }
                        }}>
                        <Input
                          placeholder="Enter Contact no"
                          maxLength={10}
                          type="text"
                          className="input-field-style"

                          // onChange={(e) => {
                          //   if (e.target.value.length === 10) {
                          //     checkPhnNumExists(e.target.value);
                          //   } else {
                          //     setShowVerify(false);
                          //   }
                          // }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={md} xl={xl} sm={sm} lg={lg}></Col>
                  </Row>
                </div>
              ))}
            </>
          )}
        </Form.List>
        {/* <HeaderWithBottomLine text={"Address Details"} icon={false} /> */}

        {/* <Row gutter={12} style={{ paddingBottom: "20px" }}>
          <Col md={md} xl={xl} sm={sm} lg={lg}>
            <Form.Item
              name="ResidentialAddressLine1"
              label={"Address line 1"}
              rules={[
                {
                  required: true,
                  message: "Please enter address",
                },
                () => ({
                  validator(rule, value = "") {
                    if (value.substring(1, 0) === " ") {
                      return Promise.reject("Invalid address line 1");
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input
                placeholder="Enter address"
                type="text"
                className="input-field-style"

                // onChange={handlerChange}
              />
            </Form.Item>
          </Col>
          <Col md={md} xl={xl} sm={sm} lg={lg}>
            <Form.Item
              name="ResidentialAddressLine2"
              label={"Address line 2"}
              rules={[
                () => ({
                  validator(rule, value = "") {
                    if (value.substring(1, 0) === " ") {
                      return Promise.reject("Invalid address line 2");
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input
                placeholder="Enter address"
                type="text"
                className="input-field-style"

                // onChange={handlerChange2}
              />
            </Form.Item>
          </Col>
          <Col md={md} xl={xl} sm={sm} lg={lg}>
            <Form.Item
              name="PINCode"
              label={"PIN code"}
              // validateStatus={apiError?.validateStatus}
              // help={apiError?.errorMsg}
              rules={[
                {
                  required: true,
                  message: "Please enter pincode",
                },
                () => ({
                  validator(rule, value = "") {
                    if (value.length < 0) {
                      return Promise.reject("Please enter a valid PIN code");
                    } else if (value.length > 0 && value.length < 6) {
                      return Promise.reject("Please enter 6 digits PIN code");
                    } else if (parseInt(value.substring(1, 0)) === 0) {
                      return Promise.reject("PIN number should not be 0");
                    } else {
                      return Promise.resolve();
                    }
                  },
                  validateTrigger: "onSubmit",
                }),
              ]}
              onKeyPress={(event) => {
                if (
                  checkNumbervalue(event) ||
                  checkSpecialCharacter(event) ||
                  checkSpace(event)
                ) {
                  event.preventDefault();
                }
              }}
            >
              <Input
                placeholder="Enter pincode"
                type="text"
                maxLength={6}
                className="input-field-style"

                // onChange={(e) => getPinCodeDetails(e, "PINCode")}
              />
            </Form.Item>
          </Col>
          <Col md={md} xl={xl} sm={sm} lg={lg}>
            <Form.Item name="City" label={"City"}>
              <Input
                placeholder="City name"
                type="text"
                maxLength={20}
                disabled={true}
                className="input-field-style"
              />
            </Form.Item>
          </Col>
          <Col md={md} xl={xl} sm={sm} lg={lg}>
            <Form.Item name="State" label={"State"}>
              <Input
                placeholder="State name"
                type="text"
                maxLength={20}
                disabled={true}
                className="input-field-style"
              />
            </Form.Item>
          </Col>
          <Col md={md} xl={xl} sm={sm} lg={lg}>
            <Form.Item name="State Code" label={"State"}>
              <Input
                placeholder="State name"
                type="text"
                maxLength={20}
                disabled={true}
                className="input-field-style"
              />
            </Form.Item>
          </Col>
        </Row> */}
        {/* <HeaderWithBottomLine
          text={"Branch Admin Details"}
          icon={false}
          style={{ paddingTop: "30px" }}
        /> */}
        {/* <Row gutter={12}>
          <Col md={md} xl={xl} sm={sm} lg={lg}>
            <Form.Item name="State" label={"Name"}>
              <Input
                placeholder="State name"
                type="text"
                className="input-field-style"
              />
            </Form.Item>
          </Col>
          <Col md={md} xl={xl} sm={sm} lg={lg}>
            <Form.Item
              name="EmailId"
              label={"Email ID"}
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please enter E-mail!",
                },
              ]}
            >
              <Input
                placeholder="Enter email ID"
                type="text"
                className="input-field-style"
                onInput={(e) => (e.target.value = e.target.value.toLowerCase())}
              />
            </Form.Item>
          </Col>
          <Col md={md} xl={xl} sm={sm} lg={lg}>
            <Form.Item
              name="AlternateContacNo"
              label={"Phone Number"}
              rules={[
                {
                  required: true,
                  message: "Please enter mobile number",
                },
                {
                  pattern: /^([1-9]{1}[0-9]{9}){0,10}$/g,
                  message: "Invalid mobile number",
                },
              ]}
              onKeyPress={(event) => {
                if (
                  checkNumbervalue(event) ||
                  checkSpecialCharacter(event) ||
                  checkSpace(event)
                ) {
                  event.preventDefault();
                }
              }}
            >
              <Input
                placeholder="Enter Contact no"
                maxLength={10}
                type="text"
                className="input-field-style"

                // onChange={(e) => {
                //   if (e.target.value.length === 10) {
                //     checkPhnNumExists(e.target.value);
                //   } else {
                //     setShowVerify(false);
                //   }
                // }}
              />
            </Form.Item>
          </Col>
          <Col md={md} xl={xl} sm={sm} lg={lg}></Col>
        </Row> */}
        <hr
          className="divider"
          style={{ marginTop: "20px", marginBottom: "30px" }}
        />
        <Row gutter={12} justify="end">
          <Col>
            <Button className="save-btn" onClick={goBackStep}>
              CANCEL
            </Button>
          </Col>
          <Col>
            <Button className="save-btn" onClick={handleStepsChange}>
              SAVE
            </Button>
          </Col>
          <Col>
            <Form.Item>
              <Button className="save-btn" htmlType="submit">
                SAVE & CONTINUE
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default NewMiscellaneous;
