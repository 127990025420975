/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button, Space, Table, message, Modal, Popconfirm } from "antd";
import { SyncOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router-dom";
// import Map from "../Map";
import MasterModal from "../../../../components/Master/Modal";
import UploadDrawer from "../../PincodeMaster/uploadDrawer";
import AddNewRoute from "./AddNewRoute";
import upload_pin_icon from "../../../../assests/images/SVG/upload-pin-icon.svg";
import download_pin_icon from "../../../../assests/images/SVG/download-pin.svg";
import print_logo from "../../../../assests/images/SVG/master-print.svg";
import "../../PacketingGuide/style.scss";
import axios from "axios";
import { APIs, getPremiseId } from "../../../../utils/worker";
import NormalAPI from "../../../../api";
import urls from "../../../../api/urls";
import { capitalize, capitaliseWithHyphen } from "../../../../utils";
import constants from "../../../../constants/constants";
import { ReactComponent as Disable_icon } from "../../../../assests/images/SVG/disable.svg";
const {
  CREATE_ROUTE,
  GET_ALL_ROUTE,
  GET_ALL_V_ROUTE,
  CREATE_V_ROUTE,
  DELETE_V_ROUTE,
} = urls;
const { ROLES } = constants;
const { HO_ADMIN, HUB_ADMIN } = ROLES;

const RouteTable = ({ primaryMode }) => {
  //   const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [userToken, setUserToken] = useState("");
  const [scale, setScale] = useState(376);
  const [height, setHeight] = useState(220);
  const [width, setWidth] = useState("95%");
  const [tableDataSource, setDataSource] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [mapDataParent, setMapDataParent] = useState([]);
  const [totalPageSize, setTotalPageSize] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMapModalVisible, setIsMapModalVisible] = useState(false);
  const [roles, setRoles] = useState();
  const url = "/route-service/v1/upload/master-data";
  const sampleUrl = "/route-service/v1/sample/" + primaryMode;

  useEffect(() => {
    setRoles(JSON.parse(localStorage.getItem("userinfo")));
  }, []);

  const tableLoading = {
    spinning: isTableLoading,
    indicator: <SyncOutlined spin />,
  };
  const actionColumn =
    roles && roles?.roles?.includes(HO_ADMIN)
      ? {}
      : {
          title: "Action",
          key: "operation",
          fixed: "right",
          width: 100,
          render: (_, data) => (
            <Popconfirm
              title="Disable route"
              description="Are you sure to disable this route?"
              onConfirm={() => deleteRoute(data)}
              okText="Yes"
              cancelText="No"
              disabled={data?.active ? false : true}
            >
              {" "}
              <Disable_icon
                style={{ cursor: data?.active ? "pointer" : "not-allowed" }}
              />{" "}
            </Popconfirm>
          ),
        };
  const columns = [
    {
      title: "Origin Hub",
      dataIndex: "origin",
      key: "origin",
      render: (text) => <span>{capitaliseWithHyphen(text)}</span>,
    },
    {
      title: "Destination Hub",
      dataIndex: "destination",
      key: "destination",
      render: (text) => <span>{capitaliseWithHyphen(text)}</span>,
    },
    {
      title: "Transit 1",
      dataIndex: "transit1",
      key: "transit1",
    },
    {
      title: "Transit 2",
      dataIndex: "transit2",
      key: "transit2",
    },
    {
      title: "Transit 3",
      dataIndex: "transit3",
      key: "transit3",
    },
    {
      title: "Transit 4",
      dataIndex: "transit4",
      key: "transit4",
    },
    {
      title: "Transit 5",
      dataIndex: "transit5",
      key: "transit5",
    },
    {
      title: "Transit 6",
      dataIndex: "transit6",
      key: "transit6",
    },
    {
      title: "Transit 7",
      dataIndex: "transit7",
      key: "transit7",
    },
    actionColumn,
  ];

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userinfo"));
    setUserToken(userData.accessToken);
    getRouteList("?pageNum=0&pageSize=10");
  }, []);

  const getRouteList = async (page) => {
    setIsTableLoading(true);
    try {
      let response = null;
      // console.log(
      //   JSON.parse(localStorage.getItem("userinfo"))?.roles?.includes(HO_ADMIN)
      // );
      // console.log(roles && roles?.roles?.includes(HO_ADMIN));
      if (
        JSON.parse(localStorage.getItem("userinfo"))?.roles?.includes(HO_ADMIN)
      ) {
        response = await NormalAPI.Api(
          GET_ALL_ROUTE + primaryMode + page
        ).getWithHeader();
      } else {
        response = await NormalAPI.Api(
          GET_ALL_V_ROUTE + primaryMode + page
        ).getWithUserNPremiseHeader();
      }

      // let response = await axios.get(
      //   APIs.baseURL + "/route-service/v1/routes/" + primaryMode + page,
      //   {
      //     headers: {
      //       Authorization: "Bearer " + token,
      //       token: token,
      //     },
      //   }
      // );
      let temp = [];
      setTotalPageSize(response?.response?.data?.totalCounts);
      response?.response?.data?.data.map((item) => {
        if (item.route.length > 0) {
          temp.push({
            id: item.routeId,
            active: item?.active,
            origin: capitalize(item.route[0].hubName),
            destination: capitalize(item.route[item.route.length - 1].hubName),
            transit1:
              item.route.length > 2
                ? capitalize(item.route[1].hubName)
                : "------",
            transit2:
              item.route.length > 3
                ? capitalize(item.route[2].hubName)
                : "------",
            transit3:
              item.route.length > 4
                ? capitalize(item.route[3].hubName)
                : "------",
            transit4:
              item.route.length > 5
                ? capitalize(item.route[4].hubName)
                : "------",
            transit5:
              item.route.length > 6
                ? capitalize(item.route[5].hubName)
                : "------",
            transit6:
              item.route.length > 7
                ? capitalize(item.route[6].hubName)
                : "------",
            transit7:
              item.route.length > 8
                ? capitalize(item.route[7].hubName)
                : "------",
          });
        }
      });
      setDataSource(temp);
      setMapData(response?.response?.data?.data);
      setMapDataParent(response?.response?.data?.data);
      setIsTableLoading(false);
    } catch (error) {
      message.error(error.response?.data?.message);
      setIsTableLoading(false);
    }
  };

  const deleteRoute = async (data) => {
    let response = await NormalAPI.Api(
      DELETE_V_ROUTE + data?.id
    ).deleteIdHeader();
    if (response?.status) {
      message.success("Disabled Successfully");
      getRouteList("?pageNum=0&pageSize=10");
    }
  };

  const handlePageChange = (pagination) => {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
    setCurrentPage(pagination.current);
    getRouteList(
      `?pageNum=${pagination.current - 1}&pageSize=${pagination.pageSize}`
    );
  };
  //need this code
  // const handlePageChange = () => {
  //   window.scrollTo(0, 0);
  // };

  // const itemRender = (_, type, originalElement) => {
  //   if (type === "prev") {
  //     return <a>Previous</a>;
  //   }
  //   if (type === "next") {
  //     return <a>Next</a>;
  //   }
  //   return <span>{originalElement}</span>;
  // };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCreateRoute = async (values, formData, destinationTime) => {
    setLoading(true);
    let tempTransit = [];
    if (formData.length > 0) {
      formData.map((item, index) => {
        tempTransit.push({
          premise: formData[index].hub,
          travelBy:
            index === formData.length - 1
              ? values.destinationTransitMode
              : formData[index + 1].mode,
          transitTime:
            index === formData.length - 1
              ? destinationTime * 60
              : formData[index + 1].time * 60,
          distance:
            index === formData.length - 1
              ? +values.destinationTransitDistance
                  .split(" ")[0]
                  .split(",")
                  .join("")
              : +formData[index + 1].distance.split(" ")[0].split(",").join(""),
        });
      });
    }
    let routeData = [
      {
        premise: values.originHub,
        travelBy:
          formData.length === 0
            ? values.destinationTransitMode
            : formData[0].mode,
        transitTime:
          formData.length === 0 ? destinationTime * 60 : formData[0].time * 60,
        distance:
          formData.length === 0
            ? +values.destinationTransitDistance
                .split(" ")[0]
                .split(",")
                .join("")
            : +formData[0].distance.split(" ")[0].split(",").join(""),
      },
      ...tempTransit,
      {
        premise: values.destinationHub,
      },
    ];
    let checkAir = routeData.some((e) => e.travelBy === "AIR");
    let routeKey =
      roles && roles?.roles?.includes(HO_ADMIN) ? "route" : "route";
    let payload = {
      name: values.originHub + "-" + values.destinationHub,
      routeType: values.primaryMode,
      [routeKey]: routeData,
    };
    if (primaryMode === "AIR") {
      if (checkAir) {
        submitData(payload, values.originHub, values.destinationHub);
      } else {
        message.error("Please select atleast one mode as AIR");
        setLoading(false);
      }
    } else {
      submitData(payload, values.originHub, values.destinationHub);
    }
  };

  const submitData = async (payload, originHub, destinationHub) => {
    let response = null;
    if (roles && roles?.roles?.includes(HO_ADMIN)) {
      response = await NormalAPI.Api(
        CREATE_ROUTE + `${originHub}/to/${destinationHub}`
      ).postWithHeader(payload);
    } else {
      response = await NormalAPI.Api(CREATE_V_ROUTE).postIdHeader(payload);
    }
    if (response.status) {
      message.success(response?.data?.message || "Route Created Successfully");
      setLoading(false);
      setIsModalOpen(false);
      setCurrentPage(1);
      getRouteList("?pageNum=0&pageSize=10");
    } else {
      message.error(response.response.data.message);
      setLoading(false);
    }
  };

  const handleCallBack = () => {
    getRouteList("?pageNum=0&pageSize=10");
  };

  const goFull = (id) => {
    const target = document.getElementById("fullscreen-target-" + id);
    target.addEventListener("click", (e) => {
      setScale(550);
      setHeight(365);
      setWidth("calc(180% - 120px)");
      setIsMapModalVisible(true);
      // e.currentTarget?.requestFullscreen();
    });
  };
  const goClose = () => {
    document.webkitExitFullscreen();
    setScale(376);
    setHeight(220);
    setWidth("95%");
    setIsMapModalVisible(false);
  };

  const downloadAllRoute = () => {
    const premiseId = getPremiseId();
    const id = premiseId.split("Bearer ")[1];
    window.open(
      APIs.baseURL +
        `/vehicle-route-service/v1/download/${primaryMode}/premise/${id}`
    );
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const handleClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className="master-main-container" style={{ paddingBottom: "1rem" }}>
      <div
        className={
          roles && roles?.roles?.includes(HO_ADMIN)
            ? "master-main-container m-2"
            : ""
        }
        style={{ marginTop: "1rem" }}
      >
        <div className="flex-end p-1">
          <Button
            className="cancel-btn"
            onClick={() => setDrawerOpen(true)}
            disabled
          >
            <img src={upload_pin_icon} alt="upload button" />
            &nbsp;UPLOAD
          </Button>
          <Button className="cancel-btn ml-1" onClick={downloadAllRoute}>
            <img src={download_pin_icon} alt="download logo" />
            &nbsp;DOWNLOAD
          </Button>
          <Button
            className="save-btn ml-1"
            onClick={() => setIsModalOpen(true)}
          >
            CREATE NEW ROUTE
          </Button>
        </div>
        <Table
          className="table-head-sticky"
          columns={columns}
          dataSource={tableDataSource}
          loading={tableLoading}
          onRow={(record) => ({
            style: {
              background: record?.active === false ? "#E2E2E2" : "white",
            },
          })}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalPageSize,
            itemRender: itemRender,
          }}
          onChange={handlePageChange}
          scroll={{
            x: 1700,
          }}
        />
      </div>
      <MasterModal
        title="Create New Route"
        content={
          <AddNewRoute
            handleCreateRoute={handleCreateRoute}
            handleCloseModal={handleCloseModal}
            primaryMode={primaryMode}
          />
        }
        visible={isModalOpen}
        width={1100}
        handleCloseModal={handleCloseModal}
        handleCreateRoute={handleCreateRoute}
        loading={loading}
      />
      <UploadDrawer
        title="Upload Route Map"
        open={drawerOpen}
        onClose={handleClose}
        url={url}
        sampleUrl={sampleUrl}
        type={primaryMode === "AIR" ? "Route Air" : "Route Surface"}
        // docType={docType}
        handleCallBack={handleCallBack}
      />
      <Modal
        visible={isMapModalVisible}
        onCancel={goClose}
        footer={false}
        height={1000}
        width={1500}
        destroyOnClose
      ></Modal>
    </div>
  );
};

export default RouteTable;
