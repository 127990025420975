/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { message, Modal, Upload, Row, Col, Form, Input, Select } from "antd";
import axios from "axios";
import { StarOutlined } from "@ant-design/icons";
import { HeaderWithBottomLine } from "../../Master/CpOnbordingSteps/HeaderWithBottomLine";
import { getToken, APIs } from "../../../utils/worker";
import uploadLogo from "../../../assests/images/bx_upload.svg";
import {
  checkAlphabets,
  checkSpecialCharacter,
  firstLetterCapitaliseDropDown,
} from "../../../utils";

const UploadDocument = ({
  uploadDocumentCallback,
  setFileList,
  fileList,
  filesData,
  children,
  form,
  billremark = false,
}) => {
  const { userInfo } = JSON.parse(window?.localStorage?.getItem("userId"));
  const [previewImage, setPreviewImage] = useState("");
  const { Dragger } = Upload;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");

  const handleChange = (info) => {
    let allFiles = info?.fileList?.map((val) => val?.response?.data);

    if (info.file.status === "done") {
      message.success(info.file.response.message);
      uploadDocumentCallback(allFiles);
    }
    if (info.file.status === "removed") {
      uploadDocumentCallback(allFiles);

      localStorage.setItem("allFiles", JSON.stringify(allFiles));
    }
    if (info.file.status === "error") {
      message.error("File not Supported");
    }
    setFileList(info.fileList);
  };
  let authToken = getToken();

  const handleRemove = (file) => {
    const { confirm } = Modal;
    let config = {
      headers: {
        Authorization: authToken,
      },
    };
    return new Promise((resolve, reject) => {
      confirm({
        title: "Are you sure you want to Delete ?",
        onOk: async () => {
          resolve(true);
          try {
            let res = await axios.delete(
              `${APIs.baseURL}/file-service/v1/file/${file?.response?.data?.id}`,
              config
            );

            if (res) {
              message.success(res.data);
            }
          } catch (error) {
            // message.error(error.data + "world");
          }
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  };

  const beforeUpload = (file, onSuccess) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (file) {
      let checkSameFile = fileList
        ?.map((val) => val?.name)
        .some((val) => val === file.name);

      if (file.name.includes("jfif")) {
        message.error("File not supported");
        return Upload.LIST_IGNORE;
      }
      if (checkSameFile) {
        message.error("Same file cannot be uploaded again");
        return Upload.LIST_IGNORE;
      }
      if (!isLt2M) {
        message.error("File size should be less than 2MB");
        return Upload.LIST_IGNORE;
      }
      if (!isJpgOrPng) {
        message.error("File not supported");
        return Upload.LIST_IGNORE;
      }
      return isJpgOrPng && isLt2M;
    } else {
      if (onSuccess) {
        message.success("File uploaded");
      }
      onSuccess("ok");
      return true;
    }
  };

  //   const handlePreview = async (file) => {
  //     if (file.response.data.displayName.split(".")[1] == "pdf") {
  //       window.open(file.response.data.filePath);
  //     } else {
  //       let imagePath = filesData.filter(
  //         ({ id }) => file?.response?.data?.id === id
  //       )[0].filePath;
  //       setPreviewImage(imagePath);
  //         setPreviewOpen(true);
  //         setPreviewTitle(
  //           file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
  //         );
  //     }
  //   };

  const props = {
    name: "files",
    multiple: true,
    listType: "picture",
    showUploadList: true,
    action: `${APIs.baseURL}/file-service/v1/file`,
    headers: { Authorization: ` ${authToken}` },

    className: "upload-list-inline",

    onChange(info) {
      if (info.file.status === "done") {
        // Handle response from API
      }
    },

    removeIcon: <StarOutlined />,
  };

  const handlePreview = (file) => {
    if (file?.type == "application/pdf" || file?.name?.split(".")[1] == "pdf") {
      window.open(file?.url || file?.response?.data?.filePath);
    } else {
      // setPreviewImage(file?.thumbUrl || file?.url);
      setPreviewImage(file?.response?.data?.filePath || file?.thumbUrl);
      setPreviewOpen(true);
      setPreviewTitle(
        file?.name || file?.url?.substring(file?.url?.lastIndexOf("/") + 1)
      );
    }
  };

  const handleCancel = () => setPreviewOpen(false);

  return (
    <>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
      <HeaderWithBottomLine text={"Add-Ons"} icon={false} />
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
      <Row className="upload_and_dragger global-form-row" gutter={16}>
        <Col lg={billremark ? 8 : 18} md={6} style={{ paddingTop: "5px" }}>
          <label style={{ margin: "5px" }}> Upload Document</label>
          <div className="one-row">
            <Dragger
              {...props}
              className="dragger"
              onChange={handleChange}
              onRemove={handleRemove}
              maxCount={10}
              fileList={fileList}
              beforeUpload={beforeUpload}
              onPreview={handlePreview}
              multiple={false}
              headers={{
                "SMCS-PREMISE-ID": userInfo?.premiseId,
                awbNumber: 123456789982, //hardcoded
                Authorization: authToken,
              }}
            >
              <Row>
                <Col span={6}>
                  <img src={uploadLogo} className="Upload_logo" />
                </Col>
                <Col span={1} className="vline"></Col>
                <Col span={17}>
                  <p>Choose file</p>
                </Col>
              </Row>
            </Dragger>
          </div>
        </Col>
        {billremark ? (
          <>
            <Col lg={7} md={6}>
              <Form.Item label="Bill remark" name="billRemark">
                <Input
                  placeholder="Enter remark"
                  onKeyPress={(event) => {
                    if (event.code == "Enter") {
                      document.getElementById("submit_retail").blur();
                    }
                  }}
                  onChange={(e) =>
                    form.setFieldValue(
                      "billRemark",
                      firstLetterCapitaliseDropDown(e.target.value)
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col
              lg={9}
              md={6}
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                paddingBottom: "5px",
              }}
            >
              {children}
            </Col>
          </>
        ) : (
          <Col span={6} lg={6} md={6} className="mb-1 mt-1">
            {children}
          </Col>
        )}
      </Row>

      <ul style={{ marginLeft: "-19px", fontSize: "11px" }}>
        <li>
          Supported file type: PDF,JPEG, <br />
          PNG and Max 10MB file size
        </li>
      </ul>
    </>
  );
};

export default UploadDocument;
