import React from "react";

const TotalCount = ({ count }) => {
  return (
    <span
      className="fw-700 mr-1"
      style={{
        background: "#D9E7F9",
        // padding: count === "/consolidated-manifest" ? "3px 15px" : "10px 15px",
        padding: "3px 15px",

        borderRadius: "5px",
      }}
    >
      <span>
        <span
          style={{
            fontSize: "0.87rem",
          }}
        >
          <span
            style={{
              color: "#52AD50",
            }}
          >
            Count:
          </span>
          {count ? count : 0}
        </span>
      </span>
    </span>
  );
};
export default TotalCount;
