import React,{useState,useEffect} from "react";
import { Row, Col, Form, Input,Button,Spin,Tooltip,Select,Upload,message } from "antd";
import uploadLogo from "../../assests/images/bx_upload.svg";
import { getToken } from "../../utils/worker";
import API from "../../api";
import urls from "../../api/urls";
const {
  GET_BANK_DETAILS_BY_IFSC,
  VERIFY_GST,
  POST_PLACE_0F_BUSINESS,
  PUT_PLACE_0F_BUSINESS,
  GST_UPLOAD
} = urls;
import {
  checkSpecialCharacter,
  capitaliseWithHyphen,
  checkNumbervalue
} from "../../utils";

const PobForm = ({editData,getPobData,pobViewHandle}) => {
  const userInfo = JSON.parse(localStorage.getItem("userId"))?.userInfo;
  let authToken = getToken();
  const [form] = Form.useForm();
  const [loading,setLoading] = useState(false);
  const [gstData,setGstData] = useState({});
  const [fileList, setfileList] = useState([]);
  const [uploadResponse,setUploadResponse] = useState("");
  const { Dragger } = Upload;
  useEffect(()=>{
    if(editData.status === "edit"){
      let edit = editData?.data;
      form.setFieldsValue({...edit});
    }
    else{
      form.resetFields();
    }
  },[editData]);
  const submitForm = async(e)=>{
    setLoading(true);
    let payload = {
      gstNumber:e.gstNumber,
      state:e.state,
      legalName:e.legalName,
      tradeName:e.tradeName,
      addressLine1:e.addressLine1,
      addressLine2:e.addressLine2,
      pincode:e.pincode,
      city:e.city,
      country:e.country,
      gstCertification:uploadResponse,
      accountNumber:e.accountNumber,
      ifscCode:e.ifscCode,
      bankName:e.bankName,
      branchName:e.branchName,
      accountType:e.accountType,
      micrCode:e.micrCode
    };
    let response =editData?.status === "addPob" ? await API.Api(POST_PLACE_0F_BUSINESS).postIdHeader(payload):
      await API.Api(PUT_PLACE_0F_BUSINESS + editData?.data?.stateMasterId).putCustomIdHeader(payload);
    if(response?.status){
      setLoading(false);
      message.success("Successfully added");
      getPobData();
      if(editData?.status === "edit"){
        pobViewHandle(editData?.data?.stateMasterId);
      }
    }else{
      setLoading(false);
      message.error(response?.response?.data?.message);
      form.resetFields();
      setfileList([]);
      form.setFieldsValue({
        bankName: "",
        branchName: "",
      });
    }
  };
  const clearGstField = ()=>{
    form.setFieldsValue({
      // gstNumber:"",
      legalName: "",
      tradeName:"",
      addressLine1:"",
      addressLine2:"",
      pincode:"",
      state:"",
      city:"",
      country:"",
    });
  };
  const checkIFSC = async (values) => {
    if(values.target.value.length === 11){
      setLoading(true);
      try {
        let response = await API.Api(
          GET_BANK_DETAILS_BY_IFSC + `?ifscCode=${values?.target.value}`
        ).get();
        if (response?.status) {
          let data = response?.response?.data;
          form.setFieldsValue({
            bankName: capitaliseWithHyphen(data?.BANK) || null,
            branchName: capitaliseWithHyphen(data?.BRANCH) || null,
          });
          setLoading(false);
        } else {
          form.setFieldsValue({
            bankName: "",
            branchName: "",
          });
          setLoading(false);
          form.setFields([
            {
              name: "ifscCode",
              errors: ["Please give correct IFSC code"],
            },
          ]);
        }
      } 
      catch (error) {
        form.setFields([
          {
            name: "ifscCode",
            errors: ["Network error"],
          },
        ]);
      }
    }else{
      form.setFieldsValue({
        bankName: "",
        branchName: "",
      });
    }
  };
  const gstHandle = async(e)=>{
    if(e.target.value.length === 15){
      setLoading(true);
      try {
        let response = await API.Api(
          VERIFY_GST + e?.target.value
        ).get();
        if (response?.status) {
          let data = response?.response?.data?.result?.gstnDetailed;
          setGstData(data);
          form.setFieldsValue({
            legalName: capitaliseWithHyphen(data?.legalNameOfBusiness) || "",
            tradeName: capitaliseWithHyphen(data?.tradeNameOfBusiness) || "",
            addressLine1:capitaliseWithHyphen(data?.principalPlaceAddress) || "",
            addressLine2:capitaliseWithHyphen(data?.additionalPlaceAddress) || "",
            pincode:capitaliseWithHyphen(data?.principalPlacePincode) || "",
            state:capitaliseWithHyphen(findMaxLengthString(data?.principalPlaceSplitAddress?.state[0])) || "",
            city:capitaliseWithHyphen(findMaxLengthString(data?.principalPlaceSplitAddress?.city)) || "",
            country:capitaliseWithHyphen(findMaxLengthString(data?.principalPlaceSplitAddress?.country)) || "",
          });
          setLoading(false);
        } else {
          clearGstField();
          setLoading(false);
          form.setFields([
            {
              name: "gstNumber",
              errors: ["Please give correct GST number"],
            },
          ]);
        }
      } catch (error) {
        form.setFields([
          {
            name: "gstNumber",
            errors: ["Network error"],
          },
        ]);
      }}else{
      clearGstField();
    }
  };
  function findMaxLengthString(arr) {
    if (arr.length === 0) {
      return null; 
    }
  
    let maxLength = 0;
    let maxLengthString = "";
  
    for (let i = 0; i < arr.length; i++) {
      if (typeof arr[i] === "string" && arr[i].length > maxLength) {
        maxLength = arr[i].length;
        maxLengthString = arr[i];
      }
    }
  
    return maxLengthString;
  }
  // dragger
  const handleChange = (info) => {
    if (info?.file?.status === "done") {
      setUploadResponse(info?.file?.response);
      message.success(
        info?.file?.response?.message || "Document uploaded successully"
      );
    }
    if (info?.file?.status === "error") {
      setUploadResponse("");
      form.setFields([
        {
          name: "uploadGstCertificate",
          errors: ["File not Supported"],
        },
      ]);
    }
    setfileList(info?.fileList);
  };
  function handleRemoveFile() {
    setfileList([]);
    message.success("File removed successfully");
  }
  const beforeUpload = (file, onSuccess) => {
    const isJpgOrPng =
      file.type === "application/pdf";
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (file) {
      let checkSameFile = fileList
        ?.map((val) => val?.name)
        .some((val) => val === file?.name);

      if (file?.name?.includes("jfif")) {
        form.setFields([
          {
            name: "uploadGstCertificate",
            errors: ["File not supported"],
          },
        ]);
        return Upload.LIST_IGNORE;
      }
      if (checkSameFile) {
        form.setFields([
          {
            name: "uploadGstCertificate",
            errors: ["Same file cannot be uploaded again"],
          },
        ]);
        return Upload.LIST_IGNORE;
      }
      if (!isLt2M) {
        form.setFields([
          {
            name: "uploadGstCertificate",
            errors: ["File size should be less than 10MB"],
          },
        ]);
        return Upload.LIST_IGNORE;
      }
      if (!isJpgOrPng) {
        form.setFields([
          {
            name: "uploadGstCertificate",
            errors: ["File not supported"],
          },
        ]);
        return Upload.LIST_IGNORE;
      }
      return isJpgOrPng && isLt2M;
    } else {
      if (onSuccess) {
        message.success("File uploaded");
      }
      onSuccess("ok");
      return true;
    }
  };
  const fileProps = {
    name: "files",
    multiple: true,
    listType: "picture",
    showUploadList: true,
    action:GST_UPLOAD,
    fileList: fileList,
  };
  return (
    
    <Col span={19}>
      <h3 className="ml-2 mr-2 mt-1 mb-2 form-header">
             Add Place of Business Details  
      </h3>
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          form={form}
          onFinish={submitForm}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
        >
          <Row gutter={24} className="pl-2 pr-2 pb-2">
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="GST no"
                name="gstNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter GST No",
                  },
                  () => ({
                    validator(rule, value = "") {
                      if (value.length > 0 && value.length < 15) {
                        return Promise.reject("GST No should be 15 digits");
                      } else {
                        return Promise.resolve();
                      }
                    },
                    // validateTrigger: "onSubmit",
                  }),
                ]}
                onInput={(e) =>
                  (e.target.value = e.target.value.toUpperCase())
                }
                onKeyPress={(event) => {
                  if ( checkSpecialCharacter(event)) {
                    event.preventDefault();
                  }
                }}
              >
                <Input className="input-field-style" placeholder="Enter GST No" onChange={gstHandle} maxLength={15}/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Legal name"
                name="legalName"
              >
                <Input disabled={true} className="input-field-style" placeholder="Enter legal name"/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Trade name"
                name="tradeName"
              >
                <Input disabled={true} className="input-field-style" placeholder="Enter trade name"/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Tooltip title={capitaliseWithHyphen(gstData?.principalPlaceAddress)}>
                <Form.Item
                  label="Address line 1"
                  name="addressLine1"
                >
                  <Input disabled={true} className="input-field-style" placeholder="Enter address line 1"/>
                </Form.Item>
              </Tooltip>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Tooltip title={capitaliseWithHyphen(gstData?.additionalPlaceAddress)}>
                <Form.Item
                  label="Address line 2"
                  name="addressLine2"
                >
                  <Input disabled={true} className="input-field-style" placeholder="Enter address line 2"/>
                </Form.Item>
              </Tooltip>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Pincode"
                name="pincode"
                rules={[
                  {
                    required: true,
                    message: "Please enter pincode",
                  },
                  () => ({
                    validator(rule, value = "") {
                      if (value.length > 0 && value.length < 6) {
                        return Promise.reject("Pincode should be 6 digits");
                      } else {
                        return Promise.resolve();
                      }
                    },
                    // validateTrigger: "onSubmit",
                  }),
                ]}
              >
                <Input disabled={true} className="input-field-style" placeholder="Enter pincode"/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="State"
                name="state"
              >
                <Input disabled={true} className="input-field-style" placeholder="Enter state"/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="City"
                name="city"
              >
                <Input disabled={true} className="input-field-style" placeholder="Enter city"/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Country"
                name="country"
              >
                <Input disabled={true} className="input-field-style" placeholder="Enter country"/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}></Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Upload GST certificate"
                name="uploadGstCertificate"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  () => ({
                    validator() {
                      if (fileList.length === 0) {
                        return Promise.reject("Please upload GST certificate");
                      } else {
                        return Promise.resolve();
                      }
                    },
                    // validateTrigger: "onSubmit",
                  }),
                ]}
              >
                <Dragger className="dragger-style"
                  {...fileProps}
                  onChange={handleChange}
                  onRemove={handleRemoveFile}
                  maxCount={1}
                  beforeUpload={beforeUpload}
                  multiple={false}
                  headers={{
                    "SMCS-PREMISE-ID": userInfo?.premiseId,
                    "USER-ID":userInfo?.userId,
                    Authorization: authToken,
                  }}
                >
                  <Row gutter={24}>
                    <Col span={8} style={{borderRight: "2px dashed #DFDFDF"}}>
                      <img src={uploadLogo} style={{width:"36px", height:"36px"}}/>
                    </Col>
                    <Col>
                      <p><b><u>Click to upload</u></b><span> or drag and drop</span></p>
                      <p className="ant-upload-hint">Maximum file size 5 MB</p>
                    </Col>
                  </Row>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
          <h3 className="ml-2 mr-2 mt-1 mb-2 form-header">
               Bank Details 
          </h3>
          <Row gutter={24} className="pl-2 pr-2 pb-2">
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Account number"
                name="accountNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter account number",
                  },
                  () => ({
                    validator(rule, value = "") {
                      if (value.length > 0 && value.length < 12) {
                        return Promise.reject("Account number should be 12 digits");
                      } else {
                        return Promise.resolve();
                      }
                    },
                    // validateTrigger: "onSubmit",
                  }),
                ]}
                onKeyPress={(event) => {
                  if (checkNumbervalue(event) || event.key === ".") {
                    event.preventDefault();
                  }
                }}
              >
                <Input className="input-field-style" placeholder="Enter account number" maxLength={12}/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="IFSC code"
                name="ifscCode"
                rules={[
                  {
                    required: true,
                    message: "Please enter IFSC code",
                  },
                  () => ({
                    validator(rule, value = "") {
                      if (value.length > 0 && value.length < 11) {
                        return Promise.reject("IFSC code should be 11 digits");
                      } else {
                        return Promise.resolve();
                      }
                    },
                    // validateTrigger: "onSubmit",
                  }),
                ]}
                onChange={checkIFSC}
                onInput={(e) =>
                  (e.target.value = e.target.value.toUpperCase())
                }
                onKeyPress={(event) => {
                  if ( checkSpecialCharacter(event)) {
                    event.preventDefault();
                  }
                }}
              >
                <Input 
                  className="input-field-style" 
                  placeholder="Enter IFSC code" 
                  maxLength={11}/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Bank name"
                name="bankName"
                rules={[
                  {
                    required: false,
                    message: "Please enter bank name",
                  },
                ]}
              >
                <Input disabled={true} className="input-field-style" placeholder="Enter bank name"/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Branch name"
                name="branchName"
                rules={[
                  {
                    required: false,
                    message: "Please enter branch name",
                  },
                ]}
              >
                <Input disabled={true} className="input-field-style" placeholder="Enter branch name"/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Account type"
                name="accountType"
                rules={[
                  {
                    required: true,
                    message: "Please select account type",
                  },
                ]}
              >
                <Select
                  className="input-field-style"
                  options={[
                    {
                      value: "current",
                      label: "Current",
                    },
                    {
                      value: "saving",
                      label: "Saving",
                    }
                  ]}
                  placeholder={"Please select an option"}
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="MICR code"
                name="micrCode"
                rules={[
                  {
                    required: true,
                    message: "Please enter MICR code",
                  },
                  () => ({
                    validator(rule, value = "") {
                      if (value.length > 0 && value.length < 9) {
                        return Promise.reject("MICR code should be 9 digits");
                      } else {
                        return Promise.resolve();
                      }
                    },
                    // validateTrigger: "onSubmit",
                  }),
                ]}
                onKeyPress={(event) => {
                  if (checkNumbervalue(event) || event.key === ".") {
                    event.preventDefault();
                  }
                }}
              >
                <Input className="input-field-style" placeholder="Enter MICR code" maxLength={9}/>
              </Form.Item>
            </Col>
            <hr className="divider" />
          </Row>
          <Row gutter={12} className="pl-2 pr-2 mb-2" justify={"end"}>
            <Col>
              <Button className="cancel-btn" onClick={()=> form.resetFields()}>CANCEL</Button>
            </Col>
            <Col>
              <Button className="save-btn" htmlType="submit">SAVE</Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Col>
  );
};

export default PobForm;
