/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  message,
  Radio,
  Select,
  Spin,
} from "antd";
import RateCardTable from "../../LeadGenerationCP/AllocateRateCard/RateCardTable";
import { cashBookingRateCardData } from "../../Master/RateCard/CashbookingRateCard/cashBookingRateCardData";
import { defaultRateCard } from "../../Master/RateCard/CashbookingRateCard/defaultRateCard";
import API from "../../../api";
import urls from "../../../api/urls";
import "./style.scss";
import rate_card_back from "../../../assests/images/onbording/rate-card-back.svg";
import Heading from "../../../components/Heading";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { getFinancialYearData } from "../../../utils";
// import {
//   fovFormInitialValues,
//   fovChargeRule,
// } from "../../LeadGenerationCP/AllocateRateCard/rateCardUtils";

import {
  getRatesDox,
  getRatesNonDoxSurface,
  getRatesNonDoxAir,
  standardTempFun,
  fastTrackTempFun,
  valuePlusTempFun,
  fovFormInitialValues,
  fovChargeRule,
} from "../../LeadGenerationCP/AllocateRateCard/rateCardUtils";
const {
  ADD_LEAD_CLIENT_RATE_CARD,
  GET_CLIENT_RATE_CARD,
  GET_QUOTE_ID,
  GET_DEFAUT_RATE_CARD,
  GET_ALL_RATECARDS,
  GET_SINGLE_RATECARD,
  GET_CLIENT_QUOTATION_DETAILS_BY_ID,
  UPDATE_CLIENT_RATE_CARD,
} = urls;

export const NewQuote = ({
  handleStepsChange = () => {},
  sideStepsHide = () => {},
  sideStepsShow = () => {},
  goBackStep = () => {},
  rateCardPresent,
  clientName,
}) => {
  const [form] = Form.useForm();
  const params = useParams();
  const navigate = useNavigate();

  const [showAddRateCard, setShowAddRateCard] = useState(false);
  const [standardDox, setStandardDox] = useState([]);
  const [standardNonDox, setStandardNonDox] = useState([]);
  const [fastTrackSurface, setFastTrackSurface] = useState([]);
  const [fastTrackAir, setFastTrackAir] = useState([]);
  const [fastTrackNonDox, setFastTrackNonDox] = useState([]);
  const [valuePlusDox, setValuePlusDox] = useState([]);
  const [valuePlusNonDox, setValuePlusNonDox] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quoteData, setQuoteData] = useState(null);
  const [financialYearData, setFinancialYearData] = useState([]);
  const [showTemplateDropdown, setShowTemplateDropdown] = useState(false);
  const [rateCardTemplatesData, setRateCardTemplatesData] = useState([]);

  const [financialYear, setFinancialYear] = useState(
    `${new Date().getFullYear() - 1}-${new Date().getFullYear()}`
  );

  let {
    standardDoxDatas,
    fastTrackSurfaceDatas,
    standardNonDoxDatas,
    fastTrackAirDatas,
    fastTrackNonDoxDatas,
    valuePlusDoxDatas,
    valuePlusNonDoxDatas,
  } = cashBookingRateCardData();

  useEffect(() => {
    setStandardDox(standardDoxDatas);
    setStandardNonDox(standardNonDoxDatas);
    setFastTrackSurface(fastTrackSurfaceDatas);
    setFastTrackAir(fastTrackAirDatas);
    setFastTrackNonDox(fastTrackNonDoxDatas);
    setValuePlusDox(valuePlusDoxDatas);
    setValuePlusNonDox(valuePlusNonDoxDatas);
    setFinancialYearData(getFinancialYearData());
    if (params?.id !== undefined) {
      getQuoteDetails();
    }
  }, []);

  const setWeightSlabData = (data, title) => {
    switch (title) {
      case "standardDocs":
        setStandardDox(data);
        break;
      case "standardNonDocs":
        setStandardNonDox(data);
        break;
      case "fastTrackSurface":
        setFastTrackSurface(data);
        break;
      case "fastTrackAir":
        setFastTrackAir(data);
        break;
      case "fastTrackNonDox":
        setFastTrackNonDox(data);
        break;
      case "valueplusDox":
        setValuePlusDox(data);
        break;
      case "valueplusNonDox":
        setValuePlusNonDox(data);
        break;
    }
  };

  const changeDynamicSelect = (title, data) => {
    switch (title) {
      case "standardNonDocs":
        setStandardNonDox(data);
        break;
      case "fastTrackNonDox":
        setFastTrackNonDox(data);
        break;
      case "valueplusNonDox":
        setValuePlusNonDox(data);
        break;
    }
  };

  const getDefaultRateCardData = async () => {
    setLoading(true);
    let response = await API.Api(GET_DEFAUT_RATE_CARD).getWithPremiseIdHeader();
    if (response?.status) {
      setLoading(false);

      handleTemplateResponse(response);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const getQuoteDetails = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_CLIENT_QUOTATION_DETAILS_BY_ID +
        `${params?.clientId}/quotation/${params?.id}`
    ).getWithPremiseIdHeader();
    if (response?.status) {
      setLoading(false);

      handleTemplateResponse(response);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const selectTemplate = (e) => {
    // form.setFieldValue("ratecardName", null);
    form.setFieldValue("quoteTemplateName", null);

    if (e.target.value === 1) {
      getDefaultRateCardData();
      setShowTemplateDropdown(false);
    } else {
      setShowTemplateDropdown(true);
      setStandardDox(standardDoxDatas);
      setStandardNonDox(standardNonDoxDatas);
      setFastTrackSurface(fastTrackSurfaceDatas);
      setFastTrackAir(fastTrackAirDatas);
      setFastTrackNonDox(fastTrackNonDoxDatas);
      setValuePlusDox(valuePlusDoxDatas);
      setValuePlusNonDox(valuePlusNonDoxDatas);
    }
  };

  const showSideStep = () => {
    sideStepsShow();
    setShowAddRateCard(false);
  };

  const createRateCard = async (values) => {
    const standardDoxData = getRatesDox(standardDox);
    const fastTrackSurfaceData = getRatesDox(fastTrackSurface);
    const fastTrackAirData = getRatesDox(fastTrackAir);
    const valuePlusDoxData = getRatesDox(valuePlusDox);
    const standardNonDoxSurfaceData = getRatesNonDoxSurface(standardNonDox);
    const standardNonDoxAirData = getRatesNonDoxAir(standardNonDox);
    const fastTrackNonDoxSurfaceData = getRatesNonDoxSurface(fastTrackNonDox);
    const fastTrackNonDoxAirData = getRatesNonDoxAir(fastTrackNonDox);
    const valuePlusNonDoxSurfaceData = getRatesNonDoxSurface(valuePlusNonDox);
    const valuePlusNonDoxAirData = getRatesNonDoxAir(valuePlusNonDox);
    delete values?.quoteTemplateName;

    const payload = {
      ...values,
      services: [
        {
          serviceType: "STANDARD",
          docs: [
            {
              docType: "DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: standardDoxData,
                },
                {
                  mode: "Air",
                  rates: standardDoxData,
                },
              ],
            },
            {
              docType: "NON_DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: standardNonDoxSurfaceData,
                },
                {
                  mode: "Air",
                  rates: standardNonDoxAirData,
                },
              ],
            },
          ],
        },
        {
          serviceType: "FAST TRACK",
          docs: [
            {
              docType: "DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: fastTrackSurfaceData,
                },
                {
                  mode: "Air",
                  rates: fastTrackAirData,
                },
              ],
            },
            {
              docType: "NON_DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: fastTrackNonDoxSurfaceData,
                },
                {
                  mode: "Air",
                  rates: fastTrackNonDoxAirData,
                },
              ],
            },
          ],
        },
        {
          serviceType: "VALUE PLUS",
          docs: [
            {
              docType: "DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: valuePlusDoxData,
                },
                {
                  mode: "Air",
                  rates: valuePlusDoxData,
                },
              ],
            },
            {
              docType: "NON_DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: valuePlusNonDoxSurfaceData,
                },
                {
                  mode: "Air",
                  rates: valuePlusNonDoxAirData,
                },
              ],
            },
          ],
        },
      ],
      billingConfigId: 2,

      clientName: quoteData?.organizationDetails?.clientName,
      businessVolume: quoteData?.businessDetails?.businessVolume,
      contactPersonName: quoteData?.businessDetails?.contactPersonName,
      gst: quoteData?.organizationDetails?.gst,
    };
    let response;
    if (params?.id === undefined) {
      response = await API.Api(
        ADD_LEAD_CLIENT_RATE_CARD + params?.clientId
      ).postIdHeader(payload);
    } else {
      response = await API.Api(
        UPDATE_CLIENT_RATE_CARD + params?.id
      ).putIdHeader(payload);
    }
    if (response.status) {
      message.success("Rate card added successfully.");
      navigate("/quotation-list/" + params?.clientId);
    } else {
      message.error(response.response.data.message);
    }
  };

  const checkNum = (e) => {
    if (/[^0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    getQuoteById(params?.clientId);
    // getAllRateCards();
  }, []);

  const getQuoteById = async (id) => {
    setLoading(true);
    let response = await API.Api(GET_QUOTE_ID + id).getWithPremiseIdHeader();
    if (response?.status) {
      setLoading(false);
      let temp = response?.response?.data;
      setQuoteData(temp);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  function handleTemplateResponse(response) {
    if (response) {
      let temp =
        response?.response.data?.data?.services ||
        response?.response.data?.services;

      let serviceTypes = {
        STANDARD: [],
        "FAST TRACK": [],
        "VALUE PLUS": [],
      };
      if (temp) {
        temp.forEach((e) => {
          const serviceType = e.serviceType.toUpperCase();
          serviceTypes[serviceType]?.push(e.docs);
        });
      }
      let standardTemp = serviceTypes.STANDARD;
      let fastTrackTemp = serviceTypes["FAST TRACK"];
      let valuePlusTemp = serviceTypes["VALUE PLUS"];

      if (standardTemp) {
        let { standardDoxTemp, standardNonDoxTemp } =
          standardTempFun(standardTemp);
        setStandardNonDox(standardNonDoxTemp);
        setStandardDox(standardDoxTemp);
      }
      if (fastTrackTemp) {
        let {
          fastTrackDoxSurfaceTemp,
          fastTrackDoxAirTemp,
          fastTrackNonDoxTemp,
        } = fastTrackTempFun(fastTrackTemp);
        setFastTrackSurface(fastTrackDoxSurfaceTemp);
        setFastTrackAir(fastTrackDoxAirTemp);
        setFastTrackNonDox(fastTrackNonDoxTemp);
      }
      if (valuePlusTemp) {
        let { valuePlusDoxTemp, valuePlusNonDoxTemp } =
          valuePlusTempFun(valuePlusTemp);
        setValuePlusDox(valuePlusDoxTemp);
        setValuePlusNonDox(valuePlusNonDoxTemp);
      }
    }
  }

  const selectExistingTemplateById = async (id) => {
    setLoading(true);
    let response = await API.Api(
      GET_SINGLE_RATECARD + id
    ).getWithPremiseIdHeader();
    if (response?.status) {
      setLoading(false);
      if (response) {
        handleTemplateResponse(response);
      }
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const getAllRateCards = async () => {
    setLoading(true);
    let response = await API.Api(GET_ALL_RATECARDS).getWithPremiseIdHeader();
    if (response?.status) {
      setLoading(false);
      let res = response?.response?.data?.data;
      if (res) {
        let temp = [];
        res?.map((e) => {
          temp.push({ label: e.ratecardName, value: e.id });
        });
        setRateCardTemplatesData(temp);
      }
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="new-quote-container">
        <Heading title={"New Quote"} />
        <div className="new-quote-form-container">
          <div>
            <span
              className="master-title"
              style={{ padding: "0.8rem 1rem 0.3rem 1rem", fontSize: "16px" }}>
              Lead details
            </span>
          </div>
          <hr className="divider" />
          <div>
            <Row className="space-between ml-2">
              <Col span={3}>
                <div>
                  <p>Client name</p>
                  <b>{quoteData?.organizationDetails?.clientName || ""}</b>
                </div>
              </Col>
              <Col span={3}>
                <div>
                  <p>Approx budget</p>
                  <b>{quoteData?.businessDetails?.businessVolume || ""}</b>
                </div>
              </Col>
              <Col span={3}>
                <div>
                  <p>Contact person name</p>
                  <b>{quoteData?.businessDetails?.contactPersonName || "NA"}</b>
                </div>
              </Col>
              <Col span={3}>
                <div>
                  <p>GSTIN</p>
                  <b>{quoteData?.organizationDetails?.gst || "N/A"}</b>
                </div>
              </Col>
              <Col span={8}></Col>
            </Row>
          </div>
          <div className="mt-1">
            <span
              className="master-title"
              style={{ padding: "0.8rem 1rem 0.3rem 1rem", fontSize: "16px" }}>
              Quote details
            </span>
          </div>
          <hr className="divider" />
          <Form
            className="global-form"
            layout="vertical"
            form={form}
            scrollToFirstError={{
              behavior: "smooth",
              block: "center",
              inline: "center",
            }}
            // initialValues={fovFormInitialValues}
            onFinish={createRateCard}>
            <Row style={{ padding: "1rem 1rem 0.5rem 1rem" }}>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
              <Col xs={22} sm={22} md={20} lg={18} xl={18}>
                <Row gutter={40} className="global-form-row">
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <Form.Item
                      name="ratecardName"
                      label="Quote name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter quote name",
                        },
                      ]}>
                      <Input placeholder="Enter quote name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    {/* <Form.Item
                    name="financialYear"
                    label="Financial year"
                    rules={[
                      {
                        required: true,
                        message: "Please select",
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item> */}
                    <Form.Item
                      name="financialYear"
                      label="Financial Year"
                      rules={[
                        {
                          required: true,
                          message: "Please select financial year",
                        },
                      ]}
                      initialValue={financialYear}>
                      <Select
                        placeholder="Select financial year"
                        options={financialYearData}
                        // defaultValue={financialYear}
                        onChange={(e) => setFinancialYear(e)}
                        // disabled={
                        //   templateType === "view" || templateType === "allocate"
                        //     ? true
                        //     : false
                        // }
                      />
                    </Form.Item>
                  </Col>
                  <hr className="divider" />

                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <Form.Item name="fov" label="FOV" rules={fovChargeRule}>
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <Form.Item
                      name="insurance"
                      label="Insurance"
                      rules={fovChargeRule}>
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <Form.Item
                      name="handlingCharges"
                      label="Handling charges"
                      rules={fovChargeRule}>
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <Form.Item
                      name="premiumServiceCharges"
                      label="Premium service charges"
                      rules={fovChargeRule}>
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <Form.Item
                      name="fuelServiceCharges"
                      label="Fuel service charges"
                      rules={fovChargeRule}>
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <Form.Item
                      name="codCharges"
                      label="COD charges"
                      rules={fovChargeRule}>
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                    <hr className="divider" />
                  </Col>

                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <div className="ml-1">Select</div>
                    <Radio.Group onChange={selectTemplate} className="p-05">
                      <Radio value={1}>
                        <span className="f-14">Default</span>
                      </Radio>
                      <Radio value={2} className="ml-1">
                        <span className="f-14">Existing quote</span>
                      </Radio>
                    </Radio.Group>
                  </Col>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    {showTemplateDropdown && (
                      <Form.Item
                        name="quoteTemplateName"
                        label="Select quote name"
                        rules={[
                          {
                            required: true,
                            message: "Please select template name",
                          },
                        ]}>
                        <Select
                          showSearch
                          placeholder="Template name"
                          options={rateCardTemplatesData}
                          // onSearch={onSearch}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          loading={loading}
                          onFocus={getAllRateCards}
                          onChange={selectExistingTemplateById}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col xs={1} sm={1} md={2} lg={3} xl={3}></Col>
            </Row>
            <div style={{ marginTop: "5px" }}>
              <RateCardTable
                standardDocsData={standardDox}
                standardNonDocsData={standardNonDox}
                fastTrackSurfaceData={fastTrackSurface}
                fastTrackAirData={fastTrackAir}
                fastTrackNonDoxData={fastTrackNonDox}
                valuePlusDoxData={valuePlusDox}
                valuePlusNonDoxData={valuePlusNonDox}
                handleChangeDocs={(e, data) => setWeightSlabData(e, data)}
                handleDynamicSelect={(data, title) =>
                  changeDynamicSelect(data, title)
                }
                templateType={"create"}
              />
            </div>

            <div className="d-flex flex-end p-1">
              <Button
                className="cancel-btn ml-1"
                onClick={() => navigate("/quotation-list/" + params?.clientId)}>
                BACK
              </Button>

              <Button
                htmlType="submit"
                className="save-btn ml-1"
                //onClick={handleStepsChange}
              >
                SAVE
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default NewQuote;
