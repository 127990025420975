import React,{useState,useEffect} from "react";
import { useNavigate,useLocation } from "react-router";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {Table,Tag,Space, Button,Modal,Row,Col,Spin, message} from "antd";
import back_img from "../../../assests/images/rightVector.svg";
import locationLogo from "../../../assests/images/LocationIconFomManifest.svg";
import { serialNum } from "../../../utils";
import urls from "../../../api/urls";
import API from "../../../api";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import { capitaliseWithHyphen } from "../../../utils";
import {getProfileDetails} from "../../../actionsMethods/masterMethods";




const DrsDetails = () => {
  const dispatch = useDispatch();
  const { GET_SINGLE_DRS,GET_POD} = urls;
  const navigate = useNavigate(); 
  const history = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading,setLoading] = useState(false);
  const [podDetails,setPodDetails] = useState("");
  const [drsList,setDrsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  let userInfo = JSON.parse(localStorage.getItem("userId"));
  let userEmailId = JSON.parse(localStorage.getItem("userinfo"))?.username;
  const profileDetails = useSelector(
    (state) => state.masterReducer.getProfileDetailsReducer,
    shallowEqual
  );
  const { result: profileDetailsResult } = profileDetails;
  useEffect(()=>{
    drsListFun(history.state);
  },[history]);
  useEffect(()=>{
    dispatch(getProfileDetails(userEmailId));
  },[]);
  const showModal = (e) => {
    setIsModalOpen(true);
    getPod(e.awbNumber);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "Sl No",
      align: "center",
      with: 10,
      render: (a, b, idx) => serialNum(10, currentPage, idx),
    },
    {
      title: "AWB Number",
      dataIndex: "awbNumber",
      key: "awbNumber",
      render: (text,record) => <span>{record?.childDetails?.childAwbNumber || record?.awbNumber}</span>,
    },
    {
      title: "Booking CP Details",
      dataIndex: "",
      key: "",
      render: (text) => <>
        <div>{capitaliseWithHyphen(text?.fromCpName)}</div>
        <div>{text?.fromCpMobileNum}</div>
      </>,
    },
    {
      title: "Receiver's Details",
      dataIndex: "addressDetails",
      key: "addressDetails",
      render: (text,record) => {
        return(
          <>
            <div>{record?.addressDetails?.receiverName || record?.childDetails?.addressDetails?.receiverName}</div>
            <div>{record?.addressDetails?.receiverMobileNumber || record?.childDetails?.addressDetails?.receiverMobileNumber}</div>
          </>
        );
      },
    },
    {
      title: "Status",
      key: "shipmentDetails",
      dataIndex: "shipmentDetails",
      render: (a,record) => {  
        let text = record?.childDetails?.bookingStatus || record?.shipmentDetails?.bookingStatus;
        let color="";
        let status= "";
        if (text === "DRS_GENERATED"){
          color = "#2E77D0";
          status= "DRS Generated";
        }else if (text === "DELIVERED"){
          color = "#149D11";
          status= "Delivered";
        }
        else if (text === "DELIVERY_FAILED"){
          color = "#F66C6C";
          status= "Delivery failed";
        }
        else if (text === "OUT_FOR_DELIVERY"){
          color = "#3DD63A";
          status= "Out for delivery";
        }
        else if (text === "HUB-IN-SCANNED"){
          color = "#3DD63B";
          status= "Hub in scanned";
        }
        return (
          <Tag color={color} key={text}>
            {status}
          </Tag>
        );
      }
    },
    {
      title: "Action",
      key: "action",
      render: (text,record) => {
        let status = record?.childDetails?.bookingStatus || record?.shipmentDetails?.bookingStatus;
        return(
          <Space size="middle">
            <Button className="save-btn" onClick={()=>showModal(record)} disabled={status?.toUpperCase() === "DELIVERY_FAILED"}>
          VIEW POD
            </Button>
          </Space>);
      },
    },
   
  ];
  // const data = [
  //   {
  //     key: "1",
  //     name: "John Brown",
  //     age: 32,
  //     address: "New York No. 1 Lake Park",
  //     tags: ["Delivered"],
  //     drsNumber:123123123,
  //     action:23,
  //     img:back_img
  //   },
  //   {
  //     key: "2",
  //     name: "Jim Green",
  //     age: 42,
  //     address: "London No. 1 Lake Park",
  //     tags: ["Failed to deliver"],
  //     drsNumber:123123123,
  //     action:25,
  //     img:dummy_sign
  //   },];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  let drsListFun = async(drsNumber)=>{ 
    setLoading(true);
    let response = await API.Api(
      GET_SINGLE_DRS+drsNumber
    ).getWithPremiseIdHeader();
    if(response?.status){
      setDrsList(response?.response?.data);
      setLoading(false);
    }else{
      message.error(response?.response?.data?.message);
      setLoading(false);
    }
  };
  let getPod = async(drsNumber)=>{ 
    setLoading(true);
    let response = await API.Api(
      GET_POD+drsNumber
    ).getWithPremiseIdHeader();
    if(response?.status){
      setPodDetails(response?.response?.data);
      setLoading(false);
    }else{
      setLoading(false);
    }
  };
  const paginationHandle = (pagination) => {
    setCurrentPage(pagination.current);
  };
  return (
    <Spin spinning={loading}
      size="large"
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="drs-detial-container p-2">
        <div className="heading mb-1 align-center">
          <img
            src={back_img}
            onClick={() => navigate("/generate-drs",{state:"DRS_DETAILS"})}
            style={{ cursor: "pointer" }}
          />
          <p
            style={{
              margin: "0",
              paddingLeft: "5px",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "32px",
              letterSpacing: "-0.25px",
              color: "#061624",
            }}
          >
            <b>DRS Generation</b>
          </p>
        </div>
        <Row className="p-2" style={{background:"#FFFFFF"}}>
          <Col span={8} className="flex-column">
            <span><img src={locationLogo} alt="Image" style={{width: "12px",
              height: "13.65px"}}></img> <span><b>{userInfo?.premises?.premiseName}</b></span></span>
            <div>{capitaliseWithHyphen(profileDetailsResult?.data?.hubAddress)}</div>
          </Col>
          <Col span={8}></Col>
          <Col span={8} className="flex-column">
            <span><b>DRS ID :</b> {drsList?.drsId}</span>
            <span><b>Date : </b>{drsList?.createDate}</span>
            <span><b>Delivery boy : </b>{drsList?.deliveryBoyName}</span>
            <span><b>Area :</b>{drsList?.areaDtos?.map((data)=>`${capitaliseWithHyphen(data?.areaName)}, `)}</span>
          </Col>
        </Row>
        <Table 
          columns={columns} 
          dataSource={drsList?.shipments}  
          pagination={{
            itemRender: itemRender,
          }}
          onChange={paginationHandle}
        />
          
      </div>
      <Modal 
        title="POD" 
        open={isModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        footer={null}
        width={600}
      >{podDetails ? 
          <div className="p-05">
            <img src={podDetails?.fileName} style={{width: "100%",height: "99.67px"}} />
            <hr className="divider" style={{marginTop: "1.2rem"}}/>
            <div className="receiver-box">
              <p className="fw-600">Receiver Details</p>
              <div className="space-between mt-1">
                <p>Name : <span className="fw-500">{podDetails?.receiverName || "N/A"}</span> </p>
                <p>Phone Number : <span className="fw-500">{podDetails?.receiverPhoneNumber || "N/A"}</span> </p>
              </div>
              <p style={{marginTop: "-0.5rem"}}>LAT-LNG : &nbsp;
                <a 
                  target="_blank" 
                  href={`https://www.google.com/maps/search/?api=1&query=${podDetails?.latitude},${podDetails?.longitude}`} 
                  className="fw-500" rel="noreferrer">
                  {`${podDetails?.latitude} - ${podDetails?.longitude}`   || "N/A"}
                </a>
              </p>
            </div>
                
          </div>
          :
          "POD is not present"
        }
            
      </Modal>
    </Spin>
  );
};

export default DrsDetails;
