import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Row, Col, Form, Input, Button, message, Modal } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { camelCase } from "../../utils";
import {
  getProfileDetails,
  saveProfileDetails,
  clearProfileDetails,
} from "../../actionsMethods/masterMethods";
// eslint-disable-next-line no-unused-vars
import profile_edit from "../../assests/images/master/profile-edit.svg";
import { QRCode } from "react-qrcode";
import "./style.scss";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [details, setDetails] = useState();
  const [value, setValue] = useState(null);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [profileData, setProfileData] = useState({
    premiseId: "",
    userId: "",
    roles: [],
  });
  const profileDetails = useSelector(
    (state) => state.masterReducer.getProfileDetailsReducer,
    shallowEqual
  );

  const saveProfileDetailss = useSelector(
    (state) => state.masterReducer.saveProfileDetailsReducer,
    shallowEqual
  );

  const { result: profileDetailsResult } = profileDetails;
  const { result: saveProfileDetailsResult, error: saveProfileDetailsError } =
    saveProfileDetailss;

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userId"));
    setValue(userData?.userInfo?.premiseId.toString());
  }, []);

  useEffect(() => {
    if (profileDetailsResult) {
      localStorage.setItem(
        "userName",
        JSON.stringify(profileDetailsResult?.data?.firstName)
      );
      let userRole = [];
      profileDetailsResult?.data?.roles.map((e) =>
        userRole.push(e.roleName.split("_")[1])
      );
      form.setFieldsValue({
        firstName: profileDetailsResult?.data?.firstName,
        lastName: profileDetailsResult?.data?.lastName,
        mobileNum: profileDetailsResult?.data?.mobileNum,
        emailId: profileDetailsResult?.data?.emailId,
        pincode: profileDetailsResult?.data?.pincode,
        city: profileDetailsResult?.data?.city,
        state: profileDetailsResult?.data?.state,
        roleDetails: camelCase(userRole.toString().split("admin").join(" ")),
        premiseName: camelCase(profileDetailsResult?.data?.premiseName),
        address: camelCase(profileDetailsResult?.data?.address),
      });
      setProfileData({
        premiseId: profileDetailsResult?.data?.premiseId,
        userId: profileDetailsResult?.data?.userId,
        roles: profileDetailsResult?.data?.roles,
      });
    }
  }, [profileDetailsResult]);

  useEffect(() => {
    if (saveProfileDetailsResult) {
      message.success(saveProfileDetailsResult?.message);
      dispatch(getProfileDetails(saveProfileDetailsResult?.data?.emailId));
      dispatch(clearProfileDetails());
    }
    if (saveProfileDetailsError) {
      message.error(saveProfileDetailsError?.message);
      dispatch(clearProfileDetails());
    }
  }, [saveProfileDetailsResult, saveProfileDetailsError]);

  useEffect(() => {
    if (details !== undefined) {
      setDetails(JSON.parse(localStorage.getItem("userId")));
    }
    dispatch(
      getProfileDetails(JSON.parse(localStorage.getItem("userinfo")).username)
    );
  }, []);

  const saveProfile = (values) => {
    delete values.roleDetails;
    let payload = { ...values, ...profileData };
    dispatch(
      saveProfileDetails(
        JSON.parse(localStorage.getItem("userinfo")).username,
        payload
      )
    );
  };

  const upperCase = (e, name) => {
    let splitStr = e.target.value.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    form.setFieldValue(name, splitStr.join(" "));
  };

  const printQR = () => {
    const printableContent = document.getElementById("printable-content");
    const printWindow = window.open("", "", "height=1000,width=1000");
    printWindow.document.write(printableContent.innerHTML);
    printWindow.print();
  };

  return (
    <div className="pl-2 pr-2 mb-2">
      <div className="master-main-container">
        <div className="p-1 flex-end">
          {JSON.parse(localStorage.getItem("userinfo"))?.roles?.includes(
            "ROLE_HUBADMIN"
          ) && (
            <button
              className="next-btn"
              onClick={() => setShowQrCodeModal(true)}>
              HUB QR CODE
            </button>
          )}
        </div>
        <hr className="divider" />
        <Form
          layout="vertical"
          form={form}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          onFinish={saveProfile}
          className="global-form">
          <Row>
            <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
            <Col xs={22} sm={22} md={16} lg={16} xl={16}>
              <div className="profile d-flex center ml-1 mb-2">
                {profileDetailsResult?.data?.firstName
                  ?.substring(1, 0)
                  .toUpperCase() || "?"}
                {/* <img src={profile_edit} alt="edit" className="profile-edit-img"/> */}
              </div>
              <Row gutter={20} className="mt-1 global-form-row">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="firstName"
                    label="First name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter first name",
                      },
                      {
                        min: 3,
                        message: "first name must be minimum of 3 characters.",
                      },
                      () => ({
                        validator(rule, value = "") {
                          if (value.substring(1, 0) === " ") {
                            return Promise.reject("Invalid first name");
                          } else {
                            return Promise.resolve();
                          }
                        },
                        validateTrigger: "onSubmit",
                      }),
                    ]}>
                    <Input
                      placeholder="Enter first name"
                      onKeyPress={(e) => {
                        if (/[^A-Za-z ]/.test(e.key)) {
                          e.preventDefault();
                        }
                        if (e.target.value.length >= 30) {
                          e.preventDefault();
                        }
                      }}
                      style={{ textTransform: "capitalize" }}
                      onBlur={(e) => upperCase(e, "firstName")}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="lastName"
                    label="Last name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter last name",
                      },
                      () => ({
                        validator(rule, value = "") {
                          if (value.substring(1, 0) === " ") {
                            return Promise.reject("Invalid last name");
                          } else {
                            return Promise.resolve();
                          }
                        },
                        validateTrigger: "onSubmit",
                      }),
                    ]}>
                    <Input
                      placeholder="Enter last name"
                      onKeyPress={(e) => {
                        if (/[^A-Za-z ]/.test(e.key)) {
                          e.preventDefault();
                        }
                        if (e.target.value.length >= 25) {
                          e.preventDefault();
                        }
                      }}
                      style={{ textTransform: "capitalize" }}
                      onBlur={(e) => upperCase(e, "lastName")}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="mobileNum"
                    label="Mobile"
                    rules={[
                      {
                        required: true,
                        message: "Please enter mobile number",
                      },
                      {
                        pattern: /^([1-9]{1}[0-9]{9}){0,10}$/g,
                        message: "Invalid mobile number",
                      },
                    ]}>
                    <Input
                      placeholder="Enter mobile number"
                      onKeyPress={(e) => {
                        if (/[^0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                        if (e.target.value.length >= 10) {
                          e.preventDefault();
                        }
                      }}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col
                  className="form-inputs"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}>
                  <Form.Item
                    name="emailId"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter email",
                      },
                    ]}>
                    <Input
                      placeholder="Enter email"
                      disabled={true}
                      className="disable-color"
                    />
                  </Form.Item>
                </Col>
                <Col
                  className="form-inputs"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}>
                  <Form.Item
                    name="pincode"
                    label="PIN code"
                    rules={[
                      {
                        required: true,
                        message: "Please enter pin code",
                      },
                    ]}>
                    <Input
                      placeholder="Enter PIN code"
                      disabled={true}
                      className="disable-color"
                    />
                  </Form.Item>
                </Col>
                <Col
                  className="form-inputs"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}>
                  <Form.Item
                    name="city"
                    label="City"
                    rules={[
                      {
                        required: true,
                        message: "Please enter city name",
                      },
                    ]}>
                    <Input
                      placeholder="Enter city name"
                      disabled={true}
                      className="disable-color"
                    />
                  </Form.Item>
                </Col>
                <Col
                  className="form-inputs"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}>
                  <Form.Item
                    name="state"
                    label="State"
                    rules={[
                      {
                        required: true,
                        message: "Please enter state",
                      },
                    ]}>
                    <Input
                      placeholder="Enter state name"
                      disabled={true}
                      className="disable-color"
                    />
                  </Form.Item>
                </Col>
                <Col
                  className="form-inputs"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}>
                  <Form.Item
                    name="roleDetails"
                    label="User role"
                    rules={[
                      {
                        required: true,
                        message: "Please enter user role",
                      },
                    ]}>
                    <Input
                      placeholder="Enetr user role"
                      disabled={true}
                      className="disable-color"
                    />
                  </Form.Item>
                </Col>
                <Col
                  className="form-inputs"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}>
                  <Form.Item
                    name="premiseName"
                    label="Premise name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter premise name",
                      },
                    ]}>
                    <Input
                      placeholder="Enetr user premise name"
                      disabled={true}
                      className="disable-color"
                    />
                  </Form.Item>
                </Col>
                <Col
                  className="form-inputs"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}>
                  <Form.Item
                    name="address"
                    label="Premise address"
                    rules={[
                      {
                        required: false,
                        message: "Please enter premise address",
                      },
                    ]}>
                    <Input.TextArea
                      rows={4}
                      placeholder="Enter user premise address"
                      disabled={true}
                      className="disable-color"
                      style={{ resize: "none" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={1} sm={1} md={4} lg={4} xl={4}>
              {" "}
            </Col>
          </Row>
          <hr className="divider" />
          <div className="flex-end p-1">
            <Button
              className="cancel-btn ml-1"
              onClick={() => navigate("/dashboard")}>
              CANCEL
            </Button>
            <Button htmlType="submit" className="save-btn ml-1">
              SAVE
            </Button>
          </div>
        </Form>
        <Modal
          title="Hub QR Code"
          open={showQrCodeModal}
          onCancel={() => setShowQrCodeModal(false)}
          footer={false}
          destroyOnClose>
          <>
            <div className="center" style={{ flexDirection: "column" }}>
              <div id="printable-content">
                <QRCode value={value} width={350} />
              </div>
              <Button className="save-btn ml-1" onClick={printQR}>
                PRINT
              </Button>
            </div>
          </>
        </Modal>
      </div>
    </div>
  );
};
export default Profile;
