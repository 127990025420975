import React from "react";
import {Row,Col,Form,Button} from "antd";
const BottomButton = ({goBackStep}) => {
  return (
    <Row gutter={12} justify="end" className="mt-2">
      <Col>
        <Button className="cancel-btn" onClick={goBackStep}>CANCEL</Button>
      </Col>
      <Col>
        <Button className="cancel-btn" htmlType="submit">SAVE</Button>
      </Col>
      <Col>
        <Form.Item>
          <Button className="save-btn" htmlType="submit">SAVE & CONTINUE</Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default BottomButton;
