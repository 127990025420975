/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React from "react";
import {
  Collapse,
  Row,
  Form,
  Col,
  Radio,
  Button,
  Popconfirm,
  AutoComplete,
} from "antd";
const { Panel } = Collapse;
import FormItem from "../../../../components/FormItem";
import ChildShipmentForm from "./childShipmentForm";
import { isValidDocumentNum } from "../../../../utils";
function RateCalculate({
  form,
  user_premiseId,
  pickUpPinCode,
  checkPinCodeServiceable,
  pinCodeServiceableStatus,
  checkNumbervalue,
  checkSpecialCharacter,
  areaList,
  getAreaDestinationPremiseId,
  docTypeBasedData,
  capitaliseWithHyphen,
  changeShipmentType,
  contentData,
  setDescriptionData,
  showNonDoxFields,
  parseInt,
  setShowRateCard,
  valumCalculation,
  changeChildSelection,
  chilldShipmentLength,
  //addAllChildShipment,
  calculeterIcon,
  childShipments,
  addChildShipment,
  changeChildShipmentData,
  removeChild,
  descriptionData,
  chargableWeight,
  setChargableWeight,
  handleChargableWeight,
  openCharge,
  readOnlyField,
  isValidAwb,
  service,
}) {
  return (
    <Form
      layout="vertical"
      form={form}
      scrollToFirstError={{
        behavior: "smooth",
        block: "center",
        inline: "center",
      }}
      initialValues={{
        shipmentType: "DOX",
        content: "Document - Form, Catalogue & Paper",
        value: 0,
      }}
      name="baseForm" //onFinish={calculateRate}
      onFinishFailed={(e) => console.log(e)}
    >
      <Row gutter={16}>
        <FormItem
          md={3}
          lg={3}
          xl={3}
          sm={8}
          label={"Booking PIN code"}
          suffix={
            <span className="green">{user_premiseId?.premises?.stateCode}</span>
          }
          type="input"
          readOnly={true}
          name="pickupPincode"
          value={pickUpPinCode}
        />
        <FormItem
          md={3}
          lg={3}
          xl={3}
          sm={8}
          label={"Delivery PIN code"}
          onChange={(e) => {
            e.target.value.length === 6 &&
              checkPinCodeServiceable(e.target.value);
            form.setFieldValue("areaId", null);
          }}
          validateStatus={pinCodeServiceableStatus?.status}
          help={pinCodeServiceableStatus?.msg}
          suffix={
            <span
              className={
                pinCodeServiceableStatus?.serviceability?.serviceability ===
                "Regular"
                  ? "green"
                  : pinCodeServiceableStatus?.serviceability?.serviceability ===
                    "Premium"
                  ? "orange"
                  : "red"
              }
            >
              {pinCodeServiceableStatus?.serviceability?.stateCode}
            </span>
          }
          type="input"
          name="deliveryPincode"
          placeholder="Enter delivery PIN code"
          rules={[
            {
              required: true,
              message: "Please enter value",
            },
            () => ({
              validator(rule, value = "") {
                if (value.length > 0 && value.length < 6) {
                  return Promise.reject("Pincode should be 6 digits");
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
          onKeyPress={(event) => {
            if (
              checkNumbervalue(event) ||
              checkSpecialCharacter(event) ||
              event.target.value.length >= 6
            ) {
              event.preventDefault();
            }
          }}
        />
        <FormItem
          md={4}
          lg={4}
          xl={4}
          sm={8}
          label="Area"
          type="select"
          name="areaId"
          placeholder="Search area"
          rules={[
            {
              required: true,
              message: "Please select area",
            },
          ]}
          options={areaList}
          onChange={(val) => {
            getAreaDestinationPremiseId(val);
          }}
        />
        <FormItem
          md={3}
          lg={3}
          xl={3}
          sm={8}
          label="Type"
          type="select"
          name="shipmentType"
          placeholder="Select DOX type"
          rules={[
            {
              required: true,
              message: "Please select shipment type",
            },
          ]}
          options={docTypeBasedData?.map(({ type }) => ({
            label: capitaliseWithHyphen(type),
            value: type,
          }))}
          onChange={(val) => changeShipmentType(val)}
        />

        {/* <FormItem
          md={7}
          lg={7}
          xl={7}
          sm={8}
          label="Content"
          type="select"
          name="content"
          placeholder="Select content"
          options={contentData}
          rules={[
            {
              required: true,
              message: "Please select content",
            },
          ]}
        /> */}
        <Col md={7} lg={7} xl={7} sm={8}>
          <Form.Item
            name="content"
            label="Content"
            rules={[
              {
                required: true,
                message: "Select content",
              },
            ]}
          >
            <AutoComplete
              className="formitem-component"
              options={contentData}
              placeholder="Select content"
              optionFilterProp="children"
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
          </Form.Item>
        </Col>

        <FormItem
          md={4}
          lg={4}
          xl={4}
          sm={8}
          label="Document Number"
          type="input"
          name="documentNumber"
          placeholder="Enter Document number"
          onKeyPress={(event) => {
            if (
              checkNumbervalue(event) ||
              checkSpecialCharacter(event) ||
              event.target.value.length >= 14
            ) {
              event.preventDefault();
            }
          }}
          rules={[
            {
              required: true,
              message: "Please enter value",
            },
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject();
                }
                if (
                  service &&
                  !isValidDocumentNum(
                    value,
                    service?.toLowerCase() === "standard"
                      ? "standardcash"
                      : service
                  )
                ) {
                  return Promise.reject(
                    "Invalid document number as per selected service"
                  );
                }
                if (!isValidAwb(value)) {
                  return Promise.reject("Please enter a valid doc number.");
                }
                return Promise.resolve();
              },
            },
          ]}
          readOnly={readOnlyField}
        />
        {!showNonDoxFields ? (
          <>
            <FormItem
              md={3}
              lg={3}
              xl={3}
              sm={8}
              label="Weight(Gms)"
              type="input"
              name="weight"
              placeholder="Enter weight"
              rules={[
                {
                  required: true,
                  message: "Please enter value",
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject();
                    }

                    if (parseInt(value?.toString()?.substring(1, 0)) === 0) {
                      return Promise.reject("Value should not be 0");
                    }
                    if (value && value.split(".").length > 2) {
                      return Promise.reject(
                        "Please enter only one decimal value"
                      );
                    }

                    const regex = /^\d+(\.\d{1,2})?$/;
                    if (value && !regex.test(value)) {
                      return Promise.reject(
                        "Please enter a valid number with up to two decimal places"
                      );
                    }

                    if (value < 50) {
                      return Promise.reject("Enter a weight of at least 50Gm");
                    }

                    if (value > 900) {
                      return Promise.reject("Weight should be less than 900Gm");
                    }

                    return Promise.resolve();
                  },

                  //validateTrigger: "onSubmit",
                }),
              ]}
              onKeyPress={(event) => {
                if (checkNumbervalue(event) || event.target.value.length >= 7) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                setChargableWeight(e.target.value), setShowRateCard(false);
              }}
            />
            <FormItem
              md={3}
              lg={3}
              xl={3}
              sm={8}
              label="Value(INR)"
              type="input"
              name="value"
              placeholder="Enter value"
              onKeyPress={(event) => {
                if (checkNumbervalue(event) || checkSpecialCharacter(event)) {
                  event.preventDefault();
                }
              }}
              rules={[
                {
                  pattern: /^[0-9].*/g,
                  message: "Initial value should be numeric.",
                },
              ]}
            />
          </>
        ) : (
          <>
            <FormItem
              md={3}
              lg={3}
              xl={3}
              sm={8}
              label="Weight(Kg)"
              type="input"
              name="weight"
              placeholder="Enter weight"
              rules={[
                {
                  required: true,
                  message: "Please enter value",
                },
                {
                  pattern: /^[0-9]{1,3}(\.[0-9]{1,2})?$/g,
                  message: "Invalid weight",
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject();
                    }

                    if (parseInt(value?.toString()?.substring(1, 0)) === 0) {
                      return Promise.reject("Value should not be 0");
                    }
                    const regex = /^\d+(\.\d{1,2})?$/;
                    if (value && !regex.test(value)) {
                      return Promise.reject(
                        "Please enter a valid number with up to two decimal places"
                      );
                    }

                    if (value && value.split(".").length > 2) {
                      return Promise.reject(
                        "Please enter only one decimal value"
                      );
                    }

                    return Promise.resolve();
                  },

                  //validateTrigger: "onSubmit",
                }),
              ]}
              onKeyPress={(event) => {
                if (checkNumbervalue(event) || event.target.value.length >= 7) {
                  event.preventDefault();
                }
              }}
              onChange={() => setShowRateCard(false)}
            />
            <FormItem
              md={4}
              lg={4}
              xl={4}
              sm={8}
              label="Length(Cm)"
              type="input"
              name="length"
              placeholder="Enter length"
              rules={[
                {
                  required: true,
                  message: "Please enter  value",
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject();
                    }

                    if (value < 1) {
                      return Promise.reject("Length should be more than 1Cm");
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
              onKeyPress={(event) => {
                if (checkNumbervalue(event) || checkSpecialCharacter(event)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => valumCalculation(e)}
            />
            <FormItem
              md={4}
              lg={4}
              xl={4}
              sm={8}
              label="Width(Cm)"
              type="input"
              name="width"
              placeholder="Enter width"
              min={"0"}
              maxLength={3}
              rules={[
                {
                  required: true,
                  message: "Please enter width",
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject();
                    }

                    if (value < 1) {
                      return Promise.reject("Width should be more than 1Cm");
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
              onKeyPress={(event) => {
                if (checkNumbervalue(event) || checkSpecialCharacter(event)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => valumCalculation(e)}
            />
            <FormItem
              md={3}
              lg={3}
              xl={3}
              sm={8}
              label="Height (Cm)"
              type="input"
              name="height"
              placeholder="Enter height"
              maxLength={3}
              rules={[
                {
                  required: true,
                  message: "Please enter height",
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject();
                    }

                    if (value < 1) {
                      return Promise.reject("Height should be more than 1Cm");
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
              onKeyPress={(event) => {
                if (checkNumbervalue(event) || checkSpecialCharacter(event)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => valumCalculation(e)}
            />
            <FormItem
              md={3}
              lg={3}
              xl={3}
              sm={8}
              label="Vol weight (Kg)"
              type="input"
              name="volumatricWeight"
              readOnly={true}
              placeholder="0"
            />
            <FormItem
              md={3}
              lg={3}
              xl={3}
              sm={8}
              label="Value(INR)"
              type="input"
              name="value"
              placeholder="Enter value"
              rules={[
                {
                  required: true,
                  message: "Please enter a value",
                },
                {
                  pattern: /^[0-9].*/g,
                  message: "Initial value should be numeric.",
                },
                () => ({
                  validator(rule, value = "") {
                    if (parseInt(value?.toString()?.substring(1, 0)) === 0) {
                      return Promise.reject("Value should not be 0");
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              onKeyPress={(event) => {
                if (checkNumbervalue(event) || checkSpecialCharacter(event)) {
                  event.preventDefault();
                }
              }}
            />
            {/* <FormItem
              md={4}
              lg={4}
              xl={4}
              sm={8}
              label="Child shipments"
              type="radio"
              name="childSelected"
              rules={[
                {
                  required: true,
                  message: "Please enter value",
                },
              ]}
              onChange={(e) => {
                changeChildSelection(e);
              }}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            /> */}
            <Col span={4}>
              <Form.Item
                name="childSelected"
                label={
                  <span className="calculater-container-pikpin">
                    Child shipments{" "}
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter value",
                  },
                ]}
                onChange={(e) => {
                  changeChildSelection(e.target.value);
                }}
              >
                <Radio.Group>
                  <Radio
                    value={true}
                    className="radioButton"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        changeChildSelection("true");
                        e.preventDefault();
                      }
                    }}
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    className="radioButton"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        changeChildSelection("false");
                        e.preventDefault();
                      }
                    }}
                  >
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {chilldShipmentLength > 0 && (
              <>
                <Col span={24} className="mt-1">
                  <Collapse defaultActiveKey="1">
                    <Panel
                      key="1"
                      header={
                        <>
                          <img
                            className="messageIcon mr-1"
                            src={calculeterIcon}
                          />
                          <span>Child Shipments</span>
                        </>
                      }
                    >
                      <Row gutter={16}>
                        <ChildShipmentForm
                          childShipments={childShipments}
                          form={form}
                          addChildShipment={addChildShipment}
                          setChildShipment={(val, i, type) =>
                            changeChildShipmentData(val, i, type)
                          }
                          removeChild={(i) => removeChild(i)}
                          readOnlyField={readOnlyField}
                        />
                      </Row>
                    </Panel>
                  </Collapse>
                </Col>
              </>
            )}
          </>
        )}
      </Row>

      <div className="flex-end mt-1">
        {form.getFieldValue("shipmentType") === "NON-DOX" && (
          <Popconfirm
            className="view-weight"
            title={
              "Chargeable Weight :" +
              (chargableWeight ? parseFloat(chargableWeight)?.toFixed(2) : 0)
            }
            open={openCharge}
            showCancel={false}
            onOpenChange={() => handleChargableWeight()}
          >
            <a href="#" className="mt-05">
              View chargeable weight
            </a>
          </Popconfirm>
        )}
        <Button className="save-btn ml-1" htmlType="submit">
          {"CALCULATE"}
        </Button>
      </div>
    </Form>
  );
}
export default RateCalculate;
