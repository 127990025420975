/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Row, Form, Input, Select, Button, Spin, message } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { capitalizeAfterSpacesAndSpecialChars } from "../../../../utils";
import {
  getPacketingGuideFromHublist,
  getPacketingGuideToHublist,
  getPacketingGuideFromCplist,
  getPacketingGuideToCplist,
  postPacketingGuideMaster,
  postPacketingGuideMasterClear,
} from "../../../../actionsMethods/masterMethods";
import Heading from "../../../../components/Heading";
import "./style.scss";
import { APIs, getToken } from "../../../../utils/worker";

const AddPacketMapping = () => {
  let token = getToken();
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fromHubData, setFromHubData] = useState();
  const [fromHubPremise, setFromHubPremise] = useState();
  const [toHubPremise, setToHubPremise] = useState();
  const [packetOpeningPremise, setPacketOpeningPremise] = useState();
  const [toHubArray, setToHubArray] = useState([]);
  const [reverseToHubArray, setReverseToHubArray] = useState([]);
  const [packetOpeningFilterData, setPacketOpeningFilterData] = useState();
  const [cpListUpdate, setCpLidtUpdate] = useState([]);
  const [dropdownDisable, setDropdownDisable] = useState(true);
  const [toHubdropdownDisable, setToHubDropdownDisable] = useState(true);
  const [packetOpeningValue, setPacketOpeningValue] = useState();
  const toCpListFilteredData = [];
  const getFromHubListResponse = useSelector(
    (state) => state?.masterReducer?.getPacketingGuideFromHublistReducer,
    shallowEqual
  );
  const getToHubListResponse = useSelector(
    (state) => state?.masterReducer?.getPacketingGuideToHublistReducer,
    shallowEqual
  );
  const getFromCpListResponse = useSelector(
    (state) => state?.masterReducer?.getPacketingGuideFromCplistReducer,
    shallowEqual
  );

  const getToCpListResponse = useSelector(
    (state) => state?.masterReducer?.getPacketingGuideToCplistReducer,
    shallowEqual
  );
  const postPacketingGuideMasterResponse = useSelector(
    (state) => state?.masterReducer?.postPacketingGuideMasterReducer,
    shallowEqual
  );
  const { error, fetching, result } = postPacketingGuideMasterResponse;
  const { result: ToCpListResult } = getToCpListResponse;
  const { result: fromCpListResult } = getFromCpListResponse;
  const { result: fromHubListResult } = getFromHubListResponse;
  const fromHubSortList = fromHubListResult?.sort((a, b) =>
    a?.premiseName?.localeCompare(b?.premiseName)
  );
  const fromFilteredHub = getToHubListResponse?.result?.filter(
    (data) => data?.premiseName !== fromHubData
  );
  const filteredHub = fromFilteredHub?.sort((a, b) =>
    a?.premiseName?.localeCompare(b?.premiseName)
  );
  useEffect(() => {
    if (ToCpListResult) {
      form.setFieldValue(
        "tocp",
        ToCpListResult?.map((data) => data?.premiseName)
      );
    }
  }, [ToCpListResult]);

  useEffect(() => {
    if (fromCpListResult) {
      form.setFieldValue(
        "fromcp",
        fromCpListResult?.map((data) => data?.premiseName)
      );
    }
  }, [fromCpListResult]);

  useEffect(() => {
    setCpLidtUpdate(fromCpListResult);
  }, [fromCpListResult]);
  useEffect(() => {
    setCpLidtUpdate([]);
  }, []);

  useEffect(() => {
    if (reverseToHubArray?.length === 0) {
      setPacketOpeningValue(null);
      form.setFieldValue("packetopeninghub", packetOpeningValue);
    } else {
      setPacketOpeningValue([]);
    }
  }, [reverseToHubArray]);

  useEffect(() => {
    if (result) {
      message.success(result);
      navigate("/packeting-guide");
      dispatch(postPacketingGuideMasterClear());
    } else if (error?.message) {
      message.error(error?.message);
      dispatch(postPacketingGuideMasterClear());
    }
  }, [result, error]);

  const onFinishHandle = (values) => {
    const fromCpFilteredData = fromCpListResult?.filter(function (item) {
      return values?.fromcp?.find((data) => {
        return data === item?.premiseName;
      });
    });
    let y = values?.tocp;
    for (let i in y) {
      let checked = ToCpListResult?.find((e) => e?.premiseName === y[i]);
      toCpListFilteredData?.push(checked?.premiseId);
    }
    let payload = {
      packetingGuideName: values?.packetname,
      packetOpeningHub: parseInt(packetOpeningPremise),
      fromHub: parseInt(fromHubPremise),
      fromCp: fromCpFilteredData?.map((data) => data?.premiseId),
      toHub: toHubPremise,
      toCp: toCpListFilteredData,
    };
    dispatch(postPacketingGuideMaster(payload));
  };
  const selectFromHubHandle = (value, key) => {
    setFromHubData(value);
    dispatch(getPacketingGuideFromCplist(key?.key));
    setFromHubPremise(key?.key);
    setToHubDropdownDisable(false);
    if (toHubArray.length > 1) {
      setToHubArray([]);
      form.setFieldValue("tohub", []);
      form.setFieldValue("packetopeninghub", []);
      setToHubDropdownDisable(true);
    }
  };
  const selectToHubHandle = (data, key) => {
    setToHubArray([...toHubArray, key?.key]);
  };

  const deselectToHubHandle = (data, key) => {
    setToHubArray(toHubArray?.filter((data) => data !== key?.key));
    setReverseToHubArray(
      reverseToHubArray?.filter((data) => data !== key?.key)
    );
  };
  useEffect(() => {
    dispatch(getPacketingGuideToCplist(toHubArray.toString()));
    setToHubPremise(toHubArray);
    if (toHubArray.length === 0) {
      form.setFieldValue("tocp", []);
    }
  }, [toHubArray]);
  const cancelHandle = () => {
    navigate("/packeting-guide");
  };
  useEffect(() => {
    dispatch(getPacketingGuideFromHublist());
    dispatch(getPacketingGuideToHublist());
    form.setFieldValue("tocp", []);
    form.setFieldValue("fromcp", []);
    setDropdownDisable(true);
  }, []);

  const selectPacketOpeningHandle = (nameData, key) => {
    let packetOpeningFilter = fromHubSortList?.filter(
      (data) => data?.premiseName !== nameData
    );

    setPacketOpeningFilterData(packetOpeningFilter);
    setPacketOpeningPremise(key?.key);
    form.setFieldValue("tohub", key);
    setToHubArray([key?.key]);
    setReverseToHubArray([key?.key]);
    setDropdownDisable(false);
  };
  const deselectPacketOpeningHandle = (data, key) => {
    setToHubArray(toHubArray?.filter((data) => data !== key?.key));
  };

  const upperCase = (e) => {
    let splitStr = e.target.value.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    let upperCaseName = splitStr.join(" ");

    form.setFieldValue("packetname", upperCaseName);
  };
  return (
    <Spin spinning={fetching}>
      <div className="p-2">
        <Heading title={"Add Packet Mapping"} />
        <Form
          autoComplete="off"
          form={form}
          layout="vertical"
          className="packeting-guide-form .master-main-container  global-form"
          onFinish={onFinishHandle}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
        >
          <Row gutter={64} className="global-form-row add-pkt-mapping-form">
            <Col span={12}>
              <Form.Item
                label="Packet name"
                name="packetname"
                rules={[
                  {
                    required: true,
                    message: "Please enter packet name",
                  },
                  {
                    min: 3,
                    message: "Packet name must be minimum of 3 characters.",
                  },
                  () => ({
                    async validator(_, value) {
                      try {
                        let res = await axios.get(
                          `${APIs.baseURL}/packet-service/v1/packeting-guide/packet-name/${value}`,
                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );
                        if (res.data && value.length >= 3) {
                          return Promise.reject("This name is already exists.");
                        } else if (value.substring(2, 3) === " ") {
                          return Promise.reject(
                            "Please enter valid packet name"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    },
                  }),
                ]}
              >
                <Input
                  style={{ textTransform: "capitalize" }}
                  onBlur={upperCase}
                  maxLength={70}
                  placeholder="Enter packet name"
                  onKeyPress={(e) => {
                    if (/[^A-Za-z- ]/.test(e.key)) {
                      e.preventDefault();
                    }
                    if (e.target.value.length >= 70) {
                      e.preventDefault();
                    }
                    if (e.target.value.length > 0) {
                      e.target.value.toLowerCase();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Packet opening hub"
                name="packetopeninghub"
                rules={[
                  {
                    required: true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select hub name"
                  onSelect={(data, key) => selectPacketOpeningHandle(data, key)}
                  onDeselect={(data, key) =>
                    deselectPacketOpeningHandle(data, key)
                  }
                >
                  {filteredHub
                    ? filteredHub?.map((option) => (
                        <Option
                          key={option?.premiseId}
                          value={option?.premiseName}
                        >
                          {capitalizeAfterSpacesAndSpecialChars(option?.premiseName)}
                        </Option>
                      ))
                    : fromHubSortList?.map((option) => (
                        <Option
                          key={option?.premiseId}
                          value={option?.premiseName}
                        >
                          {capitalizeAfterSpacesAndSpecialChars(option?.premiseName)}
                        </Option>
                      ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="From hub"
                name="fromhub"
                rules={[
                  {
                    required: dropdownDisable ? false : true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select hub name"
                  className="input-field"
                  onSelect={(data, key) => selectFromHubHandle(data, key)}
                  disabled={dropdownDisable}
                >
                  {reverseToHubArray?.length > 0
                    ? packetOpeningFilterData?.map((option) => (
                        <Option
                          key={option?.premiseId}
                          value={option?.premiseName}
                        >
                          {capitalizeAfterSpacesAndSpecialChars(option?.premiseName)}
                        </Option>
                      ))
                    : fromHubSortList?.map((option) => (
                        <Option
                          key={option?.premiseId}
                          value={option?.premiseName}
                        >
                          {capitalizeAfterSpacesAndSpecialChars(option?.premiseName)}
                        </Option>
                      ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="From CP"
                name="fromcp"
                rules={[
                  {
                    required: dropdownDisable ? false : true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select
                  placeholder="Select CP"
                  className="fromcp-style multiple-input-field cp-list-scroll mul-input"
                  mode="multiple"
                  disabled={dropdownDisable}
                >
                  {cpListUpdate?.map((option) => (
                    <Option
                      key={option?.premiseName}
                      value={option?.premiseName}
                    >
                      {capitalizeAfterSpacesAndSpecialChars(option?.premiseName)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="To hub"
                name="tohub"
                rules={[
                  {
                    required: toHubdropdownDisable ? false : true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select hub name"
                  onSelect={(data, key) => selectToHubHandle(data, key)}
                  onDeselect={(data, key) => deselectToHubHandle(data, key)}
                  mode="multiple"
                  className="tohub-style multiple-input-field cp-list-scroll mul-input"
                  disabled={toHubdropdownDisable}
                >
                  {filteredHub?.map((option) => {
                    return (
                      <Option
                        key={option?.premiseId}
                        value={option?.premiseName}
                      >
                        {capitalizeAfterSpacesAndSpecialChars(option?.premiseName)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="To CP"
                name="tocp"
                rules={[
                  {
                    required: toHubdropdownDisable ? false : true,
                    message: "Please select an option",
                    //validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Select
                  placeholder="Select CP"
                  mode="multiple"
                  className="tocp-style multiple-input-field cp-list-scroll mul-input"
                  disabled={toHubdropdownDisable}
                >
                  {getToCpListResponse?.result?.map((list) => (
                    <Option key={list?.premiseId} value={list?.premiseName}>
                      {capitalizeAfterSpacesAndSpecialChars(list?.premiseName)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <hr className="divider mt-3" />
          <div className="d-flex flex-end p-1">
            <Button
              className="btn-style cancel-btn w-10"
              onClick={cancelHandle}
            >
              CANCEL
            </Button>
            <Button htmlType="submit" className="btn-style save-btn ml-1 w-10">
              SAVE
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default AddPacketMapping;
