import React from "react";
import { Col, Input } from "antd";

import search_icon from "../../../assests/images/search-icon.svg";
import back_arrow from "../../../assests/images/master/back-arrow.svg";

import "./index.scss";
import { useNavigate } from "react-router";

const MasterSearch = ({
  title,
  search,
  masterSearch,
  masterSearchPlaceholder,
  isSearchPresent
}) => {
  const navigate = useNavigate();
  const goToPreviousPage = () => {
    navigate("/masters");
  };

  return (
    <Col span={24}>
      <div className="master-search-container">
        {
          isSearchPresent ? 
            <>
              <span className="d-flex">
                {masterSearch ? <img
                  src={back_arrow}
                  onClick={() => navigate("/dashboard")}
                  className="pointer"
                /> : (
                  <img
                    src={back_arrow}
                    onClick={() => goToPreviousPage()}
                    className="pointer"
                  />
                )}
              &nbsp;{title}
              </span>
              {masterSearch && (
                <div>
                  <Input
                    placeholder={masterSearchPlaceholder}
                    bordered
                    onChange={search}
                    style={{ borderRadius: "5px", height: "40px"}}
                    suffix={<img src={search_icon} />}
                  />
                </div>
              )}
            </>
            :
            <span className="d-flex">
              <img
                src={back_arrow}
                onClick={() => navigate("/dashboard")}
                className="pointer"
              />  &nbsp;{title}
            </span>
        }
      </div>
    </Col>
  );
};
export default MasterSearch;
