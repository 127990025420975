/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Radio,
  DatePicker,
  TimePicker,
  Progress,
} from "antd";
import { capitalize } from "../../../../utils";

import forward from "../../../../assests/images/middleMile/forward.svg";
import recurrenceIcon from "../../../../assests/images/middleMile/recurrence.svg";
import returnJourney from "../../../../assests/images/middleMile/return.svg";

import truck_loaded from "../../../../assests/images/middleMile/loaded.svg";
import truck_unloaded from "../../../../assests/images/middleMile/unloaded.svg";
import delay_icon from "../../../../assests/images/middleMile/reason.svg";

import "./style.scss";

export const TripViewForm = ({
  form,
  kindOfJourney,
  allForwardTransitData,
  allReturnTransitData,
  tripData,
}) => {
  function getProgressColor(utilizationPercent) {
    const percent = utilizationPercent.toFixed(2);
    if (percent < 25) {
      return "darkred"; // Dark blood Red for less than 25%
    } else if (percent < 50) {
      return "red"; // Red for 25% - 50%
    } else if (percent < 65) {
      return "orange"; // Amber for 50% - 65%
    } else if (percent < 85) {
      return "gold"; // Amber for 65% - 85%
    } else {
      return "green"; // Green for 85% and above
    }
  }

  return (
    <div>
      <Form
        layout="vertical"
        className="global-form"
        disabled
        form={form}
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
      >
        <Row className="pt-1 global-form-row view-trip-container" gutter={24}>
          <Col span={12} className="mb-1">
            <Form.Item name="tripId" label={"Trip Id"}>
              <Input placeholder="Enter trip name" />
            </Form.Item>
          </Col>

          <Col span={12} className="mb-1">
            <Form.Item
              label="Kind of journey"
              name={"journeyType"}
              rules={[
                {
                  required: true,
                  message: "Please select Kind of journey",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={"both"}>Forward & RL</Radio>
                <Radio value={"forward"}>Forward</Radio>
                <Radio value={"return"}>RL</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={12} className="mb-1">
            <Form.Item name={"vehicleId"} label="Vehicle number">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} className="mb-1">
            <Form.Item name="routeName" label="Route">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} className="mb-1">
            <Form.Item name="driverName" label="Driver name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} className="mb-1">
            <Form.Item name="driverNumber" label={"Driver’s phone number"}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} className="mb-1">
            <Form.Item name="vehicleType" label={"Vehicle type"}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} className="mb-1">
            <Form.Item name="vehicleCapacity" label={"Vehicle capacity (KG)"}>
              <Input />
            </Form.Item>
          </Col>

          {kindOfJourney === "forward" || kindOfJourney === "both" ? (
            <>
              <Col span={24}>
                <Row>
                  <div>
                    <img src={forward} />
                  </div>
                  <div>
                    <h3 className="ml-1">Forward Journey</h3>
                  </div>
                </Row>
                <hr className="divider mb-2" />
              </Col>

              {allForwardTransitData?.map((val, index) => {
                return (
                  <>
                    <Col span={8} className="mb-1">
                      <Form.Item
                        label={
                          <Row className="arrival-label-container">
                            <div>
                              {index === allForwardTransitData?.length - 1
                                ? "Destination"
                                : index == 0
                                ? "Origin"
                                : "Transit " + index}
                            </div>
                          </Row>
                        }
                      >
                        <div className="d-flex">
                          <Input defaultValue={capitalize(val?.premiseName)} />
                        </div>
                      </Form.Item>
                    </Col>

                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"arrivalDate" + index}
                        label="Arrival date"
                      >
                        <DatePicker format={"DD-MM-YYYY"} />
                      </Form.Item>
                    </Col>
                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"arrivalTime" + index}
                        label="Arrival time"
                      >
                        <TimePicker use12Hours format="h:mm A" />
                      </Form.Item>
                    </Col>

                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"departureDate" + index}
                        label="Departure date"
                      >
                        <DatePicker format={"DD-MM-YYYY"} />
                      </Form.Item>
                    </Col>

                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"departureTime" + index}
                        label="Departure time"
                      >
                        <TimePicker use12Hours format="h:mm A" />
                      </Form.Item>
                    </Col>
                    <Col span={8}></Col>

                    {val?.actualArrivalDate !== null && (
                      <>
                        <Col span={4} className="mb-1">
                          <Form.Item
                            name={"actualArrivalDate" + index}
                            label="Actual arrival date"
                          >
                            <DatePicker format={"DD-MM-YYYY"} />
                          </Form.Item>
                        </Col>
                        <Col span={4} className="mb-1">
                          <Form.Item
                            name={"actualArrivalTime" + index}
                            label="Actual arrival time"
                          >
                            <TimePicker use12Hours format="h:mm A" />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    {val.actualDepartureDate !== null && (
                      <>
                        <Col span={4} className="mb-1">
                          <Form.Item
                            name={"actualDepartureDate" + index}
                            label="Actual Departure date"
                          >
                            <DatePicker format={"DD-MM-YYYY"} />
                          </Form.Item>
                        </Col>
                        <Col span={4} className="mb-1">
                          <Form.Item
                            name={"actualDepartureTime" + index}
                            label="Actual Departure time"
                          >
                            <TimePicker use12Hours format="h:mm A" />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    <Col
                      span={24}
                      style={{ background: "#F4F4F4" }}
                      className="mb-1"
                    >
                      <>
                        <Row
                          className="mt-1 mb-1"
                          style={{
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Col span={4}>
                            {index !== 0 && (
                              <div>
                                <img src={truck_unloaded} height={25} />
                                &nbsp;&nbsp;&nbsp;
                                <span>
                                  Unloaded:{" "}
                                  {val?.unLoadedWeight !== null ? (
                                    <strong>
                                      {val?.unLoadedWeight + " Kg"}
                                    </strong>
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </div>
                            )}
                          </Col>

                          <Col span={4}>
                            {index !== allForwardTransitData?.length - 1 && (
                              <div>
                                <img src={truck_loaded} height={25} />
                                &nbsp;&nbsp;&nbsp;
                                <span>
                                  Loaded:{" "}
                                  {val?.loadedWeight !== null ? (
                                    <strong>{val?.loadedWeight + " Kg"}</strong>
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </div>
                            )}
                          </Col>

                          <Col span={6}>
                            <div>
                              <img src={delay_icon} height={25} />
                              &nbsp;&nbsp;&nbsp;
                              <span>
                                Reported Delay:{" "}
                                {val.reasonForDelay !== null ? (
                                  <strong>{val?.reasonForDelay}</strong>
                                ) : (
                                  "N/A"
                                )}
                              </span>
                            </div>
                          </Col>
                          {/* <Col span={2}> Utilization : </Col> */}
                          <Col span={6}>
                            <Row
                              style={{
                                flexFlow: "nowrap",
                              }}
                            >
                              <div className="mr-1">Utilization:</div>
                              {val?.utilizationPercent !== null ? (
                                <Progress
                                  percent={val?.utilizationPercent.toFixed(2)}
                                  strokeColor={getProgressColor(
                                    val?.utilizationPercent
                                  )}
                                  // style={{ width: "300px" }}
                                  status={"active"}
                                />
                              ) : (
                                "N/A"
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </>
                    </Col>
                  </>
                );
              })}
            </>
          ) : null}

          {kindOfJourney === "return" || kindOfJourney === "both" ? (
            <>
              <Col span={24} className="mt-2">
                <Row>
                  <div>
                    <img src={returnJourney} />
                  </div>
                  <div>
                    <h3 className="ml-1">Return Journey</h3>
                  </div>
                </Row>
                <hr className="divider mb-2" />
              </Col>

              {allReturnTransitData?.map((val, index) => {
                return (
                  <>
                    <Col span={8} className="mb-1 arrival-container">
                      <Form.Item
                        label={
                          <Row className="arrival-label-container">
                            <div>
                              {index === allReturnTransitData?.length - 1
                                ? "Destination"
                                : index == 0
                                ? "Origin"
                                : "Transit " + index}
                            </div>
                          </Row>
                        }
                      >
                        <Input defaultValue={capitalize(val?.premiseName)} />
                      </Form.Item>
                    </Col>
                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"returnArrivalDate" + index}
                        label="Arrival date"
                      >
                        <DatePicker format={"DD-MM-YYYY"} />
                      </Form.Item>
                    </Col>
                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"returnArrivalTime" + index}
                        label="Arrival time"
                      >
                        <TimePicker use12Hours format="h:mm A" />
                      </Form.Item>
                    </Col>
                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"returnDepartureDate" + index}
                        label="Departure date"
                      >
                        <DatePicker format={"DD-MM-YYYY"} />
                      </Form.Item>
                    </Col>
                    <Col span={4} className="mb-1">
                      <Form.Item
                        name={"returnDepartureTime" + index}
                        label="Departure time"
                      >
                        <TimePicker use12Hours format="h:mm A" />
                      </Form.Item>
                    </Col>
                    {/*  */}
                    <Col span={8}></Col>
                    {val?.actualArrivalDate !== null && (
                      <>
                        <Col span={4} className="mb-1">
                          <Form.Item
                            name={"returnActualArrivalDate" + index}
                            label="Actual arrival date"
                          >
                            <DatePicker format={"DD-MM-YYYY"} />
                          </Form.Item>
                        </Col>
                        <Col span={4} className="mb-1">
                          <Form.Item
                            name={"returnActualArrivalTime" + index}
                            label="Actual arrival time"
                          >
                            <TimePicker use12Hours format="h:mm A" />
                          </Form.Item>
                        </Col>
                      </>
                    )}

                    {val.actualDepartureDate !== null && (
                      <>
                        <Col span={4} className="mb-1">
                          <Form.Item
                            name={"returnActualDepartureDate" + index}
                            label="Actual Departure date"
                          >
                            <DatePicker format={"DD-MM-YYYY"} />
                          </Form.Item>
                        </Col>
                        <Col span={4} className="mb-1">
                          <Form.Item
                            name={"returnActualDepartureTime" + index}
                            label="Actual Departure time"
                          >
                            <TimePicker use12Hours format="h:mm A" />
                          </Form.Item>
                        </Col>
                      </>
                    )}

                    <Col span={24} style={{ background: "#F4F4F4" }}>
                      <>
                        <Row
                          className="mt-1 mb-1"
                          style={{
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Col span={4}>
                            {index !== 0 && (
                              <div>
                                <img src={truck_unloaded} height={25} />
                                &nbsp;&nbsp;&nbsp;
                                <span>
                                  Unloaded:{" "}
                                  {val?.unLoadedWeight !== null ? (
                                    <strong>
                                      {val?.unLoadedWeight + " Kg"}
                                    </strong>
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </div>
                            )}
                          </Col>
                          <Col span={4}>
                            {index !== allReturnTransitData?.length - 1 && (
                              <div>
                                <img src={truck_loaded} height={25} />
                                &nbsp;&nbsp;&nbsp;
                                <span>
                                  Loaded:{" "}
                                  {val?.loadedWeight !== null ? (
                                    <strong>{val?.loadedWeight + " Kg"}</strong>
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </div>
                            )}
                          </Col>
                          <Col span={6}>
                            <div>
                              <img src={delay_icon} height={25} />
                              &nbsp;&nbsp;&nbsp;
                              <span>
                                Reported Delay:{" "}
                                {val.reasonForDelay !== null ? (
                                  <strong>{val?.reasonForDelay}</strong>
                                ) : (
                                  "N/A"
                                )}
                              </span>
                            </div>
                          </Col>
                          {/* <Col span={2}> Utilization : </Col> */}
                          <Col span={6}>
                            <Row
                              style={{
                                flexFlow: "nowrap",
                              }}
                            >
                              <div className="mr-1">Utilization:</div>
                              {val?.utilizationPercent !== null ? (
                                <Progress
                                  percent={val?.utilizationPercent.toFixed(2)}
                                  strokeColor={getProgressColor(
                                    val?.utilizationPercent
                                  )}
                                  // style={{ width: "300px" }}
                                  status={"active"}
                                />
                              ) : (
                                "N/A"
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </>
                    </Col>
                  </>
                );
              })}
            </>
          ) : null}

          <Col span={24} className="mb-1"></Col>

          {tripData?.recursive && (
            <>
              <Col span={24}>
                <Row>
                  <div>
                    <img src={recurrenceIcon} />
                  </div>
                  <div>
                    <h3 className="ml-1">Recurrence Pattern</h3>
                  </div>
                </Row>
              </Col>
              <Col span={24}>
                <hr className="divider mb-2" />
              </Col>
              <div className="flex-column ml-3">
                <p>
                  Recur every week(s) on:
                  <strong className="ml-1">
                    {" " + tripData?.days?.map((val) => val)}
                  </strong>
                </p>

                <p>
                  Ends after:
                  <strong className="ml-1">
                    {tripData?.noOfRecursion + " occurences"}
                  </strong>
                </p>
              </div>
            </>
          )}

          <Col span={24}>
            <hr className="divider m-0" />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
