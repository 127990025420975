import { call } from "redux-saga/effects";

import actions from "../../constants/manifestActions";
import constants from "../../constants/constants";
import { getDataFromAPI } from "../../utils/sagas";

import { getToken, APIs } from "../../utils/worker";
import { getUserAndPremiseId } from "../../utils";

const getTokenId = getToken() || null;
const authToken = getTokenId || null;

const getUserInfo = getUserAndPremiseId();
const premiseId = getUserInfo?.premiseId;
const userId = getUserInfo?.userId;

export function* createManifestWorker({ payload }) {
  const reqParams = {
    url: `${APIs.baseURL}/manifest-service/v1/manifest`,
    successAction: actions.CREATE_MANIFEST_SUCCESS,
    errorAction: actions.CREATE_MANIFEST_ERROR,
    params: { ...payload, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.POST, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}
export function* getManifestWorker({ data }) {
  let getWebToken = authToken;
  let webToken = getWebToken.slice(6);

  const reqParams = {
    url: `${APIs.baseURL}/manifest-service/v1/manifest`,
    successAction: actions.GET_MANIFEST_SUCCESS,
    errorAction: actions.GET_MANIFEST_ERROR,
    params: { ...data, authToken, webToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    // ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}
export function* getManifestVehicleWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/vehicle-service/v1/vehicle`,
    successAction: actions.GET_MANIFEST_VEHICLE_SUCCESS,
    errorAction: actions.GET_MANIFEST_VEHICLE_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    // ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}
export function* updateManifestDriverWorker({ data, id }) {
  const reqParams = {
    url: `${APIs.baseURL}/manifest-service/v1/manifest/${id}/driver`, //Changed
    successAction: actions.PUT_MANIFEST_DRIVER_SUCCESS,
    errorAction: actions.PUT_MANIFEST_DRIVER_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PUT, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* createPacketsWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/v1/packets/`,
    successAction: actions.CREATE_PACKETS_SUCCESS,
    errorAction: actions.CREATE_PACKETS_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.POST);
}

export function* getPacketBarcodeWorker({ payload, query }) {
  const reqParams = {
    url: `${APIs.baseURL}/v1/packets/${query}`,
    successAction: actions.GET_PACKET_BARCODE_SUCCESS,
    errorAction: actions.GET_PACKET_BARCODE_ERROR,
    params: { ...payload, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getPacketListBySatusWorker({ data, q2 }) {
  let getWebToken = authToken;
  let webToken = getWebToken.slice(6);

  const reqParams = {
    // url: `${APIs.baseURL}/v1/packets/status/${data}`,
    url: `${APIs.baseURL}/manifest-service/v1/eligible/${data}/${q2}`,

    successAction: actions.GET_PACKET_LIST_BY_STATUS_SUCCESS,
    errorAction: actions.GET_PACKET_LIST_BY_STATUS_ERROR,
    clearAction: actions.GET_PACKET_LIST_BY_STATUS_CLEAR,
    params: { ...data, authToken, webToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* getShipmentListBySatusWorker({ data }) {
  const reqParams = {
    // url: `${APIs.baseURL}/v1/packets/status/${data}`,
    url: `${APIs.baseURL}/manifest-service/v1/eligible/${data}/NoUse`,

    successAction: actions.GET_SHIPMENT_LIST_BY_STATUS_SUCCESS,
    errorAction: actions.GET_SHIPMENT_LIST_BY_STATUS_ERROR,
    clearAction: actions.GET_SHIPMENT_LIST_BY_STATUS_CLEAR,
    params: { ...data, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* updatePacketStatusWorker({ query, data }) {
  const reqParams = {
    url: `${APIs.baseURL}/v1/packets/${query}`,
    successAction: actions.UPDATE_PACKET_STATUS_SUCCESS,
    errorAction: actions.UPDATE_PACKET_STATUS_ERROR,
    clearAction: actions.UPDATE_PACKET_STATUS_CLEAR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PUT, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* getEligiblePacketsWorker({ data }) {
  let url = "/v1/packets/eligible/packets";
  if (data === undefined) {
    url = "/v1/packets/hub/eligible/packets";
  } else {
    url = "/v1/packets/eligible/packets";
  }
  const reqParams = {
    url: `${APIs.baseURL}${url}`,
    successAction: actions.GET_ELIGIBLE_PACKET_SUCCESS,
    errorAction: actions.GET_ELIGIBLE_PACKET_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* patchPakcetShipmentWorker({ data, q2 }) {
  let getWebToken = authToken;
  let webToken = getWebToken.slice(6);

  const reqParams = {
    url: `${APIs.baseURL}/manifest-service/v1/manifest/${q2}`,
    successAction: actions.PATCH_PACKET_SHIPMENT_SUCCESS,
    errorAction: actions.PATCH_PACKET_SHIPMENT_ERROR,
    params: { ...data, authToken, webToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PATCH, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* deleteShipmentWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/v1/packets/1234/shipment/${data}`,
    successAction: actions.DELETE_SHIPMENT_SUCCESS,
    errorAction: actions.DELETE_SHIPMENT_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.DELETE);
}

export function* patchManifestScanWorker({ data }) {
  let getWebToken = authToken;
  let webToken = getWebToken.slice(6);
  const reqParams = {
    url: `${APIs.baseURL}/manifest-service/v1/manifest/scanned/${data}`, //Changed
    successAction: actions.PATCH_MANIFEST_SCAN_SUCCESS,
    errorAction: actions.PATCH_MANIFEST_SCAN_ERROR,
    params: { ...data, authToken, webToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PATCH, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* getManifestedListWorker({ data }) {
  let getWebToken = authToken;
  let webToken = getWebToken.slice(6);

  const reqParams = {
    url: `${APIs.baseURL}/manifest-service/v1/manifest/${data}`,
    successAction: actions.GET_MANIFESTED_LIST_SUCCESS,
    errorAction: actions.GET_MANIFESTED_LIST_ERROR,
    params: { ...data, authToken, webToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}
//////////////////////////////////////////////
export function* putManifestLockWorker({ data, id }) {
  const reqParams = {
    url: `${APIs.baseURL}/manifest-service/v1/manifest/${id}`,
    successAction: actions.PUT_MANIFEST_LOCK_SUCCESS,
    errorAction: actions.PUT_MANIFEST_LOCK_ERROR,
    clearAction: actions.PUT_MANIFEST_LOCK_CLEAR,
    params: { ...data, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PUT, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* updateDriverDetailsWorker({ data, id }) {
  const reqParams = {
    url: `${APIs.baseURL}/vehicle-service/v1/vehicle/${id}`,
    successAction: actions.UPDATE_DRIVE_DETAILS_SUCCESS,
    errorAction: actions.UPDATE_DRIVE_DETAILS_ERROR,
    clearAction: actions.UPDATE_DRIVE_DETAILS_CLEAR,
    params: { ...data, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PUT, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* getprintManifestWorker({ data }) {
  let webToken = authToken.slice(6);

  const reqParams = {
    // url: `${APIs.baseURL}/vehicle-service/v1/vehicle/${data}`,
    url: `${APIs.baseURL}/manifest-service/v1/manifest/${data}`,
    successAction: actions.PRINT_MANIFEST_SUCCESS,
    errorAction: actions.PRINT_MANIFEST_ERROR,
    clearAction: actions.PRINT_MANIFEST_CLEAR,
    params: { ...data, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
    token: webToken,
  });
}

export function* getViewAllBookingWorker({pageNo,pageSize}) {
  const reqParams = {
    url: `${
      APIs.baseURL
    }/shipment-booking-service/v1/shipments/?pageNo=${pageNo}&pageSize=${pageSize}`,
    successAction: actions.GET_VIEWALLBOOKINGS_SUCCESS,
    errorAction: actions.GET_VIEWALLBOOKINGS_ERROR,
    params: { ...premiseId, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* patchChildShipmentWorker({ awbNumber, data }) {
  const reqParams = {
    url: `${APIs.baseURL}/shipment-service/v1/shipment/scan/child/${awbNumber}`, //changed
    successAction: actions.PATCH_CHILDSHIPMENT_SUCCESS,
    errorAction: actions.PATCH_CHILDSHIPMENT_ERROR,
    params: { ...data, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PATCH, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}
