/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React from "react";
import { Modal, Spin } from "antd";
import crossButton from "../../assests/images/CrossButton.svg";
import Dox from "./Dox/index.jsx";
// import NonDox from "./NonDox/index.jsx";
import "./style.scss";
import "../../commonStyles/crossButton.scss";
// let date = new Date().toLocaleString(undefined, { timeZone: "Asia/Kolkata" });
const ViewBookingSummary = ({
  visible,
  handleCancel = () => {},
  allAppData,
  bookingType,
}) => {
  const summaryDate = allAppData?.bookingTime?.split(" ", 1)[0];

  return (
    <div>
      <Modal
        title="Booking Summary"
        className="booking-summary"
        open={visible}
        width={1000}
        footer={false}
        onCancel={handleCancel}
        maskClosable={false}
        closeIcon={<img src={crossButton} />}
      >
        <Spin spinning={!allAppData}>
          <Dox
            allAppData={allAppData}
            summaryDate={summaryDate}
            bookingType={bookingType}
          />
        </Spin>
      </Modal>
    </div>
  );
};

// BookingSummary.propTypes = {
//   second: PropTypes.third,
// };

export default ViewBookingSummary;
