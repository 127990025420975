/* eslint-disable indent */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { Form, message } from "antd";

import API from "../../../api";
import urls from "../../../api/urls";

import moment from "moment";
import {
  getRatesDox,
  getRatesNonDoxSurface,
  getRatesNonDoxAir,
  standardTempFun,
  fastTrackTempFun,
  valuePlusTempFun,
} from "./rateCardUtils";

import RateCardReturn from "./rateCardReturn";
import { useLocation, useParams } from "react-router-dom";

const {
  UPDATE_LEAD_CP_DETAILS,
  GET_ADDED_RATECARD_CP,
  GET_ALL_RATECARDS,
  GET_SINGLE_RATECARD,
  GET_DEFAUT_RATE_CARD,
  POST_CP_LEAD_RATE_CARD,
  PATCH_CP_LEAD_RATE_CARD,
  GET_LEAD_CP_QUOTE_BY_ID,
  GET_LEAD_CP_BILLING_CONFIG_ID,
} = urls;

export const AllocateRateCard = ({
  handleStepsChange = () => {},
  sideStepsHide = () => {},
  sideStepsShow = () => {},
  goBackStep = () => {},
  rateCardPresent,
  getData,
}) => {
  const [form] = Form.useForm();
  const loc = useLocation();

  const [loading, setLoading] = useState(false);
  const [showAddRateCard, setShowAddRateCard] = useState(false);
  const [standardDox, setStandardDox] = useState([]);
  const [standardNonDox, setStandardNonDox] = useState([]);
  const [fastTrackSurface, setFastTrackSurface] = useState([]);
  const [fastTrackAir, setFastTrackAir] = useState([]);
  const [fastTrackNonDox, setFastTrackNonDox] = useState([]);
  const [valuePlusDox, setValuePlusDox] = useState([]);
  const [valuePlusNonDox, setValuePlusNonDox] = useState([]);
  const [rateAdded, setRateAdded] = useState(false);
  const [rateDisable, setRateDisable] = useState(false);
  const [showTemplateDropdown, setShowTemplateDropdown] = useState(false);
  const [rateCardTemplatesData, setRateCardTemplatesData] = useState([]);
  // const [billingConfigId, setBillingConfigId] = useState(null);
  const [billingConfigData, setBillingConfigData] = useState(null);
  const [rateCardId, setRateCardId] = useState(null);
  const [billingConfigId, setBillingConfigId] = useState(null);

  const userId = JSON.parse(localStorage.getItem("userId"));
  const leadCpId = JSON.parse(localStorage.getItem("leadCPId"));

  useEffect(() => {
    getAllRateCardsNames();
    // form.setFieldValue("templateType", "Default");
    // getDefaultRateCardData();
    getRateCardDetails();
  }, []);

  const getRateCardDetails = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_LEAD_CP_QUOTE_BY_ID + leadCpId
    ).getWithAllHeaders();
    if (response?.status) {
      setLoading(false);
      const res = response?.response?.data?.data;
      form.setFieldsValue({
        rateCardType: res?.rateCardType,
        declaredAmount: res?.declaredAmount,
        quotationName: res?.quotationName,
        ratecardName: res?.ratecardName,
      });
      setBillingConfigId(res?.billingConfigId);

      getBillingConfigDetails(res?.billingConfigId);
      if (res?.rateCardId) {
        getAddedRateCard(res?.rateCardId);
      }
      setRateCardId(res?.rateCardId);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const getBillingConfigDetails = async (billId) => {
    setLoading(true);
    let response = await API.Api(
      GET_LEAD_CP_BILLING_CONFIG_ID + billId
    ).getWithUserNPremiseHeader();

    if (response?.status) {
      setLoading(false);
      let res =
        response?.response?.data?.billingConfigJson?.additionalComponent[0]
          ?.component;

      setBillingConfigData(res);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const getAllRateCardsNames = async () => {
    setLoading(true);
    let response = await API.Api(GET_ALL_RATECARDS).getWithPremiseIdHeader();
    if (response?.status) {
      setLoading(false);
      let res = response?.response?.data?.data;
      if (res) {
        let temp = [];
        res?.map((e) => {
          temp.push({ label: e?.ratecardName, value: e?.id });
        });
        setRateCardTemplatesData(temp);
      }
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const setWeightSlabData = (data, title) => {
    switch (title) {
      case "standardDocs":
        setStandardDox(data);
        break;
      case "standardNonDocs":
        setStandardNonDox(data);
        break;
      case "fastTrackSurface":
        setFastTrackSurface(data);
        break;
      case "fastTrackAir":
        setFastTrackAir(data);
        break;
      case "fastTrackNonDox":
        setFastTrackNonDox(data);
        break;
      case "valueplusDox":
        setValuePlusDox(data);
        break;
      case "valueplusNonDox":
        setValuePlusNonDox(data);
        break;
    }
  };

  const changeDynamicSelect = (title, data) => {
    switch (title) {
      case "standardNonDocs":
        setStandardNonDox(data);
        break;
      case "fastTrackNonDox":
        setFastTrackNonDox(data);
        break;
      case "valueplusNonDox":
        setValuePlusNonDox(data);
        break;
    }
  };

  function handleTemplateResponse(response) {
    if (response) {
      let temp = response?.response.data?.data.services;

      let serviceTypes = {
        STANDARD: [],
        "FAST TRACK": [],
        "VALUE PLUS": [],
      };
      if (temp) {
        temp?.forEach((e) => {
          const serviceType = e?.serviceType?.toUpperCase();
          serviceTypes[serviceType]?.push(e.docs);
        });
      }
      let standardTemp = serviceTypes.STANDARD;
      let fastTrackTemp = serviceTypes["FAST TRACK"];
      let valuePlusTemp = serviceTypes["VALUE PLUS"];

      if (standardTemp) {
        let { standardDoxTemp, standardNonDoxTemp } =
          standardTempFun(standardTemp);
        setStandardNonDox(standardNonDoxTemp);
        setStandardDox(standardDoxTemp);
      }
      if (fastTrackTemp) {
        let {
          fastTrackDoxSurfaceTemp,
          fastTrackDoxAirTemp,
          fastTrackNonDoxTemp,
        } = fastTrackTempFun(fastTrackTemp);
        setFastTrackSurface(fastTrackDoxSurfaceTemp);
        setFastTrackAir(fastTrackDoxAirTemp);
        setFastTrackNonDox(fastTrackNonDoxTemp);
      }
      if (valuePlusTemp) {
        let { valuePlusDoxTemp, valuePlusNonDoxTemp } =
          valuePlusTempFun(valuePlusTemp);
        setValuePlusDox(valuePlusDoxTemp);
        setValuePlusNonDox(valuePlusNonDoxTemp);
      }
    }
  }

  const getDefaultRateCardData = async () => {
    setLoading(true);
    let response = await API.Api(GET_DEFAUT_RATE_CARD).getWithPremiseIdHeader();
    if (response?.status) {
      setLoading(false);
      handleTemplateResponse(response);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const selectTemplate = (e) => {
    form.setFieldValue("templateName", null);
    setShowTemplateDropdown(e.target.value === "Existing" ? true : false);
    if (e.target.value === "Default") {
      getDefaultRateCardData();
    }
  };

  const addRateCard = () => {
    sideStepsHide();
    setShowAddRateCard(true);
  };

  const showSideStep = () => {
    sideStepsShow();
    setShowAddRateCard(false);
  };

  const viewRateCard = () => {
    sideStepsHide();
    setShowAddRateCard(true);
    // initialize();
  };

  const showSideStepCardAdded = () => {
    sideStepsShow();
    setShowAddRateCard(false);
    setRateAdded(true);
  };

  const createRateCard = async (values) => {
    const standardDoxData = getRatesDox(standardDox);
    const fastTrackSurfaceData = getRatesDox(fastTrackSurface);
    const fastTrackAirData = getRatesDox(fastTrackAir);
    const valuePlusDoxData = getRatesDox(valuePlusDox);
    const standardNonDoxSurfaceData = getRatesNonDoxSurface(standardNonDox);
    const standardNonDoxAirData = getRatesNonDoxAir(standardNonDox);
    const fastTrackNonDoxSurfaceData = getRatesNonDoxSurface(fastTrackNonDox);
    const fastTrackNonDoxAirData = getRatesNonDoxAir(fastTrackNonDox);
    const valuePlusNonDoxSurfaceData = getRatesNonDoxSurface(valuePlusNonDox);
    const valuePlusNonDoxAirData = getRatesNonDoxAir(valuePlusNonDox);

    const payload = {
      ...values,
      ratecardName: values?.ratecardName,
      billingConfigId: +billingConfigId,
      selectedType: values?.templateType == "Default" ? "Default" : "Existing",
      existingId: values?.templateName || null,

      services: [
        {
          serviceType: "STANDARD",
          docs: [
            {
              docType: "DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: standardDoxData,
                },
                {
                  mode: "Air",
                  rates: standardDoxData,
                },
              ],
            },
            {
              docType: "NON_DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: standardNonDoxSurfaceData,
                },
                {
                  mode: "Air",
                  rates: standardNonDoxAirData,
                },
              ],
            },
          ],
        },
        {
          serviceType: "FAST TRACK",
          docs: [
            {
              docType: "DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: fastTrackSurfaceData,
                },
                {
                  mode: "Air",
                  rates: fastTrackAirData,
                },
              ],
            },
            {
              docType: "NON_DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: fastTrackNonDoxSurfaceData,
                },
                {
                  mode: "Air",
                  rates: fastTrackNonDoxAirData,
                },
              ],
            },
          ],
        },
        {
          serviceType: "VALUE PLUS",
          docs: [
            {
              docType: "DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: valuePlusDoxData,
                },
                {
                  mode: "Air",
                  rates: valuePlusDoxData,
                },
              ],
            },
            {
              docType: "NON_DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: valuePlusNonDoxSurfaceData,
                },
                {
                  mode: "Air",
                  rates: valuePlusNonDoxAirData,
                },
              ],
            },
          ],
        },
      ],
    };
    let response;
    if (rateCardPresent || loc?.pathname.includes("edit")) {
      response = await API.Api(
        PATCH_CP_LEAD_RATE_CARD + rateCardId + "/" + leadCpId
      ).putIdHeader(payload, { "USER-ID": userId?.userInfo?.userId });
    } else {
      response = await API.Api(
        POST_CP_LEAD_RATE_CARD + leadCpId
      ).postCustomHeader(payload, { "USER-ID": userId?.userInfo?.userId });
    }
    if (response.status) {
      message.success("Rate card added successfully.");
      showSideStepCardAdded();
    } else {
      message.error(response?.response?.data?.message);
    }
  };

  const getRateCardById = async (id) => {
    setLoading(true);
    let response = await API.Api(
      GET_SINGLE_RATECARD + id
    ).getWithPremiseIdHeader();
    if (response?.status) {
      setLoading(false);
      if (response) {
        handleTemplateResponse(response);
      }
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const selectExistingTemplate = (e) => {
    getRateCardById(e);
  };

  const getAddedRateCard = async (ratecardId) => {
    setLoading(true);
    let response = await API.Api(
      GET_ADDED_RATECARD_CP + ratecardId
    ).getWithUserNPremiseHeader();
    if (response?.status) {
      setLoading(false);
      if (response) {
        handleTemplateResponse(response);
        let data = response?.response?.data?.data;
        const fieldsToSet = {
          fov: parseFloat(data?.fov || 0).toFixed(2),
          insurance: parseFloat(data?.insurance || 0).toFixed(2),
          handlingCharges: parseFloat(data?.handlingCharges || 0).toFixed(2),
          premiumServiceCharges: parseFloat(
            data?.premiumServiceCharges || 0
          ).toFixed(2),
          fuelServiceCharges: parseFloat(data?.fuelServiceCharges || 0).toFixed(
            2
          ),
          codCharges: parseFloat(data?.codCharges || 0).toFixed(2),
          financialYear: data?.financialYear,
          category: data?.category && data?.category,
          value: data?.values ? data?.values.split(",") : [],
          fromDate: moment(data?.allocatedFromDate || new Date()),
          ratecardName: data?.ratecardName,
          rateCardType: data?.rateCardType,
          templateType: data?.selectedType,
          templateName: data?.existingRateCardName,
          declaredAmount: data?.declaredAmount,
          quotationName: data?.quotationName,
        };

        Object.entries(fieldsToSet)?.forEach(([fieldName, value]) => {
          form.setFieldValue(fieldName, value);
        });

        setShowTemplateDropdown(
          data?.selectedType === "Existing" ? true : false
        );
      }
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  useEffect(() => {
    if (rateCardPresent) {
      getAddedRateCard(getData?.quotation?.rateCard?.ratecardId);
    }
  }, [sideStepsShow]);

  return (
    <div className="mt-1" style={{ background: "white" }}>
      <RateCardReturn
        form={form}
        standardDox={standardDox}
        standardNonDox={standardNonDox}
        fastTrackSurface={fastTrackSurface}
        fastTrackAir={fastTrackAir}
        fastTrackNonDox={fastTrackNonDox}
        valuePlusDox={valuePlusDox}
        valuePlusNonDox={valuePlusNonDox}
        showAddRateCard={showAddRateCard}
        rateAdded={rateAdded}
        rateCardPresent={rateCardPresent}
        rateDisable={rateDisable}
        rateCardTemplatesData={rateCardTemplatesData}
        showTemplateDropdown={showTemplateDropdown}
        viewRateCard={viewRateCard}
        addRateCard={addRateCard}
        showSideStep={showSideStep}
        selectExistingTemplate={selectExistingTemplate}
        goBackStep={goBackStep}
        handleStepsChange={handleStepsChange}
        createRateCard={createRateCard}
        selectTemplate={selectTemplate}
        setWeightSlabData={setWeightSlabData}
        changeDynamicSelect={changeDynamicSelect}
        billingConfigData={billingConfigData}
      />
    </div>
  );
};

export default React.memo(AllocateRateCard);
