import React from "react";
import { Modal,Row, Col,Input,Button } from "antd";
import "./style.scss";

const OTPModal = ({isModalOpen,setIsModalOpen}) => {
 
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      centered 
      open={isModalOpen} 
      onOk={handleOk} 
      onCancel={handleCancel}
      footer={false}
      className="otp-modal-style"
      maskClosable={false}
      closeIcon
    >
      <Row justify={"center"}>
        <Col><h3>OTP Verification</h3></Col>
      </Row>
      <Row justify={"center"} gutter={24} className="mt-2 mb-2">
        <Col span={12}><Input className="input-field-style"/></Col>
        <Col span={6}><Button className="save-btn" onClick={()=> setIsModalOpen(false)}>VERIFY</Button></Col>
      </Row>
      <Row justify={"center"}>
        <Col>Didn’t receive the OTP? <a>Resend</a></Col>
      </Row>

    </Modal>
  );
};

export default OTPModal;
