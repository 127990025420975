/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, Input, Form, Button, Table, Spin, message } from "antd";
import "./style.scss";
import API from "../../../api";
import urls from "../../../api/urls";
import { capitalize, serialNum } from "../../../utils";
import Heading from "../../../components/Heading";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import UpdateIcon from "../../../assests/images/updateEway.svg";

import whiteUpdateIcon from "../../../assests/images/whiteUpdateEway.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

const {
  CONSOLIDATE_EWAY_BILL,
  EWAYBILL_UPDATE_PARTB,
  AUDIT_EWAY_BILL,
  UPDATED_EWAY_BILL,
  GET_SINGLE_DRS,
} = urls;

const LastMileEWayBill = () => {
  const [form] = Form.useForm();
  const params = useParams();
  // const history = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [updatedEwayData, setUpdatedEwayData] = useState([]);
  // const [updatedEwayData, setUpdatedEwayData] = useState([]);
  const [vehicleNum, setVehicleNum] = useState("");
  const [transPortMode, setTransPortMode] = useState("surface");

  function getModifiedGST(item) {
    const gstNumber = item?.premises?.gst;
    const firstTwoDigits = gstNumber?.substring(0, 2);
    const modifiedDigits = firstTwoDigits?.startsWith("0")
      ? firstTwoDigits?.substring(1)
      : firstTwoDigits;
    return modifiedDigits;
  }

  useEffect(() => {
    getUpdatedEwayBills();
  }, []);

  useEffect(() => {
    getEwayBills();
  }, [updatedEwayData]);

  const columns = [
    {
      title: "SI No",
      dataIndex: "",
      key: "SI",
      render: (a, b, idx) => serialNum(10, 1, idx),
    },
    {
      title: "AWB No.",
      dataIndex: "awbNumber",
      key: "awbNumber",
      render: (text) => {
        return text;
      },
    },
    {
      title: "E-way Bill No",
      dataIndex: "ewayBillNumber",
      key: "ewayBillNumber",
    },
    {
      title: "Destination Hub",
      dataIndex: "destinationHubId",
      key: "destinationHubId",
      render: (record, _data) => {
        return capitalize(record);
      },
    },
    // {
    //   title: "Mode",
    //   dataIndex: "travelBy",
    //   key: "travelBy",
    //   render: (record, _data) => {
    //     return capitalize(record) || "Surface";
    //   },
    // },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      width: 20,
      render: (record, _data) => {
        return (
          <div>
            {_data.ewayUpdated ? (
              <Button
                className=""
                // disabled={_data?.ewayUpdated}
                // onClick={() => SingleConsolidate(_data)}
                style={{
                  borderRadius: "5px",
                  color: "white",
                  background: "#149D11",
                  cursor: "not-allowed",
                }}>
                <img src={whiteUpdateIcon} />
                <span className="ml-1 fw-500">E-WAY BILL UPDATED</span>
              </Button>
            ) : (
              <Button
                className=""
                // disabled={_data?.ewayUpdated}
                style={{
                  borderRadius: "5px",
                  color: "#2E77D0",
                  background: "white",
                }}
                onClick={() => SingleConsolidate(_data)}>
                <img src={UpdateIcon} />
                <span className="ml-1 fw-500">E-WAY BILL UPDATE</span>
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;

  const getEwayBills = async (e) => {
    setLoading(true);
    const response = await API.Api(
      GET_SINGLE_DRS + `${params?.id}`
    ).getWithAllHeaders();
    if (response) {
      setLoading(false);
      const resData = response?.response?.data?.shipments;
      const shipmentDetailsArray = resData
        ?.map((item) => item?.shipmentDetails)
        .filter((item) => item !== null);

      const childShipmentDetailsArray = resData
        ?.map((item) => item?.childDetails)
        .filter((item) => item !== null);

      const mergeParentChild = [
        ...shipmentDetailsArray,
        ...childShipmentDetailsArray,
      ];

      const temp = [];
      for (let i = 0; i < resData.length; i++) {
        if (resData[i]?.shipmentDetails?.ewayBillNumber !== null) {
          if (resData[i]?.shipmentDetails?.destinationHub) {
            resData[i].shipmentDetails.destinationHub =
              resData[i]?.destinationHub;
          }
          if (resData[i]?.childDetails?.ewayBillNumber !== null) {
            if (resData[i]?.childDetails) {
              resData[i].childDetails.destinationHub =
                resData[i]?.destinationHub;
            }
          }
        }
      }
      mergeParentChild?.map((e) => {
        if (e?.ewayBillNumber) {
          temp.push({
            awbNumber: e.childAwbNumber || e.awbNumber,
            ewayBillNumber: e.ewayBillNumber,
            destinationHubId: e.destinationHub,
            travelBy: e.travelBy,
            bookingCompleteTime: e.bookingStartTime,
            bookingStartTime: e.bookingStartTime || e?.createdDate,
            bookingStatus: e.bookingStatus,
            expiryDate: e.expiryDate,
            updatedDate: e.updatedDate,
            value: e.value,
            ewayBillCreateDate: e.ewayBillCreateDate || null,
            ewayUpdated: e.ewayUpdated,
          });
        }
      });
      for (let i = 0; i < temp?.length; i++) {
        if (updatedEwayData?.includes(temp[i]?.ewayBillNumber)) {
          temp[i].ewayUpdated = true;
        } else {
          temp[i].ewayUpdated = false;
        }
      }
      setTransPortMode(
        response?.response?.data?.travelBy == "surface" ? "1" : "3"
      );
      setTableData(temp);
    } else {
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.ewayUpdated,
      name: record.name,
    }),
    selectedRowKeys: selectedRowKeys,
  };

  const handleSubmit = async (params) => {
    setLoading(true);

    if (selectedRowKeys?.length > 0) {
      let temp = selectedRowKeys?.map((val) => {
        return { ewbNo: val };
      });
      const stateGST = getModifiedGST(userId);

      let payload = {
        fromPlace: userId?.premises?.city,
        fromState: +stateGST,
        vehicleNo: vehicleNum,
        transMode: transPortMode,

        transDocDate: params?.transDocDate,
        tripSheetEwbBills: temp,
      };

      let response = await API.Api(CONSOLIDATE_EWAY_BILL).postIdHeader(payload);

      if (response?.response?.data?.status == "1") {
        setLoading(false);
        message.success(
          response?.response?.data?.message || "E-way Bill Updated Successfully"
        );
        auditEwayBill(response?.response?.data, selectedRowKeys, "consolidate");
        setSelectedRowKeys([]);
      } else {
        setLoading(false);
        message.error(
          response?.response?.data?.data || "Failed to Update E-way bill"
        );
      }
    } else {
      message.error("Select an E-way bill");
      setLoading(false);
    }
  };

  async function SingleConsolidate(record) {
    setLoading(true);

    const stateGST = getModifiedGST(userId);

    let payload = {
      vehicleNo: vehicleNum,
      fromPlace: userId?.premises?.city,
      fromState: +stateGST,
      transDocDate: formattedToday,
      ewbNo: record?.ewayBillNumber,
      transMode: transPortMode,
    };

    let response = await API.Api(
      EWAYBILL_UPDATE_PARTB + record?.ewayBillNumber + "/partB"
    ).patchIdHeader(payload);
    if (response?.response?.data?.status == "1") {
      setLoading(false);
      message.success(
        response?.response?.data?.message || "E-way Bill Updated Successfully"
      );
      auditEwayBill(response?.response?.data, record, "single");
    } else {
      setLoading(false);
      message.error(
        response?.response?.data?.data || "Failed to Update E-way bill"
      );
    }
  }

  async function auditEwayBill(singleUpdateRes, record, type) {
    setLoading(true);

    function filterDataByAwbNumbers(dataArray, awbNumbersArray) {
      return dataArray?.filter((item) =>
        awbNumbersArray?.includes(item?.ewayBillNumber)
      );
    }

    let temp = [];
    let payload = {};
    if (type == "single") {
      temp = tableData?.filter(
        (val) => val?.ewayBillNumber === record?.ewayBillNumber
      );

      payload = {
        shipments: temp?.map((val) => {
          return {
            awbnumber: val?.awbNumber,
            ewaybillNo: val?.ewayBillNumber,
            ewayBillCrtDate: val?.ewayBillCreateDate || null,
            ewbBillExpDate: val?.expiryDate,
            awbcrtDate: val?.bookingStartTime,
            shipmentValue: val?.value,
          };
        }),
        tripid: params?.id,
        consEwbNo: "0",
        // vehicleNo: history?.state?.vehicleNo,
        vehicleNo: vehicleNum,
        // transMode: "1",
        transMode: transPortMode == "1" ? "surface" : "air",
        ewbOprPlace: userId?.premises?.city,
      };
    } else {
      let filteredData = filterDataByAwbNumbers(tableData, record);
      temp = filteredData.map((val) => val);

      let x = singleUpdateRes.data;
      let cNum = JSON.parse(x);

      payload = {
        shipments: temp?.map((val) => {
          return {
            awbnumber: val?.awbNumber,
            ewaybillNo: val?.ewayBillNumber,
            ewayBillCrtDate: val?.ewayBillCreateDate || null,
            ewbBillExpDate: val?.expiryDate,
            awbcrtDate: val?.bookingStartTime,
            shipmentValue: val?.value,
          };
        }),
        tripid: params?.id,
        consEwbNo: cNum?.cEwbNo,
        // vehicleNo: history?.state?.vehicleNo,
        vehicleNo: vehicleNum,
        transMode: transPortMode == "1" ? "surface" : "air",
        ewbOprPlace: userId?.premises?.city,
      };
    }

    let response = await API.Api(AUDIT_EWAY_BILL).postIdHeader(payload);

    if (response.status) {
      setLoading(false);
      getUpdatedEwayBills();
    } else {
      setLoading(false);
    }
  }

  const getUpdatedEwayBills = async () => {
    setLoading(true);
    let response = await API.Api(
      `${UPDATED_EWAY_BILL}${params?.id}`
    ).getWithAllHeaders();
    if (response?.status) {
      setUpdatedEwayData(response?.response?.data?.ewaybill);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }>
      <div className="m-2">
        <Heading title={"E-way Bill"} />
        <div className="last-mile-eway-container p-2">
          <Form
            form={form}
            onFinish={handleSubmit}
            name="accountBasicDetails"
            layout="vertical"
            initialValues={{
              transDocDate: formattedToday,
              manifestId: params?.id,
              // driverName: "",
              // vehicleNo: "",
            }}
            scrollToFirstError={{
              behavior: "smooth",
              block: "center",
              inline: "center",
            }}>
            {tableData?.length ? (
              <>
                <Row justify="center" gutter={16} className="m-1">
                  <Col span={8} sm={24} md={8} lg={8} xs={24}>
                    <Form.Item
                      // initialValue={"bottom"}
                      name="transDocDate"
                      label="Date"
                      rules={[
                        {
                          required: false,
                          message: "Select date",
                        },
                      ]}>
                      <Input placeholder="Date" disabled />
                    </Form.Item>
                  </Col>

                  <Col span={8} sm={24} md={8} lg={8} xs={24}>
                    <Form.Item
                      name="manifestId"
                      label="DRS Id"
                      rules={[
                        {
                          required: false,
                          message: "Enter manifest Id",
                        },
                      ]}>
                      <Input disabled placeholder="Enter Manifest Id" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="center" gutter={16} className="m-1">
                  <Col span={8} sm={24} md={8} lg={8} xs={24}>
                    <Form.Item
                      name="driverName"
                      label="Field executive"
                      rules={[
                        {
                          required: true,
                          message: "Enter driver name",
                        },
                      ]}>
                      <Input
                        placeholder="Enter driver name"
                        // disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} sm={24} md={8} lg={8} xs={24}>
                    <Form.Item
                      name="vehicleNo"
                      label="Vehicle number"
                      rules={[
                        {
                          required: true,
                          message: "Enter vehicle number",
                        },
                      ]}>
                      <Input
                        placeholder="Enter vehicle number"
                        // disabled
                        onChange={(e) => setVehicleNum(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="end mb-2">
                  <Button
                    className="save-btn"
                    htmlType="submit"
                    // onClick={form.submit}
                  >
                    CONSOLIDATE UPDATE
                  </Button>
                </Row>
              </>
            ) : (
              <Row justify="end mb-2">
                <h2 style={{ margin: "auto" }}>No E-way bills present</h2>
              </Row>
            )}

            <Table
              // onChange={onSelectChange}
              columns={columns}
              dataSource={tableData}
              scroll={{ x: 500 }}
              // dataSource={[]}
              pagination={false}
              rowKey={(record) => record?.ewayBillNumber}
              rowSelection={{
                ...rowSelection,
              }}
            />
          </Form>
          {tableData?.length < 1 ? (
            <Row justify="end mt-2">
              <Button
                className="save-btn"
                htmlType="submit"
                // onClick={handleStatus}
                onClick={() => navigate("/generate-drs")}>
                DONE
              </Button>
            </Row>
          ) : (
            <Row justify="end mt-2">
              <Button
                className="save-btn"
                htmlType="submit"
                onClick={() => navigate("/generate-drs")}>
                DONE
              </Button>
            </Row>
          )}
        </div>
      </div>
    </Spin>
  );
};
export default LastMileEWayBill;
