/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { Table, Spin } from "antd";
import Heading from "../../../components/Heading";
import API from "../../../api";
import urls from "../../../api/urls";
import { useNavigate } from "react-router-dom";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import "./style.scss";
const OrderHistory = () => {
  const navigate = useNavigate();
  const { ORDER_LIST } = urls;
  const [dataSource, setDataSource] = useState([]);
  const [spinning, setSpinning] = useState(false);

  useEffect(() => {
    getOrderList();
  }, []);

  const getOrderList = async () => {
    setSpinning(true);
    let response = await API.Api(
      ORDER_LIST + "?pageSize=50&pageNumber=0"
    ).getWithUserNPremiseHeader();
    if (response.status) {
      setSpinning(false);
      setDataSource(response?.response?.data);
    } else {
      setSpinning(false);
    }
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
      width: 40,
      render: (text) => (
        <span
          style={{ textTransform: "uppercase", color: "#2E77D0" }}
          className="pointer"
          onClick={() => navigate("/order-details/" + text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 30,
    },
    {
      title: "No. of Products",
      dataIndex: "items",
      key: "cartId",
      width: 30,
      render: (text) => <span> {text?.length}</span>,
    },
    {
      title: "Total Quantity",
      dataIndex: "price",
      key: "price",
      render: (text) => <span>{text?.itemTotal}</span>,
      width: 30,
    },
    {
      title: "Total Price",
      dataIndex: "price",
      key: "city",
      render: (text) => (
        <span style={{ fontSize: "14px", fontWeight: "600" }}>
          ₹&nbsp;{text?.orderTotal}
        </span>
      ),
      width: 30,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <div
          style={{
            color: "#fff",
            backgroundColor:
              text === "PENDING"
                ? "#F7B757"
                : text === "IN-PROGRESS"
                ? "#2A7CE0"
                : text === "DELIVERED"
                ? "#4DC64A"
                : text === "DISPATCHED"
                ? "#807A7A"
                : "#F96E6E",
            padding: "5px",
            fontWeight: "500",
            borderRadius: "7px",
            width: "110px",
            textAlign: "center",
          }}
        >
          {text === "PENDING"
            ? "Pending"
            : text === "IN-PROGRESS"
            ? "In-Progress"
            : text === "DELIVERED"
            ? "Delivered"
            : text === "DISPATCHED"
            ? "Dispatched"
            : "Cancelled"}
        </div>
      ),
      width: 20,
    },
  ];
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  return (
    <Spin
      spinning={spinning}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="p-2">
        <Heading title={"My Order"} id={7} navigateTo={"/dashboard"} />
        <div className="master-main-container profile-container">
          <div className="p-1">
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={{
                pageSize: 10,
                itemRender: itemRender,
              }}
              className="tat-table-fm"
            />
          </div>
        </div>
      </div>
    </Spin>
  );
};
export default OrderHistory;
