/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Tabs } from "antd";
import "./style.scss";
import Heading from "../../../components/Heading";
// import StaffOnboardingSteps from "./StaffSteps";
import ViewStaffTable from "./viewAllStaff";
import CreateStaff from "./createStaff";

const StaffOnboarding = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [destroy, setDestroy] = useState(false);
  const [updateStaff, setUpdateStaff] = useState();

  const changeTab = (key) => {
    setActiveKey(key);
    setDestroy(false);
  };

  function handleEdit(id) {
    setActiveKey("1");
    setUpdateStaff(id);
  }

  function handleSubmit() {
    setActiveKey("2");
    setDestroy(true);
  }

  return (
    <div className="p-2">
      <Heading title="Staff Onboarding" />
      <div className="global-card-tab-container">
        <Tabs
          activeKey={activeKey}
          // destroyInactiveTabPane={destroy}
          destroyInactiveTabPane
          onChange={changeTab}
          items={[
            {
              label: "Staff Onboarding",
              key: "1",
              children: (
                <CreateStaff
                  changeTab={changeTab}
                  handleSubmit={handleSubmit}
                  updateStaff={updateStaff}
                />
              ),
            },
            {
              label: "View All Staff",
              key: "2",
              children: <ViewStaffTable handleEdit={handleEdit} />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default StaffOnboarding;
