/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Steps, Spin, message } from "antd";
import "./style.scss";
import Heading from "../../../components/Heading";
import NormalApi from "../../../api";
import urls from "../../../api/urls";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import { useLocation, useParams } from "react-router-dom";

const Tracker = ({ awb }) => {
  const [subtitle, setSubtitle] = useState(false);
  const [current, setCurrent] = useState(4);
  const [trackerData, setTrackerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(0);
  const { GET_TRACKER, GET_EWAY_TRACKER } = urls;
  let parmas = useParams();
  const history = useLocation();

  const onChange = (value) => {
    setSubtitle(!subtitle);
    setCurrent(value);
  };

  let temp2 = [
    {
      title: "Dispatched to Ahmedabad Hub",
      description: "Wed, 7th April 2023 - 9:10 pm",
    },
  ];

  const getTrakerDetails = async () => {
    setLoading(true);
    let response;
    if (history.state == 2) {
      response = await NormalApi.Api(
        GET_EWAY_TRACKER + parmas?.id
      ).getWithHeader();
    } else {
      response = await NormalApi.Api(GET_TRACKER + parmas?.id).getWithHeader();
    }
    let resData = response?.response?.data;
    if (response?.response?.status == 200) {
      let count = 0;
      for (let i = 0; i < resData?.length; i++) {
        count++;
      }
      setCounter(count);
      setTrackerData(resData);
      setLoading(false);
    } else {
      message.error("Something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    getTrakerDetails();
  }, []);

  let modifiedTrackerData = [];

  trackerData.forEach((e) => {
    modifiedTrackerData.push({
      title: e.location,
      description: e.dateAndTime,
    });
  });

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div style={{ padding: "20px" }} className="tracking-container">
        <Heading title={"Tracking"} />
        <div className="tracking-container-step">
          <div className="tracking-container-step-name">
            {" "}
            <span>Shipment No:</span> <span> {parmas?.id} </span>
          </div>
          <div className="heading-line"></div>
          <div style={{ padding: "20px" }}>
            <Steps
              progressDot
              // current={current}
              current={counter - 1}
              direction="vertical"
              // onChange={onChange}
              // items={temp2}
              items={modifiedTrackerData}
            />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Tracker;
