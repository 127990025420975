import React from "react";
import { Col, Form, Button } from "antd";
const BottomButton = ({ goBackStep }) => {
  return (
    <>
      <Col>
        <Button className="cancel-btn" onClick={goBackStep}>
          CANCEL
        </Button>
      </Col>
      {/* <Col>
        <Button className="cancel-btn" htmlType="submit">
          SAVE
        </Button>
      </Col> */}
      <Col>
        <Form.Item>
          <Button className="save-btn" htmlType="submit">
            SAVE & CONTINUE
          </Button>
        </Form.Item>
      </Col>
    </>
  );
};

export default BottomButton;
