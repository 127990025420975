/* eslint-disable no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Table,
  Radio,
  message,
  Spin,
} from "antd";
import React, { useState, useEffect, useMemo } from "react";
import Heading from "../../../../components/Heading";
import {
  checkNumbervalue,
  checkSpecialCharacter,
  dateFormat_dmy,
  isValidAwb,
} from "../../../../utils";
// import { column } from "./columns";
import API from "../../../../api";
import urls from "../../../../api/urls";
import { useNavigate } from "react-router-dom";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";

import "./style.scss";
import moment from "moment";
const {
  GET_DISCOUNT_ELIGIBLE_LIST,
  APPLY_DISCOUNT,
  GET_DISCOUNT_PERCENT,
  GET_DISCOUNT_SENDERS_NAMES,
} = urls;

function Discount() {
  const [form] = Form.useForm();
  const [percentform] = Form.useForm();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [fetchTable, setFetchTable] = useState(false);
  const { Option } = Select;
  const [discountValue, setDiscountValue] = useState();
  const [radioValue, setRadioValue] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [senderNames, setSenderNames] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [discountPercentage, setDiscountPercentage] = useState();
  const [mainDataSource, setmainDataSource] = useState([]);
  const [discountPercent, setDiscountPercent] = useState(null);

  const columns = [
    {
      title: "AWB Number",
      dataIndex: "awbNumber",
      key: "awbNumber",
      width: 10,
    },
    {
      title: "Sender Name",
      dataIndex: "senderName",
      key: "senderName",
      width: 9,
      render: (text) => text?.toUpperCase(),
    },
    {
      title: "Receiver Name",
      dataIndex: "receiverName",
      key: "receiverName",
      width: 9,
      render: (text) => text?.toUpperCase(),
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      key: "serviceType",
      width: 8,
      render: (text) => text?.toUpperCase(),
    },
    {
      title: "Doc Type",
      dataIndex: "docType",
      key: "docType",
      width: 6,
      render: (text) => text?.toUpperCase(),
    },
    {
      title: "Travel By",
      dataIndex: "travelBy",
      key: "travelBy",
      width: 6,
      render: (text) => text?.toUpperCase(),
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: 6,
    },
    {
      title: "Charges",
      dataIndex: "charges",
      key: "charges",
      width: 6,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      width: 6,

      render: (text) => (text == undefined ? "0.00" : text),
    },
    {
      title: "Discounted Charges",
      dataIndex: "discountedCharges",
      key: "discountedCharges",
      width: 8,
      className: "text-wrap",
      render: (text) => (text == undefined ? "0.00" : text),
    },
    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
      width: 5,
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
      width: 5,
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
      width: 5,
    },
    {
      title: "CESS",
      dataIndex: "cess",
      key: "cess",
      width: 5,
    },
    {
      title: "Freight",
      dataIndex: "freight",
      key: "freight",
      width: 5,
    },
  ];

  const getSenderNames = async (dateString) => {
    setLoading(true);
    getDiscountPercent();
    const parts = dateString.split("-");
    const reversedDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;

    let response = await API.Api(
      GET_DISCOUNT_SENDERS_NAMES + reversedDateString
    ).getWithAllHeaders();
    if (response?.status) {
      setLoading(false);

      let res = response?.response?.data;
      setSenderNames(
        res.map((val) => {
          return { label: val, value: val };
        })
      );
    } else {
      message.error(response?.response?.data.message || "Network error");
      setLoading(false);
    }
  };

  const getDiscountPercent = async (dateString) => {
    setLoading(true);

    let response = await API.Api(GET_DISCOUNT_PERCENT).getWithAllHeaders();
    if (response?.status) {
      setLoading(false);

      let res = response?.response?.data;
      let getPercent = res.find((val) =>
        val.shipmentConfigName.includes("DISCNT_ON_PERCENTAGE")
      );

      setDiscountPercentage(getPercent?.shipmentConfigValue);
      // shipmentConfigValue
    } else {
      message.error(response?.response?.data.message || "Network error");
      setLoading(false);
    }
  };

  const calculateDiscountsAndUpdate = () => {
    // Calculate total amount
    const totalAmount = dataSource.reduce(
      (acc, item) => acc + parseFloat(item.charges),
      0
    );
    // Calculate discount as a percentage of the total amount
    let discountPercentage;
    if (radioValue === 1) {
      discountPercentage = parseFloat(discountValue) / 100;
    } else {
      // Absolute discount
      discountPercentage = parseFloat(discountValue) / totalAmount;
    }

    setDiscountPercent((discountPercentage * 100).toFixed(2));
    // Update dataSource with calculated discounts
    const updatedDataSource = dataSource.map((item) => {
      let discount;
      if (radioValue === 1) {
        // Percentage-based discount
        discount = (item.charges * discountPercentage).toFixed(2);
      } else {
        // Absolute discount
        discount = (parseFloat(item.charges) * discountPercentage).toFixed(2);
      }

      const discountedCharges = (item.charges - discount).toFixed(2);
      const sgst = item.sgst !== 0 ? (discountedCharges * 0.09).toFixed(2) : 0;
      const cgst = item.cgst !== 0 ? (discountedCharges * 0.09).toFixed(2) : 0;
      const igst = item.igst !== 0 ? (discountedCharges * 0.18).toFixed(2) : 0;
      const cess = item.cess !== 0 ? item.cess : 0;
      const freight = (
        +discountedCharges +
        +sgst +
        +cgst +
        +igst +
        +cess
      ).toFixed(2);

      return {
        ...item,
        discount,
        discountedCharges,
        sgst,
        cgst,
        igst,
        cess,
        freight,
      };
    });
    // Update dataSource state
    setDataSource(updatedDataSource);
  };

  const onSubmit = async (params) => {
    setFetchTable(!fetchTable);
    setLoading(true);

    const parts = fromDate.split("-");
    const reversedDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
    // console.log(params, "params");
    let response;
    if (params?.toDoc) {
      response = await API.Api(
        `${GET_DISCOUNT_ELIGIBLE_LIST}?date=${reversedDateString}&senderName=${params?.senderName}&fromDocNo=${params?.fromDoc}&toDocNo=${params?.toDoc}`
      ).getWithAllHeaders();
    } else {
      response = await API.Api(
        `${GET_DISCOUNT_ELIGIBLE_LIST}?date=${reversedDateString}&senderName=${params?.senderName}`
      ).getWithAllHeaders();
    }

    if (response?.status) {
      setLoading(false);

      let res = response?.response?.data;
      const dataSourceWithTotal = [
        ...res,
        {
          key: "total",
          name: "Total",
          ...newTotal(res),
          awbNumber: "Total",
        },
      ];
      setmainDataSource(dataSourceWithTotal);
      setDataSource(dataSourceWithTotal);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network erro");
      setDataSource([]);
    }
  };

  const onChange = (e) => {
    setDiscountPercent(null);
    setDiscountValue("");
    percentform.resetFields();
    // console.log("radio checked", e.target.value);
    setRadioValue(e.target.value);
    setDataSource(mainDataSource);
  };

  const changeFromDate = (date, dateString) => {
    setFromDate(dateString);
    form.setFieldsValue({ senderName: null });
    getSenderNames(dateString);
    setDataSource([]);
  };

  const newTotal = (data) => {
    let temp = data?.reduce(
      (acc, curr) => {
        return {
          charges: (parseFloat(acc.charges) + parseFloat(curr.charges)).toFixed(
            2
          ),
          freight: acc?.freight
            ? (parseFloat(acc?.freight) + parseFloat(curr?.freight)).toFixed(2)
            : 0,
          sgst: (parseFloat(acc.sgst) + parseFloat(curr.sgst)).toFixed(2),
          cgst: (parseFloat(acc.cgst) + parseFloat(curr.cgst)).toFixed(2),
          igst: (parseFloat(acc.igst) + parseFloat(curr.igst)).toFixed(2),
          cess: (parseFloat(acc.cess) + parseFloat(curr.cess)).toFixed(2),
          discount: (
            parseFloat(acc.discount) +
            (curr.discount ? parseFloat(curr.discount) : 0)
          ).toFixed(2), // handle case where discount is undefined
          discountedCharges: (
            parseFloat(acc.discountedCharges) +
            (curr.discountedCharges ? parseFloat(curr.discountedCharges) : 0)
          ).toFixed(2), // handle case where discountedCharges is undefined
        };
      },
      {
        charges: 0,
        freight: 0,
        sgst: 0,
        cgst: 0,
        igst: 0,
        discount: 0,
        cess: 0,
        discountedCharges: 0,
      }
    );
    return temp;
  };

  const applyDiscount = async (params) => {
    setLoading(true);
    let getBookDate = form.getFieldsValue().bookingDate;
    let dateFormatDD = moment(getBookDate).format("DD/MM/YYYY");

    const payload = dataSource.map((val) => {
      const {
        charges: originalCharges,
        freight: frightCharges,
        sgst: sgst,
        cgst: cgst,
        igst: igst,
        cess: cess,
        awbNumber: documentNumber,
        // discount: discount,
        // discountedCharges: discountedCharges,
      } = val; // Rename charges to freightCharges and get the rest of the object
      return {
        documentNumber,
        // discountedCharges,
        // discount,
        cgst,
        igst,
        cess,
        sgst,
        originalCharges,
        frightCharges,
      }; // Return the object with the renamed key
    });

    payload.pop();

    const response = await API.Api(`${APPLY_DISCOUNT}`).postIdHeader({
      documentList: payload,
      discount: +discountPercent,
      bookingDate: dateFormatDD,
      // discount: 26,
      senderName: form.getFieldValue("senderName"),
    });
    if (response?.status) {
      setLoading(false);

      // console.log("succes");
      navigate("/retail-discounted-list");
    } else {
      setLoading(false);
      // console.log(response, "res");
      message.error(response?.response?.data?.discount);
    }
  };

  const disabledDate = useMemo(() => {
    return (current) => {
      return current && current > moment().endOf("day");
    };
  }, []);

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div
        className="p-2 retail-discount"
        // style={{ background: "white", height: "100%" }}
      >
        <div>
          <Heading title={"Retail Discount"} />
        </div>

        <Form
          layout="vertical"
          form={form}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          initialValues={{
            // bookingDate: "2024-04-17T06:21:36.525Z",
            // senderName: "Abhishek",
            fromDoc: "",
            toDoc: "",
          }}
          onFinish={onSubmit}
          // onKeyPress={handleKeyPress}
          className="global-form"
        >
          <section className="p-1" style={{ background: "white" }}>
            <Row className="global-form-row" gutter={16}>
              <Col lg={5} md={5} sm={5}>
                <Form.Item
                  label="Booking Date"
                  name="bookingDate"
                  rules={[
                    {
                      required: true,
                      message: "Please select booking date",
                    },
                  ]}
                >
                  <DatePicker
                    // style={{ color: "black" }}
                    format={dateFormat_dmy}
                    onChange={(date, dateString) => {
                      changeFromDate(date, dateString);
                    }}
                    disabledDate={disabledDate}
                    // defaultValue={moment(new Date(), dateFormat_dmy)}
                    className="input-field-style"
                  />
                </Form.Item>
              </Col>
              <Col lg={5} md={5} sm={5}>
                <Form.Item
                  label="Sender Name"
                  name="senderName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter sender name",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select sender"
                    optionFilterProp="children"
                    onSelect={() => setDataSource([])}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase())
                    }
                    options={senderNames}
                  />
                </Form.Item>
              </Col>

              <Col lg={5} md={5} sm={5}>
                <Form.Item
                  label="From Doc"
                  name="fromDoc"
                  onKeyPress={(event) => {
                    if (
                      checkNumbervalue(event) ||
                      checkSpecialCharacter(event) ||
                      event.target.value.length >= 14
                    ) {
                      event.preventDefault();
                    }
                  }}
                  rules={[
                    {
                      required: false,
                      // message: "Please enter value",
                    },
                    {
                      validator: (_, value) => {
                        if (value.length > 10) {
                          if (!isValidAwb(value)) {
                            return Promise.reject(
                              "Please enter a valid doc number."
                            );
                          }
                          return Promise.resolve();
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter from Doc",
                  //   },
                  // ]}
                >
                  <Input placeholder="Enter from Doc " />
                </Form.Item>
              </Col>
              <Col lg={5} md={5} sm={5}>
                <Form.Item
                  label="To Doc"
                  name="toDoc"
                  onKeyPress={(event) => {
                    if (
                      checkNumbervalue(event) ||
                      checkSpecialCharacter(event) ||
                      event.target.value.length >= 14
                    ) {
                      event.preventDefault();
                    }
                  }}
                  rules={[
                    {
                      validator: (_, value) => {
                        if (
                          form.getFieldValue("fromDoc").length &&
                          value == ""
                        ) {
                          return Promise.reject("Please enter to doc ");
                        }
                        if (value.length > 10) {
                          if (!isValidAwb(value)) {
                            return Promise.reject(
                              "Please enter a valid doc number."
                            );
                          }
                          return Promise.resolve();
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input placeholder="Enter to Doc " />
                </Form.Item>
              </Col>
              <Col lg={3} md={3} sm={3}>
                <Button className=" save-btn mt-2" onClick={form.submit}>
                  SUBMIT
                </Button>
              </Col>
            </Row>
          </section>
        </Form>
        {dataSource.length ? (
          <>
            <section className="mt-1" style={{ background: "white" }}>
              <Table
                columns={columns}
                pagination={false}
                bordered
                // total={data?.totalCount}
                defaultCurrent={1}
                dataSource={dataSource}
                scroll={{
                  x: 1300,
                  y: 1200,
                }}
                // headerClassName="header"
                // className="view-table table-head-sticky"
              />
            </section>
            <section className="mt-1 p-1" style={{ background: "white" }}>
              <Form
                layout="vertical"
                form={percentform}
                onFinish={calculateDiscountsAndUpdate}
                // onKeyPress={handleKeyPress}
                className="global-form"
              >
                <div className="mt-1">
                  <div className="mb-2 mt-2">
                    <Radio.Group onChange={onChange} value={radioValue}>
                      <Radio value={1}>Percentage</Radio>
                      <Radio value={2}>Absolute</Radio>
                    </Radio.Group>
                  </div>

                  <Col lg={6} md={6} sm={6}>
                    <Form.Item
                      label={
                        radioValue == 1
                          ? "Discount Percentage %"
                          : "Discount Absolute"
                      }
                      className="global-form-row"
                      name="discountValue"
                      rules={[
                        {
                          required: true,
                          message: "Enter discount value",
                        },
                        () => ({
                          validator(rule, value = "") {
                            setDiscountPercent(null);
                            if (
                              value > discountPercentage &&
                              radioValue === 1
                            ) {
                              return Promise.reject(
                                "Discount value cannot exceed 25%"
                              );
                            } else {
                              let d =
                                (value /
                                  dataSource[dataSource.length - 1].charges) *
                                100;

                              if (d > discountPercentage && radioValue === 2) {
                                return Promise.reject(
                                  "Discount value cannot exceed 25%"
                                );
                              } else {
                                setDiscountValue(value);
                                return Promise.resolve();
                              }
                            }
                          },
                          validateTrigger: "onChange",
                        }),
                      ]}
                      onKeyPress={(event) => {
                        if (checkNumbervalue(event) || event.key === ".") {
                          event.preventDefault();
                        }
                      }}
                    >
                      <Input
                        maxLength={radioValue == 1 ? 3 : 6}
                        style={{ width: "80%" }}
                        placeholder="Enter discount value"
                        // value={discountValue}
                        // onChange={handleDiscountChange}
                      />
                    </Form.Item>
                  </Col>
                </div>
                <div className="mt-2">
                  <Row>
                    <Col md={3} lg={3} sm={12}>
                      <Button className="save-btn" onClick={percentform.submit}>
                        Calculate Discount
                      </Button>
                    </Col>

                    <Col md={3} lg={3} sm={12}>
                      <Button
                        className="save-btn"
                        onClick={applyDiscount}
                        disabled={discountPercent == null}
                      >
                        Apply Discount
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </section>
          </>
        ) : null}
      </div>
    </Spin>
  );
}

export default Discount;
