import React, { useState, useEffect } from "react";
import { Tabs, message } from "antd";
import API from "../../../api";
import { APIs } from "../../../utils/worker";
import AddProduct from "../AddProduct";
import ViewProudct from "../viewProduct";
import Heading from "../../../components/Heading";
import { useLocation } from "react-router";

function ProductTabs() {
  const [key, setKey] = useState("1");
  const history = useLocation();
  const [editDetails, setEditDetails] = useState(null);
  const [addOrEdit, setAddOrEdit] = useState(false);

  useEffect(() => {
    if (history?.state !== "viewcp") {
      setKey("1");
    } else {
      setKey("2");
    }
  }, [history?.state]);

  const onChange = (key) => {
    setEditDetails(null);
    setAddOrEdit(false);
    setKey(key);
  };

  const handleEdit = async (values) => {
    let response = await API.Api(
      `${APIs.baseURL}/item/v1/${values?.id}`
    ).getWithUserNPremiseHeader();

    if (response?.status) {
      setEditDetails(response?.response?.data);
    } else {
      if (!response.status) {
        message.error("Network error");
      }
    }
    setAddOrEdit(true);
    setKey("2");
  };

  let label = !addOrEdit ? "Add Product" : "Edit Product";
  return (
    <div className="p-2">
      <Heading title={"Product"} />
      <div className="rate-card-tab-container">
        <Tabs
          activeKey={key}
          destroyInactiveTabPane
          items={[
            {
              label: "View Product",
              key: "1",
              children: (
                <ViewProudct
                  key={key}
                  onChangekey={onChange}
                  handleEdit={handleEdit}
                />
              ),
            },
            {
              label: label,
              key: "2",
              children: (
                <AddProduct
                  onChange={onChange}
                  editDetails={editDetails}
                  handleEdit={handleEdit}
                  addOrEdit={addOrEdit}
                />
              ),
            },
          ]}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default ProductTabs;
