/* eslint-disable no-unused-vars */
import React, { useEffect, Suspense, useState } from "react";
import { BrowserRouter, Routes, Route, Router } from "react-router-dom";
import ErrorBoundary from "./errorBoundary/ErrorBoundary";
import { configureAmplify } from "../src/views/Auth/auth";
import MainLayout from "./views/MainLayout";
import { routeList } from "../src/config/routes";
import { commonRoutes } from "../src/config/commonRoutes";
import { globalPath } from "./config/globalPaths";
const PageNotFound = React.lazy(() => import("./views/404"));
import "antd/dist/antd.css";
import "./App.scss";
import { APIs, getPremiseId } from "./utils/worker";

const App = () => {
  const [roles, setRoles] = useState();
  const [userInfo, setUserInfo] = useState(
    localStorage.getItem("loggedOutTime")
  );

  useEffect(() => {
    configureAmplify(true);
  }, []);

  useEffect(() => {
    let userInfo = localStorage.getItem("loggedOutTime");
    let getRols = JSON.parse(localStorage.getItem("userinfo"));
    const getRole = getRols?.roles[0]?.replace(/.*_/, "");

    setUserInfo(JSON.parse(userInfo));
    setRoles(getRols);
    // if (
    //   !globalPath.includes(window.location.pathname) &&
    //   getRole === "HUBADMIN"
    // ) {
    //   getTodaysData();
    // }
  }, []);

  useEffect(() => {
    if (!globalPath.includes(window.location.pathname)) {
      sessionOut();
    }
  }, []);

  useEffect(() => {
    let loc = window.location.pathname;
    let check = loc?.includes("view-client");
    if (check) {
      localStorage.setItem("viewClient", loc);
    }
  }, []);

  const sessionOut = () => {
    setInterval(() => {
      const date = new Date();
      if (date.getTime() >= userInfo) {
        window.location.assign("/");
        let url = localStorage.setItem("viewClient");
        localStorage.clear();
        localStorage.setItem("viewClient", url);
        document.cookie = "userToken=";
        sessionStorage.clear();
      }
    }, 1000);
  };
  let eventSource = undefined;

  const premiseId = getPremiseId();
  const { baseURL } = APIs;

  // const getTodaysData = async () => {
  //   const a = premiseId.split("Bearer")[1];
  //   eventSource = new EventSource(
  //     `${baseURL}/hubops-report-service/v1/dashboard/${+a}`
  //   );

  //   eventSource.onmessage = (event) => {
  //     const data = JSON.parse(event.data);
  //     if (data) {
  //       // const array = dataConvertTOArray(data);
  //       sessionStorage.setItem("myValue", JSON.stringify(data));
  //     }
  //   };
  // };

  const dynamicRoutes = () => {
    let temp = [];
    routeList.map((item, index) => {
      item.role.some((rt) => {
        if (roles && roles?.roles?.includes(rt)) {
          temp.push(
            <Route
              exact
              key={`route_${index}`}
              path={item.route}
              element={<item.component />}
            />
          );
        }
      });
    });

    return temp;
  };

  const globalRoutes = () => {
    let temp = [];
    commonRoutes.map((item, index) => {
      temp.push(
        <Route
          exact
          key={`route_${index}`}
          path={item.route}
          element={<item.component />}
        />
      );
    });

    return temp;
  };

  return (
    <BrowserRouter basename="/">
      <MainLayout
        component={
          <Suspense fallback={null}>
            <ErrorBoundary>
              <Routes>
                <>
                  {globalRoutes()}
                  <Route path="*" element={<PageNotFound />} />
                  {dynamicRoutes()}
                </>
              </Routes>
            </ErrorBoundary>
          </Suspense>
        }
      />
    </BrowserRouter>
  );
};

export default App;
