/* eslint-disable no-case-declarations */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Spin,
  Form,
  Row,
  Col,
  Collapse,
  Input,
  Select,
  DatePicker,
  Button,
  message,
} from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import FovForm from "../../Master/RateCard/CashbookingRateCard/fovForm";
import AllocateDetails from "./AllocatedDetails";
import RateCardTable from "../../LeadGenerationCP/AllocateRateCard/RateCardTable";
import { cashBookingRateCardData } from "../../Master/RateCard/CashbookingRateCard/cashBookingRateCardData";
import moment from "moment";
import Alert from "../../../components/Alerts";
import { dateFormateSlashFormat, getFinancialYearData } from "../../../utils";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

import "./style.scss";
import Heading from "../../../components/Heading";
import API from "../../../api";
import urls from "../../../api/urls";
import {
  getRatesDox,
  getRatesNonDoxSurface,
  getRatesNonDoxAir,
  standardTempFun,
  fastTrackTempFun,
  valuePlusTempFun,
  getFieldValues,
  fovChargeRule,
} from "../../LeadGenerationCP/AllocateRateCard/rateCardUtils";
import { useNavigate, useParams, useLocation } from "react-router-dom";

function LeadApprovalConfirmation() {
  let parmas = useParams();
  const { state } = useLocation();

  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [standardDox, setStandardDox] = useState([]);
  const [standardNonDox, setStandardNonDox] = useState([]);
  const [fastTrackSurface, setFastTrackSurface] = useState([]);
  const [fastTrackAir, setFastTrackAir] = useState([]);
  const [fastTrackNonDox, setFastTrackNonDox] = useState([]);
  const [valuePlusDox, setValuePlusDox] = useState([]);
  const [valuePlusNonDox, setValuePlusNonDox] = useState([]);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [alertData, setAlertData] = useState(false);
  const [financialYearData, setFinancialYearData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [existingRateCardType, setExistingRateCardType] = useState([]);
  const {
    GET_DEFAULT_RETAL_RATECARD,
    GET_RETAIL_EXISTING_RATECARD,
    GET_RETAIL_EXISTING_RATECARD_BY_ID,
    GET_RATE_CARD_APPROVAL,
    POST_CP_LEAD_RATE_CARD,
    GET_DETAILS,
  } = urls;

  const navigate = useNavigate();

  let {
    standardDoxDatas,
    fastTrackSurfaceDatas,
    standardNonDoxDatas,
    fastTrackAirDatas,
    fastTrackNonDoxDatas,
    valuePlusDoxDatas,
    valuePlusNonDoxDatas,
  } = cashBookingRateCardData();

  useEffect(() => {
    if (!state?.view) {
      getDefaultRateCardData(parmas?.id, "viewRateCard");
    } else {
      getLeadDetils();
    }
  }, [state?.view]);

  useEffect(() => {
    setInitalRateCard();
  }, []);

  const getLeadDetils = async () => {
    let response = await API.Api(GET_DETAILS + 2).getWithUserNPremiseHeader();
    if (response.status) {
      form.setFieldValue(
        "ratecardName",
        response?.response?.data?.data?.templateName
      );
      form.setFieldValue(
        "businessVolume",
        response?.response?.data?.data?.businessVolume
      );
    } else {
      message.error("Error");
    }
  };

  const setInitalRateCard = () => {
    setStandardDox(standardDoxDatas);
    setStandardNonDox(standardNonDoxDatas);
    setFastTrackSurface(fastTrackSurfaceDatas);
    setFastTrackAir(fastTrackAirDatas);
    setFastTrackNonDox(fastTrackNonDoxDatas);
    setValuePlusDox(valuePlusDoxDatas);
    setValuePlusNonDox(valuePlusNonDoxDatas);

    const financialYearRange = getFinancialYearData();
    setFinancialYearData(financialYearRange);
    form.setFieldValue("financialYear", financialYearRange[0]);
  };

  const onSubmit = async (values) => {
    const standardDoxData = getRatesDox(standardDox);
    const fastTrackSurfaceData = getRatesDox(fastTrackSurface);
    const fastTrackAirData = getRatesDox(fastTrackAir);
    const valuePlusDoxData = getRatesDox(valuePlusDox);
    const standardNonDoxSurfaceData = getRatesNonDoxSurface(standardNonDox);
    const standardNonDoxAirData = getRatesNonDoxAir(standardNonDox);
    const fastTrackNonDoxSurfaceData = getRatesNonDoxSurface(fastTrackNonDox);
    const fastTrackNonDoxAirData = getRatesNonDoxAir(fastTrackNonDox);
    const valuePlusNonDoxSurfaceData = getRatesNonDoxSurface(valuePlusNonDox);
    const valuePlusNonDoxAirData = getRatesNonDoxAir(valuePlusNonDox);

    delete values.templateName;
    const {
      validityDate,
      businessVolume,
      remark,
      ratecardName,
      financialYear,
      fov,
      insurance,
      handlingCharges,
      premiumServiceCharges,
      fuelServiceCharges,
      codCharges,
      expiryThreshold,
      rateCardType,
      rateCatdList,
    } = values;
    const payload = {
      leadId: parmas?.id,
      businessVolume,
      validityDate: moment(validityDate).format(dateFormateSlashFormat),
      remark,
      approvalResponses: "approved",
      selectedType: rateCardType,
      existingId: rateCatdList,
      existingRateCardName: null,
      financialYear,
      expiryThreshold,
      // ratecard: {
      //   ratecardName,
      //   financialYear,
      //   fov,
      //   insurance,
      //   handlingCharges,
      //   premiumServiceCharges,
      //   fuelServiceCharges,
      //   codCharges,
      //   expiryThreshold,
      //   allocatedToDate: moment(values?.allocatedToDate).format(
      //     dateFormateSlashFormat
      //   ),
      //   allocatedFromDate: moment(values?.allocatedFromDate).format(
      //     dateFormateSlashFormat
      //   ),

      //   services: [
      //     {
      //       serviceType: "STANDARD",
      //       docs: [
      //         {
      //           docType: "DOX",
      //           transports: [
      //             {
      //               mode: "Surface",
      //               rates: standardDoxData,
      //             },
      //             {
      //               mode: "Air",
      //               rates: standardDoxData,
      //             },
      //           ],
      //         },
      //         {
      //           docType: "NON_DOX",
      //           transports: [
      //             {
      //               mode: "Surface",
      //               rates: standardNonDoxSurfaceData,
      //             },
      //             {
      //               mode: "Air",
      //               rates: standardNonDoxAirData,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       serviceType: "FAST TRACK",
      //       docs: [
      //         {
      //           docType: "DOX",
      //           transports: [
      //             {
      //               mode: "Surface",
      //               rates: fastTrackSurfaceData,
      //             },
      //             {
      //               mode: "Air",
      //               rates: fastTrackAirData,
      //             },
      //           ],
      //         },
      //         {
      //           docType: "NON_DOX",
      //           transports: [
      //             {
      //               mode: "Surface",
      //               rates: fastTrackNonDoxSurfaceData,
      //             },
      //             {
      //               mode: "Air",
      //               rates: fastTrackNonDoxAirData,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       serviceType: "VALUE PLUS",
      //       docs: [
      //         {
      //           docType: "DOX",
      //           transports: [
      //             {
      //               mode: "Surface",
      //               rates: valuePlusDoxData,
      //             },
      //             {
      //               mode: "Air",
      //               rates: valuePlusDoxData,
      //             },
      //           ],
      //         },
      //         {
      //           docType: "NON_DOX",
      //           transports: [
      //             {
      //               mode: "Surface",
      //               rates: valuePlusNonDoxSurfaceData,
      //             },
      //             {
      //               mode: "Air",
      //               rates: valuePlusNonDoxAirData,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //   ],
      // },
    };
    let response = await API.Api(GET_RATE_CARD_APPROVAL).putCustomIdHeader(
      payload
    );
    if (response.status) {
      message.success("Rate card added successfully.");
      setAlertData({
        visible: true,
        message:
          response?.response?.data?.message || "Approval process completed",
      });
      setTimeout(() => {
        navigate("/cp-approval-lead-list");
      }, 3000);
    } else {
      message.error(response.response.data.message);
    }
  };
  const upperCase = (e) => {
    let splitStr = e.target.value.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    form.setFieldValue("ratecardName", splitStr.join(" "));
  };

  const getDefaultRateCardData = async (e, string) => {
    setLoading(true);
    let response;
    const conditionBaseAPiCall = {
      defult: GET_DEFAULT_RETAL_RATECARD + e,
      existing: GET_RETAIL_EXISTING_RATECARD,
      viewRateCard: POST_CP_LEAD_RATE_CARD + e,
      ratecardId: GET_RETAIL_EXISTING_RATECARD_BY_ID + e,
    };
    response = await API.Api(conditionBaseAPiCall[string]).getWithAllHeaders();

    if (response?.status) {
      setLoading(false);
      const conditionBaseResponse = {
        defult: function () {
          setExistingRateCardType([]);
          handleTemplateResponse(response);
        },
        existing: function () {
          setInitalRateCard();
          setExistingRateCardType(response?.response?.data);
        },
        viewRateCard: () => handleTemplateResponse(response),
        ratecardId: () => handleTemplateResponse(response),
      };
      conditionBaseResponse[string]();
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  function handleTemplateResponse(response) {
    if (response) {
      let data = response.response.data?.data;
      if (response) {
        const fieldsToSet = getFieldValues(data);

        Object.entries(fieldsToSet).forEach(([fieldName, value]) => {
          form.setFieldValue(fieldName, value);
        });
      }
      let temp = data.services;

      let serviceTypes = {
        STANDARD: [],
        "FAST TRACK": [],
        "VALUE PLUS": [],
      };
      if (temp) {
        temp.forEach((e) => {
          const serviceType = e.serviceType.toUpperCase();
          serviceTypes[serviceType]?.push(e.docs);
        });
      }
      let standardTemp = serviceTypes.STANDARD;
      let fastTrackTemp = serviceTypes["FAST TRACK"];
      let valuePlusTemp = serviceTypes["VALUE PLUS"];

      if (standardTemp) {
        let { standardDoxTemp, standardNonDoxTemp } =
          standardTempFun(standardTemp);
        setStandardNonDox(standardNonDoxTemp);
        setStandardDox(standardDoxTemp);
      }
      if (fastTrackTemp) {
        let {
          fastTrackDoxSurfaceTemp,
          fastTrackDoxAirTemp,
          fastTrackNonDoxTemp,
        } = fastTrackTempFun(fastTrackTemp);
        setFastTrackSurface(fastTrackDoxSurfaceTemp);
        setFastTrackAir(fastTrackDoxAirTemp);
        setFastTrackNonDox(fastTrackNonDoxTemp);
      }
      if (valuePlusTemp) {
        let { valuePlusDoxTemp, valuePlusNonDoxTemp } =
          valuePlusTempFun(valuePlusTemp);

        setValuePlusDox(valuePlusDoxTemp);
        setValuePlusNonDox(valuePlusNonDoxTemp);
      }
    }
  }

  const setWeightSlabData = (data, title) => {
    const titleToStateMapping = {
      standardDocs: setStandardDox,
      standardNonDocs: setStandardNonDox,
      fastTrackSurface: setFastTrackSurface,
      fastTrackAir: setFastTrackAir,
      fastTrackNonDox: setFastTrackNonDox,
      valueplusDox: setValuePlusDox,
      valueplusNonDox: setValuePlusNonDox,
    };

    const setStateFunction = titleToStateMapping[title];
    if (setStateFunction) {
      setStateFunction(data);
    }
  };

  const changeDynamicSelect = (title, data) => {
    const titleToStateMapping = {
      standardNonDocs: setStandardNonDox,
      fastTrackNonDox: setFastTrackNonDox,
      valueplusNonDox: setValuePlusNonDox,
    };

    const setStateFunction = titleToStateMapping[title];
    if (setStateFunction) {
      setStateFunction(data);
    }
  };

  const changeAllocationFrom = (date, dateString, name) => {
    // setAllocationFromDate(dateString);
    form.setFieldValue(name, moment(date).add(1, "days"));
    //moment().add(1, 'days')
  };

  const dateFormat = "DD-MM-YYYY";

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      {alertData && <Alert alertData={alertData} />}
      <div style={{ padding: "20px 25px" }}>
        <Heading
          title={
            state?.rateCardName
              ? state?.rateCardName + " Rate card"
              : "Lead Approval Confirmation"
          }
          id={1}
          navigateTo={!state?.view ? "/all-approvals" : null}
        />
        <div className="template-container">
          <Form
            layout="vertical"
            onFinish={onSubmit}
            form={form}
            scrollToFirstError={{
              behavior: "smooth",
              block: "center",
              inline: "center",
            }}
          >
            {state?.view && (
              <Row className="mt-1">
                <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
                <Col xs={22} sm={22} md={16} lg={16} xl={16}>
                  <Row
                    className={"booking-details-container mainForm "}
                    style={{ marginTop: "1rem" }}
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row gutter={40}>
                        <Col
                          className="form-inputs"
                          xs={24}
                          sm={24}
                          md={12}
                          lg={12}
                          xl={12}
                          offset={1}
                        >
                          <Form.Item
                            name="businessVolume"
                            label="Add agreed volume of business"
                            rules={fovChargeRule}
                          >
                            <Input
                              placeholder="Enter volume of bussiness"
                              onKeyPress={(e) => {
                                if (/[^0-9.]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          className="form-inputs"
                          xs={24}
                          sm={24}
                          md={12}
                          lg={12}
                          xl={12}
                          offset={1}
                        >
                          <Form.Item
                            name="validityDate"
                            label="Agreed validity date"
                            rules={[
                              {
                                required: true,
                                message: "Please select allocation from date",
                              },
                            ]}
                          >
                            <DatePicker
                              // disabled={disabled}
                              // disabledDate={disabledDate}
                              format={dateFormat}
                              onChange={(date, dateString) =>
                                changeAllocationFrom(
                                  date,
                                  dateString,
                                  "validityDate"
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          className="form-inputs"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          offset={1}
                        >
                          <Form.Item
                            name="remark"
                            label="Remarks"
                            rules={[
                              {
                                message: "Please enter remarks",
                                required: true,
                              },
                            ]}
                          >
                            <TextArea
                              rows={4}
                              showCount
                              maxLength={100}
                              placeholder="Enter remarks"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Collapse
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined
                            rotate={isActive ? 90 : 0}
                            className="cash-rate-collapse"
                          />
                        )}
                      >
                        <Panel
                          header="Template Details"
                          key="1"
                          className="cash-rate-pannel"
                        >
                          <Row gutter={40}>
                            <Col
                              className="form-inputs"
                              xs={24}
                              sm={24}
                              md={12}
                              lg={12}
                              xl={12}
                              offset={1}
                            >
                              <Form.Item
                                name="ratecardName"
                                label="Template Name"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter template name",
                                  },
                                  {
                                    pattern: /^[a-zA-Z].*/g,
                                    message:
                                      "First Character should be alphabet",
                                  },
                                  {
                                    min: 3,
                                    message:
                                      "Template name must be minimum of 3 characters.",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Enter template name"
                                  onKeyPress={(e) => {
                                    if (/[^A-Za-z0-9-_@ ]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                    if (e.target.value.length >= 80) {
                                      e.preventDefault();
                                    }
                                    if (e.target.value.length > 0) {
                                      e.target.value.toLowerCase();
                                    }
                                  }}
                                  className="template-name"
                                  onBlur={upperCase}
                                  autoComplete="off"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              className="form-inputs"
                              xs={24}
                              sm={24}
                              md={12}
                              lg={12}
                              xl={12}
                              offset={1}
                            >
                              <Form.Item
                                name="financialYear"
                                label="Financial Year"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select financial year",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select financial year"
                                  options={financialYearData}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Panel>
                        <Panel
                          header="Miscellaneous Charges"
                          key="2"
                          className="cash-rate-pannel"
                        >
                          <FovForm
                            // fovData={fov}
                            // insuranceData={insurance}
                            // handlingChargesData={handlingCharges}
                            // premiumServiceChargesData={premiumServiceCharges}
                            // fuelServiceChargesData={fuelServiceCharges}
                            // codChargesData={codCharges}
                            // handleFovFormChange={(e, data) =>
                            //   handleFovChange(e, data)
                            // }
                            // disabled={
                            //   templateType === "view" || templateType === "allocate"
                            //     ? true
                            //     : false
                            // }
                            leadApproval={true}
                          />
                        </Panel>
                        <Panel
                          header="Allocation Details"
                          key="3"
                          className="cash-rate-pannel"
                        >
                          <AllocateDetails
                            getDefaultRateCardData={getDefaultRateCardData}
                            form={form}
                            changeFromDate={changeAllocationFrom}
                            existingRateCardType={existingRateCardType}
                          />
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                </Col>
                <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
              </Row>
            )}
            <RateCardTable
              standardDocsData={standardDox}
              standardNonDocsData={standardNonDox}
              fastTrackSurfaceData={fastTrackSurface}
              fastTrackAirData={fastTrackAir}
              fastTrackNonDoxData={fastTrackNonDox}
              valuePlusDoxData={valuePlusDox}
              valuePlusNonDoxData={valuePlusNonDox}
              handleChangeDocs={(e, data) => setWeightSlabData(e, data)}
              handleDynamicSelect={(data, title) =>
                changeDynamicSelect(data, title)
              }
              templateType={!state?.view ? "view" : "view"}
            />

            {state?.view && (
              <>
                <hr className="divider mt-3" />
                <div className="d-flex flex-end p-1">
                  <Button
                    className="btn-style cancel-btn w-10"
                    // onClick={cancelTemlate}
                  >
                    CANCEL
                  </Button>

                  <Button
                    htmlType="submit"
                    className="btn-style save-btn ml-1 w-10"
                  >
                    ALLOCATE
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
      </div>
    </Spin>
  );
}

export default LeadApprovalConfirmation;
