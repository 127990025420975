import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import { Button, Modal, Row, Col, Radio, message, Spin } from "antd";
import urls from "../../../api/urls";
import API from "../../../api";
const { PUT_RTO_SERVICE } = urls;

const ReturnTypeModal = ({ handleModalOpen, isModalOpen, response }) => {
  const [radioValue, setRadioValue] = useState(
    response?.bookingType?.toLowerCase() === "cash"
      ? "Return to Origin"
      : "Return to home office"
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleOk = async () => {
    setLoading(true);
    let res = await API.Api(
      `${PUT_RTO_SERVICE}${response?.awbNumber}`
    ).putCustomIdHeader();
    if (res.status) {
      setLoading(false);

      message.success(res?.response?.data?.message);
      handleModalOpen(false);
      if (response?.bookingType?.toLowerCase() === "cash") {
        navigate(`/cash-rto/${res?.response?.data?.awbNumber}`);
      } else {
        navigate(`/credit-rto/${res?.response?.data?.awbNumber}`);
      }
    } else {
      setLoading(false);
      handleModalOpen(false);

      message.error(res?.response?.data?.message || "Network error");
    }
  };
  const handleCancel = () => {
    handleModalOpen(false);
  };
  const onChange = (e) => {
    setRadioValue(e.target.value);
  };
  useEffect(() => {
    setRadioValue("Return to Origin");
  }, []);
  return (
    <Modal
      title="Select a Return Type"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}>
      <Spin spinning={loading}>
        <Row className="center" style={{ width: "70%" }}>
          <Radio.Group
            onChange={onChange}
            value={radioValue}
            className="flex-column"
            defaultValue={0}>
            {response?.bookingType?.toLowerCase() === "account" ? (
              <>
                <Radio value={"Return to Origin"}>Return to Origin</Radio>
                <Radio value={"Return to home office"}>
                  Return to home office
                </Radio>
              </>
            ) : response?.bookingType?.toLowerCase() === "cash" ? (
              <Radio value={"Return to Origin"}>Return to Origin</Radio>
            ) : null}
          </Radio.Group>
        </Row>
        <Row className="center mt-2" gutter={12}>
          <Col>
            <Button className="cancel-btn" onClick={handleCancel}>
              CANCEL
            </Button>
          </Col>
          <Col>
            <Button className="save-btn" onClick={handleOk}>
              PROCEED
            </Button>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default ReturnTypeModal;
