/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu, Tooltip } from "antd";
import { sideMenuList } from "../../../config/sideMenuList";

import "./style.scss";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const SideMenuItems = (visibleDrawer) => {
  const history = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [accountDetails, setAccountDetails] = useState();
  const [open, setOpen] = useState(false);
  const [activeKey, setActiveKey] = useState(["1"]);

  useEffect(() => {
    setAccountDetails(JSON.parse(localStorage.getItem("userinfo")));
  }, []);

  const onClickHandle = (title, route, active, directReRoute) => {
    if (title === " Retail Booking") {
      localStorage.removeItem("details");
      setOpen(false);
    }
    if (directReRoute) {
      window.location.href = route;

      return;
    }
    if (route) {
      setActiveKey([active]);
      navigate(route);
    }
  };

  let items = [];
  sideMenuList.map((item, index) => {
    if (
      item.role === "" ||
      item.role.some((e) => accountDetails?.roles?.includes(e))
    ) {
      items.push(
        getItem(
          item.title,
          item.active,
          activeKey[0] !== item.active ? (
            <Tooltip placement="rightTop" title={item.title}>
              <item.icon
                onClick={() =>
                  onClickHandle(
                    item.title,
                    item.route,
                    item.active,
                    item.directReRoute
                  )
                }
              />
            </Tooltip>
          ) : (
            <Tooltip placement="rightTop" title={item.title}>
              <item.fillIcon
                onClick={() =>
                  onClickHandle(
                    item.title,
                    item.route,
                    item.active,
                    item.directReRoute
                  )
                }
              />
            </Tooltip>
          ),
          item?.children?.map((data) =>
            getItem(
              data?.title,
              data?.active,
              <Tooltip placement="rightTop" title={item.title}>
                <item.icon
                  onClick={() =>
                    onClickHandle(
                      data.title,
                      data.route,
                      data.active,
                      data.directReRoute
                    )
                  }
                />
              </Tooltip>
            )
          )
        )
      );
    }
  });

  const handleClick = (e) => {
    let routeData = sideMenuList.find((it) => it.active === e.key);
    let route = routeData?.route;
    if (routeData?.directReRoute) {
      window.open(route);
      return;
    }
    if (route) {
      navigate(route);
      setActiveKey([e.key]);
    }
  };

  useEffect(() => {
    let key = sideMenuList.find((it) => it.route === history.pathname)?.active;
    if (key !== undefined) {
      localStorage.setItem("side_active", key);
      setActiveKey([key]);
    } else {
      let newKey = localStorage.getItem("side_active");
      setActiveKey([newKey]);
    }
  }, [history]);

  return (
    <div>
      <Menu
        defaultSelectedKeys={activeKey}
        selectedKeys={activeKey}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        theme="light"
        collapsed={collapsed?.toString()}
        // inlineCollapsed={collapsed}
        onClick={handleClick}
        items={items}
      ></Menu>
    </div>
  );
};

export default SideMenuItems;
