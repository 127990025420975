import React,{useState,useEffect} from "react";
import { Button, Modal,Row,Col,Radio } from "antd";
import { capitaliseWithHyphen } from "../../../utils";

const ServiceTypeModal = ({isModalOpen, handleModalOpen,serviceTypeResponse,selectTypeDataHandle}) => {
  const [radioValue, setRadioValue] = useState("");

  const handleOk = () => {
    handleModalOpen(false);
    selectTypeDataHandle(radioValue);
  };
  const handleCancel = () => {
    handleModalOpen(false);
  };
  const onChange = (e) => {
    setRadioValue(e.target.value);
  };
  useEffect(()=>{
    setRadioValue(1);
  },[]);
  return (
    <Modal 
      title="Select a Service Type to Proceed" 
      open={isModalOpen} 
      onOk={handleOk} 
      onCancel={handleCancel}
      footer={false}
    >
      <Row className="center" style={{width:"85%",height:"30px",background:"#F1F1F1",margin:"auto"}}gutter={24}>
        <Col span={12}><b>Service Type</b></Col>
        <Col span={12}><b>Freight in INR</b></Col>
      </Row>
      <Row style={{width:"70%",margin:"auto"}} className="pt-1">
        <Col span={16}>
          <Radio.Group onChange={onChange} value={radioValue} className="flex-column" defaultValue={0}>
            {
              serviceTypeResponse?.services?.map((data)=>data?.rates?.map((item,index)=> <Radio value={`${item?.rate}_${data?.serviceType}_${item?.mode}`} key={index}>{capitaliseWithHyphen(data?.serviceType + " " + item?.mode)}</Radio>
              ))
            }
          </Radio.Group>
        </Col>
        <Col span={8}>
          {serviceTypeResponse?.services?.map((data)=>data?.rates?.map((item,index)=> <div key={index}>{item?.rate}</div>
          ))}
        </Col>
      </Row>
      <Row className="center mt-2" gutter={12}>
        <Col>
          <Button className="cancel-btn" onClick={handleCancel}>CANCEL</Button>
        </Col>
        <Col>
          <Button className="save-btn" onClick={handleOk}>PROCEED</Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ServiceTypeModal;
