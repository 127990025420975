/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Line,
  Marker,
} from "react-simple-maps";
import { scaleQuantile } from "d3-scale";
import { Row, Col, Select, Button, Tooltip } from "antd";

const { Option } = Select;

import "./map.css";
import data2 from "../../../../constants/address.json";
import cross_button from "../../../../assests/images/CrossButton.svg";
import { capitalize } from "../../../../utils";

const INDIA_TOPO_JSON = require("../../../../constants/india.topo.json");

const PROJECTION_CONFIG = {
  scale: 350,
  center: [78.9629, 22.5937], // always in [East Latitude, North Longitude]
};

// Red Variants
const COLOR_RANGE = ["#E4ECFF", "#EFF9EE"];

const DEFAULT_COLOR = "#EEE";

const getRandomInt = () => {
  return parseInt(Math.random() * 100);
};

const geographyStyle = {
  default: {
    outline: "none",
  },
  hover: {
    fill: "#E4ECFF",
    transition: "all 250ms",
    outline: "none",
  },
  pressed: {
    outline: "none",
  },
};

// will generate random heatmap data on every call
const getHeatMapData = () => {
  return [
    { id: "AP", state: "Andhra Pradesh", value: getRandomInt() },
    { id: "AR", state: "Arunachal Pradesh", value: getRandomInt() },
    { id: "AS", state: "Assam", value: getRandomInt() },
    { id: "BR", state: "Bihar", value: getRandomInt() },
    { id: "CT", state: "Chhattisgarh", value: getRandomInt() },
    { id: "GA", state: "Goa", value: 21 },
    { id: "GJ", state: "Gujarat", value: 22 },
    { id: "HR", state: "Haryana", value: getRandomInt() },
    { id: "HP", state: "Himachal Pradesh", value: getRandomInt() },
    { id: "JH", state: "Jharkhand", value: 26 },
    { id: "KA", state: "Karnataka", value: getRandomInt() },
    { id: "KL", state: "Kerala", value: getRandomInt() },
    { id: "MP", state: "Madhya Pradesh", value: getRandomInt() },
    { id: "MH", state: "Maharashtra", value: getRandomInt() },
    { id: "MN", state: "Manipur", value: getRandomInt() },
    { id: "ML", state: "Meghalaya", value: 59 },
    { id: "MZ", state: "Mizoram", value: getRandomInt() },
    { id: "NL", state: "Nagaland", value: 59 },
    { id: "OD", state: "Odisha", value: getRandomInt() },
    { id: "PB", state: "Punjab", value: getRandomInt() },
    { id: "RJ", state: "Rajasthan", value: getRandomInt() },
    { id: "SK", state: "Sikkim", value: getRandomInt() },
    { id: "TN", state: "Tamil Nadu", value: getRandomInt() },
    { id: "TG", state: "Telangana", value: getRandomInt() },
    { id: "TR", state: "Tripura", value: 14 },
    { id: "UT", state: "Uttarakhand", value: getRandomInt() },
    { id: "UP", state: "Uttar Pradesh", value: 15 },
    { id: "WB", state: "West Bengal", value: 17 },
    { id: "WB", state: "West Bengal", value: 17 },
    { id: "AN", state: "Andaman and Nicobar Islands", value: getRandomInt() },
    { id: "CH", state: "Chandigarh", value: getRandomInt() },
    { id: "DN", state: "Dadra and Nagar Haveli", value: 19 },
    { id: "DD", state: "Daman and Diu", value: 20 },
    { id: "DL", state: "Delhi", value: 59 },
    { id: "JK", state: "Jammu and Kashmir", value: 30 },
    { id: "LA", state: "Ladakh", value: getRandomInt() },
    { id: "LD", state: "Lakshadweep", value: getRandomInt() },
    { id: "PY", state: "Puducherry", value: getRandomInt() },
  ];
};

function Map(props) {
  const [tooltipContent, setTooltipContent] = useState("");
  const [data, setData] = useState(getHeatMapData());
  const [routeLine, setRouteLine] = useState(props.mapData);
  const [routeLine_, setRouteLine_] = useState(props.mapData);

  const colorScale = scaleQuantile()
    .domain(data.map((d) => d.value))
    .range(COLOR_RANGE);

  const onMouseEnter = (geo, current = { value: "NA" }) => {
    // return () => {
    //   setTooltipContent(`${geo.properties.name}: ${current.value}`);
    // };
  };
  window.onkeydown = function (event) {
    if (event.keyCode == 27) {
      props.goClose;
    }
  };

  const onMouseLeave = () => {
    setTooltipContent("");
  };

  const onChangeButtonClick = () => {
    setData(getHeatMapData());
  };

  const mapData = () => {
    let routeData = [];
    let mapData = props.height === 365 ? routeLine : props.mapData;
    for (let i in mapData) {
      for (let j = 0; j < mapData[i].route.length; j++) {
        routeData.push(capitalize(mapData[i].route[j].hubName));
      }
    }
    let design = [];
    {
      data2.map((item, index) => {
        design.push(
          <Marker coordinates={[item.Longitude, item.Latitude]} key={index}>
            <Tooltip title={item["Hub Name"]}>
              <circle stroke="black" strokeWidth="0.1" r={1} fill={"green"} />
            </Tooltip>
            {/* <text fill="#2C2C2C" style={{ fontSize: "6px", fontWeight: "500" }}>
            </text> */}
          </Marker>

          // <Marker coordinates={[item.Longitude, item.Latitude]} key={index}>
          //   <g
          //     fill="none"
          //     stroke="green"
          //     strokeWidth="2"
          //     strokeLinecap="round"
          //     strokeLinejoin="round"
          //     transform="translate(-12, -24)"
          //   >
          //     <circle cx="12" cy="10" r="2" />
          //     <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          //   </g>
          // </Marker>
        );
      });
    }
    return design;
  };
  const changeSelectedData = (data) => {
    let temp = routeLine_.filter((e) => e.routeId === data);
    setRouteLine(temp);
  };
  const onSearch = (e) => {
    // console.log(e);
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "16px", // Space between rows
  };

  const rowStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px", // Space between label and dotted line
  };

  const labelStyle = {
    fontSize: "16px",
    color: "#333",
  };

  const surfaceLineStyle = {
    width: "100px",
    // border: "none",
    border: "2px solid #F8B111",
    margin: 0,
  };
  const airLineStyle = {
    width: "100px",
    // border: "none",
    border: "2px dashed #519FE8",
    margin: 0,
  };
  const airSurfaceLineStyle = {
    width: "100px",
    // borderTop: "3px dashed #519FE8", // From airLineStyle
    border: "2px solid #F8B111", // From surfaceLineStyle
    margin: 0,
    padding: "1px",
  };

  const lineData = () => {
    let design2 = [];
    let mapData = props.height === 365 ? routeLine : props.mapData;
    for (let i in mapData) {
      for (let j = 0; j < mapData[i].route.length; j++) {
        if (j !== mapData[i].route.length - 1) {
          design2.push(
            <Tooltip
              title={
                mapData[i].route[j].hubName +
                " - " +
                mapData[i].route[j + 1].hubName
              }
              className="pointer"
            >
              <Line
                key={Math.random()}
                from={[
                  mapData[i].route[j].longitude,
                  mapData[i].route[j].latitude,
                ]}
                to={[
                  mapData[i].route[j + 1].longitude,
                  mapData[i].route[j + 1].latitude,
                ]}
                stroke={
                  mapData[i].route[j].travelBy === "AIR" ? "#1890ff" : "#f8b111"
                }
                strokeWidth={1}
                strokeDasharray={
                  mapData[i].route[j].travelBy === "AIR" ? "5 7" : ""
                }
                strokeLinecap="round"
              />
            </Tooltip>
          );
        }
      }
    }
    return design2;
  };

  const LienLables = () => (
    <>
      <div style={containerStyle}>
        <div style={rowStyle}>
          <hr style={surfaceLineStyle} />
          <span style={labelStyle}>Surface Routes</span>
        </div>
        <div style={rowStyle}>
          <hr style={airLineStyle} />
          <span style={labelStyle}>Air Routes</span>
        </div>
        <div style={rowStyle}>
          <div className="flex-column row-gap3">
            <hr style={airLineStyle} />
            <hr style={surfaceLineStyle} />
          </div>
          <span style={labelStyle}>Air-Surface</span>
        </div>
      </div>
    </>
  );

  return (
    <Row>
      <Col lg={14} xl={14} md={14} style={{ position: "relative" }}>
        <div style={{ position: "absolute", top: "100px", left: "110px" }}>
          {props.showLables && <LienLables />}
        </div>

        <div
          className={
            props.height === 365 ? "" : "full-width-height container-map"
          }
          style={{
            width: props.width,
          }}
          id={"fullscreen-target-" + props.mode}
          onClick={() => props.goFull(props.mode)}
        >
          <div className="d-flex flex-end">
            {props.height === 365 ? "" : "Double click on map to zoom"}
          </div>
          {/* <h1 className="no-margin center">States and UTs</h1> */}

          <ComposableMap
            projectionConfig={{
              scale: props.scale,
              center: [78.9629, 22.5937], // always in [East Latitude, North Longitude]
            }}
            projection="geoMercator"
            width={600}
            height={props.height}
            data-tip=""
          >
            <Geographies geography={INDIA_TOPO_JSON}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  //console.log(geo.id);
                  const current = data.find((s) => s.id === geo.id);
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={"#EFF9EE"}
                      style={geographyStyle}
                      stroke="#9BA5BF"
                      strokeWidth={0.3}
                      onMouseEnter={onMouseEnter(geo, current)}
                      onMouseLeave={onMouseLeave}
                    />
                  );
                })
              }
            </Geographies>

            {lineData()}
            <>{mapData()}</>
          </ComposableMap>
        </div>
      </Col>

      {props.height === 365 ? (
        <>
          <Col
            lg={10}
            xl={10}
            md={10}
            // className="d-flex p-2"
            // style={{ alignSelf: "end" }}
          >
            <Select
              showSearch
              onSearch={onSearch}
              filterOption={(input, option) => {
                if (Array.isArray(option?.label)) {
                  return option.label.some((label) =>
                    label?.toLowerCase().includes(input?.toLowerCase())
                  );
                }
                return (option?.label ?? "")
                  .toLowerCase()
                  .includes(input?.toLowerCase());
              }}
              placeholder="Select Route"
              style={{ width: "550px", height: "40px" }}
              options={routeLine_.map((e) => ({
                value: e.routeId,
                label: e.route.map((item, index) =>
                  index === e.route.length - 1
                    ? capitalize(item?.hubName)
                    : capitalize(item?.hubName) + " - "
                ),
              }))}
              onChange={(data) => changeSelectedData(data)}
            />
          </Col>
          {/* <Col style={{ alignSelf: "end" }}>
            <div style={containerStyle}>
              <div style={rowStyle}>
                <hr style={surfaceLineStyle} />
                <span style={labelStyle}>Surface Routes</span>
              </div>
              <div style={rowStyle}>
                <hr style={airLineStyle} />
                <span style={labelStyle}>Air Routes</span>
              </div>
              <div style={rowStyle}>
                <hr style={airSurfaceLineStyle} />
                <span style={labelStyle}>Air-Surface</span>
              </div>
            </div>
          </Col> */}
        </>
      ) : (
        <Col
          lg={10}
          xl={10}
          md={10}
          className="d-flex p-2"
          style={{ flexDirection: "column", justifyContent: "center" }}
        >
          <div className="d-flex align-center">
            <span
              className="circle-map"
              style={{
                backgroundColor: "#149D11",
              }}
            ></span>
            &nbsp;&nbsp;&nbsp;
            <span
              style={{
                color: props.width === "calc(180% - 120px)" ? "#fff" : "#000",
              }}
            >
              SMCS Hub
            </span>
          </div>
          <LienLables />
          {/* <div className="d-flex align-center mt-1">
          <span
            className="circle-map"
            style={{ backgroundColor: "#123FDE" }}
          ></span>
          &nbsp;&nbsp;&nbsp;
          <span
            style={{
              color: props.width === "calc(180% - 120px)" ? "#fff" : "#000",
            }}
          >
            Mini Hub
          </span>
        </div>
        <div className="d-flex align-center mt-1">
          <span
            className="circle-map"
            style={{
              backgroundColor: "#149D11",
            }}
          ></span>
          &nbsp;&nbsp;&nbsp;
          <span
            style={{
              color: props.width === "calc(180% - 120px)" ? "#fff" : "#000",
            }}
          >
            Channel Partner
          </span>
        </div> */}
        </Col>
      )}
    </Row>
  );
}

export default Map;
