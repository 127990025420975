import React, { useEffect, useState } from "react";
import { Row, Col, Select, Spin, message } from "antd";
import back_arrow from "../../../assests/images/master/back-arrow.svg";
import cart from "../../../assests/images/cart-blue.svg";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../api";
import urls from "../../../api/urls";
import "./style.scss";
import { capitalize } from "../../../utils";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

// const test = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const ProductDetails = () => {
  const user_premiseId = JSON.parse(window?.localStorage?.getItem("userId"));
  const params = useParams();
  const navigate = useNavigate();
  const { GET_PRODUCT_BY_ID, ADD_TO_CART } = urls;
  const [product, setProduct] = useState(null);
  const [qtyList, setQtyList] = useState([]);
  const [isSpining, setSpinning] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [minQ, setMinQ] = useState(1);
  useEffect(() => {
    getProductList();
  }, []);

  const getProductList = async () => {
    setSpinning(true);
    let response = await API.Api(GET_PRODUCT_BY_ID + params.id).get();
    if (response.status) {
      setSpinning(false);
      setProduct(response?.response?.data);
      let temp = [];
      let minOrderQty = response?.response?.data?.minOrderQty;
      let minQ = response?.response?.data?.minOrderQty;
      let incrementBy = response?.response?.data?.incrementBy;
      for (let i = 0; i < 15; i++) {
        temp.push({
          label: minOrderQty,
          value: minOrderQty,
        });
        minOrderQty = minOrderQty + incrementBy;
      }
      setQtyList(temp);
      setQuantity(minQ);
      setMinQ(minQ);
    } else {
      setSpinning(false);
    }
  };

  const handleQuantityChange = (value) => {
    setQuantity(value);
  };

  const addToCart = async () => {
    setSpinning(true);
    let payload = {
      itemId: product?.id,
      skuId: product?.sku,
      series: "",
      imagePath:
        product?.imageDtos.length > 0 ? product?.imageDtos[0].imagePath : "",
      imageName: product?.name,
      quantity,
    };
    let response = await API.Api(ADD_TO_CART).postIdHeader(payload);
    if (response.status) {
      setQuantity(minQ);
      setSpinning(false);
      let res = JSON.stringify(response?.response?.data?.items.length);
      message.success("Item added to cart successfully");
      localStorage.setItem("cartDetails", res);
    } else {
      setSpinning(false);
    }
  };
  return (
    <div className="p-2">
      <Spin
        spinning={isSpining}
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }
      >
        <div className="master-search-container">
          <span className="d-flex">
            <img
              src={back_arrow}
              className="pointer"
              onClick={() => navigate("/product-list")}
            />
            &nbsp;{"Product Details"}
          </span>
        </div>
        <div
          className="master-main-container p-2"
          style={{ borderRadius: "0px 0px 5px 5px" }}
        >
          <Row gutter={40}>
            <Col sm={12} md={10} lg={8} xl={8}>
              <div
                style={{
                  textAlign: "center",
                  borderRadius: "5px",
                  border: "1px solid #E0E1E2",
                  marginBottom: "2rem",
                  padding: "1rem 0rem",
                  cursor: "pointer",
                }}
              >
                <img
                  src={
                    product?.imageDtos.length > 0
                      ? product?.imageDtos[0].imagePath
                      : ""
                  }
                  style={{
                    height: "350px",
                    width: "300px",
                    objectFit: "contain",
                  }}
                />
              </div>
            </Col>
            <Col sm={12} md={16} lg={16} xl={16}>
              <div>
                <div style={{ fontSize: "18px", fontWeight: "500" }}>
                  {capitalize(product?.name)}
                  {/* {product?.name} */}
                </div>
                <div
                  style={{ fontSize: "24px", fontWeight: "600" }}
                  className="mt-1"
                >
                  ₹&nbsp;
                  {product?.stateWisePriceDto.find(
                    (e) => e.stateName === user_premiseId?.premises?.state
                  )?.price || product?.price}
                </div>
                <div className="d-flex align-center mt-1">
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      marginRight: "1rem",
                    }}
                  >
                    Quantity
                  </span>
                  <Select
                    // defaultValue={quantity}
                    value={quantity}
                    onSelect={handleQuantityChange}
                    // onSelect={}
                    options={qtyList}
                  />
                </div>
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                    marginRight: "1rem",
                  }}
                  className="mt-1"
                >
                  Description
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    marginRight: "1rem",
                    color: "#444444",
                    width: "400px",
                  }}
                >
                  {product?.description}
                </div>
                <div
                  className="d-flex align-center p-05 mt-2 pointer hover-zoom"
                  style={{
                    border: "1px solid #2E77D0",
                    borderRadius: "5px",
                    width: "150px",
                    justifyContent: "center",
                  }}
                  onClick={addToCart}
                >
                  <img src={cart} className="pointer" />
                  &nbsp;
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#2E77D0",
                    }}
                  >
                    ADD TO CART
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
};
export default ProductDetails;
