/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  message,
  Radio,
  TimePicker,
  Spin,
  Select,
} from "antd";
import {
  checkAlphabets,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
} from "../../../../../utils";
import { APIs, getToken } from "../../../../../utils/worker";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getClientOnboard,
  patchClientOnboard,
  patchClientOnboardClear,
} from "../../../../../actionsMethods/onboardingMethods";
import x_icon from "../../../../../assests/images/SVG/removeItem.svg";
import moment from "moment";
import Smcs_Loader from "../../../../../assests/images/loader-new.gif";

// import "../../style.scss";

export const OrganisationDetails = ({
  handleStepsChange = () => {},
  goBackStep = () => {},
  patchClient,
  getData,
}) => {
  const [form] = Form.useForm();
  const [companyData, setCompanyData] = useState([]);
  const [departmentName, setDepartmentName] = useState(true);

  const [pickupTime, setPickupTime] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (getData) {
      form.setFieldsValue({
        ...getData.businessDetails,
        pickupTime: getData?.businessDetails?.pickupTime
          ? moment(getData?.businessDetails?.pickupTime, "hh:mm A")
          : "",
        departmentName: "",
        sez: getData?.businessDetails?.sez
          ? getData?.businessDetails?.sez
          : false,
        centeralized: getData?.businessDetails?.centeralized
          ? getData?.businessDetails?.centeralized
          : false,
      });

      setCompanyData(
        getData?.businessDetails?.departmentName?.split(", ") || []
      );
      setPickupTime(getData?.businessDetails?.pickupTime);
      // form.setFieldValue("pickupTime", moment("12:00 PM", "HH:mm A"));
    }
  }, [getData]);

  useEffect(() => {
    form.setFieldValue("centeralized", false);
    form.setFieldValue("sez", false);
  }, []);

  const onChange = (time, timeString) => {
    const date = moment(time._d, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    const formattedDate = date.format("DD-MM-YYYY HH:mm:ss");

    setPickupTime(formattedDate);
  };

  const handleAreaChange = (value) => {
    setDepartmentName(false);
    let inputvalue = value.target.value;

    function capitalizeFirstLetter(str) {
      const capitalized = str?.charAt(0)?.toUpperCase() + str?.slice(1);
      return capitalized;
    }

    if (inputvalue.length > 1) {
      let temp = [];
      companyData?.map((val) => temp.push(val?.toLowerCase()));

      if (temp.includes(inputvalue?.toString()?.toLowerCase())) {
        form.setFieldValue("company", "");
        message.error("Department name already exists");
      } else {
        setCompanyData([...companyData, capitalizeFirstLetter(inputvalue)]);
      }
    }
    form.setFieldValue("departmentName", "");
  };

  function handleAreaDel(params) {
    let delAreas = companyData?.filter((val, idx) => params !== idx);
    setCompanyData(delAreas);
  }

  const createOrgDetails = (formValues) => {
    patchClient({
      businessDetails: {
        ...formValues,
        pickupTime: pickupTime,
        departmentName:
          companyData?.length > 0
            ? companyData?.join(", ")
            : form.getFieldValue("departmentName"),
      },
    });
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }>
      <div>
        <div>
          <Form
            className="global-form profile-container-form pl-4 pr-4 p-2"
            layout="vertical"
            form={form}
            scrollToFirstError={{
              behavior: "smooth",
              block: "center",
              inline: "center",
            }}
            initialValues={{
              businessVolume: 0,
              centralized: true,
            }}
            onFinish={createOrgDetails}>
            <Row gutter={40} className="global-form-row">
              <Col span={24} className="mb-1">
                <span className="master-title">Business Details</span>
                <hr className="divider" />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="contactPersonName"
                  label="Contact name"
                  rules={[
                    {
                      message: "Please enter contact name",
                      required: true,
                    },
                  ]}
                  onKeyPress={(e) => {
                    if (/[^A-Za-z/ ]/.test(e.key)) {
                      e.preventDefault();
                    }
                    if (e.target.value.length >= 20) {
                      e.preventDefault();
                    }
                  }}
                  onKeyDown={(e) => {
                    if (!/[a-zA-Z]/.test(e.target.value)) e.target.value = "";
                  }}>
                  <Input
                    // style={{ textTransform: "capitalize" }}
                    placeholder="Enter contact name"
                    maxLength={30}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="designation"
                  label="Designation"
                  rules={[
                    {
                      message: "Please enter designation",
                      required: true,
                    },
                    {
                      pattern: /^([A-Za-z]{1}[A-Za-z0-9 ]{0,29})$/g,
                      message: "Designation can't be start with space",
                    },
                  ]}
                  onKeyPress={(e) => {
                    if (checkAlphabets(e)) {
                      e.preventDefault();
                    }
                    if (e.target.value.length >= 30) {
                      e.preventDefault();
                    }
                  }}>
                  <Input
                    placeholder="Enter designation"
                    style={{ textTransform: "capitalize" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="departmentName"
                  label={"Department name"}
                  rules={[
                    {
                      required: companyData?.length ? false : true,
                      message: "Please enter department name",
                    },
                  ]}>
                  <Input
                    style={{
                      width: "100%",
                    }}
                    className="tag-items"
                    placeholder="Enter department name"
                    onPressEnter={handleAreaChange}
                    onKeyPress={(e) => {
                      if (/[^A-Za-z0-9-/ ]/.test(e.key)) {
                        e.preventDefault();
                      }
                      if (e.target.value.length >= 20) {
                        e.preventDefault();
                      }
                    }}
                    onKeyDown={(e) => {
                      if (!/[a-zA-Z0-9]/.test(e.target.value))
                        e.target.value = "";
                    }}
                  />
                </Form.Item>

                {companyData?.length > 0 && (
                  <div className="area-list-box mb-1">
                    {companyData?.map((val, idx) => {
                      return (
                        <>
                          <div className="area-list-item" key={idx}>
                            {val}
                            <span
                              onClick={() => handleAreaDel(idx)}
                              className="pointer"
                              style={{ marginLeft: "10px" }}>
                              <img src={x_icon} alt="X" width={11} />
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </Col>
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="partyName"
                  label="Party name"
                  onKeyPress={(e) => {
                    if (/[^A-Za-z0-9 ]/.test(e.key)) {
                      e.preventDefault();
                    }
                    if (e.target.value.length >= 30) {
                      e.preventDefault();
                    }
                  }}
                  // rules={[
                  //   {
                  //     message: "Please enter party name",
                  //     required: true,
                  //   },
                  //   {
                  //     pattern: /^([A-Za-z]{1}[A-Za-z0-9 ]{0,29})$/g,
                  //     message: "Party name can't be start with 0 or space",
                  //   },
                  // ]}
                >
                  <Input
                    placeholder="Enter Party name"
                    style={{ textTransform: "capitalize" }}
                  />
                </Form.Item>
              </Col> */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="natureOfBusiness"
                  label="Nature of business"
                  rules={[
                    {
                      message: "Please enter nature of business",
                      required: true,
                    },
                  ]}
                  onKeyPress={(e) => {
                    if (checkAlphabets(e) || checkSpecialCharacter(e)) {
                      e.preventDefault();
                    }
                  }}>
                  <Input placeholder="Nature of business" />
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="gstRegistered"
                label={"Is GST registered"}
                rules={[
                  {
                    required: true,
                    message: "Please select GST",
                  },
                ]}
              >
                <Radio.Group className="calculater-container-div-radio">
                  <Radio value={true} className="radioButton">
                    Yes
                  </Radio>
                  <Radio value={false} className="radioButton">
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col> */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="sez"
                  label={"Is SEZ"}
                  rules={[
                    {
                      required: true,
                      message: "Please select Is SEZ",
                    },
                  ]}>
                  <Radio.Group className="calculater-container-div-radio">
                    <Radio value={true} className="radioButton">
                      Yes
                    </Radio>
                    <Radio value={false} className="radioButton">
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="centralized"
                  label={"Centralized"}
                  rules={[
                    {
                      required: true,
                      message: "Please select centeralized",
                    },
                  ]}>
                  <Radio.Group
                    // onChange={(e) => scrolltoBottom(e)}
                    className="calculater-container-div-radio"
                    // disabled={createChildShipmentResult !== null && true}
                  >
                    <Radio value={true} className="radioButton">
                      Yes
                    </Radio>
                    <Radio value={false} className="radioButton">
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="pickupTime"
                  label="Pickup time"
                  rules={[
                    {
                      message: "Please select time",
                      required: true,
                    },
                  ]}
                  className="pickup-time-style">
                  {/* <Input placeholder="Enter pickup time" /> */}
                  <TimePicker use12Hours format="hh:mm " onChange={onChange} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="businessVolume"
                  label="Committed business volume per month"
                  rules={[
                    {
                      required: true,
                      message: "Please enter business volume",
                    },
                    {
                      pattern: /^([1-9]{1}[0-9]{0,9})$/g,
                      message: "Volume can't be 0",
                    },
                  ]}
                  onKeyPress={(e) => {
                    if (/[^0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                    if (e.target.value.length >= 10) {
                      e.preventDefault();
                    }
                  }}>
                  <Input placeholder="Value(INR)" type="number" />
                </Form.Item>
              </Col>
            </Row>
            <hr className="divider" />
            <div className="flex-end mt-1">
              <Button className="cancel-btn ml-1 w-15" onClick={goBackStep}>
                BACK
              </Button>
              <Button
                onClick={form.submit}
                className="save-btn w-15 ml-1"
                // onClick={handleStepsChange}
              >
                CONTINUE
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default OrganisationDetails;
