export function cashBookingRateCardData() {

  let standardDoxDatas = [
    {
      title: "up to 50 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 100 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 250 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 500 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 750 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 1000 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "Add 250 Gms",
      design:true,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
  ];

  let standardNonDoxDatas = [
    {
      title: "By Surface (Per Kg)",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "By Air (Per Kg)",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "Add 500 Gms",
      design:true,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
  ];

  let fastTrackSurfaceDatas = [
    {
      title: "up to 100 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 250 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 500 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 750 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 1000 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "Add 250 Gms",
      design: true,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
  ];

  let fastTrackAirDatas = [
    {
      title: "up to 100 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 250 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 500 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 750 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 1000 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "Add 250 Gms",
      design: true,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
  ];

  const fastTrackNonDoxDatas = [
    {
      title: "By Surface (Per Kg)",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "By Air (Per Kg)",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "Add 500 Gms",
      design:true,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
  ];

  const valuePlusDoxDatas = [
    {
      title: "up to 100 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 250 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 500 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 750 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "up to 1000 Gms",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "Add 250 Gms",
      design: true,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
  ];

  const valuePlusNonDoxDatas = [
    {
      title: "By Surface (Per Kg)",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "By Air (Per Kg)",
      design:null,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
    {
      title: "Add 500 Gms",
      design: true,
      value: [
        { title: "LOCAL", value: "0.00" },
        { title: "WITHIN STATE", value: "0.00" },
        { title: "My Zone 1", value: "0.00" },
        { title: "My Zone 2", value: "0.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "0.00" },
        { title: "SPECIAL LOCATION", value: "0.00" },
        { title: "REST OF INDIA", value: "0.00" },
      ],
    },
  ];

  return {standardDoxDatas, fastTrackSurfaceDatas, standardNonDoxDatas, fastTrackAirDatas, fastTrackNonDoxDatas, valuePlusDoxDatas, valuePlusNonDoxDatas};

}
