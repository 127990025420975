import keyMirror from "keymirror";

const actions = {
  GET_VIEWALLBOOKINGS: null,
  GET_VIEWALLBOOKINGS_SUCCESS: null,
  GET_VIEWALLBOOKINGS_ERROR: null,
  GET_VIEWALLBOOKINGS_CLEAR: null,

  CREATE_MANIFEST: null,
  CREATE_MANIFEST_SUCCESS: null,
  CREATE_MANIFEST_ERROR: null,
  CREATE_MANIFEST_CLEAR: null,

  //  DEV-B3
  GET_MANIFEST: null,
  GET_MANIFEST_SUCCESS: null,
  GET_MANIFEST_ERROR: null,
  GET_MANIFEST_CLEAR: null,

  GET_MANIFEST_VEHICLE: null,
  GET_MANIFEST_VEHICLE_SUCCESS: null,
  GET_MANIFEST_VEHICLE_ERROR: null,
  GET_MANIFEST_VEHICLE_CLEAR: null,

  PUT_MANIFEST_DRIVER: null,
  PUT_MANIFEST_DRIVER_SUCCESS: null,
  PUT_MANIFEST_DRIVER_ERROR: null,
  PUT_MANIFEST_DRIVER_CLEAR: null,

  GET_PACKET_BARCODE: null,
  GET_PACKET_BARCODE_SUCCESS: null,
  GET_PACKET_BARCODE_ERROR: null,
  GET_PACKET_BARCODE_CLEAR: null,

  GET_PACKET_LIST_BY_STATUS: null,
  GET_PACKET_LIST_BY_STATUS_SUCCESS: null,
  GET_PACKET_LIST_BY_STATUS_CLEAR: null,
  GET_PACKET_LIST_BY_STATUS_ERROR: null,

  GET_SHIPMENT_LIST_BY_STATUS: null,
  GET_SHIPMENT_LIST_BY_STATUS_SUCCESS: null,
  GET_SHIPMENT_LIST_BY_STATUS_CLEAR: null,
  GET_SHIPMENT_LIST_BY_STATUS_ERROR: null,

  UPDATE_PACKET_STATUS: null,
  UPDATE_PACKET_STATUS_SUCCESS: null,
  UPDATE_PACKET_STATUS_CLEAR: null,
  UPDATE_PACKET_STATUS_ERROR: null,

  GET_ELIGIBLE_PACKET: null,
  GET_ELIGIBLE_PACKET_SUCCESS: null,
  GET_ELIGIBLE_PACKET_ERROR: null,
  GET_ELIGIBLE_PACKET_CLEAR: null,

  CREATE_PACKETS: null,
  CREATE_PACKETS_SUCCESS: null,
  CREATE_PACKETS_ERROR: null,
  CREATE_PACKETS_CLEAR: null,

  PATCH_PACKET_SHIPMENT: null,
  PATCH_PACKET_SHIPMENT_SUCCESS: null,
  PATCH_PACKET_SHIPMENT_CLEAR: null,
  PATCH_PACKET_SHIPMENT_ERROR: null,

  DELETE_SHIPMENT: null,
  DELETE_SHIPMENT_SUCCESS: null,
  DELETE_SHIPMENT_ERROR: null,
  DELETE_SHIPMENT_CLEAR: null,

  PATCH_MANIFEST_SCAN: null,
  PATCH_MANIFEST_SCAN_SUCCESS: null,
  PATCH_MANIFEST_SCAN_ERROR: null,
  PATCH_MANIFEST_SCAN_CLEAR: null,

  GET_MANIFESTED_LIST: null,
  GET_MANIFESTED_LIST_SUCCESS: null,
  GET_MANIFESTED_LIST_ERROR: null,
  GET_MANIFESTED_LIST_CLEAR: null,

  PUT_MANIFEST_LOCK: null,
  PUT_MANIFEST_LOCK_SUCCESS: null,
  PUT_MANIFEST_LOCK_ERROR: null,
  PUT_MANIFEST_LOCK_CLEAR: null,

  UPDATE_DRIVE_DETAILS: null,
  UPDATE_DRIVE_DETAILS_SUCCESS: null,
  UPDATE_DRIVE_DETAILS_ERROR: null,
  UPDATE_DRIVE_DETAILS_CLEAR: null,

  PRINT_MANIFEST: null,
  PRINT_MANIFEST_SUCCESS: null,
  PRINT_MANIFEST_ERROR: null,
  PRINT_MANIFEST_CLEAR: null,

  PATCH_CHILDSHIPMENT: null,
  PATCH_CHILDSHIPMENT_SUCCESS: null,
  PATCH_CHILDSHIPMENT_ERROR: null,
  PATCH_CHILDSHIPMENT_CLEAR: null,
};

export default keyMirror(actions);
