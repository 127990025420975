/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Row, Col, Table, message, Tooltip, Modal } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { APIs, getToken } from "../../../utils/worker";
import "./style.scss";
import axios from "axios";

const token = getToken();

const ViewBroadcast = (check) => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [message_, setMessage] = useState("");

  const navigate = useNavigate();
  const tableLoading = {
    spinning: isTableLoading,
    indicator: <SyncOutlined spin />,
  };
  const columns = [
    {
      title: "Sl No",
      dataIndex: "slNo",
      key: "slNo",
      width: 10,
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
      width: 25,
    },
    {
      title: "Valid From",
      dataIndex: "validFrom",
      key: "validFrom",
      width: 25,
    },
    {
      title: "Valid To",
      dataIndex: "validTo",
      key: "validTo",
      width: 25,
    },
    {
      title: "Message",
      dataIndex: "title",
      key: "title",
      width: 40,
      render: (text, record) => (
        <span
          className="broadcast-message"
          onClick={() => showMessage(record.message)}
        >
          {text}
        </span>
      ),
    },
  ];

  useEffect(() => {
    getBroadcastList();
  }, [check]);

  const showMessage = (text) => {
    setMessage(text);
    setIsModalVisible(true);
  };

  const getBroadcastList = async () => {
    setIsTableLoading(true);
    try {
      let response = await axios.get(
        APIs.baseURL + "/broadcast-service/v1/broadcast",
        {
          headers: {
            Authorization: token,
            token: token.split("Bearer ")[1],
          },
        }
      );
      let temp = [];
      response?.data?.map((e, index) => {
        temp.push({
          slNo: index + 1,
          createdOn: e.createdOn,
          message: e.message,
          validFrom: e.validFrom,
          validTo: e.validTo,
          title: e.title,
        });
      });
      setDataSource(temp);
      setIsTableLoading(false);
    } catch (error) {
      message.error(error.response.data.message);
      setIsTableLoading(false);
    }
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  return (
    <div className="master-main-container profile-container">
      <div className="p-1">
        <p className="f-16 fw-500 title-color">Broadcast Message</p>
        <hr className="divider" />
        <Table
          className="table-head-sticky"
          columns={columns}
          dataSource={dataSource}
          loading={tableLoading}
          pagination={{
            itemRender: itemRender,
          }}
        />
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={false}
      >
        <div
          className="p-1"
          dangerouslySetInnerHTML={{ __html: message_ }}
        ></div>
      </Modal>
    </div>
  );
};
export default ViewBroadcast;
