/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  message,
  Select,
  DatePicker,
  Spin,
  Modal,
} from "antd";
import {
  checkAlphabets,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
} from "../../../utils";

import urls from "../../../api/urls";
import { ReactComponent as X_Icon } from "../../../assests/images/SVG/removeItem.svg";
import deleteIcon from "../../../assests/images/onbording/delete-box.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

import "./style.scss";
import API from "../../../api";
import { useNavigate } from "react-router-dom";

const { UPDATE_LEAD_CP_DETAILS, GET_ALL_AREAS } = urls;

export const DeliveryDetails = ({ getData, goBackStep = () => {} }) => {
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const leadCPId = localStorage.getItem("leadCPId");
  const [clickedButton, setClickedButton] = useState(null);

  const [data, setData] = useState([
    {
      allAreas: [],
      deliveryAreas: [],
      // pincode: getData?.cpDetail?.pincode,
    },
  ]);

  useEffect(() => {
    form.setFieldValue("cpPincode", getData?.cpDetail?.pincode || null);

    if (getData?.quotation?.deliveryDetails?.areas?.length > 0) {
      const updatedData = getData?.quotation?.deliveryDetails?.areas?.map(
        (areaSet, id) => {
          const deliveryAreas = areaSet?.assignedAreas;
          const nonDeliveryAreas = areaSet?.avilableAreas;

          form.setFieldsValue({
            ["deliveryPincode" + id]: areaSet?.pincode,
            ["serviceableAreas" + id]: deliveryAreas?.map((val) => {
              return {
                id: val?.areaId,
                label: val?.areaName,
                value: val?.areaName,
              };
            }),
          });
          return {
            allAreas: nonDeliveryAreas,
            deliveryAreas,
          };
        }
      );
      setData(updatedData);
    } else {
      // handleDelivery(getData?.cpDetail?.pincode, 0);
      // form.setFieldsValue({
      //   ["deliveryPincode" + 0]: getData?.cpDetail?.pincode,
      // });
    }
  }, [getData]);

  const updateCPDetails = async (formValues) => {
    setLoading(true);

    let payload = {
      deliveryDetails: {
        pincode: getData?.cpDetail?.pincode,
        areas: [],
      },
    };

    for (let i = 0; i < data?.length; i++) {
      let deliveryPincode = formValues[`deliveryPincode${i}`];
      let serviceableAreas = formValues[`serviceableAreas${i}`];

      payload.deliveryDetails?.areas?.push({
        pincode: +deliveryPincode,
        assignedAreas: serviceableAreas?.map((val) => {
          return {
            areaId: val?.id,
            areaName: val?.value,
          };
        }),
      });
    }

    let response = await API.Api(
      UPDATE_LEAD_CP_DETAILS + "/" + leadCPId
    ).putCustomHeader(
      {
        leadId: +leadCPId,
        quotation: {
          ...payload,
        },
      },
      { "USER-ID": userId?.userInfo?.userId }
    );
    if (response?.status) {
      setLoading(false);
      message.success("Delivery details updated successfully");
      if (clickedButton === "saveAndContinueButton") {
        navigate("/cp-lead-generation-list");
        localStorage.removeItem("leadCPId");
      }
    } else {
      setLoading(false);
    }
  };

  const cancelReset = () => {
    goBackStep();
  };

  function switchAreas(data, dataIndex, valueToMove, type) {
    const { allAreas, deliveryAreas } = data[dataIndex] || {};

    if (!allAreas || !deliveryAreas || !valueToMove) {
      return data;
    }

    if (type === "deliveryAreas") {
      const fromIndex = deliveryAreas.findIndex(
        (area) => area.areaName === valueToMove
      );

      if (fromIndex !== -1) {
        const movedArea = deliveryAreas?.splice(fromIndex, 1)[0]; // Remove from deliveryAreas
        allAreas.push(movedArea); // Add to allAreas
        let data = deliveryAreas?.map((val) => {
          return {
            value: val?.areaName,
            id: val?.areaId,
            label: val?.areaName,
          };
        });
        form.setFieldValue("serviceableAreas" + dataIndex, [...data]);
      }
    } else if (type === "allAreas") {
      const fromIndex = allAreas.findIndex(
        (area) => area.areaName === valueToMove
      );

      if (fromIndex !== -1) {
        const movedArea = allAreas?.splice(fromIndex, 1)[0]; // Remove from allAreas
        deliveryAreas.push(movedArea); // Add to deliveryAreas
        // Assuming 'form' is defined and used for setting field values
        let data = deliveryAreas?.map((val) => {
          return {
            value: val?.areaName,
            id: val?.areaId,
            label: val?.areaName,
          };
        });
        form.setFields([
          {
            name: "serviceableAreas" + dataIndex,
            errors: false,
          },
        ]);
        form.setFieldValue("serviceableAreas" + dataIndex, [...data]);
      }
    }
    return data;
  }

  const handleClick = (dataIndex, fromIndex, type) => {
    const newData = switchAreas([...data], dataIndex, fromIndex, type);
    setData(newData);
  };

  function handleAddDelivery() {
    setData([
      ...data,
      {
        allAreas: [],
        deliveryAreas: [],
        // pincode: [],
      },
    ]);
  }

  function handleDelete(index) {
    let temp = data?.filter((item, idx) => idx !== index);
    form.setFieldValue("deliveryPincode" + index, null);
    setData(temp);
  }

  const updateAllAreasByIndex = (index, newAllAreas) => {
    if (newAllAreas == null) {
      form.setFieldValue("serviceableAreas" + index, null);

      setData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          allAreas: [],
          deliveryAreas: [],
          pincode: [],
        };
        return newData;
      });
    } else {
      setData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],

          allAreas: [
            ...newAllAreas?.avilableAreas,
            ...newAllAreas?.areaAlreadyAssigned,
          ],
        };
        return newData;
      });
    }
  };

  async function handleDelivery(value, idx) {
    // setData((prevArray) => {
    //   const newArray = [...prevArray];
    //   newArray[idx] = { ...newArray[idx], allAreas: [], deliveryAreas: [] };
    //   return newArray;
    // });
    // updateAllAreasByIndex(idx, null);
    // let temp = data.map((val, idx) => {
    //   return form.getFieldsValue("deliveryPincode" + idx);
    // });

    if (value?.toString()?.length > 5) {
      setLoading(true);
      const firstSetDataPromise = Promise.resolve();
      firstSetDataPromise
        .then(() => {
          return API.Api(GET_ALL_AREAS + value + "/areas").getWithAllHeaders();
        })
        .then((response) => {
          if (response?.status) {
            updateAllAreasByIndex(idx, response?.response?.data);
          } else {
            message.error(
              response?.response?.data?.message || "Invalid pincode"
            );
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.message || "Invalid pincode");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <Spin
      spinning={loading}
      size="large"
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="cp-lead-gen-container">
        <Form
          className="global-form profile-container-form pl-4 pr-4 p-2"
          layout="vertical"
          form={form}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          onFinish={updateCPDetails}
        >
          <Row gutter={40} className="global-form-row">
            <Col span={24} className="mb-1">
              <span className="master-title">Delivery Details</span>
              <hr className="divider" />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="cpPincode"
                label="CP PIN code"
                rules={[
                  {
                    required: true,
                    message: "Please enter CP PIN code",
                  },
                ]}
              >
                <Input placeholder="Enter CP PIN Code" maxLength={6} disabled />
              </Form.Item>
            </Col>
          </Row>

          {data?.map((item, idx, val) => {
            return (
              <>
                <Row className="global-form-row card space-between">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label="Delivery PIN code"
                      name={"deliveryPincode" + idx}
                      rules={[
                        {
                          required: true,
                          message: "Please enter delivery PIN code",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter delivery PIN code"
                        maxLength={6}
                        onKeyPress={(event) => {
                          if (
                            checkNumbervalue(event) ||
                            checkSpace(event) ||
                            checkSpecialCharacter()
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => handleDelivery(e.target.value, idx)}
                        style={{ width: "98%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                    {idx !== 0 && (
                      <img
                        src={deleteIcon}
                        alt="X"
                        width={30}
                        className="pointer"
                        onClick={() => handleDelete(idx)}
                      />
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <p>All area</p>
                    <div className="area-list-box allArea-box">
                      {item?.allAreas?.map((item, i) => {
                        return (
                          <>
                            <div
                              className="area-list-item"
                              key={i}
                              style={{
                                color: item?.allocated ? "grey" : null,
                                background: item?.allocated ? "#dcdbdb" : null,
                              }}
                            >
                              {item?.areaName}
                              <span
                                onClick={() => {
                                  if (!item?.allocated) {
                                    handleClick(
                                      idx,
                                      item?.areaName,
                                      "allAreas"
                                    );
                                  }
                                }}
                              >
                                <X_Icon
                                  style={{
                                    marginLeft: "10px",
                                    cursor: item?.allocated
                                      ? "not-allowed"
                                      : "pointer",
                                  }}
                                />
                              </span>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name={"serviceableAreas" + idx}
                      label={"Serviceable areas"}
                      rules={[
                        {
                          required: true,
                          message: "Please select area",
                        },
                      ]}
                      className="mt-1"
                    >
                      <Select
                        placeholder=""
                        mode="multiple"
                        onDeselect={(e, v, i) =>
                          handleClick(idx, e, "deliveryAreas")
                        }
                        style={{
                          overflow: "scroll",
                          maxHeight: "142px",
                        }}
                      >
                        {item?.deliveryAreas?.map((option, id) => {
                          return (
                            <>
                              <Option key={id} value={option?.areaName}>
                                {option?.areaName}
                              </Option>
                            </>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            );
          })}
          <Row className="mt-1 mb-1">
            <Button className="cancel-btn" onClick={handleAddDelivery}>
              + ADD DELIVERY PINCODE
            </Button>
          </Row>
          <hr className="divider" />
          <div className="flex-end mt-1">
            <Button className="cancel-btn ml-1 w-15" onClick={cancelReset}>
              CANCEL
            </Button>
            <Button className="cancel-btn ml-1 w-15" htmlType="submit">
              SAVE
            </Button>
            <Button
              htmlType="submit"
              className="save-btn  ml-1"
              onClick={() => setClickedButton("saveAndContinueButton")}
              // onClick={handleStepsChange}
            >
              SAVE & CONTINUE
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default DeliveryDetails;
