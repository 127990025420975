import React from "react";
import { Form, Col, Input } from "antd";
import AutoCompleteAddress from "../views/FirstMile/CrediteBooking/AutoCompleteAddress";
import AutoSelectAndType from "../views/FirstMile/CrediteBooking/AutoSelectAndType";

export const ReciverAddressComponent = ({
  form,
  reviverNameAndAddress,
  parseInt,
  span = 6,
}) => {
  return (
    <>
      <Col lg={6} sm={6}>
        <AutoSelectAndType
          form={form}
          // fieldName="receiverName"
          reviverNameAndAddress={reviverNameAndAddress?.receiverDetails}
        />
      </Col>
      <Col lg={span} sm={span}>
        <AutoCompleteAddress
          form={form}
          addressDetailsCallback={null}
          fieldName="receiverAddress"
          label="Receiver Address"
          rules={[
            {
              required: true,
              message: "Enter receiver address",
            },
          ]}
        />
      </Col>
      <Col span={6}>
        <Form.Item
          label="Mobile number"
          name="mobileNumber"
          rules={[
            {
              required: true,
              message: "Enter mobile number",
            },
            () => ({
              validator(rule, value = "") {
                if (value.length > 0 && value.length < 10) {
                  return Promise.reject("Enter 10 digits phone number");
                } else if (parseInt(value.substring(1, 0)) === 0) {
                  return Promise.reject("Mobile number should not be 0");
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <Input
            placeholder="Enter phone number"
            onKeyPress={(e) => {
              if (e.code == "Enter") {
                document.getElementById("submit_retail").blur();
              } else if (/[^0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            maxLength={10}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Email address"
          name="receiverEmail"
          rules={[
            {
              type: "email",
              message: "Give the correct email",
            },
          ]}
        >
          <Input
            placeholder="Enter email address"
            onBlur={(e) =>
              form.setFieldValue(
                "returnEmail",
                e.target.value.toLocaleLowerCase()
              )
            }
          />
        </Form.Item>
      </Col>
    </>
  );
};
