/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React from "react";
import { Row, Col, Input, Button, Form, message, Radio, Select } from "antd";
import RateCardTable from "./RateCardTable";

import rate_card_back from "../../../assests/images/onbording/rate-card-back.svg";
import { fovChargeRule } from "./rateCardUtils";
import { useParams } from "react-router-dom";

function RateCardReturn({
  form,
  standardDox,
  standardNonDox,
  fastTrackSurface,
  fastTrackAir,
  fastTrackNonDox,
  valuePlusDox,
  valuePlusNonDox,
  showAddRateCard,
  rateAdded,
  rateCardPresent,
  viewRateCard,
  addRateCard,
  showSideStep,
  rateDisable,
  rateCardTemplatesData,
  showTemplateDropdown,
  selectExistingTemplate,
  billingConfigData,
  goBackStep = () => {},
  handleStepsChange = () => {},
  createRateCard = () => {},
  selectTemplate = () => {},
  setWeightSlabData = () => {},
  changeDynamicSelect = () => {},
}) {
  const checkNum = (e) => {
    if (/[^0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const params = useParams();

  return (
    <>
      {!showAddRateCard && params?.id === undefined ? (
        <div className="p-2">
          <span className="master-title">Add Rate Card</span>
          <hr className="divider" />
          {rateAdded || rateCardPresent ? (
            <div
              className="rate-card-container"
              style={{
                background: "#fff",
                border: "1px solid #DDDDDD",
                width: "100%",
              }}>
              <Button className="cancel-btn" onClick={viewRateCard}>
                VIEW RATE CARD
              </Button>
            </div>
          ) : (
            <div className="rate-card-container">
              <p>Click here to get started</p>
              <Button className="save-btn" onClick={addRateCard}>
                + ADD NEW QUOTE
              </Button>
            </div>
          )}
          <hr className="divider mt-2" />
          <div className="flex-end" style={{ paddingTop: "1rem" }}>
            <Button className="cancel-btn ml-1 w-15" onClick={goBackStep}>
              BACK
            </Button>
            <Button
              onClick={() => handleStepsChange(3)}
              className={
                rateAdded || rateCardPresent
                  ? "save-btn w-15 ml-1"
                  : "btn-style w-15 ml-1"
              }
              disabled={rateAdded || rateCardPresent ? false : true}>
              CONTINUE
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div>
            <span
              className="master-title"
              style={{
                padding: "0.8rem 1rem 0.3rem 1rem",
                marginTop: "1rem",
                fontSize: "16px",
              }}>
              <img
                src={rate_card_back}
                className="mr-1 pointer"
                onClick={
                  params?.id === undefined
                    ? showSideStep
                    : () => window.history.back()
                }
              />{" "}
              {params?.id === undefined ? "Add quotation" : "Back"}
            </span>
          </div>
          <hr className="divider" />
          <Form
            className="global-form"
            layout="vertical"
            form={form}
            scrollToFirstError={{
              behavior: "smooth",
              block: "center",
              inline: "center",
            }}
            // initialValues={fovFormInitialValues}
            onFinish={createRateCard}>
            <Row style={{ padding: "1rem 1rem 0.5rem 1rem" }}>
              <Col xs={1} sm={1} md={2} lg={3} xl={3}></Col>
              <Col xs={22} sm={22} md={20} lg={18} xl={18}>
                <Row gutter={40} className="global-form-row">
                  {/* {billingConfigData?.map((val, idx) => {
                    return (
                      <>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Form.Item
                            name={val.name}
                            label={val.name}
                            rules={[
                              {
                                required: true,
                                message: `Please enter ${val.name}`,
                              },
                            ]}
                          >
                            <Input placeholder={`Enter ${val.name}`} />
                          </Form.Item>
                        </Col>
                      </>
                    );
                  })} */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="ratecardName"
                      label="Rate card name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter rate card name",
                        },
                      ]}>
                      <Input placeholder="Enter rate card name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="quotationName"
                      label="Quotation name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter rate card name",
                        },
                      ]}>
                      <Input placeholder="Enter rate card name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="rateCardType"
                      label="Rate card type"
                      rules={[
                        {
                          required: true,
                          message: "Please enter rate card type",
                        },
                      ]}>
                      <Input placeholder="Enter rate card type" />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="fov" label="FOV" rules={fovChargeRule}>
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                        disabled={rateDisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="insurance"
                      label="Insurance"
                      rules={fovChargeRule}>
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                        disabled={rateDisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="handlingCharges"
                      label="Handling charges"
                      rules={fovChargeRule}>
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                        disabled={rateDisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="premiumServiceCharges"
                      label="Premium service charges"
                      rules={fovChargeRule}>
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                        disabled={rateDisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="fuelServiceCharges"
                      label="Fuel service charges"
                      rules={fovChargeRule}>
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                        disabled={rateDisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="codCharges"
                      label="COD charges"
                      rules={fovChargeRule}>
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                        disabled={rateDisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="declaredAmount"
                      label="Declared amount"
                      // rules={fovChargeRule}
                      rules={[
                        {
                          required: true,
                          message: "Please enter amount",
                        },
                      ]}>
                      <Input
                        placeholder="Enter amount"
                        maxLength={10}
                        onKeyPress={(e) => checkNum(e)}
                        disabled={rateDisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <hr className="divider" />
                  </Col>
                  {!rateDisable && (
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="templateType"
                        label={<div className="ml-1">Select weight slab</div>}
                        rules={[
                          {
                            required: true,
                            message: "Please select type",
                          },
                        ]}>
                        <Radio.Group onChange={selectTemplate} className="p-05">
                          <Radio value={"Default"}>
                            <span className="f-14">Default</span>
                          </Radio>
                          <Radio value={"Existing"} className="ml-1">
                            <span className="f-14">Existing template</span>
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    {showTemplateDropdown && (
                      <Form.Item
                        name="templateName"
                        label="Select template"
                        rules={[
                          {
                            required: true,
                            message: "Please select template name",
                          },
                        ]}>
                        <Select
                          showSearch
                          placeholder="Template name"
                          options={rateCardTemplatesData}
                          // onSearch={onSearch}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={selectExistingTemplate}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col xs={1} sm={1} md={2} lg={3} xl={3}></Col>
            </Row>
            <div style={{ marginTop: "5px" }}>
              <RateCardTable
                standardDocsData={standardDox}
                standardNonDocsData={standardNonDox}
                fastTrackSurfaceData={fastTrackSurface}
                fastTrackAirData={fastTrackAir}
                fastTrackNonDoxData={fastTrackNonDox}
                valuePlusDoxData={valuePlusDox}
                valuePlusNonDoxData={valuePlusNonDox}
                handleChangeDocs={(e, data) => setWeightSlabData(e, data)}
                handleDynamicSelect={(data, title) =>
                  changeDynamicSelect(data, title)
                }
                templateType={rateDisable ? "view" : "create"}
              />
            </div>

            <div className="d-flex flex-end p-1">
              <Button className="cancel-btn ml-1" onClick={showSideStep}>
                BACK
              </Button>
              {!rateDisable && (
                <Button
                  htmlType="submit"
                  className="save-btn ml-1"
                  //onClick={handleStepsChange}
                >
                  SAVE
                </Button>
              )}
            </div>
          </Form>
        </>
      )}
    </>
  );
}

export default React.memo(RateCardReturn);
