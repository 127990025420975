/* eslint-disable no-unused-vars */
import React from "react";
import { Table, Row, Col } from "antd";
import { camelCase } from "../../../utils";

const TableContent = ({ apiData }) => {
  // let shipmentDetailsData = apiData?.shipmentScanDtos?.map(
  //   (data) => data?.shipmentDetails
  // );

  // let packetDetailsData = apiData?.shipmentScanDtos?.map(
  //   (data) => data?.packetsdto
  // );
  // let nonDoxWOChild = apiData?.filter((data, idx, arr) => {
  //   if (data?.nonDoxWithChildShipments?.length === 0) {
  //     return { data, arr };
  //   }
  // });
  // let nonDoxWithChild = shipmentDetailsData?.filter((data, idx, arr) => {
  //   if (data?.type === "non-dox" && data?.childShipmentDetails?.length > 0) {
  //     return { data, arr };
  //   }
  // });
  // let doxData = shipmentDetailsData?.filter((data, idx, arr) => {
  //   if (data?.type === "dox" && data?.childShipmentDetails?.length === 0) {
  //     return { data, arr };
  //   }
  // });

  const columnsNonDoxNoChild = [
    {
      title: "AWB No",
      dataIndex: "awbNumber",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      render: (text) => <div>{!text ? null : `${text.toFixed(2)} Kg`} </div>,
    },
    // {
    //   title: "Mode of manifest",
    //   dataIndex: "mode",
    //   render: () => <div>{camelCase(apiData?.mode)}</div>,
    // },
    {
      title: "Eway bill",
      dataIndex: "ewayBillNumber",
      render: (text) => <div>{text}</div>,
    },
  ];
  // with child
  const columnsNonDoxWithChild = [
    {
      title: "AWB No",
      dataIndex: "awbNumber",
      render: (text, record) => {
        return (
          <div>
            <div
              style={
                record?.childShipmentDetails?.length > 0
                  ? { marginBottom: "20px" }
                  : {}
              }
            >
              {text}
            </div>
          </div>
        );
      },
    },
    {
      title: "Child AWB No",
      dataIndex: "childs",
      render: (text, record) => {
        return (
          <Col>
            {record.childs?.map((data, idx) => (
              <Row key={idx} className="center">
                {data.childAwbNumber}
              </Row>
            ))}
          </Col>
        );
      },
    },

    {
      title: "Eway bill",
      dataIndex: "ewayBillNumber",
      render: (text, record) => {
        return (
          <Col>
            {record?.ewayBillNumber?.map((data, idx) => (
              <Row key={idx} className="center">
                {data}
              </Row>
            ))}
          </Col>
        );
      },
    },
  ];
  const columnsDoxWithNoChild = [
    {
      title: "AWB No",
      dataIndex: "awbNumber",
      render: (text, record) => (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            {text}
          </div>
          <div style={{ display: "block", visibility: "hidden" }}>
            {record?.nonDoxNoChldAwbNumber?.map((data, idx) => (
              <p key={idx}>{data}</p>
            ))}
          </div>
        </>
      ),
    },
    {
      title: "Weight",
      dataIndex: "weight",
      render: (text) => {
        return (
          <Col>
            <Row>{text ? `${text.toFixed(2)} Kg` : null}</Row>
          </Col>
        );
      },
    },
    // {
    //   title: "Mode of manifest",
    //   dataIndex: "mode",
    //   render: () => <div>{camelCase(apiData?.mode)}</div>,
    //   // render: (text) => <p>{camelCase(text)}</p>
    // },
    {
      title: "Eway bill",
      dataIndex: "ewayBillNumber",
      render: (text) => <div>{text}</div>,
    },
  ];

  // dox
  const columnsDox = [
    {
      title: "Packet ID",
      dataIndex: "packetBarcode",
      render: (text) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            // minHeight: "200px",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Packet Content",
      dataIndex: "packetShipments",
      render: (text) => (
        <Col>
          {text?.map((data, idx) => (
            <Row key={idx}>{data?.awbNo}</Row>
          ))}
        </Col>
      ),
    },
    {
      title: "Weight",
      dataIndex: "weight",
      render: (text) => <Col>{text ? `${text.toFixed(2)} Kg` : null}</Col>,
    },
    // {
    //   title: "Mode of manifest",
    //   dataIndex: "mode",
    //   render: () => <div>{camelCase(apiData?.mode)}</div>,
    //   // render: (text) => <p>{camelCase(text)}</p>,
    // },
    {
      title: "Eway bill",
      dataIndex: "ewayBillNumber",
      render: (text) => <div>{text}</div>,
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Table
          columns={columnsNonDoxNoChild}
          dataSource={apiData.nonDoxShipments}
          bordered
          title={() => "Non Dox W/O Child Shipment"}
          pagination={false}
        />
      </Col>
      <Col span={24} className="non-dox-child">
        <Table
          columns={columnsNonDoxWithChild}
          dataSource={apiData?.nonDoxWithChild}
          bordered
          title={() => "Non Dox With Child Shipment"}
          pagination={false}
        />
      </Col>
      <Col span={24} className="non-dox-child">
        <Table
          columns={columnsDoxWithNoChild}
          dataSource={apiData?.doxShipments}
          bordered
          title={() => "Dox"}
          pagination={false}
        />
      </Col>
      {/* <Col span={24} className="dox-recept-table">
        <Table
          columns={columnsDox}
          dataSource={packetDetailsData?.filter(Boolean)}
          bordered
          title={() => "Packets"}
          pagination={false}
        />
      </Col> */}
      <Col span={24}></Col>
    </Row>
  );
};

export default TableContent;
