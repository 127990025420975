/* eslint-disable no-unused-vars */
import { Divider, Steps, Row, Col, message, Spin } from "antd";
import React, { useState, useEffect } from "react";
// import BasicDetails from "./BasicDetails";
// import "./style.scss";
import Smcs_Loader from "../../assests/images/loader-new.gif";
import Heading from "../../components/Heading";
import BasicDetails from "./BasicDetails";
import CPDetails from "./CPDetails";
import DeliveryDetails from "./DeliveryDetails";
import AllocateRateCard from "./AllocateRateCard";
import API from "../../api";

import urls from "../../api/urls";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const {
  POST_LEAD_CP_DETAILS,
  UPDATE_LEAD_CP_DETAILS,
  GET_CP_LEAD_ID,
  GET_ALL_CP_LEADS,
} = urls;

const CPLeadList = () => {
  // const navigate = useNavigate();

  const history = useLocation();
  const [current, setCurrent] = useState(0);
  const [sideStepHide, setSideStepHide] = useState(false);
  const [getData, setGetData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cpLeadId, setcpLeadId] = useState(null);
  const [headingTitle, setHeadingTitle] = useState(null);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [rateCardPresent, setRateCardPresent] = useState(false);

  useEffect(() => {
    const leadCPId = localStorage.getItem("leadCPId");
    if (leadCPId) {
      getCPDetails_(leadCPId);
    }
    window.scrollTo(0, 0);
    if (window.location.pathname.includes("onboarding")) {
      setHeadingTitle("CP onboarding");
    } else {
      setHeadingTitle("CP Lead Generation");
    }
  }, [current]);

  useEffect(() => {
    if (history.state !== null) {
      setCurrent(history.state);
    }
  }, []);

  const getCPDetails_ = async (clientId) => {
    let response = await API.Api(
      GET_CP_LEAD_ID + `${clientId}`
    ).getCustomHeader({ "USER-ID": userId?.userInfo?.userId });
    if (response?.status) {
      let data = response?.response?.data;
      setGetData(data);
      setRateCardPresent(
        data?.quotation?.rateCard?.isAllocated == true ? true : false
      );
    } else {
      message.error("Network error");
    }
  };

  const patchClient = async (values, checkBtn) => {
    setLoading(true);
    const leadCPId = localStorage.getItem("leadCPId");
    let response = await API.Api(
      UPDATE_LEAD_CP_DETAILS + "/" + leadCPId
    ).putCustomHeader(
      { leadId: +leadCPId, ...values },
      { "USER-ID": userId?.userInfo?.userId }
    );
    if (response?.status) {
      setLoading(false);
      message.success("CP updated successfully.");
      if (checkBtn) {
        setCurrent(current + 1);
      }
    } else {
      message.error(response?.response?.data?.message);
      setLoading(false);
    }
  };

  const onChange = (value) => {
    setCurrent(value);
  };

  const handleStepsChange = (item) => {
    setCurrent(current + 1);
    setcpLeadId(item);
  };

  const goBackStep = () => {
    window.scrollTo(0, 0);
    setCurrent(current - 1);
  };

  const sideStepHideFunc = () => {
    setSideStepHide(true);
  };

  const sideStepShowFunc = () => {
    setSideStepHide(false);
  };

  return (
    <div className="p-2">
      <Heading title={headingTitle} />
      <div className="">
        <div className="master-main-container">
          {/* <Spin
            spinning={loading}
            indicator={
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            }
          > */}
          <Row
            className={sideStepHide ? "" : "p-1"}
            style={{ height: "70vh", overflow: "scroll" }}>
            {!sideStepHide && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={6}
                className="global-steps-container">
                <Steps
                  current={current}
                  direction="vertical"
                  onChange={onChange}
                  items={[
                    {
                      title: "Basic Details",
                      description: "Please provide your basic details",
                    },
                    {
                      title: "CP Details",
                      description: "Please create a PIN Code map",
                      disabled: getData?.cpDetail?.premiseName ? false : true,
                    },
                    {
                      title: "Quotation",
                      description: "Please provide rate card details",
                      disabled: getData?.quotation?.quotationName
                        ? false
                        : true,
                    },

                    {
                      title: "Delivery Details",
                      description: "Please provide delivery details",
                      disabled: getData?.quotation?.deliveryDetails?.areas
                        ?.length
                        ? false
                        : true,
                    },
                  ]}
                />
              </Col>
            )}
            <Col xs={24} sm={24} md={24} lg={24} xl={sideStepHide ? 24 : 18}>
              {current === 0 ? (
                <BasicDetails
                  handleStepsChange={handleStepsChange}
                  patchClient={patchClient}
                  getData={getData}
                />
              ) : current === 1 ? (
                <CPDetails
                  handleStepsChange={handleStepsChange}
                  patchClient={patchClient}
                  getData={getData}
                  goBackStep={goBackStep}
                />
              ) : current === 2 ? (
                <AllocateRateCard
                  handleStepsChange={handleStepsChange}
                  sideStepsHide={sideStepHideFunc}
                  sideStepsShow={sideStepShowFunc}
                  goBackStep={goBackStep}
                  rateCardPresent={rateCardPresent}
                  getData={getData}
                  // clientName={getData?.clientOnboard?.clientName}
                />
              ) : current === 3 ? (
                <DeliveryDetails
                  handleStepsChange={handleStepsChange}
                  goBackStep={goBackStep}
                  getData={getData}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
          {/* </Spin> */}
        </div>
      </div>
    </div>
  );
};

export default CPLeadList;
