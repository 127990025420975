import React from "react";
import { Row, Col, Spin, Tooltip } from "antd";
import { camelCase } from "../../../utils";
import "./style.scss";
const ExpandedHubScreen = ({ recordData, allCpData }) => {
  let temp = [
    { label: "Hub name", value: camelCase(recordData?.premiseName) },
    { label: "Hub type", value: camelCase(recordData?.hubType) },
    { label: "Hub mode", value: camelCase(recordData?.hubMode || "Surface") },
    { label: "GSTIN", value: recordData?.gst },
    { label: "Email ID", value: recordData?.officialEmailId?.toLowerCase() },
    { label: "Phone number 1", value: recordData?.officialNumber},
    { label: "Phone number 2", value: recordData?.personalNumber},
    {
      label: "Address line 1",
      value:
        recordData?.addressLine1?.charAt(0).toUpperCase() +
        recordData?.addressLine1?.slice(1),
    },
    {
      label: "Address line 2",
      value:
        recordData?.addressLine2?.charAt(0).toUpperCase() +
        recordData?.addressLine2?.slice(1),
    },
    { label: "PIN code", value: recordData?.pincode },
    { label: "City", value: camelCase(recordData?.city) },
    { label: "State name", value: camelCase(recordData?.state) },
    { label: "Zone", value: camelCase(recordData?.zone) },
    { label: "Latitude", value: recordData?.latitude },
    { label: "Longitude", value: recordData?.longitude },
  ];

  return (
    <Spin spinning={recordData ? false : true}>
      <div className="expanded-hub-screen">
        <div className="expanded-hub-wrapper">
          <Row className="expanded-hub-header">Hub Details</Row>
          <hr className="divider" />
          <Row className="expanded-hub">
            {temp?.map((val) => {
              return (
                <>
                  <Col span={6}>
                    <Row className="expanded-hub-lable">{val?.label}</Row>
                    <Row
                      className="expanded-hub-value"
                      style={{ overflow: "hidden" }}
                    >
                      <Tooltip title={val?.value || "------"}>
                        {val?.value || "------"}
                      </Tooltip>
                    </Row>
                  </Col>
                </>
              );
            })}
          </Row>

          <Row className="expanded-hub-header">Mapped CPs</Row>
          <hr className="divider" />
          {allCpData?.length > 0 ? (
            <Row className="expanded-hub">
              {allCpData?.map((val) => {
                return (
                  <>
                    <Col span={6}>
                      <Row className="expanded-hub-lable">{`• ${camelCase(
                        val
                      )}`}</Row>
                    </Col>
                  </>
                );
              })}
            </Row>
          ) : (
            <Row className="expanded-hub">
              <Col span={6}>
                <Row className="expanded-hub-lable">No CPs available.</Row>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </Spin>
  );
};
export default ExpandedHubScreen;
