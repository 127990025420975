/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Form,
  Button,
  Table,
  Spin,
  message,
  Popconfirm,
} from "antd";
import "./style.scss";
import API from "../../../api";
import urls from "../../../api/urls";
import { capitalize, serialNum } from "../../../utils";
import Heading from "../../../components/Heading";
import { useLocation, useNavigate } from "react-router-dom";

import UpdateIcon from "../../../assests/images/updateEway.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

import whiteUpdateIcon from "../../../assests/images/whiteUpdateEway.svg";

const {
  CP_EWAY_BILL_TABLE,
  CONSOLIDATE_EWAY_BILL,
  EWAYBILL_UPDATE_PARTB,
  AUDIT_EWAY_BILL,
  UPDATED_EWAY_BILL,
} = urls;

const CPEWayBill = () => {
  const [form] = Form.useForm();
  const history = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [updatedEwayData, setUpdatedEwayData] = useState([]);
  const [transPortMode, setTransPortMode] = useState();

  function getModifiedGST(item) {
    const gstNumber = item.premises.gst;
    const firstTwoDigits = gstNumber.substring(0, 2);
    const modifiedDigits = firstTwoDigits.startsWith("0")
      ? firstTwoDigits.substring(1)
      : firstTwoDigits;
    return modifiedDigits;
  }

  useEffect(() => {
    getUpdatedEwayBills();
  }, []);

  useEffect(() => {
    getEwayBills();
  }, [updatedEwayData]);

  const columns = [
    {
      title: "SI No",
      dataIndex: "",
      key: "SI",
      render: (a, b, idx) => serialNum(10, 1, idx),
    },
    {
      title: "AWb No",
      dataIndex: "awbNumber",
      key: "awbNumber",
      render: (text) => {
        return text;
      },
    },
    {
      title: "E-way Bill No",
      dataIndex: "ewayBillNumber",
      key: "ewayBillNumber",
    },
    {
      title: "Destination Hub",
      dataIndex: "toHub",
      key: "toHub",
      render: (record, _data) => {
        return capitalize(record);
      },
    },
    {
      title: "Mode",
      dataIndex: "travelBy",
      key: "travelBy",
      render: (record, _data) => {
        return capitalize(record);
      },
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      width: 20,
      render: (record, _data) => {
        return (
          <div>
            {_data.ewayUpdated ? (
              <Button
                className=""
                // disabled={_data?.ewayUpdated}
                style={{
                  borderRadius: "5px",
                  color: "white",
                  background: "#149D11",
                  cursor: "not-allowed",
                }}
              >
                <img src={whiteUpdateIcon} />
                <span className="ml-1 fw-500">E-WAY BILL UPDATED</span>
              </Button>
            ) : (
              <Button
                className=""
                // disabled={_data?.ewayUpdated}
                style={{
                  borderRadius: "5px",
                  color: "#2E77D0",
                  background: "white",
                }}
                onClick={() => SingleConsolidate(_data)}
              >
                <img src={UpdateIcon} />
                <span className="ml-1 fw-500">E-WAY BILL UPDATE</span>
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;

  const getEwayBills = async (e) => {
    setLoading(true);
    const temp = [];

    let pushTempData = (data, childAwb, toDestination) => {
      if (data?.ewayBillNumber) {
        temp.push({
          awbNumber: data?.childAwbNumber || data.awbNumber,
          ewayBillNumber: data?.ewayBillNumber,
          toHub: data?.destinationHub || toDestination,
          travelBy: data?.travelBy,
          bookingCompleteTime: data?.bookingStartTime,
          bookingStartTime: data?.bookingStartTime || data?.createdDate,
          bookingStatus: data?.bookingStatus,
          expiryDate: data?.expiryDate,
          updatedDate: data?.updatedDate,
          value: data?.value,
          ewayBillCreateDate: data?.ewayBillCreateDate || null,
          ewayUpdated: updatedEwayData?.includes(data?.ewayBillNumber) || false,
        });
      }
    };

    const response = await API.Api(
      CP_EWAY_BILL_TABLE + `${history?.state?.manifestId}`
    ).getWithAllHeaders();
    if (response) {
      setLoading(false);
      const x = response?.response?.data?.shipmentScanDtos;

      const packetsEwayBills = response?.response?.data?.shipmentScanDtos;
      const getPacketsDTOData =
        packetsEwayBills[0]?.packetsdto?.ewayBillShipments;

      const shipmentDetailsArray = x?.map((item) => item?.shipmentDetails);

      if (getPacketsDTOData?.length) {
        getPacketsDTOData.forEach((e) => pushTempData(e));
      }
      if (shipmentDetailsArray?.length) {
        shipmentDetailsArray?.forEach((e) => {
          pushTempData(e);
          if (e?.childShipmentDetails?.length) {
            e.childShipmentDetails?.forEach((item) => {
              pushTempData(item, item?.childAwbNumber, e?.destinationHub);
            });
          }
        });
      }

      setTransPortMode(
        response?.response?.data?.travelBy == "surface" ? "1" : "3"
      );

      let temp2 = [
        {
          awbNumber: 100074741661520,
          ewayBillNumber: "831362683978",
          toHub: "PUNE WARJE HUB",
          travelBy: "surface",
          bookingCompleteTime: "2023-11-27 15:22:07",
          bookingStartTime: "2023-11-27 15:22:07",
          bookingStatus: "MANIFESTED",
          expiryDate: "2023-11-30 23:59:00",
          updatedDate: "2023-11-27 15:23:16",
          value: 100000,
          ewayBillCreateDate: "2023-11-25 14:04:00",
          ewayUpdated: false,
        },
        {
          awbNumber: 4343434343434,
          ewayBillNumber: "8313626843434",
          toHub: "PUNE WARJE HUB",
          travelBy: "surface",
          bookingCompleteTime: "2023-11-27 15:22:07",
          bookingStartTime: "2023-11-27 15:22:07",
          bookingStatus: "MANIFESTED",
          expiryDate: "2023-11-30 23:59:00",
          updatedDate: "2023-11-27 15:23:16",
          value: 100000,
          ewayBillCreateDate: "2023-11-25 14:04:00",
          ewayUpdated: false,
        },
      ];
      setTableData(temp);
    } else {
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.ewayUpdated,
      name: record.name,
    }),
    selectedRowKeys: selectedRowKeys,
  };

  const handleStatus = async (params) => {
    let lockManifestPayload = {
      status: "LOCKED",
    };

    let response = await API.Api(
      CP_EWAY_BILL_TABLE + `${history.state.manifestId}`
    ).putIdHeader(lockManifestPayload);
    if (response?.status) {
      setLoading(false);
      message.success(
        response?.response?.data?.message || "Locked Successfully"
      );
      navigate("/viewBooking");
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  const handleSubmit = async (params) => {
    setLoading(true);

    const stateGST = getModifiedGST(userId);

    if (selectedRowKeys?.length > 0) {
      let temp = selectedRowKeys?.map((val) => {
        return { ewbNo: val };
      });
      let payload = {
        fromPlace: userId?.premises?.city,
        fromState: +stateGST,
        vehicleNo: history?.state?.vehicleNo,
        transMode: transPortMode,
        transDocDate: params.transDocDate,
        tripSheetEwbBills: temp,
      };

      let response = await API.Api(CONSOLIDATE_EWAY_BILL).postIdHeader(payload);

      if (response?.response?.data?.status == "1") {
        setLoading(false);
        message.success(
          response?.response?.data?.message || "E-way Bill Updated Successfully"
        );
        auditEwayBill(response?.response?.data, selectedRowKeys, "consolidate");
        setSelectedRowKeys([]);
      } else {
        setLoading(false);
        message.error(
          response?.response?.data?.data || "Failed to Update E-way bill"
        );
      }
    } else {
      message.error("Select an E-way bill");
      setLoading(false);
    }
  };

  async function SingleConsolidate(record) {
    setLoading(true);

    const stateGST = getModifiedGST(userId);

    let payload = {
      // vehicleNo: "KA32P1275",
      vehicleNo: history?.state?.vehicleNo,
      fromPlace: userId?.premises?.city,
      fromState: +stateGST,
      transDocDate: formattedToday,
      ewbNo: record.ewayBillNumber,
      transMode: transPortMode,
    };

    let response = await API.Api(
      EWAYBILL_UPDATE_PARTB + record?.ewayBillNumber + "/partB"
    ).patchIdHeader(payload);
    if (response?.response?.data?.status == "1") {
      setLoading(false);
      message.success(
        response?.response?.data?.message || "E-way Bill Updated Successfully"
      );
      auditEwayBill(response?.response?.data, record, "single");
    } else {
      setLoading(false);
      message.error(
        response?.response?.data?.data || "Failed to Update E-way bill"
      );
    }
  }

  async function auditEwayBill(singleUpdateRes, record, type) {
    setLoading(true);

    function filterDataByAwbNumbers(dataArray, awbNumbersArray) {
      return dataArray?.filter((item) =>
        awbNumbersArray?.includes(item.ewayBillNumber)
      );
    }
    let temp = [];
    let payload = {};
    if (type == "single") {
      temp = tableData?.filter(
        (val) => val?.ewayBillNumber === record?.ewayBillNumber
      );

      payload = {
        shipments: temp?.map((val) => {
          return {
            awbnumber: val?.awbNumber,
            ewaybillNo: val?.ewayBillNumber,
            ewbBillExpDate: val?.expiryDate,
            ewayBillCrtDate: val?.ewayBillCreateDate || null,
            awbcrtDate: val?.bookingStartTime || val?.createdDate,
            shipmentValue: val?.value,
          };
        }),
        tripid: history?.state?.manifestId,
        consEwbNo: "0",
        vehicleNo: history?.state?.vehicleNo,
        transMode: transPortMode == "1" ? "surface" : "air",
        ewbOprPlace: userId?.premises?.city,
      };
    } else {
      let filteredData = filterDataByAwbNumbers(tableData, record);
      temp = filteredData?.map((val) => val);

      let x = singleUpdateRes?.data;
      let cNum = JSON.parse(x);

      payload = {
        shipments: temp.map((val) => {
          return {
            awbnumber: val?.awbNumber,
            ewaybillNo: val?.ewayBillNumber,
            ewayBillCrtDate: val?.ewayBillCreateDate || null,
            ewbBillExpDate: val?.expiryDate,
            awbcrtDate: val?.bookingStartTime || val?.createdDate,
            shipmentValue: val?.value,
          };
        }),
        tripid: history?.state?.manifestId,
        consEwbNo: cNum?.cEwbNo,
        vehicleNo: history?.state?.vehicleNo,
        transMode: transPortMode == "1" ? "surface" : "air",
        ewbOprPlace: userId?.premises?.city,
      };
    }

    let response = await API.Api(AUDIT_EWAY_BILL).postIdHeader(payload);

    if (response?.status) {
      setLoading(false);
      getUpdatedEwayBills();
    } else {
      setLoading(false);
    }
  }

  const getUpdatedEwayBills = async () => {
    setLoading(true);
    let response = await API.Api(
      `${UPDATED_EWAY_BILL}${history?.state?.manifestId}`
    ).getWithAllHeaders();
    if (response?.status) {
      setUpdatedEwayData(response?.response?.data?.ewaybill);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const confirm = () => {
    handleStatus();
  };

  const allEwayUpdatedTrue = tableData?.every(
    (item) => item?.ewayUpdated === true
  );

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="m-2">
        <Heading title={"CP E-way Bill"} />
        <div className="cp-eway-container p-2">
          <Form
            form={form}
            onFinish={handleSubmit}
            name="accountBasicDetails"
            layout="vertical"
            initialValues={{
              transDocDate: formattedToday,
              manifestId: history?.state?.manifestId,
              driverName: history?.state?.driverName,
              vehicleNo: history?.state?.vehicleNo,
            }}
            scrollToFirstError={{
              behavior: "smooth",
              block: "center",
              inline: "center",
            }}
          >
            {tableData?.length ? (
              <>
                <Row justify="center" gutter={16} className="m-1">
                  <Col span={8} sm={24} md={8} lg={8} xs={24}>
                    <Form.Item
                      // initialValue={"bottom"}
                      name="transDocDate"
                      label="Date"
                      rules={[
                        {
                          required: false,
                          message: "Select date",
                        },
                      ]}
                    >
                      <Input placeholder="Date" disabled />
                    </Form.Item>
                  </Col>

                  <Col span={8} sm={24} md={8} lg={8} xs={24}>
                    <Form.Item
                      name="manifestId"
                      label="Manifest Id"
                      rules={[
                        {
                          required: false,
                          message: "Enter manifest Id",
                        },
                      ]}
                    >
                      <Input disabled placeholder="Enter Manifest Id" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="center" gutter={16} className="m-1">
                  <Col span={8} sm={24} md={8} lg={8} xs={24}>
                    <Form.Item
                      name="driverName"
                      label="Driver name"
                      rules={[
                        {
                          required: true,
                          message: "Enter driver name",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter driver name"
                        disabled
                        defaultValue={history?.state?.driverName}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} sm={24} md={8} lg={8} xs={24}>
                    <Form.Item
                      name="vehicleNo"
                      label="Vehicle number"
                      rules={[
                        {
                          required: true,
                          message: "Enter vehicle number",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter vehicle number"
                        disabled
                        defaultValue={history?.state?.vehicleNo}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="end mb-2">
                  <Button
                    className="save-btn"
                    htmlType="submit"
                    // onClick={form.submit}
                  >
                    CONSOLIDATE UPDATE
                  </Button>
                </Row>
              </>
            ) : (
              <Row justify="end mb-2">
                <h2 style={{ margin: "auto" }}>No E-way bills present</h2>
              </Row>
            )}

            <Table
              // onChange={onSelectChange}
              columns={columns}
              scroll={{ x: 500 }}
              dataSource={tableData}
              // dataSource={[]}
              pagination={false}
              rowKey={(record) => record?.ewayBillNumber}
              rowSelection={{
                ...rowSelection,
              }}
            />
          </Form>
          {tableData?.length < 1 ? (
            <Row justify="end mt-2">
              <Button
                className="save-btn"
                htmlType="submit"
                onClick={handleStatus}
              >
                LOAD AND LOCK
              </Button>
            </Row>
          ) : !allEwayUpdatedTrue ? (
            <>
              <Row justify="end mt-2">
                <Popconfirm
                  title={
                    <div>
                      <div>You have not updated all E-way bills,</div>
                      <div>do you wish to proceed?</div>
                    </div>
                  }
                  onConfirm={confirm}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="save-btn" htmlType="submit">
                    LOCK MANIFEST
                  </Button>
                </Popconfirm>
              </Row>
            </>
          ) : (
            <Row justify="end mt-2">
              <Button
                className="save-btn"
                htmlType="submit"
                onClick={handleStatus}
              >
                LOCK MANIFEST
              </Button>
            </Row>
          )}
        </div>
      </div>
    </Spin>
  );
};
export default CPEWayBill;
