/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import {
  Card,
  Row,
  Button,
  Modal,
  Input,
  message,
  Spin,
  TimePicker,
  Form,
} from "antd";
import "./DashboardCard.scss";
import urls from "../../api/urls";
import API from "../../api";
import { QRCode } from "react-qrcode";
import { APIs } from "../../utils/worker";
import { checkSpace, checkSpecialCharacter } from "../../utils";

function DashbordCard({
  accountBookingIcon,
  accountBookingArrowIcon,
  bookingType,
  route,
  id,
}) {
  const { QR_FOR_CHECK_IN } = urls;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setValue(null);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setValue(null);
    setIsModalOpen(false);
  };

  const funCall = (route) => {
    if (route !== null) {
      navigate(route);
    } else if (route === null) {
      showModal();
    }
  };

  const onChangeHandler = () => {
    setValue(null);
  };

  const onFinish = async (values) => {
    setValue(null);
    let time = values["time-picker"].format("HH:mm");
    let vehicleNumber = values?.vehicleNumber;
    let response = await API.Api(
      `${QR_FOR_CHECK_IN + vehicleNumber}?manualTimeInPut=${time}`
    ).getWithUserNPremiseHeader();
    if (response.status) {
      let res = response.response.data;
      setValue(
        `${APIs.baseURL}/lcr-service/v1/mm/vehicleCheckin/${res.tripId}/${res.premiseId}/driver/`
      );
      setLoading(false);
    } else {
      setLoading(false);
      message.error(response.response.data.message || "Network error");
    }
  };

  const config = {
    rules: [
      {
        type: "object",
        required: true,
        message: "Please select time!",
      },
    ],
  };

  return (
    <>
      <Modal
        title="Trip QR Code"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        destroyOnClose
      >
        <Spin spinning={loading}>
          <Form
            name="basic"
            className="global-form "
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <div>
              <Form.Item
                label="Enter Vehicle Number"
                name="vehicleNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input Vehicle Number!",
                  },
                ]}
              >
                <Input
                  style={{
                    borderRadius: "5px",
                    border: "0px solid #EBEBEB",
                    background: "#F7F7F7",
                    height: "40px",
                  }}
                  placeholder="Enter Vehicle Number"
                  onKeyPress={(event) => {
                    if (checkSpecialCharacter(event) || checkSpace(event)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={onChangeHandler}
                  maxLength={12}
                />
              </Form.Item>

              <Form.Item name="time-picker" label="Check-In Time" {...config}>
                <TimePicker
                  style={{
                    borderRadius: "5px",
                    border: "0px solid #EBEBEB",
                    background: "#F7F7F7",
                    height: "40px",
                    width: "100%",
                  }}
                  size="large"
                  format="HH:mm"
                  // use12Hours
                  onChange={onChangeHandler}
                />
              </Form.Item>
            </div>
          </Form>
          <div className="flex-end pt-1 ">
            <Button
              type="primary"
              className="save-btn"
              htmlType="submit"
              form="basic"
              key="submit"
            >
              Generate QR
            </Button>
          </div>
          {value && (
            <div className="center">
              <QRCode value={value} width={400} />
            </div>
          )}
        </Spin>
      </Modal>

      <Row id={id} onClick={() => funCall(route)}>
        <Card className="cardFlex">
          <div className="cardFlex1">
            <div className="accountImageCard">
              <img
                alt="example"
                width="50"
                src={accountBookingIcon}
                className="chart_img"
              />
            </div>
            <div className="chart_para">
              <p style={{ margin: "0 0 0 0 " }}>
                {bookingType}
                <img
                  className="arrowClass"
                  src={accountBookingArrowIcon}
                  width="8px"
                />
              </p>
            </div>
          </div>
        </Card>
      </Row>
    </>
  );
}

DashbordCard.propTypes = {
  accountBookingIcon: PropTypes.node,
  accountBookingArrowIcon: PropTypes.node,
  watchIcon: PropTypes.node,
  bookingType: PropTypes.string,
};

export default DashbordCard;
