/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Radio,
  Row,
  Col,
  Form,
  Input,
  Space,
  Button,
  Select,
  message,
  Spin,
  Alert,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
// import { APIs } from "../../utils/worker";
import API from "../../api";
import urls from "../../api/urls";

const { POST_GST, POST_WO_GST, UPDATE_CP_KYC, VERIFY_KYC_OTP, CP_KYC_SAVE } =
  urls;

const KycDetailsWOGST = ({
  form,
  address,
  setPostResponse,
  postResponse,
  validateWrapper,
  typeOfBusinessOptions,
}) => {
  const [loading, setLoading] = useState(false);
  const [panBusinees, setPanBusinees] = useState(false);
  const [aadharVerify, setAadharVerify] = useState(false);
  const [panVerify, setPanVerify] = useState(false);

  function handleBusinessType(params) {
    if (params == "Proprietorship" || params == "Individual") {
      setPanBusinees(false);
    } else {
      setPanBusinees(true);
    }
  }

  async function sendOTP() {
    let formData = form.getFieldsValue();
    setLoading(true);
    let response = await API.Api(
      `${UPDATE_CP_KYC + postResponse?.customerId}`
    ).patchIdHeader({
      KYC: formData,
    });

    if (response.status) {
      setLoading(false);
      let data = response?.response.data?.data;
      setPostResponse(response?.response.data?.data);
      setAadharVerify(false);
      if (data?.kycData?.aadharResponse?.isValid) {
        message.success(data?.kycData?.aadharResponse?.message);
        setPanVerify(true);
      } else {
        // if (data?.kycData?.aadharResponse?.isValid == false) {
        //   message.error(data?.kycData?.aadharResponse?.message);
        // }
        <Alert
          message="Error Text"
          description={
            data?.kycData?.aadharResponse?.message || "Network error"
          }
          type="error"
          closable
        />;
      }
    } else {
      setLoading(false);

      setPanVerify(false);
    }
  }

  async function handleVerifyOTP() {
    let formOTP = form.getFieldValue("otp");
    setLoading(true);
    let response = await API.Api(
      `${VERIFY_KYC_OTP + postResponse?.customerId}`
    ).patchIdHeader({
      KYC: {
        OTP: formOTP,
      },
    });

    if (response.status) {
      setLoading(false);

      let data = response?.response.data?.data;
      setPanVerify(false);

      if (data?.kycData?.aadharResponse?.isValid) {
        message.success(data?.kycData?.message || "KYC verified successfully");
        setPostResponse(data);
        let address = data?.kycData?.aadharResponse?.addressAsPerAadhar;

        form.setFieldsValue({
          addressLine1: address?.addressLine1,
          addressLine2: address?.addressLine2,
          pincode: address?.pinCode || address?.pincode,
          state: address?.state,
          city: address?.city,
          country: address?.country,
        });
      } else {
        if (data?.kycData?.aadharResponse?.isValid == false) {
          <Alert
            message="Error Text"
            description={
              data?.kycData?.aadharResponse?.message || "Network error"
            }
            type="error"
            closable
          />;
          // message.error(data?.kycData?.aadharResponse?.message);
        }
      }
    } else {
      setLoading(false);
    }
  }

  function handleSendOTP(params) {
    params.preventDefault();
    setPanVerify(false);
    if (params.target.value.length > 5) {
      setAadharVerify(true);
    } else {
      setAadharVerify(false);
    }
  }

  return (
    <Spin spinning={loading}>
      <Row gutter={24}>
        <>
          <Col md={12} lg={12} xl={12} sm={24} xs={24}>
            <Form.Item
              label="Type of business"
              name="businessType"
              rules={[
                {
                  required: true,
                  message: "Please select type of business",
                },
              ]}>
              <Select
                placeholder="Please select an option"
                onChange={handleBusinessType}
                options={typeOfBusinessOptions}
              />
            </Form.Item>
          </Col>
          <Col md={12} lg={12} xl={12} sm={24} xs={24}></Col>

          <Col md={12} lg={12} xl={12} sm={24} xs={24}>
            <Form.Item
              label="Signatory name"
              name="signatoryName"
              rules={[
                {
                  required: true,
                  message: "Please enter signatory name",
                },
              ]}>
              <Input
                className="input-field-style"
                placeholder="Enter signatory name"
              />
            </Form.Item>
          </Col>
          <Col md={12} lg={12} xl={12} sm={24} xs={24}>
            <Form.Item
              label="Signatory PAN number"
              name="signatoryPAN"
              rules={[
                {
                  required: true,
                  message: "Please enter signatory PAN number",
                },
              ]}>
              <Input placeholder="Enter signatory PAN number" />
            </Form.Item>
          </Col>
          {panBusinees && (
            <>
              <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                <Form.Item
                  label="Business PAN number"
                  name="businessPAN"
                  rules={[
                    {
                      required: true,
                      message: "Please enter business pan number",
                    },
                  ]}>
                  <Input
                    className="input-field-style"
                    placeholder="Enter business pan number"
                  />
                </Form.Item>
              </Col>
              <Col md={12} lg={12} xl={12} sm={24} xs={24}></Col>
            </>
          )}

          <Col md={12} lg={12} xl={12} sm={24} xs={24}>
            <Form.Item
              label="Aadhar number"
              name="aadhar"
              rules={[
                {
                  required: true,
                  message: "Please enter aadhar number",
                },
              ]}
              onKeyPress={validateWrapper}
              help={
                <div style={{ margin: "5px 0 10px 0" }}>
                  <InfoCircleOutlined />
                  {" Please fill all the above fields "}
                </div>
              }>
              <Input
                placeholder="Enter aadhar number"
                onChange={handleSendOTP}
                maxLength={14}
                suffix={
                  <Button
                    className="save-btn mt-05 mb-05"
                    disabled={!aadharVerify}
                    onClick={sendOTP}>
                    SEND OTP
                  </Button>
                }
              />
            </Form.Item>
          </Col>

          <Col md={12} lg={12} xl={12} sm={24} xs={24}>
            <Form.Item
              label="OTP"
              name="otp"
              rules={[
                {
                  required: true,
                  message: "Please enter OTP",
                },
              ]}>
              <Input
                // onChange={() => setPanVerify(true)}
                placeholder="Enter OTP"
                maxLength={6}
                suffix={
                  <Button
                    className="save-btn mt-05 mb-05"
                    disabled={!panVerify}
                    onClick={handleVerifyOTP}>
                    VERIFY OTP
                  </Button>
                }
              />
            </Form.Item>
          </Col>
          <div>
            <Row gutter={12}>{address}</Row>
          </div>
        </>
      </Row>
    </Spin>
  );
};

export default KycDetailsWOGST;
