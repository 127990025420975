import React, { useState } from "react";
import { Button, Form, Input, Row, Col, Spin, message } from "antd";

import { newPassword } from "../../Auth/auth";
import { checkSpace } from "../../../utils";

import lockIcon from "../../../assests/images/forgotLockIcon.svg";

const NewPassword = () => {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const onFinish = async (values) => {
    if (values.confirmPassword !== values.new_password) {
      return setShowError(true);
    } else {
      try {
        await newPassword(
          {
            username: values.username,
            code: values.code,
            new_password: values.confirmPassword,
          },
          (e) => {
            message.error(e.message);
          }
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="new-password">
        <Spin spinning={loading}>
          <Row>
            <div className="formIcon">
              <img src={lockIcon} />
            </div>
          </Row>
          <Row className="pass-form-lables">
            <Col span={24}>
              <div className="wlc-div">{"Set New Password"}</div>
            </Col>
            <Col span={24}>
              <div className="msg">Enter a new password for your account.</div>
            </Col>
            <Col span={24}>
              <div className="error">{/* <p>{errorMessage}</p> */}</div>
            </Col>
          </Row>
          <Form onFinish={onFinish} autoComplete="off" className="form">
            <div className="email-div">Email</div>

            <Form.Item
              name="username"
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              rules={[
                {
                  required: true,
                  type: "email",
                  validateTrigger: "onSubmit",
                  message: "Please Enter Valid Email",
                },
              ]}>
              <Input className="input-style" bordered={false} />
            </Form.Item>
            <div className="email-div">Confirmation code</div>

            <Form.Item
              name="code"
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter Code",
                },
              ]}>
              <Input
                autoComplete="code"
                className="input-style"
                bordered={false}
                maxLength={20}
              />
            </Form.Item>
            <div className="email-div">New Password</div>
            <div className="emailInput-div">
              <Form.Item
                name="new_password"
                onKeyPress={(e) => {
                  if (checkSpace(e)) {
                    e.preventDefault();
                  }
                }}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Password",
                  },
                  {
                    pattern: new RegExp(
                      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                    ),
                    message:
                      "Password should consist of at least one uppercase , one lowercase , one number & 1 special character and minimum 8 characters are needed",
                  },
                  () => ({
                    validator(rule, value = "") {
                      if (value.length > 0 && value.length < 8) {
                        return Promise.reject("");
                      } else {
                        return Promise.resolve();
                      }
                    },
                    validateTrigger: "onSubmit",
                  }),
                ]}
                hasFeedback>
                <Input className="input-style" bordered={false} />
              </Form.Item>

              <div className="email-div">Re-Enter Password</div>
              <Form.Item
                name="confirmPassword"
                labelCol={{ span: 24 }}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onKeyPress={(e) => {
                  if (checkSpace(e)) {
                    e.preventDefault();
                  }
                }}
                dependencies={["new_password"]}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Confirm Password",
                  },
                ]}>
                <Input.Password
                  className="input-style"
                  placeholder="Re-Enter Password"
                  bordered={false}
                  // maxLength={25}
                  // minLength={8}
                  onChange={() => setShowError(false)}
                />
              </Form.Item>
              {showError ? (
                <p className="misMatchError">
                  The two passwords that you entered do not match
                </p>
              ) : (
                ""
              )}
            </div>

            <Form.Item>
              <div className="login-btn">
                <Button
                  className="loginBtn"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{ fontWeight: "500" }}
                  block>
                  RESET PASSWORD
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </>
  );
};

export default NewPassword;
