import React, { useState } from "react";
import { Tabs } from "antd";

import Heading from "../../../components/Heading";
import ViewAllVehicle from "./viewAllVehicle";
import CreateVehicle from "./createVehicle";
// import "./style.scss";

const VehicleOnboardTabs = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [type, setType] = useState();
  const [vehicleNo, setVehicleNo] = useState();

  const changeTab = (key) => {
    setActiveKey(key);
    setVehicleNo([]);
    setType(null);
  };

  function handleEdit(vehicleNo, key) {
    setActiveKey(key);
    setType("edit");
    setVehicleNo(vehicleNo);
  }

  function handleSuccess() {
    setActiveKey("2");
    setType("create");
  }

  return (
    <div className="p-2">
      <Heading title={"Vehicle Onboarding"} />
      <div className="vehicle-onboard-tab-container">
        <Tabs
          activeKey={activeKey}
          onChange={changeTab}
          items={[
            {
              label: "Vehicle Onboarding",
              key: "1",
              children: (
                <CreateVehicle
                  type={type}
                  vehicleNo={vehicleNo}
                  activeKey={activeKey}
                  handleSuccess={handleSuccess}
                  handleEdit={handleEdit}
                />
              ),
            },
            {
              label: "View All Vehicle",
              key: "2",
              children: (
                <ViewAllVehicle handleEdit={handleEdit} activeKey={activeKey} />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};
export default VehicleOnboardTabs;
