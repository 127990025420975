import React, { useEffect,useState } from "react";
import { Modal, Row, Col, Form, Button, Input, message,Spin } from "antd";
import { camelCase } from "../../../utils";
import moment from "moment";
import urls from "../../../api/urls";
import NormalAPI from "../../../api";
const { POST_LCR } = urls;
import cross_button from "../../../assests/images/CrossButton.svg";
import "./style.scss";
const GenerateLcrModal = ({
  isModalOpen,
  setIsModalOpen,
  lcrPayload,
  vehicleLcrId,
  handleCallBack,
  unScannedBag,
  reason,
  tripID
}) => {
  const [form] = Form.useForm();
  const [loading,setLoading] = useState(false);
  let date = moment();
  let currentDate = date.format("DD/MM/YYYY");
  useEffect(() => {
    if (lcrPayload) form.setFieldValue("toHub", camelCase(lcrPayload?.toHub));
  }, [lcrPayload]);
  const handleSave = async () => {
    let payload = {
      lcrGenerationRequestDto:lcrPayload,
      lcrEliminatedContentsDto:{
        awbNumbers:unScannedBag?.map((data)=>data?.awbNumber),
        remark:reason
      }
    };
    setLoading(true);
    let responseEligibleBag = await NormalAPI.Api(`${POST_LCR}/${vehicleLcrId}?tripId=${tripID}`).postIdHeader(payload);
    if (responseEligibleBag?.status) {
      setLoading(false);
      message.success("LCR generated");
      form.resetFields();
      setIsModalOpen(false);
      handleCallBack();
    } else if (!responseEligibleBag?.status) {
      setLoading(false);
      message.error(responseEligibleBag?.response?.data?.message || "Network error");
      // handleCallBack();
    }
    
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      className="lcr-modal-wrapper"
      title="Generate LCR"
      open={isModalOpen}
      closeIcon={<img src={cross_button} />}
      onCancel={handleCancel}
      footer={null}
      style={{ padding: "0" }}
    >
      <Spin spinning={loading}>
        <Form
          layout={"vertical"}
          form={form}
          autoComplete="off"
          onFinish={handleSave}
        >
          <Row gutter={12} className="mb-3">
            <Col span={12}>
              <Form.Item label="Hub user" name="hubUser">
                <Input defaultValue={camelCase(lcrPayload?.hubUser)} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="To date" name="toDate">
                <Input defaultValue={currentDate} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="From hub" name="fromHub">
                <Input defaultValue={camelCase(lcrPayload?.fromHub)} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="To hub" name="toHub">
                <Input defaultValue={camelCase(lcrPayload?.toHub)} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Travel by" name="travelBy">
                <Input defaultValue={camelCase(lcrPayload?.travelBy)} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Ownership" name="coLoader">
                <Input defaultValue={camelCase(lcrPayload?.coloader)} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              {lcrPayload?.travelBy?.toUpperCase() === "AIR" ? <Form.Item
                label="Flight number"
                name="flightNumber"
              >
                <Input defaultValue={lcrPayload?.vehicleNum} disabled />
              </Form.Item> : 
                <Form.Item
                  label="Vehicle number"
                  name="vehicleNum"
                >
                  <Input defaultValue={lcrPayload?.vehicleNum} disabled />
                </Form.Item>
              }
            </Col>
            <Col span={12}>
              {lcrPayload?.travelBy?.toUpperCase() === "AIR" ? <Form.Item
                label="Co-loader"
                name="driverName"
              >
                <Input
                  defaultValue={camelCase(lcrPayload?.driverName)}
                  disabled
                />
              </Form.Item> : 
                <Form.Item
                  label="Driver name"
                  name="driverName"
                >
                  <Input
                    defaultValue={camelCase(lcrPayload?.driverName)}
                    disabled
                  />
                </Form.Item>}
            </Col>
          </Row>
          <hr className="divider" />
          <div className="d-flex flex-end p-1 footer-btn">
            <Button htmlType="submit" className="btn-style save-btn btn-two">
            SUBMIT
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default GenerateLcrModal;
