import React, { useState } from "react";
import { Tabs } from "antd";
import "./style.scss";
import Drs from "./pendingDrsList";
import FailedShipmentList from "./failedShipment";
import Heading from "../../../components/Heading";

const DrsList = () => {
  const [step, setStep] = useState("1");
  
  const items = [
    {
      key: "1",
      label: "Pending For DRS",
      children: <Drs />,
    },
    {
      key: "2",
      label: "Failed Shipment List",
      children: <FailedShipmentList />,
    },
  ];

  const onChange = (key) => {
    setStep(key);
  };

  return (
    <div className="consolidated-tab-cntr">
      <Heading title={"DRS Eligibility"} navigateTo={"/last-mile-operations"} />
      <div className="rate-card-tab-container" style={{marginTop: "0.1rem"}}>
        <Tabs
          destroyInactiveTabPane
          defaultActiveKey={"1"}
          activeKey={step}
          items={items}
          onChange={onChange}
        />
      </div>
      
    </div>
  );
};
export default DrsList;
