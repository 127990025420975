import React from "react";
import { Tabs } from "antd";
import Heading from "../../../../components/Heading";
import CreateCalendar from "./createCalendar";
import ViewCalendar from "./viewCalendar";
const TripScheduler = () => {
  return (
    <div className="p-2">
      <Heading title={"Trip Scheduler"} navigateTo={"/dashboard"} id={7} />
      <div className="rate-card-tab-container">
        <Tabs
          destroyInactiveTabPane
          items={[
            {
              label: "Create Schedule",
              key: "2",
              children: <CreateCalendar />,
            },
            {
              label: "View Schedule",
              key: "1",
              children: <ViewCalendar />,
            },
          ]}
        />
      </div>
    </div>
  );
};
export default TripScheduler;
