/* eslint-disable indent */
import React, { useState } from "react";
import { Row, Col, Form, Spin, Input, Button } from "antd";
import Places from "../../../../components/places/places";
import Heading from "../../../../components/Heading";

const TatCalulator = () => {
  const [form] = Form.useForm();
  const [isLoading] = useState(false);
  const [include, setInclude] = useState(1);
  const [originLatLng, setOriginLatLng] = useState({
    latitude: "",
    longitude: "",
  });
  const [destLatLng, setDestLatLng] = useState({
    latitude: "",
    longitude: "",
  });
  const [, setSpeed] = useState(40);

  function timeConvert(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) " + rminutes + " minute(s)";
  }

  const setDetailsHandler1 = (lat, lng) => {
    setOriginLatLng({ latitude: lat, longitude: lng });
  };

  const setDetailsHandler2 = (lat, lng) => {
    setDestLatLng({ latitude: lat, longitude: lng });
  };

  const changeSpeed = (e) => {
    setSpeed(+e.target.value);
  };

  const changeBreak = (e) => {
    // console.log("change", e.target.checked);
    setInclude(e.target.value);
  };

  const calculate = () => {
    calculator(
      originLatLng.latitude,
      originLatLng.longitude,
      destLatLng.latitude,
      destLatLng.longitude
    );
  };

  const calculator = (lat1, lng1, lat2, lng2) => {
    const google = window.google;
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: new google.maps.LatLng(lat1, lng1),
        destination: new google.maps.LatLng(lat2, lng2),
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          let speed = form.getFieldValue("speed");
          let distance = result.routes[0].legs[0].distance.text;
          let time = +distance.split(" ")[0].split(",").join("") / speed;
          let finalTime =
            +include > 0
              ? ((time / 4) * include + time).toFixed(1)
              : time.toFixed(1);
          let finalMinute = finalTime * 60;
          let actualTime = timeConvert(finalMinute);

          form.setFieldValue("distance", distance);
          form.setFieldValue("tat", actualTime);
          form.setFieldValue("totalTAT", null);
        } else {
          console.error("error fetching directions", result, status);
        }
      }
    );
  };
  return (
    <Spin spinning={isLoading}>
      <div className="p-2">
        <Heading
          title={"TAT Calculator"}
          navigateTo={"/master-card-child"}
          id={7}
        />
        <div className="template-container">
          <Form
            autoComplete="off"
            className="global-form profile-container-form"
            layout="vertical"
            form={form}
            scrollToFirstError={{
              behavior: "smooth",
              block: "center",
              inline: "center",
            }}
          >
            <Row className="global-form-row">
              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
              <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                <Row
                  gutter={20}
                  className="mt-1 profile-details-container mainForm"
                >
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Places
                      setDetailsHandler={(lat, lng) =>
                        setDetailsHandler1(lat, lng)
                      }
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Places
                      setDetailsHandler={(lat, lng) =>
                        setDetailsHandler2(lat, lng)
                      }
                    />
                  </Col>
                  <Row
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    style={{
                      width: "100%",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                    gutter={12}
                  >
                    {/* <Checkbox onChange={changeBreak}>
                      Include break hour
                    </Checkbox> */}
                    <Col xs={8} sm={8} md={4} lg={3} xl={3}>
                      <Form.Item
                        name="speed"
                        label="Speed(km/h)"
                        initialValue={40}
                      >
                        <Input
                          placeholder="Speed"
                          type="number"
                          disabled
                          onChange={changeSpeed}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={8} sm={8} md={4} lg={3} xl={3}>
                      <Form.Item
                        name="break"
                        label="Break(in hour)"
                        initialValue={1}
                      >
                        <Input
                          placeholder="Break time"
                          type="number"
                          onChange={changeBreak}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={8} sm={8} md={4} lg={3} xl={3}>
                      <Button
                        disabled={
                          originLatLng?.latitude &&
                          originLatLng?.longitude &&
                          destLatLng?.latitude &&
                          destLatLng?.longitude
                            ? false
                            : true
                        }
                        className={
                          originLatLng?.latitude &&
                          originLatLng?.longitude &&
                          destLatLng?.latitude &&
                          destLatLng?.longitude
                            ? "save-btn"
                            : "btn-style disable-btn"
                        }
                        style={{ height: "2.5rem", marginTop: "14px" }}
                        onClick={calculate}
                      >
                        CALCULATE
                      </Button>
                    </Col>
                  </Row>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="distance" label="Distance">
                      <Input placeholder="Distance" type="text" disabled />
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name="speed"
                      label="Speed(km/h)"
                      initialValue={40}
                    >
                      <Input
                        placeholder="Speed"
                        type="number"
                        onChange={changeSpeed}
                      />
                    </Form.Item>
                  </Col> */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="tat" label="TAT">
                      <Input placeholder="TAT" type="text" disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
            </Row>
          </Form>
        </div>
      </div>
    </Spin>
  );
};
export default TatCalulator;
