import React, { useState, useEffect } from "react";
import { Space, Table, message, Tooltip, Button } from "antd";
import Heading from "../../components/Heading";
import API from "../../api";
import urls from "../../api/urls";
import Smcs_Loader from "../../assests/images/loader-new.gif";
import { ReactComponent as Edit_icon } from "../../assests/images/edit-logo.svg";
import { useNavigate } from "react-router-dom";
function RateCardList() {
  const [loading, setLoading] = useState(false);
  const [rateList, setRateList] = useState([]);
  const { GET_RATE_CARD_LIST } = urls;
  const navigate = useNavigate();

  useEffect(() => {
    getRateCardList();
  }, []);

  const getRateCardList = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_RATE_CARD_LIST
    ).getWithUserNPremiseHeader();
    if (response?.status) {
      const res = response?.response?.data;
      setLoading(false);
      setRateList(res);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const getRateCardById = (id) => {
    navigate("/billing-config", { state: id });
  };

  const columns = [
    {
      title: "Rate Card Template",
      dataIndex: "templateName",
      key: "templateName",
    },
    {
      title: "CP Type",
      dataIndex: "ratecardType",
      key: "ratecardType",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: () => <div>14/12/1997</div>,
    },
    {
      title: "Actions",
      key: "action",
      align: "center",
      className: "actions-col",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Tooltip title={"Edit"} placement="button">
              <p
                className="pointer"
                onClick={() => getRateCardById(record?.billingId)}
              >
                <Edit_icon width={35} height={35} />
              </p>
            </Tooltip>
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <div className="lead-gen-list-container mt-2 ml-2">
        <Heading title="Rate Card List" />
      </div>
      <div className="master-main-container m-1">
        <div className="flex-end  p-1">
          <div className="hub-onboard-buttons">
            <Button
              className="btn-style save-btn mr-1 p-1"
              onClick={() => getRateCardById(null)}
            >
              ADD Billing
            </Button>
          </div>
        </div>
        <Table
          dataSource={rateList}
          columns={columns}
          loading={{
            indicator: (
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            ),
            spinning: loading,
          }}
          pagination={false}
        />
      </div>
    </div>
  );
}

export default RateCardList;
