/* eslint-disable indent */
import React from "react";
import {
  Button,
  Row,
  Col,
  Input,
  Space,
  Tooltip,
  Spin,
  Empty,
  Popconfirm,
  message,
} from "antd";
import { capitaliseWithHyphen, isValidChildAwb } from "../../../utils";
import ScannedShipment from "../../../components/ScannedShipment";
import noBagging from "../../../assests/images/middleMile/noBagging.svg";
import bag from "../../../assests/images/middleMile/bag.svg";
import white_bag_ from "../../../assests/images/middleMile/white-bag-new.svg";
import no_shipment from "../../../assests/images/middleMile/no-shipment.svg";
import lock_bag from "../../../assests/images/middleMile/lock-bag.svg";
import disable_bag_create from "../../../assests/images/middleMile/disable-bag-create.svg";
import cross_img from "../../../assests/images/trash-can.png";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import { ReactComponent as Scan_check } from "../../../assests/images/SVG/check.svg";

export function bodySection(
  loading,
  baggingData,
  inputSearchValue,
  bagSearchData,
  bagColor,
  setChangedBagNum,
  changedBagNum,
  addNewBag,
  bagPlusColor,
  discardBag,
  discardSingleShipment,
  surfaceWeight,
  airWeight,
  finaliseBagContents
) {
  return (
    <Spin
      spinning={loading}
      size="large"
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      {baggingData.length > 0 ? (
        <div className="bagging-div">
          {(inputSearchValue.length > 0 ? bagSearchData : baggingData).map(
            (e, parentIndex) => {
              return (
                <div
                  key={parentIndex}
                  id={parentIndex}
                  className="m-1 section-container"
                >
                  <div style={{ padding: "1rem 1rem 0 1rem" }}>
                    <div className="space-between align-center">
                      <div className="bagging-head align-center">
                        <p>
                          Destination:{" "}
                          <span className="fw-600">
                            {capitaliseWithHyphen(e.destination)}
                          </span>{" "}
                          <span>
                            {
                              bagColor.find((item) => item.value === e.bagColor)
                                .label
                            }
                          </span>
                        </p>
                        <p>
                          Product:{" "}
                          <span className="fw-600">
                            {capitaliseWithHyphen(e.product)}
                          </span>
                        </p>
                        <p>
                          Content Type:{" "}
                          <span className="fw-600">
                            {e.product === "fast track"
                              ? "Dox / Non-Dox"
                              : capitaliseWithHyphen(e.contentType)}
                          </span>
                        </p>
                        <p>
                          Mode:{" "}
                          <span className="fw-600">
                            {capitaliseWithHyphen(e.mode)}
                          </span>
                        </p>
                      </div>
                      <ScannedShipment
                        scannedData={e?.bagList
                          .map((e) => e?.bagContents?.length)
                          .reduce((a, b) => a + b, 0)}
                        totalData={
                          e?.eligibleContent?.length +
                          e?.bagList
                            .map((e) => e?.bagContents?.length)
                            .reduce((a, b) => a + b, 0)
                        }
                      />
                    </div>
                  </div>
                  <hr className="divider" />

                  <Space size="middle" className="flex-end mr-1">
                    <Tooltip title={"Add new bag"}>
                      {e.bagList.some((i) => i.status === "CREATED") ||
                      e.eligibleContent.length === 0 ? (
                        <img src={disable_bag_create} width={30} />
                      ) : (
                        <Popconfirm
                          title={
                            <>
                              <p> Bag Number</p>
                              <Input
                                placeholder="Enter bag number"
                                bordered
                                onChange={(e) =>
                                  setChangedBagNum(e.target.value)
                                }
                                className="bag-num-input"
                                onKeyPress={(e) => {
                                  if (/[^0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                  if (e.target.value.length === 11) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </>
                          }
                          onConfirm={() => {
                            if (isValidChildAwb(changedBagNum)) {
                              addNewBag(
                                parentIndex,
                                e.product,
                                e.contentType,
                                e.mode,
                                e.destinationId
                                  ? e.destinationId
                                  : `mix_${e.mixDestinationHubId}`,
                                e.bagColor
                              );
                            } else {
                              message.error("Enter bag number");
                            }
                          }}
                          okText="CREATE"
                          cancelText="CANCEL"
                        >
                          <span className="pointer">
                            {
                              bagPlusColor.find(
                                (item) => item.value === e.bagColor
                              ).label
                            }
                          </span>
                        </Popconfirm>
                      )}
                    </Tooltip>
                  </Space>
                  <Row className="p-1" style={{ marginTop: "-2.5rem" }}>
                    <Col
                      xl={6}
                      lg={6}
                      md={8}
                      sm={12}
                      xs={24}
                      key={parentIndex}
                      className="bag-design"
                      style={{ marginLeft: "10px" }}
                    >
                      <p className="text-center eligible-content-wrapper">
                        Eligible Contents
                      </p>
                      <div className="eligible-list-wrapper">
                        {e.eligibleContent.length === 0 ? (
                          <div className="center" style={{ height: "80%" }}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </div>
                        ) : (
                          <table className="w-100">
                            <thead className="dark-border-bottom">
                              <th className="text-center">AWB No.</th>
                              <th className="text-left">Weight (kg)</th>
                            </thead>
                            <tbody>
                              {e.eligibleContent?.map((item, i) => (
                                <tr key={i} className="ml-1 dark-border-bottom">
                                  <td style={{ padding: "0 1rem" }}>
                                    {item.barcodeNo}
                                  </td>
                                  <td>{item?.weight}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </Col>
                    {e.bagList.map((item, innerIndex) => {
                      return (
                        <Col
                          xl={6}
                          lg={6}
                          md={8}
                          sm={12}
                          xs={24}
                          key={innerIndex}
                          style={{ marginLeft: "10px" }}
                        >
                          {item.status == "FINALISED" && (
                            <div className="finalise-div">
                              <div
                                className="center"
                                style={{ marginTop: "38%" }}
                              >
                                <img src={lock_bag} />
                              </div>
                            </div>
                          )}
                          <div className="bag-design">
                            <div
                              style={{
                                background: e.bagColor,
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                              }}
                            >
                              <p className="space-between align-center dark-border-bottom">
                                {e.bagColor == "#fff" ? (
                                  <img
                                    src={white_bag_}
                                    className="ml-1"
                                    style={{ height: "20px" }}
                                  />
                                ) : (
                                  <img src={bag} className="ml-1" />
                                )}
                                <span
                                  style={{
                                    padding: "0.5rem",
                                    display: "block",
                                    color:
                                      e.bagColor == "#fff" ? "#444444" : "#fff",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {e.bagColor == "#70C36E"
                                    ? "Green Bag"
                                    : e.bagColor == "#2E7FE1"
                                    ? "Blue Bag"
                                    : e.bagColor == "#D2DD3F"
                                    ? "Yellow Bag"
                                    : e.bagColor == "#FFAB02"
                                    ? "Orange Bag"
                                    : "White Bag"}
                                </span>
                                <Input
                                  className="bag-barcode-num"
                                  value={item.bagBarcodeNo}
                                  disabled
                                />
                                {item.bagBarcodeNo && !item.isDamaged && (
                                  <Popconfirm
                                    title="Are you sure to delete this bag?"
                                    onConfirm={() =>
                                      discardBag(
                                        item.bagBarcodeNo,
                                        item?.bagContents,
                                        parentIndex
                                      )
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <img
                                      src={cross_img}
                                      className="delete-img"
                                    />
                                  </Popconfirm>
                                )}
                              </p>
                            </div>

                            {item?.bagContents?.length > 0 ? (
                              <div style={{ height: "250px" }}>
                                <div
                                  style={{
                                    height: "65%",
                                    overflowX: "scroll",
                                  }}
                                >
                                  {item?.bagContents.map(
                                    (data, contentIndex) => (
                                      <div
                                        className="space-between dark-border-bottom"
                                        key={contentIndex}
                                      >
                                        <div className="ml-1">
                                          {data.barcodeNo} &nbsp;
                                          <Scan_check />
                                        </div>
                                        {item.status !== "FINALISED" && (
                                          <Popconfirm
                                            title="Are you sure to discard this shipment?"
                                            onConfirm={() =>
                                              discardSingleShipment(
                                                data.barcodeNo,
                                                item?.bagContents,
                                                data,
                                                e?.eligibleContent,
                                                parentIndex
                                              )
                                            }
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <img
                                              src={cross_img}
                                              className="delete-img"
                                            />
                                          </Popconfirm>
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                                <div
                                  style={{
                                    background: "#F3F3F3",
                                    padding: "0.4rem",
                                  }}
                                >
                                  Bag Capacity:{" "}
                                  <span
                                    style={{
                                      fontWeight: 500,
                                      color:
                                        item?.bagShipmentWeight.toFixed(2) <=
                                        (e.mode === "surface"
                                          ? surfaceWeight
                                          : airWeight)
                                          ? "#000"
                                          : "#FD701A",
                                    }}
                                  >
                                    {item?.bagShipmentWeight.toFixed(2)}
                                    <span style={{ color: "#000" }}>
                                      /{" "}
                                      {e.mode === "surface"
                                        ? surfaceWeight
                                        : airWeight}{" "}
                                      (Kg)
                                    </span>
                                  </span>
                                </div>
                                <div style={{ padding: "0.5rem 1rem" }}>
                                  <div className="flex-end">
                                    <Button
                                      className={
                                        item.status == "FINALISED"
                                          ? "btn-style ml-1"
                                          : "save-btn ml-1"
                                      }
                                      disabled={item.status == "FINALISED"}
                                      onClick={() =>
                                        finaliseBagContents(
                                          item.bagContents,
                                          item.bagBarcodeNo,
                                          item?.bagShipmentWeight.toFixed(2),
                                          e.mode === "surface"
                                            ? surfaceWeight
                                            : airWeight
                                        )
                                      }
                                    >
                                      FINALIS
                                      {item.status == "FINALISED" ? "ED" : "E"}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="center"
                                style={{ marginTop: "25%" }}
                              >
                                <img src={no_shipment} />
                              </div>
                            )}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              );
            }
          )}
        </div>
      ) : (
        <div className="center" style={{ height: "80%" }}>
          <img src={noBagging} alt="bagging" className="m-1" />
        </div>
      )}
    </Spin>
  );
}
