/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  Tabs,
  Table,
  Space,
  Spin,
  Tooltip,
  Button,
  Empty,
  DatePicker,
  message,
} from "antd";
import { serialNum } from "../../../utils";
import { useNavigate } from "react-router-dom";
import {
  capitaliseWithHyphen,
  getDataRange,
  dateFormat_dmy,
  commonSorterForTable,
} from "../../../utils";
import { getPremiseId } from "../../../utils/worker";
import urls from "../../../api/urls";
import API from "../../../api";
import printerIcon from "../../../assests/images/printIcon.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import UpdateIcon from "../../../assests/images/updateEway.svg";
import moment from "moment";

import search_icon from "../../../assests/images/search-icon.svg";

import "../InScan/style.scss";
const CpWiseManifest = () => {
  const { MANIFEST_CP_LIST, CP_WISE_MANIFEST } = urls;
  const { RangePicker } = DatePicker;

  let premiseId = getPremiseId();
  const navigate = useNavigate();
  const [allCps, setAllCps] = useState();
  const [manifestDetails, setManifestDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [currentCpId, setCurrentCpId] = useState(null);
  const [lastDate, setLastDate] = useState({});
  const [dateRangeStatus, setDateRangeStatus] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: "Sl No",
      align: "center",
      width: 10,
      render: (a, b, idx) => serialNum(10, 1, idx),
    },
    {
      title: "Manifest ID",
      dataIndex: "manifestId",
      key: "manifestId",
      width: 10,

      render: (text) => <span>{text}</span>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 10,
      sorter: (a, b) => commonSorterForTable(a, b, "date"),
      sortOrder: sortedInfo?.columnKey === "date" ? sortedInfo?.order : null,
      ellipsis: true,
      render: (text) => {
        let parsedDate = moment(text, "YYYY-MM-DD HH:mm:ss");
        let formattedDate = parsedDate?.format("DD-MM-YYYY HH:mm:ss");
        return <div>{formattedDate ? formattedDate : null}</div>;
      },
    },
    {
      title: "No of Shipment(s)",
      width: 10,
      dataIndex: "shipmentCount",
      key: "shipmentCount",
    },
    {
      title: "Total Weight (Kg)",
      dataIndex: "totalWeight",
      key: "totalWeight",
      width: 10,
      render: (text) => <span>{text?.toFixed(2)}</span>,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 20,
      render: (text, record) => (
        <Space size="middle">
          <Button
            className=""
            disabled={!record?.isEwayBillPresent}
            style={{
              borderRadius: "5px",
              color: !record?.isEwayBillPresent ? "#919090" : "#2E77D0",
              background: !record?.isEwayBillPresent ? "#EEEEEE" : "white",
              border: "1px solid",
              padding: "2px 4px",
            }}
            onClick={() =>
              navigate("/cp-wise-Eway/" + record?.manifestId, {
                state: record,
              })
            }
          >
            <img src={UpdateIcon} />
            <span className="ml-1 fw-500">E-WAY BILL UPDATE</span>
          </Button>
          <Button
            onClick={() =>
              navigate("/cp-wise-manifest-print/" + record?.manifestId)
            }
          >
            <Tooltip title="Print">
              <img src={printerIcon} />
            </Tooltip>
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getCPDetails();
    setTwoDayDate();
  }, []);

  const setTwoDayDate = () => {
    const dateFormat = dateFormat_dmy || "DD-MM-YYYY";
    const yesterday = moment().subtract(1, "day").format(dateFormat);
    const today = moment().format(dateFormat);
    setLastDate({ fromDate: yesterday, toDate: today });
  };

  useEffect(() => {
    currentCpId && getManifestDetails();
  }, [currentCpId]);

  const getManifestDetails = async (
    start = moment().subtract(1, "day").format(dateFormat_dmy),
    end = new Date()
  ) => {
    setLoading(true);
    let response = await API.Api(
      CP_WISE_MANIFEST + currentCpId + "?" + getDataRange(start, end)
    ).getWithPremiseHeader();
    if (response?.status) {
      let data = response?.response?.data;
      setManifestDetails(data);
      setLoading(false);
    } else {
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
      setLoading(false);
    }
  };

  const getCPDetails = async () => {
    setLoading(true);
    let response = await API.Api(
      MANIFEST_CP_LIST + premiseId?.split("Bearer ")[1]
    ).getWithHeader();

    if (response?.status) {
      let data = response?.response?.data;
      setAllCps(data);
      setCurrentCpId(data?.length > 0 ? data[0]?.cpId : null);
      setLoading(false);
    } else {
      message.error(
        response?.response?.data?.message || "Internal server error."
      );
      setLoading(false);
    }
  };

  const onChangeHandle = (e) => {
    setCurrentCpId(e);
    setTwoDayDate();
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const dateRangeHandle = (e, a) => {
    setLastDate({ fromDate: a[0] || "", toDate: a[1] || "" });
    if (a[0]?.length === 0) {
      setDateRangeStatus(false);
    } else {
      setDateRangeStatus(true);
    }
  };

  return (
    <div className="cp-wise-manifest-cntr" style={{ minHeight: "400px" }}>
      <Spin
        spinning={loading}
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }
      >
        {allCps?.length > 0 ? (
          <>
            <Tabs
              className="cp-tab"
              defaultActiveKey="1"
              type="card"
              size={"small"}
              onChange={onChangeHandle}
              tabBarExtraContent={
                <div className="flex-end  ">
                  <RangePicker
                    style={{
                      borderRadius: "5px 0 0 5px",
                      height: "40px",
                      width: "230px",
                    }}
                    disabledDate={(current) => disabledDate(current)}
                    onChange={dateRangeHandle}
                    value={[
                      Object.keys(lastDate)?.length > 0 &&
                      lastDate?.fromDate !== ""
                        ? moment(
                            lastDate?.fromDate?.replaceAll("-", "/"),
                            dateFormat_dmy
                          )
                        : "",
                      Object.keys(lastDate)?.length > 0 &&
                      lastDate?.toDate !== ""
                        ? moment(
                            lastDate?.toDate?.replaceAll("-", "/"),
                            dateFormat_dmy
                          )
                        : "",
                    ]}
                    format={dateFormat_dmy}
                  />
                  <Button
                    className="center mr-1"
                    disabled={!dateRangeStatus}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "1px solid #d9d9d9",
                      borderRadius: "0 5px 5px 0",
                    }}
                    onClick={() =>
                      getManifestDetails(lastDate?.fromDate, lastDate?.toDate)
                    }
                  >
                    <img src={search_icon} />
                  </Button>
                </div>
              }
              items={allCps?.map((item) => {
                return {
                  label: (
                    <div className="align-center">
                      <div>{capitaliseWithHyphen(item?.cpName)}</div>
                      <div className="cp-count-style center ml-1">
                        {item?.noOfManifest}
                      </div>
                    </div>
                  ),
                  key: item?.cpId,
                  children: (
                    <>
                      <Table
                        onChange={handleChange}
                        className="table-head-sticky"
                        loading={loading}
                        columns={columns}
                        dataSource={manifestDetails}
                        pagination={false}
                      />
                    </>
                  ),
                };
              })}
            />
          </>
        ) : (
          <Empty />
        )}
      </Spin>
    </div>
  );
};

export default CpWiseManifest;
