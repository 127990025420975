import { combineReducers } from "redux";

import appReducer from "./reducers/cashBookingReducer";
import manifestReducer from "./reducers/manifestReducer";
import masterReducer from "./reducers/masterReducer";
import onboardingReducer from "./reducers/onboardingReducer";

const appReducers = combineReducers({
  appReducer,
  manifestReducer,
  masterReducer,
  onboardingReducer,
});

export default appReducers;
