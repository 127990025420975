import React, { useState, useEffect } from "react";
import { serialNum } from "../../../utils";
import { useNavigate } from "react-router";
import { Table } from "antd";
import urls from "../../../api/urls";
import API from "../../../api";
import back_img from "../../../assests/images/rightVector.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import "./style.scss";
const { GET_LCR_LIST } = urls;
const LcrList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState();

  const [total, setTotal] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [pageNum, setPageNum] = useState(0);
  const columns = [
    {
      title: "Sl No",
      align: "center",
      with: 10,
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "Lcr Id",
      dataIndex: "vehicleLcrId",
      key: "vehicleLcrId",
      render: (text) => <span>{text}</span>,
    },

    {
      title: "Vehicle No",
      dataIndex: "vehicleNum",
      key: "vehicleNum",
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => (
        <span>
          {`${text?.split(" ")[0]?.split("-")?.reverse()?.join("-")}` ||
            "------"}
        </span>
      ),
    },
  ];
  useEffect(() => {
    getLcrList();
  }, []);

  const getLcrList = async () => {
    setLoading(true);
    let response = await API.Api(GET_LCR_LIST).getWithPremiseHeader();
    setResponseData(response?.response?.data);
    setTotal(response?.response?.data.length);
    setLoading(false);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  function handlePageChange(pagination) {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
  }

  return (
    <div className="p-2 lcr-list-container">
      <div className="heading mb-1 align-center">
        <img
          src={back_img}
          onClick={() => navigate("/create-trip")}
          style={{ cursor: "pointer" }}
        />
        <p style={{ margin: "0 0 0 5px" }}>
          <b>LCR LIST</b>
        </p>
      </div>
      <div className="lcr-table-list-container">
        <div className="d-flex align-right mb-1">
          <div className="hub-onboard-buttons"></div>
        </div>
        <Table
          columns={columns}
          dataSource={responseData}
          onChange={handlePageChange}
          loading={{
            indicator: (
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            ),
            spinning: loading,
          }}
          pagination={{
            pageSize: pageSize,
            total: total,
            itemRender: itemRender,
            current: pageNum == 0 ? +1 : pageNum,
          }}
        />
      </div>
    </div>
  );
};

export default LcrList;
