/* eslint-disable no-unused-vars */
import { Amplify, Auth } from "aws-amplify";
import { APIs, authDetails } from "../../utils/worker";

export const configureAmplify = async (navigateTo) => {
  Amplify.configure({
    Auth: {
      region: `${authDetails.region}`,
      userPoolId: `${authDetails.userPoolId}`,
      userPoolWebClientId: `${authDetails.userPoolWebClientId}`,
      cookieStorage: {
        domain: `${authDetails.domain}`,
        path: "/",
        expires: 365,
        secure: true,
      },
      authenticationFlowType: "USER_PASSWORD_AUTH",

      oauth: {
        domain: `${authDetails.oauthDomain}`,
        scope: ["phone", "email", "openid"],
        redirectSignIn: `${APIs.baseURL}`,
        redirectSignOut: `${APIs.baseURL}`,
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
  });

  Auth.currentSession()
    .then((user) => {
      console.log("auto sign in", user);
    })
    .catch((err) => {
      // console.log("NO SESSION");
    });
};

export const forgotPassword = async ({ username }, callBack) => {
  try {
    await Auth.forgotPassword(username);
    window.open("/confirmMail");
  } catch (error) {
    callBack(error);
  }
};

export const newPassword = async (
  { username, code, new_password },
  callBack
) => {
  try {
    await Auth.forgotPasswordSubmit(username, code, new_password);
    window.open("/confirmPassword");
  } catch (error) {
    callBack(error);
  }
};

export const signUp = async (signupData, callBack) => {
  const { Username, email, Password, org_name, staff_phone } = signupData;
  try {
    const user = await Auth.signUp({
      username: email,
      password: Password,
      // attributes,
      attributes: {
        email,
        "custom:orgName": org_name,
        "custom:staffPhone1": staff_phone,
      },
    });

    const userConfimParams = {
      username: user.username,
      confirmationCode: user.userSub,
    };

    const userConfirmSignUp = await confirmSignUp({ ...userConfimParams });
  } catch (error) {
    callBack(error);
  }
};

export const confirmSignUp = async ({ username, authenticationCode }) => {
  try {
    await Auth.confirmSignUp({ username, authenticationCode });
    console.log(
      "success confirm sign up ",
      await Auth.confirmSignUp({ username, authenticationCode })
    );
  } catch (error) {
    console.log("error", error);
  }
};

export const federatedSignIn = async (provider, callBack) => {
  try {
    await Auth.federatedSignIn({ provider: provider });
  } catch (error) {
    callBack(error);
  }
};

export const signout = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

// export configureAmplify;
