/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  message,
  Spin,
  Radio,
} from "antd";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import {
  capitalise,
  capitaliseWithHyphen,
  capitalize,
  checkAlphabets,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
  camelCase,
  checkArrowKey,
} from "../../../utils";

import {
  clearAllOnboardedVehicles,
  getSingleOnboarededVehicle,
  getSingleOnboarededVehicleClear,
  onboardVehicle,
  onboardVehicleClear,
  updateOnboardedVehicle,
  updateOnboardedVehicleClear,
} from "../../../actionsMethods/cashBookingMethods";
import axios from "axios";
import { APIs, getToken } from "../../../utils/worker";

import urls from "../../../api/urls";
import API from "../../../api";

import Smcs_Loader from "../../../assests/images/loader-new.gif";

const { GET_VEHICLE_ROUTES, GET_VEHICLE_FEET } = urls;

const createVehicle = ({
  type,
  vehicleNo,
  activeKey,
  handleSuccess = () => {},
  handleEdit = () => {},
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const history = useLocation();

  const [loading, setLoading] = useState(false);

  const [getVehicleData, setGetVehicleData] = useState(null);
  const [editView, setEditView] = useState();
  const [routeData, setRouteData] = useState([]);
  const [hubAdmin, setHubAdmin] = useState(false);
  const [routeId, setRouteId] = useState(null);
  const [coLoader, setCoLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const userinfo = JSON.parse(localStorage.getItem("userinfo"));
  const regex =
    /^(AP|AR|AS|BR|CG|CH|DD|DL|DN|GA|GJ|HR|HP|JK|JH|KA|KL|LD|MH|ML|MN|MP|MZ|NL|OD|PB|PY|RJ|SK|TN|TS|TR|UK|UP|WB)[0-9]{1,2}[A-Z]{1,3}[0-9]{4}$/gm;
  useEffect(() => {
    if (userinfo?.roles?.includes("ROLE_HUBADMIN")) {
      setHubAdmin(true);
    }
  }, []);

  const validateVehicleNumber = () => {
    const vehicleNumber = form.getFieldValue("vehicleNo");
    if (vehicleNumber && !regex.test(vehicleNumber.toUpperCase())) {
      setErrorMsg("Please enter a valid vehicle number (e.g., AP12AB1234)");
      form.setFields([
        {
          name: "vehicleNo",
          errors: ["Invalid vehicle number format"],
        },
      ]);
    } else {
      setErrorMsg("");
      form.setFields([{ name: "vehicleNo", errors: [] }]);
    }
  };

  const permitTypeData = [
    {
      value: "National",
      key: "National",
      label: "National",
    },
    {
      value: "State",
      key: "State",
      label: "State",
    },
  ];

  const OwnershipTypeData = [
    {
      value: " Own",
      key: " Own",
      label: " Own",
    },
    {
      value: "Co-loader",
      key: "Co-loader",
      label: "Co-Loader",
    },
  ];

  const dlClass = [
    {
      label: "MC 50CC",
      value: "MC 50CC",
    },
    {
      label: "MCWOG/FVG",
      value: "MCWOG/FVG",
    },
    {
      label: "MC EX50CC",
      value: "MC EX50CC",
    },
    {
      label: "MGV",
      value: "MGV",
    },
    {
      label: "HMV",
      value: "HMV",
    },
    {
      label: "HGMV",
      value: "HGMV",
    },
    {
      label: "HPMV",
      value: "HPMV",
    },
    {
      label: "LMV",
      value: "LMV",
    },
    {
      label: "LMV-NT",
      value: "LMV-NT",
    },
    {
      label: "HTV",
      value: "HTV",
    },
    {
      label: "Trailer",
      value: "Trailer",
    },
  ];

  useEffect(() => {
    if (type !== undefined) {
      setEditView(type);
    }
    form.resetFields();
    if (editView !== "edit") {
      form.setFieldsValue({
        gpsInstalled: true,
        isLocal: true,
      });
    }
  }, [activeKey]);

  const updateOnboardedVehicleResponse = useSelector(
    (state) => state.appReducer.updateOnboardedVehicleReducer,
    shallowEqual
  );

  const getSingleOnboarededVehicleResponse = useSelector(
    (state) => state.appReducer.getSingleOnboarededVehicleReducer,
    shallowEqual
  );

  const onboardVehicleResponse = useSelector(
    (state) => state.appReducer.onboardVehicleReducer,
    shallowEqual
  );

  const {
    fetching: registerFetching,
    result: registerResult,
    error: registerError,
  } = onboardVehicleResponse;

  const {
    fetching: updateFetching,
    result: updateResult,
    error: updateError,
  } = updateOnboardedVehicleResponse;

  const {
    fetching: singleFetching,
    result: singleResult,
    error: singleError,
  } = getSingleOnboarededVehicleResponse;

  useEffect(() => {
    dispatch(getSingleOnboarededVehicleClear());
  }, [history.pathname]);

  useEffect(() => {
    if (getVehicleData !== null) {
      form.setFieldsValue({
        ownership: getVehicleData?.ownership,
        loadCapacity: getVehicleData?.loadCapacity,
        permitType: getVehicleData?.permitType,
        vehicleNo: getVehicleData?.vehicleNo,
        // noOfWheels: getVehicleData?.noOfWheels,
        vehicleClass: getVehicleData?.vehicleClass,
        loadCapacityInTons: getVehicleData?.loadCapacityInTons,
        vehicleFeet: getVehicleData?.vehicleFeet,
        vehicleMakeAndModel: getVehicleData?.vehicleMakeAndModel,
        coloaderName: getVehicleData?.coloaderName,
        gpsInstalled: getVehicleData?.gpsInstalled,
        isLocal: getVehicleData?.isLocal,
      });

      if (getVehicleData?.ownership == "Co-loader") {
        setCoLoader(true);
      } else {
        setCoLoader(false);
      }

      if (hubAdmin) {
        if (routeData?.length && hubAdmin) {
          let getRouteName = routeData?.find(
            (val) => val?.routeId == getVehicleData?.routeId
          );
          setRouteId(getRouteName?.routeId);
          form.setFieldValue(
            "routeId",
            capitaliseWithHyphen(getRouteName?.name)
          );
        } else {
          form.setFieldsValue({
            gpsInstalled: false,
            isLocal: true,
          });
        }
      }
    }
  }, [getVehicleData]);

  useEffect(() => {
    if ((getVehicleData !== undefined) & hubAdmin) {
      if (editView !== "edit") {
        getVehicleRoutes();
      }
    }
  }, [getVehicleData, hubAdmin]);

  useEffect(() => {
    if (updateResult !== null) {
      message.success(updateResult?.message);
      dispatch(updateOnboardedVehicleClear());
      dispatch(clearAllOnboardedVehicles());
      handleSuccess("2");
    }
    if (updateError !== null) {
      message.error(updateError?.message);
      dispatch(updateOnboardedVehicleClear());
      dispatch(clearAllOnboardedVehicles());
    }
  }, [updateResult, updateError]);

  useEffect(() => {
    if (activeKey == "1" && vehicleNo !== undefined && vehicleNo?.length > 0) {
      dispatch(getSingleOnboarededVehicle(vehicleNo));
    }
  }, [activeKey, vehicleNo]);

  useEffect(() => {
    if (singleResult !== null) {
      setGetVehicleData(singleResult);
    }

    if (singleResult == null) {
      setGetVehicleData(null);
    }

    if (singleError !== null) {
      message.error(singleError?.message);
    }
  }, [singleResult, singleError, activeKey, vehicleNo]);

  useEffect(() => {
    if (registerResult !== null) {
      message.success("Vehicles Details are registered succesfully");
      dispatch(onboardVehicleClear());
      dispatch(clearAllOnboardedVehicles());
      handleSuccess("2");
      form.resetFields();
    }
    if (registerError !== null) {
      message.error(registerError?.message);
      dispatch(onboardVehicleClear());
    }
  }, [registerResult, registerError]);

  const registerNewVehicle = (values) => {
    dispatch(onboardVehicle(values));
  };

  const onUpdate = (values) => {
    delete values.vehicleNo;
    let getRouteId = routeData?.find((val) => val?.routeId == routeId);

    let payload = {
      ...values,
      routeId: getRouteId?.routeId,
    };

    dispatch(updateOnboardedVehicle(payload, form.getFieldValue("vehicleNo")));
  };

  const formProps = {
    name: "createVehicle",
    onFinish: editView == "edit" ? onUpdate : registerNewVehicle,
    autoComplete: "off",
  };

  const handleCancel = () => {
    if (editView === "edit") {
      handleEdit(null, "2");
    } else {
      setEditView(undefined);
      navigate("/onboarding-settings");
    }
    form.resetFields();
  };

  // const vehicleValidation = async (values) => {
  //   let token = getToken();
  //   try {
  //     let res = await axios.get(
  //       `${APIs.baseURL}/vehicle-service/v1/getDetails/fromGovn/${values}`,
  //       {
  //         headers: {
  //           Authorization: token,
  //         },
  //       }
  //     );
  //     if (res.status === 200) {
  //       let getClassData = res.data.data.response.vehicle.category;
  //       let getWeight = res.data.data.response.vehicle.cubicCapacity;

  //       form.setFieldValue("vehicleClass", getClassData);
  //       form.setFieldValue("loadCapacity", getWeight);
  //       let num = (getWeight / 1000)?.toString();
  //       form.setFieldValue("loadCapacityInTons", num);
  //     }
  //   } catch (error) {
  //     form.setFieldValue("vehicleClass", null);
  //     form.setFieldValue("loadCapacity", null);
  //     form.setFieldValue("loadCapacityInTons", null);
  //     message.error("Invalid Number");
  //   }
  // };

  async function getVehicleRoutes() {
    setLoading(true);
    let response = await API.Api(
      `${GET_VEHICLE_ROUTES}`
    ).getWithUserNPremiseHeader();
    if (response?.status) {
      setLoading(false);

      setRouteData(response?.response?.data?.data);
    } else {
      setLoading(false);
    }
  }

  function handleOwnership(params) {
    if (params == "Co-loader") {
      setCoLoader(true);
    } else {
      setCoLoader(false);
    }
  }
  function handleTon(params) {
    if (!hubAdmin) {
      let num = (params.target.value / 1000)?.toString();
      form.setFieldValue("loadCapacityInTons", num);
    }
  }

  const allowedChars = /^[a-zA-Z0-9]+$/;
  const handleKeyDown = (e) => {
    const { key } = e;

    if (
      [
        "ArrowLeft",
        "ArrowRight",
        "Home",
        "End",
        "ArrowUp",
        "ArrowDown",
      ].includes(key)
    ) {
      e.preventDefault();
    }

    const allowedKeys = ["Backspace", "Delete", "Tab", "Shift", "Control"];

    if (!allowedKeys.includes(key) && !allowedChars.test(key)) {
      e.preventDefault();
    }
  };

  const setCaretToEnd = () => {
    const input = document.getElementById("myInput");
    const length = input.value.length;
    input.focus();
    input.setSelectionRange(length, length);
  };

  function handleRouteId(params) {
    setRouteId(params);
  }
  return (
    <div className="master-main-container">
      <Spin
        spinning={
          loading || registerFetching || updateFetching || singleFetching
        }
        size="large"
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }
      >
        <Form
          layout="vertical"
          className="global-form"
          form={form}
          {...formProps}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
        >
          <Row
            className="pt-1 global-form-row vehicle-onboard-create-container"
            gutter={20}
          >
            <Col span={24} className="mb-1">
              <span className="master-title">
                {editView === "edit"
                  ? "Edit Vehicle Details"
                  : "Vehicle Details"}
              </span>
              <hr className="divider" />
            </Col>
            <Col span={12} className="mb-1">
              <Form.Item
                name="vehicleNo"
                label={"Vehicle number"}
                rules={[
                  {
                    required: true,
                    message: "Please enter vehicle number",
                  },
                  // {
                  //   message: "this is custom",
                  //   validator: (_, value) => {
                  //     if (regex.test(value.toUpperCase())) {
                  //       return Promise.resolve();
                  //     } else {
                  //       return Promise.reject("Some message here");
                  //     }
                  //   },
                  // },
                ]}
                onClick={setCaretToEnd}
                onKeyUp={setCaretToEnd}
                onChange={validateVehicleNumber}
                validateStatus={errorMsg ? "error" : ""}
                help={errorMsg}
                onKeyDown={handleKeyDown}
                onKeyPress={(event) => {
                  if (
                    checkSpecialCharacter(event) ||
                    checkSpace(event) ||
                    checkArrowKey(event)
                  ) {
                    event.preventDefault();
                  }
                }}
              >
                <Input
                  id="myInput"
                  style={{ textTransform: "uppercase" }}
                  placeholder="Example:KA32F26222"
                  onBlur={(e) => {
                    form.setFieldValue(
                      "vehicleNo",
                      e.target.value.toUpperCase()
                    );
                  }}
                  disabled={editView === "edit"}
                  // maxLength={15}
                />
              </Form.Item>
            </Col>
            {hubAdmin && (
              <Col span={12} className="mb-1">
                <Form.Item
                  name="routeId"
                  label="Route"
                  rules={[
                    {
                      required: true,
                      message: "Please select route",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Please select route"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase())
                    }
                    options={routeData?.map((val, index) => {
                      return {
                        value: val?.routeId,
                        key: index,
                        label: capitaliseWithHyphen(val?.name),
                      };
                    })}
                    onChange={handleRouteId}
                    // disabled={editView === "edit"}
                  />
                </Form.Item>
              </Col>
            )}

            <Col span={12} className="mb-1">
              <Form.Item
                name="permitType"
                label="Permit type"
                rules={[
                  {
                    required: true,
                    message: "Please select permit type",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Please select permit type"
                  options={permitTypeData}
                  // disabled={editView === "edit"}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vehicleFeet"
                label="Vehicle feet"
                // className="pr-1"
                rules={[
                  {
                    required: true,
                    message: "Enter vehicle feet",
                  },
                ]}
                onKeyPress={(event) => {
                  if (checkNumbervalue(event) || checkSpace(event)) {
                    event.preventDefault();
                  }
                }}
              >
                <Input
                  maxLength={2}
                  placeholder="Please enter vehicle feet"
                  addonAfter={"ft"}
                  onBlur={(e) => {
                    form.setFieldValue("vehicleFeet", +e.target.value);
                  }}
                  // disabled={editView === "edit"}
                />
              </Form.Item>
            </Col>
            {hubAdmin ? (
              <Col span={12}>
                <Form.Item
                  name="loadCapacity"
                  label="Vehicle capacity"
                  rules={[
                    {
                      required: true,
                      message: "Enter vehicle capacity",
                    },
                  ]}
                  onKeyPress={(event) => {
                    if (checkNumbervalue(event) || checkSpace(event)) {
                      event.preventDefault();
                    }
                  }}
                >
                  <Input
                    maxLength={4}
                    placeholder="Please enter load capacity"
                    // disabled={editView === "edit"}
                    onBlur={(e) => {
                      form.setFieldValue("loadCapacity", +e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            ) : (
              <>
                <Col span={6}>
                  <Form.Item
                    name="loadCapacity"
                    label="Weight (kg)"
                    // className="pr-1"
                    rules={[
                      {
                        required: true,
                        message: "Enter weight",
                      },
                      () => ({
                        validator(rule, value = "") {
                          if (parseInt(value) === 0) {
                            return Promise.reject("Value should not be 0");
                          } else {
                            return Promise.resolve();
                          }
                        },
                        // validateTrigger: "onSubmit",
                      }),
                      {
                        pattern: /^\d{1,6}(\.\d{0,})?$/,
                        message: "Invalid format",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter weight"
                      // disabled={editView === "edit"}
                      maxLength={6}
                      onKeyPress={(event) => {
                        if (
                          checkNumbervalue(event) ||
                          checkSpace(event) ||
                          checkSpecialCharacter(event)
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={handleTon}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="loadCapacityInTons" label="Weight (ton)">
                    <Input
                      placeholder="Weight"
                      disabled={true}
                      maxLength={6}
                      onKeyPress={(event) => {
                        if (checkNumbervalue(event) || checkSpace(event)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </>
            )}

            <Col span={12} className="mb-1">
              <Form.Item
                name="vehicleClass"
                label={"Vehicle class"}
                rules={[
                  {
                    required: true,
                    message: "Enter vehicle class",
                  },
                ]}
              >
                {/* <Input
                  placeholder="Enter vehicle class"
                  maxLength={30}
                  // disabled={editView === "edit"}
                  onBlur={(e) => {
                    form.setFieldValue(
                      "vehicleClass",
                      e.target.value.toUpperCase()
                    );
                  }}
                /> */}
                <Select
                  showSearch
                  placeholder="Select vehicle class"
                  // filterOption={(input, option) =>
                  //   (option?.label ?? "")
                  //     ?.toLowerCase()
                  //     ?.includes(input?.toLowerCase())
                  // }
                  options={dlClass}
                  onChange={handleRouteId}
                  // disabled={editView === "edit"}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="mb-1">
              <Form.Item
                name="vehicleMakeAndModel"
                label={"Vehicle model"}
                rules={[
                  {
                    required: true,
                    message: "Please enter vehicle model",
                  },
                ]}
              >
                <Input
                  placeholder="Enter vehicle model"
                  maxLength={30}
                  // disabled={editView === "edit"}
                  // style={{ textTransform: "uppercase" }}
                />
              </Form.Item>
            </Col>

            <Col span={12} className="mb-1">
              <Form.Item
                name="ownership"
                label="Ownership"
                rules={[
                  {
                    required: true,
                    message: "Please select ownership",
                  },
                ]}
              >
                <Select
                  showSearch
                  onChange={handleOwnership}
                  placeholder="Please select ownership"
                  options={OwnershipTypeData}
                  // disabled={editView === "edit"}
                />
              </Form.Item>
            </Col>
            {coLoader && (
              <Col span={12} className="mb-1">
                <Form.Item
                  name="coloaderName"
                  label={"Co-loader name"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter co-loader name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Co-loader name"
                    maxLength={30}
                    // disabled={editView === "edit"}
                    // style={{ textTransform: "uppercase" }}
                    // onBlur={(e) => {
                    //   form.setFieldValue(
                    //     "vehicleClass",
                    //     e.target.value.toUpperCase()
                    //   );
                    // }}
                  />
                </Form.Item>
              </Col>
            )}
            {/* {!coLoader && <Col span={12} className="mb-1"></Col>} */}

            <Col span={6} className="mb-1">
              <Form.Item
                name={"gpsInstalled"}
                label="GPS Installed"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Radio.Group
                // disabled={editView === "edit"}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={6} className="mb-1">
              <Form.Item
                name={"isLocal"}
                label="Local"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Radio.Group
                // disabled={editView === "edit"}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <hr className="divider m-0" />
            </Col>
            <Col span={24} className="flex-end p-1">
              <Button className="cancel-btn w-13" onClick={handleCancel}>
                CANCEL
              </Button>

              <Button htmlType="submit" className="save-btn ml-1 w-13">
                SAVE
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default createVehicle;
