import React, { useState, useEffect } from "react";
import moment from "moment";
import { Form, message, Spin } from "antd";
import { useNavigate } from "react-router-dom";

import API from "../../../../api";
import urls from "../../../../api/urls";
import { capitaliseWithHyphen, dateFormat_dmy } from "../../../../utils";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";

import { UpdateTripScheduleForm } from "./form";

// import { tripData } from "../../../../store/createSchedule";
import "./style.scss";

const { CREATE_SCHEDULE, GET_TRIP_SCHEDULE_DATA, GET_TRIP_SCHEDULE_BY_TYPE } =
  urls;

const UpdateTripSchedule = ({ id, handleModalCancel = () => {} }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [returnOriginDate, setReturnOriginDate] = useState();
  // const [forwardOriginDate, setForwardOriginDate] = useState();

  const [tripData, setTripData] = useState({});
  const [kindOfJourney, setKindOfJourney] = useState("");
  const [recurrence, setRecurrence] = useState(Boolean);
  const [apiRecurrence, setApiRecurrence] = useState(Boolean);

  const [recurrenceType, setRecurrenceType] = useState("");

  const [allForwardTransitData, setallForwardTransitData] = useState([]);
  const [allReturnTransitData, setAllReturnTransitData] = useState([]);
  const [onlyNRemaining, setOnlyNRemaining] = useState("This and Remainings");

  const [currentDate, setCurrentDate] = useState({
    date: "",
    dayOfWeek: "",
  });

  useEffect(() => {
    const currentStart = moment(tripData?.scheduleStartDate, dateFormat_dmy);
    const dayOfWeek = currentStart?.format("dddd");

    setCurrentDate({
      date: currentStart?._i,
      dayOfWeek: dayOfWeek,
    });

    form.setFieldsValue({
      tripId: tripData?.tripId,
      journeyType: tripData.journeyType,
      vehicleId: tripData?.vehicleId,
      vehicleType: tripData?.vehicleType,
      vehicleFeet: tripData?.vehicleFeet,
      vehicleCapacity: tripData?.vehicleCapacity,
      driverName: tripData?.driverName,
      driverNumber: tripData?.driverNumber,
      driverId: tripData?.driverId,
      routeName: capitaliseWithHyphen(tripData?.routeName),
      noOfRecursion: tripData?.noOfRecursion,
      recursiveType: tripData?.recursiveType,
      recursive: tripData?.recursive,
      noOfTransits: tripData?.forwardSchedule?.length - 2,
      returnNoOfTransits: tripData?.returnSchedule?.length - 2,
      days: tripData?.days,
    });

    setKindOfJourney(tripData?.journeyType);
    setRecurrenceType(tripData?.recursiveType);
    setRecurrence(tripData?.recursive);
    setApiRecurrence(tripData?.recursive);
  }, [tripData]);

  useEffect(() => {
    getFormData();
  }, [id]);

  useEffect(() => {
    if (!tripData) return;

    if (
      tripData?.journeyType === "both" ||
      tripData?.journeyType === "forward"
    ) {
      setallForwardTransitData(tripData?.forwardSchedule);
    }

    if (
      tripData?.journeyType === "both" ||
      tripData?.journeyType === "return"
    ) {
      setAllReturnTransitData(tripData?.returnSchedule);
    }
  }, [tripData]);

  useEffect(() => {
    if (allReturnTransitData) {
      changeReturnDepartureFields(allReturnTransitData);
    }
  }, [allReturnTransitData]);

  useEffect(() => {
    if (allForwardTransitData) {
      changeForwardDepartureFields(allForwardTransitData);
    }
  }, [allForwardTransitData]);

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  function disableReturnDate(current) {
    return current && current < moment(currentDate.date, dateFormat_dmy);
  }

  const getFormData = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_TRIP_SCHEDULE_DATA + id
    ).getWithAllHeaders();
    if (response?.status) {
      setLoading(false);
      setTripData(response?.response?.data);
      // setGetData(tripData);
    } else {
      setLoading(false);
    }
  };

  function extractDepartureTimes(obj, data) {
    let temp = [];

    data?.forEach((item, index) => {
      let tempObj = {
        arrivalDate: item.arrivalDate,
        arrivalTime: item.arrivalTime,
      };

      let timeKey = `departureTime${index}`;
      let dateKey = `departureDate${index}`;

      if (obj[timeKey] && obj[dateKey]) {
        tempObj.departureTime = moment(obj[timeKey]?._d).format("HH:mm:ss");
        tempObj.departureDate = obj[dateKey]?._i?.split(" ")[0];
      }

      temp.push(tempObj);
    });
    return temp;
  }

  function extractReturnDepartureTimes(obj, data) {
    let temp = [];
    data?.forEach((item, index) => {
      let tempObj = {
        arrivalDate: item?.arrivalDate,
        arrivalTime: item?.arrivalTime,
      };

      let rtimeKey = `returnDepartureTime${index}`;
      let rdateKey = `returnDepartureDate${index}`;

      if (obj[rdateKey] && obj[rtimeKey]) {
        tempObj.departureTime = moment(obj[rtimeKey]?._d).format("HH:mm:ss");
        tempObj.departureDate = obj[rdateKey]?._i?.split(" ")[0];
      }
      temp.push(tempObj);
    });
    return temp;
  }

  function formatBaseDateTime(date, time) {
    let origindateFormat = moment(date)?.format("YYYY-MM-DD");
    let originTimeFormat = moment(time)?.format("hh:mm a");
    // Combine date and time
    let combinedDateTime = moment(
      `${origindateFormat} ${originTimeFormat}`,
      "YYYY-MM-DD hh:mm a"
    );

    let formattedDate = combinedDateTime.format(
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)"
    );

    return formattedDate;
  }

  async function handleKindOfJourney(params) {
    setKindOfJourney(params);
    setLoading(true);
    let response = await API.Api(
      GET_TRIP_SCHEDULE_BY_TYPE + id + "/for/" + params
    ).getWithAllHeaders();
    if (response?.status) {
      setLoading(false);
      let resData = response?.response?.data;

      const startDate = resData?.scheduleStartDate;

      const dateFormat = moment(startDate, dateFormat_dmy);
      const dayOfWeek = dateFormat?.format("dddd");

      setCurrentDate({
        date: startDate,
        dayOfWeek: dayOfWeek,
      });

      if (params == "forward") {
        setAllReturnTransitData([]);
        setallForwardTransitData(resData?.forwardDtos);
        form.setFieldValue("noOfTransits", resData?.forwardDtos?.length - 2);
      }
      if (params == "return") {
        setallForwardTransitData([]);
        setAllReturnTransitData(resData?.returnDtos);
      }
      if (params == "both") {
        setallForwardTransitData(resData?.forwardDtos);
        setAllReturnTransitData(resData?.returnDtos);
        form.setFieldValue("noOfTransits", resData?.forwardDtos?.length - 2);

        form.setFieldValue(
          "returnNoOfTransits",
          resData?.returnDtos?.length - 2
        );
      }
    } else {
      setLoading(false);
    }
  }

  function handleRecursivePattern(params) {
    setRecurrence(params);
  }

  function handleThisRemainEvent(params) {
    setOnlyNRemaining(params);
  }

  function handleRecurrenceType(params) {
    setRecurrenceType(params);
  }

  const changeForwardDepartureFields = (scheduleArray) => {
    for (const i in scheduleArray) {
      form.setFieldsValue({
        [`departureDate${i}`]: moment(
          scheduleArray[i]?.departureDate,
          dateFormat_dmy
        ),
        [`departureTime${i}`]: moment(
          scheduleArray[i]?.departureTime,
          "HH:mm:ss"
        ),
        [`arrivalDate${i}`]: moment(
          scheduleArray[i]?.arrivalDate,
          dateFormat_dmy
        ),
        [`arrivalTime${i}`]: moment(scheduleArray[i]?.arrivalTime, "HH:mm:ss"),
      });
    }
  };

  const changeReturnDepartureFields = (scheduleArray) => {
    for (const i in scheduleArray) {
      form.setFieldsValue({
        [`returnArrivalDate${i}`]: moment(
          scheduleArray[i]?.arrivalDate,
          dateFormat_dmy
        ),
        [`returnArrivalTime${i}`]: moment(
          scheduleArray[i]?.arrivalTime,
          "HH:mm:ss"
        ),
        [`returnDepartureDate${i}`]: moment(
          scheduleArray[i]?.departureDate,
          dateFormat_dmy
        ),
        [`returnDepartureTime${i}`]: moment(
          scheduleArray[i]?.departureTime,
          "HH:mm:ss"
        ),
      });
    }
  };

  function handleForwardDate(data) {
    const inputDate = moment(data?._d);
    const formattedDate = inputDate.format(dateFormat_dmy);
    const weekDay = inputDate.format("dddd");

    setCurrentDate({
      date: formattedDate,
      dayOfWeek: weekDay,
    });

    form.setFieldsValue({ days: [weekDay] });

    for (let i = 0; i < allForwardTransitData?.length; i++) {
      form.setFieldsValue({
        [`departureDate${i}`]: "",
        [`departureTime${i}`]: "",
        [`returnDepartureDate${i}`]: "",
        [`returnDepartureTime${i}`]: "",
        departureTime0: "",
        returnDepartureTime0: "",

        [`arrivalDate${i + 1}`]: "",
        [`arrivalTime${i}`]: "",
        [`returnArrivalDate${i}`]: "",
        [`returnArrivalTime${i}`]: "",
      });
    }
  }

  function handleForwardTime(data, type) {
    if (type == "forward") {
      let forwardDate = form.getFieldValue("arrivalDate0");
      const formattedDate = formatBaseDateTime(forwardDate?._d, data?._d);
      allForwardTransitData[0].arrivalDate = moment(forwardDate?._d)?.format(
        "DD-MM-YYYY"
      );
      allForwardTransitData[0].arrivalTime = moment(data?._d)?.format(
        "hh:mm a"
      );
      calculateArrivalDeparture(
        formattedDate,
        "forward",
        allForwardTransitData
      );
    }
    if (type == "return") {
      let returnDate = form.getFieldValue("returnArrivalDate0");
      const formattedDate = formatBaseDateTime(returnDate?._d, data?._d);
      allReturnTransitData[0].arrivalDate = moment(returnDate?._d)?.format(
        "DD-MM-YYYY"
      );
      allReturnTransitData[0].arrivalTime = moment(data?._d)?.format("hh:mm a");
      calculateArrivalDeparture(formattedDate, "return", allReturnTransitData);
    }
  }

  const handleReturnOriginDate = (data) => {
    for (let i = 0; i < allReturnTransitData?.length; i++) {
      form.setFieldsValue({
        [`returnDepartureDate${i}`]: "",
        [`returnDepartureTime${i}`]: "",
        returnDepartureTime0: "",
        [`arrivalDepartureDate${i + 1}`]: "",
        [`arrivalDepartureTime${i}`]: "",
        arrivalDepartureTime0: "",
        [`returnArrivalTime${i}`]: "",
        [`returnArrivalDate${i + 1}`]: "",
      });
    }

    if (kindOfJourney === "return") {
      const inputDate = moment(data?._d);
      // const formattedDate = inputDate.format(dateFormat_dmy);
      const weekDay = inputDate.format("dddd");
      setCurrentDate({
        date: currentDate?.date,
        dayOfWeek: weekDay,
      });
    }
  };

  function calculateArrivalDeparture(baseDateTime, type, data) {
    let premises = [...data];
    const formattedBaseDateTime = moment(baseDateTime);

    for (let i = 0; i < premises.length; i++) {
      if (i === 0) {
        premises[i].arrivalDate = formattedBaseDateTime.format(dateFormat_dmy);
        premises[i].arrivalTime = formattedBaseDateTime.format("HH:mm:ss");

        if (premises[i].loadUnloadTime !== null) {
          formattedBaseDateTime.add(premises[i].loadUnloadTime, "minutes");

          premises[i].departureDate =
            formattedBaseDateTime.format(dateFormat_dmy);
          premises[i].departureTime = formattedBaseDateTime.format("HH:mm:ss");
        }
      } else {
        const prevPremise = premises[i - 1];

        const momentObject = moment(
          `${prevPremise.departureDate} ${prevPremise.departureTime}`,
          "DD-MM-YYYY HH:mm:ss"
        );

        const transitTime =
          prevPremise.transitTime !== null ? prevPremise.transitTime : 0;
        momentObject.add(transitTime, "minutes");

        premises[i].arrivalDate = momentObject.format(dateFormat_dmy);
        premises[i].arrivalTime = momentObject.format("HH:mm:ss");

        if (premises[i].loadUnloadTime !== null) {
          momentObject.add(premises[i].loadUnloadTime, "minutes");

          premises[i].departureDate = momentObject.format(dateFormat_dmy);
          premises[i].departureTime = momentObject.format("HH:mm:ss");
        }
      }
    }

    if (type == "forward") {
      setallForwardTransitData(premises);

      changeForwardDepartureFields(premises);
      const forwardDepartDate = premises[premises?.length - 1];

      let combinedDateTime = moment(
        `${forwardDepartDate?.arrivalDate} ${forwardDepartDate?.arrivalTime}`
      );

      const inputDate = moment(combinedDateTime?._i, "DD-MM-YYYY HH:mm:ss");
      const formattedDate = inputDate.format(
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)"
      );
      let temp = [...allReturnTransitData];

      temp[0].departureDate = forwardDepartDate?.departureDate;
      temp[0].departureTime = forwardDepartDate?.departureTime;
      temp[0].arrivalDate = forwardDepartDate?.arrivalDate;
      temp[0].arrivalTime = forwardDepartDate?.arrivalTime;

      setAllReturnTransitData(temp);

      calculateArrivalDeparture(moment(formattedDate), "return", temp);
    }
    if (type == "return") {
      setAllReturnTransitData(premises);
      changeReturnDepartureFields(premises);
    }
  }

  const updateTripSchdule = async (params) => {
    setLoading(true);

    const updateSchedule = (schedules, dtos) => {
      if (!schedules || !dtos) {
        return [];
      }

      return schedules.map((item, i) => ({
        premiseId: item.premiseId,
        premiseName: item.premiseName,
        arrivalDate: dtos[i]?.arrivalDate,
        arrivalTime: dtos[i]?.arrivalTime,
        departureDate: dtos[i]?.departureDate,
        departureTime: dtos[i]?.departureTime,
      }));
    };

    const deleteKeys = (obj, keyPrefix) => {
      for (const key in obj) {
        if (key.startsWith(keyPrefix)) {
          delete obj[key];
        }
      }
    };

    let forwardSchedule;
    let returnSchedule;

    if (params?.journeyType === "forward" || params?.journeyType === "both") {
      forwardSchedule = updateSchedule(
        [...allForwardTransitData],
        extractDepartureTimes(params, allForwardTransitData)
      );

      deleteKeys(params, "departureTime");
      deleteKeys(params, "departureDate");

      deleteKeys(params, "arrivalDate");
      deleteKeys(params, "arrivalTime");

      deleteKeys(params, "noOfTransits");
    }

    if (params?.journeyType === "return" || params?.journeyType === "both") {
      returnSchedule = updateSchedule(
        [...allReturnTransitData],
        extractReturnDepartureTimes(params, allReturnTransitData)
      );

      deleteKeys(params, "returnDepartureTime");
      deleteKeys(params, "returnDepartureDate");

      deleteKeys(params, "returnArrivalTime");
      deleteKeys(params, "returnArrivalDate");

      deleteKeys(params, "returnNoOfTransits");
    }

    const journeyType = params?.journeyType;
    const scheduleDate =
      journeyType === "return"
        ? returnSchedule?.[0]?.arrivalDate
        : forwardSchedule?.[0]?.arrivalDate;

    const scheduleTime =
      journeyType === "return"
        ? returnSchedule?.[0]?.arrivalTime
        : forwardSchedule?.[0]?.arrivalTime;

    let payload = {
      ...params,
      id: id,
      scheduleDate: scheduleDate,
      scheduleTime: scheduleTime,
      forwardSchedule,
      returnSchedule,
      updateTo: onlyNRemaining,
    };

    let response = await API.Api(CREATE_SCHEDULE + id).putIdHeader(payload);

    if (response?.status) {
      setLoading(false);
      navigate("/trip-scheduler");
      message.success(
        response?.response?.data?.message || "Trip schedule successfully"
      );
      handleModalCancel(1);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  return (
    <div className="">
      <Spin
        spinning={loading}
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }
      >
        <UpdateTripScheduleForm
          form={form}
          recurrence={recurrence}
          apiRecurrence={apiRecurrence}
          kindOfJourney={kindOfJourney}
          recurrenceType={recurrenceType}
          allForwardTransitData={allForwardTransitData}
          allReturnTransitData={allReturnTransitData}
          updateTripSchdule={updateTripSchdule}
          disabledDate={disabledDate}
          handleForwardDate={handleForwardDate}
          handleForwardTime={handleForwardTime}
          handleReturnOriginDate={handleReturnOriginDate}
          handleKindOfJourney={handleKindOfJourney}
          handleRecursivePattern={handleRecursivePattern}
          handleRecurrenceType={handleRecurrenceType}
          currentDayWeek={currentDate?.dayOfWeek}
          disableReturnDate={disableReturnDate}
          handleModalCancel={handleModalCancel}
          handleThisRemainEvent={handleThisRemainEvent}
          days={tripData?.days}
        />
      </Spin>
    </div>
  );
};

export default UpdateTripSchedule;
