/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import Heading from "../../components/Heading";
import { Pagination, Spin, message } from "antd";
import urls from "../../api/urls";
import API from "../../api";
const { GET_NOTIFICATION1 } = urls;
import "./style.scss";

const Notification = () => {
  const history = useLocation();
  const [notificationList, setNotificationList] = useState([]);
  const [number, setNumber] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(4);
  const [loading, setLoading] = useState(false);
  let userid = JSON.parse(localStorage.getItem("userId"));

  useEffect(() => {
    initialize();
  }, [history?.state]);

  const initialize = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_NOTIFICATION1 + userid?.userInfo?.userId
    ).get();
    if (response?.status) {
      let data = response?.response?.data;
      data.sort(
        (a, b) =>
          a.viewed.toString().charCodeAt(0) - b.viewed.toString().charCodeAt(0)
      );
      setNotificationList([...data]);
    } else {
      message.error("Network error.");
    }
    setLoading(false);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const handlePage = (pageNumber, pageSize) => {
    setNumber(pageNumber);
    setPostsPerPage(pageSize);
  };

  let newNotificationList = notificationList.slice(
    (number - 1) * postsPerPage,
    postsPerPage * number
  );
  const viewedColor = {
    color: "#A7A7A7",
  };
  const notViewedColor = {
    color: "#2E77D0",
  };

  return (
    <div className="master-main-container" style={{ margin: "2rem 1rem" }}>
      <div style={{ padding: "1rem 1rem 0 1rem" }}>
        <Heading title="Notification" navigateTo="/dashboard" />
      </div>
      <hr className="divider" />
      <Spin spinning={loading}>
        <div className="p-1">
          {
            <div className="notification-container">
              {newNotificationList.map((e, i) => {
                return (
                  <div key={i} className="space-between notification-div">
                    <div style={{ width: "80%" }}>
                      <span
                        className="fw-600"
                        style={e.viewed ? viewedColor : notViewedColor}
                      >
                        {e.notificationTitle}
                      </span>
                      <p style={e.viewed ? viewedColor : {}}>
                        {e.notificationDescpiction}
                      </p>
                    </div>
                    <span
                      className="date-color"
                      style={e.viewed ? viewedColor : notViewedColor}
                    >
                      {e.notificationCreatedDate}
                    </span>
                  </div>
                );
              })}
            </div>
          }
          <br />
          <Pagination
            current={number}
            total={notificationList.length}
            itemRender={itemRender}
            className="flex-end"
            pageSize={postsPerPage}
            onChange={handlePage}
          />
        </div>
      </Spin>
    </div>
  );
};
export default Notification;
