import { takeLatest } from "redux-saga/effects";

import { createWatcher } from "../utils/sagas";
import actions from "../constants/masterActions";

import * as worker from "../workers/master/masterWorker";

export const getPincodeMasterTableWatcher = createWatcher(
  actions.GET_PINCODE_MASTER_TABLE,
  worker.getPincodeMasterTableWorker,
  takeLatest
);

export const getPincodeWatcher = createWatcher(
  actions.GET_PINCODE,
  worker.getPincodeWorker,
  takeLatest
);

export const getStateWatcher = createWatcher(
  actions.GET_STATE,
  worker.getStateWorker,
  takeLatest
);

export const getserviceabilityWatcher = createWatcher(
  actions.GET_SERVICEABILITY,
  worker.getServiceablitiyWorker,
  takeLatest
);

export const updatePincodeWatcher = createWatcher(
  actions.UPDATE_PINCODE,
  worker.updatePincodeWorker,
  takeLatest
);

export const createPincodeWatcher = createWatcher(
  actions.CREATE_PINCODE,
  worker.createPincodeWorker,
  takeLatest
);

//--Rate Card Master--//
export const getDefaultRateCardWatcher = createWatcher(
  actions.GET_DEFAULT_RATE_CARD,
  worker.getDefaultRateCardWorker,
  takeLatest
);

export const getCategoryBasedValueWatcher = createWatcher(
  actions.GET_CATEGORY_BASED_VALUE,
  worker.getCategoryBasedValueWorker,
  takeLatest
);

export const createCashRateCardWatcher = createWatcher(
  actions.CREATE_CASH_RATE_CARD_TEMPLATE,
  worker.createCashRateCardWorker,
  takeLatest
);

export const getCashRateCardTemplatesWatcher = createWatcher(
  actions.GET_CASH_RATE_CARD_TEMPLATES,
  worker.getCashRateCardTemplatesWorker,
  takeLatest
);

export const getCashRateCardDetailsWatcher = createWatcher(
  actions.GET_CASH_RATE_CARD_DETAILS,
  worker.getCashRateCardDetailsWorker,
  takeLatest
);

export const allocateCashRateCardWatcher = createWatcher(
  actions.ALLOCATE_CASH_RATE_CARD,
  worker.allocateCashRateCardWorker,
  takeLatest
);

export const getExistingCashRateCardTemplatesWatcher = createWatcher(
  actions.GET_EXISTING_CASH_RATE_CARD_TEMPLATES,
  worker.getExistingCashRateCardTemplatesWorker,
  takeLatest
);

export const getprofileDetailsWatcher = createWatcher(
  actions.GET_PROFILE_DETAILS,
  worker.getProfileWorker,
  takeLatest
);

export const saveprofileDetailsWatcher = createWatcher(
  actions.SAVE_PROFILE_DETAILS,
  worker.saveProfileWorker,
  takeLatest
);

//----//

//packeting guide//
export const getPacketingGuideFromHublistWatcher = createWatcher(
  actions.GET_PACKETING_GUIDE_FROM_HUBLIST,
  worker.getPacketingGuideFromHublistWorker,
  takeLatest
);
export const getPacketingGuideToHublistWatcher = createWatcher(
  actions.GET_PACKETING_GUIDE_TO_HUBLIST,
  worker.getPacketingGuideToHublistWorker,
  takeLatest
);
export const getPacketingGuideFromCplistWatcher = createWatcher(
  actions.GET_PACKETING_GUIDE_FROM_CPLIST,
  worker.getPacketingGuideFromCplistWorker,
  takeLatest
);
export const getPacketingGuideToCplistWatcher = createWatcher(
  actions.GET_PACKETING_GUIDE_TO_CPLIST,
  worker.getPacketingGuideToCplistWorker,
  takeLatest
);
export const getPacketingGuideMasterWatcher = createWatcher(
  actions.GET_PACKETING_GUIDE_MASTER,
  worker.getPacketingGuideMasterWorker,
  takeLatest
);
export const getPacketingGuideSearchWatcher = createWatcher(
  actions.GET_PACKETING_GUIDE_SEARCH,
  worker.getPacketingGuideSearchWorker,
  takeLatest
);
export const getPacketingGuideDownloadWatcher = createWatcher(
  actions.GET_PACKETING_GUIDE_DOWNLOAD,
  worker.getPacketingGuideDownloadWorker,
  takeLatest
);
export const postPacketingGuideMasterWatcher = createWatcher(
  actions.POST_PACKETING_GUIDE_MASTER,
  worker.postPacketingGuideMasterWorker,
  takeLatest
);
export const putPacketingGuideMasterWatcher = createWatcher(
  actions.PUT_PACKETING_GUIDE_MASTER,
  worker.putPacketingGuideMasterWorker,
  takeLatest
);
export const getPacketConfigurationWatcher = createWatcher(
  actions.GET_PACKET_CONFIGURATION,
  worker.getPacketConfigurationWorker,
  takeLatest
);

export const putPacketConfigurationWatcher = createWatcher(
  actions.PUT_PACKET_CONFIGURATION,
  worker.putPacketConfigurationWorker,
  takeLatest
);
//----//

//pincode mapping//
export const getPincodeMappingWatcher = createWatcher(
  actions.GET_PINCODE_MAPPING,
  worker.getPincodeMappingWorker,
  takeLatest
);
//---------------//

// hub onboarding //
export const getAllHubOnboardWatcher = createWatcher(
  actions.GET_ALL_HUB_ONBOARD,
  worker.getAllHubOnboardWorker,
  takeLatest
);
// -------------------//
export const searchPincodeMasterTableWatcher = createWatcher(
  actions.SEARCH_PINCODE_MASTER_TABLE,
  worker.searchPincodeMasterTableWorker,
  takeLatest
);

export const disableMasterPincodeWatcher = createWatcher(
  actions.ENABLE_DISABLE_PINCODE,
  worker.enableDisablePincodeWorker,
  takeLatest
);

export const deletePincodeAreaWatcher = createWatcher(
  actions.DELETE_PINCODE_AREA,
  worker.enableDisablePincodeWorker,
  takeLatest
);

//--TAT MASTER--//
export const getFromHubTatWatcher = createWatcher(
  actions.GET_FROM_HUB_TAT,
  worker.getFromHubTatWorker,
  takeLatest
);
export const getToHubTatWatcher = createWatcher(
  actions.GET_TO_HUB_TAT,
  worker.getToHubTatWorker,
  takeLatest
);
export const getFMLMTatWatcher = createWatcher(
  actions.GET_FM_LM_TAT,
  worker.getFMLMTatWorker,
  takeLatest
);
//----//
//--Sorting Guide--//
export const getHubListWatcher = createWatcher(
  actions.GET_HUB_LIST,
  worker.getHubListWorker,
  takeLatest
);
export const createSortingGuideWatcher = createWatcher(
  actions.CREATE_SORTING_GUIDE,
  worker.createSortingGuideWorker,
  takeLatest
);
export const getHubBasedSortingGuideWatcher = createWatcher(
  actions.GET_HUB_BASED_SORTING_GUIDE,
  worker.getHubBasedSortingGuideWorker,
  takeLatest
);
export const getAllSortingGuideWatcher = createWatcher(
  actions.GET_ALL_SORTING_GUIDE,
  worker.getAllSortingGuideWorker,
  takeLatest
);
export const viewAllSortingGuideWatcher = createWatcher(
  actions.VIEW_SORTING_GUIDE,
  worker.viewSortingGuideWorker,
  takeLatest
);
//----//
export const premiseTableWatcher = createWatcher(
  actions.PREMISE_TABLE,
  worker.premiseTableWorker,
  takeLatest
);

export const patchOnboardFormWatcher = createWatcher(
  actions.ONBOARD_PATCH,
  worker.patchOnboardFormWorker,
  takeLatest
);

//client wacter
