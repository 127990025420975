/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Space,
  Input,
  Tooltip,
  Popconfirm,
  message,
  Table,
  Button,
  Row,
} from "antd";

import { capitalize, serialNum } from "../../utils";

import urls from "../../api/urls";
import API from "../../api";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { ReactComponent as Edit_icon } from "../../assests/images/edit-logo.svg";
import { ReactComponent as Disable_icon } from "../../assests/images/SVG/disable.svg";
import { ReactComponent as Disable_Edit_icon } from "../../assests/images/SVG/disableEdit.svg";
import { ReactComponent as Allocate } from "../../assests/images/master/allocate.svg";

import search_icon from "../../assests/images/search-icon.svg";
import check_mark from "../../assests/images/SVG/check.svg";
import Smcs_Loader from "../../assests/images/loader-new.gif";
// import Heading from "../../";
import { useNavigate } from "react-router-dom";
// import "./style.scss";
import Heading from "../../components/Heading";

import allocate from "../../assests/images/master/allocate.svg";
import disable_allocate from "../../assests/images/master/disable-allocate.svg";

const { UPDATE_STAFF, LEAD_CP_LIST } = urls;

// import "./style.scss";

const CPLeadGeneartionList = ({ handleEdit = () => {} }) => {
  const [inputSearchValue, setInputSearchValue] = useState("");
  let [tableData, setTableData] = useState([]);
  const [dataSource, setdataSource] = useState(tableData);
  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const navigate = useNavigate();
  useEffect(() => {
    getStaffTable("?pageNum=0&pageSize=10");
  }, []);

  const getStaffTable = async (page) => {
    setLoading(true);
    let response = await API.Api(
      LEAD_CP_LIST + page
    ).getWithUserNPremiseHeader();
    if (response?.status) {
      const res = response?.response?.data;
      setTotalPageSize(res?.count);
      setLoading(false);
      setTableData(res?.leadGenerationResponses);
      // setTableData(res?.leadDetails);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }

    let mockData = {
      count: 1,
      leadGenerationResponses: [
        {
          leadId: 1,
          leadName: "Pradip2",
          name: "Testdwe",
          officialNumber: null,
          officialEmailId: null,
          attachment: ["aaaaa", "bbb", "cc", "d"],
          status: "pending",
          onboaredOn: "2023-12-15T10:15:30.156454",
          leadGeneratorName: "Pradip Rtyuioo",
          rateCardId: 1,
          rateCardName: "Default",
        },
      ],
    };
    // setTableData(mockData?.leadGenerationResponses);

    // setTableData(mockData?.leadGenerationResponses);
  };

  const updateStaffOnboard = async (values) => {
    setLoading(true);
    let response = await API.Api(UPDATE_STAFF).putWithHeader(values);
    if (response?.status) {
      setLoading(false);
      message.success(response?.response?.data?.message);
      getStaffTable();
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  const handleDisable = (id) => {
    let payload = {
      staffId: id,
      status: false,
    };
    updateStaffOnboard(payload);
  };

  const handleEnable = (id) => {
    let payload = {
      staffId: id,
      status: true,
    };
    updateStaffOnboard(payload);
  };

  function handleIdClick(val, currentPage) {
    localStorage.setItem("leadCPId", val);
    navigate("/cp-lead-generation");
  }

  const columns = [
    {
      title: "Sl No",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: 50,
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "Lead Name",
      dataIndex: "leadName",
      key: "leadName",
      width: 100,
      render: (text, record) => {
        return <a onClick={() => handleIdClick(record?.leadId)}>{text}</a>;
      },
    },
    {
      title: "Owner Name",
      dataIndex: "name",
      key: "name",
      width: 100,
    },

    {
      title: "Phone number",
      dataIndex: "officialNumber",
      key: "officialNumber",
      width: 100,
    },
    {
      title: "Email",
      dataIndex: "officialEmailId",
      key: "officialEmailId",
      width: 100,
    },
    {
      title: "Quote",
      dataIndex: "rateCardId",
      key: "rateCardId",
      width: 100,
      //   render: (text) =>
      //     text === null ? (
      //       <a onClick={() => navigate("cp-quotation-list/")}>View Quote</a>
      //     ) : (
      //       "No quote"
      //     ),
      // },
      render: (text, record) => {
        return text !== null ? (
          <a
            onClick={() => {
              localStorage.setItem("leadCPId", record?.leadId);
              navigate("/cp-quotation-list/" + record?.leadId);
            }}
          >
            View Quote
          </a>
        ) : (
          "No Quote"
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,

      render: (text) => {
        return (
          <div className={`lead-list-status ${"CPlead_" + text}`}>
            {capitalize(text) || "--"}
          </div>
        );
      },
    },

    {
      title: "Actions",
      key: "action",
      align: "center",
      className: "actions-col",
      width: 150,
      render: (_, record) => {
        return record?.status ? (
          <Space size="middle">
            <Tooltip title={"Edit"} placement="bottom">
              <p
                className="pointer"
                style={{ marginBottom: 0 }}
                onClick={() => handleIdClick(record?.leadId)}
              >
                <Edit_icon width={35} height={35} />
              </p>
            </Tooltip>
            <Tooltip
              title={record.allocated === "Yes" ? "" : "Allocate"}
              placement="bottom"
            >
              <p
                className="pointer"
                style={{ marginBottom: 0 }}
                onClick={() => handleIdClick(record?.leadId, 2)}
              >
                <Allocate width={35} height={35} />
              </p>
            </Tooltip>
          </Space>
        ) : (
          <Space size="middle">
            <Tooltip title={record.allocated === "Yes" ? "" : "Allocate"}>
              <img
                onClick={() => handleIdClick(record?.leadId, 2)}
                src={record.allocated === "Yes" ? disable_allocate : allocate}
                alt="allocate"
                className={
                  record.allocated === "Yes"
                    ? "w-33 pointer-disable"
                    : "w-33 pointer"
                }
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const filterArray = (data, currValue) => {
    return data?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );
  };

  const onSearch = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(tableData, currValue)
        : filterArray(tableData, currValue);
    setdataSource(filteredData);
  };

  const handlePageChange = (pagination) => {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
    setCurrentPage(pagination.current);
    getStaffTable(
      `?pageNum=${pagination.current - 1}&pageSize=${pagination.pageSize}`
    );
  };

  return (
    <>
      <div className="lead-gen-list-container mt-1 ml-2">
        <Heading title="CP Lead Generation" />
      </div>
      <div className="master-main-container m-1">
        <div className="align-center space-between">
          {/* <div className="pl-1-5 fw-600"></div> */}
          <div className="p-1">
            <Input
              placeholder="Search by Lead name, phone number"
              bordered
              onChange={onSearch}
              style={{ borderRadius: "5px", height: "40px", width: "400px" }}
              suffix={<img src={search_icon} />}
            />
          </div>
          <Row className="p-1">
            <Button
              className="save-btn mr-2"
              onClick={() => {
                localStorage.removeItem("leadCPId");
                navigate("/cp-lead-generation");
              }}
            >
              {" "}
              + Add New CP
            </Button>
          </Row>
        </div>
        <Table
          className="table-head-sticky"
          loading={{
            indicator: (
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            ),
            spinning: loading,
          }}
          columns={columns}
          // loading={loading}
          defaultCurrent={1}
          onChange={handlePageChange}
          dataSource={tableData}
          pagination={{
            // pageSize: pageSize,
            // total: inputSearchValue.length == 0 ? tableData.length : 10,
            current: currentPage,
            pageSize: pageSize,
            total: totalPageSize,
            itemRender: itemRender,
          }}
          rowClassName={(record) => !record?.status && "disabled-row"}
        />
      </div>
    </>
  );
};

export default CPLeadGeneartionList;
