/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import MasterSearch from "../../../components/Master/MasterSearch";

import { Row } from "antd";
import { childMaster } from "../../../config/childMaster";
import MasterCard from "../../../components/Master/MasterCard/MasterCard";
import { useLocation, useNavigate } from "react-router-dom";

function masterChild() {
  const navigate = useNavigate();
  const history = useLocation();
  const [masterList, setMasterList] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [masterSearchTitle, setMasterSearchTitle] = useState("Master Settings");
  const filterArray = (dataSource, currValue) =>
    dataSource?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );
  const setVisibleMasterList = () => {
    window.history.back();
  };

  const onClickHandler = (route) => {
    navigate(route);
  };

  useEffect(() => {
    setMasterList(childMaster.filter((e)=>e.parentId === history?.state));
  }, []);


  const searchSetting = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(masterList, currValue)
        : filterArray(masterList, currValue);
    setDataSource(filteredData);
  };
  return (
    <div className="master-container">
      <Row gutter={30}>
        <MasterSearch
          title={masterList.length>0?masterList[0].parentHeading:""}
          search={searchSetting}
          setVisibleMasterList={setVisibleMasterList}
          setMasterSearchTitle={setMasterSearchTitle}
          goBack={"master"}
          MasterSearch={false}
        />
        {inputSearchValue.length == 0
          ? masterList.map((e) => {
              return (
                <MasterCard
                  id={e.id}
                  key={e.id}
                  title={e.title}
                  imgSrc={e.imgSrc}
                  route={e.route}
                  onClickHandler={onClickHandler}
                  searchTitle={masterSearchTitle}
                  disable={e.disable}
                />
              );
            })
          : dataSource.map((e) => {
              return (
                <MasterCard
                  id={e.id}
                  key={e.id}
                  title={e.title}
                  imgSrc={e.imgSrc}
                  route={e.route}
                  onClickHandler={onClickHandler}
                  searchTitle={masterSearchTitle}
                  disable={e.disable}
                />
              );
            })}
      </Row>
    </div>
  );
}

export default masterChild;
