/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  message,
  Select,
  DatePicker,
  Spin,
  Modal,
  Checkbox,
  Upload,
} from "antd";
import {
  capitalize,
  checkAlphabets,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
} from "../../../utils";
import uploadLogo from "../../../assests/images/bx_upload.svg";

import urls from "../../../api/urls";
import API from "../../../api";

import Smcs_Loader from "../../../assests/images/loader-new.gif";
import { APIs } from "../../../utils/worker";
import { useParams } from "react-router-dom";

const {
  GET_HUB_PINCODE,
  UPDATE_LEAD_CP_DETAILS,
  GET_ALL_CP_HUBS,
  LEAD_CP_UPLOAD,
  GET_CPTYPE_CHECKBOX,
  GET_RATE_CARD_LIST,
} = urls;

export const CPDetails = ({
  handleStepsChange = () => {},
  patchClient,
  getData,
  goBackStep = () => {},
}) => {
  const { Dragger } = Upload;
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [checkBoxList, setCheckBoxList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [fileList, setfileList] = useState([]);
  const [checkBoxRaw, setCheckBoxRow] = useState([]);
  const [hubList, setHubList] = useState([]);
  const [motherHub, setMotherHub] = useState(null);
  // const [fileName, setFileName] = useState(null);
  const [clickedButton, setClickedButton] = useState(null);
  const [selectedBillingId, setSelectedBillingId] = useState(null);

  let lg = 12;
  let sm = 24;
  let md = 12;
  let xs = 24;

  const userData = JSON.parse(localStorage.getItem("userinfo"));
  const [rateCardTypeOptions, setRateCardTypeOptions] = useState(null);

  useEffect(() => {
    getAllHubs();
    // getRateType();
  }, []);

  useEffect(() => {
    if (getData?.cpDetail?.cpType) {
      cpTypeOnChange(getData?.cpDetail?.cpType);
    }
  }, [getData?.cpDetail?.premiseRbacDto?.rbacs]);

  useEffect(() => {
    if (getData) {
      form.setFieldsValue({
        premiseName: getData?.cpDetail?.premiseName,
        cpType: getData?.cpDetail?.cpType,
        parentPremiseName: capitalize(getData?.cpDetail?.parentPremiseName),
        zone: capitalize(getData?.cpDetail?.zone),
        rateCardType: getData?.cpDetail?.rateCardType,
        billingCycle: getData?.cpDetail?.billingCycle,
        addressLine1: getData?.cpDetail?.addressLine1,
        addressLine2: getData?.cpDetail?.addressLine2,
        pincode: getData?.cpDetail?.pincode,
        city: capitalize(getData?.cpDetail?.city),
        state: capitalize(getData?.cpDetail?.state),
        // declaredAmount: getData?.cpDetail?.declaredAmount,
      });
      setfileList(getData?.cpDetail?.attachments);
      form.setFieldValue("idUrl", getData?.cpDetail?.attachments);

      let temp = getData?.premiseRbacDto?.rbacs?.map((item) => ({
        label: item?.module,
        value: item?.rbacId,
      }));
      // setCheckBoxList(temp);
      setSelectedBillingId({
        label: getData?.cpDetail?.rateCardType,
        value: getData?.cpDetail?.billingConfigId,
        billingCycle: getData?.cpDetail?.billingCycle,
      });
      setCheckedList(temp?.map((val) => val.value));
    }
  }, [getData]);

  async function getRateType() {
    setLoading(true);
    let response = await API.Api(GET_RATE_CARD_LIST).getWithPremiseHeader();
    if (response?.status) {
      setLoading(false);
      let temp = response?.response?.data?.map((item) => ({
        label: item?.rateCardType,
        value: item?.billingId,
        billingCycle: item?.billingCycle,
      }));
      setRateCardTypeOptions(temp);
    } else {
      message.error(response?.response?.data.message || "Network error");
      setLoading(false);
    }
  }

  const handleRateType = (params) => {
    let temp = rateCardTypeOptions?.find((val) => val?.value == params);
    form.setFieldValue("billingCycle", temp?.billingCycle);
    setSelectedBillingId(temp);
  };

  const getAllHubs = async () => {
    let response = await API.Api(GET_ALL_CP_HUBS).getCustomHeader({
      "USER-ID": userId?.userInfo?.userId,
    });
    let data = response?.response?.data;
    if (response) {
      setHubList(data);
    } else {
      setHubList([]);
    }
  };

  const updateCPDetails = async (formValues) => {
    setLoading(true);

    delete formValues.idUrl;

    if (formValues?.pincode?.toString()?.length < 5) {
      form.setFields([
        {
          name: "pincode",
          errors: ["Please enter valid PIN code"],
        },
      ]);
      setLoading(false);

      return;
    } else {
      const cpID = localStorage.getItem("leadCPId");
      const checkBtn = clickedButton === "saveAndContinueButton";

      const selectedOptions = checkBoxRaw?.filter((item) =>
        checkedList?.includes(item.rbacId)
      );
      const filterHub = hubList?.find((val) => {
        if (motherHub) {
          return val?.premiseId === motherHub;
        } else {
          return val?.premiseId === getData?.cpDetail?.parentId;
        }
      });

      const handleFiles = (array) => {
        let outputArray = [];
        if (Array.isArray(array) && array?.length > 0) {
          array.forEach((item) => {
            if (typeof item === "string") {
              outputArray.push(item);
            } else if (item && item?.response) {
              outputArray.push(item?.response);
            }
          });
        }
        return outputArray;
      };

      const payload = {
        leadId: +cpID,
        cpDetail: {
          ...formValues,
          rateCardType: selectedBillingId?.label,
          billingConfigId: selectedBillingId?.value,
          parentPremiseName: filterHub?.premiseName,
          parentId: filterHub?.premiseId,
          attachments: handleFiles(fileList),
        },
        premiseRbacDto: {
          rbacs: selectedOptions,
        },
      };

      const response = await API.Api(
        UPDATE_LEAD_CP_DETAILS + "/" + cpID
      ).putCustomHeader(payload, { "USER-ID": userId?.userInfo?.userId });
      if (response?.status) {
        setLoading(false);
        message.success("CP details updated successfully");
        if (checkBtn) {
          handleStepsChange(1);
        }
      } else {
        setLoading(false);
        message.error(response?.response?.data?.message);
      }
    }
  };

  const cpTypeOnChange = async (e) => {
    setLoading(true);
    let response = await API.Api(GET_CPTYPE_CHECKBOX + e).get();
    if (response.status) {
      setLoading(false);
      let temp = response?.response?.data?.map((item) => ({
        label: item?.module,
        value: item?.rbacId,
      }));
      setCheckBoxList(temp);
      setCheckBoxRow(response?.response?.data);
    } else {
      setLoading(false);
      setCheckBoxList([]);
      setCheckBoxRow([]);
      message.error(response?.response?.error);
    }
  };

  const handlePincodeInput = async (values) => {
    if (values?.target?.value?.length > 5) {
      setLoading(true);
      let response = await API.Api(
        GET_HUB_PINCODE + values?.target?.value
      ).getWithPremiseHeader();
      if (response?.status) {
        setLoading(false);
        form.setFieldValue("state", response?.response?.data?.stateName);
        form.setFieldValue("city", response?.response?.data?.city);
      } else {
        message.error(response?.response?.data?.message || "Invalid pincode");
        setLoading(false);
        form.setFieldValue("state", null);
        form.setFieldValue("city", null);
      }
    }
  };

  const handleCheckboxChange = (checkedValue) => {
    setCheckedList([...checkedList, checkedValue]);
    if (checkedList.includes(checkedValue)) {
      setCheckedList(checkedList.filter((value) => value !== checkedValue));
    } else {
      setCheckedList([...checkedList, checkedValue]);
    }
  };

  const onChange = (value) => {
    setMotherHub(value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase());

  const handlePreview = async (file) => {
    window.open(file?.response);
  };

  const fileProps = {
    name: "files",
    multiple: true,
    listType: "picture",
    showUploadList: true,
    action: `${LEAD_CP_UPLOAD + "document"}`,
    className: "upload-list-inline",

    fileList:
      Array.isArray(fileList) &&
      fileList.length > 0 &&
      typeof fileList[0] === "object"
        ? fileList
        : fileList?.length
        ? fileList?.map((val) => {
            return { name: val?.split("/").pop(), response: val };
          })
        : null,
  };

  const handleChange = ({ fileList: newFileList, file }) => {
    setfileList(newFileList);
    form.setFieldValue("idUrl", newFileList);
  };

  function handleRemoveFile(params) {
    message.success("File removed successfully");
    // setfileList([]);
  }

  const beforeUpload = (file, onSuccess) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (file) {
      let checkSameFile = fileList
        ?.map((val) => val?.name)
        .some((val) => val === file?.name);

      if (file?.name?.includes("jfif")) {
        message.error("File not supported");
        return Upload.LIST_IGNORE;
      }
      if (checkSameFile) {
        message.error("Same file cannot be uploaded again");
        return Upload.LIST_IGNORE;
      }
      if (!isLt2M) {
        message.error("File size should be less than 10MB");
        return Upload.LIST_IGNORE;
      }
      if (!isJpgOrPng) {
        message.error("File not supported");
        return Upload.LIST_IGNORE;
      }
      return isJpgOrPng && isLt2M;
    } else {
      if (onSuccess) {
        message.success("File uploaded");
      }
      onSuccess("ok");
      return true;
    }
  };

  const headers = {
    Authorization: "Bearer " + userData?.accessToken,
    "SMCS-PREMISE-ID": userId?.userInfo?.premiseId,
    "USER-ID": userId?.userInfo?.userId,
  };

  return (
    <Spin
      spinning={loading}
      size="large"
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }>
      <div>
        <Form
          className="global-form profile-container-form pl-4 pr-4 p-2"
          layout="vertical"
          form={form}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          onFinish={updateCPDetails}>
          <Row gutter={40} className="global-form-row">
            <Col span={24} className="mb-1">
              <span className="master-title">CP Details</span>
              <hr className="divider" />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="premiseName"
                label="CP name"
                rules={[
                  {
                    required: true,
                    message: "Please enter CP name",
                  },
                ]}
                onKeyPress={(event) => {
                  if (checkAlphabets(event)) {
                    event.preventDefault();
                  }
                }}>
                <Input
                  placeholder="Enter CP name"
                  // disabled={getData ? true : false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="cpType"
                label="CP type"
                rules={[
                  {
                    required: true,
                    message: "Please select CP type",
                  },
                ]}>
                <Select
                  showSearch
                  placeholder="Please select CP type"
                  // options={CpTypeOptions}
                  options={[
                    {
                      value: "Booking",
                      label: "Booking",
                    },
                    {
                      value: "Delivery",
                      label: "Delivery",
                    },
                    {
                      value: "Booking & Delivery",
                      label: "Booking & Delivery",
                    },
                  ]}
                  onSelect={(e) => cpTypeOnChange(e)}
                  // disabled={editView === "edit"}
                />
              </Form.Item>
            </Col>
            {checkBoxList?.length !== 0 && (
              <>
                <Col lg={lg} md={md} sm={sm} xs={xs}></Col>
                <Col lg={lg} md={md} sm={sm} xs={xs}>
                  <Form.Item name="typeOption">
                    {checkBoxList?.map((option, index) => {
                      return (
                        <>
                          <Checkbox
                            key={index}
                            onChange={() => handleCheckboxChange(option?.value)}
                            checked={checkedList?.includes(option?.value)}>
                            {option?.label}
                          </Checkbox>
                        </>
                      );
                    })}
                  </Form.Item>
                </Col>
              </>
            )}

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="parentPremiseName"
                label="Mother hub name"
                rules={[
                  {
                    required: true,
                    message: "Please enter mother hub name",
                  },
                ]}
                onKeyPress={(event) => {
                  if (checkAlphabets(event)) {
                    event.preventDefault();
                  }
                }}>
                <Select
                  showSearch
                  placeholder="Select a mother hub name"
                  optionFilterProp="children"
                  onChange={onChange}
                  filterOption={filterOption}
                  options={
                    hubList?.length
                      ? hubList?.map((val) => {
                          return {
                            value: val?.premiseId,
                            label: capitalize(val?.premiseName),
                          };
                        })
                      : []
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="zone"
                label="Zone"
                rules={[
                  {
                    required: true,
                    message: "Please enter zone",
                  },
                ]}>
                <Input
                  placeholder="Enter zone"
                  // disabled={getData ? true : false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="rateCardType"
                label="Rate card type"
                rules={[
                  {
                    required: true,
                    message: "Please select rate card type",
                  },
                ]}>
                <Select
                  showSearch
                  placeholder="Select rate card type"
                  // onChange={handleRateType}
                  onFocus={getRateType}
                  onSelect={handleRateType}
                  options={rateCardTypeOptions}
                  // disabled={editView === "edit"}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="billingCycle"
                label="Billing cycle"
                rules={[
                  {
                    required: true,
                    message: "Please enter billing cycle",
                  },
                ]}>
                {/* <Select
                  showSearch
                  placeholder="Select billing cycle"
                  options={billingCycleOptions}
                  disabled={true}
                /> */}
                <Input placeholder="Enter billing cycle" disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="addressLine1"
                label="Address line 1"
                rules={[
                  {
                    required: true,
                    message: "Please enter address line 1",
                  },
                ]}>
                <Input
                  placeholder="Enter address line 1"
                  // disabled={getData ? true : false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item name="addressLine2" label="Address line 2">
                <Input
                  placeholder="Enter address line 2"
                  // disabled={getData ? true : false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {/* <Form.Item
                name="pincode"
                label="Pincode"
                rules={[
                  {
                    required: true,
                    message: "Please enter pincode",
                  },
                ]}
              >
                <Input
                  placeholder="Enter pincode"
                  // disabled={getData ? true : false}
                />
              </Form.Item> */}
              <Form.Item
                name="pincode"
                label="PIN code"
                rules={[
                  {
                    message: "Please enter PIN code",
                    required: true,
                  },
                ]}>
                <Input
                  placeholder="Enter PIN code"
                  onChange={handlePincodeInput}
                  maxLength={6}
                  onKeyPress={(event) => {
                    if (
                      checkNumbervalue(event) ||
                      checkSpace(event) ||
                      checkSpecialCharacter()
                    ) {
                      event.preventDefault();
                    }
                  }}
                  // disabled={getData ? true : false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "Please enter city",
                  },
                ]}>
                <Input placeholder="Enter city" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    required: true,
                    message: "Please enter state",
                  },
                ]}>
                <Input placeholder="Enter state" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}></Col>
            {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="declaredAmount"
                label="Declared advance amount"
                rules={[
                  {
                    required: true,
                    message: "Please enter declared advance amount",
                  },
                ]}
                onKeyPress={(event) => {
                  if (checkNumbervalue(event)) {
                    event.preventDefault();
                  }
                }}
              >
                <Input
                  maxLength={10}
                  placeholder="Enter declared advance amount"
                  // disabled={getData ? true : false}
                />
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="idUrl"
                label="Upload file"
                rules={[
                  {
                    required: true,
                    message: "Please upload a file",
                  },
                ]}>
                <Dragger
                  {...fileProps}
                  className=""
                  onChange={handleChange}
                  onRemove={handleRemoveFile}
                  // defaultFileList={[...fileList]}
                  // disabled={formResult?.idUrl}
                  maxCount={3}
                  onPreview={handlePreview}
                  beforeUpload={beforeUpload}
                  multiple={false}
                  headers={headers}
                  // fileList={fileList}
                >
                  <Row>
                    <Col
                      span={6}
                      className="m-auto"
                      style={{ borderRight: "1px solid #CECECE" }}>
                      <img
                        src={uploadLogo}
                        className="Upload-logo"
                        style={{ width: "40px", height: "40px" }}
                      />
                    </Col>
                    <Col span={18} className="f-13">
                      <p>Click to upload or drag and drop</p>
                      {/* <p>Maximum file size 10 MB</p> */}
                      <p>
                        Supported file type: PDF, JPEG, PNG and Max 5MB file
                        size
                      </p>
                    </Col>
                  </Row>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
          <hr className="divider" />
          <div className="flex-end mt-1">
            <Button className="cancel-btn ml-1 w-15" onClick={goBackStep}>
              CANCEL
            </Button>
            <Button className="cancel-btn ml-1  w-15" htmlType="submit">
              SAVE
            </Button>
            <Button
              htmlType="submit"
              className="save-btn  ml-1"
              id="saveNContinue"
              onClick={() => setClickedButton("saveAndContinueButton")}>
              SAVE & CONTINUE
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default CPDetails;
