export function contentDiscriptionData() {
  const provinceData = [
    "Apparel & Accessories",
    "Automobile",
    "Books & Stationary",
    "Consumables/FMCG",
    "Covid 19 Essentials",
    "Electronics",
    "Household Items",
    "Sports Equipment",
    "Baby Care",
  ];
  const cityData = {
    "Apparel & Accessories": ["Accessories", "Clothes", "Footwear"],
    Automobile: ["Ancillary tools & Parts", "Navigation Devices"],
    "Baby Care": [
      "Baby Food",
      "Baby Medical & Health Care",
      "Diaper",
      "Hair Oil , Skin care etc",
    ],
    "Books & Stationary": ["Office Supplies", "Games", "Books & Magazines"],
    "Consumables/FMCG": [
      "Grooming & Beauty Products ",
      "Non-perishable Food Items",
    ],
    "Covid 19 Essentials": [
      "Masks, Gloves, Face Shields etc.",
      "Medicines",
      "PPE Kits",
    ],
    Electronics: [
      "Chargers, Earphones etc.",
      "Printer, Modem, Router etc.",
      "Wireless Devices",
    ],
    "Household Items": [
      "Gardening Tools & Materials",
      "Heating & Cooling Appliances",
      "Kitchen & Home Appliances",
      "Kitchenware",
      "Lifestyle/ Décor Items",
      "Pet Supplies",
      "Small Furniture",
    ],
    "Sports Equipment": ["Gym Equipment", "Sports Accessories", "Sports Gear"],
  };
  return { cityData, provinceData };
}
