/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import axios from "axios";
import {
  Radio,
  Row,
  Col,
  Form,
  Input,
  Space,
  Button,
  Select,
  Table,
  Spin,
  message,
} from "antd";
import BottomButton from "./BottomButton";
import Heading from "./Heading";
import Dragger from "./Dragger";
import Places from "../../components/places";
import { capitaliseWithHyphen } from "../../utils";
import { APIs } from "../../utils/worker";

const CpDetails = ({ handleStepsChange, goBackStep }) => {
  const [msmeValue, setMsmeValue] = useState("Yes");
  const [tanValue, setTanValue] = useState("Yes");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const columns = [
    {
      title: "PIN code",
      dataIndex: "pinCode",
      key: "pinCode",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
    },
  ];
  const data = [
    {
      pinCode: 560068,
      type: "Regular",
      area: "Bomanhalli, Hongadsndra, Kodichikkanahalli, devarachikkanahalli",
    },
    {
      pinCode: 560061,
      type: "Regular",
      area: "HSR Layout",
    },
  ];
  const msmeRadioChange = (e) => {
    setMsmeValue(e.target.value);
  };
  const tanRadioChange = (e) => {
    setTanValue(e.target.value);
  };
  const onCpDetailsFinish = (e) => {};
  const onAddressDetailsFinish = (e) => {
    handleStepsChange();
  };
  const setDetailsHandler = (lat, lng) => {
    form.setFieldValue("latitude", lat);
    form.setFieldValue("longitude", lng);
  };
  const pinCodeHandler = async (e) => {
    if (e.target.value.length > 5) {
      setLoading(true);
      let userData = JSON.parse(localStorage.getItem("userinfo"));

      try {
        let response = await axios.get(
          `${APIs.baseURL}/pincode-service/v1/pincode/${e.target.value}`,
          {
            headers: {
              Authorization: "Bearer " + userData.accessToken,
            },
          }
        );
        let res = response.data;
        if (res?.stateName) {
          setLoading(false);
          if (
            res?.enable == true &&
            res.serviceability.serviceability !== "Non-Serviceable"
          ) {
            form.setFieldsValue({
              city: res?.city !== null ? capitaliseWithHyphen(res?.city) : "NA",
              stateName: capitaliseWithHyphen(res?.stateName),
              stateCode: res?.stateCode,
            });
          } else {
            setLoading(false);
            form.setFields([
              {
                name: "pinCode",
                errors: ["Please give correct pincode"],
              },
            ]);
          }
        }
      } catch (error) {
        message.error(error?.response);
        setLoading(false);
        form.setFields([
          {
            name: "pinCode",
            errors: ["Network error"],
          },
        ]);
      }
    } else if (e.target.value.length < 6) {
      form.setFieldsValue({
        city: "",
        stateCode: "",
        stateName: "",
      });
    }
  };
  return (
    <div className="cp-detail-wrapper">
      <Spin spinning={loading}>
        <Heading title={"CP Details"} />
        <Form
          className="global-form"
          layout={"vertical"}
          onFinish={onCpDetailsFinish}>
          <Row gutter={12} className="global-form-row">
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="CP name"
                name="cpName"
                rules={[
                  {
                    required: true,
                    message: "Please enter CP name",
                  },
                ]}>
                <Input placeholder="Enter CP name" />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="CP type"
                name="cpType"
                rules={[
                  {
                    required: true,
                    message: "Please enter CP type",
                  },
                ]}>
                <Input placeholder="Enter CP type" />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Parent Hub"
                name="parentHub"
                rules={[
                  {
                    required: true,
                    message: "Please enter parent hub",
                  },
                ]}>
                <Input placeholder="Enter parent hub" />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Zone"
                name="zone"
                rules={[
                  {
                    required: true,
                    message: "Please enter zone",
                  },
                ]}>
                <Input placeholder="Enter zone" />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Rate card type"
                name="rateCardType"
                rules={[
                  {
                    required: true,
                    message: "Please enter rate card type",
                  },
                ]}>
                <Input placeholder="Enter rate card type" />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Billing cycle"
                name="billingCycle"
                rules={[
                  {
                    required: true,
                    message: "Please enter billing cycle",
                  },
                ]}>
                <Input placeholder="Enter billing cycle" />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="MSME"
                name="msme"
                rules={[
                  {
                    required: true,
                    message: "Please enter MSME",
                  },
                ]}>
                <Radio.Group
                  onChange={msmeRadioChange}
                  defaultValue={msmeValue}
                  value={msmeValue}>
                  <Radio value={"Yes"}>Yes</Radio>
                  <Radio value={"No"}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {msmeValue === "Yes" ? (
              <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                <Form.Item
                  label="Upload MSME Certificate"
                  name="uploadMsmeCertificate"
                  rules={[
                    {
                      required: true,
                      message: "Please upload MSME certificate",
                    },
                  ]}>
                  <Dragger
                    msg={" Maximum file size 5 MB"}
                    url={
                      "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    }
                    multiple={false}
                    maxCount={1}
                  />
                </Form.Item>
              </Col>
            ) : null}
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="If TAN registered?"
                name="ifTanRegistered"
                rules={[
                  {
                    required: true,
                    message: "Please select",
                  },
                ]}>
                <Radio.Group
                  onChange={tanRadioChange}
                  defaultValue={tanValue}
                  value={tanValue}>
                  <Radio value={"Yes"}>Yes</Radio>
                  <Radio value={"No"}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {tanValue === "Yes" ? (
              <>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item
                    label="TAN Number"
                    name="tanNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter TAN Number",
                      },
                    ]}>
                    <Input placeholder="Enter TAN Number" />
                  </Form.Item>
                </Col>
                <Col md={12} lg={12} xl={12} sm={24} xs={24}>
                  <Form.Item
                    label="Upload TAN Certificate"
                    name="uploadTanCertificate"
                    rules={[
                      {
                        required: true,
                        message: "Please upload TAN certificate",
                      },
                    ]}>
                    <Dragger
                      msg={" Maximum file size 5 MB"}
                      url={
                        "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                      }
                      multiple={false}
                      maxCount={1}
                    />
                  </Form.Item>
                </Col>
              </>
            ) : null}
          </Row>
          <hr
            className="divider"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          />
          <Row gutter={12} justify="end">
            <Col>
              <Button className="cancel-btn">CANCEL</Button>
            </Col>
            <Col>
              <Button className="save-btn" htmlType="submit">
                SAVE
              </Button>
            </Col>
          </Row>
        </Form>
        <Heading title={"Address Details"} />
        <Form
          form={form}
          className="global-form"
          layout={"vertical"}
          onFinish={onAddressDetailsFinish}>
          <Row gutter={12} className="global-form-row">
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Address line 1"
                name="addressline1"
                rules={[
                  {
                    required: true,
                    message: "Please Enter address line 1",
                  },
                ]}>
                <Input placeholder="Enter address line 1" />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Address line 2"
                name="addressline2"
                rules={[
                  {
                    required: false,
                    message: "Please input your username",
                  },
                ]}>
                <Input placeholder="Enter address line 2" />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="PIN code"
                name="pinCode"
                rules={[
                  {
                    required: true,
                    message: "Please input your username",
                  },
                  () => ({
                    validator(rule, value = "") {
                      if (value.length > 0 && value.length < 6) {
                        return Promise.reject("Pincode should be 6 digits");
                      } else {
                        return Promise.resolve();
                      }
                    },
                    // validateTrigger: "onSubmit",
                  }),
                ]}>
                <Input
                  onChange={pinCodeHandler}
                  placeholder="Enter PIN code"
                  maxLength={6}
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item label="City" name="city">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item label="State name" name="stateName">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item label="State code" name="stateCode">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={24} lg={24} xl={24} sm={24} xs={24} className="mt-1">
              {/* <Form.Item
                label=""
                name=""
              >
                <Places
                  setDetailsHandler={(lat, lng) =>
                    setDetailsHandler(lat, lng)
                  }
                />
              </Form.Item> */}
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item label="Latitude" name="latitude">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item label="Longitude" name="longitude">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="mt-1">
              <Heading title={"Delivery Details"} />
            </Col>
            <Col md={24} lg={24} xl={24} sm={24} xs={24}>
              <Form.Item>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  className="cp-details-table"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={12}
            className="mt-2"
            justify={"end"}
            style={{ flex: "auto" }}>
            <BottomButton goBackStep={goBackStep} htmlType="submit" />
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default CpDetails;
