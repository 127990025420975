/* eslint-disable no-unused-vars */
import React,{useState,useEffect} from "react";
import { Row,Col,Select,Form,Button,Table,message, TreeSelect,Spin } from "antd";
import urls from "../../../api/urls";
import API from "../../../api";
import { capitaliseWithHyphen } from "../../../utils";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import "./style.scss";
const { GET_VIEW_ALL_DELIVERY_AREAS,POST_ELIGIBLE_SHIPMENTS,POST_GENERATE_DRS,GET_DELIVERY_BOY,PATCH_DRS_SHIPMENT,GET_DELIVERY_AREAS} = urls;
const user_premiseId = JSON.parse(window?.localStorage?.getItem("userId"));

const DrsGeneration = ({setStep}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [areaResponse,setAreaResponse]=useState([]);
  const [deliveryBoyResponse,setDeliveryBoyResponse]=useState([]);
  const [eligibleShipments,setEligibleShipments] = useState([]);
  const [delhiveryBoyId,setDelhiveryBoyId]= useState(0);
  const [delhiveryBoyName,setDelhiveryBoyName]= useState(0);
  // const [delhiveryBoyNumber,setDelhiveryBoyNumber]= useState(0);
  const [loading,setLoading] = useState(false);
  const [gnrtDrs,setGnrtDrs] = useState(false);
  const [virtualAreaId,setVirtualAreaId] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const columns = [
    {
      title: "AWB Number",
      dataIndex: "",
      key:"awbNumber",
      render:(text)=>
        <a>{text?.childAwbNumber ? text?.childAwbNumber : text?.awbNumber}</a>
    },
    {
      title: "Shipment",
      dataIndex: "",
      key:"",
      render:(text)=>{
        return(
          <>
            <div>Shipment type: {capitaliseWithHyphen(text?.shipmentDetails?.type) || null}</div>
            <div>Booking type: {capitaliseWithHyphen(text?.serviceDetails?.service) || null}</div>
          </>
        );}
    },
    {
      title: "Booking CP Details",
      dataIndex: "",
      key:"",
      render:(text)=>{
        return(
          <>
            <div>{capitaliseWithHyphen(text?.cpName)}</div>
            <div>{text?.cpNum}</div>
          </>
        );
      }
    },
    {
      title: "Receiver's details",
      dataIndex: "addressDetails",
      key: "addressDetails",
      render:(text)=>{
        return(
          <>
            <div> 
              <strong> {capitaliseWithHyphen(text?.receiverName)} </strong>
            </div>
            <div>{capitaliseWithHyphen(text?.receiverAddressLine1)}</div>
            <div>{capitaliseWithHyphen(text?.receiverAddressLine2)}</div>
          </>
        );
      }
    },
  ];
  useEffect(()=>{
    areaFetching();
    getDeliveyBoy();
  },[]);
  let areaFetching = async()=>{ 
    let response = await API.Api(
      GET_VIEW_ALL_DELIVERY_AREAS
    ).getWithPremiseIdHeader();
    if(response?.status){
      let virtualArea = response?.response?.data;
      setAreaResponse(virtualArea);
      let response1 = await API.Api(
        GET_DELIVERY_AREAS + user_premiseId?.userInfo?.premiseId
      ).getWithPremiseIdHeader();
      let secondArea = response1?.response?.data;
      let finalArr = [];
      virtualArea.map((e)=>{
        finalArr.push({
          value: e.virtualAreaId,
          title: capitaliseWithHyphen(e.virtualAreaName),
          children: e.areas.map((item)=>{return({value: item.areaId, title: capitaliseWithHyphen(item.areaName)});})
        });
      });
      secondArea.map((e)=>{
        finalArr.push({
          value: e.areas.areaId,
          title: capitaliseWithHyphen(e.areas.areaName),
          children: []
        });
      });
      setTreeData([...finalArr]);
    }
  };

  let getDeliveyBoy = async()=>{ 
    let response = await API.Api(
      GET_DELIVERY_BOY
    ).getWithPremiseIdHeader();
    if(response?.status)setDeliveryBoyResponse(response?.response?.data);
  };
  const onSelectChange = (newSelectedRowKeys,e) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };
  const getShipmentHandle = async(e)=>{
    setVirtualAreaId(e?.selectArea);
    setDelhiveryBoyId(e?.selectDeliverBoy);
    setLoading(true);
    let response = await API.Api(
      POST_ELIGIBLE_SHIPMENTS
    ).postWithHeader({areaId:e?.selectArea});
    if(response.status ){
      setEligibleShipments(response?.response?.data?.shipmentResponseDtos);
      setLoading(false);
      if(response?.response?.data?.shipmentResponseDtos?.length === 0){
        message.error("No shipments present");
        form.resetFields();
      }else{
        setGnrtDrs(true);
      }
    }
  };
  const generateDrsHandle = async()=>{
    setLoading(true);
    let payload = areaResponse?.virtualAreaId ? {
      virtualAreaId : areaResponse?.virtualAreaId,
      deliveryBoyId : delhiveryBoyId,
      deliveryBoyName:delhiveryBoyName
    } : {
      areaId :virtualAreaId,
      deliveryBoyId : delhiveryBoyId,
      deliveryBoyName:delhiveryBoyName
    };
    let response = await API.Api(
      POST_GENERATE_DRS
    ).postIdHeader(payload);
    if(response.status){
      patchDrsShipment(response?.response?.data?.drsId);
      setEligibleShipments([]);
      setLoading(false);
    }
  };
  const patchDrsShipment = async (id) => {
    setLoading(true);
    let payload = {
      "shipments" : selectedRowKeys?.map((data)=>data?.childAwbNumber || data?.awbNumber)
    };
    let response = await API.Api(
      `${PATCH_DRS_SHIPMENT}${id}`
    ).patchIdHeader(payload);
    if (response.status) {
      setLoading(false);
      message.success("DRS is successfully generated");
      form.resetFields();
      setStep("2");
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };



  let allAreas =[];
  const treeSelectOnChange = ()=>{
    setEligibleShipments([]);
  };
  return (
    <Spin spinning={loading}
      size="large"
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }>
      <div className="drs-generation-container">
        <Form
          form={form}
          name="accountBasicDetails"
          layout="vertical"
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          onFinish={getShipmentHandle}
          className="p-2"
        >
          <Row gutter={16} className="m-1">
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="selectArea"
                label="Select area"
                rules={[
                  {
                    required: true,
                    message: "Please select an option",
                  },
                ]}
              >
                <TreeSelect
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  //value={value}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  placeholder="Please select"
                  allowClear
                  multiple
                  treeDefaultExpandAll
                  onChange={treeSelectOnChange}
                  treeData={treeData}
                  treeCheckable
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name="selectDeliverBoy"
                label="Select delivery boy"
                rules={[
                  {
                    required: true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Select onChange={(e,a)=>{
                  setDelhiveryBoyName(a?.children);
                }} placeholder="Select delivery boy">
                  {deliveryBoyResponse?.map((data,index)=>
                    <Option value={data?.deliveryBoyId} key={data?.phoneNumber}>{`${data?.firstName} ${data?.lastName}- ${data?.phoneNumber}`}</Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <hr className="divider" />
          <Row justify="end" className="m-1">
            <Col>
              <Button htmlType="submit" className="save-btn">INITIATE DRS</Button>
            </Col>
          </Row>
        </Form>
        <Table 
          className="table-head-sticky"
          rowKey={(record) => record}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRowKeys(selectedRowKeys);
            },
          }}
          columns={columns} 
          dataSource={eligibleShipments} 
          pagination={{
            itemRender: itemRender,
          }}
        />
      
        {gnrtDrs && eligibleShipments?.length > 0  ? <Row justify="end">
          <Col className="m-1">
            <Button className="save-btn" disabled={!selectedRowKeys?.length > 0} onClick={generateDrsHandle}>GENERATE DRS</Button>
          </Col>
        </Row> : null}
      
      </div>
    </Spin>
  );
};

export default DrsGeneration;
