/* eslint-disable indent */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Table,
  Col,
  Row,
  Select,
  Form,
  Spin,
  Input,
  Button,
  message,
} from "antd";
import axios from "axios";
import { APIs } from "../../../../utils/worker";
import { capitalize, capitaliseWithHyphen } from "../../../../utils";
import { LoadScript } from "@react-google-maps/api";
import { getPremiseId } from "../../../../utils/worker";
import constants from "../../../../constants/constants";
import { ReactComponent as Delete_icon } from "../../../../assests/images/master/red-delete-svg.svg";

const { ROLES } = constants;
const { HO_ADMIN } = ROLES;

import "./style.scss";

const EditRoute = ({
  handleEditRoute,
  handleCloseModal,
  primaryMode,
  editRouteData,
}) => {
  // console.log(editRouteData.routes, "editRouteData");

  const formLayout = "vertical";
  const [form] = Form.useForm();
  const { Option } = Select;

  const transitTimeOptions = [
    { label: 24, value: 24 },
    { label: 48, value: 48 },
    { label: 72, value: 72 },
    { label: 96, value: 96 },
  ];
  const transitMode = [
    { label: "Surface", value: "Surface" },
    { label: "Air", value: "Air" },
  ];
  const options = [];
  const [transitForm, setTransitForm] = useState([]);
  const [hubDetails, setHubDetails] = useState([]);
  const [transitHubDetails, setTransitHubDetails] = useState([]);
  const [totalTAT, setTotalTAT] = useState(0);
  const [hubDetailsParent, setHubDetailsParent] = useState([]);
  const [originHub, setOriginHub] = useState("");
  const [originLatLng, setOriginLatLng] = useState({
    latitude: "",
    longitude: "",
  });
  const [destLatLng, setDestLatLng] = useState({ latitude: "", longitude: "" });
  const [destinationHub, setDestinationHub] = useState("");
  const [destinationTime, setDestinationTime] = useState("");
  const [tokenData, setToken] = useState("");
  const [transitValue, settransitValue] = useState(0);
  const [test, setTest] = useState(false);
  const [roles, setRoles] = useState();

  useEffect(() => {
    setRoles(JSON.parse(localStorage.getItem("userinfo")));
  }, []);

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userinfo"));
    getHubDetails(userData.accessToken);

    setToken(userData.accessToken);
  }, []);

  // useEffect(() => {
  //   changeTransitHubs();
  // }, []);

  useEffect(() => {
    changeTransitHubs();
  }, [transitValue, destinationHub]);

  const changeTransitHubs = () => {
    setTransitHubDetails(
      hubDetails.filter(
        (e) => e.value !== originHub && e.value !== destinationHub
      )
    );
  };
  const getHubDetails = async (token) => {
    let response = await axios.get(
      APIs.baseURL + "/premise-service/v1/premises",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let temp = [];
    response.data.map((item) => {
      temp.push({
        label: capitaliseWithHyphen(item?.premiseName),
        value: item?.premiseId,
        latitude: item?.latitude,
        longitude: item?.longitude,
      });
    });
    setHubDetails(temp);
    setTransitHubDetails(
      temp.filter((e) => e.value !== originHub && e.value !== destinationHub)
    );
    setHubDetailsParent(temp);
    roles && roles?.roles?.includes(HO_ADMIN)
      ? ""
      : // : setOriginHubDetails(temp, getPremiseId().split(" ")[1]);
        setOriginHubDetails(temp, editRouteData.premiseId);
  };

  const setOriginHubDetails = (a, b) => {
    // let foundData = a.find(
    //   (item) => item.label.toLowerCase() == b.toLowerCase()
    // );
    let foundData = a.find((item) => item.value == b);

    if (foundData) {
      form.setFieldValue("destinationHub", editRouteData?.destinationHubId);

      form.setFieldValue("originHub", foundData?.value);
      setOriginHub(foundData?.value);
      setOriginLatLng({
        latitude: foundData?.latitude,
        longitude: foundData?.longitude,
      });
      form.setFieldValue("noOfTransit", editRouteData?.routes?.length - 2);

      if (editRouteData?.routes?.length > 2) {
        let temp = [...editRouteData.routes];
        let temp2 = [...editRouteData.routes];

        let transitForm_ = [];
        temp.pop();
        temp.shift();

        for (let i = 0; i < temp.length; i++) {
          transitForm_.push({
            hub: temp[i]?.premise,
            time: "",
            mode: temp2[i]?.travelBy,
            distance: "",
          });
        }

        form.setFieldValue(
          "destinationTransitMode",
          capitalize(temp2[temp2?.length - 2]?.travelBy)
        );
        // transitForm_.shift();
        // transitForm_.pop();
        setTransitForm([...transitForm_]);
      } else {
        form.setFieldValue(
          "destinationTransitMode",
          capitalize(editRouteData?.routes[0]?.travelBy)
        );
      }
      // handleSelectChange(editRouteData.destinationHubId, "destinationHub");
    }
  };

  // console.log(transitForm, "tttt");

  useEffect(() => {
    if (transitForm?.length > 0) {
      let temp = [...transitForm];
      for (let i = 0; i < transitForm?.length; i++) {
        form.setFieldValue("transitHub" + i, capitalize(temp[i]?.hub));
        form.setFieldValue("transitMode" + i, capitalize(temp[i]?.mode));
      }
    }
  }, [transitForm]);

  const formProps = {
    name: "address",
    className: "register-form",
    onFinish: (values) => handleEditRoute(values, transitForm, destinationTime),
    autoComplete: "off",
  };

  const handleSelectChange = (value, name) => {
    let findLatLng = hubDetailsParent?.find((it) => it?.value === value);
    if (name === "destinationHub") {
      setDestinationHub(value);
      setDestLatLng({
        latitude: findLatLng.latitude,
        longitude: findLatLng.longitude,
      });
      const google = window.google;
      const directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin:
            transitForm?.length > 0
              ? new google.maps.LatLng(
                  transitForm[transitForm.length - 1]?.latitude,
                  transitForm[transitForm.length - 1]?.longitude
                )
              : new google.maps.LatLng(
                  originLatLng?.latitude,
                  originLatLng?.longitude
                ),
          destination: new google.maps.LatLng(
            findLatLng?.latitude,
            findLatLng?.longitude
          ),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            let distance = result.routes[0].legs[0].distance.text;
            let time = +distance.split(" ")[0].split(",").join("") / 40;
            let finalTime = (time / 4 + time).toFixed(1);
            let finalMinute = finalTime * 60;
            let actualTime = timeConvert(finalMinute);
            form.setFieldValue("destinationTransitDistance", distance);
            form.setFieldValue("destinationTransitTime", actualTime);
            setDestinationTime(finalTime);

            for (let i = 0; i < transitForm?.length; i++) {
              form.setFieldValue("transitHub" + i, null);
              form.setFieldValue("transitTime" + i, null);
              form.setFieldValue("totalTAT", null);
            }
          } else {
            console.error("error fetching directions", result, status);
          }
        }
      );
    }
  };

  const changeTransitValue = (e) => {
    for (let i = 0; i < transitForm?.length; i++) {
      form.setFieldValue("transitHub" + i, null);
      form.setFieldValue("transitTime" + i, null);
      form.setFieldValue("transitDistance" + i, null);
      form.setFieldValue("destinationTransitTime", null);
      form.setFieldValue("destinationTransitDistance", null);
      form.setFieldValue("totalTAT", null);
    }
    let value = e.target.value;
    if (value > 7) {
      settransitValue(7);
    } else {
      settransitValue(value);
      let transitForm_ = [];
      for (let i = 0; i < value; i++) {
        transitForm_.push({
          hub: "",
          time: "",
          mode: primaryMode === "SURFACE" ? "Surface" : "Air",
          distance: "",
          premiseId: "",
        });
      }
      setTransitForm([...transitForm_]);
      setTest(!test);
    }
    if (value === "" || value == "0") {
      handleSelectChange(destinationHub, "destinationHub", "x");
    }
  };

  // console.log(getPremiseId().split(" ")[1]);

  function timeConvert(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) " + rminutes + " minute(s)";
  }

  const changeTransit = async (data, index, name, nameVal) => {
    let temp = [...transitForm];

    if (nameVal.includes("transitMode")) {
      temp[index].mode = form.getFieldValue("transitMode" + index);
      return;
    }

    setTransitForm(temp);

    temp[index].premiseId = data;
    temp[index].hub = form.getFieldValue("transitHub" + index);
  };
  const onSearch = (e) => {
    // console.log(e);
  };

  const handleTransitDelete = (index) => {
    // console.log(index, "delt index");
    let temp = [...transitForm];
    temp = temp.filter((val, idx) => idx !== index);
    setTransitForm(temp);
    let value = form.getFieldValue("noOfTransit");
    form.setFieldValue("noOfTransit", value - 1);
    if (temp?.length) {
      form.setFieldValue(
        "destinationTransitMode",
        capitalize(temp[temp?.length - 1]?.mode)
      );
    }
  };

  const transitData = () => {
    let design = [];
    transitForm.map((item, index) => {
      let random = Math.random();
      design.push(
        <Row className="global-form-row" gutter={20} key={index}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item
              name={"transitHub" + index}
              label={"Transit " + (index + 1)}
              // initialValue={item.hub ? item.hub : "Select transit hub"}
              rules={[
                {
                  required: true,
                  message: "Please select transit hub",
                },
              ]}
            >
              <Select
                showSearch
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select transit hub"
                options={transitHubDetails}
                onChange={(data) =>
                  changeTransit(data, index, "hub", "transitHub" + index)
                }
                value={item.hub}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4} lg={6} xl={6}>
            <Form.Item
              name={`transitMode${index}`}
              label={
                index === 0
                  ? "Origin to T" + (index + 1) + " Mode"
                  : "T" + index + " to " + "T" + (index + 1) + " Mode"
              }
              // initialValue={primaryMode}
              initialValue={primaryMode === "SURFACE" ? "SURFACE" : null}
              rules={[
                {
                  required: true,
                  message: "Please select mode",
                },
              ]}
            >
              <Select
                placeholder="Select mode"
                disabled={primaryMode === "AIR" ? false : true}
                options={transitMode}
                onChange={(data) =>
                  changeTransit(data, index, "mode", `transitMode${index}`)
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item
              name={`transitDistance${index}`}
              label={
                index === 0
                  ? "Origin to T" + (index + 1) + " Distance"
                  : "T" + index + " to " + "T" + (index + 1) + " Distance"
              }
              initialValue={item.distance}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Row>
              <Form.Item
                name={`transitTime${index}`}
                label={
                  index === 0
                    ? "Origin to T" + (index + 1) + " TAT"
                    : "T" + index + " to " + "T" + (index + 1) + " TAT"
                }
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select time",
                //   },
                // ]}
              >
                <Input disabled />
              </Form.Item>
              <Delete_icon
                style={{
                  cursor: "pointer",
                  width: "40px",
                  height: "30px",
                  alignSelf: "center",
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
                onClick={() => handleTransitDelete(index)}
              />
            </Row>
          </Col>
        </Row>
      );
    });
    return design;
  };

  function handleAddTransists() {
    // console.log(params);
    // settransitValue((prev) => prev + 1);
    let transitForm_ = [...transitForm];
    transitForm_.push({
      hub: "",
      time: "",
      mode: primaryMode === "SURFACE" ? "SURFACE" : "AIR",
      distance: "",
    });

    setTransitForm([...transitForm_]);
  }

  return (
    <div>
      <Spin spinning={false}>
        <Form
          {...formProps}
          form={form}
          layout={formLayout}
          className="route-table global-form"
        >
          <Row className={"global-form-row route-main"} gutter={20}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name="originHub"
                label="Origin Hub"
                rules={[
                  {
                    required: true,
                    message: "Please select origin hub",
                  },
                ]}
              >
                <Select
                  showSearch
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select origin hub"
                  options={hubDetails}
                  onChange={(value) => handleSelectChange(value, "originHub")}
                  disabled
                  // disabled={
                  //   roles && roles?.roles?.includes(HO_ADMIN) ? false : true
                  // }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name="destinationHub"
                label="Destination Hub"
                rules={[
                  {
                    required: true,
                    message: "Please select destination hub",
                  },
                ]}
              >
                <Select
                  showSearch
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select destination hub"
                  options={hubDetails}
                  onChange={(value) =>
                    handleSelectChange(value, "destinationHub")
                  }
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={8}></Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name="primaryMode"
                label="Primary mode of travel"
                rules={[
                  {
                    required: true,
                    message: "Please select mode",
                  },
                ]}
                initialValue={capitalize(primaryMode)}
              >
                <Select
                  className="disable-input"
                  disabled
                  placeholder="Select mode"
                  options={transitMode}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={3} lg={3} xl={3}>
              <Form.Item name="noOfTransit" label="Add Transits">
                <Input
                  placeholder="0"
                  onChange={changeTransitValue}
                  // value={transitValue}
                  onKeyPress={(e) => {
                    if (/[^0-7]/.test(e.key) || e.target.value.length === 1) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            {/* <Col span={13}>
              <Button onClick={handleAddTransists}>Add transists</Button>
            </Col> */}
          </Row>
          {transitData()}
          <Row gutter={20} className="global-form-row">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item name="destinationHub" label="Destination Hub">
                <Select
                  placeholder="Select destination hub"
                  options={hubDetails}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name={`destinationTransitMode`}
                label={
                  transitForm.length > 0
                    ? "T" + transitForm.length + " to Destination Mode"
                    : "Origin to Destination Mode"
                }
                rules={[
                  {
                    required: true,
                    message: "Please select mode",
                  },
                ]}
              >
                <Select
                  placeholder="Select mode"
                  // onChange={handleDestinationChange}
                  disabled={primaryMode === "SURFACE" && true}
                  // disabled
                  options={transitMode}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name={`destinationTransitDistance`}
                label={
                  transitForm.length > 0
                    ? "T" + transitForm.length + " to Destination Distance"
                    : "Origin to Destination Distance"
                }
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name={`destinationTransitTime`}
                label={
                  transitForm.length > 0
                    ? "T" + transitForm.length + " to Destination TAT (in Hour)"
                    : "Origin to Destination TAT (in hour)"
                }
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item name="totalTAT" label="Total TAT" initialValue={0}>
                <Input disabled />
              </Form.Item>
            </Col> */}
          </Row>
          <hr className="divider mt-3" />
          <div className="d-flex flex-end" style={{ padding: "1rem 0 0 0" }}>
            <Button className="cancel-btn" onClick={handleCloseModal}>
              CANCEL
            </Button>
            <Button htmlType="submit" className="save-btn ml-1">
              SAVE
            </Button>
          </div>
          {/* <Row className="add-pin-footer">
            <Button className="btn-style cancel-btn w-10">Cancel</Button>

            <Button className="btn-style save-btn ml-1 w-10" htmlType="submit">
              Save
            </Button>
          </Row> */}
        </Form>
      </Spin>
    </div>
  );
};

export default EditRoute;
