import React, { useState, useEffect } from "react";
import { Table, DatePicker } from "antd";
import { serialNum } from "../../../utils";
import urls from "../../../api/urls";
import API from "../../../api";
import { capitaliseWithHyphen } from "../../../utils";
import "./style.scss";

const Pendingdrs = () => {
  const { PENDING_DRS,PENDING_DRS_SEARCH } = urls;
  const { RangePicker } = DatePicker;
  const [drsListResponse, setDrsListResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const columns = [
    {
      title: "Sl No",
      align: "center",
      with: 10,
      render: (a, b, idx) => serialNum(10, currentPage, idx),
    },
    {
      title: "AWB Number",
      dataIndex: "",
      key: "",
      render: (text) => {
        return (
          <span>{text?.childAwbNumber ? text?.childAwbNumber : text?.awbNumber}</span>
        );
      },
    },
    {
      title: "In-Scan Date",
      dataIndex: "bookingStartTime",
      key: "bookingStartTime",
      render: (text) => {
        return (
          <span>{text?.split(" ")[0]?.split("-")?.reverse()?.join("-")}</span>
        );
      },
    },
    {
      title: "Booking CP Details",
      dataIndex: "",
      key: "",
      render: (text) => {
        return (
          <>
            <div>{capitaliseWithHyphen(text?.cpName)}</div>
            <div>{text?.cpNum}</div>
          </>
        );
      },
    },
    {
      title: "Receiver's Name",
      dataIndex: "addressDetails",
      key: "addressDetails",
      render: (text) => {
        return (
          <>
            <div>
              {" "}
              <b>{capitaliseWithHyphen(text?.receiverName)}</b>{" "}
            </div>
            <div>{capitaliseWithHyphen(text?.receiverAddressLine1)}</div>
            <div>{capitaliseWithHyphen(text?.receiverAddressLine2)}</div>
            <div>{text?.receiverMobileNumber}</div>
          </>
        );
      },
    },
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };
  const drsListFun = async () => {
    setLoading(true);
    let response = await API.Api(PENDING_DRS).getWithPremiseIdHeader();
    if (response?.status) {
      setDrsListResponse(response?.response?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const rangeHandle = async (a,date)=>{
    if(a !== null){
      let dateRangeResponse = await API.Api(`${PENDING_DRS_SEARCH}?fromDate=${date[0]}&toDate=${date[1]}`).getWithPremiseIdHeader();
      if (dateRangeResponse?.status) {
        setDrsListResponse(dateRangeResponse?.response?.data);
        setLoading(false);
        setCurrentPage(0);
      } else {
        setLoading(false);
      }}else{
      drsListFun();
    }
  };
  const paginationHandle = (pagination) => {
    setCurrentPage(pagination.current);
  };
  useEffect(() => {
    drsListFun();
  }, []);
  return (
    <div className="pending-drs-container mt-1">
      <div
        className="p-1"
        style={{ display: "flex", justifyContent: "end", alignItems: "center" }}
      >
        <RangePicker onChange={rangeHandle}/>
      </div>
      <Table
        className="table-head-sticky"
        loading={loading}
        columns={columns}
        dataSource={drsListResponse}
        pagination={{
          itemRender: itemRender,
        }}
        onChange={paginationHandle}
      />
    </div>
  );
};

export default Pendingdrs;
