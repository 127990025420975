/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import {
  Drawer,
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  Card,
  Radio,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  checkNumbervalue,
  firstLetterCapitaliseDropDown,
} from "../../../../utils";
import useClientAndDepartment from "../useClientAndDepartment ";

import "./style.scss";
import AutoCompleteAddress from "../AutoCompleteAddress";

function ReturnAddressDrawer({
  open,
  setOpen,
  cliendID,
  alertData,
  changeSenderAddress,
  senderAddress,
  setReturnAddress,
  savedReturnAddressList,
  updateReturnAddressList,
  radioActive,
  upDateRadioActive,
}) {
  const [size, setSize] = useState("large");
  const [returnAddressform] = Form.useForm();

  useEffect(() => {
    if (alertData?.visible) {
      returnAddressform.resetFields([]);
    }
  }, [alertData]);

  const { userInfo } = JSON.parse(window?.localStorage?.getItem("userId"));
  const {
    pinCodeServiceableStatus,
    getDeliveryPincode,
    areaList,
    resetCredit,
  } = useClientAndDepartment(userInfo, returnAddressform, "returnAddressform");

  const onClose = () => {
    setOpen(false);
  };
  const onSubmitFormData = (value) => {
    const newVal = {
      ...value,
      isAddressEnabled: 0,
    };

    const newAddress = [...savedReturnAddressList, newVal];
    updateReturnAddressList(newAddress);
    returnAddressform.resetFields();
    resetCredit();
  };

  const [dropDownChanges, setdropDownChanges] = useState(false);

  useEffect(() => {
    document.querySelectorAll(".ant-select-item-option").forEach((option) => {
      option.classList.remove("ant-select-item-option-active");
    });
  }, [dropDownChanges]);

  const handleKeyDown = (event, dataOptions, formItemName, value = "value") => {
    switch (event.key) {
      case "Tab":
        setdropDownChanges(!dropDownChanges);
        // eslint-disable-next-line no-case-declarations
        let activeOption = document.querySelector(
          ".ant-select-item-option-active"
        );

        if (activeOption) {
          const value = activeOption.getAttribute("title");

          const optionExists = dataOptions.some(
            (option) => option?.value?.toLowerCase() == value?.toLowerCase()
          );
          if (optionExists) {
            returnAddressform.setFieldsValue({ [formItemName]: value });
          }
        }

        break;

      default:
        break;
    }
  };

  return (
    <div>
      <Drawer
        className="credit-custom-styled-drawer"
        title={"Add Return Address"}
        placement="right"
        size={size}
        onClose={onClose}
        open={open}
      >
        <div style={{ padding: "5px 20px" }}>
          <Form
            //   {...formProps}
            form={returnAddressform}
            onFinish={onSubmitFormData}
            layout="vertical"
            className="global-form"
            scrollToFirstError={{
              behavior: "smooth",
              block: "center",
              inline: "center",
            }}
          >
            <Row className="global-form-row" gutter={20}>
              <Col lg={12}>
                <Form.Item
                  name="returnPincode"
                  label="To Pincode"
                  validateStatus={pinCodeServiceableStatus?.status}
                  help={pinCodeServiceableStatus?.msg}
                  rules={[
                    {
                      required: true,
                      message: "Enter to pincode",
                    },
                  ]}
                  onKeyPress={(event) => {
                    if (checkNumbervalue(event)) {
                      event.preventDefault();
                    }
                  }}
                >
                  <Input
                    onChange={getDeliveryPincode}
                    className="accpickupPincode"
                    placeholder="Enter delivery PIN code"
                    maxLength={6}
                    suffix={
                      <span
                        className={
                          pinCodeServiceableStatus?.serviceability === "Regular"
                            ? "green"
                            : pinCodeServiceableStatus?.serviceability ===
                              "Premium"
                            ? "orange"
                            : "red"
                        }
                      >
                        {pinCodeServiceableStatus?.stateCode}
                      </span>
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  lg={6}
                  sm={6}
                  label="Area"
                  type="select"
                  name="returnAreaName"
                  placeholder="Search area"
                  rules={[
                    {
                      required: true,
                      message: "Select area",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select area"
                    options={areaList}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onKeyDown={(event) =>
                      handleKeyDown(event, areaList, "returnAreaName", "value")
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={12} sm={12}>
                <Form.Item
                  label="Name"
                  name="returnName"
                  rules={[
                    {
                      required: true,
                      message: "Enter name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter name"
                    onChange={(e) =>
                      returnAddressform.setFieldValue(
                        ["returnName"],
                        firstLetterCapitaliseDropDown(e.target.value)
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={12} sm={12}>
                <Form.Item
                  label="Address"
                  name="returnAddressLine1"
                  rules={[
                    {
                      required: true,
                      message: null,
                    },
                  ]}
                >
                  <AutoCompleteAddress
                    form={returnAddressform}
                    addressDetailsCallback={null}
                    fieldName="returnAddressLine1"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Mobile number"
                  name="returnMobileNumber"
                  rules={[
                    () => ({
                      validator(rule, value = "") {
                        if (value.length > 0 && value.length < 10) {
                          return Promise.reject(
                            "Please enter 10 digits phone number"
                          );
                        } else if (parseInt(value.substring(1, 0)) === 0) {
                          return Promise.reject(
                            "Mobile number should not be 0"
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder="Enter phone number"
                    onKeyPress={(e) => {
                      if (e.code == "Enter") {
                        document.getElementById("submit_drawer").blur();
                      } else if (/[^0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    maxLength={10}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email address"
                  name="returnEmail"
                  rules={[
                    {
                      type: "email",
                      message: "Give the correct email",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter email address"
                    onBlur={(e) =>
                      form.setFieldValue(
                        "returnEmail",
                        e.target.value.toLocaleLowerCase()
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Button
            className="save-btn"
            onClick={returnAddressform.submit}
            id="submit_drawer"
            // htmlType="submit"
          >
            Add +{" "}
          </Button>
          <div className="pt-1  ">
            {/* {savedReturnAddressList?.length > 0 && ( */}
            <div>
              <div
                className="pointer"
                onClick={() => upDateRadioActive("default")}
              >
                <Card
                  key={"add"}
                  size="small"
                  title={
                    <span>
                      <Radio
                        onChange={() => upDateRadioActive("default")}
                        checked={radioActive === "default"}
                      ></Radio>
                      {senderAddress?.senderName}
                    </span>
                  }
                  style={{
                    width: "auto",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    marginBottom: "1rem",
                    padding: "0",
                    borderRadius: "5px",
                    border:
                      radioActive === "default"
                        ? "2px dashed #D7D9E8"
                        : "2px dashed #DCDDE0",
                    background:
                      radioActive === "default" ? "#F4F8FF" : "#FFFFFF",
                  }}
                >
                  <p style={{ marginTop: "-0.5rem" }}>
                    {senderAddress?.senderAddressLine1}
                  </p>
                  <p style={{ marginTop: "-1rem" }}>
                    {senderAddress?.areaName}, -{senderAddress?.senderPincode}
                  </p>
                  <p style={{ marginTop: "-1rem" }}>
                    {senderAddress?.senderMobileNumber}
                  </p>
                  <p style={{ marginTop: "-1rem" }}>
                    {senderAddress?.senderEmail}
                  </p>
                </Card>
              </div>
              {savedReturnAddressList?.map((e, i) => (
                <div
                  key={i}
                  className="pointer"
                  onClick={() => upDateRadioActive(i)}
                >
                  <Card
                    key={`add${i}`}
                    size="small"
                    title={
                      <span>
                        <Radio
                          onChange={() => upDateRadioActive(i)}
                          checked={i === radioActive}
                        ></Radio>
                        {e?.returnName}
                      </span>
                    }
                    style={{
                      width: "auto",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      marginBottom: "1rem",
                      padding: "0",
                      borderRadius: "5px",
                      border:
                        i === radioActive
                          ? "2px dashed #D7D9E8"
                          : "2px dashed #DCDDE0",
                      background: i === radioActive ? "#F4F8FF" : "#FFFFFF",
                    }}
                  >
                    <p style={{ marginTop: "-0.5rem" }}>
                      {e?.returnAddressLine1}
                    </p>
                    <p style={{ marginTop: "-1rem" }}>
                      {e.returnAreaName}, -{e?.returnPincode}
                    </p>
                    <p style={{ marginTop: "-1rem" }}>
                      {e?.returnMobileNumber}
                    </p>
                    <p style={{ marginTop: "-1rem" }}>{e?.returnEmail}</p>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default ReturnAddressDrawer;
