/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Tabs } from "antd";
// import CpWiseManifest from "./CpWiseManifest";
// import ConsolidatedManifest from "./ConsolidatedList";
import "./style.scss";
import Heading from "../../../components/Heading";
import sorting from "../../../assests/images/SVG/sort-white.svg";
import ShipmentReport from "./shipmentActionReport";
import ShipmentShortage from "./shipmentShortage";
import BagShortageOverage from "./BagShortageOverage";
import BagShortageActionReport from "./BagShortageActionReport";

const ExpectionReport = () => {
  const [activeKey, setActiveKey] = useState("1");

  const onChange = (key) => {
    setActiveKey(key);

    //   if (key == 1) {
    //     getTableData(GET_MIS_ROUTED_SHIPMENT);
    //   } else if (key == 2) {
    //     getDamageShipments();
    //   } else {
    //     getTableData(GET_EXCESS_IN_SCAN);
    //   }
  };

  const items = [
    {
      key: "1",
      label: "Shipment shortage overage",
      children: <ShipmentShortage activeKey={activeKey} />,
    },
    {
      key: "2",
      label: "Shipment take action report",
      children: <ShipmentReport activeKey={activeKey} />,
    },
    {
      key: "3",
      label: "Bag shortage overage",
      children: <BagShortageOverage activeKey={activeKey} />,
    },
    {
      key: "4",
      label: "Bag take action report",
      children: <BagShortageActionReport activeKey={activeKey} />,
    },
  ];

  // console.log(activeKey, "ACt");
  return (
    <div className="p2 consolidated-tab-cntr">
      <Heading
        title={"Exception report"}
        // nextPage={"/sort-shipment"}
        // nextPageTitle={"Sort Shipment"}
        // imgSrc={sorting}
      />
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        activeKey={activeKey}
      />
    </div>
  );
};
export default ExpectionReport;
