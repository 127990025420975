import React, { useState } from "react";
import { Tabs } from "antd";
import Heading from "../../components/Heading";
import CreateBroadcast from "./CreateBroadcast";
import ViewBroadcast from "./ViewBroadcast";
import "./index.scss";
const Broadcast = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [check, setCheck] = useState(false);

  const changeTab = (key) => {
    setActiveKey(key);
    setCheck(!check);
  };

  return (
    <div className="p-2">
      <Heading title="Message & Alert" />
      <div className="rate-card-tab-container">
        <Tabs
          activeKey={activeKey}
          onChange={changeTab}
          items={[
            {
              label: "Broadcast Message",
              key: "1",
              children: <CreateBroadcast />,
            },
            {
              label: "View Broadcast Message",
              key: "2",
              children: <ViewBroadcast check={check} />,
            },
          ]}
        />
      </div>
    </div>
  );
};
export default Broadcast;
