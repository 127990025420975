/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Space,
  Input,
  Tooltip,
  Popconfirm,
  message,
  Table,
  Button,
  Row,
} from "antd";

import { capitalize, serialNum } from "../../utils";

import urls from "../../api/urls";
import API from "../../api";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { ReactComponent as Edit_icon } from "../../assests/images/edit-logo.svg";
import { ReactComponent as Disable_icon } from "../../assests/images/SVG/disable.svg";
import { ReactComponent as Disable_Edit_icon } from "../../assests/images/SVG/disableEdit.svg";

import search_icon from "../../assests/images/search-icon.svg";
import check_mark from "../../assests/images/SVG/check.svg";
import Smcs_Loader from "../../assests/images/loader-new.gif";
// import Heading from "../../";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import Heading from "../../components/Heading";

const { UPDATE_STAFF, LEAD_CLIENT_LIST } = urls;

// import "./style.scss";

const ClientLeadList = ({ handleEdit = () => {} }) => {
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [tableData, setTableData] = useState([]);
  const [dataSource, setdataSource] = useState(tableData);
  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const navigate = useNavigate();
  useEffect(() => {
    getStaffTable("?pageNum=0&pageSize=10");
  }, []);

  const getStaffTable = async (page) => {
    setLoading(true);
    let response = await API.Api(
      LEAD_CLIENT_LIST + page
    ).getWithUserNPremiseHeader();
    if (response?.status) {
      const res = response?.response?.data;
      setTotalPageSize(res?.count);
      setLoading(false);

      setTableData(res?.leadDetails);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
    // setTableData(mockData);
  };

  const updateStaffOnboard = async (values) => {
    setLoading(true);
    let response = await API.Api(UPDATE_STAFF).putWithHeader(values);
    if (response?.status) {
      setLoading(false);
      message.success(response?.response?.data?.message);
      getStaffTable();
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  const handleDisable = (id) => {
    let payload = {
      staffId: id,
      status: false,
    };
    updateStaffOnboard(payload);
  };

  function handleLeadIdClick(text, record) {
    // if (record?.quoteStatus) {
    //   navigate("/quotation-list/" + text);
    //   // navigate("/client-lead-generation/" + text);
    // } else {
    // }
    localStorage.setItem("clientLeadId", text);
    navigate("/client-lead-generation/" + text);
  }

  const columns = [
    {
      title: "Lead ID",
      dataIndex: "clientLeadId",
      key: "clientLeadId",
      align: "center",
      width: 50,
      render: (text, record) => {
        return (
          <>
            <a onClick={() => handleLeadIdClick(text, record)}>{text}</a>
            {/* <a onClick={() => navigate("/quotation-list/" + text)}>{text}</a> */}
          </>
        );
      },
    },
    {
      title: "Owner Name",
      dataIndex: "clientName",
      key: "clientName",
      width: 100,
    },

    {
      title: "Phone number",
      dataIndex: "officialNumber",
      key: "officialNumber",
      width: 100,
    },
    {
      title: "Email",
      dataIndex: "emailId",
      key: "emailId",
      width: 100,
    },
    {
      title: "Quotation",
      dataIndex: "quoteStatus",
      key: "quoteStatus",
      width: 100,
      render: (text, record) => {
        return (
          <a
            onClick={() => navigate("/quotation-list/" + record?.clientLeadId)}
            style={{
              cursor: text == true ? "pointer" : "not-allowed",
              color: text == true ? "#1890ff" : "#a0a0a0",
            }}
          >
            {"View quote"}
          </a>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,

      render: (text) => {
        return <div className="">{capitalize(text) || "--"}</div>;
      },
    },

    // {
    //   title: "Actions",
    //   key: "action",
    //   align: "center",
    //   className: "actions-col",
    //   width: 150,

    //   render: (_, record) => {
    //     return record?.status == "Pending" ? (
    //       <Space size="middle">
    //         <Tooltip title={"Edit"} placement="bottom">
    //           <p
    //             className="pointer"
    //             style={{ marginBottom: 0 }}
    //             onClick={() => handleEdit(record?.staffId)}
    //           >
    //             <Edit_icon />
    //           </p>
    //         </Tooltip>
    //         <Tooltip title="Disable" placement="bottom">
    //           <div className="view-all-staff">
    //             <Popconfirm
    //               title={
    //                 <span
    //                   className="test"
    //                   style={{
    //                     textTransform: "initial",
    //                   }}
    //                 >
    //                   Do you want to disable this Staff ?
    //                 </span>
    //               }
    //               cancelText="No"
    //               okText="Yes"
    //               icon={
    //                 <QuestionCircleOutlined
    //                   style={{
    //                     color: "red",
    //                   }}
    //                 />
    //               }
    //               onConfirm={() => handleDisable(record?.staffId)}
    //             >
    //               {/* <p className="pointer" style={{ marginBottom: 0 }}>
    //                 <Disable_icon />
    //               </p> */}
    //             </Popconfirm>
    //           </div>
    //         </Tooltip>
    //       </Space>
    //     ) : (
    //       <Space size="middle">
    //         {/* <Tooltip title={"Edit"} placement="bottom"> */}
    //         <p style={{ marginBottom: 0 }}>
    //           <Disable_Edit_icon />
    //         </p>
    //         {/* </Tooltip> */}
    //         <Tooltip title="Enable" placement="bottom">
    //           <Popconfirm
    //             title={
    //               <span
    //                 className="test"
    //                 style={{
    //                   textTransform: "initial",
    //                 }}
    //               >
    //                 Do you want to enable this Staff ?
    //               </span>
    //             }
    //             // description="Are you sure to Enable this staff?"
    //             okText="Yes"
    //             cancelText="No"
    //             icon={
    //               <QuestionCircleOutlined
    //                 style={{
    //                   color: "green",
    //                 }}
    //               />
    //             }
    //             onConfirm={() => handleEnable(record?.staffId)}
    //           >
    //             {/* <p className="pointer">
    //               <img src={check_mark} style={{ width: "22px" }} />
    //             </p> */}
    //           </Popconfirm>
    //         </Tooltip>
    //       </Space>
    //     );
    //   },
    // },
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const filterArray = (data, currValue) => {
    return data?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );
  };

  const onSearch = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(tableData, currValue)
        : filterArray(tableData, currValue);
    setdataSource(filteredData);
  };

  const handlePageChange = (pagination) => {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
    setCurrentPage(pagination.current);
    getStaffTable(
      `?pageNum=${pagination.current - 1}&pageSize=${pagination.pageSize}`
    );
  };

  return (
    <>
      <div className="lead-gen-list-container mt-1 ml-2">
        <Heading title="Lead Generation List" />
      </div>
      <div className="master-main-container m-1">
        <div className="align-center space-between">
          {/* <div className="pl-1-5 fw-600"></div> */}
          <div className="p-1">
            <Input
              placeholder="Search by Lead Id, Phone number, Company name"
              bordered
              onChange={onSearch}
              style={{ borderRadius: "5px", height: "40px", width: "400px" }}
              suffix={<img src={search_icon} />}
            />
          </div>
          <Row className="p-1">
            <Button
              className="save-btn"
              onClick={() => {
                navigate("/client-lead-generation");
                localStorage.removeItem("clientLeadId");
              }}
            >
              {" "}
              + Add New
            </Button>
          </Row>
        </div>
        <Table
          className="table-head-sticky"
          loading={{
            indicator: (
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            ),
            spinning: loading,
          }}
          columns={columns}
          // loading={loading}
          defaultCurrent={1}
          onChange={handlePageChange}
          dataSource={inputSearchValue?.length == 0 ? tableData : dataSource}
          pagination={{
            // pageSize: pageSize,
            // total: inputSearchValue.length == 0 ? tableData.length : 10,
            current: currentPage,
            pageSize: pageSize,
            total: totalPageSize,
            itemRender: itemRender,
          }}
          // rowClassName={(record) => !record?.status && "disabled-row"}
        />
      </div>
    </>
  );
};

export default ClientLeadList;
