/* eslint-disable indent */
import React from "react";
import { Row, Col, Tooltip } from "antd";
import packedIdIcon from "../../../assests/images/packetIcon.svg";
import rsIcon from "../../../assests/images/rupiIcon.svg";
import paper_pin from "../../../assests/images/paperpin.svg";
import packetContents from "../../../assests/images/packetContents.svg";
import "./style.scss";

const Minicard = ({ item }) => {
  return (
    <div className="truck-minicard-container">
      <div className="minicard-main-container">
        <div className="first-container">
          <div className="left-container">
            <img src={packedIdIcon} />
            <div className="lower-box">
              <p>AWB Number</p> <div className="awb-no">{item?.awbNumber}</div>
            </div>
          </div>
          <div className="right-container">
            <img src={rsIcon} />
            <div className="lower-box">
              <p>Value</p>
              <div className="awb-no">{item?.value}</div>
            </div>
          </div>
        </div>
        {item?.type === "non-dox" && item?.childShipmentDetails?.length > 0 ? (
          <div className="middle-container">
            <div className="right-container">
              <img src={packetContents} />
              <p>Packet Contents</p>
            </div>

            <div className="box-cont">
              <Row gutter={32}>
                {item?.childShipmentDetails?.map((el, idx) => (
                  <Col key={idx}>{el?.childAwbNumber}</Col>
                ))}
              </Row>
              <Tooltip
                placement="bottom"
                title={item?.childShipmentDetails
                  ?.slice(4, item?.childShipmentDetails?.length)
                  .map((el, ind) => (
                    <div key={ind}>{el && el?.childAwbNumber}</div>
                  ))}
              >
                {item?.childShipmentDetails?.length > 4 ? (
                  <div className="count">{`+${
                    item?.childShipmentDetails?.length - 4
                  }`}</div>
                ) : null}
              </Tooltip>
            </div>
          </div>
        ) : null}
        <div className="second-container space-between" style={{paddingRight:"10px"}}>
          {item?.ewayBillNumber ? (
            <div className="paperpin-container">
              <img src={paper_pin} />
              <div className="child-packet-div">E-way Bill</div>
            </div>
          ) : null}
          {item?.type === "non-dox" && item?.childShipmentDetails?.length ? (
            <div className="paperpin-container">
              <img src={paper_pin} />
              <div className="child-packet-div">Child Packet</div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Minicard;
