/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Input, Table, message, Space } from "antd";
import search_icon from "../../../assests/images/search-icon.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import { camelCase, capitaliseWithHyphen } from "../../../utils";
import { serialNum } from "../../../utils";
import { ReactComponent as Scan_check } from "../../../assests/images/SVG/check.svg";
import urls from "../../../api/urls";
import API from "../../../api";
import { useNavigate } from "react-router-dom";

import "./style.scss";
import { APIs } from "../../../utils/worker";

const ViewManifest = ({ onClickId, activeKey }) => {
  const { GET_MANIFEST_LIST, GET_CP_MANIFEST_LIST } = urls;
  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (activeKey == "2") {
      getManifestList("?pageNum=0&pageSize=10");
    }
  }, [activeKey]);

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const dateConverter = (tableDate) => {
    const date = new Date(tableDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };
  const navigate = useNavigate();

  const navigatePage = (record) => {
    navigate("/print", { state: record?.manifestId });
  };

  const adr = (inputString) => {
    let a = inputString
      .split("_")
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
      .join(" ");
    return a;
  };

  const columns = [
    {
      title: "Sl No",
      align: "center",
      // width: 70,
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "Manifest ID",
      dataIndex: "manifestId",
      // width: 20,
      key: "manifestId",
      render: (text, record) => {
        return (
          <div className="d-flex">
            <div>
              <a
                onClick={() =>
                  record?.status !== "LOCKED_BY_HUB"
                    ? onClickId(text, record?.toPremise, record?.cpName)
                    : navigate("/print-generate-manifest/" + record?.manifestId)
                }
                // style={{ marginRight: "15px" }}
              >
                {text}
              </a>
            </div>
            {/* {<Scan_check />} */}
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createDate",
      key: "createDate",
      render: (text) => (
        <div style={{ whiteSpace: "nowrap" }}>{dateConverter(text)}</div>
      ),
      // width: 100,
    },
    {
      title: "CP",
      dataIndex: "cpName",
      key: "cpName",
      render: (text) => <div>{adr(text)}</div>,
      width: 270,
    },

    {
      title: "No of shipment(s)",
      dataIndex: "shipmentNum",
      width: 30,
      key: "shipmentNum",
    },
    {
      title: "No of packet(s)",
      dataIndex: "packetNum",
      width: 30,
      key: "packetNum",
    },

    {
      title: "Total Count",
      dataIndex: "count",
      width: 30,
      key: "count",
      render: (text, record) => (
        <div>{record?.packetNum + record?.shipmentNum}</div>
      ),
    },
    {
      title: "Total weight (Kg)",
      dataIndex: "totalweight",
      width: 30,
      key: "totalweight",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text) => <div>{adr(text)}</div>,
    },
    {
      title: "E-way Bill",
      dataIndex: "EwayUpdate",
      key: "EwayUpdate",
      render: (record, _record) => {
        return (
          <Space>
            <Button
              className="cancel-btn"
              disabled={!_record?.isEwayBill}
              onClick={() => {
                navigate(`/hub-manifest-eway/${_record.manifestId}`, {
                  state: _record,
                });
              }}
            >
              UPDATE PART B
            </Button>
            <Button
              className="cancel-btn"
              onClick={() =>
                navigate("/print-eway-bill", {
                  state: {
                    state: _record?.manifestId,
                    key: "MANIFEST_LIST",
                    slug: "/generateManifest",
                  },
                })
              }
            >
              PRINT
            </Button>
          </Space>
        );
      },
    },
  ];

  const userinfo = JSON.parse(localStorage.getItem("userinfo"));

  const getManifestList = async (page) => {
    setLoading(true);
    let response;
    if (userinfo.roles.includes("ROLE_CPADMIN")) {
      response = await API.Api(
        GET_CP_MANIFEST_LIST
      ).getWithUserNPremiseHeader();
    } else {
      response = await API.Api(
        GET_MANIFEST_LIST + page
      ).getWithUserNPremiseHeader();
    }
    // let response = await API.Api(GET_MANIFEST_LIST).getWithUserNPremiseHeader();
    if (response?.status) {
      setLoading(false);

      setTableData(response?.response?.data?.data);
      setTotalPage(response?.response?.data?.data[0]?.count, "res");
    } else {
      message.error(response?.response?.data?.data?.message);
      setLoading(false);
    }
  };

  const handlePageChange = (pagination) => {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
    setCurrentPage(pagination.current);
    getManifestList(
      `?pageNum=${pagination.current - 1}&pageSize=${pagination.pageSize}`
    );
  };

  const [inputSearch, setInputSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  // const [errorMessate, setErrorMessate] = useState(false);s

  const openMessage = (msg) => {
    const key = "updatable";

    messageApi.open({
      key,
      type: "loading",
      content: "Loading...",
    });
    setTimeout(() => {
      messageApi.open({
        key,
        type: "error",
        content: msg,
        duration: 2,
      });
    }, 1000);
  };

  const onSearch = async (e) => {
    // if (e.target.value.length < 3) {
    //   setPageSize(0);
    // }
    setInputSearch(e.target.value);
    if (e.target.value.length >= 2) {
      let response = await API.Api(
        `${APIs.baseURL}/last-mile-manifest-service/v1/search/manifest/${e.target.value}`
      ).getWithAllHeaders();
      if (response.status) {
        let resData = response?.response?.data;
        setFilteredData(resData);
        setTotalPage(resData.length);
      } else {
        openMessage(response.response.data.message);
        setTableData([]);
        setFilteredData([]);
        // setTotalPage(tableData[0]?.count);
        setTotalPage(0);
        // message.error(response?.response?.data?.message);
      }
    }
    if (e.target.value.length < 1) {
      getManifestList("?pageNum=0&pageSize=10");
      setTotalPage(tableData[0]?.count);
      setCurrentPage(1);
    }
  };
  return (
    <div>
      {contextHolder}
      <div className="manifestListContainer">
        <div className="p-1" style={{ display: "flex", justifyContent: "end" }}>
          <Input
            placeholder="Search by CP Name , Manifest ID"
            bordered
            style={{ borderRadius: "5px", height: "40px", width: "465px" }}
            suffix={<img src={search_icon} />}
            onChange={(e) => {
              onSearch(e);
            }}
          />
        </div>
        <Table
          className="table-head-sticky"
          // loading={loading}
          loading={{
            indicator: (
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            ),
            spinning: loading,
          }}
          columns={columns}
          dataSource={inputSearch !== "" ? filteredData : tableData}
          // scroll={{
          //   x: 1500,
          //   y: 500,
          // }}
          pagination={{
            pageSize: pageSize,
            total: totalPage,
            itemRender: itemRender,
            pageSizeOptions: [10, 20, 50, 100],
            current: currentPage == 0 ? currentPage + 1 : currentPage,
          }}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default ViewManifest;
