/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Empty,
  Button,
  Modal,
  Spin,
  Tooltip,
  Input,
  message,
} from "antd";
import Heading from "../../../components/Heading";
import packeting from "../../../assests/images/SVG/packet-white.svg";
import API from "../../../api";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import urls from "../../../api/urls";
import search_icon from "../../../assests/images/search-icon.svg";
import bagging from "../../../assests/images/SVG/bag-white.svg";

import { capitaliseWithHyphen } from "../../../utils";
const { SORT_SHIPMENT } = urls;
import "./style.scss";

const ViewSortingMM = () => {
  const navigate = useNavigate();
  const [sortShipment, setSortShipment] = useState([]);
  const [modalContent, setModalContent] = useState({});
  const [premiseName, setPremiseName] = useState("");
  const [loading, setLoading] = useState(false);
  const [sortingRulesTemp, setSortingRulesTemp] = useState([]);
  const [sortingRulesTemp_, setSortingRulesTemp_] = useState([]);
  const [numColumns, setNumColmuns] = useState(4);
  const [inputSearchValue, setInputSearchValue] = useState("");

  useEffect(() => {
    let hubDetails = JSON.parse(localStorage.getItem("userId"));
    setPremiseName(capitaliseWithHyphen(hubDetails?.premises?.premiseName));
    initialize();
  }, []);

  useEffect(() => {
    let sortingData = sortShipment;
    let numberOfColumns = numColumns;
    let sortingDataTemp = [];
    let numRow = sortingData?.length / numberOfColumns;
    let x = numberOfColumns;
    let z = 0;
    for (let i = 0; i < numRow; i++) {
      let temp = [];
      for (let y = z; y < x; y++) {
        if (sortingData[y]) {
          temp.push(sortingData[y]);
        }
      }
      sortingDataTemp.push(temp);
      z = x;
      x += numberOfColumns;
    }
    setSortingRulesTemp([...sortingDataTemp]);
  }, [sortShipment]);

  const initialize = async () => {
    setLoading(true);
    let response = await API.Api(SORT_SHIPMENT).getWithPremiseHeader();
    if (response?.status) {
      let sortingData = response?.response?.data?.sortingPlanDtos;
      // sortingData?.map((e) => {
      //   e.barcodeAndStatusList = e.barcodeAndStatusList.sort(
      //     (a, b) => a.status.charCodeAt(0) - b.status.charCodeAt(0)
      //   );
      // });
      let sortingDataTemp = [];
      let numberOfColumns = response?.response?.data?.numberOfColumns || 4;
      setNumColmuns(numberOfColumns);
      let numRow = sortingData?.length / numberOfColumns;
      let x = numberOfColumns;
      let z = 0;
      for (let i = 0; i < numRow; i++) {
        let temp = [];
        for (let y = z; y < x; y++) {
          if (sortingData[y]) {
            temp.push(sortingData[y]);
          }
        }
        sortingDataTemp.push(temp);
        z = x;
        x += numberOfColumns;
      }
      setSortingRulesTemp([...sortingDataTemp]);
      setSortingRulesTemp_([...sortingDataTemp]);
      setSortShipment(sortingData);
      setLoading(false);
    } else {
      message.error(response?.response?.data?.message || "Ntework error.");
      setLoading(false);
    }
  };

  const filterArray = (data, currValue) => {
    let temp = [];
    for (let i in data) {
      if (typeof data[i] == "object") {
        let filtered = data[i]?.filter((o) =>
          Object?.keys(o)?.some((k) =>
            String(o[k])?.toLowerCase()?.includes(currValue.toLowerCase())
          )
        );
        if (filtered.length > 0) {
          temp.push(filtered);
        }
      }
    }
    return temp;
  };

  const onSearch = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue?.length > 0 || inputSearchValue?.length == 0
        ? filterArray(sortingRulesTemp_, currValue)
        : filterArray(sortingRulesTemp_, currValue);
    setSortingRulesTemp(filteredData);
  };

  function toCapitalizedFormat(str) {
    return str
      .toLowerCase()
      .replace(/\b\w/g, (chr) => chr.toUpperCase())
      .replace(/\(\w/g, (chr) => chr.toUpperCase());
  }

  const sortingCages = () => {
    let temp = [];
    sortingRulesTemp.map((item, i) => {
      temp.push(
        <div className="d-flex" key={`cage_num${i}`}>
          {item.map((e, i) => {
            return (
              <div
                className="view-cage"
                key={`viewCage${i}`}
                style={{
                  minWidth: "170px",
                  maxWidth: "170px",
                  margin: "0.5rem",
                }}>
                <div className="space-between align-center">
                  <div className="align-center">
                    <span className="box-no left-box center">
                      {e.cageNumber}
                    </span>
                    <Tooltip title={toCapitalizedFormat(e?.destinationHubName)}>
                      <div
                        className="fw-500 ml-1 d-hub"
                        style={{ maxWidth: "100px", minWidth: "100px" }}>
                        {capitaliseWithHyphen(e?.destinationHubName)}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div
                  className="cage-container p-05"
                  style={{ background: "#EAF1FD", height: "110px" }}
                  id={e.cageNumber}>
                  <Row>
                    <Col span={20}>
                      <div className="shipment-show-div">
                        {e?.barcodes?.slice(0, 5).map((item, i) => (
                          <span
                            style={{ fontSize: "12px" }}
                            key={`shipment${i}`}
                            className="align-center">
                            {item}
                            {i < 4 && i < e.barcodes.length - 1 && ","}
                            {i === 4 && e.barcodes.length > 5 && " ..."}
                            &nbsp;
                          </span>
                        ))}
                      </div>
                    </Col>
                    <Col span={4}>
                      <span
                        className="count-circle center pointer"
                        style={{ borderRadius: "2px" }}
                        onClick={() =>
                          showBoxModal(e?.cageNumber, e?.barcodes, "barcode")
                        }>
                        {e?.barcodes?.length}
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
            );
          })}
        </div>
      );
    });
    return temp;
  };

  const showBoxModal = (cageNo, list, type) => {
    // let listTemp = [];
    // if (type == "hub") {
    //   list.map((e) => {
    //     listTemp.push({
    //       barcode: e.hubName
    //         .split(" ")
    //         .map((e) => e.charAt(0).toUpperCase() + e.slice(1).toLowerCase())
    //         .join(" "),
    //       status: "",
    //     });
    //   });
    // } else {
    //   listTemp = list;
    // }
    setModalContent({
      cageNo: `Box ${cageNo} details`,
      list: list,
      show: true,
    });
  };

  return (
    <div className="p-2-tab">
      <Heading
        title={"Sort Shipment"}
        nextPage={"/shipment-bagging"}
        nextPageTitle={"Bagging"}
        imgSrc={bagging}
      />
      <Spin
        spinning={loading}
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }>
        <div className="master-main-container mt-1">
          <div className="space-between align-center">
            <p
              className="f-16 fw-700 title-color"
              style={{ padding: "1rem 1rem 0 1rem" }}>
              {premiseName}
            </p>
            <Input
              placeholder="Search by Hub/AWB Number"
              bordered
              style={{
                borderRadius: "5px",
                height: "40px",
                width: "300px",
                marginRight: "1rem",
              }}
              suffix={<img src={search_icon} />}
              onChange={(e) => {
                onSearch(e);
              }}
            />
          </div>

          <hr className="divider" />

          <Row className="sort-class">
            <div className="p-1">{sortingCages()}</div>
          </Row>

          <Modal
            title={modalContent?.cageNo}
            open={modalContent?.show}
            onCancel={() => setModalContent({})}
            width={600}
            footer={null}
            className="hub-show-modal"
            destroyOnClose>
            <Row gutter={15} className="mb-1">
              {modalContent?.list?.length > 0 ? (
                modalContent?.list?.map((e) => {
                  return (
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      key={e.value}
                      className="mt-1">
                      <div className="space-between p-05 modal-hub">
                        <span>{e}</span>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <Col span={24}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Col>
              )}
            </Row>
          </Modal>
          <div>
            <hr className="divider" />
            <div className="d-flex flex-end p-1">
              <Button
                className="save-btn ml-1"
                onClick={() => navigate("/middle-mile")}>
                DONE
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};
export default ViewSortingMM;
