import actions from "../constants/cashBookingActions";

export const loginUser = (data) => ({
  type: actions.LOGIN_USER,
  data,
});

export const getUser = (data) => ({
  type: actions.GET_USER,
  data,
});

export const getCpPremises = (data) => ({
  type: actions.GET_CP_PREMISES,
  data,
});
export const getSenderPincode = (data) => ({
  type: actions.GET_SENDER_PINCODE,
  data,
});

export const getGSTDetails = (data) => ({
  type: actions.GET_GST_DETAILS,
  data,
});

export const getBarcodeId = (data, id, bookingType) => ({
  type: actions.GET_BARCODE_ID,
  data,
  id,
  bookingType,
});

export const clearBarcodeId = () => ({
  type: actions.GET_BARCODE_ID_CLEAR,
});

export const getPacketBarcode = (data, id) => ({
  type: actions.GET_PACKET_BARCODE,
  data,
  id,
});

export const getBookingDetails = (data) => ({
  type: actions.GET_BOOKING_DETAILS,
  data,
});

export const getServiceAbilityCheck = (data, token) => ({
  type: actions.GET_SERVICEABILITY_DETAILIS,
  data,
  token,
});

export const getServiceAbilityCheckReset = () => ({
  type: actions.GET_SERVICEABILITY_DETAILIS_CLEAR,
});

export const getCalculaterRateChart = (data) => ({
  type: actions.GET_CAL_TATCHART,
  data,
});
export const getCalculaterRateChartClear = () => ({
  type: actions.GET_CAL_TATCHART_CLEAR,
});

export const getPinCodeArea = (data) => ({
  type: actions.GET_PINCODE_AREA,
  data,
});

export const getAccountService = (data, token) => ({
  type: actions.GET_ACCOUNT_SERVICE,
  data,
  token,
});

export const postShipment = (data, id) => ({
  type: actions.CREATE_SHIPMENT,
  data,
  id,
});

export const clearPostShipment = () => ({
  type: actions.CREATE_SHIPMENT_CLEAR,
});

export const updateShipment = (data, awbNum) => ({
  type: actions.UPDATE_SHIPMENT,
  data,
  awbNum,
});

export const clearUpdateShipment = () => ({
  type: actions.UPDATE_SHIPMENT_CLEAR,
});

export const clearBarcode = () => ({
  type: actions.GET_BARCODE_ID_CLEAR,
});

export const updateAWBNum = (data, awbNum) => ({
  type: actions.UPDATE_AWB_NUM,
  data,
  awbNum,
});

export const getSingleBooking = (data) => ({
  type: actions.GET_SINGLE_VIEWBOOKING,
  data,
});

export const getChildPacket = (payload) => ({
  type: actions.OPEN_CAL,
  payload,
});

export const getChildPacketAdd = (payload) => ({
  type: actions.OPEN_CAL_ADD,
  payload,
});

export const getChildPacketSub = (index) => ({
  type: actions.OPEN_CAL_SUB,
  index,
});

export const getChildPacketIds = (p1, p2, p3) => ({
  type: actions.GET_CHILDPACKETIDs,
  p1,
  p2,
  p3,
});

export const clearChildPacketIds = () => ({
  type: actions.GET_CHILDPACKETIDs__CLEAR,
});

export const getManifest = () => ({
  type: actions.GET_MANIFEST,
});

export const getPostalPincode = (payload) => ({
  type: actions.GET_POSTAL_PINCODE,
  payload,
});

export const createChildShipment = (query, payload) => ({
  type: actions.CREATE_CHILD_SHIPMENT,
  query,
  payload,
});

export const clearCreateChildShipment = () => ({
  type: actions.CREATE_CHILD_SHIPMENT_CLEAR,
});
// e-way-bill
export const getEWayBill = (payload) => ({
  type: actions.GET_E_WAY_BILL,
  payload,
});

// vehicle onboard

export const onboardVehicle = (payload) => ({
  type: actions.ONBOARD_VEHICLE_,
  payload,
});

export const onboardVehicleClear = () => ({
  type: actions.ONBOARD_VEHICLE_CLEAR,
});

export const updateOnboardedVehicle = (payload, query) => ({
  type: actions.UPDATE_ONBOARDED_VEHICLE_,
  payload,
  query,
});

export const updateOnboardedVehicleClear = () => ({
  type: actions.UPDATE_ONBOARDED_VEHICLE_CLEAR,
});

export const disableOnboardedVehicle = (payload, query) => ({
  type: actions.DISBALE_ONBOARDED_VEHICLE_,
  payload,
  query,
});

export const disableOnboardedVehicleClear = () => ({
  type: actions.DISBALE_ONBOARDED_VEHICLE_CLEAR,
});

export const getSingleOnboarededVehicle = (payload) => ({
  type: actions.GET_SINGLE_ONBOARDED_VEHICLE_,
  payload,
});

export const getSingleOnboarededVehicleClear = () => ({
  type: actions.GET_SINGLE_ONBOARDED_VEHICLE_CLEAR,
});

export const getAllOnboardedVehicles = (payload) => ({
  type: actions.GET_ALL_ONBOARDED_VEHICLES_TABLE,
  payload,
});

export const clearAllOnboardedVehicles = () => ({
  type: actions.GET_ALL_ONBOARDED_VEHICLES_TABLE_CLEAR,
});

export const accountPostShipment = (data, id) => ({
  type: actions.ACCOUNT_CREATE_SHIPMENT,
  data,
  id,
});

export const accountClearPostShipment = () => ({
  type: actions.ACCOUNT_CREATE_SHIPMENT_CLEAR,
});

export const accountUpdateShipment = (data, awbNum) => ({
  type: actions.ACCOUNT_UPDATE_SHIPMENT,
  data,
  awbNum,
});

export const accountClearUpdateShipment = () => ({
  type: actions.ACCOUNT_UPDATE_SHIPMENT_CLEAR,
});
