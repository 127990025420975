import React from "react";
import { Row, Col, Tooltip } from "antd";

export const PacketContents = ({ numberOfPakcet }) => {
  return (
    <Row gutter={12} className="packet-contents-row">
      {numberOfPakcet?.slice(0, 4).map((e, i) => {
        // console.log(e?.awbNo, "e?.awbNo");
        return (
          <Col className="PacketContents-number" key={i}>
            {e?.awbNo || e?.childAwbNumber}
          </Col>
        );
      })}
      <span className="PacketContents-editionalCount">
        <Tooltip
          placement="bottom"
          title={numberOfPakcet?.slice(4, numberOfPakcet.length).map((e, i) => (
            <div key={i}>{e?.awbNo || e?.childAwbNumber}</div>
          ))}
        >
          {numberOfPakcet?.length > 4 ? `+ ${numberOfPakcet.length - 4}` : null}
        </Tooltip>
      </span>
    </Row>
  );
};
