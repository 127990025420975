/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import Heading from "../../components/Heading";
import { Button, Form, Row, Col, Input, message } from "antd";
import { checkNumbervalue, checkSpace } from "../../utils";
import nodeURLs from "../../api/nodeUrls";
import API from "../../api";

function LCRThreshold() {
  const [form] = Form.useForm();
  const { THRESHOLD_POST, ELIGBLE_THRESHOLD } = nodeURLs;
  const [editEnable, setEditEnable] = useState(false);
  const [onInitialize, setonInitialize] = useState([]);
  useEffect(() => {
    getThresholWeight();
  }, []);

  const onFinish = async (values) => {
    const response = await API.Api(THRESHOLD_POST).postWithPrmiseHeader(values);

    if (response.status) {
      setEditEnable(!editEnable);
      message.success(response?.data?.message || "Data Audited Successfully");
    } else {
      message.error(response.response.data.message);
    }
  };

  const getThresholWeight = async () => {
    let response = await API.Api(ELIGBLE_THRESHOLD).getWithPremiseIdHeader();

    if (response?.status) {
      form.setFieldsValue({
        weightThreshold: response.response.data[1].value,
        volumetricWeightThreshold: response.response.data[0].value,
      });
      setonInitialize(response.response.data);
    } else {
      message.error(response.response.data.message);
    }
  };

  const onCancle = () => {
    setEditEnable(false);
    form.setFieldsValue({
      weightThreshold: onInitialize[1].value,
      volumetricWeightThreshold: onInitialize[0].value,
    });
  };

  const rules = [
    {
      required: true,
      message: "Please enter a weight",
    },
    {
      pattern: /^(100(\.0{0,2})?|(\d{1,2})(\.\d{0,2})?)$/,
      message: "Enter a valid weight up to 99.99 with up to two decimal places",
    },
    {
      validator: (_, value) => {
        if (value && value.endsWith(".")) {
          return Promise.reject("Please enter a value after the decimal point");
        }
        return Promise.resolve();
      },
    },
  ];

  return (
    <div className="p-2">
      <Heading title={"LCR Threshold"} navigateTo={"/masters"} />
      <div className="config-template mb-2">
        <p className="f-16 fw-500 title-color pt-1 pb-1 pl-1">
          Set shipment weight threshold
        </p>
        <hr className="divider" />
        <>
          <Form
            form={form}
            onFinish={onFinish}
            name="basic"
            className="global-form "
            layout="vertical"
            autoComplete="off"
          >
            <Row
              gutter={20}
              justify={"center"}
              style={{ minWidth: "65%" }}
              className="p-2 global-form-row "
            >
              <Col lg={8}>
                <Form.Item
                  label="Actual Weight(Kg)"
                  name="weightThreshold"
                  onKeyPress={(event) => {
                    if (checkNumbervalue(event) || checkSpace(event)) {
                      event.preventDefault();
                    }
                  }}
                  rules={rules}
                >
                  <Input
                    disabled={!editEnable}
                    placeholder="Enter weight"
                    maxLength={5}
                    type="text"
                  />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  name="volumetricWeightThreshold"
                  label="Volumetric Weight(Kg)"
                  rules={rules}
                  onKeyPress={(event) => {
                    if (checkNumbervalue(event) || checkSpace(event)) {
                      event.preventDefault();
                    }
                  }}
                >
                  <Input
                    disabled={!editEnable}
                    placeholder="Enter volumetric"
                    maxLength={5}
                    type="text"
                  />
                </Form.Item>
              </Col>
              <Col lg={2}>
                {editEnable ? (
                  <div style={{ display: "flex", columnGap: "5px" }}>
                    <Button
                      type="primary"
                      className="save-btn"
                      form="basic"
                      htmlType="submit"
                      style={{ marginTop: "30px", padding: "0px 40px" }}
                    >
                      SAVE
                    </Button>
                    <Button
                      // type="primary"
                      onClick={() => onCancle()}
                      className="back-button"
                      style={{
                        marginTop: "30px",
                        padding: "0px 20px",
                      }}
                    >
                      CANCEL
                    </Button>
                  </div>
                ) : (
                  <Button
                    type="primary"
                    // className="save-btn"
                    onClick={() => setEditEnable(true)}
                    // form="basic"
                    // htmlType="submit"
                    style={{ marginTop: "30px", padding: "0px 40px" }}
                  >
                    Edit
                  </Button>
                )}
              </Col>
            </Row>
            <Row
              gutter={20}
              justify={"center"}
              style={{ minWidth: "65%" }}
              className="p-2 global-form-row"
            >
              <Col
                style={{
                  backgroundColor: "#D7E5F4",
                  border: "1px solid #C2D4EA ",
                  height: "92px",
                  borderRadius: "5px",
                }}
                lg={12}
                className="center"
              >
                <span style={{ fontSize: "14px", color: "#444444" }}>
                  <span
                    style={{
                      color: "#1C68C5",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    NOTE :
                  </span>
                  If (Volumetric Weight / Actual Weight) is less than or equal
                  to threshold, then the shipment must be bagged.
                </span>
              </Col>
            </Row>
          </Form>
        </>
      </div>
    </div>
  );
}

export default LCRThreshold;
