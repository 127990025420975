/* eslint-disable no-unused-vars */
import React,{useState} from "react";
import axios from "axios";
import { capitaliseWithHyphen} from "../../utils";
import { APIs } from "../../utils/worker";
import Heading from "./Heading";
import BottomButton from "./BottomButton";
import { Form,Row, Col,Input, Button, Radio, Spin,message,Select,Space } from "antd";


const BranchDetails = ({goBackStep,handleStepsChange}) => {
  const [form] = Form.useForm();
  const [loading,setLoading] = useState(false);
  const onFinish=()=>{
    handleStepsChange();
  };
  const pinCodeHandler = async (e) => {
    if (e.target.value.length > 5) {
      setLoading(true);
      let userData = JSON.parse(localStorage.getItem("userinfo"));

      try {
        let response = await axios.get(
          `${APIs.baseURL}/pincode-service/v1/pincode/${e.target.value}`,
          {
            headers: {
              Authorization: "Bearer " + userData.accessToken,
            },
          }
        );
        let res = response.data;
        if (res?.stateName) {
          setLoading(false);
          if (
            res?.enable == true &&
              res.serviceability.serviceability !== "Non-Serviceable"
          ) {
            form.setFieldsValue({
              city: res?.city !== null ? capitaliseWithHyphen(res?.city) : "NA",
              stateName: capitaliseWithHyphen(res?.stateName),
              stateCode: res?.stateCode,
            });
          } 
          else {
            setLoading(false);
            form.setFields([
              {
                name: "pinCode",
                errors: ["Please give correct pincode"],
              },
            ]);
          }
        }
      } catch (error) {
        message.error(error?.response);
        setLoading(false);
        form.setFields([
          {
            name: "pinCode",
            errors: ["Network error"],
          },
        ]);
      }
    } else if (e.target.value.length < 6) {
      form.setFieldsValue({
        city: "",
        stateCode: "",
        stateName: "",
      });
    }
  };
  return (
    <div className="p-2">
      <Spin spinning={loading}>
        <Heading title={"Branch Details"}/>
        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
        >
          <Row gutter={24} className="mb-2">
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Bracnh name"
                name="bracnhName"
                rules={[
                  {
                    required: true,
                    message: "Please enter bracnh name",
                  },
                ]}
              >
                <Input className="input-field-style" placeholder="Enter branch name"/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="CP type"
                name="cpType"
                rules={[
                  {
                    required: true,
                    message: "Select CP type",
                  },
                ]}
              >
                <Select 
                  className="input-field-style select-field"
                  placeholder="Please select CP type"
                  options={[
                    {
                      value: "Individual",
                      label: "Individual",
                    },
                    {
                      value: "Proprietorship",
                      label: "Proprietorship",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Mother hub name"
                name="motherHubName"
                rules={[
                  {
                    required: true,
                    message: "Select mother hub name",
                  },
                ]}
              >
                <Select 
                  className="input-field-style select-field"
                  placeholder="Please select mother hub name"
                  options={[
                    {
                      value: "Individual",
                      label: "Individual",
                    },
                    {
                      value: "Proprietorship",
                      label: "Proprietorship",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Zone"
                name="zone"
                rules={[
                  {
                    required: true,
                    message: "Please enter zone",
                  },
                ]}
              >
                <Input className="input-field-style" placeholder="Enter zone name"/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Rate card type"
                name="rateCardType"
                rules={[
                  {
                    required: true,
                    message: "Select rate card type",
                  },
                ]}
              >
                <Select 
                  className="input-field-style select-field"
                  placeholder="Please select rate card type"
                  options={[
                    {
                      value: "Individual",
                      label: "Individual",
                    },
                    {
                      value: "Proprietorship",
                      label: "Proprietorship",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Billing cycle"
                name="billingCycle"
                rules={[
                  {
                    required: true,
                    message: "Select billing cycle",
                  },
                ]}
              >
                <Select 
                  className="input-field-style select-field"
                  placeholder="Please select billing cycle"
                  options={[
                    {
                      value: "Individual",
                      label: "Individual",
                    },
                    {
                      value: "Proprietorship",
                      label: "Proprietorship",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Heading title={"Address Details"} />
          <Row gutter={24}>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Address line 1"
                name="addressLine1"
                rules={[
                  {
                    required: true,
                    message: "Please input your username",
                  }]}
              >
                <Input className="input-field-style" placeholder="Enter address line 1"/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="Address line 2"
                name="addressLine2"
              >
                <Input className="input-field-style" placeholder="Enter address line 2"/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="PIN code"
                name="pinCode"
                rules={[
                  {
                    required: true,
                    message: "Please input your username",
                  },
                  () => ({
                    validator(rule, value = "") {
                      if (value.length > 0 && value.length < 6) {
                        return Promise.reject("Pincode should be 6 digits");
                      } else {
                        return Promise.resolve();
                      }
                    },
                    // validateTrigger: "onSubmit",
                  }),
                ]}
              >
                <Input className="input-field-style" onChange={pinCodeHandler} placeholder="Enter PIN code" maxLength={6}/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="City"
                name="city"
              >
                <Input className="input-field-style" disabled />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="State name"
                name="stateName"
              >
                <Input className="input-field-style" disabled/>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} sm={24} xs={24}>
              <Form.Item
                label="State code"
                name="stateCode"
              >
                <Input className="input-field-style" disabled/>
              </Form.Item>
            </Col>
          </Row>
          <hr className="divider" style={{ marginTop: "20px", marginBottom:"30px" }} />
          <BottomButton goBackStep={goBackStep}/>
        </Form>
      </Spin>
    </div>
  );
};

export default BranchDetails;
