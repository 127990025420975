/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table, message, Tooltip } from "antd";
import {
  camelCase,
  serialNum,
  normalizeString,
  toCapitalizedFormat,
  formatDateTime,
  routeListCapitalizedFormat,
  capitaliseWithHyphen,
} from "../../../../utils";
import urls from "../../../../api/urls";
import API from "../../../../api";

import Smcs_Loader from "../../../../assests/images/loader-new.gif";

import moment from "moment";

import nodeURLs from "../../../../api/nodeUrls";
import EditExpectionModal from "../EditExceptionModal";
import BagExpectionModal from "../BagExceptionModal";
import ExpectionSearchAndDateFilter from "../ExpectionSearchAndDateFilter";

const { GET_EXCEPTION_ACTIONS, UPDATE_EXCEPTION_REPORT, GET_EXCEPTION_REPORT } =
  nodeURLs;

const BagShortageActionReport = ({ activeKey }) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState();
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [startAndEndDate, setStartAndEndDate] = useState({});
  const [serachValue, setSerachValue] = useState(null);
  const [filterType, setFilterType] = useState("ALL");
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [editAwbData, setEditAwbData] = useState();

  const [dateRangeStatus, setDateRangeStatus] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});

  useEffect(() => {
    if (activeKey == "4") {
      let todaysDate = moment(new Date(), "ddd MMM DD YYYY HH:mm:ss").format(
        "DD-MM-YYYY"
      );
      let twoDaysBack = moment().subtract(1, "days").format("DD-MM-YYYY");

      if (startAndEndDate?.start) {
        getManifest(
          pageNum,
          pageSize,
          startAndEndDate.start,
          startAndEndDate.end,
          null,
          filterType
        );
      } else {
        setStartAndEndDate({ start: twoDaysBack, end: todaysDate });
        getManifest(
          pageNum,
          pageSize,
          twoDaysBack,
          todaysDate,
          null,
          filterType
        );
      }
    }
  }, [activeKey]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (serachValue?.length > 0) {
        getManifest(
          0,
          pageSize,
          startAndEndDate?.start,
          startAndEndDate?.end,
          serachValue
        ); //search api call
      } else if (serachValue === "") {
        getManifest(
          pageNum,
          pageSize,
          startAndEndDate?.start,
          startAndEndDate?.end
        );
      }
    }, 900);
    return () => clearTimeout(delayDebounceFn);
  }, [serachValue]);

  const onShowSizeChange = (current, pageSize) => {
    getManifest(
      current,
      pageSize,
      startAndEndDate.start,
      startAndEndDate.end,
      null,
      filterType
    );
  };

  function handlefilterChange(params) {
    setFilterType(params.toUpperCase());
    setSerachValue(null);
    getManifest(
      pageNum,
      pageSize,
      startAndEndDate.start,
      startAndEndDate.end,
      null,
      params.toUpperCase()
    );
  }

  function handleEditCallBack(params) {
    getManifest(
      pageNum,
      pageSize,
      startAndEndDate.start,
      startAndEndDate.end,
      null,
      null
    );
  }

  function handleEditModal(params) {
    setEditVisible(true);
    setEditAwbData(params);
  }

  const getColumnSearchProps = (dataIndex, filterList) => ({
    filters: filterList?.map((value) => ({
      text: capitaliseWithHyphen(value),
      value,
    })),
    onFilter: (value, record) => record[dataIndex]?.includes(value),
  });
  const columns = [
    {
      title: "Sl No",
      align: "center",
      width: 10,
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "Bag Number",
      dataIndex: "awb_number",
      width: 15,
      key: "awb_number",
      render: (text) => text,
    },

    {
      title: "Route",
      dataIndex: "route",
      key: "route",
      width: 20,
      render: (text, record) => {
        return text ? (
          <Tooltip
            placement="rightTop"
            title={
              record?.route_list &&
              routeListCapitalizedFormat(record?.route_list)
            }
          >
            {toCapitalizedFormat(text)}
          </Tooltip>
        ) : (
          "--"
        );
      },
    },
    {
      title: "Overage Hub",
      dataIndex: "overage_premise",
      key: "overage_premise",
      sorter: (a, b) => {
        const nameA = a.overage_premise || "";
        const nameB = b.overage_premise || "";

        if (!a.overage_premise && b.overage_premise) return 1;
        if (a.overage_premise && !b.overage_premise) return -1;
        return nameA.localeCompare(nameB);
      },
      ...getColumnSearchProps(
        "overage_premise",
        filterOptions?.overage_premise
      ),

      width: 20,

      render: (text) => <div>{capitaliseWithHyphen(text) || "--"}</div>,
    },

    {
      title: "Shortage Hub",
      dataIndex: "shortage_premise",
      key: "shortage_premise",
      sorter: (a, b) => {
        const nameA = a.shortage_premise || "";
        const nameB = b.shortage_premise || "";

        if (!a.shortage_premise && b.shortage_premise) return 1;
        if (a.shortage_premise && !b.shortage_premise) return -1;
        return nameA.localeCompare(nameB);
      },
      ...getColumnSearchProps(
        "shortage_premise",
        filterOptions?.shortage_premise
      ),

      width: 20,

      render: (text) => <div>{camelCase(text) || "--"}</div>,
    },
    {
      title: "Misroute",
      dataIndex: "exception_type",
      key: "exception_type",
      width: 10,
      render: (text) => <div>{text === "MISSROUTE" ? "Yes" : "No"}</div>,
    },
    // {
    //   title: "Booking",
    //   dataIndex: "is_booking",
    //   key: "is_booking",
    //   width: 10,
    //   render: (text) => <div>{text == true ? "Yes" : "No"}</div>,
    // },
    {
      title: "Operation",
      dataIndex: "currentstatus",
      width: 15,
      key: "awb_number",
      render: (text, record) => (
        <div>{normalizeString(record?.currentstatus?.operation) || "--"}</div>
      ),
    },

    {
      title: "Last Status",
      dataIndex: "lastStatus",
      key: "lastStatus",
      width: 10,
      render: (text, record) => (
        <div>
          {normalizeString(record?.actions[0]?.action) ||
            camelCase(record?.current?.status) ||
            "--"}
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "exception_type",
      key: "exception_type",
      width: 10,
      render: (text) => <div>{camelCase(text) || "--"}</div>,
    },
    {
      title: "Reason",
      dataIndex: "exception_type",
      key: "exception_type",
      width: 20,
      render: (text, record) => {
        // if (typeof record?.actions == "string") {
        //   return <div>{camelCase(text)}</div>;
        // } else {
        return <div>{camelCase(record?.actions[0]?.reason) || "--"}</div>;
        // }
      },
    },

    {
      title: "Last Updated(Date and time)",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 20,
      render: (text, rec) => <div>{formatDateTime(rec) || "--"}</div>,
    },
    {
      title: "Last Updated by",
      dataIndex: "lastUpdatedBy",
      key: "lastUpdatedBy",
      width: 20,
      render: (text, rec) => <div>{camelCase(rec?.premise_name) || "--"}</div>,
    },
    // {
    //   title: "Reason",
    //   dataIndex: "exception_type",
    //   key: "exception_type",
    //   width: 20,
    //   render: (text, record) => {
    //     if (record?.actions?.length > 0) {
    //       return (
    //         <div>{camelCase(record?.actions[0]?.action_code) || "--"}</div>
    //       );
    //     } else {
    //       return <div>{camelCase(text)}</div>;
    //     }
    //   },
    // },
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   width: 15,
    //   fixed: "right",
    //   key: "",
    //   render: (text, record) => {
    //     return (
    //       <Button
    //         className="save-btn"
    //         onClick={() => handleEditModal(record)}
    //         disabled={record?.lastStatus?.toUpperCase() == "DAMAGED"}
    //       >
    //         TAKE ACTION
    //       </Button>
    //     );
    //   },
    // },
  ];

  const getManifest = async (
    pageNum = null,
    pageSize = null,
    start = null,
    end = null,
    search = null,
    exceptionType = null
  ) => {
    setLoading(true);

    let params;
    if (search) {
      params = {
        page: pageNum,
        limit: pageSize,
        type: "Bag",
        exceptionType:
          exceptionType == "ALL"
            ? "DAMAGE,MISSROUTE,ROUTE_NOT_FOUND"
            : exceptionType, // OVERAGE
        startDate: start || null,
        endDate: end || null,
        search,
      };
    } else {
      setSerachValue(null);
      params = {
        page: pageNum,
        limit: pageSize,
        type: "Bag",
        exceptionType:
          exceptionType == "ALL"
            ? "DAMAGE,MISSROUTE,ROUTE_NOT_FOUND"
            : exceptionType, // OVERAGE
        startDate: start,
        endDate: end,
        // search,
      };
    }

    const response = await API.Api(GET_EXCEPTION_REPORT).NodeGetWithPremise(
      params
    );

    if (response?.status) {
      setLoading(false);
      let temp = [];
      let data = response?.response?.data?.exceptions;

      data?.forEach((e, index) => {
        temp.push({ ...e, slno: index + 1 });
      });
      const uniqueOveragePremise = [
        ...new Set(temp?.map((item) => item?.overage_premise)),
      ];
      const uniqueShortagePremise = [
        ...new Set(temp?.map((item) => item?.shortage_premise)),
      ];

      setFilterOptions({
        overage_premise: uniqueOveragePremise.filter(Boolean),
        shortage_premise: uniqueShortagePremise.filter(Boolean),
      });
      setDataSource(temp);

      setTotal(response?.response?.data?.pagination?.totalFields);
      return temp;
    } else {
      setLoading(false);
      message.error(response?.response?.statusText || "Internal server error");
    }
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const handleOnclick = () => {
    getManifest(
      pageNum,
      pageSize,
      startAndEndDate?.start,
      startAndEndDate?.end,
      null,
      filterType
    );
  };

  function handlePageChange(pagination) {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);

    getManifest(
      pageNum,
      pageSize,
      startAndEndDate?.start,
      startAndEndDate?.end,
      null,
      filterType
    );
  }

  const handleDateChange = (prarms, p2) => {
    const StartDate = moment(p2[0], "DD/MM/YYYY").format("DD-MM-YYYY");
    const endDate = moment(p2[1], "DD/MM/YYYY").format("DD-MM-YYYY");
    setStartAndEndDate({ start: StartDate, end: endDate });
    if (p2[0]?.length === 0) {
      // getTableData();
      setDateRangeStatus(false);
    } else {
      setDateRangeStatus(true);
    }

    // getManifest(pageNum, pageSize, StartDate, endDate);
  };

  const handleInputChange = (e) => {
    setSerachValue(e.target.value);
  };

  const handleCallBack = (e) => {
    getManifest(
      pageNum,
      pageSize,
      startAndEndDate?.start,
      startAndEndDate?.end
    );
  };

  //

  return (
    <>
      <div className="consolidated-manifest-cntr m-1">
        {/* <ExpectionModal
          visible={visible}
          setVisible={setVisible}
          handleCallBack={handleCallBack}
          type="bag"
        /> */}
        <BagExpectionModal
          visible={visible}
          setVisible={setVisible}
          handleCallBack={handleCallBack}
          type="bag"
        />
        <EditExpectionModal
          editVisible={editVisible}
          setEditVisible={setEditVisible}
          handleEditCallBack={handleEditCallBack}
          editAwbData={editAwbData}
        />

        <div className="new-header ml-1">
          <ExpectionSearchAndDateFilter
            serachValue={serachValue}
            handleInputChange={handleInputChange}
            handleDateChange={handleDateChange}
            dateRangeStatus={dateRangeStatus}
            handleOnclick={handleOnclick}
            filterType={filterType}
            handlefilterChange={handlefilterChange}
            total={total}
            onClicked={() => setVisible(true)}
            bag={true}
            options={[
              {
                value: "DAMAGE",
                label: "Damage",
              },
              {
                value: "MISSROUTE",
                label: "Miss Route",
              },
              {
                value: "ROUTE_NOT_FOUND",
                label: "Route Not Found",
              },
              {
                value: "ALL",
                label: "All",
              },
            ]}
          />
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          // dataSource={dataSource2}
          scroll={{
            x: 2000,
            y: 500,
          }}
          onChange={handlePageChange}
          pagination={{
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            pageSizeOptions: [50, 100, 150, 200, 500],
            pageSize: pageSize,
            total: total,
            itemRender: itemRender,
            current: pageNum,
          }}
          // rowClassName={(record) => !record?.lost?.length && "disabled-row"}
          loading={{
            indicator: (
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            ),

            spinning: loading,
          }}
        />
      </div>
    </>
  );
};

export default BagShortageActionReport;
