import React from "react";
import { Drawer, Button, Row, Col } from "antd";
import AppHeader from "../AppHeader";
import menuCloseIcon from "../../assests/images/SVG/menuclose.svg";
import appLogo from "../../assests/images/smcsLogo.svg";
import "./style.scss";

const Navbar = ({
  menu,
  showLogo,
  visibleDrawer,
  handleOpenDrawer = () => {},
  handleDrawerClose = () => {},
  onClose = () => {},
}) => {
  return (
    <nav className="navbar">
      <Drawer
        title={
          <>
            <Row className="title-container">
              <Col span={1}></Col>
              <Col span={6}>
                <Button
                  className="menu"
                  // style={{ backgound: "transparent" }}
                  icon={<img src={menuCloseIcon} />}
                  onClick={handleDrawerClose}
                />
              </Col>
              <Col span={15}>
                <div>
                  <img src={appLogo} />
                </div>
              </Col>
            </Row>
          </>
        }
        placement="left"
        onClose={onClose}
        open={visibleDrawer}
        width={283}
        closable={false}
        className="menu-drawer"
      >
        <span onClick={() => onClose()}>
          <div className="expanded-side-container">{menu}</div>
        </span>
      </Drawer>
      <AppHeader showLogo={showLogo} handleOpenDrawer={handleOpenDrawer} />
    </nav>
  );
};
export default Navbar;
