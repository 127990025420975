/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { message } from "antd";
import API from "../../../../api";
import urls from "../../../../api/urls";
import moment from "moment";

const useEwayBill = (
  form,
  allEwayBills,
  setAllEwayBills = () => {},
  handleLoader = () => {}
) => {
  const { EWAYBILL } = urls;

  // eslint-disable-next-line no-undef
  const transporterId = process.env.REACT_APP_ENV_TRANSPORTER_ID;

  const currentDate = moment();
  const todayDateNTimeFormat = currentDate.format("DD/MM/YYYY hh:mm:ss A");

  const eWayBillHandle = async (value, key, index) => {
    if (value?.length === 12) {
      handleLoader(true);

      const isPresentInOtherIndex = allEwayBills?.some(
        (item) => item !== allEwayBills[key] && item?.ewayBillNumber === value
      );

      let errorFields = [];

      let response = await API.Api(
        EWAYBILL + "/" + value
      ).getWithPremiseIdHeader();
      if (response?.status) {
        handleLoader(false);
        let data = JSON.parse(response?.response?.data?.data);
        const validilityDate = moment(data?.validUpto, "DD/MM/YYYY");
        const todayDateNTime = moment(todayDateNTimeFormat, "DD/MM/YYYY");

        if (allEwayBills[index]?.ewayBillNumber !== value) {
          if (isPresentInOtherIndex) {
            errorFields.push({
              name: [`ewayBill_${key}`],
              errors: ["E-way bill already exists"],
            });
          }
        }

        if (data?.errorCodes) {
          errorFields.push({
            name: [`ewayBill_${key}`],
            errors: ["Please give correct E-way bill number"],
          });
        }

        if (!data?.errorCodes) {
          if (todayDateNTime.isAfter(validilityDate)) {
            errorFields.push({
              name: [`ewayBill_${key}`],
              errors: ["E-way bill expired"],
            });
          } else if (data?.transporterId !== transporterId) {
            errorFields.push({
              name: [`ewayBill_${key}`],
              errors: ["Transporter id is not matching"],
            });
          }
        }
        if (errorFields.length > 0) {
          form.setFields(errorFields);
          updateErrorEwayBillAtIndex(
            key,
            errorFields[errorFields?.length - 1].errors[0]
          );
        } else {
          updateVerifiedEwayBillAtIndex(key, data, value);
          return Promise.resolve(
            message.success("E-way bill request succeeds")
          );
        }
      } else {
        handleLoader(false);
        message.error("Network error");
      }
    }
    // else {
    //   allEwayBills[index] = {
    //     expiryDate: "",
    //     ewayBillCreateDate: "",
    //     ewayBillNumber: "",
    //     id: `ewayBill_${Math.random()}`,
    //   };
    // }
  };

  const updateErrorEwayBillAtIndex = (name, errorMsg) => {
    setAllEwayBills((prevData) => {
      return prevData?.map((field, index) => {
        if (field?.id === name) {
          return {
            id: name,
            error: true,
            errorMsg: errorMsg,
          };
        }
        return field;
      });
    });
  };

  const updateVerifiedEwayBillAtIndex = (name, newData, ewayBillNumber) => {
    setAllEwayBills((prevData) => {
      return prevData?.map((field, index) => {
        if (field?.id === name) {
          return {
            id: name,
            error: false,
            name: "Shree Maruti",
            transMode:
              newData?.VehiclListDetails[0]?.transMode == "1" ? "Road" : "Air",
            expiryDate: moment(
              newData?.validUpto,
              "DD/MM/YYYY h:mm:ss A"
            ).format("YYYY-MM-DD HH:mm:ss"),
            ewayBillCreateDate: moment(
              newData?.ewayBillDate,
              "DD/MM/YYYY h:mm:ss A"
            ).format("YYYY-MM-DD HH:mm:ss"),
            ewayBillNumber,
          };
        }
        return field;
      });
    });
  };

  return {
    eWayBillHandle,
  };
};

export default useEwayBill;
