import React from "react";
import white_bag from "../../assests/images/middleMile/white-bag.svg";
import shipment from "../../assests/images/middleMile/shipment.png";
const ScannedShipment = ({
  // filterType,
  scannedData,
  totalData,
  scannedColor,
  slug = "",
  type,
}) => {
  return (
    <span
      className="fw-700 mr-1"
      style={{
        background: "#D9E7F9",
        padding: slug === "/consolidated-manifest" ? "3px 15px" : "10px 15px",
        borderRadius: "5px",
      }}
    >
      {type === "bag" ? (
        <img
          src={white_bag}
          alt="white bag"
          style={{ width: "25px", height: "25px", marginTop: "-5px" }}
        />
      ) : (
        <img
          src={shipment}
          alt="shipment"
          style={{ width: "25px", height: "25px", marginTop: "-5px" }}
        />
      )}
      <span
        style={{
          color: scannedColor ? scannedColor : "#52AD50",
          fontSize: slug === "/consolidated-manifest" ? "0.87rem" : "0.92rem",
        }}
      >
        {"Total: "}
        {/* {`${
          slug === "/consolidated-manifest"
            ? `Total ${filterType === "both" ? " " : filterType}:`
            : "Scanned: "
        }`} */}
        <span
          style={{
            fontSize: slug === "/consolidated-manifest" ? "0.87rem" : "0.92rem",
          }}
        >
          {scannedData ? scannedData : 0}
        </span>
      </span>
      &nbsp;/&nbsp;
      <span
        style={{
          color: "#000",
          fontSize: slug === "/consolidated-manifest" ? "0.87rem" : "0.92rem",
        }}
      >
        <span
          style={{
            fontSize: slug === "/consolidated-manifest" ? "0.87rem" : "0.92rem",
          }}
        >
          {totalData ? totalData : 0}
        </span>
      </span>
    </span>
  );
};
export default ScannedShipment;
