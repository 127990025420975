/* eslint-disable no-unused-vars */
import { Button, Modal, Row, Col } from "antd";
import React, { useState } from "react";
import crossButton from "../../../assests/images/CrossButton.svg";
const ProceedModal = ({ visible, handleCancel, handleOk, unScannedAwbs }) => {
  return (
    <>
      <Modal
        open={visible}
        onOk={handleOk}
        className="unscanned-modal"
        onCancel={handleCancel}
        maskClosable={false}
        width={600}
        okText={"Save"}
        closeIcon={<img src={crossButton} />}
        footer={[
          <Button key="back" onClick={handleCancel} className="cancelButton">
            No
          </Button>,
          <Button
            key="submit"
            type="primary"
            // disabled={true}
            onClick={handleOk}
            // className={fileList.length ? "uploadButton" : "uploadButtonDisable"}
          >
            Yes
          </Button>,
        ]}
      >
        <Row className="awb-container" justify="center">
          <Col>
            <p> AWB numbers </p>
            {unScannedAwbs + ","}
            <p> are not scanned. Do you want to remove and proceed? </p>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default ProceedModal;
