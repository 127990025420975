import React from "react";
import Lottie from "react-lottie";
import * as errorLoader from "./error.json";
const ErrorLoader = () => {

  let defaultOptions = {
    loop: true,
    autoPlay: true,
    animationData: errorLoader.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <>
      <div className="">
        <div className='mt-3'>
          <Lottie
            options={defaultOptions}
            height={450}
            width={450}
          />
        </div>
      </div>
    </>
  );
};
export default ErrorLoader;
