/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Form, Spin } from "antd";

import { capitaliseWithHyphen, dateFormat_dmy } from "../../../../utils";

import { TripViewForm } from "./formViewCalender";

import "./style.scss";

const TripView = ({ viewTripData }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [kindOfJourney, setKindOfJourney] = useState("");

  const [allForwardTransitData, setallForwardTransitData] = useState([]);
  const [allReturnTransitData, setAllReturnTransitData] = useState([]);

  let viewTripData2 = {
    id: "e0ca7108-08b2-4d50-8da7-494095c17d17",
    tripId: "DEL02102023095127",
    position: null,
    journeyType: "both",
    vehicleId: "DL98TH5643",
    vehicleType: "MGV",
    vehicleCapacity: 500,
    driverName: "Ashutosh Panda",
    driverNumber: "8765456788",
    driverId: "24d3578f-e9ea-4d7d-82de-4e77414b6b05",
    routeId: "d4309b20-e37b-4360-9b1b-f3995cbec500",
    routeName: "DELHI GANDHINAGAR HUB-RAJKOT HUB",
    scheduleStartDate: "04-10-2023",
    scheduleStartTime: "00:30",
    forwardSchedule: [
      {
        position: 0,
        premiseName: "DELHI GANDHINAGAR HUB",
        arrivalDate: "04-10-2023",
        arrivalTime: "00:30:00",
        departureDate: "04-10-2023",
        departureTime: "00:50:00",
        loadedWeight: 30,
        unLoadedWeight: null,
        actualArrivalDate: null,
        actualArrivalTime: null,
        actualDepartureDate: null,
        actualDepartureTime: null,
        reasonForDelay: null,
        loadCapacity: 100,
        utilizationPercent: 60,
      },
      {
        position: 1,
        premiseName: "RAJKOT HUB",
        arrivalDate: "04-10-2023",
        arrivalTime: "02:38:00",
        departureDate: "04-10-2023",
        departureTime: "02:58:00",
        loadedWeight: 40,
        unLoadedWeight: 50,
        actualArrivalDate: null,
        actualArrivalTime: null,
        actualDepartureDate: null,
        actualDepartureTime: null,
        reasonForDelay: null,
        utilizationPercent: 90,
      },
    ],
    returnSchedule: [
      {
        position: 0,
        premiseName: "RAJKOT HUB",
        arrivalDate: "04-10-2023",
        arrivalTime: "02:38:00",
        departureDate: "04-10-2023",
        departureTime: "02:58:00",
        loadedWeight: null,
        unLoadedWeight: null,
        actualArrivalDate: null,
        actualArrivalTime: null,
        actualDepartureDate: null,
        actualDepartureTime: null,
        reasonForDelay: null,
        utilizationPercent: 30,
      },
      {
        position: 1,
        premiseName: "DELHI GANDHINAGAR HUB",
        arrivalDate: "04-10-2023",
        arrivalTime: "04:46:00",
        departureDate: "04-10-2023",
        departureTime: "05:06:00",
        loadedWeight: null,
        unLoadedWeight: null,
        actualArrivalDate: null,
        actualArrivalTime: null,
        actualDepartureDate: null,
        actualDepartureTime: null,
        reasonForDelay: null,
        utilizationPercent: 20,
      },
    ],
    recursive: false,
    recursiveType: "daily",
    days: [null],
    noOfRecursion: 1,
  };

  useEffect(() => {
    form.setFieldsValue({
      tripId: viewTripData?.tripId,
      journeyType: viewTripData?.journeyType,
      vehicleId: viewTripData?.vehicleId,
      vehicleType: viewTripData?.vehicleType,
      vehicleCapacity: viewTripData?.vehicleCapacity,
      driverName: viewTripData?.driverName,
      driverNumber: viewTripData?.driverNumber,
      driverId: viewTripData?.driverId,
      routeName: capitaliseWithHyphen(viewTripData?.routeName),
      noOfRecursion: viewTripData?.noOfRecursion,
      recursiveType: viewTripData?.recursiveType,
      recursive: viewTripData?.recursive,
      noOfTransits: viewTripData?.forwardSchedule?.length - 2,
      returnNoOfTransits: viewTripData?.returnSchedule?.length - 2,
      days: viewTripData?.days,
    });

    setKindOfJourney(viewTripData?.journeyType);
  }, [viewTripData]);

  useEffect(() => {
    if (!viewTripData) return;

    if (
      viewTripData.journeyType === "both" ||
      viewTripData.journeyType === "forward"
    ) {
      setallForwardTransitData(viewTripData?.forwardSchedule);
      changeReturnDepartureFields(viewTripData?.forwardSchedule);
    }

    if (
      viewTripData?.journeyType === "both" ||
      viewTripData?.journeyType === "return"
    ) {
      setAllReturnTransitData(viewTripData?.returnSchedule);
      changeReturnDepartureFields(viewTripData?.returnSchedule);
    }
  }, [viewTripData]);

  useEffect(() => {
    if (allReturnTransitData) {
      changeReturnDepartureFields(allReturnTransitData);
    }
  }, [allReturnTransitData]);

  useEffect(() => {
    if (allForwardTransitData?.length) {
      changeForwardDepartureFields(allForwardTransitData);
    }
  }, [allForwardTransitData]);

  const changeForwardDepartureFields = (scheduleArray) => {
    for (const i in scheduleArray) {
      form.setFieldsValue({
        [`departureDate${i}`]: moment(
          scheduleArray[i]?.departureDate,
          dateFormat_dmy
        ),
        [`departureTime${i}`]: moment(
          scheduleArray[i]?.departureTime,
          "HH:mm:ss"
        ),
        [`arrivalDate${i}`]: moment(
          scheduleArray[i]?.arrivalDate,
          dateFormat_dmy
        ),
        [`arrivalTime${i}`]: moment(scheduleArray[i]?.arrivalTime, "HH:mm:ss"),

        //actuall
        [`actualArrivalDate${i}`]: moment(
          scheduleArray[i]?.actualArrivalDate,
          dateFormat_dmy
        ),
        [`actualArrivalTime${i}`]: moment(
          scheduleArray[i]?.actualArrivalTime,
          "HH:mm:ss"
        ),
        [`actualDepartureDate${i}`]: moment(
          scheduleArray[i]?.actualDepartureDate,
          dateFormat_dmy
        ),
        [`actualDepartureTime${i}`]: moment(
          scheduleArray[i]?.actualDepartureTime,
          "HH:mm:ss"
        ),
      });
    }
  };

  const changeReturnDepartureFields = (scheduleArray) => {
    for (const i in scheduleArray) {
      form.setFieldsValue({
        [`returnArrivalDate${i}`]: moment(
          scheduleArray[i]?.arrivalDate,
          dateFormat_dmy
        ),
        [`returnArrivalTime${i}`]: moment(
          scheduleArray[i]?.arrivalTime,
          "HH:mm:ss"
        ),
        [`returnDepartureDate${i}`]: moment(
          scheduleArray[i]?.departureDate,
          dateFormat_dmy
        ),
        [`returnDepartureTime${i}`]: moment(
          scheduleArray[i]?.departureTime,
          "HH:mm:ss"
        ),

        //actual
        [`returnActualArrivalDate${i}`]: moment(
          scheduleArray[i]?.actualArrivalDate,
          dateFormat_dmy
        ),
        [`returnActualArrivalTime${i}`]: moment(
          scheduleArray[i]?.actualArrivalTime,
          "HH:mm:ss"
        ),
        [`returnActualDepartureDate${i}`]: moment(
          scheduleArray[i]?.actualDepartureDate,
          dateFormat_dmy
        ),
        [`returnActualDepartureTime${i}`]: moment(
          scheduleArray[i]?.actualDepartureTime,
          "HH:mm:ss"
        ),
      });
    }
  };

  return (
    <div className="">
      <Spin spinning={loading}>
        <TripViewForm
          form={form}
          tripData={viewTripData}
          kindOfJourney={kindOfJourney}
          allForwardTransitData={allForwardTransitData}
          allReturnTransitData={allReturnTransitData}
        />
      </Spin>
    </div>
  );
};

export default TripView;
