import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Select,
  Form,
  Input,
  Button,
  message,
  Popconfirm,
  Radio,
} from "antd";
import { useDispatch } from "react-redux";
import { QuestionCircleOutlined } from "@ant-design/icons";

import MasterModal from "../../../components/Master/Modal";
import { updatePincode } from "../../../actionsMethods/masterMethods";

import x_icon from "../../../assests/images/SVG/removeItem.svg";
import "./style.scss";

const EditFormModal = ({
  setEditAreaData,
  serviceResult,
  visible,
  loading,
  editData,
  editAreaData,
  serviceabilityType,
  areaServiceType,
  editPremiumData,
  setEditPremiumData,
  handleCloseModal = () => {},
  handleAreaType = () => {},
}) => {
  const dispatch = useDispatch();

  const [editForm] = Form.useForm();
  const { Option } = Select;

  const [serviceability, setServiceability] = useState();
  const mileHours = [24, 48, 72, 96];
  const [delIds, setDelIds] = useState([]);
  const [selectedMile, setSeletedMile] = useState(editData?.fmlm);
  const [serviceType, setServiceType] = useState(1);
  const [cpAreas, setcpAreas] = useState([]);

  useEffect(() => {
    editForm.resetFields();
    setEditAreaData([]);
    setEditPremiumData([]);
  }, [visible]);

  useEffect(() => {
    editForm.setFieldsValue({
      pinCode: editData?.pincode,
      stateName: editData?.stateName,
      stateCode: editData?.stateCode,
      city: editData?.city,
      zone: editData?.zone,
      mile: editData?.fmlm,
      serviceability: editData?.serviceability?.serviceability,
      ServiceabilityType: editData?.pincodeType?.pincodeType,
      areaType: areaServiceType,
    });

    setServiceability(editData?.serviceability?.serviceableId);
  }, [editData, visible, areaServiceType]);

  const handleAreaChange = (value, type) => {
    let inputvalue = value.target.value;

    function capitalizeFirstLetter(str) {
      const capitalized = str?.charAt(0)?.toUpperCase() + str?.slice(1);
      return capitalized;
    }

    if (inputvalue?.length > 1) {
      const isDuplicateRegular = editAreaData.some(
        (val) => val?.areaName?.toLowerCase() === inputvalue?.toLowerCase()
      );

      const isDuplicatePremium = editPremiumData?.some(
        (val) => val?.areaName?.toLowerCase() === inputvalue?.toLowerCase()
      );

      if (isDuplicateRegular || isDuplicatePremium) {
        const fieldName = isDuplicateRegular ? "Regular area" : "Premium area";
        editForm.setFieldValue(fieldName, "");
        message.error("Area name already exists");
      } else {
        if (type === "Regular") {
          setEditAreaData((prevState) => [
            ...prevState,
            {
              areaName: capitalizeFirstLetter(inputvalue),
              areatype: "regular",
            },
          ]);
        } else if (type === "Premium") {
          setEditPremiumData((prevState) => [
            ...prevState,
            {
              areaName: capitalizeFirstLetter(inputvalue),
              areatype: "premium",
            },
          ]);
        }
      }
    }
    editForm.setFieldValue("Regular area", "");
    editForm.setFieldValue("Premium area", "");
  };

  const handleEditSave = () => {
    const temp = [];

    if (
      (areaServiceType === "Regular" || areaServiceType === "Both") &&
      editAreaData.length > 0
    ) {
      editAreaData.forEach((val) => {
        temp.push(val);
      });
    }

    if (
      (areaServiceType === "Premium" || areaServiceType === "Both") &&
      editPremiumData?.length > 0
    ) {
      editPremiumData?.forEach((val) => {
        temp.push(val);
      });
    }

    if (temp?.length > 0) {
      let updatePayload = {
        serviceability: {
          serviceableId: parseInt(serviceability),
        },
        fmlm: +selectedMile || 24,
        deleteAreaId: delIds?.filter((val) => val !== undefined) || [],
        pincodeType: {
          typeId: serviceType,
        },
        areadto: temp,
        deleteAreafromPremise: cpAreas,
      };
      dispatch(updatePincode(updatePayload, editData?.pincode));
      setDelIds([]);
    } else {
      message.error("Area cannot be empty");
    }
  };

  function handleAreaDel(params, ids, type, allocated) {
    if (type == "Regular") {
      let delAreas = editAreaData?.filter((val, idx) => params !== idx);
      setEditAreaData(delAreas);
    } else {
      let delPremiumAreas = editPremiumData?.filter(
        (val, idx) => params !== idx
      );
      setEditPremiumData(delPremiumAreas);
    }

    setDelIds([...delIds, ids]);
    if (allocated) {
      setcpAreas([...delIds, ids]);
    }
  }

  function handleType(item) {
    let type = item?.label[0]["props"]["value"];
    setServiceability(type);
  }

  function handleServiceType(item) {
    let typeId = item?.label[0]["props"]["value"];
    setServiceType(typeId);
  }

  function handleMile(params) {
    setSeletedMile(params.label[1]?.replace("hrs", ""));
  }

  const formProps = {
    name: "address",
    onFinish: handleEditSave,
    layout: "vertical",
    autoComplete: "off",
  };

  function showAreaFormItem(params, data) {
    return (
      <>
        <Col span={12}>
          <Form.Item
            name={params + " area"}
            label={params + " area"}
            rules={[
              {
                required:
                  params == "Regular"
                    ? editAreaData?.length == 0
                    : editPremiumData?.length == 0,
                message: "Area is required",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              className="tag-items"
              placeholder="Enter area name"
              onPressEnter={(e) => handleAreaChange(e, params)}
              onKeyPress={(e) => {
                if (/[^A-Za-z0-9-/ ]/.test(e.key)) {
                  e.preventDefault();
                }
                if (e.target.value.length >= 20) {
                  e.preventDefault();
                }
              }}
              onKeyDown={(e) => {
                if (!/[a-zA-Z0-9]/.test(e.target.value)) e.target.value = "";
              }}
            />
          </Form.Item>

          {data?.length > 0 && (
            <div className="area-list-box mb-1">
              {data?.map((val, idx) => {
                return (
                  <>
                    {val.allocated ? (
                      <Popconfirm
                        title={
                          <span
                            className="test"
                            style={{
                              textTransform: "initial",
                            }}
                          >
                            This area is mapped to a CP, do you wish to proceed?
                          </span>
                        }
                        cancelText="No"
                        okText="Yes"
                        onConfirm={() =>
                          handleAreaDel(
                            idx,
                            val?.areaId,
                            params,
                            val?.allocated
                          )
                        }
                        icon={
                          <QuestionCircleOutlined
                            style={{
                              color: "red",
                            }}
                          />
                        }
                      >
                        <div className="area-list-item" key={idx}>
                          {val?.areaName}
                          <span
                            className="pointer"
                            style={{ marginLeft: "10px" }}
                          >
                            <img src={x_icon} alt="X" width={11} />
                          </span>
                        </div>
                      </Popconfirm>
                    ) : (
                      <div className="area-list-item" key={idx}>
                        {val?.areaName}
                        <span
                          onClick={() =>
                            handleAreaDel(idx, val?.areaId, params)
                          }
                          className="pointer"
                          style={{ marginLeft: "10px" }}
                        >
                          <img src={x_icon} alt="X" width={11} />
                        </span>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          )}
        </Col>
      </>
    );
  }

  return (
    <MasterModal
      title={"Edit PIN Code Details"}
      visible={visible}
      handleCloseModal={handleCloseModal}
      className={"pincode-master-modal"}
      width={800}
      loading={loading}
      footer={
        <>
          <div className="d-flex flex-end" style={{ padding: "1rem 0 0 0" }}>
            <Button className="cancel-btn w-13" onClick={handleCloseModal}>
              CANCEL
            </Button>
            <Button
              htmlType="submit"
              onClick={editForm.submit}
              className="save-btn ml-1 w-13"
            >
              SAVE
            </Button>
          </div>
        </>
      }
      content={
        <div>
          <Form {...formProps} form={editForm} className="global-form">
            <Row
              justify="space-evenly"
              span={24}
              gutter={20}
              className="global-form-row"
            >
              <Col span={12}>
                <Form.Item
                  name="pinCode"
                  label="PIN code"
                  rules={[
                    {
                      message: null,
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Enter PIN code" maxLength={6} disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="PIN code type"
                  name="serviceability"
                  rules={[
                    {
                      required: true,
                      message: "PIN code type is required",
                    },
                  ]}
                >
                  <Select
                    labelInValue
                    className="app-selector"
                    placeholder={"Select type"}
                    onChange={handleType}
                  >
                    {serviceResult?.map((val, idx) => {
                      return (
                        <div key={idx}>
                          <Option
                            className="dropDown"
                            value={val?.serviceableId}
                          ></Option>
                          <span>{val?.serviceability}</span>
                        </div>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Serviceability type"
                  name="ServiceabilityType"
                  rules={[
                    {
                      required: true,
                      message: "Service type is required",
                    },
                  ]}
                >
                  <Select
                    labelInValue
                    onChange={handleServiceType}
                    className="app-selector"
                    placeholder={"Select Service type"}
                  >
                    {serviceabilityType
                      ?.sort((a, b) => b?.typeId - a?.typeId)
                      ?.map((val, idx) => {
                        return (
                          <div key={idx}>
                            <Option
                              className="drapDown"
                              value={val?.typeId}
                            ></Option>
                            {val?.pincodeType.includes(",")
                              ? "All"
                              : val?.pincodeType}
                          </div>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="city" label="City" className="bookingAreaItem">
                  <Input placeholder="City name" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={11}>
                    <Form.Item
                      name="stateName"
                      label="State name"
                      className="bookingAreaItem"
                    >
                      <Input placeholder="Select state" disabled />
                    </Form.Item>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Form.Item
                      name="stateCode"
                      label="State code"
                      className="bookingAreaItem"
                    >
                      <Input placeholder="Select code" disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={12}>
                <Form.Item name="zone" label="Zone" className="bookingAreaItem">
                  <Input placeholder="Zone" disabled />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name={"areaType"}
                  label="Select area type"
                  rules={[
                    {
                      message: "Select area type",
                      required: true,
                    },
                  ]}
                >
                  <Radio.Group onChange={handleAreaType}>
                    <Radio value={"Regular"}>Regular</Radio>
                    <Radio value={"Premium"}>Premium</Radio>
                    <Radio value={"Both"}>Both</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              {(areaServiceType === "Regular" || areaServiceType === "Both") &&
                showAreaFormItem("Regular", editAreaData)}

              {(areaServiceType === "Premium" || areaServiceType === "Both") &&
                showAreaFormItem("Premium", editPremiumData)}

              <Col span={12}>
                <Form.Item
                  label="First mile & Last mile TAT "
                  name="mile"
                  rules={[
                    {
                      required: true,
                      message: "First mile & Last mile TAT is required",
                    },
                  ]}
                >
                  <Select
                    labelInValue
                    className="app-selector"
                    placeholder={"Select time"}
                    onChange={handleMile}
                  >
                    {mileHours &&
                      mileHours.map((val, idx) => {
                        return (
                          <div key={idx}>
                            <Option className="dropDown" value={val}></Option>
                            {val + " " + "hrs"}
                          </div>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={11}></Col>
            </Row>
          </Form>
        </div>
      }
    ></MasterModal>
  );
};

export default EditFormModal;
