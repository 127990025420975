/* eslint-disable no-unused-vars */
import pin_code_master from "../assests/images/master/pin-code-master.svg";
import pin_code_mapping from "../assests/images/master/pin-code-mapping.svg";
import packeting_guide from "../assests/images/master/packeting-guide.svg";
import sorting_guide from "../assests/images/master/sorting-guide.svg";
import rate_card from "../assests/images/master/rate-card.svg";
import route from "../assests/images/master/route.svg";
import tat from "../assests/images/master/tat.svg";
import other_setting from "../assests/images/master/other-setting.svg";
import cp_onboarding from "../assests/images/globalSetting/cp-onboarding.svg";
import vehicle_onboarding from "../assests/images/globalSetting/vehicle-onboarding.svg";
import client_onboarding from "../assests/images/globalSetting/client-onboarding.svg";
import staff_onboarding from "../assests/images/globalSetting/staff-onboarding.svg";
import hub_onboarding from "../assests/images/globalSetting/hub-onboarding.svg";
import lcrInScan from "../assests/images/middleMile/lcr-in-scan.svg";
import lcrGen from "../assests/images/middleMile/lcr-gen.svg";
import bagging from "../assests/images/middleMile/bagging.svg";
import Sorting from "../assests/images/middleMile/sorting.svg";
import AddCategory from "../assests/images/addCategory.svg";
import openBag from "../assests/images/middleMile/open-bag.svg";
import openPackets from "../assests/images/middleMile/open-packet.svg";
import sortToCp from "../assests/images/middleMile/sort-to-cp.svg";
import mm_inscan from "../assests/images/SVG/mm-in-scan.svg";
import report_vehicle from "../assests/images/SVG/vehicle-utilize.svg";
import packeting from "../assests/images/SVG/packeting.svg";
import my_order from "../assests/images/my-order.svg";
import product_request from "../assests/images/product-request.svg";
import orderRequest from "../assests/images/OrderRequest.svg";
import addProduct from "../assests/images/addProduct.svg";
import createDelivery from "../assests/images/SVG/createDelivery.svg";
import generateDRS from "../assests/images/SVG/generateDRS.svg";
import constants from "../constants/constants";
import checkin_checkout from "../assests/images/middleMile/checkin.svg";
import expection_report from "../assests/images/middleMile/expection-report.svg";
import vehicle_master from "../assests/images/SVG/vehicle-master.svg";

import cp_Approval from "../assests/images/SVG/cp_approval.svg";
import Cp_lead from "../assests/images/SVG/cp_lead-gen.svg";
import client_approval from "../assests/images/SVG/client_approval.svg";
import client_lead from "../assests/images/SVG/client_lead_gen.svg";
import branch_approval from "../assests/images/SVG/branch_approval.svg";
import hubops_config from "../assests/images/hubops-configure.svg";

import misrouted_shipment from "../assests/images/misrouted-shipment.svg";
import billing_configuration from "../assests/images/master/billing_configuration.svg";
import create_trip from "../assests/images/middleMile/create-trip.svg";
import LCRThrshold from "../assests/images/LCRThrshold.svg";

const { ROLES } = constants;
const { CP_ADMIN, HO_ADMIN, HUB_ADMIN, ROLE_HOMARKETING } = ROLES;

export const parentMaster = [
  {
    mainRoute: "/masters",
    masterProperty: {
      headingTitle: "Master Settings",
      searchPlaceholder: "Search master setting",
      searchPresent: true,
    },
    masterData: [
      {
        id: 1,
        imgSrc: pin_code_master,
        title: "PIN code master",
        route: "/pincode-master",
        disable: false,
        childPresent: false,
        role: [HO_ADMIN],
      },
      {
        id: 2,
        imgSrc: pin_code_mapping,
        title: "PIN code mapping",
        route: "/pin-code-mapping",
        disable: false,
        childPresent: false,
        role: [HO_ADMIN],
      },
      {
        id: 3,
        imgSrc: packeting_guide,
        title: "packeting guide",
        route: "/packeting-guide",
        disable: false,
        childPresent: false,
        role: [HO_ADMIN],
      },
      {
        id: 4,
        imgSrc: sorting_guide,
        title: "sorting guide",
        route: "/sorting-guide",
        disable: false,
        childPresent: false,
        role: [HO_ADMIN],
      },
      {
        id: 5,
        imgSrc: rate_card,
        title: "rate card master",
        disable: false,
        childPresent: true,
        role: [HO_ADMIN],
      },
      {
        id: 6,
        imgSrc: route,
        title: "route master",
        route: "/route-configuration",
        disable: false,
        childPresent: false,
        role: [HO_ADMIN],
      },
      {
        id: 7,
        imgSrc: tat,
        title: "TAT master",
        disable: false,
        childPresent: true,
        role: [HO_ADMIN],
      },
      {
        id: 8,
        imgSrc: other_setting,
        title: "others setting",
        route: "",
        disable: true,
        childPresent: false,
        role: [HO_ADMIN],
      },
      {
        id: 9,
        imgSrc: billing_configuration,
        title: "Billing Configuration",
        route: "",
        disable: false,
        childPresent: true,
        role: [HO_ADMIN],
      },
      {
        id: 10,
        imgSrc: hubops_config,
        title: "HubOps Configurations",
        route: "",
        disable: false,
        childPresent: true,
        role: [HO_ADMIN],
      },
      {
        id: 11,
        imgSrc: route,
        title: "route master",
        route: "/hub-route-configuration",
        disable: false,
        childPresent: false,
        role: [HUB_ADMIN],
      },
      // {
      //   id: 11,
      //   imgSrc: route,
      //   title: "HubOps Configurations",
      //   route: "/hub-ops-configuration",
      //   disable: false,
      //   childPresent: false,
      //   role: [HUB_ADMIN],
      // },
    ],
  },
  {
    mainRoute: "/onboarding-settings",
    masterProperty: {
      headingTitle: "Onboard Settings",
      searchPlaceholder: "Search onboard setting",
      searchPresent: true,
    },
    masterData: [
      {
        id: 1,
        imgSrc: staff_onboarding,
        title: "Staff Onboarding",
        route: "/staff-onboarding",
        disable: false,
        childPresent: false,
        role: [CP_ADMIN, HO_ADMIN, HUB_ADMIN],
      },
      {
        id: 2,
        imgSrc: cp_onboarding,
        title: "CP Onboarding",
        route: "/cp-onboarding",
        disable: false,
        childPresent: false,
        role: [HO_ADMIN],
      },
      {
        id: 3,
        imgSrc: vehicle_onboarding,
        title: "Vehicle Onboarding",
        route: "/vehicle-onboard",
        disable: false,
        childPresent: false,
        role: [CP_ADMIN, HUB_ADMIN],
      },
      {
        id: 4,
        imgSrc: hub_onboarding,
        title: "Hub Onboarding",
        route: "/hub-onboarding",
        disable: false,
        childPresent: false,
        role: [HO_ADMIN],
      },
      // {
      //   id: 6,
      //   imgSrc: client_approval,
      //   title: "Client Approval",
      //   route: "/client-lead-generation-list",
      //   disable: true,
      //   childPresent: false,
      //   role: [HO_ADMIN],
      // },
      // {
      //   id: 6,
      //   imgSrc: cp_Approval,
      //   title: "Approval",
      //   route: "/cp-approval-lead-list",
      //   disable: false,
      //   childPresent: false,
      //   role: [HO_ADMIN],
      // },
      {
        id: 6,
        imgSrc: cp_Approval,
        title: "Approval",
        route: "/all-approvals",
        disable: false,
        childPresent: false,
        role: [HO_ADMIN],
      },
      // {
      //   id: 6,
      //   imgSrc: branch_approval,
      //   title: "Branch Approval",
      //   route: "/client-list",
      //   disable: true,
      //   childPresent: false,
      //   role: [HO_ADMIN],
      // },
      // {
      //   id: 7,
      //   imgSrc: client_onboarding,
      //   title: "Lead Approval",
      //   route: "/cp-approval-lead-list",
      //   disable: false,
      //   childPresent: false,
      //   role: [HO_ADMIN],
      // },
      {
        id: 8,
        imgSrc: vehicle_master,
        title: "Vehicle Master",
        route: "/vehicle-master",
        disable: false,
        childPresent: false,
        role: [HUB_ADMIN],
      },
      {
        id: 8,
        imgSrc: Cp_lead,
        title: "CP Lead Generation",
        route: "/cp-lead-generation-list",
        disable: false,
        childPresent: false,
        role: [ROLE_HOMARKETING],
      },
      {
        id: 9,
        imgSrc: client_lead,
        title: "DP Lead Generation",
        route: "/dp-lead-generation-list",
        role: [ROLE_HOMARKETING],
      },
      // {
      //   id: 10,
      //   imgSrc: client_lead,
      //   title: "Client Lead Generation",
      //   route: "/client-lead-generation-list",
      //   role: [CP_ADMIN],
      // },
      // {
      //   id: 10,
      //   imgSrc: client_lead,
      //   title: "Client Lead Generation",
      //   route: "/client-lead-generation-list",
      //   role: [CP_ADMIN],
      // },
    ],
  },
  {
    mainRoute: "/middle-mile",
    masterProperty: {
      headingTitle: "Hub Outbound",
      searchPlaceholder: "Search hub outbound",
      searchPresent: true,
    },
    masterData: [
      {
        id: 1,
        imgSrc: mm_inscan,
        title: "In Scan",
        route: "/consolidated-manifest",
        disable: false,
        childPresent: false,
        role: [HUB_ADMIN],
      },
      {
        id: 2,
        imgSrc: Sorting,
        title: "Sort Shipment",
        route: "/sort-shipment",
        disable: false,
        childPresent: false,
        role: [HUB_ADMIN],
      },
      // {
      //   id: 3,
      //   imgSrc: packeting,
      //   title: "Packeting",
      //   route: "/packeting",
      //   disable: false,
      //   childPresent: false,
      //   role: [HUB_ADMIN],
      // },
      {
        id: 4,
        imgSrc: bagging,
        title: "Bagging",
        route: "/shipment-bagging",
        disable: false,
        childPresent: false,
        role: [HUB_ADMIN],
      },
      {
        id: 5,
        imgSrc: lcrGen,
        title: "LCR Generation",
        route: "/create-or-update-trip",
        disable: false,
        childPresent: false,
        role: [HUB_ADMIN],
      },
      {
        id: 6,
        imgSrc: create_trip,
        title: "LCR View",
        route: "/new-lcr-generation",
        disable: false,
        childPresent: false,
        role: [HUB_ADMIN],
      },
      // {
      //   id: 6,
      //   imgSrc: misrouted_shipment,
      //   title: "Exceptions",
      //   route: "/excess-misrouted",
      //   disable: false,
      //   childPresent: false,
      //   role: [HUB_ADMIN],
      // },
    ],
  },
  {
    mainRoute: "/hub-in-scan",
    masterProperty: {
      headingTitle: "Hub Inbound",
      searchPlaceholder: "Search hub inbound",
      searchPresent: true,
    },
    masterData: [
      {
        id: 1,
        imgSrc: lcrInScan,
        title: "LCR IN-Scan",
        route: "/lcr-scan-list",
        disable: false,
        childPresent: false,
        role: [HUB_ADMIN],
      },
      // {
      //   id: 2,
      //   imgSrc: openBag,
      //   title: "Open Bags",
      //   route: "/lcr-scanned-bags",
      //   disable: false,
      //   childPresent: false,
      //   role: [HUB_ADMIN],
      // },
      // {
      //   id: 3,
      //   imgSrc: openPackets,
      //   title: "Open Packets",
      //   route: "/scan-packets",
      //   disable: false,
      //   childPresent: false,
      //   role: [HUB_ADMIN],
      // },

      {
        id: 4,
        imgSrc: sortToCp,
        title: "Sort to Last Mile",
        route: "/sort-to-cp",
        disable: false,
        childPresent: false,
        role: [HUB_ADMIN],
      },
      // {
      //   id: 5,
      //   imgSrc: generateDRS,
      //   title: "Generate Manifest",
      //   route: "/generateManifest",
      //   disable: false,
      //   childPresent: false,
      //   role: [HUB_ADMIN],
      // },
      {
        id: 5,
        imgSrc: generateDRS,
        title: "Manifest",
        route: "/generateManifest",
        disable: false,
        childPresent: false,
        role: [HUB_ADMIN],
      },
    ],
  },
  {
    mainRoute: "/configuration-guide",
    masterProperty: {
      headingTitle: "Configuration",
      searchPlaceholder: "",
      searchPresent: false,
    },
    masterData: [
      {
        id: 1,
        imgSrc: Sorting,
        title: "Sorting Guide",
        route: "/sorting-guide",
        disable: false,
        childPresent: false,
        role: [HUB_ADMIN],
      },
    ],
  },
  {
    mainRoute: "/last-mile-operations",
    masterProperty: {
      headingTitle: "Last Mile Operations",
      searchPlaceholder: "",
      searchPresent: false,
    },
    masterData: [
      {
        id: 1,
        imgSrc: mm_inscan,
        title: "In Scan Shipments",
        route: "/in-scan-cp",
        disable: false,
        childPresent: false,
        role: [CP_ADMIN],
      },

      {
        id: 2,
        imgSrc: generateDRS,
        title: "Generate DRS",
        route: "/generate-drs",
        disable: false,
        childPresent: false,
        role: [CP_ADMIN],
      },
      {
        id: 3,
        imgSrc: mm_inscan,
        title: "DRS Eligibility",
        route: "/pending-drs",
        disable: false,
        childPresent: false,
        role: [CP_ADMIN],
      },
      {
        id: 4,
        imgSrc: createDelivery,
        title: "Create Delivery Area",
        route: "/create-virtual-area",
        disable: false,
        childPresent: false,
        role: [CP_ADMIN],
      },
    ],
  },
  {
    mainRoute: "/reports",
    masterProperty: {
      headingTitle: "Reports",
      searchPlaceholder: "",
      searchPresent: false,
    },
    masterData: [
      {
        id: 1,
        imgSrc: report_vehicle,
        title: "Vehicle Utilization",
        route: "/vehicle-trip-list",
        disable: false,
        childPresent: false,
        role: [HUB_ADMIN],
      },
      {
        id: 2,
        imgSrc: checkin_checkout,
        title: "Vehicle Check-In Check-Out",
        route: "/vehicle-checkin-checkout",
        disable: false,
        childPresent: false,
        role: [HUB_ADMIN],
      },
      {
        id: 3,
        imgSrc: expection_report,
        title: "Exception Report",
        route: "/exception-report",
        disable: false,
        childPresent: false,
        role: [HUB_ADMIN],
      },
    ],
  },
];
