/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Layout, Button, Row } from "antd";
import "antd/dist/antd.css";

import SideBarOpen from "../../components/Navbar";
import AppFooter from "../../components/AppFooter";

import hamIcon from "../../assests/images/maruti/hamburgerIcon.svg";
import SideBarClosed from "../../components/SideMenu/SideMenuItems";
import { globalPath } from "../../config/globalPaths";

const { Header, Footer, Content, Sider } = Layout;

const MainLayout = ({ component }) => {
  const [menuSideBarVisible, setMenuSideBarVisible] = useState(false);
  const unauthorizedUser = window.location.pathname;

  const handleMenuSideBar = () => {
    setMenuSideBarVisible(!menuSideBarVisible);
  };

  const handleDrawerClose = () => {
    setMenuSideBarVisible(!menuSideBarVisible);
  };

  const handleOpenDrawer = () => {
    setMenuSideBarVisible(!menuSideBarVisible);
  };
  const closeDrawer = () => {
    setMenuSideBarVisible(false);
  };

  const Menu = <SideBarClosed />;
  return (
    <div className="App">
      {!globalPath.includes(unauthorizedUser) &&
      localStorage.getItem("loggedOutTime") ? (
        <Layout>
          <Sider
            width={80}
            style={{ minHeight: "100vh", color: "white" }}
            collapsible
            collapsed={false}
            trigger={null}
          >
            <div className="sidemenu-container">
              <Button onClick={handleMenuSideBar} className="hamburger">
                {menuSideBarVisible ? (
                  <img src={hamIcon} />
                ) : (
                  <Row>
                    <div>
                      <img src={hamIcon} />
                    </div>
                  </Row>
                )}
              </Button>
              <SideBarClosed    visibleDrawer={menuSideBarVisible} />
            </div>
          </Sider>

          <Layout>
            <Header>
              <SideBarOpen
                menu={Menu}
                showLogo={!menuSideBarVisible}
                visibleDrawer={menuSideBarVisible}
                handleDrawerClose={handleDrawerClose}
                handleOpenDrawer={handleOpenDrawer}
                onClose={closeDrawer}
              />
            </Header>
            <Content>{component}</Content>
            <Footer>
              <AppFooter />
            </Footer>
          </Layout>
        </Layout>
      ) : (
        <Content>{component}</Content>
      )}
    </div>
  );
};

MainLayout.propTypes = {
  component: PropTypes.node,
  userAuthorized: PropTypes.bool,
  userRole: PropTypes.string,
  handleUserRole: PropTypes.func,
};

MainLayout.defaultProps = {
  userAuthorized: false,
};

export default React.memo(MainLayout);
