/* eslint-disable no-unused-vars */
import React,{useState,useEffect} from "react";
import { Row, Col,Button,Spin,Tooltip } from "antd";
import Heading from "../../components/Heading";
import PobForm from "./PobForm";
import PobView from "./PobView";
import "./style.scss";
import API from "../../api";
import urls from "../../api/urls";
const {
  GET_POB,
  GET_POB_BY_ID
} = urls;

const index = () => {
  const [pobState,setPobState] = useState(false);
  const [stateData,setStateData] = useState({});
  const [stateName,setStateName] = useState([]);
  const [loading,setLoading] = useState(false);
  const [editData,setEditData] = useState({});
  const [indexKey,setIndexKey] = useState(null);
  useEffect(()=>{
    getPobData();
  },[]);
  const getPobData = async()=>{
    setLoading(true);
    let response = await API.Api(GET_POB).getWithPremiseIdHeader();
    if(response.status){
      let stateArray = response?.response?.data;
      stateArray?.sort((a, b) => {
        if (a?.state?.toLowerCase() === "gujarat") {
          return -1;
        } else if (b?.state?.toLowerCase() === "gujarat") {
          return 1;
        }
        return 0;
      });
      setStateName(stateArray);
      setLoading(false);
    }
  };
  const addPobHandle=(e)=>{
    setEditData(e);
    setPobState(true);
  };
  const pobViewHandle=async(id,key)=>{
    setIndexKey(key);
    setLoading(true);
    let response = await API.Api(GET_POB_BY_ID + id).getWithAllHeaders();
    if(response?.status){
      setLoading(false);
      setStateData(response?.response?.data);
      setPobState(false);
    }
  };
  return (
    <div className="p-2">
      <Spin spinning={loading}>
        <Heading title={"Add State Master"}/>
        <div className="state-master-component">
          <Row justify={"space-between"} className="state-master-header p-1 align-center">
            <Col className="state-header">State Master Details</Col>
            <Col>
              <Tooltip title="Edit">
                <Button className="cancel-btn" onClick={()=>addPobHandle({data:{},status:"addPob"})}>+ ADD PLACE OF BUSINESS</Button>
              </Tooltip>
            </Col>
          </Row>
          <Row className="state-master-container">
            <Col span={5} className="pob-name">
              {stateName?.map((data,key)=> 
                <div key={key} className={indexKey === key ? "pob p-1 center clicked" : "pob p-1 center"} onClick={()=>pobViewHandle(data?.stateMasterId,key)}>
                  {data?.state}
                </div>)
              }
            </Col>
            {pobState ?
              <PobForm editData={editData} getPobData={getPobData} pobViewHandle={pobViewHandle}/> 
              :
              stateData?.stateMasterId ? <PobView addPobHandle={addPobHandle} stateData={stateData} /> : <Col span={19}></Col>
            }
          </Row>
        </div>
      </Spin>
    </div>
  );
};

export default index;
