import actions from "../constants/masterActions";

export const getPincodeMastertable = (pageNum, pageSize) => ({
  type: actions.GET_PINCODE_MASTER_TABLE,
  pageNum,
  pageSize,
});

export const getPincode = (data) => ({
  type: actions.GET_PINCODE,
  data,
});

export const getState = (data) => ({
  type: actions.GET_STATE,
  data,
});
export const clearGetState = (data) => ({
  type: actions.GET_STATE_CLEAR,
  data,
});

export const getServiceablility = () => ({
  type: actions.GET_SERVICEABILITY,
});

export const createPincode = (data) => ({
  type: actions.CREATE_PINCODE,
  data,
});

export const clearCreatePincode = (data) => ({
  type: actions.CREATE_PINCODE_CLEAR,
  data,
});

export const updatePincode = (data, id) => ({
  type: actions.UPDATE_PINCODE,
  data,
  id,
});

export const clearUpdatePincode = () => ({
  type: actions.UPDATE_PINCODE_CLEAR,
});

export const searchPincodeMasterTable = (data) => ({
  type: actions.SEARCH_PINCODE_MASTER_TABLE,
  data,
});

export const enableDisablePincodeMaster = (data) => ({
  type: actions.ENABLE_DISABLE_PINCODE,
  data,
});

export const clearEnableDisablePincodeMaster = () => ({
  type: actions.ENABLE_DISABLE_PINCODE_CLEAR,
});

export const deletePincodeAreaMaster = (data) => ({
  type: actions.DELETE_PINCODE_AREA,
  data,
});

//--Rate Card Master--//
export const getDefaultRateCard = (data) => ({
  type: actions.GET_DEFAULT_RATE_CARD,
  data,
});

export const getCategoryBasedValue = (data) => ({
  type: actions.GET_CATEGORY_BASED_VALUE,
  data,
});

export const createCashRateCardTemplate = (data, method) => ({
  type: actions.CREATE_CASH_RATE_CARD_TEMPLATE,
  data,
  method,
});

export const clearCreateCashRateCardTemplate = (data) => ({
  type: actions.CREATE_CASH_RATE_CARD_TEMPLATE_CLEAR,
  data,
});

export const getCashRateCardTemplates = (data) => ({
  type: actions.GET_CASH_RATE_CARD_TEMPLATES,
  data,
});

export const clearCashRateCardTemplates = (data) => ({
  type: actions.GET_CASH_RATE_CARD_TEMPLATES_CLEAR,
  data,
});

export const getCashRateCardDetails = (data) => ({
  type: actions.GET_CASH_RATE_CARD_DETAILS,
  data,
});

export const allocateCashRateCard = (data) => ({
  type: actions.ALLOCATE_CASH_RATE_CARD,
  data,
});

export const clearAllocateCashRateCard = (data) => ({
  type: actions.ALLOCATE_CASH_RATE_CARD_CLEAR,
  data,
});

export const getExistingCashRateCardTemplates = (data) => ({
  type: actions.GET_EXISTING_CASH_RATE_CARD_TEMPLATES,
  data,
});
//----//
export const getProfileDetails = (data) => ({
  type: actions.GET_PROFILE_DETAILS,
  data,
});

export const saveProfileDetails = (data, payload) => ({
  type: actions.SAVE_PROFILE_DETAILS,
  data,
  payload,
});

export const clearProfileDetails = (data) => ({
  type: actions.SAVE_PROFILE_DETAILS_CLEAR,
  data,
});

//packeting--guide//

export const getPacketingGuideFromHublist = (data) => ({
  type: actions.GET_PACKETING_GUIDE_FROM_HUBLIST,
  data,
});
export const getPacketingGuideToHublist = (data) => ({
  type: actions.GET_PACKETING_GUIDE_TO_HUBLIST,
  data,
});
export const getPacketingGuideFromCplist = (data) => ({
  type: actions.GET_PACKETING_GUIDE_FROM_CPLIST,
  data,
});
export const getPacketingGuideToCplist = (data) => ({
  type: actions.GET_PACKETING_GUIDE_TO_CPLIST,
  data,
});
export const getPacketingGuideMaster = (data) => ({
  type: actions.GET_PACKETING_GUIDE_MASTER,
  data,
});
export const getPacketingGuideSearch = (data) => ({
  type: actions.GET_PACKETING_GUIDE_SEARCH,
  data,
});
export const getPacketingGuideDownload = (data) => ({
  type: actions.GET_PACKETING_GUIDE_DOWNLOAD,
  data,
});
export const getPacketConfiguration = (data) => ({
  type: actions.GET_PACKET_CONFIGURATION,
  data,
});
export const putPacketConfiguration = (data) => ({
  type: actions.PUT_PACKET_CONFIGURATION,
  data,
});
export const putPacketConfigurationClear = (data) => ({
  type: actions.PUT_PACKET_CONFIGURATION_CLEAR,
  data,
});
export const postPacketingGuideMaster = (data) => ({
  type: actions.POST_PACKETING_GUIDE_MASTER,
  data,
});
export const putPacketingGuideMaster = (data) => ({
  type: actions.PUT_PACKETING_GUIDE_MASTER,
  data,
});
export const putPacketingGuideMasterClear = (data) => ({
  type: actions.PUT_PACKETING_GUIDE_MASTER_CLEAR,
  data,
});
export const postPacketingGuideMasterClear = (data) => ({
  type: actions.POST_PACKETING_GUIDE_MASTER_CLEAR,
  data,
});

// pincode mapping//
export const getPincodeMapping = (pageNum, pageSize) => ({
  type: actions.GET_PINCODE_MAPPING,
  pageNum,
  pageSize,
});
//---------------//

// hub onboarding //
export const getAllHubOnboard = (pageNum, pageSize) => ({
  type: actions.GET_ALL_HUB_ONBOARD,
  pageNum,
  pageSize,
});
// -----------------//
//--TAT MASTER--//
export const getFromHubTat = (data) => ({
  type: actions.GET_FROM_HUB_TAT,
  data,
});

export const getToHubTat = (data, id) => ({
  type: actions.GET_TO_HUB_TAT,
  data,
  id,
});

export const clearGetToHubTat = (data) => ({
  type: actions.GET_TO_HUB_TAT_CLEAR,
  data,
});

export const getFMLMTat = (data) => ({
  type: actions.GET_FM_LM_TAT,
  data,
});

//----//
//--Sorting Guide--//
export const getHubList = (data) => ({
  type: actions.GET_HUB_LIST,
  data,
});

export const createSortingGuide = (data) => ({
  type: actions.CREATE_SORTING_GUIDE,
  data,
});

export const clearSortingGuide = (data) => ({
  type: actions.CREATE_SORTING_GUIDE_CLEAR,
  data,
});

export const getHubBasedSortingGuide = (data) => ({
  type: actions.GET_HUB_BASED_SORTING_GUIDE,
  data,
});
export const clearHubBasedSortingGuide = (data) => ({
  type: actions.GET_HUB_BASED_SORTING_GUIDE_CLEAR,
  data,
});

export const getAllSortingGuide = (data) => ({
  type: actions.GET_ALL_SORTING_GUIDE,
  data,
});

export const viewSortingGuide = (data) => ({
  type: actions.VIEW_SORTING_GUIDE,
  data,
});
//--//
// onBording //
export const primiseTable = (data) => ({
  type: actions.PREMISE_TABLE,
  data,
});

export const patchOnboardForm = (data, id) => ({
  type: actions.ONBOARD_PATCH,
  data,
  id,
});

export const patchOnboardFormClear = () => ({
  type: actions.ONBOARD_PATCH_CLEAR,
});
