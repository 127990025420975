import React from "react";
import { Row } from "antd";
import packedIdIcon from "../../../assests/images/packetIcon.svg";
import packetContents from "../../../assests/images/packetContents.svg";
import rsIcon from "../../../assests/images/rupiIcon.svg";
import { PackedIdAndValue } from "./ManifestCard";
import { PacketFooter } from "./PacketFooter";
import { PacketContents } from "./PacketContents";

export const ManifestMiniCard = ({ color, item }) => {
  return (
    <div style={{ padding: "5px" }}>
      <div className={`mini-reactangle-${color}`}>
        <div className="mini-reactangle1">
          <div className="mini-reactangle2">
            <div className="mini-container">
              <div className="mini-container-div">
                <div>
                  <img src={packedIdIcon} />
                </div>
                <div>
                  <PackedIdAndValue
                    text={"AWB Number"}
                    number={item.awbNumber}
                  />
                </div>
              </div>
              <div className="mini-container-div">
                <div>
                  <img src={rsIcon} />
                </div>
                <div>
                  <PackedIdAndValue text={"Value"} number={`${item.value}`} />
                </div>
              </div>
            </div>
            <Row gutter={12} className="manifestCard-container-secondRow">
              <div className="packetContent" style={{ display: "flex" }}>
                <div>
                  <img src={packetContents} />
                </div>
                <div className="manifestCard-container-text">
                  <div>{"Packet Contents"}</div>
                  <div
                    style={{
                      maxWidth: "202px",
                      paddingLeft: "5px",
                      paddingTop: "5px",
                    }}
                  >
                    <PacketContents
                      numberOfPakcet={item?.childShipmentDetails}
                    />
                  </div>
                </div>
              </div>

              <div className="manifestCard-container-secondRow-footer"></div>
            </Row>
          </div>

          <PacketFooter
            item={item?.childShipmentDetails?.length}
            childPacket={item}
          />
        </div>
      </div>
    </div>
  );
};
