import React from "react";
import { Col } from "antd";

import next_arrow from "../../../assests/images/master/next-arrow.svg";

import "./MasterCard.scss";
const MasterCard = ({
  id,
  imgSrc,
  title,
  onClickHandler,
  route,
  disable,
  childPresent,
}) => {
  return (
    <Col
      xs={24}
      sm={12}
      md={8}
      lg={6}
      xl={4}
      key={id}
      onClick={() => onClickHandler(route, childPresent, id)}
      className={disable ? "pointer-disable" : "pointer master-card-col"}
    >
      <div
        className={
          disable
            ? "master-card-container master-card-container-disbale"
            : "master-card-container"
        }
      >
        <div className="img-wrapper">
          <img src={imgSrc} />
        </div>
        <div className="div">
          {title}&nbsp;<img src={next_arrow} />
        </div>
      </div>
    </Col>
  );
};
export default MasterCard;
