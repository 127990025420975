/*eslint-disable indent */
import React, { useRef, useEffect } from "react";
import { Button, Col, Row } from "antd";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { getprintManifest } from "../../../actionsMethods/manifestMethods";
import { camelCase, capitalize } from "../../../utils";
import print_icon from "../../../assests/images/master/print.svg";
import back_img from "../../../assests/images/rightVector.svg";
import { PrintManifest } from "../../../components/PrintManifest";

const PrintConsolidatedManifest = () => {
  const manifestId = useParams();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const { result: packetAndShipmt } = useSelector(
    (state) => state.manifestReducer.getprintManifestReducer,
    shallowEqual
  );
  const packetDataDox = packetAndShipmt?.shipmentScanDtos?.filter(
    (data) => data?.shipmentDetails?.type == "dox"
  );
  const packetData = packetAndShipmt?.shipmentScanDtos?.map(
    (data) => data?.packetsdto
  );
  const packetDataNonDoxWithChild = packetAndShipmt?.shipmentScanDtos?.filter(
    (data) =>
      data?.shipmentDetails?.childShipments?.length > 0
        ? data?.shipmentDetails?.type == "non-dox"
        : ""
  );
  const packetDataNonDoxWithNoChild = packetAndShipmt?.shipmentScanDtos?.filter(
    (data) =>
      data?.shipmentDetails?.childShipments?.length === 0
        ? data?.shipmentDetails?.type == "non-dox"
        : ""
  );
  const columnsWOChild = [
    {
      title: "AWB No",
      dataIndex: "",
      key: "",
      render: (text) => (
        <div>{text?.shipmentDetails?.awbNumber || "------"}</div>
      ),
    },
    {
      title: "Weight",
      dataIndex: "",
      key: "",
      render: (text) => (
        <div>{`${text?.shipmentDetails?.weight} Kg` || "------"}</div>
      ),
    },
    {
      title: "Mode",
      dataIndex: "",
      key: "",
      render: (text) => (
        <div>{capitalize(text?.shipmentDetails?.travelBy) || "------"}</div>
      ),
    },
  ];
  const columnsWithChild = [
    {
      title: "AWB No",
      dataIndex: "shipmentDetails",
      key: "awbNo",
      render: (text) => {
        const child = text?.childShipmentDetails?.map(
          (data) => data?.childAwbNumber
        );
        child.shift("------");
        return (
          <div>
            <Col
              style={
                text?.childShipmentDetails?.length > 0
                  ? { marginBottom: "20px" }
                  : {}
              }>
              {text?.awbNumber}
            </Col>
            <Col style={{ visibility: "hidden" }}>
              {child?.map((data, idx) => (
                <Row key={idx}>{data}</Row>
              ))}
            </Col>
          </div>
        );
      },
    },
    {
      title: "Child AWB No",
      dataIndex: "shipmentDetails",
      key: "childAwbNo",
      render: (text) => {
        const child = text?.childShipmentDetails?.map(
          (data) => data?.childAwbNumber
        );
        child.unshift("------");
        return (
          <Col>
            {child?.map((data, idx) => (
              <Row
                key={idx}
                style={
                  data === "------"
                    ? {
                        width: "85px",
                        display: "flex",
                        justifyContent: "center",
                      }
                    : {}
                }>
                {data}
              </Row>
            ))}
          </Col>
        );
      },
    },
    {
      title: "Weight",
      dataIndex: "shipmentDetails",
      key: "weight",
      render: (text) => {
        let child = text?.childShipmentDetails?.map((data) => data?.weight);
        child.unshift(text?.weight);
        return child?.map((item, index) => (
          <div key={index}>{`${item} Kg`}</div>
        ));
      },
    },
    {
      title: "Mode",
      dataIndex: "",
      key: "",
      render: (text) => (
        <div>{capitalize(text?.shipmentDetails?.travelBy) || "------"}</div>
      ),
    },
  ];
  const columnsDox = [
    {
      title: "AWB No",
      dataIndex: "",
      key: "",
      render: (text) => (
        <div>{text?.shipmentDetails?.awbNumber || "------"}</div>
      ),
    },
    {
      title: "Weight",
      dataIndex: "",
      key: "",
      render: (text) => (
        <div>{`${text?.shipmentDetails?.weight || "------"} Kg`}</div>
      ),
    },
    {
      title: "Mode",
      dataIndex: "",
      key: "",
      render: (text) => (
        <div>{camelCase(text?.shipmentDetails?.travelBy) || "------"}</div>
      ),
    },
  ];
  const packets = [
    {
      title: "Packet ID",
      dataIndex: "",
      key: "",
      render: (text) => <div>{text?.packetBarcode}</div>,
    },
    {
      title: "Packet Content",
      dataIndex: "",
      key: "",
      render: (text) => (
        <>
          {text?.packetShipments?.map((data, idx) => (
            <div key={idx}>{data?.awbNo}</div>
          ))}
        </>
      ),
    },
    {
      title: "Weight",
      dataIndex: "",
      key: "",
      render: (text) => (
        <>
          {text?.packetShipments?.map((data, idx) => (
            <div key={idx}>{`${data?.weight} Kg`}</div>
          ))}
        </>
      ),
    },
    {
      title: "Mode",
      dataIndex: "",
      key: "",
      render: (record) => <div>{capitalize(record?.travelBy)}</div>,
    },
  ];

  useEffect(() => {
    dispatch(getprintManifest(+manifestId?.id));
  }, []);
  const printHandle = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="p-2">
      <div className="print-consolidated-manifest">
        <div className="heading-lcr p-1-5">
          <span>
            <img
              src={back_img}
              onClick={() => window.history.back()}
              style={{ cursor: "pointer" }}
              className="mr-1"
            />
          </span>{" "}
          Print Manifest
        </div>
        <div className="lcr-report-wrapper">
          <div className="download-btn">
            <Button onClick={printHandle}>
              <img src={print_icon} /> PRINT
            </Button>
          </div>
          <PrintManifest
            componentRef={componentRef}
            packetAndShipmt={packetAndShipmt}
            // fromLocation={fromLocation}
            // toLocation={toLocation}
            manifestId={manifestId}
            columnsWOChild={columnsWOChild}
            packetDataNonDoxWithNoChild={packetDataNonDoxWithNoChild}
            columnsWithChild={columnsWithChild}
            packetDataNonDoxWithChild={packetDataNonDoxWithChild}
            columnsDox={columnsDox}
            packetDataDox={packetDataDox}
            packets={packets}
            packetData={packetData}
            Boolean={Boolean}
          />
        </div>
      </div>
    </div>
  );
};

export default PrintConsolidatedManifest;
