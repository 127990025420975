/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  TimePicker,
  Button,
  Space,
  Table,
  Tooltip,
  message,
  Spin,
} from "antd";
import edit_icon from "../../../../../assests/images/onbording/editIcon.svg";
import delete_icon from "../../../../../assests/images/onbording/delete-box.svg";
import add_circle from "../../../../../assests/images/add-circle.svg";
import cross_circle from "../../../../../assests/images/cross-circle.svg";
import API from "../../../../../api";
import urls from "../../../../../api/urls";
import moment from "moment";
import { serialNum } from "../../../../../utils";
import { capitaliseWithHyphen } from "../../../../../utils";

function HeavyVehicleRestrictions({ scrollToRef }) {
  const [form] = Form.useForm();

  const {
    GET_ALL_STATE,
    GET_CITY,
    VEHICLE_RESTRICTION,
    GET_RESTRICTION_LIST,
    DELETE_CEHICLE_RESTRICTION,
    EDIT_CEHICLE_RESTRICTION,
  } = urls;

  const [allState, setAllState] = useState([]);
  const [cityName, setCityName] = useState(null);
  const [vehiclePayload, setVehiclePayload] = useState({});
  const [list, setList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [additionalHours, setAdditionalHours] = useState(false);
  const [disabledRange, setDisabledRange] = useState([]);
  useEffect(() => {
    getStates();
    getTableData();
  }, []);

  useEffect(() => {
    disabledHours();
  }, [vehiclePayload?.mHours]);

  const onDelete = async (value) => {
    setLoading(true);
    let deleteApiResponse = await API.Api(
      DELETE_CEHICLE_RESTRICTION + value
    ).deleteIdHeader();
    if (deleteApiResponse?.status) {
      message.success("Deleted successfully");
      getTableData();
    }
    setLoading(false);
  };

  const onEdit = (value) => {
    let tempObj = {};
    if (value.totalTimings.length !== 2) {
      setAdditionalHours(false);
      tempObj = {
        mHours: [
          value?.totalTimings[0].split("-")[0],
          value?.totalTimings[0].split("-")[1],
        ],
      };
      form.setFieldsValue({
        mHours: [
          moment(value?.totalTimings[0].split("-")[0], "HH:mm a"),
          moment(value?.totalTimings[0].split("-")[1], "HH:mm a"),
        ],

        city: capitaliseWithHyphen(value?.city),
        state: value?.state,
        vehicleType: value?.vehicletype,
      });
    } else {
      setAdditionalHours(true);
      tempObj = {
        mHours: [
          value?.totalTimings[0].split("-")[0],
          value?.totalTimings[0].split("-")[1],
        ],
        eHours: [
          value?.totalTimings[1]?.split("-")[0],
          value?.totalTimings[1]?.split("-")[1],
        ],
      };

      form.setFieldsValue({
        mHours: [
          moment(value?.totalTimings[0].split("-")[0], "HH:mm a"),
          moment(value?.totalTimings[0].split("-")[1], "HH:mm a"),
        ],
        eHours: [
          moment(value?.totalTimings[1].split("-")[0], "HH:mm a"),
          moment(value?.totalTimings[1].split("-")[1], "HH:mm a"),
        ],
        city: capitaliseWithHyphen(value?.city),
        state: value?.state,
        vehicleType: value?.vehicletype,
      });
    }
    let obj = {
      vehicleType: value?.vehicletype,
      State: value?.state,
      city: value?.city,
      ...tempObj,
    };

    setVehiclePayload(obj);
    setIsEdit(true);
    scrollToRef();
  };

  function formatTime(time) {
    let ampm = time.split(" ")[1];
    function addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }

    let [hours, minutes] = time.split(":");
    hours = parseInt(hours);
    minutes = parseInt(minutes);

    hours = hours % 12;
    hours = hours ? hours : 12;

    hours = addZero(hours);
    minutes = addZero(minutes);

    return hours + ":" + minutes + " " + ampm;
  }

  const columns = [
    {
      title: "Sl No",
      dataIndex: "slNo",
      key: "slNo",
      // width: 100,
      render: (a, b, idx) => serialNum(10, 1, idx),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (text) => <span>{capitaliseWithHyphen(text)}</span>,
      width: 150,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      filters: allState?.map((hub) => ({
        text: hub?.stateName,
        value: hub?.stateName,
      })),
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.state.startsWith(value),
      render: (text) => <span>{capitaliseWithHyphen(text)}</span>,
      width: 150,
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicletype",
      key: "vehicletype",
      width: 250,
    },

    {
      title: "From Time",
      dataIndex: "totalTimings",
      key: "totalTimings",
      render: (text, record) => (
        <>
          <div className="vertical-space-between row-gap">
            <span>{formatTime(text[0].split("-")[0])}</span>
            {text.length == 2 && (
              <span>{formatTime(text[1].split("-")[0])}</span>
            )}
          </div>
        </>
      ),
      // width: 170,
    },
    {
      title: "To Time",
      dataIndex: "totalTimings",
      key: "totalTimings",
      render: (text, record) => (
        <>
          <div className="vertical-space-between row-gap">
            <span>{formatTime(text[0].split("-")[1])}</span>
            {text.length == 2 && (
              <span>{formatTime(text[1].split("-")[1])}</span>
            )}
          </div>
        </>
      ),
      // width: 180,
    },

    {
      title: "Duration (in hr)",
      dataIndex: "totalhours",
      key: "totalhours",
      render: (text, record) => (
        <>
          <div className="vertical-space-between row-gap">
            <span>{text[0]}</span>
            <span>{text[1]}</span>
          </div>
        </>
      ),
      // width: 175,
    },

    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <Tooltip title={"Delete"} className="pointer">
            <img
              src={delete_icon}
              alt="Delete"
              onClick={() => onDelete(record?.city)}
            />
          </Tooltip>
          <Tooltip title={"Edit"} className="pointer">
            <img src={edit_icon} alt="Edit" onClick={() => onEdit(record)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const getStates = async () => {
    setLoading(true);
    let allStateResponse = await API.Api(GET_ALL_STATE).getWithPremiseHeader();
    if (allStateResponse?.status) {
      setAllState(allStateResponse?.response?.data);
    }
    setLoading(false);
  };

  const getCity = async (value, name) => {
    form.setFieldValue("city", null);

    setLoading(true);
    let allStateResponse = await API.Api(
      GET_CITY + value
    ).getWithPremiseHeader();
    if (allStateResponse?.status) {
      setCityName(allStateResponse?.response?.data.city);
    }
    setVehiclePayload({ ...vehiclePayload, [name]: value });
    setLoading(false);
  };

  const onChangehandler = (name, e) => {
    setVehiclePayload({ ...vehiclePayload, [name]: e });
  };

  const [validateTime, setValidateTime] = useState({});
  const [additionalValidateTime, setAdditionalValidateTime] = useState({});

  const onChange = (name, time) => {
    setVehiclePayload({ ...vehiclePayload, [name]: time });
    if (time && time?.length === 2 && time[0] !== "" && time[1] !== "") {
      form.setFieldValue("eHours", null);
      setAdditionalValidateTime({
        validateStatus: "success",
        errorMsg: null,
      });
      const date1 = new Date(`2000-01-01 ${time[0]}`);
      const date2 = new Date(`2000-01-01 ${time[1]}`);

      // Compare the Date objects
      if (date1.getTime() === date2.getTime()) {
        setValidateTime({
          validateStatus: "error",
          errorMsg: "Start and end time should not be same ",
        });
      } else {
        const [start, end] = time;
        const disabledStart = moment(start);
        const disabledEnd = moment(end);

        setDisabledRange([disabledStart, disabledEnd]);
        setValidateTime({
          validateStatus: "success",
          errorMsg: null,
        });
      }
    } else {
      // message.error("Date Same ");
      setDisabledRange([]);
    }
  };

  const onChange2 = (name, time) => {
    let morningHours = vehiclePayload?.mHours;
    const [fromTime1, toTime1] = morningHours;
    const [fromTime2, toTime2] = time;

    const fromDate1 = new Date(`2023-01-01 ${fromTime1}`);
    const toDate1 = new Date(`2023-01-01 ${toTime1}`);
    const fromDate2 = new Date(`2023-01-01 ${fromTime2}`);
    const toDate2 = new Date(`2023-01-01 ${toTime2}`);

    if (
      (fromDate2 >= fromDate1 && fromDate2 <= toDate1) ||
      (toDate2 >= fromDate1 && toDate2 <= toDate1)
    ) {
      setAdditionalValidateTime({
        validateStatus: "error",
        errorMsg: `Time range should not be in ${fromTime1} to ${toTime1}`,
      });
    } else {
      setAdditionalValidateTime({
        validateStatus: "success",
        errorMsg: null,
      });
      setVehiclePayload({ ...vehiclePayload, [name]: time });
    }
  };
  const disabledSecondTimePicker = (current) => {
    if (disabledRange.length === 2) {
      const [disabledStart, disabledEnd] = disabledRange;
    }
    return false;
  };

  function timeDifferenceInHours(start, end) {
    var startTime = new Date("01/01/2007 " + start);
    var endTime = new Date("01/01/2007 " + end);

    var difference = endTime.getTime() - startTime.getTime();
    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60;

    return Math.abs(hoursDifference);
  }

  const submitData = async () => {
    setLoading(true);
    let timeings1 = vehiclePayload?.mHours.join("-");
    let timiings2 = vehiclePayload?.eHours?.join("-");
    let res;
    let hours;
    if (typeof timiings2 !== "undefined") {
      res = `${timeings1},${timiings2}`;
      hours = `${timeDifferenceInHours(
        vehiclePayload?.mHours[0],
        vehiclePayload?.mHours[1]
      )},${timeDifferenceInHours(
        vehiclePayload?.eHours[0],
        vehiclePayload?.eHours[1]
      )}`;
    } else {
      res = timeings1;
      hours = `${timeDifferenceInHours(
        vehiclePayload?.mHours[0],
        vehiclePayload?.mHours[1]
      )}`;
    }

    let payload = {
      city: vehiclePayload?.city,
      state: vehiclePayload?.State,
      timings: res,
      hours: hours,
      vehicletype: vehiclePayload?.vehicleType,
    };

    let response = null;

    if (isEdit) {
      response = await API.Api(
        EDIT_CEHICLE_RESTRICTION + vehiclePayload?.city
      ).patchCustomHeader(payload);
    } else {
      response = await API.Api(VEHICLE_RESTRICTION).post(payload);
    }

    if (response.status) {
      form.setFieldValue("state", null);
      form.setFieldValue("city", null);
      form.setFieldValue("vehicleType", null);
      form.setFieldValue("mHours", null);
      form.setFieldValue("eHours", null);
      setIsEdit(false);
      getTableData();
      setAdditionalHours(false);
      setVehiclePayload({});
      message.success(
        isEdit ? "Data Updated Successfully" : "Data Saved Successfully"
      );
    } else {
      message.error(response?.response?.data?.message);
    }
    setLoading(false);
  };

  const onClear = () => {
    form.setFieldValue("mHours", null);
    form.setFieldValue("eHours", null);
    form.setFieldValue("state", null);
    form.setFieldValue("city", null);
    form.setFieldValue("vehicleType", null);
    setVehiclePayload({});

    setIsEdit(false);
    setAdditionalHours(false);
  };

  const getTableData = async () => {
    setLoading(true);
    let allStateResponse = await API.Api(
      GET_RESTRICTION_LIST
    ).getWithPremiseHeader();
    if (allStateResponse?.status) {
      setList(allStateResponse?.response?.data);
    }
    setLoading(false);
  };

  let btnstyle = {
    marginTop: "30px",
    minWidth: "130px",
  };

  const onClickAdditional = () => {
    form.setFieldValue("eHours", null);
    setAdditionalValidateTime({
      validateStatus: "success",
      errorMsg: null,
    });
    delete vehiclePayload.eHours;
    setAdditionalHours(false);
  };

  let val = additionalHours ? 5 : 4;

  const disabledHours = () => {
    // Disable the hour 12 (12:00 PM)
    return [12, 1];
  };

  const disabledTime = (selectedTime, type) => {
    const selectedHour = selectedTime.hours();

    if ((type === "start" || type === "end") && selectedHour === 12) {
      return {
        disabledHours: () => [12], // Disable the 12th hour (12:00 PM)
        disabledMinutes: () => [...Array(60).keys()], // Disable all minutes
      };
    }

    // For other cases, enable all hours and minutes
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  };
  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        name="basic"
        className="global-form "
        layout="vertical"
        autoComplete="off"
      >
        <Row className="p-2 global-form-row " gutter={20}>
          <Col lg={8}>
            <Form.Item
              label="State"
              name="state"
              rules={[
                {
                  required: true,
                  message: "Please enter state",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a state"
                onChange={(e) => getCity(e, "State")}
                options={allState?.map((hub) => ({
                  label: hub?.stateName,
                  value: hub?.stateName,
                }))}
                disabled={isEdit}
              />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please enter city",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a city"
                value={cityName}
                disabled={isEdit}
                onChange={(e) => onChangehandler("city", e)}
                options={cityName?.map((hub) => ({
                  label: capitaliseWithHyphen(hub),
                  value: hub,
                }))}
              />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              label="Vehicle type"
              name="vehicleType"
              rules={[
                {
                  required: true,
                  message: "Please select vehicle type",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select vehicle type "
                onChange={(e) => onChangehandler("vehicleType", e)}
                options={[
                  {
                    value: "Ecco - 6ft - 500kg",
                    label: "Ecco - 6ft - 500kg",
                  },
                  {
                    value: "Tata ace - 7ft - 900kg",
                    label: "Tata ace - 7ft - 900kg",
                  },
                  {
                    value: "Bolero pickup - 7ft - 1200kg",
                    label: "Bolero pickup - 7ft - 1200kg",
                  },
                  {
                    value: "Eicher/Tata 407 - 10ft - 2500kg",
                    label: "Eicher/Tata 407 - 10ft - 2500kg",
                  },
                  {
                    value: "Close body vehicle - 14ft - 4500kg",
                    label: "Close body vehicle - 14ft - 4500kg",
                  },
                  {
                    value: "Close body vehicle - 18ft - 6000kg",
                    label: "Close body vehicle - 18ft - 6000kg",
                  },
                  {
                    value: "Close body vehicle - 20ft - 7000kg",
                    label: "Close body vehicle - 20ft - 7000kg",
                  },
                  {
                    value: "Close body vehicle - 22ft - 9800kg",
                    label: "Close body vehicle - 22ft - 9800kg",
                  },
                  {
                    value: "Close body vehicle - 24ft - 9800kg",
                    label: "Close body vehicle - 24ft - 9800kg",
                  },
                  {
                    value: "Close body vehicle - 32ft - 9800kg",
                    label: "Close body vehicle - 32ft - 9800kg",
                  },
                ]}
              />
            </Form.Item>
          </Col>

          <Col lg={8}>
            <Row gutter={10}>
              <Col lg={22}>
                <Form.Item
                  label="Restriction hours"
                  name="mHours"
                  validateStatus={validateTime?.validateStatus}
                  help={validateTime?.errorMsg}
                  rules={[
                    {
                      required: true,
                      message: "Please enter restriction hours",
                    },
                  ]}
                >
                  <TimePicker.RangePicker
                    use12Hours
                    format="h:mm a"
                    onChange={(e, a, v) => onChange("mHours", a, v)}
                  />
                </Form.Item>
              </Col>
              {!additionalHours && (
                <Col lg={2} style={{ marginTop: "35px" }}>
                  <Tooltip title={"Add"} className="pointer">
                    <img
                      src={add_circle}
                      alt="add"
                      onClick={() => setAdditionalHours(true)}
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
          </Col>
          {additionalHours && (
            <Col lg={8}>
              <Row gutter={10}>
                <Col lg={22}>
                  <Form.Item
                    label="Additional restriction hours"
                    name="eHours"
                    rules={[
                      {
                        required: true,
                        message: "Please enter restriction hours",
                      },
                    ]}
                    validateStatus={additionalValidateTime?.validateStatus}
                    help={additionalValidateTime?.errorMsg}
                  >
                    {/* <TimePicker.RangePicker
                      use12Hours
                      format="h:mm a"
                      disabledHours={disabledHours}
                      disabledMinutes={disabledMinutes}
                      onChange={(e, a, v) => onChange("eHours", a, v)}
                    /> */}
                    <TimePicker.RangePicker
                      format="h:mm a"
                      onChange={(e, a, v) => onChange2("eHours", a, v)}
                      // disabledTime={(selectedTime, type) =>
                      //   disabledTime(selectedTime, type)
                      // }
                      // defaultValue={[moment(), moment()]} // Set the default time range, if needed
                    />
                  </Form.Item>
                </Col>

                <Col lg={2} style={{ paddingTop: "35px" }}>
                  <Tooltip title={"Remove"} className="pointer">
                    <img
                      src={cross_circle}
                      alt="add"
                      onClick={() => onClickAdditional()}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          )}

          <Col
            lg={4}
            style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}
          >
            <Button
              type="primary"
              className="save-btn"
              form="basic"
              style={btnstyle}
              onClick={submitData}
              disabled={
                Object.keys(vehiclePayload).length == val ? false : true
              }
            >
              {isEdit ? "Update" : "SAVE"}
            </Button>

            <Button
              className="clear-btn-restriction"
              style={btnstyle}
              onClick={onClear}
            >
              CANCEL
            </Button>
          </Col>
          <Col lg={2} style={{ paddingLeft: "20px" }}></Col>
        </Row>
        <div>
          <Table columns={columns} dataSource={list} pagination={false} />
        </div>
      </Form>
    </Spin>
  );
}

export default HeavyVehicleRestrictions;
