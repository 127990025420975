export const focusFirstInputAndScrollToTop = (id) => {
  const getFocusFirstInput = (id) => {
    const timer = setTimeout(() => {
      const inputElement = document.getElementById(id);
      if (inputElement) {
        inputElement.focus();
      }
    }, 100);
    return () => clearTimeout(timer);
  };
  getFocusFirstInput(id);
};

// export default { CustomFunctions, FocusFirstInput };
