/* eslint-disable no-unused-vars */

export function getModifiedGST(userId) {
  const gstNumber = userId?.premises?.gst;
  const firstTwoDigits = gstNumber?.substring(0, 2);
  const modifiedDigits = firstTwoDigits?.startsWith("0")
    ? firstTwoDigits?.substring(1)
    : firstTwoDigits;
  return modifiedDigits;
}

const today = new Date();
const yyyy = today.getFullYear();
let mm = today.getMonth() + 1; // Months start at 0!
let dd = today.getDate();

if (dd < 10) dd = "0" + dd;
if (mm < 10) mm = "0" + mm;

export const formattedToday = dd + "/" + mm + "/" + yyyy;
