/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import urls from "../../../api/urls";
import API from "../../../api";
import { message, Table, Input } from "antd";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import { serialNum, capitalize, convertDate } from "../../../utils";
import Heading from "../../../components/Heading";
import search_icon from "../../../assests/images/search-icon.svg";
import { useNavigate } from "react-router-dom";

// import { ReactComponent as Pfd_red_icon } from "../../assests/images/SVG/pdf-red-icon.svg";

// import "./style.scss";

function ClientApprovalLeadList() {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  //   const [dataSource, setdataSource] = useState(tableData);
  const [pageSize, setPageSize] = useState(20);
  const [pageNum, setPageNum] = useState(0);
  const navigate = useNavigate();
  const { GET_CLIENT_APPROVAL_LIST } = urls;

  useEffect(() => {
    getClientApprovalList();
  }, []);
  const getClientApprovalList = async () => {
    setLoading(true);
    let response = await API.Api(GET_CLIENT_APPROVAL_LIST).getWithHeader();
    if (response?.status) {
      setLoading(false);
      const res = response?.response?.data;
      const temp = res?.filter((val) => val?.status == "Waiting for Approval");

      setPageSize(temp?.length);
      setTableData(temp);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network Error");
    }
  };

  const handlePageChange = (pagination) => {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
  };

  const reRoute = (record) => {
    console.log(record, "record");
    navigate(`/approve-dp/${record?.clientLeadId}`);
    // console.log(record, "value");
  };

  const columns = [
    {
      title: "Sl No",
      align: "center",
      key: "clientLeadId",
      dataIndex: "clientLeadId",
      // render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
      render: (text) => text,
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      render: (text, record) => <a onClick={() => reRoute(record)}>{text}</a>,
    },
    {
      title: "CP Name",
      dataIndex: "cpname",
      key: "cpname",
    },
    {
      title: "Date",
      dataIndex: "onboaredOn",
      key: "onboaredOn",
      //   width: 100,
      render: (_, record) => <div>{convertDate(record?.onboaredOn)}</div>,
    },
    {
      title: "Allocated Rate Card",
      dataIndex: "quoteName",
      key: "quoteName",
      //   width: 100,
    },
    {
      title: "Agreed Business Volume (INR)",
      dataIndex: "businessVolume",
      key: "businessVolume",
    },

    {
      title: "Agreed Valid Date ",
      dataIndex: "agreedValidDate ",
      key: "agreedValidDate ",
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      className: "actions-col",

      render: (_, record) => (
        <div className={`status center ${record?.status}`}>
          {capitalize(record?.status)}
        </div>
      ),
    },
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  return (
    <div>
      <div className="lead-gen-list-container mt-2 ml-2">
        {/* <Heading title=" CP Approval Lead List" /> */}
      </div>
      <div className="master-main-container view-all-staff ml-2 mr-2">
        <div className="align-center">
          <div className="pl-1-5 fw-600"></div>
          <div className="text-right p-1">
            <Input
              placeholder="Search by keyword"
              bordered
              // onChange={onSearch}
              style={{ borderRadius: "5px", height: "40px", width: "400px" }}
              suffix={<img src={search_icon} />}
            />
          </div>
        </div>
        <Table
          className="table-head-sticky"
          loading={{
            indicator: (
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            ),
            spinning: loading,
          }}
          columns={columns}
          // loading={loading}
          defaultCurrent={1}
          onChange={handlePageChange}
          // dataSource={inputSearchValue?.length == 0 ? tableData : dataSource}
          dataSource={tableData}
          pagination={{
            // pageSize: pageSize,
            // total: inputSearchValue.length == 0 ? tableData.length : 10,
            itemRender: itemRender,
          }}
          rowClassName={(record) => !record?.status && "disabled-row"}
        />
      </div>
    </div>
  );
}

export default ClientApprovalLeadList;
