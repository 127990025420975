/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row } from "antd";
import MasterCard from "../../components/Master/MasterCard/MasterCard";
import MasterSearch from "../../components/Master/MasterSearch";
import add_category from "../../assests/images/addCategory.svg";
import add_product from "../../assests/images/addProduct.svg";
import orderRequest from "../../assests/images/OrderRequest.svg";
import constants from "../../constants/constants";
const { ROLES } = constants;
const { HO_ADMIN } = ROLES;

const stationaryTypes = [
  {
    id: 1,
    imgSrc: add_category,
    title: "Add Categories",
    route: "/add-category",
    disable: false,
    childPresent: false,
    role: [HO_ADMIN],
  },
  {
    id: 2,
    imgSrc: add_product,
    title: "Add Product",
    route: "/product",
    disable: false,
    childPresent: false,
    role: [HO_ADMIN],
  },
  {
    id: 3,
    imgSrc: orderRequest,
    title: "Order Request",
    route: "/order-request",
    disable: false,
    childPresent: false,
    role: [HO_ADMIN],
  },
];
function HoStationary() {
  const [globalSettingList, setglobalSettingList] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [masterSearchTitle, setMasterSearchTitle] = useState("Product");
  const navigate = useNavigate();

  useEffect(() => {
    let role = JSON.parse(localStorage.getItem("userinfo"));
    let temp = [];
    stationaryTypes.map((e) => {
      e.role.some((item) => {
        if (role && role.roles.includes(item)) {
          temp.push(e);
        }
      });
    });
    setglobalSettingList(temp);
  }, []);

  const searchSetting = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);
    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(globalSettingList, currValue)
        : filterArray(globalSettingList, currValue);
    setDataSource(filteredData);
  };
  const filterArray = (dataSource, currValue) =>
    dataSource?.filter((o) =>
      o.title.toLowerCase().includes(currValue.toLowerCase())
    );

  const onClickHandler = (route, childPresent, id) => {
    if (childPresent) {
      navigate("/master-card-child", {
        state: id,
      });
    } else {
      if (route === "/client-onboarding") {
        localStorage.removeItem("clientId");
      }
      if (route === "/staff-onboarding") {
        localStorage.removeItem("staff");
      }
      navigate(route);
    }
  };
  return (
    <div className="master-container">
      <Row gutter={30}>
        <MasterSearch
          title={masterSearchTitle}
          search={searchSetting}
          setMasterSearchTitle={setMasterSearchTitle}
          masterSearch={true}
          masterSearchPlaceholder={"Search product setting"}
        />
        {inputSearchValue.length == 0
          ? globalSettingList.map((e) => {
              return (
                <MasterCard
                  key={e.id}
                  id={e.id}
                  title={e.title}
                  imgSrc={e.imgSrc}
                  onClickHandler={onClickHandler}
                  route={e.route}
                  searchTitle={masterSearchTitle}
                  disable={e.disable}
                  childPresent={e.childPresent}
                />
              );
            })
          : dataSource.map((e) => {
              return (
                <MasterCard
                  key={e.id}
                  id={e.id}
                  title={e.title}
                  imgSrc={e.imgSrc}
                  onClickHandler={onClickHandler}
                  route={e.route}
                  searchTitle={masterSearchTitle}
                  disable={e.disable}
                  childPresent={e.childPresent}
                />
              );
            })}
      </Row>
    </div>
  );
}

export default HoStationary;
