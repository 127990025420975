/* eslint-disable no-prototype-builtins */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import Moment from "react-moment";
import moment from "moment";
import { Tooltip, Typography, Popconfirm } from "antd";
const { Paragraph } = Typography;

const cookies = new Cookies();

export const getUserAndPremiseId = () => {
  const getPremiseId = localStorage.getItem("userId");
  const premise = JSON.parse(getPremiseId);
  const userInfo = premise?.userInfo;
  return userInfo;
};

export const getRemainingTimeForToken = () => {
  const token = localStorage.getItem("userinfo");

  if (token) {
    const decodedToken = jwtDecode(token);
    return (decodedToken.exp * 1000 - Date.now()) / 1000;
  }
  return 0;
};

// str => string
// matchingStr => array of matching string or string
export const checkForSetOfStrings = (str, matchingStr) => {
  if (typeof matchingStr === "object") {
    return matchingStr.some((el) => str.includes(el));
  } else if (typeof matchingStr === "string") {
    return str.includes(matchingStr);
  }
  return false;
};

export const isNull = (e) => {
  return e ? e + ", " : "";
};

export const isBlank = (e) => {
  return e || "";
};

export const checkNumbervalue = (event) => {
  if (!/^[0-9]*\.?[0-9]*$/.test(event.key) && event.key !== "Backspace") {
    return true;
  }
};

export const checkSpecialCharacter = (event) => {
  if (!/^[A-Za-z0-9 ]+$/.test(event.key) && event.key !== "Backspace") {
    return true;
  }
};

export const checkSpace = (event) => {
  if (!/^\S+$/.test(event.key) && event.key !== "Backspace") {
    return true;
  }
};
export const checkArrowKey = (event) => {
  if (
    ["ArrowLeft", "ArrowRight", "Home", "End"].includes(event) &&
    event.key !== "Backspace"
  ) {
    return true;
  }
};

export const checkEditable = (event) => {
  if (event.target.value.length < 10) {
    return true;
  }
};

export const checkAlphabets = (event) => {
  if (!/^[a-zA-Z- ]*$/.test(event.key) && event.key !== "Backspace") {
    return true;
  }
};

export const checkAlphabetsAndNumber = (event) => {
  if (
    !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
      event.key
    ) &&
    event.key !== "Backspace"
  ) {
    return true;
  }
};

export const scrollToTop = (selector) => {
  const elem = document.querySelector(selector);
  elem?.scrollTo(0, 0);
};

export const gstNumber = (event) => {
  if (
    !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
      event.key
    ) &&
    event.key !== "Backspace"
  ) {
    return true;
  }
};

export const panNumber = (event) => {
  if (
    !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(event.key) &&
    event.key !== "Backspace"
  ) {
    return true;
  }
};

export const getUserTokenCookie = () => {
  return cookies.get("userToken");
};

export const getUserEmail = () => {
  return cookies.get("userToken");
};

//overflow ellipsis for long text
export const overflowFunc = (text, width) => {
  return (
    <Tooltip title={text} placement="bottomLeft">
      <Paragraph ellipsis style={{ width }}>
        {text && text}
      </Paragraph>
    </Tooltip>
  );
};

//Serial Number for tables
export const serialNum = (pageSize, pageNum, index) => {
  let serialNum = pageSize * (pageNum == 0 ? pageNum : pageNum - 1) + index + 1;
  return serialNum;
};

// Date format
export const dateFormat = (dateToFormat) => {
  const toUpperCaseFilter = (d) => {
    return d.slice(0, 10);
  };
  return <Moment format="DD/MM/YYYY">{dateToFormat}</Moment>;
};

//All words will be upperCase
export const capitalise = (e) => {
  let splitStr = e?.target?.value?.toLowerCase()?.split(" ");
  for (let i = 0; i < splitStr?.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr?.join(" ");
};

//Only first word will be uppercase
export const firstLetterCapitalise = (e) => {
  let str = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
  return str;
};

export const firstLetterCapitaliseDropDown = (e) => {
  let str = e.charAt(0).toUpperCase() + e.slice(1);
  return str;
};
export const firstLetterCapsInSentence = (a) => {
  let str = a.split(" ")[0].charAt(0).toUpperCase() + a.slice(1).toLowerCase();
  return str;
};

///Camel Case///
export const camelCase = (e) => {
  let str = e
    ?.split(" ")
    .map((e) => e.charAt(0).toUpperCase() + e.slice(1).toLowerCase())
    .join(" ");
  return str;
};
//--//

///Capitalize///
export const capitalize = (e) => {
  if (typeof e !== "string") {
    return e;
  } else {
    let str = e
      ?.split(" ")
      .map((e) => e.charAt(0).toUpperCase() + e.slice(1).toLowerCase())
      .join(" ");
    return str;
  }
};
export const capitalizeWithComma = (e) => {
  let str = e
    ?.split(",")
    .map((e) => e.charAt(0).toUpperCase() + e.slice(1).toLowerCase())
    .join(",");
  return str;
};
export const capitalizeAfterSpacesAndSpecialChars = (inputString) => {
  return inputString
    ?.split(/([\s\W]+)/)
    ?.map((word) => {
      if (/^[a-zA-Z]/.test(word)) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word;
    })
    .join("");
};

export const capitaliseWithHyphen = (e) => {
  let covertString = [" ", "-", "_", ",", "("];
  let value = e?.toString()?.trim();
  let capitaliseString = "";
  if (value) {
    value = value.toLowerCase();
    for (let i = 0; i < value.length; i++) {
      if (i === 0) {
        capitaliseString += value[0]?.toUpperCase();
      } else {
        if (covertString?.includes(value[i])) {
          capitaliseString += value[i];
          if (i + 1 < value.length) {
            capitaliseString +=
              value[i + 1]?.charAt(0)?.toUpperCase() +
              value[i + 1]?.slice(1)?.toLowerCase();
            i++;
          }
        } else {
          capitaliseString += value[i];
        }
      }
    }
  }
  return capitaliseString;
};

export const normalizeString = (inputString) => {
  let normalizedString = inputString?.replace(/[_-]/g, " ");
  normalizedString =
    normalizedString?.charAt(0)?.toUpperCase() +
    normalizedString?.slice(1)?.toLowerCase();

  return normalizedString;
};

export const convertDate = (date1, time) => {
  var date = new Date(date1);
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var year = date.getFullYear();
  if (time) {
    return day + "/" + month + "/" + year + date1.substring(10);
  } else {
    return day + "/" + month + "/" + year;
  }
};

//Disbale Next Date

export function verifiedEway(childData, setVerifiedEwayBill) {
  return (indexToUpdate, newData) => {
    const updatedVerifiedEwayBill = [...childData];
    updatedVerifiedEwayBill[indexToUpdate].ewayBillNumber = newData.ewbNo;
    updatedVerifiedEwayBill[indexToUpdate].expiryDate = moment(
      newData.validUpto,
      "DD/MM/YYYY h:mm:ss A"
    ).format("YYYY-MM-DD HH:mm:ss");
    updatedVerifiedEwayBill[indexToUpdate].ewayBillCreateDate = moment(
      newData.ewayBillDate,
      "DD/MM/YYYY h:mm:ss A"
    ).format("YYYY-MM-DD HH:mm:ss");
    setVerifiedEwayBill(updatedVerifiedEwayBill);
  };
}
export function timeFormater(time) {
  let dateString = time;
  let dateObject = new Date(dateString);
  var year = dateObject.getFullYear();
  var month = dateObject.getMonth() + 1;
  var day = dateObject.getDate();
  var hours = dateObject.getHours();
  var minutes = dateObject.getMinutes();
  var seconds = dateObject.getSeconds();
  return `${day}-${month}-${year}`;
}

export const dateFormat_dmy = "DD-MM-YYYY";
export const dateFormat_ymd = "YYYY-MM-DD";
export const dateFormateSlashFormat = "DD/MM/YYYY";
export const invalideMobileNo = [
  9999999999, 8888888888, 7777777777, 6666666666, 5555555555, 4444444444,
  3333333333, 2222222222, 1111111111, 1234567890, 0,
];

export const discountPrice = (price, discountInPercentage) => {
  if (discountInPercentage !== 0) {
    return `${(price - (price * discountInPercentage) / 100).toFixed(
      2
    )} - ${price} `;
  } else {
    return price;
  }
};

export const getFinancialYearData = () => {
  let financialYearData = [];
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  for (let i = currentYear - 1; i < currentYear + 5; i++) {
    financialYearData.push({
      label: `${i}-${i + 1}`,
      value: `${i}-${i + 1}`,
    });
  }
  return financialYearData;
};

export function isValidAwb(inputNumber) {
  const strNumber = inputNumber.toString();
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear() % 100;
  const pastYear = currentYear - (1 % 100);

  if (
    (strNumber.length === 11 || strNumber.length === 14) &&
    (strNumber.startsWith(pastYear.toString()) ||
      strNumber.startsWith(currentYear.toString()))
  ) {
    if (/^\d+$/.test(strNumber)) {
      return true;
    }
  }

  return false;
}

export function isValidAwbForCredit(inputNumber, documentLength) {
  const strNumber = inputNumber.toString();
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear() % 100;
  const pastYear = currentYear - (1 % 100);

  if (
    strNumber.length === documentLength &&
    (strNumber.startsWith(pastYear.toString()) ||
      strNumber.startsWith(currentYear.toString()))
  ) {
    if (/^\d+$/.test(strNumber)) {
      return true;
    }
  }

  return false;
}

export function isValidChildAwb(inputNumber) {
  const strNumber = inputNumber.toString();
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear() % 100;
  const pastYear = currentYear - (1 % 100);

  if (
    strNumber.length === 11 &&
    (strNumber.startsWith(pastYear.toString()) ||
      strNumber.startsWith(currentYear.toString()))
  ) {
    if (/^\d+$/.test(strNumber)) {
      return true;
    }
  }

  return false;
}

export function NamewithFirstCaptialwithNoSpace(event) {
  let value = event.target.value;

  // Capitalize the first letter
  value = value.replace(/^\s*([a-z])/, (_, letter) => letter.toUpperCase());

  // Prevent leading spaces
  if (value.startsWith(" ")) {
    value = value.trimStart();
  }

  event.target.value = value;
}
export function getStartAndEndDate(startDate, endDate, dateFormat_dmy) {
  const dateFormat = dateFormat_dmy || "DD-MM-YYYY";
  const yesterday = moment().subtract(1, "day").format(dateFormat);
  const today = moment().format(dateFormat);
  let start = startDate
    ? moment(startDate, "DD/MM/YYYY").format(dateFormat)
    : yesterday;
  let end = endDate ? moment(endDate, "DD/MM/YYYY").format(dateFormat) : today;
  return `&fromDate=${start}&toDate=${end}`;
}

export function getDataRange(startDate, endDate, dateFormat_dmy) {
  const dateFormat = dateFormat_dmy || "DD-MM-YYYY";
  const yesterday = moment().subtract(1, "day").format(dateFormat);
  const today = moment().format(dateFormat);
  let start = startDate
    ? moment(startDate, "DD/MM/YYYY").format(dateFormat)
    : yesterday;
  let end = endDate ? moment(endDate, "DD/MM/YYYY").format(dateFormat) : today;
  return `startDate=${start}&endDate=${end}`;
}

export function isValidDocumentNum(docNumber, bookingType) {
  const shipmentType = bookingType?.toLowerCase()?.split(" ")?.join("");
  const strNumber = docNumber.toString();
  let currentYear = new Date().getFullYear() % 100;
  let prevYear = (currentYear - 1).toString();
  const validDoc = {
    standardcash: /^([0-9]{5}[1]{1}[0-9]{8}){0,14}$/,
    standardcredit: /^([0-9]{5}[2-3]{1}[0-9]{8}){0,14}$/,
    fasttrack: /^([0-9]{2}[1]{1}[0-9]{8}){0,11}$/,
    child: /^([0-9]{2}[4]{1}[0-9]{8}){0,11}$/,
    valueplus: /^([0-9]{2}[1]{1}[0-9]{8}){0,11}$/,
  };

  if (validDoc.hasOwnProperty(shipmentType)) {
    return (
      validDoc[shipmentType].test(strNumber) &&
      (strNumber.startsWith(currentYear.toString()) ||
        strNumber.startsWith(prevYear.toString()))
    );
  }
  return false;
}

export const validateDocumentNumber = (cashOrCredit, value, form) => {
  if (
    !value ||
    !isValidDocumentNum(
      value,
      form?.getFieldValue("service")?.toLowerCase() === "standard"
        ? cashOrCredit
        : form.getFieldValue("service")
    )
  ) {
    return Promise.reject("Enter a valid doc number.");
  }
  return Promise.resolve();
};

export const validatePhoneNumber = () => ({
  validator(rule, value = "") {
    if (value.length > 0 && value.length < 10) {
      return Promise.reject("Enter 10 digits phone number");
    } else if (parseInt(value.substring(1, 0)) === 0) {
      return Promise.reject("Mobile number should not be 0");
    } else {
      return Promise.resolve();
    }
  },
});

export const validateWeight = (_, value) => {
  if (!value) {
    return Promise.reject();
  }

  const firstDigit = parseInt(value[0]);
  if (firstDigit === 0) {
    return Promise.reject("Value should not be 0");
  }

  if (value.split(".").length > 2) {
    return Promise.reject("Enter only one decimal value");
  }

  const regex = /^\d+(\.\d{1,2})?$/;
  if (!regex.test(value)) {
    return Promise.reject("Enter a valid number with up to two decimal places");
  }

  const numericValue = parseFloat(value);
  if (numericValue < 50 || numericValue > 999) {
    return Promise.reject("Weight should be between 50Gm and 999Gm");
  }

  return Promise.resolve();
};

// Common sorter function
export const commonSorterForTable = (a, b, key) => {
  if (typeof a[key] === "number" && typeof b[key] === "number") {
    return a[key] - b[key];
  }
  if (typeof a[key] === "string" && typeof b[key] === "string") {
    if (
      moment(a[key], "DD-MM-YYYY", true).isValid() &&
      moment(b[key], "YYYY-MM-DD", true).isValid()
    ) {
      return moment(a[key]).diff(moment(b[key]));
    }
    return a[key].localeCompare(b[key]);
  }
  return 0;
};

export const toCapitalizedFormat = (str) => {
  return str
    .toLowerCase()
    .replace(/\b\w/g, (chr) => chr.toUpperCase())
    .replace(/>\s*\w/g, (chr) => chr.toUpperCase());
};

export const formatDateTime = ({ exception_date, exception_time }) => {
  let combinedDateTime =
    moment.utc(exception_date).format("YYYY-MM-DD") + "T" + exception_time;

  let formattedDateTime = moment(combinedDateTime).format("DD-MM-YYYY hh:mm A");

  return formattedDateTime;
};

export const routeListCapitalizedFormat = (str) => {
  return str.toLowerCase().replace(/\b\w/g, (chr) => chr.toUpperCase());
};
