/* eslint-disable no-prototype-builtins */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from "react";
import { Collapse, Row, Form, Col, Button, message, Spin } from "antd";
import calculeterIcon from "../../../../assests/images/calculeterIcon.svg";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";
import ritTick from "../../../../assests/images/ritTick.svg";
import API from "../../../../api";
import urls from "../../../../api/urls";
import {
  capitaliseWithHyphen,
  checkNumbervalue,
  checkSpecialCharacter,
  isValidAwb,
  isValidDocumentNum,
} from "../../../../utils";
import RateCard from "./rateCard";
import RateCalculate from "./rateCalculate";

import "../../style.scss";
const {
  CHECK_PIN_CODE_SERVICEABILITY,
  FETCH_RATE_CARD_CP,
  GET_CP_TAT_SERVICE,
  GET_DESTINATION_PREMISE_ID,
  POST_RETAIL_BOOKING,
  GET_RETAIL_DOCUMENT_DETAILS,
} = urls;
const { Panel } = Collapse;
const RateCalculatorForm = ({ changeStep }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [pinCodeServiceableStatus, setPinCodeServiceableStatus] = useState({});
  const [areaList, setAreaList] = useState([]);
  const [areaDestinationPremiseId, setAreaDestinationPremiseId] =
    useState(null);
  const [showNonDoxFields, setShowNonDoxFields] = useState(false);
  const [docTypeBasedData, setDocTypeBasedData] = useState([
    {
      type: "DOX",
      data: [
        {
          content: "Document",
          description: [
            "Form, Catalogue & Paper",
            "Passport",
            "Tender Document",
          ],
        },
      ],
    },
    {
      type: "NON-DOX",
      data: [
        {
          content: "Apparel & Accessories",
          description: ["Accessories", "Clothes", "Footwear"],
        },
        {
          content: "Automobile",
          description: ["Ancillary tools & Parts", "Navigation Devices"],
        },
        {
          content: "Books & Stationary",
          description: ["Office Supplies", "Games", "Books & Magazines"],
        },
        {
          content: "Consumables/FMCG",
          description: [
            "Grooming & Beauty Products ",
            "Non-perishable Food Items",
          ],
        },
        {
          content: "Covid 19 Essentials",
          description: [
            "Masks, Gloves, Face Shields etc.",
            "Medicines",
            "PPE Kits",
          ],
        },
        {
          content: "Electronics",
          description: [
            "Chargers, Earphones etc.",
            "Printer, Modem, Router etc.",
            "Wireless Devices",
          ],
        },
        {
          content: "Household Items",
          description: [
            "Gardening Tools & Materials",
            "Heating & Cooling Appliances",
            "Kitchen & Home Appliances",
            "Kitchenware",
            "Lifestyle/ Décor Items",
            "Pet Supplies",
            "Small Furniture",
          ],
        },
        {
          content: "Sports Equipment",
          description: ["Gym Equipment", "Sports Accessories", "Sports Gear"],
        },
      ],
    },
  ]);
  const [contentData, setContentData] = useState([]);

  const [rateCardData, setRateCardData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [showRateCard, setShowRateCard] = useState(false);
  const [chargableWeight, setChargableWeight] = useState(0);
  const [openCharge, setOpenCharge] = useState(false);
  const [readOnlyField, setReadOnlyField] = useState(false);
  //--Child--//
  const [chilldShipmentLength, setChilldShipmentLength] = useState(0);
  const [childShipments, setChildShipments] = useState([]);
  //----//
  const user_premiseId = JSON.parse(window?.localStorage?.getItem("userId"));
  const pickUpPinCode = user_premiseId?.premises?.pincode;

  const getDocumentDetails = async (docNum) => {
    //setLoading(true);
    const response = await API.Api(
      GET_RETAIL_DOCUMENT_DETAILS + docNum
    ).getCustomHeader();
    const data = response?.response?.data;
    const shipmentDetails = response?.response?.data?.shipmentDetails;
    const childShipmentDetails = response?.response?.data?.childShipmentDetails;
    setShowNonDoxFields(shipmentDetails?.content === "dox" ? false : true);
    form.setFieldsValue({
      documentNumber: data?.awbNumber,
      deliveryPincode: shipmentDetails?.destinationPincode,
      shipmentType: shipmentDetails?.type,
      content: shipmentDetails?.content === "dox" ? "DOX" : "NON-DOX",
      description: shipmentDetails?.description,
      weight: shipmentDetails?.weight,
      value: shipmentDetails?.value,
    });
    if (shipmentDetails?.content === "dox") {
      form.setFieldsValue({
        weight: shipmentDetails?.weight,
        value: shipmentDetails?.value,
      });
    } else {
      setChilldShipmentLength(1);
      setChildShipments(
        childShipmentDetails?.map((e) => ({
          childDocumentNumber: e?.childAwbNumber,
          weight: e?.weight,
          length: e?.length,
          width: e?.width,
          height: e?.height,
          volumatricWeight: e?.volumetricWeight,
          value: e?.value,
          id: e?.childAwbNumber,
        }))
      );
      form.setFieldsValue({
        weight: shipmentDetails?.weight,
        value: shipmentDetails?.value,
        childSelected: true,
        length: shipmentDetails?.length,
        width: shipmentDetails?.width,
        height: shipmentDetails?.height,
        volumatricWeight: shipmentDetails?.volumetricWeight,
      });

      childShipmentDetails?.map((item) => {
        form.setFieldsValue({
          [`weightChild_${item?.childAwbNumber}`]: item?.weight,
          [`lengthChild_${item?.childAwbNumber}`]: item?.length,
          [`widthChild_${item?.childAwbNumber}`]: item?.width,
          [`heightChild_${item?.childAwbNumber}`]: item?.height,
          [`totalVolWeightChild_${item?.childAwbNumber}`]:
            item?.volumetricWeight,
          [`valueInrNonDoxChild_${item?.childAwbNumber}`]: item?.value,
          [`awbNumberChild_${item?.childAwbNumber}`]: item?.childAwbNumber,
        });
      });
    }
    checkPinCodeServiceable(shipmentDetails?.destinationPincode);
  };

  useEffect(() => {
    form.setFieldsValue({
      pickupPincode: pickUpPinCode,
    });
    setContentData(
      docTypeBasedData
        ?.find((e) => e.type === "DOX")
        ?.data?.flatMap((item) =>
          item.description.map((desc) => ({
            label: `${item.content} - ${desc}`,
            value: `${item.content} - ${desc}`,
          }))
        )
    );
  }, []);

  const checkPinCodeServiceable = async (value) => {
    let response = await API.Api(
      CHECK_PIN_CODE_SERVICEABILITY + value
    ).getWithoutBearer();
    setAreaList([]);

    let data = response?.response?.data?.result;

    form.setFieldsValue({
      area: null,
    });

    if (response?.status) {
      const serviceability = data?.serviceability?.serviceability || "";
      const areas =
        serviceability !== "Non-Serviceable"
          ? (data?.areas || []).map((e) => ({
              label: capitaliseWithHyphen(e.areaName),
              value: e?.areaId,
              disabled: e?.areatype == "non-serviceable" ? true : false,
            }))
          : [];
      setAreaList(areas);

      const status = serviceability === "Non-Serviceable" ? "error" : "success";
      const msg =
        serviceability === "Non-Serviceable" ? "Non-Serviceable" : null;

      setPinCodeServiceableStatus({
        serviceability: data?.serviceability,
        status,
        msg,
      });
      setShowRateCard(false);
    } else {
      setPinCodeServiceableStatus({
        serviceability: null,
        status: "error",
        msg: response?.response?.data?.message,
      });
    }
  };

  const getAreaDestinationPremiseId = async (val) => {
    let response = await API.Api(GET_DESTINATION_PREMISE_ID + val).get();
    if (response?.status) {
      setAreaDestinationPremiseId(response?.response?.data);
    } else {
      setAreaDestinationPremiseId(null);
      form.setFieldValue("areaId", null);
      message.error(response?.response?.data?.message);
    }
  };

  const valumCalculation = (e) => {
    setShowRateCard(false);
    let length = form.getFieldValue("length");
    let widht = form.getFieldValue("width");
    let height = form.getFieldValue("height");
    let volum = ((+length * +widht * +height) / 5000).toFixed(2);
    form.setFieldsValue({
      volumatricWeight: volum,
    });
  };

  const changeShipmentType = (val) => {
    setChilldShipmentLength(0);
    setShowRateCard(false);
    setSelectedId(null);
    form.setFieldsValue({
      value: null,
      documentNumber: null,
      childSelected: false,
    });
    if (val == "NON-DOX") {
      form.setFieldsValue({
        content: null,
        description: null,
        weight: null,
        length: null,
        width: null,
        height: null,
        volumatricWeight: null,
      });
    } else {
      form.setFieldsValue({
        content: "Document - Form, Catalogue & Paper",
        weight: null,
        value: "0",
      });
    }
    setShowNonDoxFields(val === "DOX" ? false : true);
    setContentData(
      docTypeBasedData
        ?.find((e) => e.type === val)
        ?.data?.flatMap((item) =>
          item.description.map((desc) => ({
            label: `${item.content} - ${desc}`,
            value: `${item.content} - ${desc}`,
          }))
        )
    );
  };

  //--Child Shipment--//
  const changeChildSelection = (e) => {
    const val = e;
    setChilldShipmentLength(val == "true" ? 1 : 0);
    form.setFieldsValue({
      noOfChildPacket: val == "true" ? 1 : 0,
      childSelected: val == "true" ? true : false,
    });
    setShowRateCard(false);
    if (val == "true") {
      setChildShipments([
        {
          childDocumentNumber: null,
          weight: 0,
          length: 0,
          width: 0,
          height: 0,
          volumatricWeight: 0,
          value: 0,
          id: Math.random(),
        },
      ]);
    } else {
      setChildShipments([]);
    }
  };

  const addChildShipment = () => {
    let temp = childShipments;
    temp.push({
      childDocumentNumber: null,
      weight: 0,
      length: 0,
      width: 0,
      height: 0,
      volumatricWeight: 0,
      value: 0,
      id: Math.random(),
    });
    setChildShipments([...temp]);
  };

  const addAllChildShipment = () => {
    let noOfChildPacket = form.getFieldValue("noOfChildPacket");
    let childShipments = Array.from({ length: noOfChildPacket }, () => ({
      childDocumentNumber: null,
      weight: 0,
      length: 0,
      width: 0,
      height: 0,
      volumatricWeight: 0,
      value: 0,
      id: Math.random(),
    }));

    setChildShipments(childShipments);
  };

  const changeChildShipmentData = (val, id, type) => {
    setShowRateCard(false);
    let data = childShipments;
    let i = data.findIndex((e) => e.id == id);
    data[i][type] = val;
    setChildShipments([...data]);
  };

  const removeChild = (id) => {
    setShowRateCard(false);
    let data = childShipments;
    let i = data.findIndex((e) => e.id == id);
    data.splice(i, 1);
    setChildShipments([...data]);
  };
  ///---///
  const onSubmit = async (values) => {
    if (!selectedId) {
      message.error("Select a service");
    } else if (
      !isValidDocumentNum(
        values.documentNumber,
        selectedId?.serive?.toLowerCase() === "standard"
          ? "standardcash"
          : selectedId.serive
      )
    ) {
      message.error("Invalid document number as per selected service");
    } else {
      setLoading(true);

      let val = {
        ...values,
        destinationCpId: areaDestinationPremiseId,
        destinationHubId: null,
        content: values?.content?.split(" - ")?.[0].toLowerCase(),
        description: values?.content?.split(" - ")?.[1],
        shipmentType: values?.shipmentType?.toLowerCase(),
        service: selectedId?.serive?.toLowerCase(),
        travelBy: selectedId?.modeName?.toLowerCase(),
        baseCharge: selectedId?.estimatedPrice,
        fov: selectedId?.fov,
        cod: selectedId?.cod,
      };
      if (childShipments?.length > 0) {
        Object.keys(val).forEach((key) => {
          if (key.includes("Child_")) {
            delete val[key];
          }
        });
      }
      let payload = {
        ...val,
        childShipments: childShipments,
      };
      let response = await API.Api(POST_RETAIL_BOOKING).postIdHeader(payload);
      if (response?.status) {
        changeStep(values?.documentNumber);
      } else {
        message.error(
          response?.response?.data?.message ||
            response?.response?.data ||
            "Network error"
        );
      }
      setLoading(false);
    }
  };

  const getTatValue = async (values, rateList) => {
    let response = await API.Api(
      GET_CP_TAT_SERVICE +
        `${
          user_premiseId?.premises?.pincode + "/to/" + values?.deliveryPincode
        }`
    ).getCustomHeader({
      "SMCS-PREMISE-ID": user_premiseId?.userInfo?.premiseId,
      "SMCS-DPREMISE-ID": areaDestinationPremiseId,
    });
    let data = response?.response?.data?.data;
    let temp = [];
    let rateCatdResponse = rateList;
    for (let i = 0; i < rateCatdResponse.length; i++) {
      let mode =
        rateCatdResponse[i]?.serive.replace(/ +/g, "") +
        rateCatdResponse[i]?.modeName;
      let x = data;
      temp.push({
        ...rateCatdResponse[i],
        tatService:
          mode.toLowerCase() === "standardsurface"
            ? x?.standardSurfaceDays
            : mode.toLowerCase() === "standardair"
            ? x?.standardAirDays
            : mode.toLowerCase() === "valueplusair"
            ? x?.valueAirDays
            : mode.toLowerCase() === "valueplussurface"
            ? x?.valueSurfaceDays
            : mode.toLowerCase() === "fasttrackair"
            ? x?.expressAirDays
            : mode.toLowerCase() === "fasttracksurface"
            ? x?.expressSurfaceDays
            : "",
      });
    }
    setRateCardData(temp);
  };

  const handleChargableWeight = () => {
    let values = {
      shipmentType: form.getFieldValue("shipmentType"),
      weight: form.getFieldValue("weight"),
      volumatricWeight: form.getFieldValue("volumatricWeight"),
      deliveryPincode: form.getFieldValue("deliveryPincode"),
    };
    let ttvw = 0;
    if (childShipments.length > 0) {
      const childTotalWeight = childShipments.reduce(
        (sum, item) => sum + parseFloat(item.weight),
        0
      );
      const childTotalVolWeight = childShipments.reduce(
        (sum, item) => sum + parseFloat(item.volumatricWeight),
        0
      );
      if (
        +values?.weight + childTotalWeight <
        +values?.volumatricWeight + childTotalVolWeight
      ) {
        ttvw = +values?.volumatricWeight + childTotalVolWeight;
      } else {
        ttvw = +values?.weight + childTotalWeight;
      }
    } else {
      if (+values?.weight < values?.volumatricWeight) {
        ttvw = +values?.volumatricWeight;
      } else {
        ttvw = +values?.weight;
      }
    }
    let weight = values?.shipmentType == "DOX" ? values?.weight : +ttvw;
    setChargableWeight(weight);
    setOpenCharge(!openCharge);
  };

  const getRateCard = async (values) => {
    setLoading(true);
    let volWeightCheck =
      values?.shipmentType?.toUpperCase() == "NON-DOX" ? true : false;

    if (!values?.weight || !values?.deliveryPincode) {
      message.error("Please fill require fields");
    } else {
      let ttvw = 0;
      if (childShipments.length > 0) {
        const childTotalWeight = childShipments.reduce(
          (sum, item) => sum + parseFloat(item.weight),
          0
        );
        const childTotalVolWeight = childShipments.reduce(
          (sum, item) => sum + parseFloat(item.volumatricWeight),
          0
        );
        if (
          +values?.weight + childTotalWeight <
          +values?.volumatricWeight + childTotalVolWeight
        ) {
          ttvw = +values?.volumatricWeight + childTotalVolWeight;
        } else {
          ttvw = +values?.weight + childTotalWeight;
        }
      } else {
        if (+values?.weight < values?.volumatricWeight) {
          ttvw = +values?.volumatricWeight;
        } else {
          ttvw = +values?.weight;
        }
      }
      let weight = values?.shipmentType == "DOX" ? values?.weight : +ttvw;
      let stateName = JSON.parse(localStorage.getItem("userId"))?.premises
        ?.state;
      let payload = {
        areaId: areaDestinationPremiseId,
        type: form.getFieldValue("shipmentType") == "DOX" ? "DOX" : "NON_DOX",
        weight: weight,
        stateName: stateName.toUpperCase(),
      };
      let response = await API.Api(
        FETCH_RATE_CARD_CP +
          `${
            user_premiseId?.premises?.pincode + "/to/" + values?.deliveryPincode
          }`
      ).postCustomHeader(payload, {
        "SMCS-PREMISE-ID": user_premiseId?.userInfo?.premiseId,
        clientId: 0,
      });
      if (response.status) {
        setRateAndGetTat(response, values);
      } else {
        setLoading(false);
        if (
          response?.response?.data?.message ==
          "recvAddress(..) failed: Connection reset by peer"
        ) {
          let response1 = await API.Api(
            FETCH_RATE_CARD_CP +
              `${
                user_premiseId?.premises?.pincode +
                "/to/" +
                values?.deliveryPincode
              }`
          ).postCustomHeader(payload, {
            "SMCS-PREMISE-ID": user_premiseId?.userInfo?.premiseId,
            clientId: 0,
          });
          if (response1?.status) {
            setRateAndGetTat(response1, values);
          } else {
            message.error(
              response1?.response?.data?.message || "Internal server error."
            );
          }
        } else {
          message.error(
            response?.response?.data?.message || "Internal server error."
          );
        }
      }
    }
  };

  const setRateAndGetTat = (response, values) => {
    setLoading(false);
    setShowRateCard(true);
    let service = response?.response?.data?.data?.services;
    let responseData = response?.response?.data?.data;
    var temp = [];
    for (let i = 0; i < service?.length; i++) {
      for (let j = 0; j < service[i]?.rates?.length; j++) {
        temp.push({
          id: i + "_" + j + "_" + 1,
          serive: service[i]?.serviceType,
          modeName: service[i]?.rates[j]?.mode,
          price: service[i]?.rates[j]?.rate,
          color:
            service[i]?.serviceType == "FAST TRACK"
              ? "green"
              : service[i]?.serviceType == "STANDARD"
              ? "blue"
              : "orange",
          tatService: null,
          cod: responseData?.codCharges,
          fov: responseData?.fov,
          checkedFov: false,
          checkedCod: false,
        });
      }
    }

    setRateCardData([...temp]);
    getTatValue(values, temp);
  };

  return (
    <Spin
      spinning={loading}
      size="large"
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <Collapse accordion defaultActiveKey="1" className="collapse-atbs">
        <Panel
          header={
            <>
              <img className="messageIcon mr-1" src={calculeterIcon} />
              <span>Shipping Rate Calculator</span>
            </>
          }
          key="1"
        >
          <Form.Provider
            onFormFinish={(name, { values, forms }) => {
              if (name === "baseForm") {
                getRateCard(values);
              } else {
                onSubmit(forms.baseForm.getFieldsValue());
              }
            }}
          >
            <RateCalculate
              form={form}
              user_premiseId={user_premiseId}
              pickUpPinCode={pickUpPinCode}
              checkPinCodeServiceable={checkPinCodeServiceable}
              pinCodeServiceableStatus={pinCodeServiceableStatus}
              // descriptionData={descriptionData}
              checkNumbervalue={checkNumbervalue}
              isValidAwb={isValidAwb}
              checkSpecialCharacter={checkSpecialCharacter}
              areaList={areaList}
              getAreaDestinationPremiseId={getAreaDestinationPremiseId}
              docTypeBasedData={docTypeBasedData}
              capitaliseWithHyphen={capitaliseWithHyphen}
              changeShipmentType={changeShipmentType}
              contentData={contentData}
              // setDescriptionData={setDescriptionData}
              showNonDoxFields={showNonDoxFields}
              parseInt={parseInt}
              setShowRateCard={setShowRateCard}
              valumCalculation={valumCalculation}
              changeChildSelection={changeChildSelection}
              chilldShipmentLength={chilldShipmentLength}
              addAllChildShipment={addAllChildShipment}
              calculeterIcon={calculeterIcon}
              childShipments={childShipments}
              addChildShipment={addChildShipment}
              changeChildShipmentData={changeChildShipmentData}
              removeChild={removeChild}
              chargableWeight={chargableWeight}
              setChargableWeight={(val) => setChargableWeight(val)}
              handleChargableWeight={handleChargableWeight}
              openCharge={openCharge}
              readOnlyField={readOnlyField}
              service={selectedId?.serive || null}
            />
            <Form
              layout="vertical"
              scrollToFirstError={{
                behavior: "smooth",
                block: "center",
                inline: "center",
              }}
              name="rateForm"
              // onFinishFailed={(e) => console.log(e)}
            >
              {showRateCard && (
                <Collapse defaultActiveKey="1">
                  <Panel
                    key="1"
                    header={
                      <>
                        <img
                          className="messageIcon mr-1"
                          src={calculeterIcon}
                        />
                        <span>Select Service</span>
                      </>
                    }
                  >
                    <>
                      <Row gutter={16}>
                        {user_premiseId?.premises?.city == "Rajkot"
                          ? rateCardData?.map((item, i) => (
                              <Fragment key={i}>
                                <Col xs={22} sm={20} md={8} lg={6} xl={6}>
                                  <RateCard
                                    key={i}
                                    itemList={item}
                                    setSelectedId={setSelectedId}
                                    borderColor={
                                      item.id == selectedId?.id
                                        ? selectedId?.color
                                        : "transparent"
                                    }
                                    buttonColor={
                                      item.id == selectedId?.id ? "#2E77D0" : ""
                                    }
                                    buttonTextColor={
                                      item.id == selectedId?.id ? "#FFFFFF" : ""
                                    }
                                    ritTick={
                                      item.id == selectedId?.id ? ritTick : ""
                                    }
                                    premiumOrnot={
                                      pinCodeServiceableStatus?.serviceability
                                        ?.serviceability
                                    }
                                  />
                                </Col>
                              </Fragment>
                            ))
                          : rateCardData
                              .filter((e) => e.serive !== "VALUE PLUS")
                              .map((item, i) => (
                                <Fragment key={`rate${i}`}>
                                  <Col
                                    xs={22}
                                    sm={20}
                                    md={8}
                                    lg={6}
                                    xl={6}
                                    key={i}
                                  >
                                    <RateCard
                                      key={i}
                                      itemList={item}
                                      setSelectedId={setSelectedId}
                                      borderColor={
                                        item.id == selectedId?.id
                                          ? selectedId?.color
                                          : "transparent"
                                      }
                                      buttonColor={
                                        item.id == selectedId?.id
                                          ? "#2E77D0"
                                          : ""
                                      }
                                      buttonTextColor={
                                        item.id == selectedId?.id
                                          ? "#FFFFFF"
                                          : ""
                                      }
                                      ritTick={
                                        item.id == selectedId?.id ? ritTick : ""
                                      }
                                      premiumOrnot={
                                        pinCodeServiceableStatus?.serviceability
                                          ?.serviceability
                                      }
                                    />
                                  </Col>
                                </Fragment>
                              ))}
                      </Row>
                    </>
                  </Panel>
                </Collapse>
              )}
              {rateCardData?.length > 0 && showRateCard && (
                <div className="flex-end">
                  <Button htmlType="submit" className="save-btn mt-2">
                    NEXT
                  </Button>
                </div>
              )}
            </Form>
          </Form.Provider>
        </Panel>
      </Collapse>
    </Spin>
  );
};
export default RateCalculatorForm;
