/* eslint-disable indent */
import React, { useState, useEffect } from "react";

export function LeadDetailsContainer({
  dataSources,
  numberOfColumns,
  style,
  headingText,
}) {
  const [numOfColoum, setnumOfColoum] = useState(numberOfColumns);
  const columnWidth = 100 / numOfColoum;
  const outputArray = dataSources
    ? Object?.entries(dataSources)?.map(([key, value]) => ({
        key,
        value,
      }))
    : [];

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width >= 1408) {
      setnumOfColoum(numberOfColumns);
    } else if (width >= 1104) {
      setnumOfColoum(numberOfColumns);
    } else if (width >= 842) {
      setnumOfColoum(3);
    } else {
      setnumOfColoum(2);
    }
  };

  function formatString(inputString) {
    if (typeof inputString !== "string") {
      throw new Error("Input must be a string");
    }

    const words = inputString.split(/(?=[A-Z])/);

    const formattedString = words.map((word, index) => {
      if (index > 0 && word.length > 1 && word === word.toUpperCase()) {
        return word;
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    });

    return formattedString.join(" ");
  }

  const generateRows = () => {
    const rows = [];

    for (let i = 0; i < outputArray?.length; i += numOfColoum) {
      const rowItems = outputArray?.slice(i, i + numOfColoum);

      while (rowItems?.length < numOfColoum) {
        rowItems?.push({ key: "", value: "" });
      }

      const row = (
        <div className="row" key={i}>
          {rowItems.map((item, index) => (
            <div
              className="column"
              style={{ minWidth: `${columnWidth}%` }}
              key={index}>
              <div className="column-content">
                <div className="view-details-gride-name">
                  {" "}
                  {formatString(item?.key.replace(/\d/g, ""))}
                </div>
                <div className="view-details-gride-hub">
                  {" "}
                  {item?.key ? item?.value || "N/A" : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      );

      rows.push(row);
    }

    return rows;
  };

  return (
    <div style={style?.viewDetailsContainer}>
      <div className="view-details-header" style={style?.viewDetailsHeader}>
        {headingText}
      </div>
      <div className="view-details-gride">{generateRows()}</div>
    </div>
  );
}
