import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Row, Col, Spin, message } from "antd";

import urls from "../../api/urls";

const { GET_NEW_PASSOWORD_DETAILS, CREATE_NEW_PASSWORD } = urls;

const CreateNewPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    let token = window?.location?.search?.split("=")[1];
    let response = await axios.get(GET_NEW_PASSOWORD_DETAILS + token);
    form.setFieldsValue({
      username: response?.data?.data?.emailId,
      email: response?.data?.data?.emailId,
    });
  };

  const onFinish = async (values) => {
    setLoading(true);
    let payload = { ...values, username: values.email };
    delete payload["confirm"];
    let response = await axios.post(CREATE_NEW_PASSWORD, payload);
    if (response?.data?.username) {
      message.success("Password created successfully.");
      navigate("/");
    } else {
      message.error("Internal server error.");
    }
    setLoading(false);
  };

  return (
    <div className="login-main-container">
      <Spin spinning={loading}>
        <Row>
          <Col
            className="img-parent"
            xl={12}
            lg={12}
            md={24}
            sm={24}
            xs={24}
            // className={"logibg-container"}
          >
            <div className="img-div"></div>
          </Col>
          <Col
            xl={12}
            lg={12}
            md={24}
            sm={24}
            xs={24}
            style={{
              margin: "0 auto",
              position: "relative",
              backgroundColor: " #ffffff",
            }}
          >
            <div className="login-container">
              <div
                className="login-container-headding"
                style={{ marginTop: "10rem" }}
              >
                Create New Password
              </div>
              <Form form={form} onFinish={onFinish} autoComplete="on">
                <div className="login-container-email mt-1">Email</div>

                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Enter your email address",
                    },
                  ]}
                >
                  <Input
                    className="login-container-email-input"
                    placeholder="Enter your email address"
                    maxLength={50}
                    bordered={false}
                    disabled
                  />
                </Form.Item>
                <div className="login-container-email mt-1-5">Password</div>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Enter your password",
                    },
                    {
                      pattern: /^([A-Z]{1}[a-zA-Z0-9!@#$%^&*]{7,19})$/g,
                      message:
                        "First letter should be capital and length must be 8.",
                    },
                  ]}
                >
                  <Input.Password
                    className="login-container-email-input"
                    placeholder="Password"
                    bordered={false}
                  />
                </Form.Item>
                <div className="login-container-email mt-1-5">
                  Confirm Password
                </div>
                <Form.Item
                  name="confirm"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="login-container-email-input"
                    placeholder="Password"
                    bordered={false}
                  />
                </Form.Item>
                <Form.Item>
                  <div className="login-container-btndiv">
                    {" "}
                    <Button
                      className="login-container-btn"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      block
                    >
                      Create Password
                    </Button>
                  </div>
                </Form.Item>
              </Form>
              <div
                className="footer"
                style={{
                  position: "sticky",
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p>
                  Copyright © {new Date().getFullYear()} Shree Maruti Courier
                  Services Pvt. Ltd.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default CreateNewPassword;
