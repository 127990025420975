/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import {
  Row,
  Col,
  Tooltip,
  message,
  Modal,
  DatePicker,
  Button,
  Spin,
  Popconfirm,
  Radio,
  Select,
} from "antd";
import API from "../../../../api";
import urls from "../../../../api/urls";
import moment from "moment";
const {
  GET_TRIP_SCHEDULER_LIST,
  GET_ALL_SCHEDULED_VECHILE,
  DROP_SCHEDULE,
  DELETE_TRIP_SCHEDULE,
} = urls;
import {
  capitaliseWithHyphen,
  dateFormat_dmy,
  dateFormat_ymd,
} from "../../../../utils";
import UpdateTripSchedule from "../UpdateTripSchedule";
import driver_user from "../../../../assests/images/driver-user.svg";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";
import { DeleteOutlined } from "@ant-design/icons";
import "./style.scss";

const CalendarView = () => {
  const calendarComponentRef = React.createRef();
  const [calendarData, setCalendarData] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [showTripModal, setShowTripModal] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [assignDate, setAssignDate] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);

  const [dates, setDates] = useState(null);
  const [time, setTime] = useState(null);
  const [eventData, setEventData] = useState({});
  const [loading, setLoading] = useState(false);
  const [updateDelete, setUpdateDelete] = useState("Only This");
  const weekFormat = "MM/DD";
  const customWeekStartEndFormat = (value) =>
    `${moment(value).startOf("week").format(weekFormat)} ~ ${moment(value)
      .endOf("week")
      .format(weekFormat)}`;

  let currentDatePast = new Date();
  let currentDateFuture = new Date();
  currentDatePast.setFullYear(currentDatePast.getFullYear() + 1);
  currentDateFuture.setFullYear(currentDateFuture.getFullYear() - 1);
  currentDatePast = moment(currentDatePast).format(dateFormat_dmy);
  currentDateFuture = moment(currentDateFuture).format(dateFormat_dmy);
  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };

  useEffect(() => {
    initialize(currentDatePast, currentDateFuture);
    getScheduledVehicle();
  }, []);

  const initialize = async (date1, date2) => {
    setLoading(true);
    let response = await API.Api(
      GET_TRIP_SCHEDULER_LIST + `${date2}/to/${date1}`
    ).getWithAllHeaders();
    if (response.status) {
      let data = response?.response?.data;
      let newData = data.map((item) => {
        if (item.hasOwnProperty("driverName")) {
          item.title = item.driverName;
          item.start = `${moment(item.scheduleDate, dateFormat_dmy).format(
            dateFormat_ymd
          )} ${item?.scheduleTime}`;
        }
        return item;
      });
      setCalendarData(newData);
    } else {
      message.error(response?.response?.data?.message || "Network error");
    }
    setLoading(false);
  };

  const getScheduledVehicle = async () => {
    let response = await API.Api(GET_ALL_SCHEDULED_VECHILE).getWithAllHeaders();
    if (response.status) {
      let data = response?.response?.data;
      data.map((item) => {
        item["title"] = item["driverName"];
      });
      setVehicleList(data);
      let temp = [];
      data.map((e) => {
        temp.push({
          label: (
            <Tooltip
              title={`${e.driverName} - ${e.vehicleNo} - ${capitaliseWithHyphen(
                e.routeName
              )}`}>
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}>
                {e.driverName} - {e.vehicleNo} -{" "}
                {capitaliseWithHyphen(e.routeName)}
              </span>
            </Tooltip>
          ),
          title: `${e.driverName} - ${e.vehicleNo} - ${e.routeName}`,
          value: e.scheduleMasterId,
        });
      });
      setDriverList([...temp]);
    } else {
      message.error(response?.response?.data?.message || "Network error.");
    }
  };

  const showDateRange = () => {
    setShowTripModal(true);
    setShowDatePicker(true);
  };

  const viewModal = (eventInfo) => {
    setShowDatePicker(false);
    setShowTripModal(true);
    setEventData(eventInfo);
  };

  function handleModalCancel(params) {
    setShowTripModal(false);
    if (params == 1) {
      initialize(currentDatePast, currentDateFuture);
    }
  }

  const viewModalContent = () => {
    return (
      <p>
        <UpdateTripSchedule
          id={eventData?.event?._def.publicId}
          handleModalCancel={handleModalCancel}
        />
      </p>
    );
  };

  const deleteSchedule = async () => {
    let id = eventData?.event?._def.publicId;
    let response = await API.Api(DELETE_TRIP_SCHEDULE + id).deleteIdHeader({
      tripId: id,
      updateTo: updateDelete,
    });
    if (response?.status) {
      message.success(
        response?.response?.data?.message || "Trip deleted successfully"
      );
      initialize(currentDatePast, currentDateFuture);
      setShowTripModal(false);
    } else {
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <div className="space-between">
          <div
            className="trip-title d-flex"
            onClick={() => viewModal(eventInfo)}>
            <div
              style={{
                background: eventInfo.event._def.extendedProps.driverColor,
                display: "flex",
                alignItems: "center",
                padding: "2px",
              }}>
              <img src={driver_user} />
            </div>
            <div style={{ padding: "0 2px 0 2px" }}>
              <p>{eventInfo.event.title}</p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleEvents = (events) => {
    setCurrentEvents(events);
  };

  const showWeekView = () => {
    let currentYear = moment(dates?._d).format(dateFormat_ymd).split("-")[0];
    let startDate = time?.split(" ~ ")[0].concat(`/${currentYear}`);
    let endDate = time?.split(" ~ ")[1].concat(`/${currentYear}`);
    startDate = moment(startDate).format(dateFormat_ymd);
    endDate = moment(endDate).format(dateFormat_ymd);
    calendarComponentRef.current
      .getApi()
      .changeView("timeGridWeek", startDate, endDate);
    setShowTripModal(false);
  };

  const changeWeekView = (date, time) => {
    setDates(date);
    setTime(time);
  };

  const startEnd = {
    start: moment().format(dateFormat_ymd),
    end: "2100-01-01",
  };

  const onChangeAssignDate = (value, dateString) => {
    setAssignDate(dateString);
  };

  const assignEvent = () => {
    if (assignDate && selectedDriver) {
      let eventDate = moment(assignDate).format("DD-MM-YYYY HH:mm");
      let date = eventDate.split(" ")[0];
      let time = eventDate.split(" ")[1];
      let selectedDriverTime = vehicleList.find(
        (item) => item.scheduleMasterId === selectedDriver
      );
      selectedDriverTime["scheduleDate"] = date;
      selectedDriverTime["scheduleTime"] = time;
      delete selectedDriverTime.driverName;
      postSelectedEvent(selectedDriverTime);
    } else {
      message.error("Please select driver and schedule date");
    }
  };

  const postSelectedEvent = async (payload) => {
    setLoading(true);
    let response = await API.Api(DROP_SCHEDULE).postIdHeader(payload);
    if (response?.status) {
      initialize(currentDatePast, currentDateFuture);
      message.success(response?.response?.data?.message);
      setAssignDate(null);
      setSelectedDriver(null);
      setLoading(false);
    } else {
      initialize(currentDatePast, currentDateFuture);
      message.error(response?.response?.data?.message);
      setAssignDate(null);
      setSelectedDriver(null);
      setLoading(false);
    }
  };

  return (
    <div className="master-main-container">
      <Spin
        spinning={loading}
        size="large"
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }>
        <div className="flex-end">
          <div className="d-flex create-schedule-btn">
            <Select
              showSearch
              placeholder="Select driver"
              options={driverList}
              filterOption={(input, option) =>
                (option?.title ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              className="select-without-form mr-1"
              onChange={(e) => setSelectedDriver(e)}
              value={selectedDriver}
            />
            <DatePicker
              showTime
              onChange={onChangeAssignDate}
              style={{
                borderRadius: "5px",
                height: "40px",
                width: "250px",
              }}
              disabledDate={disabledDate}
              format="YYYY-MM-DD HH:mm"
              className="mr-1"
              value={assignDate ? moment(assignDate, "YYYY-MM-DD HH:mm") : null}
            />
            <Button className="save-btn mr-1" onClick={assignEvent}>
              Assign
            </Button>
            <Button className="save-btn mr-1" onClick={showDateRange}>
              {"Select Week"}
            </Button>
          </div>
        </div>
        <Row gutter={16}>
          <Col xl={1} lg={1} md={1}></Col>
          <Col xl={22} lg={22} md={22} className="create-calendar-class">
            {calendarData && (
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: "prev,next title",
                  right: "",
                }}
                initialView="timeGridWeek"
                weekends={true}
                events={calendarData}
                eventContent={renderEventContent}
                ref={calendarComponentRef}
                dayMaxEvents={true}
                eventsSet={handleEvents}
                eventConstraint={startEnd}
                className="full-calendar-create"
                dayHeaderContent={(args) => {
                  return moment(args.date).format("ddd Do");
                }}
              />
            )}
          </Col>
          <Col xl={1} lg={1} md={1}></Col>
        </Row>
      </Spin>
      <Modal
        onCancel={() => {
          setShowTripModal(false);
        }}
        open={showTripModal}
        width={showDatePicker ? 350 : 1000}
        footer={null}
        title={
          showDatePicker ? (
            ""
          ) : (
            <div className="space-between">
              <div>Trip Schedule</div>

              <div style={{ margin: "0 42px" }}>
                <Popconfirm
                  placement="bottom"
                  title={
                    <>
                      <div>
                        <h3>Delete recurring event?</h3>
                      </div>
                      <Radio.Group
                        defaultValue={"Only This"}
                        onChange={(e) => setUpdateDelete(e.target.value)}>
                        <div>
                          <Radio value={"Only This"}>
                            <h4>Only for this</h4>
                          </Radio>
                        </div>

                        <div>
                          <Radio value={"This and Remainings"}>
                            <h4>This and remaining</h4>
                          </Radio>
                        </div>
                      </Radio.Group>
                    </>
                  }
                  onConfirm={deleteSchedule}
                  okText="OK"
                  cancelText="CANCEL">
                  <DeleteOutlined />
                </Popconfirm>
              </div>
            </div>
          )
        }
        className="timepicker-modal"
        maskClosable={false}
        destroyOnClose={showDatePicker ? false : true}>
        {showDatePicker ? (
          <>
            <br />
            <label style={{ color: "#202124" }}>Select Week</label>
            <DatePicker
              style={{ width: "300px" }}
              defaultValue={moment()}
              format={customWeekStartEndFormat}
              picker="week"
              onChange={changeWeekView}
              disabledDate={disabledDate}
            />
            <div className="flex-end mt-1">
              <Button className="save-btn" onClick={showWeekView}>
                CONTINUE
              </Button>
            </div>
          </>
        ) : (
          viewModalContent()
        )}
      </Modal>
    </div>
  );
};
export default CalendarView;
