/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table, Row, Col, message, Input, DatePicker, Button } from "antd";

import { camelCase, serialNum, getStartAndEndDate } from "../../../utils";
import urls from "../../../api/urls";
import API from "../../../api";
import { ReactComponent as Audit_icon } from "../../../assests/images/middleMile/audited-icon.svg";
import search_icon from "../../../assests/images/search-icon.svg";

import Smcs_Loader from "../../../assests/images/loader-new.gif";
import { ReactComponent as Substract } from "../../../assests/images/subtract.svg";
import { ReactComponent as Aubstract } from "../../../assests/images/aubtract.svg";
import download_pin_icon from "../../../assests/images/SVG/download-pin.svg";

import constants from "../../../constants/constants";
import moment from "moment";
const {
  AUDIT_LIST,
  AUDIT_SEARCH,
  AUDIT_EXCEL_DOWNLOAD,
  AUDIT_EXCEL_DOWNLOAD_HO,
} = urls;
const { RangePicker } = DatePicker;

const NewAudit = ({ activeKey }) => {
  const [manifestedCp, setManifestedCp] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pageNum, setPageNum] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const [startAndEndDate, setStartAndEndDate] = useState({});
  const [serachValue, setSerachValue] = useState(null);

  const { ROLES } = constants;
  const { HO_ADMIN, HUB_ADMIN } = ROLES;

  const userinfo = JSON.parse(localStorage.getItem("userinfo"));
  const userId = JSON.parse(localStorage.getItem("userId"));

  const getRole = userinfo?.roles[0];
  useEffect(() => {
    if (activeKey == "2") {
      let todaysDate = moment(new Date(), "ddd MMM DD YYYY HH:mm:ss").format(
        "DD-MM-YYYY"
      );
      let twoDaysBack = moment().subtract(1, "days").format("DD-MM-YYYY");
      setStartAndEndDate({ start: twoDaysBack, end: todaysDate });
      getManifest("?pageNo=0&pageSize=10", twoDaysBack, todaysDate);
    }
  }, [activeKey]);

  useEffect(() => {
    if (getRole === HO_ADMIN) {
      let todaysDate = moment(new Date(), "ddd MMM DD YYYY HH:mm:ss").format(
        "DD-MM-YYYY"
      );
      let twoDaysBack = moment().subtract(1, "days").format("DD-MM-YYYY");
      setStartAndEndDate({ start: twoDaysBack, end: todaysDate });
      getManifest("?pageNo=0&pageSize=10", twoDaysBack, todaysDate);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (serachValue?.length > 0) {
        handleSearch(serachValue);
      } else if (serachValue === "") {
        getManifest(
          "?pageNo=0&pageSize=10",
          startAndEndDate?.start,
          startAndEndDate?.end
        );
      }
    }, 900);
    return () => clearTimeout(delayDebounceFn);
  }, [serachValue]);

  const downloadURL = () => {
    const baseUrl =
      getRole === HO_ADMIN ? AUDIT_EXCEL_DOWNLOAD_HO : AUDIT_EXCEL_DOWNLOAD;
    const dateParams = getStartAndEndDate(
      startAndEndDate?.start,
      startAndEndDate?.end,
      "DD-MM-YYYY"
    );
    const premiseIdParam =
      getRole !== HO_ADMIN
        ? `&SMCS-PREMISE-ID=${userId?.userInfo?.premiseId}`
        : "";

    // console.log(
    //   `${baseUrl}?pageNo=${
    //     pageNum - 1
    //   }&pageSize=${pageSize}${dateParams}${premiseIdParam}`
    // );
    return `${baseUrl}?pageNo=${
      pageNum - 1
    }&pageSize=${pageSize}${dateParams}${premiseIdParam}`;
  };

  const columns = [
    {
      title: "Sl No",
      align: "center",

      render: (a, b, idx) => serialNum(10, pageNum, idx),
      dataIndex: "slno",
      key: "slno",
      width: 80,
    },
    {
      title: "Awb No",
      dataIndex: "awbNumber",
      key: "awbNumber",

      render: (text, val) => {
        return (
          <div>
            <span style={{ marginRight: "10px" }}>{text}</span>
            {val.auditStatus && <Audit_icon />}
          </div>
        );
      },
    },
    {
      title: "Source Hub",
      dataIndex: "sourceHubName",
      key: "sourceHubName",
      sorter: (a, b) => a.sourceHubName.localeCompare(b.sourceHubName),
      render: (text) => {
        return text ? camelCase(text) : "-----";
      },
    },
    {
      title: "Destination Hub",
      dataIndex: "destinationHubName",
      key: "destinationHubName",
      sorter: (a, b) =>
        a.destinationHubName.localeCompare(b.destinationHubName),

      render: (text) => {
        return text ? camelCase(text) : "-----";
      },
    },
    {
      title: "Audited Hub Details",
      dataIndex: "auditedHub",
      key: "auditedHub",
      render: (text, rec) => {
        return (
          <>
            <div>{text && camelCase(text)}</div>
            <div>{rec?.auditTime && rec?.auditTime}</div>
          </>
        );
      },
    },
    {
      title: "Booking types",
      dataIndex: "bookingType",
      key: "bookingType",

      render: (text) => {
        return text ? text : "-----";
      },
      width: 120,
    },
    {
      title: "Service type",
      dataIndex: "serviceType",
      key: "serviceType",
      render: (text) => {
        return text ? text : "-----";
      },
      width: 120,
    },
    {
      title: "No of Audit Parameter",
      dataIndex: "noOfAuditParam",
      key: "noOfAuditParam",
      render: (text) => {
        return text ? text : "-----";
      },
    },
    {
      title: "Audited for",
      dataIndex: "auditType",
      key: "auditType",

      render: (text) => (text ? camelCase(text) : "-----"),
    },
    {
      title: "Booking Value",
      dataIndex: "auditType",
      key: "premiseName",

      render: (text, rec) => (
        <TableCompo
          actual={rec?.bookedWeight}
          l={rec?.bookedLength}
          b={rec?.bookedWidth}
          h={rec?.bookedHeight}
          volumetric={rec?.volumetricBookedValue}
        />
      ),
      width: 250,
    },
    {
      title: "Audited Value",
      dataIndex: "auditType",
      key: "premiseName",
      render: (text, rec) => (
        <TableCompo
          actual={rec?.auditedWeight}
          l={rec?.auditedLength}
          b={rec?.auditedWidth}
          h={rec?.auditedHeight}
          volumetric={rec?.volumetricAuditedValue}
        />
      ),
      width: 250,
    },
    {
      title: "Alert type",
      dataIndex: "volWeightDiffPerc",
      key: "volWeightDiffPerc",
      fixed: "right",

      render: (recored, rec) => {
        const shouldRenderSubstract =
          (rec?.weightDiffPerc && rec?.weightDiffPerc > 10) ||
          (rec?.volWeightDiffPerc && rec?.volWeightDiffPerc > 10);

        return shouldRenderSubstract ? <Aubstract /> : <Substract />;
      },
    },
  ];

  const getManifest = async (page, start, end) => {
    setLoading(true);
    const role = getRole === HUB_ADMIN ? "premise" : "ho";
    let response = await API.Api(
      AUDIT_LIST +
        `${role}${page}` +
        getStartAndEndDate(start, end, "DD-MM-YYYY")
    ).getWithPremiseHeader();
    if (response.status) {
      setLoading(false);
      let temp = [];
      response?.response?.data?.auditReports?.forEach((e, index) => {
        temp.push({ ...e, slno: index + 1 });
      });
      setTotalPageSize(response?.response?.data?.count);
      setManifestedCp(temp);
    } else {
      setLoading(false);
      message.error(
        response?.response?.data?.message || "Something went wrong"
      );
    }
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  function handlePageChange(pagination) {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
    setCurrentPage(pagination.current);

    getManifest(
      `?pageNo=${pagination.current - 1}&pageSize=${pagination.pageSize}`,
      startAndEndDate?.start,
      startAndEndDate?.end
    );
  }

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleOnclick = () => {
    getManifest(
      `?pageNo=${currentPage - 1}&pageSize=${pageSize}`,
      startAndEndDate?.start,
      startAndEndDate?.end
    );
  };

  const TableCompo = ({ l, b, h, volumetric, actual }) => {
    return (
      <Row justify={"space-between"} gutter={[10, 15]} style={{ width: "500" }}>
        <Col lg={16} style={{ fontWeight: "500" }}>
          Actual
        </Col>
        <Col lg={8}>{actual}</Col>
        <Col lg={16} style={{ fontWeight: "500" }}>
          Volumetric
        </Col>
        <Col lg={8}>{volumetric}</Col>
        <Col lg={16} style={{ fontWeight: "500" }}>
          LWH
        </Col>
        <Col lg={8}>{`${l},${b},${h}`}</Col>
      </Row>
    );
  };

  const [dateRangeStatus, setDateRangeStatus] = useState(false);

  const handleDateChange = (prarms, p2) => {
    const StartDate = moment(p2[0], "DD/MM/YYYY").format("DD-MM-YYYY");
    const endDate = moment(p2[1], "DD/MM/YYYY").format("DD-MM-YYYY");
    setStartAndEndDate({ start: StartDate, end: endDate });
    if (p2[0]?.length === 0) {
      // getTableData();
      setDateRangeStatus(false);
    } else {
      setDateRangeStatus(true);
    }
  };

  const handleSearch = async (e) => {
    setLoading(true);

    let response = await API.Api(
      AUDIT_SEARCH + "?search=" + e
    ).getWithPremiseHeader();
    if (response.status) {
      let temp = [];
      response?.response?.data?.auditReports?.forEach((e, index) => {
        temp.push({ ...e, slno: index + 1 });
      });
      setManifestedCp(temp);
      setTotalPageSize(response?.response?.data?.count);
      setLoading(false);
    } else {
      message(response?.response?.data?.message || "Something went wrong");

      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setSerachValue(e.target.value);
  };

  return (
    <>
      <div className="consolidated-manifest-cntr m-1">
        <div className="new-header ml-1">
          <Row gutter={12} justify={"space-between"}>
            <Col className="center" span={8}>
              <Input
                placeholder="Search AWB number,Source HUB name"
                bordered
                style={{
                  borderRadius: "5px",
                  height: "40px",
                  width: "100%",
                }}
                suffix={<img src={search_icon} />}
                onChange={handleInputChange}
              />
            </Col>
            <Col className="d-flex" alignContent={""}>
              <RangePicker
                onChange={handleDateChange}
                format={"DD/MM/YYYY"}
                disabledDate={(current) => disabledDate(current)}
                defaultValue={[moment().subtract(1, "days"), moment()]}
                style={{
                  borderRadius: "5px 0 0 5px",
                  height: "40px",
                  width: "230px",
                }}
              />
              <Button
                className="center mr-1"
                disabled={!dateRangeStatus}
                style={{
                  width: "40px",
                  height: "40px",
                  border: "1px solid #d9d9d9",
                  borderRadius: "0 5px 5px 0",
                }}
                onClick={handleOnclick}
              >
                <img src={search_icon} />
              </Button>
              <Button
                className="cancel-btn ml-1"
                style={{ height: "auto" }}
                onClick={() => window.open(downloadURL())}
              >
                <img src={download_pin_icon} alt="download logo" />
                &nbsp; DOWNLOAD REPORT
              </Button>
            </Col>
          </Row>
        </div>

        <Table
          columns={columns}
          dataSource={manifestedCp}
          scroll={{
            x: 2000,
            y: 500,
          }}
          onChange={handlePageChange}
          // pagination={{
          //   current: currentPage,
          //   pageSize: total,
          //   total: pageSize ,
          //   itemRender: itemRender,
          // }}

          pagination={{
            // pageSize: pageSize,
            // total: inputSearchValue.length == 0 ? tableData.length : 10,
            pageSizeOptions: ["10", "50", "100", "500", "1000"],
            current: currentPage,
            pageSize: pageSize,
            total: totalPageSize,
            // total: 100,
            itemRender: itemRender,
          }}
          loading={{
            indicator: (
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            ),
            spinning: loading,
          }}
        />
      </div>
    </>
  );
};

export default NewAudit;
