/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./style.scss";
import Heading from "../../components/Heading";
import { Table, message } from "antd";
import { camelCase, serialNum } from "../../utils";
import API from "../../api";
import urls from "../../api/urls";
import { APIs, getPremiseId } from "../../utils/worker";

function ExcesInscan() {
  const { GET_CP_IN_SCAN_LIST } = urls;
  let premiseId = getPremiseId();

  const [loading, setLoading] = useState(false);
  //   const [showtoolTip, setShowtoolTip] = useState(false);
  //   setLoading(false);

  const getTableData = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_CP_IN_SCAN_LIST +
        premiseId?.split("Bearer ")[1] +
        "?pageNum=0&pageSize=10"
    ).getWithUserNPremiseHeader();
    if (response.status) {
      setLoading(false);
    } else {
      if (!response.status) {
        setLoading(false);
        message.error(response.response.data.message || "Network error");
      }
    }
    // SetManifestedCp(mock);
  };

  let mock = [
    {
      manifestId: 12323313132,
      status: "OUT-SCANNED",
      premiseId: 489,
      premiseName: "DELHI-GANDHI NAGAR-LAXMI CINEMA",
      createDate: "12-2-110",
      travelBy: "surface",
      awbNumber: 111111111111, //shipemnt
      manifestStatus: "INSCANNED",
      service: "standard",
    },
    {
      manifestId: 4584689698648964,
      status: "INSCANNED",
      premiseId: 489,
      premiseName: "DELHI-GANDHI NAGAR-LAXMI CINEMA",
      createDate: "12-2-110",
      travelBy: "surface",
      awbNumber: 22222222222, //child
      manifestStatus: "INSCANNED",
      service: "standard",
    },
    {
      manifestId: 55467546754546,
      status: "OUT-SCANNED",
      premiseId: 489,
      premiseName: "DELHI-GANDHI NAGAR-LAXMI CINEMA",
      createDate: "12-2-110",
      travelBy: "surface",
      awbNumber: 55555555555555, //packet
      manifestStatus: "OUTSCANNED",
      service: "standard",
    },
    {
      manifestId: 54545454545,
      status: "OUT-SCANNED",
      premiseId: 489,
      premiseName: "DELHI-GANDHI NAGAR-LAXMI CINEMA",
      createDate: "12-2-110",
      travelBy: "surface",
      awbNumber: 666666666666666, //packet
      manifestStatus: "OUTSCANNED",
      service: "standard",
    },
    {
      manifestId: 56676776677676,
      status: "OUT-SCANNED",
      premiseId: 489,
      premiseName: "DELHI-GANDHI NAGAR-LAXMI CINEMA",
      createDate: "12-2-110",
      travelBy: "surface",
      awbNumber: 7777777777777, //packet
      manifestStatus: "OUTSCANNED",
      service: "standard",
    },
  ];

  const columns = [
    {
      title: "Sl No",
      align: "center",
      // width: 10,
      render: (a, b, idx) => serialNum(10, 1, idx),
    },
    {
      title: "Awb No/Packet Id",
      dataIndex: "barcode",
      width: 200,
      key: "barcode",
      render: (text) => {
        return <text>{text}</text>;
      },
    },

    {
      title: "Date",
      dataIndex: "createDate",
      key: "createDate",
      render: (text) => <div style={{ whiteSpace: "nowrap" }}>{text}</div>,
    },
    {
      title: "CP Name",
      dataIndex: "premiseName",
      key: "premiseName",
      render: (text) => <div>{camelCase(text)}</div>,
    },
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
      render: (text) => <div>{camelCase(text)}</div>,
    },

    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      render: (text) => <div>{camelCase(text)}</div>,
    },

    {
      title: "Service Type",
      dataIndex: "travelBy",
      key: "travelBy",
      render: (text) => <div>{camelCase(text)}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <div className="inscan-cp-container">
      {" "}
      <Heading title={"Excess In Scan"} />
      <div className="consolidated-manifest-cntr">
        {/* <Row> */}
        <Table
          columns={columns}
          dataSource={mock}
          scroll={{ x: 800, y: 1000 }}
          loading={loading}
          pagination={false}
          //   onChange={onChange}
        />
        {/* </Row> */}
      </div>
    </div>
  );
}

export default ExcesInscan;
