/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row } from "antd";
import { auditPath } from "../../config/auditPaths";
import MasterCard from "../../components/Master/MasterCard/MasterCard";
import MasterSearch from "../../components/Master/MasterSearch";

const Audits = () => {
  const [globalSettingList, setglobalSettingList] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [masterSearchTitle, setMasterSearchTitle] =
    useState("Audit Shipment");
  const navigate = useNavigate();

  useEffect(() => {
    let role = JSON.parse(localStorage.getItem("userinfo"));
    let temp = [];
    auditPath.map((e) => {
      e.role.some((item) => {
        if (role && role.roles.includes(item)) {
          temp.push(e);
        }
      });
    });
    setglobalSettingList(temp);
  }, []);

  const searchSetting = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);
    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(globalSettingList, currValue)
        : filterArray(globalSettingList, currValue);
    setDataSource(filteredData);
  };

  const filterArray = (dataSource, currValue) =>
    dataSource?.filter((o) =>
      o.title.toLowerCase().includes(currValue.toLowerCase())
    );

  const onClickHandler = (route, childPresent, id) => {
    if (childPresent) {
      navigate("/master-card-child", {
        state: id,
      });
    } else {
      navigate(route);
    }
  };

  return (
    <div className="master-container">
      <Row gutter={30}>
        <MasterSearch
          title={masterSearchTitle}
          search={searchSetting}
          setMasterSearchTitle={setMasterSearchTitle}
          masterSearch={true}
          masterSearchPlaceholder={"Search audit shipment"}
        />
        {inputSearchValue.length == 0
          ? globalSettingList.map((e) => {
              return (
                <MasterCard
                  key={e.id}
                  id={e.id}
                  title={e.title}
                  imgSrc={e.imgSrc}
                  onClickHandler={onClickHandler}
                  route={e.route}
                  searchTitle={masterSearchTitle}
                  disable={e.disable}
                  childPresent={e.childPresent}
                />
              );
            })
          : dataSource.map((e) => {
              return (
                <MasterCard
                  key={e.id}
                  id={e.id}
                  title={e.title}
                  imgSrc={e.imgSrc}
                  onClickHandler={onClickHandler}
                  route={e.route}
                  searchTitle={masterSearchTitle}
                  disable={e.disable}
                  childPresent={e.childPresent}
                />
              );
            })}
      </Row>
    </div>
  );
};
export default Audits;
