export function defaultRateCard() {
  const standardDoxDefaultDatas = [
    {
      title: "up to 50 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "35.00" },
        {  title: "WITHIN STATE", value: "70.00" },
        {  title: "My Zone 1", value: "35.00" },
        {  title: "My Zone 2", value: "35.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "160.00" },
        {  title: "SPECIAL LOCATION", value: "160.00" },
        {  title: "REST OF INDIA", value: "160.00" }
      ]
    },
    {
      title: "up to 100 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "35.00" },
        {  title: "WITHIN STATE", value: "70.00" },
        {  title: "My Zone 1", value: "35.00" },
        {  title: "My Zone 2", value: "35.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "160.00" },
        {  title: "SPECIAL LOCATION", value: "160.00" },
        {  title: "REST OF INDIA", value: "160.00" }
      ]
    },
    {
      title: "up to 250 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "45.00" },
        {  title: "WITHIN STATE", value: "90.00" },
        {  title: "My Zone 1", value: "45.00" },
        {  title: "My Zone 2", value: "45.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "180.00" },
        {  title: "SPECIAL LOCATION", value: "180.00" },
        {  title: "REST OF INDIA", value: "180.00" }
      ]
    },
    {
      title: "up to 500 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "50.00" },
        {  title: "WITHIN STATE", value: "100.00" },
        {  title: "My Zone 1", value: "50.00" },
        {  title: "My Zone 2", value: "50.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "200.00" },
        {  title: "SPECIAL LOCATION", value: "200.00" },
        {  title: "REST OF INDIA", value: "200.00" }
      ]
    },
    {
      title: "up to 750 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "60.00" },
        {  title: "WITHIN STATE", value: "110.00" },
        {  title: "My Zone 1", value: "60.00" },
        {  title: "My Zone 2", value: "60.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "260.00" },
        {  title: "SPECIAL LOCATION", value: "260.00" },
        {  title: "REST OF INDIA", value: "260.00" }
      ]
    },
    {
      title: "up to 1000 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "60.00" },
        {  title: "WITHIN STATE", value: "110.00" },
        {  title: "My Zone 1", value: "60.00" },
        {  title: "My Zone 2", value: "60.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "260.00" },
        {  title: "SPECIAL LOCATION", value: "260.00" },
        {  title: "REST OF INDIA", value: "260.00" }
      ]
    },
    {
      title: "Add 250 Gms",
      design: true,
      value: [
        { title: "LOCAL", value: "30.00" },
        { title: "WITHIN STATE", value: "60.00" },
        { title: "My Zone 1", value: "30.00" },
        { title: "My Zone 2", value: "30.00" },
        { title: "My Zone 3", value: "0.00" },
        { title: "My Zone 4", value: "0.00" },
        { title: "METRO", value: "140.00" },
        { title: "SPECIAL LOCATION", value: "140.00" },
        { title: "REST OF INDIA", value: "140.00" }
      ],
    },
  ];
  const standardNonDoxDefaultDatas = [
    {
      title: "By Surface (Per Kg)",
      design: null,
      value: [
        {  title: "LOCAL", value: "50.00" },
        {  title: "WITHIN STATE", value: "80.00" },
        {  title: "My Zone 1", value: "50.00" },
        {  title: "My Zone 2", value: "50.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "190.00" },
        {  title: "SPECIAL LOCATION", value: "190.00" },
        {  title: "REST OF INDIA", value: "190.00" }
      ]
    },
    {
      title: "By Air (Per Kg)",
      design: null,
      value: [
        {  title: "LOCAL", value: "50.00" },
        {  title: "WITHIN STATE", value: "80.00" },
        {  title: "My Zone 1", value: "50.00" },
        {  title: "My Zone 2", value: "50.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "250.00" },
        {  title: "SPECIAL LOCATION", value: "250.00" },
        {  title: "REST OF INDIA", value: "250.00" }
      ]
    },
    {
      title: "Add 500 Gms",
      design: true,
      value: [
        {  title: "LOCAL", value: "50.00" },
        {  title: "WITHIN STATE", value: "80.00" },
        {  title: "My Zone 1", value: "50.00" },
        {  title: "My Zone 2", value: "50.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "190.00" },
        {  title: "SPECIAL LOCATION", value: "190.00" },
        {  title: "REST OF INDIA", value: "190.00" }
      ]
    }
  ];
  const fastTrackSurfaceDefaultDatas = [
    {
      title: "up to 100 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "300.00" },
        {  title: "WITHIN STATE", value: "300.00" },
        {  title: "My Zone 1", value: "300.00" },
        {  title: "My Zone 2", value: "300.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "350.00" },
        {  title: "SPECIAL LOCATION", value: "350.00" },
        {  title: "REST OF INDIA", value: "350.00" }
      ]
    },
    {
      title: "up to 250 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "320.00" },
        {  title: "WITHIN STATE", value: "350.00" },
        {  title: "My Zone 1", value: "320.00" },
        {  title: "My Zone 2", value: "320.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "380.00" },
        {  title: "SPECIAL LOCATION", value: "440.00" },
        {  title: "REST OF INDIA", value: "400.00" }
      ]
    },
    {
      title: "up to 500 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "350.00" },
        {  title: "WITHIN STATE", value: "400.00" },
        {  title: "My Zone 1", value: "350.00" },
        {  title: "My Zone 2", value: "350.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "420.00" },
        {  title: "SPECIAL LOCATION", value: "460.00" },
        {  title: "REST OF INDIA", value: "430.00" }
      ]
    },
    {
      title: "up to 750 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "370.00" },
        {  title: "WITHIN STATE", value: "450.00" },
        {  title: "My Zone 1", value: "370.00" },
        {  title: "My Zone 2", value: "370.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "500.00" },
        {  title: "SPECIAL LOCATION", value: "550.00" },
        {  title: "REST OF INDIA", value: "500.00" }
      ]
    },
    {
      title: "up to 1000 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "370.00" },
        {  title: "WITHIN STATE", value: "450.00" },
        {  title: "My Zone 1", value: "370.00" },
        {  title: "My Zone 2", value: "370.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "500.00" },
        {  title: "SPECIAL LOCATION", value: "550.00" },
        {  title: "REST OF INDIA", value: "500.00" }
      ]
    },
    {
      title: "Add 250 Gms",
      design: true,
      value: [
        {  title: "LOCAL", value: "190.00" },
        {  title: "WITHIN STATE", value: "230.00" },
        {  title: "My Zone 1", value: "190.00" },
        {  title: "My Zone 2", value: "190.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "250.00" },
        {  title: "SPECIAL LOCATION", value: "250.00" },
        {  title: "REST OF INDIA", value: "250.00" }
      ]
    }
  ];
  const fastTrackAirDefaultDatas = [
    {
      title: "up to 100 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "300.00" },
        {  title: "WITHIN STATE", value: "300.00" },
        {  title: "My Zone 1", value: "300.00" },
        {  title: "My Zone 2", value: "300.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "350.00" },
        {  title: "SPECIAL LOCATION", value: "350.00" },
        {  title: "REST OF INDIA", value: "350.00" }
      ]
    },
    {
      title: "up to 250 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "320.00" },
        {  title: "WITHIN STATE", value: "350.00" },
        {  title: "My Zone 1", value: "320.00" },
        {  title: "My Zone 2", value: "320.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "380.00" },
        {  title: "SPECIAL LOCATION", value: "440.00" },
        {  title: "REST OF INDIA", value: "400.00" }
      ]
    },
    {
      title: "up to 500 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "350.00" },
        {  title: "WITHIN STATE", value: "400.00" },
        {  title: "My Zone 1", value: "350.00" },
        {  title: "My Zone 2", value: "350.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "420.00" },
        {  title: "SPECIAL LOCATION", value: "460.00" },
        {  title: "REST OF INDIA", value: "430.00" }
      ]
    },
    {
      title: "up to 750 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "370.00" },
        {  title: "WITHIN STATE", value: "450.00" },
        {  title: "My Zone 1", value: "370.00" },
        {  title: "My Zone 2", value: "370.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "500.00" },
        {  title: "SPECIAL LOCATION", value: "550.00" },
        {  title: "REST OF INDIA", value: "500.00" }
      ]
    },
    {
      title: "up to 1000 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "370.00" },
        {  title: "WITHIN STATE", value: "450.00" },
        {  title: "My Zone 1", value: "370.00" },
        {  title: "My Zone 2", value: "370.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "500.00" },
        {  title: "SPECIAL LOCATION", value: "550.00" },
        {  title: "REST OF INDIA", value: "500.00" }
      ]
    },
    {
      title: "Add 250 Gms",
      design: true,
      value: [
        {  title: "LOCAL", value: "190.00" },
        {  title: "WITHIN STATE", value: "230.00" },
        {  title: "My Zone 1", value: "190.00" },
        {  title: "My Zone 2", value: "190.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "250.00" },
        {  title: "SPECIAL LOCATION", value: "280.00" },
        {  title: "REST OF INDIA", value: "250.00" }
      ]
    }
  ];
  const fastTrackNonDoxDefaultDatas = [
    {
      title: "By Surface (Per Kg)",
      design: null,
      value: [
        {  title: "LOCAL", value: "370.00" },
        {  title: "WITHIN STATE", value: "450.00" },
        {  title: "My Zone 1", value: "370.00" },
        {  title: "My Zone 2", value: "370.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "500.00" },
        {  title: "SPECIAL LOCATION", value: "550.00" },
        {  title: "REST OF INDIA", value: "500.00" }
      ]
    },
    {
      title: "By Air (Per Kg)",
      design: null,
      value: [
        {  title: "LOCAL", value: "370.00" },
        {  title: "WITHIN STATE", value: "450.00" },
        {  title: "My Zone 1", value: "370.00" },
        {  title: "My Zone 2", value: "370.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "500.00" },
        {  title: "SPECIAL LOCATION", value: "550.00" },
        {  title: "REST OF INDIA", value: "500.00" }
      ]
    },
    {
      title: "Add 500 Gms",
      design: true,
      value: [
        {  title: "LOCAL", value: "190.00" },
        {  title: "WITHIN STATE", value: "230.00" },
        {  title: "My Zone 1", value: "190.00" },
        {  title: "My Zone 2", value: "190.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "250.00" },
        {  title: "SPECIAL LOCATION", value: "280.00" },
        {  title: "REST OF INDIA", value: "250.00" }
      ]
    }
  ];
  const valuePlusDoxDefaultDatas = [
    {
      title: "up to 100 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "150.00" },
        {  title: "WITHIN STATE", value: "200.00" },
        {  title: "My Zone 1", value: "150.00" },
        {  title: "My Zone 2", value: "150.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "250.00" },
        {  title: "SPECIAL LOCATION", value: "250.00" },
        {  title: "REST OF INDIA", value: "250.00" }
      ]
    },
    {
      title: "up to 250 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "170.00" },
        {  title: "WITHIN STATE", value: "220.00" },
        {  title: "My Zone 1", value: "170.00" },
        {  title: "My Zone 2", value: "170.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "280.00" },
        {  title: "SPECIAL LOCATION", value: "280.00" },
        {  title: "REST OF INDIA", value: "280.00" }
      ]
    },
    {
      title: "up to 500 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "200.00" },
        {  title: "WITHIN STATE", value: "250.00" },
        {  title: "My Zone 1", value: "200.00" },
        {  title: "My Zone 2", value: "200.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "320.00" },
        {  title: "SPECIAL LOCATION", value: "320.00" },
        {  title: "REST OF INDIA", value: "320.00" }
      ]
    },
    {
      title: "up to 750 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "250.00" },
        {  title: "WITHIN STATE", value: "300.00" },
        {  title: "My Zone 1", value: "250.00" },
        {  title: "My Zone 2", value: "250.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "400.00" },
        {  title: "SPECIAL LOCATION", value: "400.00" },
        {  title: "REST OF INDIA", value: "400.00" }
      ]
    },
    {
      title: "up to 1000 Gms",
      design: null,
      value: [
        {  title: "LOCAL", value: "250.00" },
        {  title: "WITHIN STATE", value: "300.00" },
        {  title: "My Zone 1", value: "250.00" },
        {  title: "My Zone 2", value: "250.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "400.00" },
        {  title: "SPECIAL LOCATION", value: "400.00" },
        {  title: "REST OF INDIA", value: "400.00" }
      ]
    },
    {
      title: "Add 250 Gms",
      design:true,
      value: [
        {  title: "LOCAL", value: "130.00" },
        {  title: "WITHIN STATE", value: "150.00" },
        {  title: "My Zone 1", value: "130.00" },
        {  title: "My Zone 2", value: "130.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "200.00" },
        {  title: "SPECIAL LOCATION", value: "200.00" },
        {  title: "REST OF INDIA", value: "200.00" }
      ]
    }
  ];
  const valuePlusNonDoxDefaultDatas = [
    {
      title: "By Surface (Per Kg)",
      design: null,
      value: [
        {  title: "LOCAL", value: "250.00" },
        {  title: "WITHIN STATE", value: "300.00" },
        {  title: "My Zone 1", value: "250.00" },
        {  title: "My Zone 2", value: "250.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "400.00" },
        {  title: "SPECIAL LOCATION", value: "400.00" },
        {  title: "REST OF INDIA", value: "400.00" }
      ]
    },
    {
      title: "By Air (Per Kg)",
      design: null,
      value: [
        {  title: "LOCAL", value: "250.00" },
        {  title: "WITHIN STATE", value: "300.00" },
        {  title: "My Zone 1", value: "250.00" },
        {  title: "My Zone 2", value: "250.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "400.00" },
        {  title: "SPECIAL LOCATION", value: "400.00" },
        {  title: "REST OF INDIA", value: "400.00" }
      ]
    },
    {
      title: "Add 500 Gms",
      design:true,
      value: [
        {  title: "LOCAL", value: "130.00" },
        {  title: "WITHIN STATE", value: "150.00" },
        {  title: "My Zone 1", value: "130.00" },
        {  title: "My Zone 2", value: "130.00" },
        {  title: "My Zone 3", value: "0.00" },
        {  title: "My Zone 4", value: "0.00" },
        {  title: "METRO", value: "200.00" },
        {  title: "SPECIAL LOCATION", value: "200.00" },
        {  title: "REST OF INDIA", value: "200.00" }
      ]
    }
  ];
  return {standardDoxDefaultDatas, fastTrackSurfaceDefaultDatas, standardNonDoxDefaultDatas, fastTrackAirDefaultDatas, fastTrackNonDoxDefaultDatas, valuePlusDoxDefaultDatas, valuePlusNonDoxDefaultDatas};

}
