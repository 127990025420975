/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Form,
  Button,
  Table,
  Spin,
  message,
  Popconfirm,
  Space,
  Tooltip,
} from "antd";
import "./style.scss";
import API from "../../../api";
import urls from "../../../api/urls";
import { capitalize } from "../../../utils";

const {
  UPDATE_VIRTUAL_AREA,
  REMOVE_VIRTUAL_AREA,

  GET_VIRTUAL_AREA,
  GET_DELIVERY_AREAS,
  DELETE_VIRTUAL_AREA,
} = urls;

const EditVirtualArea = ({ handleTabChange = () => {}, editId }) => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [deSelectedAreas, setDeSelectedAreas] = useState([]);
  const [virtualName, setVirtualName] = useState();
  const user_premiseId = JSON.parse(window?.localStorage?.getItem("userId"));

  useEffect(() => {
    getEditData();
    form.setFieldValue("deliveryName", virtualName);
  }, [virtualName]);

  const columns = [
    {
      title: "Area Name",
      dataIndex: "areaName",
      width: 350,
      key: "areaName",
      render: (text) => {
        return capitalize(text);
      },
    },
    {
      title: "Pincode",
      width: 650,
      dataIndex: "pincode",
      key: "pincode",
    },
  ];

  let getKeys = () => {
    return tableData?.map((val) => val.key);
  };

  const getEditData = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_VIRTUAL_AREA + editId.id
    ).getWithPremiseIdHeader();
    if (response.status) {
      setLoading(false);
      let data = response?.response?.data?.areas.map((val, idx) => {
        setVirtualName(response.response.data.virtualAreaName);
        val["key"] = idx;
        val["virtualAreaName"] = response.response.data.virtualAreaName;
        val["premiseId"] = response.response.data.premiseId;
        return val;
      });
      let x = getKeys();
      setSelectedRowKeys(x);
      setTableData(data);
      setSelectedAreas(data);
      getTableData();
    } else {
      setLoading(false);
      message.error(response.response.data.message || "Network error");
    }
  };

  const getTableData = async () => {
    setLoading(true);
    let response = await API.Api(
      GET_DELIVERY_AREAS + user_premiseId?.userInfo?.premiseId
    ).getWithPremiseIdHeader();
    if (response.status) {
      setLoading(false);
      let x = response?.response?.data.map((val) => val);
      let data = x.map((val, idx) => {
        val.areas["key"] = Math.random();
        val.areas["addedToVirtualArea"] = val.addedToVirtualArea;
        val.areas["pincode"] = val?.pincode;
        return val.areas;
      });
      setDeSelectedAreas(data);

      let mergedAreas = data.concat(tableData.map((item) => item));
      setTableData([...new Set(mergedAreas)]);
    } else {
      setLoading(false);
      // message.error(response.response.data.message || "Network error");
    }
  };

  function deleteKeysWrapper(data, getIds) {
    let temp = [...data];
    let temp2 = temp?.map((val) => {
      delete val["key"];
      delete val["allocated"];
      delete val["pincode"];
      delete val["virtualAreaName"];
      delete val["addedToVirtualArea"];
      delete val["premiseId"];
      if (getIds) {
        return val.areaId;
      } else {
        return val;
      }
    });
    return temp2;
  }

  const handleSubmit = async (id) => {
    let _areas = JSON.parse(JSON.stringify(selectedAreas));
    let _selectedAreas = deleteKeysWrapper(_areas, false);

    let payload = {
      virtualAreaName: id?.deliveryName,
      premiseId: editId.pId,
      areas: _selectedAreas,
    };
    let response = await API.Api(UPDATE_VIRTUAL_AREA + editId?.id).putIdHeader(
      payload
    );
    if (response.status) {
      setLoading(false);
      message.success(
        response?.response?.data?.message || "Updated Successfully"
      );
      handleTabChange("2");
      handleDeleteKeys();
    } else {
      setLoading(false);
      handleDeleteKeys();

      message.error(response?.response?.data?.message || "Network error");
    }
  };

  const handleDeleteKeys = async () => {
    let _deSelectareasKeys = JSON.parse(JSON.stringify(deSelectedAreas));
    let deSelectedAreasKeys = deleteKeysWrapper(_deSelectareasKeys, true);

    let payload = {
      areaId: deSelectedAreasKeys,
    };
    let response = await API.Api(REMOVE_VIRTUAL_AREA).putIdHeader(payload);
    if (response.status) {
      setLoading(false);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  function filterUniqueWrapper(data) {
    let temp = [...new Map(data.map((item) => [item["key"], item])).values()];
    return temp;
  }

  function filterArrayByValues(arr, values) {
    let selectTemp = arr?.filter((item) => values.includes(item.key));
    let deSelectTemp = arr?.filter((item) => !values.includes(item.key));

    let uniqueSelectedAreas = filterUniqueWrapper(selectTemp);
    let uniqueDeselectedAreas = filterUniqueWrapper(deSelectTemp);

    return {
      uniqueSelectedAreas,
      uniqueDeselectedAreas,
    };
  }

  const onSelectChange = (newSelectedRowKeys, info) => {
    setSelectedRowKeys(newSelectedRowKeys);
    let allAreas = [...selectedAreas, ...tableData];
    let filteredSelectedAreas = filterArrayByValues(
      allAreas,
      newSelectedRowKeys
    );
    setDeSelectedAreas(filteredSelectedAreas?.uniqueDeselectedAreas);
    setSelectedAreas(filteredSelectedAreas?.uniqueSelectedAreas);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleDisable = async () => {
    setLoading(true);
    let response = await API.Api(
      DELETE_VIRTUAL_AREA + editId?.id
    ).deleteIdHeader();
    if (response.status) {
      setLoading(false);
      handleTabChange("2");
      message.success(response.response.data.message || "Success");
    } else {
      setLoading(false);
      message.error(response.response.data.message || "Network error");
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="drs-generation-container p-2">
        <Form
          // {...layout}
          form={form}
          onFinish={handleSubmit}
          name="accountBasicDetails"
          layout="vertical"
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}>
          <Row gutter={16} className="m-1">
            <Col span={6}>
              <Form.Item
                name="deliveryName"
                label="Delivery area name"
                rules={[
                  {
                    required: true,
                    message: "Type area",
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Table
            onChange={onSelectChange}
            rowSelection={{
              preserveSelectedRowKeys: true,
              getCheckboxProps: (a) => {
                return a;
              },
              ...rowSelection,
            }}
            className="table-head-sticky"
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
          {selectedAreas?.length == 0 ? (
            <Row justify="end mt-1">
              <Tooltip placement="bottom">
                <div className="view-all-staff">
                  <Popconfirm
                    title={
                      <span
                        className="test"
                        style={{
                          textTransform: "initial",
                        }}>
                        Removing the area(s) will remove the Delivery Area
                        <br />
                        {virtualName + " "}
                        created. Do you want to proceed?
                      </span>
                    }
                    cancelText="No"
                    okText="Yes"
                    onConfirm={handleDisable}>
                    <Col>
                      <Button className="save-btn" htmlType="submit">
                        SAVE CHANGES
                      </Button>
                    </Col>
                  </Popconfirm>
                </div>
              </Tooltip>
            </Row>
          ) : (
            <Row justify="end mt-1">
              <Col>
                <Button
                  className="cancel-btn mr-1"
                  onClick={() => handleTabChange("2")}>
                  CANCEL
                </Button>
              </Col>
              <Col>
                <Button className="save-btn" htmlType="submit">
                  SAVE CHANGES
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </Spin>
  );
};
export default EditVirtualArea;
