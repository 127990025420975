import React from "react";
import { Row, Col, Tooltip } from "antd";
import packedIdIcon from "../../../assests/images/packetIcon.svg";
import weightLogo from "../../../assests/images/weightLogo.svg";
import truck from "../../../assests/images/manifestTruckIcon.svg";
import air from "../../../assests/images/manifestAirIcon.svg";
import packetContents from "../../../assests/images/packetContents.svg";
import "./style.scss";

const Minicard = ({ item }) => {
  return (
    <div className="truck-bigcard-container">
      <div className="minicard-main-container">
        <div className="first-container">
          <div className="left-container">
            <img src={packedIdIcon} />
            <div className="lower-box">
              <p>Packet ID</p>{" "}
              <div className="awb-no">{item?.packetBarcode}</div>
            </div>
          </div>
          <div className="right-container">
            <img src={weightLogo} />
            <div className="lower-box">
              <p>Weight</p>
              <div className="awb-no">{item?.weight.toFixed(2)}</div>
            </div>
            <img src={item?.travelBy === "air" ? air : truck} className="surface-icon" />
          </div>
        </div>
        <div className="middle-container">
          <div className="right-container">
            <img src={packetContents} />
            <p>Packet Contents</p>
          </div>
          <div className="box-cont">
            <Row gutter={32}>
              {item?.packetShipments?.map((el, idx) => (
                <Col key={idx}>{el?.awbNo}</Col>
              ))}
            </Row>
            <Tooltip
              placement="bottom"
              title={item?.packetShipments
                ?.slice(4, item?.packetShipments?.length)
                .map((el, ind) => (
                  <div key={ind}>{el.awbNo}</div>
                ))}
            >
              {item?.packetShipments?.length > 4 ? (
                <div className="count">{`+${
                  item?.packetShipments?.length - 4
                }`}</div>
              ) : null}
            </Tooltip>
          </div>
        </div>
        <div className="second-container"></div>
      </div>
    </div>
  );
};

export default Minicard;
